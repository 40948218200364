import * as React from 'react';
import { useMedia as useMediaLib } from 'use-media';

export const MOBILE_SCREEN_WIDTH = '639px';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useMedia = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const isMobile = useMediaLib({ maxWidth: MOBILE_SCREEN_WIDTH });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
    windowDimensions,
  };
};
