import { Dropdown, TextField } from '@fluentui/react';
import Axios from 'axios';
import * as React from 'react';

import { Grid } from 'ui-library';

import Card from 'common/controls/surfaces/Card';
import Page from 'common/layout/Page';

import OverlaySpinner from '../common/controls/progress/OverlaySpinner';

const AzureSearch: React.FC = () => {
  const [query, setQuery] = React.useState<string>('');
  const [orderBy, setOrderBy] = React.useState<string>('');
  const [resp, setResp] = React.useState<any>();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<string | number>('location-index');
  const handleSearch = () => {
    setLoading(true);
    Axios.get(`/development/azure_search?index=${selectedIndex}&query=${encodeURIComponent(query)}&orderBy=${encodeURIComponent(orderBy)}`, {
      timeout: 20000,
    })
      .then((r) => {
        setResp(r.data);
        setError(undefined);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  document.onkeypress = function (e) {
    if (e.code === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Page title="Azure search">
      {loading && <OverlaySpinner label="searching" />}
      <h2>Azure search</h2>
      <Card>
        <Grid.Row>
          <Grid.Col lg={2}>
            <Dropdown
              label="index"
              selectedKey={selectedIndex}
              onChange={(_, option) => setSelectedIndex(option ? option.key : 'location-index')}
              options={[
                { key: 'callsign-index', text: 'callsign-index' },
                { key: 'case-index', text: 'case-index' },
                { key: 'client-index', text: 'client-index' },
                { key: 'correspondence-index', text: 'correspondence-index' },
                { key: 'licence-index', text: 'licence-index' },
                { key: 'location-index', text: 'location-index' },
                { key: 'renewal-fee-index', text: 'renewal-fee-index' },
                { key: 'spectrum-index', text: 'spectrum-index' },
                { key: 'task-index', text: 'task-index' },
              ]}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={3}>
            <TextField label="Query string" onChange={(_, value) => setQuery(value ?? '')} placeholder="example: clientId eq '507316'" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={3}>
            <TextField label="Order by" onChange={(_, value) => setOrderBy(value ?? '')} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={12}>
            <h3>Response (JSON) document count ({resp?.count})</h3>
            <pre style={{ wordWrap: 'break-word', borderStyle: 'solid', backgroundColor: 'white', borderWidth: 1, minHeight: 200 }}>
              {JSON.stringify(error ? error : resp?.data, null, 2)}
            </pre>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </Page>
  );
};

export default AzureSearch;
