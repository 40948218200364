import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EquipmentDto, FileNoteDtoEntityTypeEnum } from 'api-client';

import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { useAuth } from 'common/auth/AuthHook';
import Form from 'common/form/Form';
import Page from 'common/layout/Page';
import { COMMON_FILE_NOTE_TYPE } from 'common/reference/referenceData';

import { useReferenceDataHookApi } from '../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../ReferenceDataRouter';
import UpdateEquipmentFields from './UpdateEquipmentFields';
import { equipmentSchema } from './validationSchema';

const UpdateEquipmentPage = () => {
  const { updateEquipment, getEquipment } = useReferenceDataHookApi();
  const { navigateToMaintainEquipment } = useLicenceReferenceDataNavigator();
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const [equipment, setEquipment] = useState<EquipmentDto>();

  useEffect(() => {
    getEquipment(+equipmentId).then(setEquipment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentId]);

  const submitForm = (values: any) =>
    updateEquipment(
      { ...values, make: values.make?.toUpperCase(), model: values.model?.toUpperCase(), band: values.band?.toUpperCase() },
      {
        successMessage: 'A new version of this Equipment has been created.',
      },
    ).then((equipment) => {
      navigateToMaintainEquipment(equipment.id!, true);
    });

  const { hasPermission, isInternalUser } = useAuth();

  const canEdit = hasPermission('UPDATE_EQUIPMENT') && equipment?.current && (isInternalUser || !equipment?.hidden);
  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  return (
    <Page title="Update Equipment">
      {equipment ? (
        <Formik initialValues={equipment} onSubmit={submitForm} enableReinitialize validationSchema={equipmentSchema}>
          <Form id={'update-equipment-form'} showFormButtonsBottom canEdit={canEdit} mode="VIEW" disabledSubmitWhenNoDirty>
            <UpdateEquipmentFields />
            {canViewFileNote && (
              <FileNoteSection entityId={+equipmentId} entityType={FileNoteDtoEntityTypeEnum.EQUIPMENT} noteTypes={COMMON_FILE_NOTE_TYPE} />
            )}
          </Form>
        </Formik>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default UpdateEquipmentPage;
