import { IDropdownOption } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { useEffect, useState } from 'react';

import { LicenceClassificationType, LicenceDtoLicenceTypeCodeEnum } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import { YesNoChoiceGroup } from 'common/controls/inputs/ChoiceGroup';
import DatePicker from 'common/controls/inputs/DatePicker';
import Dropdown from 'common/controls/inputs/Dropdown';
import { NumberField } from 'common/controls/inputs/NumberField';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { useForm } from 'common/form/FormHook';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';

import { useLicenceApi } from '../../LicenceApiHooks';
import SpectrumCreationUELSection from '../../craft/components/SpectrumDetails/SpectrumCreationUELSection';
import ITUServiceType from './ITUServiceTypes';
import ReferenceFrequencies from './ReferenceFrequencies';
import { spectrumTypeOptions, polarisationOptions } from './references';

interface Props {
  craftEmbedded?: boolean;
  showUelSection?: boolean;
  licenceClassification?: LicenceClassificationType;
  licenceTypeCode?: LicenceDtoLicenceTypeCodeEnum;
}

const SpectrumRecordFields: React.FC<Props> = (props) => {
  const { initialValues, values, setFieldValue } = useFormikContext<any>();
  const { getServiceTypes } = useLicenceApi();
  const [serviceTypesOptions, setServiceTypesOptions] = useState<IDropdownOption[]>([]);
  const [showServiceType, setShowServiceType] = useState<boolean>(true);
  const [bandChannel, setBandChannel] = useState<string | undefined>();
  const [isChannelORFrequency, setIsChannelORFrequency] = useState<boolean>(false);
  const [showRemarksReq, setShowRemarksReq] = useState<boolean>(false);
  const { mode } = useForm();
  const [bandwidth, setBandwidth] = useState<number | undefined>();

  useEffect(() => {
    (async function () {
      const serviceTypes = await getServiceTypes();

      setServiceTypesOptions(serviceTypes.map((o) => ({ key: o.id!, text: o.description! })));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      handleSpectrumTypeChange(initialValues.spectrumType);
      calculateBandWidth(values.lowerBound, values.upperBound);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, mode]);

  const { hasPermission, isInternalUser } = useAuth();

  const maintainForm = mode === 'EDIT' || mode === 'VIEW';

  const readOnlyDate = mode === 'EDIT' ? !(hasPermission('UPDATE_SPEC_RECORD') && isInternalUser) : false;

  const calculateBandWidth = (lowerBound: any, upperBound: any) => {
    const bandwidth = (upperBound * 1000000 - lowerBound * 1000000) / 1000000;
    setBandwidth(bandwidth);
  };

  const OnLowerBoundChange = (value: any) => {
    calculateBandWidth(value, values?.upperBound);
  };

  const onUpperBoundChange = (value: any) => {
    calculateBandWidth(values?.lowerBound, value);
  };

  const handleServiceTypeChange = (key: any) => {
    if (key !== 5 && values.generalPurpose != null) {
      setFieldValue('generalPurpose', null);
    } else {
      setFieldValue('generalPurpose', 'true');
    }
  };

  const handleSpectrumTypeChange = (key: any) => {
    key !== 'ITU' ? setShowServiceType(true) : setShowServiceType(false);
    if (key === 'BND' || key === 'CHL') {
      setBandChannel(key === 'BND' ? 'Band' : 'Channel');
    } else {
      setBandChannel(undefined);
      setFieldValue('label', null);
    }

    if (key === 'BND' && values.levelOneServiceId == null && mode === 'CREATE') {
      setFieldValue('levelOneServiceId', 13); //Other
    }
    if (key === 'BND' || key === 'ITU') {
      values.polarisation = undefined;
      setShowRemarksReq(true);
    } else {
      setShowRemarksReq(false);
    }

    if (key === 'CHL' || key === 'FRQ') {
      if (values.frequencies.length === 0) {
        values.frequencies.push({
          referenceFrequency: undefined,
          referenceFrequencyType: undefined,
          power: '',
          powerType: undefined,
          tolerance: '',
          hourCode: undefined,
          isoStartTime: '',
          isoStopTime: '',
          frequencyEmissions: [],
        });
      }
      setIsChannelORFrequency(true);
    } else {
      if (values.frequencies.length > 0) {
        setFieldValue('frequencies', []);
      }
      setIsChannelORFrequency(false);
    }
  };
  return (
    <>
      {maintainForm && (
        <div>
          <Grid.Row>
            <Grid.Col sm={12} lg={3}>
              <ReadOnlyField label="Spectrum ID" name={'id'} />
            </Grid.Col>
          </Grid.Row>
        </div>
      )}

      <Grid.Row>
        {!maintainForm && !props.craftEmbedded ? (
          <Grid.Col sm={12} lg={3}>
            <Dropdown
              name="spectrumType"
              label="Spectrum type"
              options={spectrumTypeOptions}
              onChange={(key: any) => handleSpectrumTypeChange(key)}
              required
            />
          </Grid.Col>
        ) : (
          <Grid.Col sm={12} lg={3}>
            <ReadOnlyField
              label="Spectrum type"
              name={'spectrumType'}
              renderFormat={(t) => spectrumTypeOptions.find((type) => type.key === t)?.text}
            />
          </Grid.Col>
        )}
        <Grid.Col sm={12} lg={3}>
          <ReadOnlyField label="Spectrum status" name="spectrumStatus" />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        {showServiceType &&
          (props.craftEmbedded ? (
            <Grid.Col sm={12} lg={3}>
              <ReadOnlyField
                label="Service type"
                name={'levelOneServiceId'}
                renderFormat={(t) => serviceTypesOptions.find((type) => type.key === t)?.text}
              />
            </Grid.Col>
          ) : (
            <Grid.Col sm={12} lg={3}>
              <Dropdown
                name="levelOneServiceId"
                label="Service type"
                options={serviceTypesOptions}
                required
                onChange={(key: any) => handleServiceTypeChange(key)}
              />
            </Grid.Col>
          ))}
        {!showServiceType && (
          <Grid.Col sm={12} lg={5}>
            <ITUServiceType name="ituAllocationServiceId" label="ITU service type" required={!showServiceType} />
          </Grid.Col>
        )}
        {bandChannel && (
          <>
            <Grid.Col sm={12} lg={3}>
              <TextField label={bandChannel} name="label" required maxLength={10} />
            </Grid.Col>
            {values.levelOneServiceId === 5 && (
              <Grid.Row>
                <Grid.Col sm={12} lg={2}>
                  <YesNoChoiceGroup name="generalPurpose" label="General use" required />
                </Grid.Col>
              </Grid.Row>
            )}
          </>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <NumberField
            label="Spectrum low (MHz)"
            name="lowerBound"
            onBlurCapture={(e: any) => OnLowerBoundChange(e.target.value)}
            required
            maxLength={13}
            integerDigit={1}
            decimalDigit={6}
            maximunDecimalDigit={11}
            readOnlyRenderFormat={(f) => f?.toFixed(6)}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <NumberField
            label="Spectrum high (MHz)"
            name="upperBound"
            onBlurCapture={(e: any) => onUpperBoundChange(e.target.value)}
            required
            maxLength={13}
            integerDigit={1}
            decimalDigit={6}
            maximunDecimalDigit={11}
            readOnlyRenderFormat={(f) => f?.toFixed(6)}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ReadonlyData label="Bandwidth (MHz)" value={bandwidth?.toFixed(6) + ' MHz'} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        {(values?.spectrumType === 'CHL' || values?.spectrumType === 'FRQ') && (
          <Grid.Col sm={12} lg={3}>
            <Dropdown name="polarisation" label="Polarisation" options={polarisationOptions} required />
          </Grid.Col>
        )}
        {isChannelORFrequency && (
          <Grid.Col sm={12} lg={3}>
            <TextField name="accessCode" label="Access code" maxLength={20} />
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <DatePicker name="startDate" label="Start date" isRequired dateOnlyAsString readOnly={readOnlyDate || props.craftEmbedded} allowTextInput />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <DatePicker
            name="endDate"
            label="End date"
            dateOnlyAsString
            readOnly={readOnlyDate || props.craftEmbedded}
            allowTextInput
            emptyFormat={'-'}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          <TextField label="Remarks" name="remarks" multiline required={showRemarksReq} />
        </Grid.Col>
      </Grid.Row>
      {mode !== 'CREATE' && (
        <>
          <Grid.Row>
            <Grid.Col sm={6} lg={5}>
              <ReadonlyData label="Created by" value={initialValues.createdByName} />
            </Grid.Col>
            <Grid.Col sm={6} lg={5}>
              <ReadonlyData label="Created date" value={initialValues.createdDate} renderFormat={formatDateTimeFromISO} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={6} lg={5}>
              <ReadonlyData label="Last updated by" value={initialValues.lastUpdatedName} />
            </Grid.Col>
            <Grid.Col sm={6} lg={5}>
              <ReadonlyData label="Last updated date" value={initialValues.lastUpdated} renderFormat={formatDateTimeFromISO} />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      {isChannelORFrequency && (
        <ReferenceFrequencies
          requiredEmissions={!!props.craftEmbedded}
          licenceClassification={props.licenceClassification}
          licenceTypeCode={props.licenceTypeCode}
        />
      )}
      {props.showUelSection && <SpectrumCreationUELSection />}
    </>
  );
};

export default SpectrumRecordFields;
