import * as React from 'react';
import { SectionHeading } from '../../../common/layout/SectionHeadings';

const ApplicationDetailsForLADecision = () => {
  return (
    <>
      <SectionHeading title="Application details for LA decision" />
    </>
  );
};

export default ApplicationDetailsForLADecision;
