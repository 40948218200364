import { FocusZoneTabbableElements } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { FrequencyEmissionDto, LicenceDtoLicenceTypeCodeEnum, SpectrumDto } from 'api-client';

import { Grid } from 'ui-library';

import InlineButton from 'common/controls/buttons/InlineButton';
import Dropdown from 'common/controls/inputs/Dropdown';
import { NumberField } from 'common/controls/inputs/NumberField';
import DetailsList from 'common/controls/lists/DetailsList';
import InlineForm from 'common/form/InlineForm';
import { SubSectionHeading } from 'common/layout/SectionHeadings';

import { polarisationMap } from '../../../craft/components/SpectrumDetails/LicenceSpectrumList';
import ReferenceFrequencyCard from '../../../craft/components/SpectrumDetails/ReferenceFrequencyCard';
import { polarisationOptions } from '../../../reference_data/spectrum_record/references';
import { spectrumDoneSchema } from './validationSchema';

const SpectrumDetailsList = ({
  spectrums,
  onRemoveSpectrum,
  onSaveSpectrum,
  readOnly,
  lastSaved,
}: {
  spectrums: SpectrumDto[];
  onRemoveSpectrum: (spectrumId: number) => Promise<any>;
  onSaveSpectrum: () => void;
  readOnly: boolean;
  lastSaved?: Date;
}) => {
  const [expandedId, setExpandedIdState] = useState<number>(-1);
  const setExpandedId = (id: number = -1) => {
    setExpandedIdState(id);
  };
  const expandedIndex = spectrums.findIndex((spectrum) => spectrum.id === expandedId);

  const [recentlySavedId, setRecentlySavedId] = React.useState<number | undefined>(undefined);

  const mode = readOnly ? 'VIEW' : 'EDIT';

  useEffect(() => {
    setRecentlySavedId(expandedId);
    // Collapse the opened view after saving.
    setExpandedId();
    // eslint-disable-next-line
  }, [lastSaved]);

  return (
    <>
      <SubSectionHeading title="Spectrum details" />
      <DetailsList
        id={'spectrum-details-table'}
        data-automation-id={'spectrum-details-table'}
        items={spectrums}
        columns={columns(expandedId, setExpandedId, onRemoveSpectrum)}
        renderExpandPanel={(item) => {
          const close = () => {
            setExpandedId();
          };
          return (
            <InlineForm
              id="inline-form"
              canEdit={false}
              hideBack
              hideFormButtonsTop
              showFormButtonsBottom
              onCancelEdit={close}
              onSubmit={onSaveSpectrum}
              disableErrorMessage
              mode={mode}
              additionalButtons={(mode) => (mode === 'VIEW' ? [{ id: `close-spectrum-${expandedIndex}`, text: 'Close', onClick: close }] : [])}
            >
              <SpectrumDetails />
            </InlineForm>
          );
        }}
        expandedIndex={expandedIndex}
        handleTabKey={FocusZoneTabbableElements.all}
        renderRowAsSuccess={(row) => ({ shouldRender: recentlySavedId === row.item.id, callback: () => setRecentlySavedId(undefined) })}
        renderRowAsError={mode === 'VIEW' ? undefined : (row) => !spectrumDoneSchema.isValidSync(row.item)}
      />
    </>
  );
};

const columns = (expandedId: number, setExpandedId: (id?: number) => void, onRemoveSpectrum: (spectrumId: number) => Promise<any>) =>
  [
    {
      key: 'lowerBound',
      name: 'Low (MHz)',
      isNumber: true,
      onRender: (item: SpectrumDto) => item.lowerBound?.toFixed(6),
      maxWidth: 100,
    },
    {
      key: 'upperBound',
      name: 'High (MHz)',
      isNumber: true,
      onRender: (item: SpectrumDto) => item.upperBound?.toFixed(6),
      maxWidth: 100,
    },
    {
      key: 'referenceFrequency',
      name: 'Ref. freq. (MHz)',
      isNumber: true,
      onRender: (item: SpectrumDto) => (item.frequencies ? item.frequencies[0]?.referenceFrequency?.toFixed(6) : ''),
      maxWidth: 100,
    },
    {
      key: 'power',
      name: 'Power dBW(eirp)',
      isNumber: true,
      onRender: (item: SpectrumDto) => (item.frequencies ? item.frequencies[0]?.power?.toFixed(1) : ''),
      maxWidth: 100,
    },
    {
      key: 'frequencyEmissions',
      name: 'Emission',
      onRender: (item: SpectrumDto) =>
        item.frequencies && item.frequencies[0]?.frequencyEmissions && item.frequencies[0]?.frequencyEmissions.length > 0
          ? item.frequencies[0]?.frequencyEmissions?.map((v: FrequencyEmissionDto) => v.emission).join(', ')
          : '',
      isMultiline: true,
    },
    {
      key: 'polarisation',
      name: 'Polarisation',
      onRender: (item: SpectrumDto) => (item.polarisation ? polarisationMap.get(item.polarisation) : undefined),
    },
    {
      key: 'action',
      fieldName: '',
      name: '',
      minWidth: 200,
      maxWidth: 200,
      isResizable: false,
      onRender: (item: SpectrumDto) =>
        !item.id ? undefined : (
          <>
            <ViewButton id={item.id} onClick={() => setExpandedId(item.id)} />
            <RemoveButton id={item.id} onClick={() => onRemoveSpectrum(item.id!).then(() => (item.id === expandedId ? setExpandedId() : {}))} />
          </>
        ),
    },
  ].map((col) => ({
    ...col,
    minWidth: col.minWidth ?? 15,
    maxWidth: col.maxWidth ?? 135,
    isResizable: col.isResizable === undefined,
    fieldName: col.key,
  }));

const RemoveButton = (props: { id: number; onClick: () => void }) => <InlineButton id={`remove-${props.id}`} text="Remove" onClick={props.onClick} />;

const ViewButton = (props: { id: number; onClick: () => void }) => <InlineButton id={`view-${props.id}`} text="View" onClick={props.onClick} />;

export const SpectrumDetails: React.FC = () => {
  const { values } = useFormikContext<{ spectrum?: SpectrumDto }>();
  return (
    <>
      <Grid.Row>
        <Grid.Col lg={3}>
          <NumberField label="Low (MHz)" name="spectrum.lowerBound" required maxLength={13} decimalDigit={6} />
        </Grid.Col>
        <Grid.Col lg={3}>
          <NumberField label="High (MHz)" name="spectrum.upperBound" required maxLength={13} decimalDigit={6} />
        </Grid.Col>
        <Grid.Col lg={3}>
          <Dropdown label="Polarisation" name="spectrum.polarisation" required options={[{ key: '', text: '' }, ...polarisationOptions]} />
        </Grid.Col>
      </Grid.Row>
      <SubSectionHeading title="Reference frequencies" />
      <ReferenceFrequencyCard
        name={'spectrum.frequencies[0]'}
        spectrum={values.spectrum!}
        isSatellite={true}
        licenceTypeCode={LicenceDtoLicenceTypeCodeEnum.RDL}
        isFreqReadOnly={false}
      />
    </>
  );
};

export default SpectrumDetailsList;
