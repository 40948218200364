import { GeoreferenceTypeEnum, LicenceLocationGeoreferenceDto } from 'api-client';

import { isEmpty } from '../validation/yupUtils';

export const validFormatEastingNorthing = (isLatNorhing?: boolean, type?: GeoreferenceTypeEnum) => (val: string) => {
  if (type === GeoreferenceTypeEnum.M || type === GeoreferenceTypeEnum.T) {
    if (/^\d{0,3}(\.\d{0,2})?$/.test(val) && val !== '.') {
      // '.' is a special case, handle it as zero
      return formatEastingNorthing(val, type);
    }
  } else if (type === GeoreferenceTypeEnum.D2000) {
    if (/^(-?\d*)(\.\d*)?$/.test(val) && val !== '.') {
      // it is a number
      const numberVal = Number.parseFloat(val);
      // check range.
      const isInRange = isLatNorhing ? numberVal >= -90 && numberVal <= 90 : numberVal >= -180 && numberVal <= 180;
      if (isInRange && /^(-?\d*)(\.\d{0,7})?$/.test(val)) {
        // no more than 7 decimal places.
        return formatEastingNorthing(val, type);
      }
    }
  } else {
    return formatEastingNorthing(val, type);
  }
  return val;
};

export const formatEastingNorthing = (val?: string, type?: GeoreferenceTypeEnum) => {
  if (val === undefined || isEmpty(val)) return '';
  if (type === GeoreferenceTypeEnum.M || type === GeoreferenceTypeEnum.T) {
    return Number.parseFloat(val).toFixed(2).padStart(6, '0');
  } else if (type === GeoreferenceTypeEnum.D2000) {
    return Number.parseFloat(val).toFixed(7);
  } else {
    return Number.parseFloat(val).toFixed(0);
  }
};

export const displayType = (type?: GeoreferenceTypeEnum) => {
  if (isEmpty(type)) return '';
  if (type === GeoreferenceTypeEnum.D2000) {
    return 'Lat/Long';
  } else {
    return 'East/North';
  }
};

export const byPriorityThenOrder = (g1: LicenceLocationGeoreferenceDto, g2: LicenceLocationGeoreferenceDto) =>
  g1.priority! * 10 - g2.priority! * 10 + g1.order! - g2.order!;

export const formatAltitude = (altitude?: number, unit?: string) => {
  if (altitude === undefined || isEmpty(altitude)) return '-';
  const unitDescription = unit === 'M' ? 'metres' : unit === 'K' ? 'kilometres' : '';
  return altitude + ' ' + unitDescription;
};
