import { Icon, INavLink, INavLinkGroup, INavStyles, IPanelStyles, mergeStyles, mergeStyleSets, Nav, Panel, useTheme } from '@fluentui/react';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuth } from 'common/auth/AuthHook';

import { ConfirmNavigateModal, dirtyFormConfirmStrategy, neverConfirmStrategy } from '../controls/inputs/ConfirmOnClick';
import Persona from '../controls/items/Persona';
import { MenuItem, MenuProps } from './DesktopNavBar';
import { NavigationContext } from './Navigation';
import usePage from './PageHook';
import border from './border.png';

interface Props {
  title: string;
  menus: MenuProps[];
}

const MobileNavBar: React.FC<Props> = (props) => {
  const { showMobileNav, setShowMobileNav } = usePage();
  const { formikContext, mode } = useContext(NavigationContext);
  const { semanticColors, palette } = useTheme();
  const panelStyles: Partial<IPanelStyles> = {
    main: { backgroundColor: semanticColors.menuHeader },
    navigation: { color: palette.white },
    subComponentStyles: {
      closeButton: {
        icon: { color: palette.white },
        rootHovered: { backgroundColor: semanticColors.menuHeader },
        rootPressed: { backgroundColor: semanticColors.menuHeader },
      },
    },
  };
  const className = mergeStyles({
    selectors: {
      // No way to set the hover color through navStyles
      'button:hover.ms-Button.ms-Button--action.ms-Button--command.ms-Nav-link': {
        backgroundColor: semanticColors.menuHeader,
      },
    },
  });
  const navStyles: Partial<INavStyles> = {
    // TODO hover is not working correctly, have tried all combinations, no luck - .compositeLink?
    root: { color: palette.white, backgroundColor: semanticColors.menuHeader },
    link: {
      backgroundColor: semanticColors.menuHeader,
      color: palette.white,
      ':hover': { color: palette.white, backgroundColor: semanticColors.menuHeader },
    },
    chevronButton: { color: palette.white, backgroundColor: semanticColors.menuHeader },
  };

  const [menuItemWasClickedProps, setMenuItemWasClickedProps] = useState<{
    onClick?: () => boolean | void;
  }>();

  const subMenuItems = (item: MenuItem) => {
    return item.subMenuItems && item.subMenuItems.length > 0
      ? {
          links: item.subMenuItems
            .filter((subItem) => subItem.show)
            .map((subItem) => {
              return {
                key: subItem.key,
                name: subItem.text ? subItem.text : '',
                url: '',
                onClick: () => {
                  setMenuItemWasClickedProps({ onClick: subItem.onClick });
                },
              } as INavLink;
            }),
        }
      : {};
  };

  const groups: INavLinkGroup[] = props.menus.map((menu) => {
    return {
      name: menu.label,
      collapseByDefault: true,
      automationId: menu.label,
      links: menu.items
        ? menu.items.map((item) => {
            return item.subMenuItems && item.subMenuItems.length > 0
              ? ({
                  key: item.key,
                  name: item.text ? item.text : '',
                  url: '',
                  ...subMenuItems(item),
                } as INavLink)
              : ({
                  key: item.key,
                  name: item.text ? item.text : '',
                  url: '',
                  onClick: () => {
                    setMenuItemWasClickedProps({ onClick: item.onClick });
                  },
                } as INavLink);
          })
        : [],
    };
  });

  return (
    <>
      <Panel styles={panelStyles} isOpen={showMobileNav} onDismiss={() => setShowMobileNav(false)} className={className}>
        <Persona />
        <Nav groups={groups} styles={navStyles} />
        <ConfirmNavigateModal
          confirmStrategy={formikContext && mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy}
          onClick={() => {
            if (menuItemWasClickedProps?.onClick) {
              menuItemWasClickedProps.onClick();
            }
            setShowMobileNav(false);
          }}
          onCancel={() => {
            setMenuItemWasClickedProps(undefined);
            setShowMobileNav(false);
          }}
          visible={!!menuItemWasClickedProps?.onClick}
          formikContext={formikContext!}
        />
      </Panel>
      <MobilePageTitle {...props} />
    </>
  );
};

const MobilePageTitle = ({ title }: { title: string }) => {
  const { semanticColors, palette } = useTheme();
  const { isCasualUser, loggedIn } = useAuth();
  const { push } = useHistory();

  const className = mergeStyleSets({
    container: {
      gridArea: 'title',
      background: `${semanticColors.menuHeader} url(${border}) repeat-x 0 bottom`,
    },
    homeIcon: {
      justifySelf: 'end',
      color: palette.white,
      padding: '5px',
    },
    title: {
      display: 'inline-block',
      color: palette.white,
    } as React.CSSProperties,
  });

  const handleNavigation = () => (!isCasualUser() && loggedIn ? push('/ui/app/dashboard') : push('/ui/'));

  return (
    <div className={className.container}>
      <Icon
        iconName="Home"
        className={className.homeIcon}
        onClick={handleNavigation}
        styles={{ root: { fontSize: '1rem', cursor: 'pointer' } }}
        title="Visit my RRF homepage"
      />
      <h3 className={className.title}>{title}</h3>
    </div>
  );
};

export default MobileNavBar;
