import {
  CraftingProgress,
  LicenceAuthoritiesDto,
  LicenceRadiationPatternDto,
  LicenceSpecificConditionsDto,
  MaintainReceiveLocationDto,
  MaintainSpectrumDto,
  MaintainTransmitLocationDto,
  PendingSpectrumId,
  RadioLicenceBasicDetailsDto,
  RadioLicenceDto,
  SpectrumDto,
  SpectrumLicenceBasicDetailsDto,
  SpectrumLicenceDto,
} from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { licenceApi, licenceCraftingApi } from 'common/api/api';
import { sortByReferenceFrequencies } from 'licence_management/LicenceApiHooks';

export const useLicenceCraftingApi = () => {
  const { aroundApi } = useApi();

  return {
    getCraftingConfig: (id: number, config?: ApiConfig) => aroundApi(licenceCraftingApi.getLicenceCraftingConfiguration(id), config),
    getTransmitLocations: (id: number, config?: ApiConfig) => aroundApi(licenceCraftingApi.getTransmitLocations(id), config),
    getReceiveLocations: (id: number, config?: ApiConfig) => aroundApi(licenceCraftingApi.getReceiveLocations(id), config),
    maintainTransmitLocation: (
      licenceId: number,
      configurationId: number,
      maintainTransmitLocationDto?: MaintainTransmitLocationDto,
      config?: ApiConfig,
    ) => aroundApi(licenceCraftingApi.maintainTransmitConfiguration(licenceId, configurationId, maintainTransmitLocationDto), config),
    maintainReceiveLocation: (
      licenceId: number,
      configurationId: number,
      maintainReceiveLocationDto?: MaintainReceiveLocationDto,
      config?: ApiConfig,
    ) => aroundApi(licenceCraftingApi.maintainReceiveConfiguration(licenceId, configurationId, maintainReceiveLocationDto), config),
    createTransmitLocation: (licenceId: number, locationId: number, licenceVersion?: string, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.createTransmitConfiguration(licenceId, locationId, { licenceId, locationId, licenceVersion }), config),
    createReceiveLocation: (licenceId: number, locationId: number, licenceVersion?: string, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.createReceiveConfiguration(licenceId, locationId, { licenceId, locationId, licenceVersion }), config),
    removeLicenceConfiguration: (licenceId: number, locationId: number, licenceVersion?: string, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.removeLicenceConfiguration(licenceId, locationId, { licenceId, locationId, licenceVersion }), config),
    maintainRadiationPatterns: (licenceId: number, radiationPatterns: LicenceRadiationPatternDto[], config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.maintainRadiationPatterns(licenceId, radiationPatterns), config),
    maintainRadioLicence: (id: number, radioLicence: RadioLicenceDto, config?: ApiConfig): Promise<RadioLicenceDto> =>
      aroundApi(licenceCraftingApi.maintainRadioLicence(id, radioLicence), config),
    maintainSpectrumLicence: (id: number, spectrumLicence: SpectrumLicenceDto, config?: ApiConfig): Promise<SpectrumLicenceDto> =>
      aroundApi(licenceCraftingApi.maintainSpectrumLicence(id, spectrumLicence), config),
    maintainSpectrum: (licenceId: number, spectrum: MaintainSpectrumDto, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.maintainSpectrum(licenceId, spectrum), config).then((spectrum) =>
        spectrum ? sortByReferenceFrequencies([spectrum])[0] : spectrum,
      ),
    cloneSpectrum: (licenceId: number, spectrumId: number, config?: ApiConfig): Promise<SpectrumDto | undefined> =>
      aroundApi(licenceCraftingApi.cloneSpectrum(licenceId, spectrumId), config).then((pendingSpectrum) =>
        aroundApi(licenceApi.getSpectrums(licenceId), config).then((spectrums: SpectrumDto[]) =>
          spectrums.find((spectrum) => spectrum!.id === pendingSpectrum!.id),
        ),
      ),
    removeSpectrum: (licenceId: number, spectrumId: number, config?: ApiConfig): Promise<PendingSpectrumId> =>
      aroundApi(licenceCraftingApi.removeSpectrum(licenceId, spectrumId), config),
    updateLicenceAuthorities: (licenceId: number, licenceAuthoritiesDto: LicenceAuthoritiesDto, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.updateLicenceAuthorities(licenceId, licenceAuthoritiesDto), config),
    updateLicenceConditions: (licenceId: number, licenceConditionsDto: LicenceSpecificConditionsDto, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.updateLicenceConditions(licenceId, licenceConditionsDto), config),
    updateCraftingProgress: (licenceId: number, craftingProgress: CraftingProgress, config?: ApiConfig) =>
      aroundApi(licenceCraftingApi.updateCraftingProgress(licenceId, craftingProgress), config),
    maintainRadioLicenceBasicDetails: (
      id: number,
      radioLicenceBasicDetails: RadioLicenceBasicDetailsDto,
      config?: ApiConfig,
    ): Promise<RadioLicenceDto> => aroundApi(licenceCraftingApi.maintainRadioLicenceBasicDetails(id, radioLicenceBasicDetails), config),
    maintainSpectrumLicenceBasicDetails: (
      id: number,
      spectrumLicenceBasicDetails: SpectrumLicenceBasicDetailsDto,
      config?: ApiConfig,
    ): Promise<SpectrumLicenceDto> => aroundApi(licenceCraftingApi.maintainSpectrumLicenceBasicDetails(id, spectrumLicenceBasicDetails), config),
  };
};
