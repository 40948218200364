import { Formik, FormikState } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import React from 'react';

import { SpectrumDto } from 'api-client';

import { useAuth } from '../../../common/auth/AuthHook';
import Form from '../../../common/form/Form';
import Page from '../../../common/layout/Page';
import { useReferenceDataHookApi } from '../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../ReferenceDataRouter';
import SpectrumRecordFields from './SpectrumRecordFields';
import { spectrumRecordSchema } from './validationSchema';

interface CreateSpectrumRecordPageContextProps {
  onSpectrumTypeChange: (key: any) => void;
  calculateBandWidth: (value: any) => void;
  showServiceType: boolean;
  isChannelORFrequency: boolean;
}

const defaultContextProps: CreateSpectrumRecordPageContextProps = {
  onSpectrumTypeChange: () => {},
  calculateBandWidth: () => {},
  showServiceType: true,
  isChannelORFrequency: false,
};

export const CreateSpectrumPageContext = React.createContext<CreateSpectrumRecordPageContextProps>(defaultContextProps);

const CreateSpectrumRecordPage = () => {
  const { createSpectrumRecord } = useReferenceDataHookApi();
  const { navigateToMaintainSpectrumRecord } = useLicenceReferenceDataNavigator();

  const { hasRole } = useAuth();

  if (hasRole('ROLE_APPROVED_RADIO_ENGINEER') || hasRole('ROLE_APPROVED_RADIO_CERTIFIER')) {
    window.location.href = '/ui/forbidden';
    return <></>;
  }

  const submitForm = (values: any) =>
    createSpectrumRecord({ ...values, label: values.label?.toUpperCase() } as SpectrumDto, {
      successMessage: 'Spectrum Record has been created.',
    }).then((spec) => {
      if (spec) {
        navigateToMaintainSpectrumRecord(spec.id!);
      }
    });

  const formInitValue = {
    spectrumType: undefined,
    levelOneServiceId: undefined,
    spectrumStatus: 'Planned',
    ituAllocationServiceId: undefined,
    label: '',
    lowerBound: '',
    upperBound: '',
    polarisation: undefined,
    generalPurpose: 'true',
    startDate: undefined,
    endDate: undefined,
    remarks: '',
    accessCode: '',
    frequencies: [],
  };

  const renderForm = (helper: FormikHelpers<any> & FormikState<any>) => {
    return (
      <>
        <Form id={'create-spectrum-record-form'} showFormButtonsBottom mode="CREATE">
          <SpectrumRecordFields />
        </Form>
      </>
    );
  };

  return (
    <Page title="Create Spectrum Record">
      <Formik initialValues={formInitValue} onSubmit={submitForm} validationSchema={spectrumRecordSchema}>
        {renderForm}
      </Formik>
    </Page>
  );
};

export default CreateSpectrumRecordPage;
