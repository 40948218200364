import React, { useEffect } from 'react';

import { LicenceRadiationPatternDto } from 'api-client';

import {
  DEGREE_LINE_COLOUR,
  DEGREE_LINE_WIDTH,
  drawTooltip,
  getCanvasHeight,
  getCanvasWidth,
  getPatternDescription,
  OUTER_CIRCLE_LINE_COLOUR,
  OUTER_CIRCLE_LINE_WIDTH,
  scaleCanvas,
  SECTOR_CIRCLE_LINE_COLOUR,
  SECTOR_CIRCLE_LINE_WIDTH,
  SECTOR_VALUE_FONT,
  SECTOR_VALUE_FONT_COLOUR,
  SECTOR_VALUE_LINE_COLOUR,
  SECTOR_VALUE_LINE_WIDTH,
  setupRadiationPatternChartVars,
  TEN_DEGREE_FONT,
  TEN_DEGREE_FONT_COLOUR,
  TEN_DEGREE_LINE_WIDTH,
} from './radiationPatternUtils';

const MIN_WIDTH = 250;
const MAX_WIDTH = 500;

interface HorizontalRadiationPatternChartProps {
  radiationPatterns: LicenceRadiationPatternDto[];
}

const HorizontalRadiationPatternChart: React.FC<HorizontalRadiationPatternChartProps> = ({ radiationPatterns }) => {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const tooltipCanvas = React.useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    drawGraph();
  }, [canvas.current?.parentElement?.clientWidth]);

  function drawGraph() {
    if (radiationPatterns.length === 0 || !canvas.current) {
      return;
    }

    const { numSectors, sectorValues, sectorRatios, ratios } = setupRadiationPatternChartVars(radiationPatterns);
    const currentCanvas = canvas.current as HTMLCanvasElement;
    const ctx = scaleCanvas(currentCanvas);

    const size = getCanvasSize();
    const centre = size / 2;
    const radius = centre - 20;

    //Outer Circle
    ctx.beginPath();
    ctx.strokeStyle = OUTER_CIRCLE_LINE_COLOUR;
    ctx.lineWidth = OUTER_CIRCLE_LINE_WIDTH;
    ctx.arc(centre, centre, radius, 0, Math.PI * 2, true);
    ctx.stroke();

    //Degree Lines
    ctx.save();
    ctx.translate(centre, centre);
    ctx.strokeStyle = DEGREE_LINE_COLOUR;
    for (let i = 0; i < 360; i++) {
      ctx.beginPath();
      ctx.moveTo(0, 0);
      if (i % 10 === 0) {
        ctx.lineWidth = TEN_DEGREE_LINE_WIDTH;
      } else {
        ctx.lineWidth = DEGREE_LINE_WIDTH;
      }
      ctx.lineTo(0, -radius);
      ctx.stroke();
      ctx.rotate(Math.PI / 180);
    }
    ctx.restore();

    //Degree Text
    ctx.save();
    ctx.translate(centre, centre);
    ctx.font = TEN_DEGREE_FONT;
    ctx.fillStyle = TEN_DEGREE_FONT_COLOUR;
    for (let i = 0; i < 36; i++) {
      ctx.fillText((i * 10).toString(), 0 - ctx.measureText((i * 10).toString()).width / 2, (radius + 10) * -1);
      ctx.rotate(Math.PI / 18);
    }
    ctx.restore();

    //Sector Circles
    ctx.save();
    for (let i = 0; i < numSectors; i++) {
      ctx.beginPath();
      ctx.strokeStyle = SECTOR_CIRCLE_LINE_COLOUR;
      ctx.lineWidth = SECTOR_CIRCLE_LINE_WIDTH;
      ctx.arc(centre, centre, radius * sectorRatios[i], 0, Math.PI * 2, true);
      ctx.stroke();
    }
    ctx.restore();

    //Value Text
    ctx.font = SECTOR_VALUE_FONT;
    ctx.fillStyle = SECTOR_VALUE_FONT_COLOUR;
    for (let i = 0; i < numSectors; i++) {
      ctx.fillText(sectorValues[i].toString(), centre - ctx.measureText(sectorValues[i].toString()).width - 5, centre - 2 - radius * sectorRatios[i]);
    }

    //Value Arcs
    ctx.strokeStyle = SECTOR_VALUE_LINE_COLOUR;
    ctx.lineWidth = SECTOR_VALUE_LINE_WIDTH;
    ctx.translate(centre, centre);
    ctx.rotate(Math.PI / -2);
    ctx.beginPath();
    radiationPatterns.forEach((radiationPattern, i) => {
      ctx.arc(0, 0, radius * ratios[i], (radiationPattern.bearingFrom / 360) * Math.PI * 2, (radiationPattern.bearingTo / 360) * Math.PI * 2, false);
    });
    ctx.closePath();
    ctx.stroke();

    if (!tooltipCanvas?.current) {
      return;
    }
    const tooltipCtx = scaleCanvas(tooltipCanvas.current);
    tooltipCtx.translate(centre, centre);
    tooltipCtx.rotate(Math.PI / -2);
    tooltipCanvas.current.addEventListener('mousemove', (event) => {
      // Check whether point is inside circle
      // Store the current transformation matrix
      tooltipCtx.save();
      // Use the identity matrix while clearing the canvas
      tooltipCtx.setTransform(1, 0, 0, 1, 0, 0);
      tooltipCtx.clearRect(0, 0, tooltipCanvas.current!.width, tooltipCanvas.current!.height);
      // Restore the transform
      tooltipCtx.restore();

      let i = 0;
      for (const radiationPattern of radiationPatterns) {
        tooltipCtx.beginPath();
        tooltipCtx.moveTo(0, 0);
        tooltipCtx.arc(
          0,
          0,
          radius * ratios[i],
          (radiationPattern.bearingFrom / 360) * Math.PI * 2,
          (radiationPattern.bearingTo / 360) * Math.PI * 2,
          false,
        );
        const position = {
          x: event.offsetX * window.devicePixelRatio,
          y: event.offsetY * window.devicePixelRatio,
        };
        const isPointInPath = tooltipCtx.isPointInPath(position.x, position.y);
        if (isPointInPath) {
          tooltipCtx.fillStyle = SECTOR_VALUE_LINE_COLOUR;
          tooltipCtx.fill();
          drawTooltip(tooltipCtx, event.offsetX, event.offsetY, radiationPattern, size);
        }
        i++;
      }
    });
  }

  function getCanvasSize() {
    return Math.max(MIN_WIDTH, Math.min(MAX_WIDTH, canvas.current?.parentElement?.clientWidth ?? MAX_WIDTH));
  }

  const dimensions = getCanvasSize();

  return (
    <div data-automation-id="horizontalRadiationPatternChart" style={{ position: 'relative' }}>
      <canvas
        ref={canvas}
        height={getCanvasHeight(dimensions)}
        width={getCanvasWidth(dimensions)}
        style={{ height: `${dimensions}px`, width: `${dimensions}px` }}
        role="img"
        aria-label={`A circular chart displaying the radiation patterns bearing from and to, and their values. The values are: ${getPatternDescription(
          radiationPatterns,
        )}`}
      />
      <canvas
        ref={tooltipCanvas}
        height={getCanvasHeight(dimensions)}
        width={getCanvasWidth(dimensions)}
        style={{ height: `${dimensions}px`, width: `${dimensions}px`, position: 'absolute', left: 0 }}
        role="img"
        aria-label={`A canvas for displaying tooltips over the above chart.`}
      />
    </div>
  );
};

export default HorizontalRadiationPatternChart;
