import { Formik } from 'formik';
import React from 'react';

import { useAuth } from 'common/auth/AuthHook';
import { getPreferenceGeoReferenceType } from 'common/user/components/UserPreference';
import { isNotEmpty } from 'common/validation/yupUtils';
import { parseQueryString } from 'search/utils';

import Form from '../../../../common/form/Form';
import Page from '../../../../common/layout/Page';
import { validateWithFormValues } from '../../../../common/validation/validationWithFormValues';
import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../../ReferenceDataRouter';
import MultipointFields from '../components/MultipointFields';
import { reOrderGeoReferenceIndex } from '../utils';
import { createMultipointSchema } from '../validationSchema';

const CreateMultiPointPage = () => {
  const { createMultipoint } = useReferenceDataHookApi();
  const { navigateToMaintainLocation } = useLicenceReferenceDataNavigator();

  const qInitialValue = parseQueryString({});
  const { userPreference } = useAuth();
  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  const formValue: any = {
    locationName: '',
    georeferenceType: isNotEmpty(qInitialValue.type) ? qInitialValue.type : geoRefTypeUserPreference,
    georeferences: [
      {
        origin: {
          original: true,
          type: isNotEmpty(qInitialValue.type) ? qInitialValue.type : geoRefTypeUserPreference,
          northing: qInitialValue.northing || '',
          easting: qInitialValue.easting || '',
          mapNumber: qInitialValue.mapNumber || '',
          order: 1,
        },
      },
      {
        origin: {
          original: true,
          type: isNotEmpty(qInitialValue.type) ? qInitialValue.type : geoRefTypeUserPreference,
          northing: '',
          easting: '',
          mapNumber: '',
          order: 2,
        },
      },
      {
        origin: {
          original: true,
          type: isNotEmpty(qInitialValue.type) ? qInitialValue.type : geoRefTypeUserPreference,
          northing: '',
          easting: '',
          mapNumber: '',
          order: 3,
        },
      },
    ],
  };

  return (
    <Page title="Create Location">
      <Formik
        initialValues={formValue}
        onSubmit={(value: any) => {
          return createMultipoint(reOrderGeoReferenceIndex(value), {
            successMessage: 'Location has been created.',
          }).then((v) => {
            navigateToMaintainLocation(v.id!);
          });
        }}
        enableReinitialize
        validateOnMount
        validate={validateWithFormValues(createMultipointSchema, {})}
      >
        <Form id={'create-multi-point-form'} showFormButtonsBottom mode={'CREATE'}>
          <MultipointFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreateMultiPointPage;
