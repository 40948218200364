import React from 'react';

import { FrequencyEmissionDto } from '../../../../gen/api/api';
import TagPicker from '../../../common/controls/inputs/TagPicker';

const Emissions = ({ name, requiredEmissions }: { name: string; requiredEmissions: boolean }) => {
  return (
    <TagPicker
      label="Emissions"
      name={name}
      required={requiredEmissions}
      onResolveSuggestions={() => []}
      allowFreeForm
      fieldValueToTag={(value: FrequencyEmissionDto) => ({ key: value.id ?? value.emission, name: value.emission })}
      tagToFieldValue={(tag) => ({ id: '' + tag.key !== tag.name ? tag.key : undefined, emission: tag.name })}
      inputToFieldValue={(emission) => ({ emission: emission.toUpperCase() })}
    />
  );
};

export default Emissions;
