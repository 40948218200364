import { DefaultButton, Label, Text } from '@fluentui/react';
import * as React from 'react';

import { EmissionLimitDto } from 'api-client';

import { Grid } from 'ui-library';

const UnwantedEmissionLimitsView = ({
  unwantedEmissionLimits,
  lowerBound,
  upperBound,
  onViewUelPts,
  onViewGraph,
}: {
  unwantedEmissionLimits?: EmissionLimitDto[];
  lowerBound: number;
  upperBound: number;
  onViewUelPts?: () => void;
  onViewGraph?: () => void;
}) => {
  const byGraphPoint = (a: { graphPoint: string }, b: { graphPoint: string }) => a.graphPoint?.localeCompare(b.graphPoint);

  const belowLowerUels = unwantedEmissionLimits?.filter((uel) => uel.limitFrequency <= lowerBound).sort(byGraphPoint);
  const aboveUpperUels = unwantedEmissionLimits?.filter((uel) => uel.limitFrequency >= upperBound).sort(byGraphPoint);

  return (
    <>
      <Grid.Row>
        <Grid.Col lg={6}>
          <Label>Below lower boundary</Label>
        </Grid.Col>
        <Grid.Col lg={6} style={{ textAlign: 'right', display: 'inline-block' }}>
          {onViewUelPts && <DefaultButton id="uel-points-button" text="UEL Points" onClick={onViewUelPts} />}
          {onViewGraph && <DefaultButton id="uel-graph-button" text="Graph" style={{ marginLeft: 10 }} onClick={onViewGraph} />}
        </Grid.Col>
      </Grid.Row>
      <FromToHeader />
      <div data-automation-id="below-lower-boundary-uels">
        {belowLowerUels?.length === 1 ? (
          <UnwantedEmissionLimitRow from={belowLowerUels[0]} to={belowLowerUels[0]} />
        ) : (
          belowLowerUels?.map((uel, i) =>
            i < belowLowerUels.length - 1 ? <UnwantedEmissionLimitRow from={uel} to={belowLowerUels[i + 1]} /> : <></>,
          )
        )}
      </div>
      <Grid.Row>
        <Grid.Col lg={6} style={{ height: 32 }}>
          <Label>Above upper boundary</Label>
        </Grid.Col>
      </Grid.Row>
      <FromToHeader />
      <div data-automation-id="above-upper-boundary-uels">
        {aboveUpperUels?.length === 1 ? (
          <UnwantedEmissionLimitRow from={aboveUpperUels[0]} to={aboveUpperUels[0]} />
        ) : (
          aboveUpperUels?.map((uel, i) =>
            i < aboveUpperUels.length - 1 ? <UnwantedEmissionLimitRow from={uel} to={aboveUpperUels[i + 1]} key={uel.id} /> : <></>,
          )
        )}
      </div>
    </>
  );
};

const FromToHeader = () => (
  <Grid.Row style={{ textAlign: 'right' }}>
    <Grid.Col lg={1}>
      <Label>FROM</Label>
    </Grid.Col>
    <Grid.Col lg={2} md={6} sm={6}>
      <Label>eirp (dBW) at</Label>
    </Grid.Col>
    <Grid.Col lg={2} md={6} sm={6}>
      <Label>Frequency (MHz)</Label>
    </Grid.Col>
    <Grid.Col lg={1}>
      <Label>TO</Label>
    </Grid.Col>
    <Grid.Col lg={2} md={6} sm={6}>
      <Label>eirp (dBW) at</Label>
    </Grid.Col>
    <Grid.Col lg={2} md={6} sm={6}>
      <Label>Frequency (MHz)</Label>
    </Grid.Col>
  </Grid.Row>
);

const UnwantedEmissionLimitRow = ({ from, to }: { from: EmissionLimitDto; to: EmissionLimitDto }) => (
  <Grid.Row style={{ textAlign: 'right' }}>
    <Grid.Col lg={1} />
    <Grid.Col lg={2} md={6} sm={6}>
      <Text data-automation-id={`from-limitValue`}>{(+from.limitValue).toFixed(1)}</Text>
    </Grid.Col>
    <Grid.Col lg={2} md={6} sm={6}>
      <Text data-automation-id={`from-limitFrequency`}>{(+from.limitFrequency).toFixed(6)}</Text>
    </Grid.Col>
    <Grid.Col lg={1} />
    <Grid.Col lg={2} md={6} sm={6}>
      <Text data-automation-id={`to-limitValue`}>{(+to.limitValue).toFixed(1)}</Text>
    </Grid.Col>
    <Grid.Col lg={2} md={6} sm={6}>
      <Text data-automation-id={`to-limitFrequency`}>{(+to.limitFrequency).toFixed(6)}</Text>
    </Grid.Col>
  </Grid.Row>
);

export default UnwantedEmissionLimitsView;
