import { mergeStyleSets } from '@fluentui/merge-styles';
import React from 'react';

import { RRFTheme } from 'common/theme/RRFTheme';

export interface IComponentClassNames {
  root: string;
  header: string;
  headerTitle: string;
  complete: string;
  inComplete: string;
  error: string;
  title: string;
  content: string;
  chevron: string;
  doneBtn: string;
  unchangeLabel: string;
}

export const getClassNames = (theme: RRFTheme, expanded?: boolean, disabled?: boolean): IComponentClassNames =>
  mergeStyleSets({
    root: { cursor: disabled ? 'auto' : 'pointer' } as React.CSSProperties,
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '56px',
      backgroundColor: disabled
        ? theme.semanticColors.buttonBackgroundDisabled
        : expanded
        ? theme.semanticColors.accordionHeaderExpanded
        : theme.semanticColors.accordionHeaderCollapsed,
      border: expanded ? 'solid 1px #e3eef6' : 'solid 1px #e2e2e2',
      '&:hover': {
        backgroundColor: theme.semanticColors.accordionHeaderHover,
      },
    } as React.CSSProperties,
    headerTitle: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    } as React.CSSProperties,
    complete: {
      color: '#18a437',
      fontSize: '16px',
      marginLeft: '20px',
      fontWeight: 'bold',
    } as React.CSSProperties,
    inComplete: {
      fontSize: '16px',
      marginLeft: '20px',
      fontWeight: 'bold',
    } as React.CSSProperties,
    error: {
      color: '#ff6900',
      fontSize: '16px',
      marginLeft: '20px',
      fontWeight: 'bold',
    } as React.CSSProperties,
    title: {
      marginLeft: '10px',
      color: disabled ? theme.semanticColors.buttonTextDisabled : expanded ? '#262626' : '#5d9ac6',
      cursor: disabled ? 'auto' : 'pointer',
    } as React.CSSProperties,
    unchangeLabel: {
      marginRight: '50px',
      marginLeft: 'auto',
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    doneBtn: {
      marginRight: '50px',
      marginLeft: 'auto',
    },
    chevron: {
      marginRight: '10px',
    } as React.CSSProperties,
    content: {
      padding: '10px',
      border: 'solid 1px #e2e2e2',
      backgroundColor: theme.semanticColors.accordionContent,
    } as React.CSSProperties,
  });
