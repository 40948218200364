import * as React from 'react';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Dropdown from 'common/controls/inputs/Dropdown';
import SearchForm from 'search/SearchForm';
import { hiddenOptions } from 'search/utils';

import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';

interface Props {
  onClear?: () => void;
  showAddSpectrumMaskButton?: boolean;
}

const SearchSpectrumMaskForm: React.FC<Props> = (props) => {
  const { hasPermission, isInternalUser } = useAuth();
  const { navigateToCreateSpectrumMask } = useLicenceReferenceDataNavigator();

  const addSpectrumMaskButtonProps =
    props.showAddSpectrumMaskButton && hasPermission('CREATE_SPECTRUM_MASK')
      ? [{ id: 'add-spectrum-mask', text: 'Add a new Spectrum Mask', onClick: navigateToCreateSpectrumMask }]
      : undefined;

  return (
    <>
      <SearchForm
        id="search-spectrum-mask-form"
        mode="STAND_ALONE"
        title="Search spectrum mask"
        searchBoxProps={{
          placeholder: 'Identifier or Description',
          hint: 'You can search by Identifier or Description.',
          required: true,
        }}
        onClear={props.onClear}
        additionalButtons={addSpectrumMaskButtonProps}
      >
        {isInternalUser && (
          <Grid.Row>
            <Grid.Col sm={12} lg={4}>
              <Dropdown label="Spectrum mask visibility" name="hidden" options={hiddenOptions} />
            </Grid.Col>
          </Grid.Row>
        )}
      </SearchForm>
    </>
  );
};

export default SearchSpectrumMaskForm;
