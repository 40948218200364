import Page from '../../../../common/layout/Page';
import { Formik } from 'formik';
import React from 'react';
import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import DefinedAreaFields from '../components/DefinedAreaFields';
import { createDefinedAreaSchema } from '../validationSchema';
import Form from '../../../../common/form/Form';
import { useLicenceReferenceDataNavigator } from '../../ReferenceDataRouter';
import { useAuth } from '../../../../common/auth/AuthHook';

const CreateDefinedAreaPage = () => {
  const { createDefinedArea } = useReferenceDataHookApi();
  const { navigateToMaintainLocation } = useLicenceReferenceDataNavigator();
  const initValue = {
    nameQualifier: undefined,
    locationName: undefined,
    districts: [],
  };

  const { isInternalUser } = useAuth();

  if (!isInternalUser) {
    window.location.href = '/ui/forbidden';
    return <></>;
  }

  return (
    <Page title="Create Location">
      <Formik
        initialValues={initValue}
        onSubmit={(value: any) => {
          return createDefinedArea(value, {
            successMessage: 'Location has been created.',
          }).then((v) => {
            navigateToMaintainLocation(v.id!);
          });
        }}
        validationSchema={createDefinedAreaSchema}
      >
        <Form id={'create-defined-area-form'} showFormButtonsBottom mode={'CREATE'}>
          <DefinedAreaFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreateDefinedAreaPage;
