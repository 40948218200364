import { useDispatch, useSelector } from 'react-redux';

import { useApi } from 'common/api/ApiHook';
import { featureFlagApi } from 'common/api/api';
import { containsAny } from 'common/utils/arrayUtils';

import { StoreState } from '../../store';
import { setRoleClientProfile } from '../actions/loginActions';
import { FeatureFlag } from './FeatureFlags';
import { Permission } from './Permissions';
import { Role } from './Roles';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { aroundApi } = useApi();

  const {
    loggedIn,
    username,
    isInternalUser,
    myUserId,
    currentClientId,
    isKeyClient,
    firstName,
    lastName,
    currentRole,
    clientNameProfile: currentClientName,
    authorityType,
    adminAccess,
    myRolesAvailables,
    agreedViewSuppressed,
    userPreference,
    isLocked,
    featureFlags,
  } = useSelector((state: StoreState) => state.loginState);

  const permissions = useSelector((store?: StoreState) => store?.loginState.permissions);
  const hasPermission = (permission?: Permission) => (permission ? permissions?.find((perm) => perm === permission) !== undefined : false);

  const hasAnyPermission = (perms: Permission[]) => containsAny(permissions, perms);
  const hasRole = (role?: Role) => role === currentRole;
  const hasAnyRole = (roles: Role[]) =>
    roles.includes(
      currentRole as
        | 'ROLE_APPROVED_RADIO_CERTIFIER'
        | 'ROLE_APPROVED_RADIO_ENGINEER'
        | 'ROLE_APPROVED_RADIO_EXAMINER'
        | 'ROLE_CASUAL_USER'
        | 'ROLE_CLIENT'
        | 'ROLE_INVESTIGATIONS_MANAGER'
        | 'ROLE_LICENSING_MANAGER'
        | 'ROLE_LICENSING_OFFICER'
        | 'ROLE_MSG_SERVICE_CENTRE'
        | 'ROLE_RADIO_INVESTIGATOR'
        | 'ROLE_RSM_RADIO_ENGINEER'
        | 'ROLE_SENIOR_RADIO_INVESTIGATOR'
        | 'ROLE_SYSTEM_ADMINISTRATOR'
        | 'ROLE_PRIVATE_BAND_MANAGER'
        | 'ROLE_CROWN_BAND_MANAGER'
        | 'ROLE_RIGHT_HOLDER'
        | 'ROLE_GENERAL_USER'
        | 'ROLE_REGISTRAR'
        | 'ROLE_SPECIAL_USER'
        | 'ROLE_LICENSING_AGENCY'
        | 'ROLE_CAVEATOR'
        | 'ROLE_MORTGAGOR'
        | 'ROLE_MORTGAGEE',
    );
  const isCasualUser = () => hasRole('ROLE_CASUAL_USER');
  const isFeatureFlagEnabled = (featureFlag: FeatureFlag) =>
    featureFlags ? featureFlags.findIndex((flag) => flag.name === featureFlag && flag.enabled) !== -1 : false;

  const enableFeatureFlag = (featureFlag: FeatureFlag) => {
    return aroundApi(featureFlagApi.enableFeatureFlag(featureFlag), { showLoadingSpinner: 'Enabeling flag...' });
  };

  const disableFeatureFlag = (featureFlag: FeatureFlag) => {
    return aroundApi(featureFlagApi.disableFeatureFlag(featureFlag), { showLoadingSpinner: 'Disabling flag...' });
  };

  const hasFloatingRole = (role?: Role) => role === currentRole && !currentClientId;
  const isFloatingARE = () => hasFloatingRole('ROLE_APPROVED_RADIO_ENGINEER');

  const myUserFullName = `${firstName} ${lastName}`;

  const hasEADAOnAKeyClient =
    myRolesAvailables?.findIndex(
      (availableRole) =>
        availableRole.clientId !== undefined &&
        availableRole.isKeyClient &&
        (availableRole.authorityType === 'DA' || availableRole.authorityType === 'EA'),
    ) !== -1;

  return {
    loggedIn,
    username,
    isInternalUser,
    isCasualUser,
    myUserId,
    currentClientId,
    isKeyClient,
    hasPermission,
    hasAnyPermission,
    hasRole,
    hasAnyRole,
    hasFloatingRole,
    firstName,
    lastName,
    currentRole,
    currentClientName,
    authorityType,
    adminAccess,
    myRolesAvailables,
    agreedViewSuppressed,
    refreshJWT: (newJWT: string) => dispatch(setRoleClientProfile(newJWT)),
    userPreference,
    myUserFullName,
    isLocked,
    featureFlags,
    isFeatureFlagEnabled,
    enableFeatureFlag,
    disableFeatureFlag,
    isFloatingARE,
    hasEADAOnAKeyClient,
  };
};
