import { FieldAttributes, useField } from 'formik';
import * as React from 'react';
import Error from '../items/Error';

interface Props extends FieldAttributes<any> {
  errorMessage?: string;
  showIcon?: boolean;
  alwaysValidate?: boolean;
}

const ErrorLabel: React.FC<Props> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [_, meta] = useField(props as any);

  return (props.alwaysValidate || meta.touched) && (typeof meta.error === 'string' || props.errorMessage) ? (
    <Error errorMessage={meta.error || props.errorMessage} showIcon={props.showIcon} />
  ) : (
    <></>
  );
};

export default ErrorLabel;
