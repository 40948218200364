import { MessageBarType } from '@fluentui/react';
import React from 'react';

import { AuditAppealProcessTypeEnum } from 'api-client';

import { DialogForm, Dropdown, Grid2 } from 'ui-library';

import { useAuditApi } from 'investigation/audit/hooks';
import { AuditDialogProps } from 'investigation/interfaces';

import { useAppRouter } from '../../../Routes';
import usePage from '../../../common/layout/PageHook';

interface AuditClientAppealedFormProps {
  appealProcessType: AuditAppealProcessTypeEnum;
}

const AuditClientAppealedDialog = (props: AuditDialogProps) => {
  const { appealAuditApi } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  return (
    <DialogForm<AuditClientAppealedFormProps>
      buttonProps={{
        cancelClicked: () => props.setIsOpen(false),
        okClicked: (values) => {
          appealAuditApi({
            id: props.caseDetails.caseNumber || 0,
            appealProcessType: values.appealProcessType,
          }).then(() => {
            props.setIsOpen(false);
            navigateToDashboard();
            setPageInstruction('Client appeal successfully registered.', MessageBarType.success);
          });
        },
      }}
      id="AuditClientAppealedDialog"
      isOpen={props.isOpen}
      title="Client appealed"
      validationSchema={[{ name: 'appealProcessType' }]}
    >
      <Grid2>
        <Grid2.Col>
          <Dropdown
            label="Requested process"
            name="appealProcessType"
            options={[
              { key: AuditAppealProcessTypeEnum.HEARING, text: 'Hearing' },
              { key: AuditAppealProcessTypeEnum.REVIEW, text: 'Review' },
            ]}
            required
          />
        </Grid2.Col>
      </Grid2>
    </DialogForm>
  );
};

export default AuditClientAppealedDialog;
