import { IDropdownOption } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { LicenceTypeDto, SelectedServiceLevels, ServiceTypeDto } from 'api-client';

import { Grid } from 'ui-library';

import Dropdown from '../../../common/controls/inputs/Dropdown';
import { findServices, licenceTypeToKeyValue, serviceTypeToKeyValue } from '../../../licence_management/apply/utils';
import { asOptions } from '../../utils/dropdownUtils';

export interface ServiceLevelSelectorProps {
  onLevel4Change: (key: any, licenceTypes: LicenceTypeDto[], level1Name: any) => void;
  serviceTypes: ServiceTypeDto[];
  level1: IDropdownOption[];
  level2?: IDropdownOption[];
  setNextLevelToValidate: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedLevels?: SelectedServiceLevels;
  canEditLicenceType?: boolean;
  canEditForCopy?: boolean;
}

const ServiceLevelSelector: React.FC<ServiceLevelSelectorProps> = (props) => {
  const formikContext = useFormikContext<any>();

  const [showLevel2, setShowLevel2] = useState<boolean>(false);
  const [showLevel3, setShowLevel3] = useState<boolean>(false);
  const [showLevel4, setShowLevel4] = useState<boolean>(false);

  const [level2, setLevel2] = useState<IDropdownOption[]>([]);
  const [level3, setLevel3] = useState<IDropdownOption[]>([]);
  const [level4, setLevel4] = useState<IDropdownOption[]>([]);
  const disableLevels = props.canEditForCopy ? false : !!props.selectedLevels;
  const disableLevel4 = props.canEditForCopy ? false : props.canEditLicenceType ? false : !!props.selectedLevels;
  let [licenceTypes, setLicenceTypesState] = useState<LicenceTypeDto[]>([]);
  const setLicenceTypes = (_licenceTypes: LicenceTypeDto[]) => {
    setLicenceTypesState(_licenceTypes);
    licenceTypes = _licenceTypes;
  };

  const licenceTypeId = formikContext.values.licenceTypeId;
  useEffect(() => {
    if (!licenceTypeId) {
      // Trigger this callback, to reset/hide the Management Right or Preferred Bands section as e.g. the user has changed
      // his/her mind on the licence type.
      props.onLevel4Change(undefined, [], undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenceTypeId]);

  useEffect(() => {
    if (props.selectedLevels && !formikContext.values.level1) {
      formikContext.setFieldValue('level1', props.selectedLevels?.serviceLevel1?.description, false);
      if (props.selectedLevels?.serviceLevel1) {
        handleLevel1Change(props.selectedLevels.serviceLevel1.description);
        formikContext.setFieldValue('level1', props.selectedLevels.serviceLevel1?.description, false);
        formikContext.setFieldValue('level2', props.selectedLevels.serviceLevel2?.description, false);
      }

      if (props.selectedLevels?.serviceLevel2) {
        handleLevel2Change(props.selectedLevels?.serviceLevel2.description);
        formikContext.setFieldValue('level3', props.selectedLevels.serviceLevel3?.description, false);
      }

      if (props.selectedLevels?.serviceLevel3) {
        handleLevel3Change(props.selectedLevels?.serviceLevel3.description);
      }

      formikContext.setFieldValue('licenceTypeId', props.selectedLevels?.serviceLevel4?.id + '', false);
      formikContext.setFieldValue('licenceTypeName', props.selectedLevels?.serviceLevel4?.description, false);
      formikContext.setFieldValue('licenceTypeDisplayCode', props.selectedLevels?.serviceLevel4?.code, false);
      setShowLevel4(true);
      props.onLevel4Change(licenceTypeId, licenceTypes, props.selectedLevels.serviceLevel1?.description);
    } else {
      handleLevel1Change(props.selectedLevels?.serviceLevel1?.description);
      handleLevel2Change(props.selectedLevels?.serviceLevel2?.description);
      if (formikContext.values.licenceTypeId !== 1) {
        handleLevel3Change(props.selectedLevels?.serviceLevel3?.description);
      } else {
        handleLevel3Change(formikContext.values.level3);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLevels, formikContext.initialValues]);

  const handleLevel1Change = (key: any) => {
    const level1Service = findServices(props.serviceTypes, key);
    if (level1Service?.nextLevelServices) {
      const level2services = level1Service?.nextLevelServices.map(serviceTypeToKeyValue);
      //TODO put setShowLevelX into form props?
      setShowLevel2(true);
      setShowLevel3(false);
      setShowLevel4(false);
      props.setNextLevelToValidate('LEVEL2');

      setLevel2(asOptions(level2services, true, true));
    } else if (level1Service?.licenceTypes) {
      const licenceOptions = level1Service.licenceTypes.map(licenceTypeToKeyValue);
      setLicenceTypes(level1Service.licenceTypes);
      setShowLevel2(false);
      setShowLevel3(false);
      setShowLevel4(true);
      props.setNextLevelToValidate('LEVEL4');

      setLevel4(asOptions(licenceOptions, true, true));
    }
  };

  const handleLevel2Change = (key: any) => {
    const level1pick = formikContext.values.level1 || props.selectedLevels?.serviceLevel1?.description;
    const level2service = findServices(props.serviceTypes, level1pick, key);

    if (level2service?.nextLevelServices) {
      const level3services = level2service?.nextLevelServices.map(serviceTypeToKeyValue);
      setShowLevel3(true);
      setShowLevel4(false);
      props.setNextLevelToValidate('LEVEL3');

      setLevel3(asOptions(level3services, true, true));
    } else if (level2service?.licenceTypes) {
      const licenceOptions = level2service.licenceTypes.map(licenceTypeToKeyValue);
      setLicenceTypes(level2service.licenceTypes);
      setShowLevel3(false);
      setShowLevel4(true);
      props.setNextLevelToValidate('LEVEL4');

      setLevel4(asOptions(licenceOptions, true, true));
    }
  };

  const handleLevel3Change = (key: any) => {
    const level1pick = formikContext.values.level1 || props.selectedLevels?.serviceLevel1?.description;
    const level2pick = formikContext.values.level2 || props.selectedLevels?.serviceLevel2?.description;
    const level3service = findServices(props.serviceTypes, level1pick, level2pick, key);

    if (level3service?.licenceTypes) {
      const licenceOptions = level3service.licenceTypes.map(licenceTypeToKeyValue);
      setLicenceTypes(level3service.licenceTypes);
      setShowLevel4(true);
      props.setNextLevelToValidate('LEVEL4');

      setLevel4(asOptions(licenceOptions, true, true));
    }
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Dropdown name="level1" label="Licence type" options={props.level1} onChange={handleLevel1Change} required disabled={disableLevels} />
        </Grid.Col>
      </Grid.Row>
      {showLevel2 && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <Dropdown name="level2" options={level2} onChange={handleLevel2Change} required disabled={disableLevels} />
          </Grid.Col>
        </Grid.Row>
      )}
      {showLevel3 && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <Dropdown
              name="level3"
              options={level3}
              onChange={(event: any) => {
                handleLevel3Change(event);
                formikContext.setFieldValue('licenceTypeId', 1);
              }}
              required
              disabled={false}
            />
          </Grid.Col>
        </Grid.Row>
      )}
      {showLevel4 && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <Dropdown
              name="licenceTypeId"
              options={level4}
              onChange={(key: any) => props.onLevel4Change(key, licenceTypes, undefined)}
              required
              disabled={disableLevel4}
            />
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

export default ServiceLevelSelector;
