import { validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';

import { CertificateDto } from 'api-client';

import { saveDraftCertificateSchema } from './create/validationSchema';

const validationCertificateForm = (values: any) => {
  try {
    validateYupSchema(values, saveDraftCertificateSchema, true, { ...values });
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

const formikValidator = (schema: any, apiErrors: any) => (values: any) => {
  try {
    validateYupSchema(values, schema, true, { ...values });
  } catch (err) {
    return _.merge(yupToFormErrors(err), apiErrors);
  }
  return { ...apiErrors };
};

const addCallsignDiscriminator = (values: CertificateDto) => {
  const copied = JSON.parse(JSON.stringify(values));
  if ((values.type !== 'GAOC' && values.type !== 'AOARC') || _.isEmpty(values.primaryCallsign?.callsign)) {
    copied.primaryCallsign = undefined;
    copied.secondaryCallsign = undefined;
    copied.temporaryCallsigns = undefined;
  } else {
    copied.primaryCallsign.callsignDiscriminator = 'PrimaryCallsignDto';
    if (_.isEmpty(values.secondaryCallsign?.callsign)) {
      copied.secondaryCallsign = undefined;
    } else {
      copied.secondaryCallsign.callsignDiscriminator = 'SecondaryCallsignDto';
    }
    if (!_.isEmpty(values.temporaryCallsigns)) {
      copied.temporaryCallsigns = copied.temporaryCallsigns
        .map((tc: any) =>
          _.isEmpty(tc.callsign)
            ? undefined
            : {
                ...tc,
                callsignDiscriminator: 'TemporaryCallsignDto',
              },
        )
        .filter((tc: any) => !!tc);
    }
  }
  return copied;
};

const validateForm = (helper: any, validationSchema: any, apiErrors: any) => {
  let validationErrors = {};
  try {
    validateYupSchema(helper.values, validationSchema, true, { ...helper.values });
  } catch (err) {
    validationErrors = _.merge(yupToFormErrors(err), apiErrors);
  }

  if (!_.isEmpty(validationErrors)) {
    helper.setErrors(validationErrors);
    helper.setTouched(validationErrors);
  } else {
    return true;
  }

  return false;
};

export { validationCertificateForm, addCallsignDiscriminator, validateForm, formikValidator };
