import { MessageBarType } from '@fluentui/react';
import { useAppRouter } from 'Routes';
import React from 'react';
import * as Yup from 'yup';

import { AuditRecommendationEnum, CaseFinalResultEnum, CaseTypeCodeEnum, InfringementDto } from 'api-client';

import { Form } from 'ui-library';

import Dialog from 'common/controls/surfaces/Dialog';
import usePage from 'common/layout/PageHook';
import { useAuditApi, useAuditFormState } from 'investigation/audit/hooks';
import { isOptionalString, isRequiredString } from 'investigation/hooks';

import { formatDateFromISO, getTimeFromIsoDate } from '../../../common/utils/dateUtils';
import AuditRecommendationDialogContent from './AuditRecommendationDialogContent';

type CaseDetails = {
  caseNumber: number;
  caseType: string;
  caseTypeCode: CaseTypeCodeEnum;
  clientType: string | undefined;
  emcAuditType?: boolean;
  isOffencesRequired: boolean;
  radioAuditType?: boolean;
  startDate: string | undefined;
};

type AuditCompleteDialogProps = {
  caseDetails: Partial<CaseDetails>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export type AuditRecommendationFormProps = {
  caseDetails: Partial<CaseDetails>;
  clientBirthDate: string;
  endDate: string;
  finalResult: CaseFinalResultEnum;
  finalResultNotes: string;
  infringementId: number;
  infringementFees: number[];
  infringementDate: string;
  infringementLocation: string;
  infringementTime: string;
  offences?: { [key: string]: boolean | undefined };
  recommendation: AuditRecommendationEnum;
  otherOffenceDescs?: { [key: string]: string | undefined };
};

const UnsatisfactoryRequiredFields = Yup.string().when('finalResult', {
  is: CaseFinalResultEnum.UNSATISFACTORY,
  then: isRequiredString,
  otherwise: isOptionalString,
});

const DiscardedRequiredFields = Yup.string().when('finalResult', {
  is: CaseFinalResultEnum.DISCARD,
  then: isRequiredString,
  otherwise: isOptionalString,
});

const AuditRecommendationDialog = ({ caseDetails, isOpen, setIsOpen }: AuditCompleteDialogProps) => {
  const { recommendAuditApi } = useAuditApi();
  const { setPageInstruction } = usePage();
  const { navigateToDashboard } = useAppRouter();
  const { formState } = useAuditFormState();

  return (
    <Dialog title="Audit recommendation" isOpen={isOpen} width="750px">
      <Form<AuditRecommendationFormProps>
        formButtonsOptions={{
          additionalButtons: [
            {
              id: 'auditRecommendationBtn',
              onClick: () => {
                setIsOpen(false);
              },
              text: 'Cancel',
            },
          ],
          hideBackButton: true,
          hideCancelButton: false,
          submitButtonText: 'Submit',
        }}
        handleSubmit={(formValues, formikHelpers) => {
          const {
            caseDetails,
            clientBirthDate,
            offences = {},
            infringementId,
            infringementDate,
            infringementFees,
            infringementLocation,
            infringementTime,
            ...values
          } = formValues;

          const isUnsatisfactory = values.finalResult === CaseFinalResultEnum.UNSATISFACTORY;

          const caseOffences: { offenceId: number; otherDesc?: string }[] | undefined = isUnsatisfactory
            ? Object.keys(offences)
                .filter((key) => offences[key])
                .map((key) => ({ offenceId: Number(key), otherDesc: undefined }))
            : undefined;

          if (values.otherOffenceDescs) {
            Object.keys(values.otherOffenceDescs).forEach((key) => {
              let index = caseOffences?.findIndex((offence) => offence.offenceId === +key);
              if (!!index && !!caseOffences?.[index]) {
                caseOffences[index].otherDesc = values.otherOffenceDescs?.[key];
              }
            });
          }

          const infringement: InfringementDto | undefined = isUnsatisfactory
            ? {
                clientBirthDate,
                infringementDate: `${infringementDate}T${infringementTime}:00`,
                infringementFees: infringementFees.map((id) => ({ id })),
                location: infringementLocation,
                id: infringementId,
              }
            : undefined;

          recommendAuditApi({ id: caseDetails?.caseNumber || 0, offences: caseOffences, infringement, ...values }).then((auditDto) => {
            navigateToDashboard();
            auditDto.finalResult === CaseFinalResultEnum.DISCARD
              ? setPageInstruction('Case discarded.', MessageBarType.success)
              : setPageInstruction('Case sent for review.', MessageBarType.success);
          });
          //.finally(() => formikHelpers.setSubmitting(false))
        }}
        hideFormButtonsTop
        id="auditRecommendationDialog"
        isInDialog
        initialValues={{
          caseDetails,
          clientBirthDate: formState.infringement?.clientBirthDate,
          endDate: formState.endDate,
          finalResult: formState.finalResult,
          finalResultNotes: formState.finalResultNotes,
          infringementId: formState.infringement?.id,
          infringementFees: formState.infringement?.infringementFees?.map((s) => s.id),
          infringementDate: formatDateFromISO(formState.infringement?.infringementDate),
          infringementLocation: formState.infringement?.location,
          infringementTime: getTimeFromIsoDate(formState.infringement?.infringementDate),
          offences: formState.offences,
          otherOffenceDescs: formState.offenceOther,
          recommendation: formState.recommendation,
        }}
        mode="CREATE"
        showFormButtonsBottom
        validationSchema={[
          { name: 'finalResult' },
          {
            name: 'finalResultNotes',
            validationType: 'custom',
            schema: DiscardedRequiredFields,
          },
          {
            name: 'infringementFees',
            validationType: 'custom',
            schema: Yup.array().when('finalResult', {
              is: CaseFinalResultEnum.UNSATISFACTORY,
              then: isRequiredString,
              otherwise: isOptionalString,
            }),
          },
          {
            name: 'infringementDate',
            validationType: 'custom',
            schema: UnsatisfactoryRequiredFields,
          },
          {
            name: 'infringementLocation',
            validationType: 'custom',
            schema: UnsatisfactoryRequiredFields,
          },
          {
            name: 'infringementTime',
            validationType: 'custom',
            schema: UnsatisfactoryRequiredFields.matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Time should follow HH:MM format'),
          },
          {
            name: 'offence',
            validationType: 'custom',
            schema: Yup.string().when(['caseDetails.isOffencesRequired', 'finalResult', 'offences'], {
              is: (isOffencesRequired: boolean, finalResult: CaseFinalResultEnum, offences: any = {}) =>
                isOffencesRequired &&
                finalResult === CaseFinalResultEnum.UNSATISFACTORY &&
                Object.keys(offences).filter((key) => offences[key]).length === 0,
              then: (yupString: Yup.StringSchema) => isRequiredString(yupString, 'Select at least 1 offence'),
              otherwise: isOptionalString,
            }),
          },
          {
            name: 'recommendation',
            validationType: 'custom',
            schema: UnsatisfactoryRequiredFields,
          },
        ]}
      >
        <AuditRecommendationDialogContent />
      </Form>
    </Dialog>
  );
};

export default AuditRecommendationDialog;
