import { DefaultButton, mergeStyles, PrimaryButton } from '@fluentui/react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from 'ui-library/grid';

import InlineButton from 'common/controls/buttons/InlineButton';
import { dirtyFormConfirmStrategy, neverConfirmStrategy, withConfirmOnClick } from 'common/controls/inputs/ConfirmOnClick';
import { FormMode } from 'common/form/Form';
import { FormButton } from 'common/form/FormButtons';
import { useForm } from 'common/form/FormHook';

export const createId = (id: string, top?: boolean) => (top ? `${id}-top` : `${id}-bottom`);

export interface AdditionalFormButton extends FormButton {
  showModes?: FormMode[];
}

export interface BaseFormButtonsProps<T> {
  hideBackButton?: boolean;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  submitButtonId?: string;
  submitButtonText?: string;

  onRenderSubmitButtonText?: (values: T) => string;

  noMargin?: boolean;
  inModal?: boolean;

  additionalButtons?: AdditionalFormButton[];

  onCancelButtonClick?: () => void;
  onEditButtonClick?: () => void;

  /**
   * Called when the submit button is clicked.
   */
  onSubmitButtonClick?: (formikContext: FormikContextType<T>) => void;
}

export interface FormButtonsProps<T> extends BaseFormButtonsProps<T> {
  canEdit?: boolean;
  id: string;
  top?: boolean;
  disabledSubmitWhenNoDirty?: boolean;
}

const FormButtons = <T extends FormikValues>(props: FormButtonsProps<T>) => {
  const { values } = useFormikContext<T>();
  const { id, top } = props;
  const { mode } = useForm();

  const buttonsGroupClass = `ms-Grid-col ${!props.hideBackButton ? 'ms-sm8' : 'ms-sm12'}`;
  const className = mergeStyles(
    props.noMargin
      ? {}
      : {
          marginTop: top ? '10px' : '20px',
          marginBottom: top ? '0px' : props.inModal ? '0px' : '30px',
        },
  );

  const submitButtonText = props.onRenderSubmitButtonText ? props.onRenderSubmitButtonText(values) : props.submitButtonText || 'Save';

  return (
    <div className={className}>
      <Grid.Row>
        <BackButton hideBack={props.hideBackButton} />
        <div className={buttonsGroupClass} style={{ textAlign: 'right', display: 'inline-block' }}>
          {props
            .additionalButtons!.filter((button: AdditionalFormButton) => !button.showModes || button.showModes.includes(mode))
            .map((formButton: AdditionalFormButton, idx: number) => (
              <DecorateFormButton key={idx} formButton={formButton} idx={idx} top={top} disabledSubmitWhenNoDirty={props.disabledSubmitWhenNoDirty} />
            ))}
          <CancelButton id={id} hideCancel={props.hideCancelButton} onClick={props.onCancelButtonClick} top={top} />
          <EditButton id={id} canEdit={props.canEdit} onClick={props.onEditButtonClick} top={top} />

          {submitButtonText && (
            <SubmitButton
              id={id}
              hideSubmit={props.hideSubmitButton}
              onClick={props.onSubmitButtonClick}
              text={submitButtonText}
              top={top}
              disabledSubmitWhenNoDirty={props.disabledSubmitWhenNoDirty}
            />
          )}
        </div>
      </Grid.Row>
    </div>
  );
};

FormButtons.defaultProps = {
  additionalButtons: [],
};

interface BackButtonProps {
  hideBack?: boolean;
}

const BackButton = (props: BackButtonProps) => {
  const { mode } = useForm();
  const formikContext = useFormikContext();
  const history = useHistory();

  if (props.hideBack) {
    return null;
  }

  return (
    <div className="ms-Grid-col ms-sm4" style={{ display: 'inline-block' }}>
      {withConfirmOnClick(
        <DefaultButton
          text="Back"
          onClick={() => {
            history.goBack();
          }}
        />,
        mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy,
        formikContext,
      )}
    </div>
  );
};

interface CancelButtonProps {
  id: string;
  hideCancel?: boolean;
  onClick?: () => void;
  top?: boolean;
}

const CancelButton = (props: CancelButtonProps) => {
  const { mode, setMode } = useForm();
  const formikContext = useFormikContext();

  if (props.hideCancel || mode !== 'EDIT') {
    return null;
  }

  return withConfirmOnClick(
    <DefaultButton
      data-cy={`cancel-button${props.top && '-top'}`}
      text="Cancel"
      onClick={() => {
        mode === 'EDIT' && formikContext.resetForm();
        setMode && setMode('VIEW');
      }}
    />,
    dirtyFormConfirmStrategy(formikContext),
    formikContext,
  );
};

interface EditButtonProps {
  id: string;
  canEdit?: boolean;
  onClick?: () => void;
  top?: boolean;
}

const EditButton = (props: EditButtonProps) => {
  const { mode, setMode } = useForm();

  if (!props.canEdit || mode === 'EDIT') {
    return null;
  }

  return (
    <InlineButton
      data-cy={`edit-button${props.top && '-top'}`}
      text="Edit"
      onClick={() => {
        setMode && setMode('EDIT');
        props.onClick && props.onClick();
      }}
    />
  );
};

interface SubmitButtonProps {
  id: string;
  hideSubmit?: boolean;
  onClick?: (formikContext: FormikContextType<any>) => void;
  text?: string;
  top?: boolean;
  disabledSubmitWhenNoDirty?: boolean;
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { mode, setMode } = useForm();
  const formikContext = useFormikContext();

  if (props.hideSubmit || mode === 'VIEW') {
    return null;
  }

  const isDisabled = mode === 'EDIT' && (formikContext.isSubmitting || props.disabledSubmitWhenNoDirty ? false : !formikContext.dirty);

  return (
    <PrimaryButton
      data-cy={`submit-button${props.top && '-top'}`}
      data-automation-id={createId(props.id, props.top)}
      style={{ marginLeft: '10px' }}
      text={props.text || 'Submit'}
      disabled={isDisabled}
      onClick={() => {
        props.onClick ? props.onClick(formikContext) : formikContext.submitForm();
        if (formikContext.isValid) {
          mode === 'EDIT' && setMode && setMode('VIEW');
        }
      }}
    />
  );
};

interface DecorateFormButtonProps {
  formButton: FormButton;
  idx: number;
  top?: boolean;
  disabledSubmitWhenNoDirty?: boolean;
}

const DecorateFormButton: React.FC<DecorateFormButtonProps> = ({ formButton, idx, top, disabledSubmitWhenNoDirty }) => {
  const { mode } = useForm();
  const formikContext = useFormikContext();
  const handleClick = () => {
    formButton.onClick(formikContext);
  };

  const isDisabledPrimary = mode === 'EDIT' && (formikContext.isSubmitting || disabledSubmitWhenNoDirty ? !formikContext.dirty : false);
  const isDisabledDefault =
    mode === 'EDIT' &&
    (formikContext.isSubmitting ||
      (formButton.disabledWhenNoDirty ? !formikContext.dirty : false) ||
      (formButton.disabledWhenInvalid ? !formikContext.isValid : false));

  const button = formButton.isPrimary ? (
    <PrimaryButton
      {...formButton}
      id={createId(formButton.id, top)}
      data-automation-id={createId(formButton.id, top)}
      disabled={isDisabledPrimary}
      onClick={handleClick}
    />
  ) : (
    <DefaultButton
      {...formButton}
      id={createId(formButton.id, top)}
      data-automation-id={createId(formButton.id, top)}
      disabled={isDisabledDefault}
      onClick={handleClick}
    />
  );

  return (
    <span style={{ marginRight: '10px' }} key={idx}>
      {formButton.shouldConfirm
        ? withConfirmOnClick(
            button,
            mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy,
            formikContext,
          )
        : button}
    </span>
  );
};

export default FormButtons;
