import { Label, Link } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { AddressDto } from 'api-client';

import Dropdown from 'ui-library/dropdown';
import Grid2 from 'ui-library/grid/Grid2';
import { SelectedTag, SingleTagPicker, SingleTagPickerProps } from 'ui-library/tagpicker';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import { addressesUtils } from 'common/controls/inputs/address/addressesUtils';
import { useForm } from 'common/form/FormHook';
import { countryCodeOptions } from 'common/reference/countryCodes';

type AddressPickerMode = 'MANUAL' | 'SEARCH';

interface AddressPickerProps extends Omit<SingleTagPickerProps<AddressDto>, 'onResolveSuggestions'> {
  includePhysical?: boolean;
  includePostal?: boolean;
  initialAddress?: any;
  mode?: AddressPickerMode;
  onEnterAddressManually?: () => void;
}

interface AddressPickerLabelProps extends Pick<AddressPickerProps, 'label' | 'name' | 'required'> {}
interface AddressPickerContextProps {
  mode?: AddressPickerMode;
  toggleMode?: () => void;
}

export const toAddressTag = (address: AddressDto) => ({
  key: `${address.pafId}`,
  name: `${address.pafAddress}`,
  data: address,
});

const AddressPickerContext = createContext({} as AddressPickerContextProps);

const AddressPicker = (props: AddressPickerProps) => {
  const { includePhysical = true, includePostal = true, label = '', name = 'address', onEnterAddressManually, ...pickerProps } = props;

  const { mode: formMode } = useForm();
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const [mode, setMode] = useState<AddressPickerMode>();

  const initialValue: any = getFieldMeta(name).initialValue;

  useEffect(() => {
    if (initialValue?.manual) {
      setMode('MANUAL');
    } else {
      setMode(props.mode || 'SEARCH');
    }
  }, [props.mode, initialValue]);

  if (formMode !== 'CREATE' && formMode !== 'EDIT' && mode === 'SEARCH') {
    const addressDto = initialValue?.data as AddressDto;
    return <ReadOnlyField label={label} name={name} renderFormat={() => addressDto?.pafAddress} />;
  }

  const toggleMode = () => {
    const nextMode: AddressPickerMode = mode === 'MANUAL' ? 'SEARCH' : 'MANUAL';
    let fieldValue = null;

    if (nextMode === 'MANUAL') {
      fieldValue = {
        cityTown: undefined,
        countryCode: undefined,
        manual: true,
        postCode: undefined,
        streetName: undefined,
        suburb: undefined,
      };
    } else {
      fieldValue = null;
    }

    setFieldValue(name, fieldValue);
    setMode(nextMode);

    onEnterAddressManually && onEnterAddressManually();
  };

  return (
    <AddressPickerContext.Provider value={{ mode, toggleMode }}>
      {mode === 'SEARCH' ? (
        <SearchAddressPicker label={label} name={name} {...pickerProps} />
      ) : (
        <ManualAddress label={label} name={name} required={props.required} />
      )}
    </AddressPickerContext.Provider>
  );
};

const AddressPickerLabel = ({ label, name, required }: AddressPickerLabelProps) => {
  const { mode, toggleMode } = useContext(AddressPickerContext);
  const { mode: formMode } = useForm();

  return (
    <Grid2 layout={{ lg: 2, sm: 2 }}>
      <Grid2.Col>
        {label && (
          <Label htmlFor={name} required={required}>
            {label}
          </Label>
        )}
      </Grid2.Col>
      {(formMode === 'CREATE' || formMode === 'EDIT') && (
        <Grid2.Col align="right" style={{ paddingBottom: label ? 0 : 6, paddingTop: 3 }}>
          <Link onClick={toggleMode}>{mode === 'SEARCH' ? 'Enter address manually' : 'Show search address'}</Link>
        </Grid2.Col>
      )}
    </Grid2>
  );
};

const ManualAddress = ({ label, name, required }: AddressPickerLabelProps) => {
  return (
    <Grid2>
      <Grid2.Col>
        <AddressPickerLabel label={label} name={name} required={required} />
      </Grid2.Col>

      <Grid2.Col colStart={1}>
        <TextField label="Street name and number" name={`${name}.streetName`} required />
      </Grid2.Col>

      <Grid2.Col colStart={1}>
        <TextField label="Suburb" name={`${name}.suburb`} required />
      </Grid2.Col>

      <Grid2.Col colStart={1}>
        <TextField label="City/Town" name={`${name}.cityTown`} required />
      </Grid2.Col>

      <Grid2.Col colStart={1}>
        <TextField
          label="Post code"
          name={`${name}.postCode`}
          required
          onRenderSuffix={() => (
            <Link href="https://www.nzpost.co.nz/tools/address-postcode-finder" target="_blank" rel="noreferrer">
              Find Post Code
            </Link>
          )}
        />
      </Grid2.Col>

      <Grid2.Col colStart={1}>
        <Dropdown label="Country" name={`${name}.countryCode`} options={countryCodeOptions} required />
      </Grid2.Col>
    </Grid2>
  );
};

const SearchAddressPicker = (props: AddressPickerProps) => {
  const { includePhysical = true, includePostal = true, label = '', name = 'address', onEnterAddressManually, ...pickerProps } = props;

  const { toggleMode } = useContext(AddressPickerContext);
  const { getFieldMeta } = useFormikContext();

  const initialValue: any = getFieldMeta(name).initialValue;

  return (
    <Grid2>
      <Grid2.Col>
        <SingleTagPicker<AddressDto>
          {...pickerProps}
          defaultSelectedTag={initialValue?.manual ? undefined : (initialValue as SelectedTag<AddressDto>)}
          name={name}
          onRenderLabel={<AddressPickerLabel label={label} name={name} required={props.required} />}
          onResolveSuggestions={async (searchText) => {
            const addresses = await addressesUtils(searchText, includePhysical, includePostal);
            return addresses.map(toAddressTag);
          }}
          placeholder={props.placeholder || 'Start typing to search for an address'}
          pickerSuggestionsProps={{
            loadingText: 'Searching for address...',
            manualTagText: 'Enter address manually',
            onClickManualTag: toggleMode,
          }}
        />
      </Grid2.Col>
    </Grid2>
  );
};

export default AddressPicker;
