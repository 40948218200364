import { mergeStyles, MessageBar } from '@fluentui/react';
import { Label } from '@fluentui/react';
import * as React from 'react';

import { OutageNotificationDto } from 'api-client';

import usePage from './PageHook';

interface BannerProps {
  preview: boolean;
  item?: OutageNotificationDto;
}

const convertPriorityToStyle = (priority?: number) => {
  switch (priority) {
    case 1:
      return '#d14904';
    case 2:
      return '#0e7ac3';
    case 3:
      return '#707070';
    default:
      return '#d14904';
  }
};

export const OutageNotificationBanner = (props: BannerProps) => {
  const className = mergeStyles({
    gridArea: 'header',
    backgroundColor: convertPriorityToStyle(props.item?.priority),
    color: '#fff',
    fontSize: '14px',
    fontFamily: 'FiraSans Book, Helvetica, Arial, sans-serif',
    selectors: {
      a: {
        color: '#fff',
        textDecoration: 'underline',
      },
      i: {
        color: '#fff',
      },
      '.dismissal-80': {
        color: '#fff',
      },
      '.ms-Button-icon': {
        color: '#fff',
      },
      '.dismissal-80 .ms-Button-icon': {
        color: '#fff',
      },
    },
  });
  const { removeOutageNotification } = usePage();

  return props.item ? (
    <MessageBar
      delayedRender={false}
      data-automation-id="outage-notification-banner"
      className={className}
      onDismiss={() => removeOutageNotification(props.item)}
      key={`${props.item.id}-outage-notif-msg-bar`}
    >
      <div
        id={`${props.item.id}-outage-notif-msg`}
        key={`${props.item.id}-outage-notif-msg`}
        dangerouslySetInnerHTML={{ __html: props.item.message }}
      />
      <Label>{props.preview ? 'In Preview' : ''}</Label>
    </MessageBar>
  ) : (
    <div className={className} style={{ minHeight: '35px' }} id="outage-notification-banner-placeholder" />
  );
};
