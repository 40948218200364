import moment from 'moment';
import * as yup from 'yup';

import { LicenceCallsignTypeEnum } from 'api-client';

import { isDigitsOfLength, requiredValidRangeWithDecimal, validRangeWithDecimal } from 'common/validation/yupUtils';

import { RRF_DATE_FORMAT } from '../../common/utils/dateUtils';
import {
  mustBeFutureOrPresentDate,
  notRequiredBoolean,
  requiredDate,
  requiredNumber,
  requiredString,
  requiredWholeNumber,
  requiredWholePositiveNumber,
  wholeNumber,
} from '../../common/validation/yupUtils';
import { baseCallsignsSchema, callsignSchema, mobileCallsignsSchema } from '../common/validationSchema';
import { tenMonthsFromLastDayOfCurrentMonth } from './utils';

const LICENCE_AIR = LicenceCallsignTypeEnum.LICENCE_AIR;

const commencementDateSchema = requiredDate.when(['fixedTerm'], {
  is: (fixedTerm: boolean) => fixedTerm === true,
  then: requiredDate.test(
    'periodCommencementDate',
    `Commencement date of a fixed term radio licence cannot be later than ${tenMonthsFromLastDayOfCurrentMonth.format(RRF_DATE_FORMAT)}`,
    function (this: yup.TestContext, value) {
      return moment(value).isSameOrBefore(tenMonthsFromLastDayOfCurrentMonth);
    },
  ),
});
const periodEndingSchema = yup
  .date()
  .when(['fixedTerm'], {
    is: (fixedTerm: boolean) => fixedTerm === true,
    then: requiredDate.test('periodEnding', 'Must be after or same as Commencement date', function (this: yup.TestContext, value) {
      return !value || !this.parent['periodCommencementDate'] || moment(value).isSameOrAfter(this.parent['periodCommencementDate']);
    }),
  })
  .nullable(true);

export const periodCommencementDate = yup.date().when('$context.showMangementRight', {
  is: (showMangementRight) => showMangementRight,
  then: mustBeFutureOrPresentDate()
    .required('Required')
    .test('management right commencement', 'Must be after or same as Management Right Commencement', function (this: yup.TestContext, date) {
      const mr: { commencementDate?: string } | undefined = (this.options.context as any)?.context?.selectedManagementRight;
      return !date || !mr?.commencementDate || moment(date).isSameOrAfter(mr.commencementDate);
    }),
  otherwise: commencementDateSchema,
});

export const periodEnding = yup.date().when('$context.showMangementRight', {
  is: (showMangementRight) => showMangementRight,
  then: periodEndingSchema.test(
    'management right expiry',
    'Must be before or same as Management Right Expiry',
    function (this: yup.TestContext, date) {
      const mr: { expiryDate?: string } | undefined = (this.options.context as any)?.context?.selectedManagementRight;
      return !date || !mr?.expiryDate || moment(date).isSameOrBefore(mr.expiryDate);
    },
  ),
  otherwise: periodEndingSchema,
});

export const managementRightId = yup.number().when('$context.showMangementRight', {
  is: (showMangementRight) => showMangementRight,
  then: requiredWholeNumber.required('Please provide a Management Right'),
});

const aircraftCallsignSchema = callsignSchema.when('$context.selectedLicenceType', {
  is: (selectedLicenceType) => selectedLicenceType?.callsignType === LICENCE_AIR,
  then: requiredString,
});

const orbitalPositionschema = requiredValidRangeWithDecimal(-180, 180, 2, 'Must be two decimal place and in the range -180 to 180');
const minimumElevation = validRangeWithDecimal(-90, 90, 2, 'Must be two decimal place and in the range -90 to 90');

const satelliteSchema = yup.object().shape({
  // TODO not mandatory on save draft
  name: requiredString,
  licenceClassification: requiredString,
  satelliteType: requiredString,
  orbitalPosition: yup
    .number()
    .when(['satelliteType'], {
      is: (satelliteType: string) => satelliteType === 'GSO',
      then: orbitalPositionschema,
    })
    .nullable(true),
  minimumElevation: minimumElevation,
  ituNoticeId: requiredNumber.test('digits', 'Please enter a 9 digit number', (value) => isDigitsOfLength(9, '' + value)),
  beamName: requiredString,
  commercialName: requiredString,
  owner: requiredString,
});

const licenceShape = {
  applicant: requiredWholePositiveNumber,
  level1: requiredString,
  level2: yup.string().when(['level1', '$context.nextLevelToValidate'], {
    is: (type: string, nextLevelToValidate: string) => type !== undefined && 'LEVEL2' === nextLevelToValidate,
    then: requiredString,
  }),
  level3: yup.string().when(['level2', '$context.nextLevelToValidate'], {
    is: (type: string, nextLevelToValidate: string) => type !== undefined && 'LEVEL3' === nextLevelToValidate,
    then: requiredString,
  }),

  licenceTypeId: requiredString,

  bandSpectrums: yup.array().when(['showPreferredBand'], {
    is: (showPreferredBand: boolean) => showPreferredBand === true,
    then: yup.array().of(requiredNumber).required('Required'),
  }),

  managementRightId,
  periodCommencementDate,
  periodEnding,
  anniversaryMonth: requiredString,
  rsmToPerformEngineering: notRequiredBoolean,
  approvedEngineerId: yup
    .string()
    .when(['licenceTypeId', 'rsmToPerformEngineering'], {
      is: (licenceTypeId: string, rsmToPerformEngineering: boolean) =>
        licenceTypeId !== '15' && licenceTypeId !== '120' && licenceTypeId !== '121' && licenceTypeId !== '211' && rsmToPerformEngineering === false,
      then: requiredString,
    })
    .nullable(true),

  //*** land simplex details ***
  noOfSetsRequired: yup
    .number()
    .when(['licenceTypeId'], {
      is: (licenceTypeId: string) => licenceTypeId === '211',
      then: requiredNumber,
    })
    .nullable(true)
    .when(['licenceTypeId'], {
      is: (licenceTypeId: string) => licenceTypeId === '211',
      then: wholeNumber.min(2, 'Number of sets must be greater than or equal to 2'),
    }),

  location: yup.string().when(['licenceTypeId'], {
    is: (licenceTypeId: string) => licenceTypeId === '211',
    then: requiredString,
  }),

  channelSpectrums: yup.array().when(['licenceTypeId'], {
    is: (licenceTypeId: string) => licenceTypeId === '211',
    then: yup
      .array()
      .of(yup.array().of(yup.number().required('Required').nullable(true)).min(1, 'Required'))
      .min(1, 'Required'),
  }),

  callsign: aircraftCallsignSchema,
  baseCallsigns: baseCallsignsSchema,
  mobileCallsigns: mobileCallsignsSchema,
  satellite: yup.object().when(['licenceTypeId'], {
    is: (licenceTypeId: string) => licenceTypeId === '55',
    then: satelliteSchema,
  }),
  purposeOfService: yup.string().max(4000, 'Exceeds the required length').nullable(),
};

export const applyLicenceSchema = yup.object().shape(licenceShape);
