import { ActionButton, IButtonStyles, IIconProps, useTheme } from '@fluentui/react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Schema } from 'yup';

import {
  CreateFrequencySpectrumDto,
  LicenceClassificationType,
  LicenceDto,
  LicenceRadiationPatternDto,
  LicenceSpectrumConfigurationDto,
  MaintainSpectrumDto,
  PreferredBandDto,
  ServiceTypeDto,
  SpectrumDto,
} from 'api-client';

import { Grid2 } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import Error from 'common/controls/items/Error';
import Accordion from 'common/layout/Accordion';
import { isEmpty as isEmptyField, isEmpty } from 'common/utils/objectUtils';
import { validateWithFormValues } from 'common/validation/validationWithFormValues';
import { useLicenceApi } from 'licence_management/LicenceApiHooks';
import { Licence } from 'licence_management/craft/CraftLicencePage';
import { useDoneValidation } from 'licence_management/craft/DoneValidationHooks';
import { useLicenceCraftingApi } from 'licence_management/craft/LicenceCraftingApiHook';

import EmbeddedCreateFrqSpectrum from './EmbeddedCreateFrqSpectrum';
import LicenceSpectrumList, { Spectrum } from './LicenceSpectrumList';
import RadiationPatternsSection, { maximumPower, radiationPatternsAsFormValues, RadiationPatternsFormProps } from './RadiationPatternsSection';
import { withBounds as freqWithBounds } from './ReferenceFrequenciesSection';
import SpectrumTemplateSearch from './SpectrumTemplateSearch';
import { withBounds as uelWithBounds } from './UnwantedEmissionLimitsSection';
import { radiationPatternsSchema } from './radiationPatternValidationSchema';
import { spectrumDetailsAllDoneSchema, spectrumDetailsSchema } from './spectrumDetailsValidationSchema';

export const getLicenceSpectrumConfig = (licenceSpectrumConfigs: LicenceSpectrumConfigurationDto[] = [], licence?: LicenceDto) =>
  licence && licenceSpectrumConfigs
    ? licenceSpectrumConfigs
        .filter((config) => config.licenceTypeId === licence.licenceTypeId)
        .find((config) => !config.serviceLevel3Id || config.serviceLevel3Id === licence?.selectedServiceLevels?.serviceLevel3?.id) ?? {
        licenceTypeId: licence.licenceTypeId,
        spectrumChannel: true,
        spectrumFrequency: true,
      }
    : undefined;

export interface LicenceSpectrumDetailsProps {
  licence?: Licence;
  licenceSpectrumConfig: LicenceSpectrumConfigurationDto;
  serviceTypes: ServiceTypeDto[];
  onDone: (isDone: boolean) => Promise<void>;
  isModifySpectrumLicence?: boolean;
  readonly?: boolean;
  baseCallSignCapture?: boolean;
  mobileCallSignCapture?: boolean;
  preferredBands?: PreferredBandDto[];
  onSpectrumBandUpdate: (bands: PreferredBandDto[]) => void;
}

const LicenceSpectrumDetails: React.FC<LicenceSpectrumDetailsProps> = (props) => {
  const { semanticColors } = useTheme();
  const { hasRole } = useAuth();
  const { createFrequencySpectrum, getPreferredBands } = useLicenceApi();
  const { getSpectrums } = useLicenceApi();
  const { maintainSpectrum, maintainRadiationPatterns, cloneSpectrum, removeSpectrum } = useLicenceCraftingApi();
  const [spectrums, setSpectrumsState] = useState<Spectrum[]>();
  const [radiationPatterns, setRadiationPatternsState] = useState<{
    horizontal: LicenceRadiationPatternDto[];
    vertical: LicenceRadiationPatternDto[];
  }>();
  const licence = props.licence;
  const licenceId = licence?.id;
  const isDone = !!licence?.craftingProgress?.isSpectrumDetailsDone;
  const isBasicDetailsDone = !!licence?.craftingProgress?.isBasicDetailsDone;
  const [expanded, setExpanded] = useState<boolean | undefined>();
  const [viewSpectrumId, setViewSpectrumId] = useState<number | undefined>();
  const [lastSaved, setLastSaved] = useState<Date | undefined>();
  let [submit, setSubmitState] = useState<{ onSubmit: (values: any) => Promise<any>; schema: Schema<any>; isDone?: boolean }>({
    onSubmit: () => Promise.resolve(),
    schema: yup.object(),
  });
  const [renderCreateSpectrum, setRenderCreateSpectrum] = useState<boolean>(false);

  const setSubmit = (submitState: { onSubmit: (values: any) => Promise<any>; schema: Schema<any> }) => {
    submit = submitState;
    setSubmitState(submitState);
  };
  let validateDone = false;
  const setRadiationPatterns = (radiationPatterns: LicenceRadiationPatternDto[], maxPower?: number) =>
    setRadiationPatternsState(radiationPatternsAsFormValues({ ...licence, radiationPatterns } as any, maxPower));
  const setSpectrums = (spectrums: SpectrumDto[], classification?: LicenceClassificationType) =>
    setSpectrumsState(spectrumsAsFormValues(spectrums, classification));
  const maxPower = maximumPower(spectrums);

  const maxSpectrums = props.licenceSpectrumConfig.maxSpectrums ?? 1000000;

  const isSimplexLicence = [71, 73, 211].includes(licence?.licenceTypeId ?? 0);

  const iconProps: IIconProps = {
    iconName: 'Add',
    styles: {
      root: {
        fontSize: '15px',
        color: semanticColors.primaryButtonBackground,
        fontWeight: 'bolder',
      },
    },
  };

  const addLinkStyle: IButtonStyles = {
    root: { height: '30px' },
    label: {
      fontSize: 'larger',
      color: semanticColors.primaryButtonBackground,
    },
    labelHovered: { color: semanticColors.primaryButtonBackgroundHovered },
    iconHovered: { color: semanticColors.primaryButtonBackgroundHovered },
    iconPressed: { color: semanticColors.primaryButtonBackgroundHovered },
  };

  useEffect(() => {
    if (spectrums && licence?.classification === LicenceClassificationType.C) {
      setSpectrums(spectrums, LicenceClassificationType.C);
    }
    // eslint-disable-next-line
  }, [licence?.classification]);

  useEffect(() => {
    if (licenceId) {
      getSpectrums(licenceId, { showLoadingSpinner: true }).then((spectrums) => {
        setSpectrums(spectrums, licence?.classification);
        setRadiationPatterns(licence?.radiationPatterns ?? [], maximumPower(spectrums));
      });
    }
    // eslint-disable-next-line
  }, [licenceId, licence?.spectrumDetails]);

  useEffect(() => {
    // Force expand if prior basic details is or has become Done.
    if (isBasicDetailsDone && !isDone) {
      setExpanded(true);
    }
  }, [isBasicDetailsDone, isDone]);
  useEffect(() => {
    if (spectrums) {
      // Expand when Continue Crafting is pressed or un-Done by basic details saved change e.g. licence classification.
      // Collapse if Done is pressed.
      setExpanded(!isDone);
    }
  }, [!!isDone]);

  useEffect(() => {
    if (spectrums && props.preferredBands) {
      const hasLt30PreferredBand = props.preferredBands?.some((band) => {
        if (!band.lowerBound || !band.upperBound) return false;
        return band.lowerBound < 30 || band.upperBound < 30;
      });
      if (hasLt30PreferredBand) return;
      if (spectrums.some((spectrum) => spectrum.lowerBound < 30 || spectrum.upperBound < 30)) {
        licence?.selectedServiceLevels?.serviceLevel1 &&
          getPreferredBands(licence?.selectedServiceLevels?.serviceLevel1?.id!, { showLoadingSpinner: true }).then((bands) => {
            for (const band of bands) {
              if (band.lowerBound && band.upperBound && band.lowerBound < 30 && band.upperBound < 30) {
                props.onSpectrumBandUpdate([...props.preferredBands!, band]);
                break;
              }
            }
          });
      }
    }
  }, [spectrums, props.preferredBands]);

  const onCloneSpectrum = (spectrumId: number) => {
    const prependToSpectrums = (clonedSpectrum?: SpectrumDto) => {
      if (clonedSpectrum) {
        const updatedSpectrums = [{ ...clonedSpectrum, created: new Date() }, ...spectrums!];
        setSpectrums(updatedSpectrums);
        return updatedSpectrums;
      }
    };
    return cloneSpectrum(licenceId!, spectrumId, { showLoadingSpinner: 'Adding...' }).then(prependToSpectrums);
  };

  const onRemoveSpectrum = (spectrumId: number) => {
    const removeFromSpectrums = () => {
      const idx = spectrums!.findIndex((s) => s.id === spectrumId);
      const updatedSpectrums = [...spectrums!];
      updatedSpectrums!.splice(idx, 1);
      setSpectrums(updatedSpectrums);
      return updatedSpectrums;
    };
    return removeSpectrum(licenceId!, spectrumId, { showLoadingSpinner: 'Removing...' }).then(removeFromSpectrums);
  };

  const onContinueCrafting = () => props.onDone(false);

  const onDone = async (updatingSpectrums: Spectrum[], updatingRadiationPatterns: RadiationPatternsFormProps) => {
    // Save the opened spectrum first before Done'ing
    const canDone1 = viewSpectrumId ? !!(await onSaveViewingSpectrum(updatingSpectrums)) : true;
    const canDone2 = !!(await onSaveRadiationPatterns(updatingRadiationPatterns));
    return canDone1 && canDone2 ? props.onDone(true) : undefined;
  };

  const onSaveViewingSpectrum = (updatingSpectrums: Spectrum[]) => {
    const idx = updatingSpectrums.findIndex((spectrum) => spectrum.id === viewSpectrumId)!;
    const updateSpectrums = (updatedSpectrum: SpectrumDto) => {
      if (updatedSpectrum) {
        // An API error response doesn't return a spectrum record.
        const updatedSpectrums = [...spectrums!];
        updatedSpectrums[idx] = updatedSpectrum;
        setSpectrums(updatedSpectrums);
        setLastSaved(new Date());
      }
      return updatedSpectrum;
    };
    return maintainSpectrum(licenceId!, updatingSpectrums[idx] as MaintainSpectrumDto).then(updateSpectrums);
  };

  // Preserve the editing view if the user happened to clone or remove another spectrum.
  const continueEditing = (
    formProps: FormikProps<{ spectrums?: SpectrumDto[]; radiationPatterns?: RadiationPatternsFormProps }>,
    updatedSpectrums?: SpectrumDto[],
  ) => {
    continueEditingSpectrum(formProps, updatedSpectrums);
    continueEditingRadiationPatterns(formProps);
  };

  const continueEditingSpectrum = (
    { values, setFieldValue, dirty }: FormikProps<{ spectrums?: SpectrumDto[] }>,
    updatedSpectrums?: SpectrumDto[],
  ) => {
    updatedSpectrums = updatedSpectrums ?? values.spectrums;
    const editingSpectrum = dirty ? values.spectrums?.find((s) => s.id === viewSpectrumId) : undefined;
    if (editingSpectrum) {
      const idx = updatedSpectrums?.findIndex((spectrum) => spectrum.id === viewSpectrumId);
      if (idx !== -1) {
        setFieldValue(`spectrums[${idx}]`, editingSpectrum);
      }
    }
  };
  // Preserve any unsaved radiation patterns changes, following save spectrum.
  const continueEditingRadiationPatterns = ({ setFieldValue, values }: FormikProps<{ radiationPatterns?: RadiationPatternsFormProps }>) =>
    setFieldValue('radiationPatterns', values.radiationPatterns);

  const onSaveRadiationPatterns = ({ horizontal, vertical }: RadiationPatternsFormProps) => {
    const isNotEmpty = ({ bearingTo, value }: LicenceRadiationPatternDto) => !isEmptyField('' + ((bearingTo ?? '') + (value ?? '')));
    return maintainRadiationPatterns(licenceId!, [...horizontal.filter(isNotEmpty), ...vertical.filter(isNotEmpty)]).then((result) => {
      setRadiationPatterns(result, maxPower);
      return result;
    });
  };
  const submitDone = {
    isDone: true,
    schema: spectrumDetailsAllDoneSchema(
      licence?.licenceTypeCode,
      maxPower,
      props.baseCallSignCapture || props.mobileCallSignCapture,
      isSimplexLicence,
    ),
    onSubmit: (values: { spectrums?: SpectrumDto[]; radiationPatterns?: RadiationPatternsFormProps }) =>
      onDone(values.spectrums!, values.radiationPatterns!),
  };
  const submitSpectrum = {
    schema: spectrumDetailsSchema(viewSpectrumId ?? 0),
    onSubmit: ({ spectrums }: { spectrums: SpectrumDto[] }) => onSaveViewingSpectrum(spectrums),
  };
  const submitRadiationPatterns = {
    schema: radiationPatternsSchema(maxPower),
    onSubmit: ({ radiationPatterns }: { radiationPatterns?: RadiationPatternsFormProps }) => onSaveRadiationPatterns(radiationPatterns!),
  };

  const canAddSpectrum = () => {
    const levelOne = licence?.selectedServiceLevels?.serviceLevel1?.id || 0;
    const levelTwo = licence?.selectedServiceLevels?.serviceLevel2?.id || 0;
    const areForSpectrumCrownLevelTwoId = [35, 36];

    if (levelOne === 37 || (levelOne === 36 && areForSpectrumCrownLevelTwoId.includes(levelTwo))) {
      return (
        hasRole('ROLE_REGISTRAR') ||
        hasRole('ROLE_APPROVED_RADIO_ENGINEER') ||
        hasRole('ROLE_RSM_RADIO_ENGINEER') ||
        hasRole('ROLE_SYSTEM_ADMINISTRATOR') ||
        hasRole('ROLE_LICENSING_OFFICER') ||
        hasRole('ROLE_LICENSING_MANAGER')
      );
    } else if (levelOne === 36) {
      // other spectrum crown types
      return (
        hasRole('ROLE_REGISTRAR') ||
        hasRole('ROLE_RSM_RADIO_ENGINEER') ||
        hasRole('ROLE_SYSTEM_ADMINISTRATOR') ||
        hasRole('ROLE_LICENSING_OFFICER') ||
        hasRole('ROLE_LICENSING_MANAGER')
      );
    } else {
      return (
        hasRole('ROLE_RSM_RADIO_ENGINEER') ||
        hasRole('ROLE_SYSTEM_ADMINISTRATOR') ||
        hasRole('ROLE_LICENSING_OFFICER') ||
        hasRole('ROLE_LICENSING_MANAGER')
      );
    }
  };

  const handleCancelCreateSpectrum = () => {
    setRenderCreateSpectrum(false);
  };

  const handleAddCreateSpectrum = (dto: CreateFrequencySpectrumDto): Promise<any> => {
    return createFrequencySpectrum(licenceId || 0, dto, {
      successMessage: 'Spectrum Record has been created.',
    }).then(
      (createdDto: SpectrumDto) => {
        setRenderCreateSpectrum(false);
        const updatedSpectrums = [{ ...createdDto, created: new Date() }, ...spectrums!];
        setSpectrums(updatedSpectrums);
        return updatedSpectrums;
      },
      () => {
        return Promise.reject('Error during the creation of the spectrum record');
      },
    );
  };

  return (
    <Formik<{ spectrums?: Spectrum[]; radiationPatterns?: RadiationPatternsFormProps }>
      initialValues={{ spectrums, radiationPatterns }}
      onSubmit={submit.onSubmit}
      validate={(values) =>
        validateWithFormValues(validateDone ? submitDone.schema : submit.schema, {
          ...licence,
          baseCallsignRequired: props?.baseCallSignCapture,
          mobileCallsignRequired: props?.mobileCallSignCapture,
        })(values)
      }
      enableReinitialize
    >
      {(formProps) => (
        <LicenceSpectrumAccordion
          licence={licence}
          serviceTypes={props.serviceTypes}
          onDone={
            !props.readonly
              ? props.isModifySpectrumLicence
                ? undefined
                : isDone
                ? onContinueCrafting
                : () => {
                    setSubmit(submitDone);
                    formProps.submitForm();
                  }
              : undefined
          }
          onValidateDone={() => {
            validateDone = true;
            formProps.validateForm().then(() => (validateDone = false));
          }}
          onExpandCollapsed={() => setExpanded(!expanded)}
          done={isDone}
          expanded={expanded}
          baseCallSignCapture={props.baseCallSignCapture}
          mobileCallSignCapture={props.mobileCallSignCapture}
        >
          {!props.isModifySpectrumLicence && !isDone && spectrums && spectrums.length < maxSpectrums && (
            <Grid2>
              <Grid2.Col lg={7}>
                <SpectrumTemplateSearch
                  spectrums={spectrums}
                  licence={licence}
                  licenceSpectrumConfig={props.licenceSpectrumConfig}
                  onSelectedSpectrum={(id) =>
                    onCloneSpectrum(id).then((spectrums) => {
                      setRenderCreateSpectrum(false);
                    })
                  }
                />
              </Grid2.Col>
              {canAddSpectrum() && (
                <>
                  <Grid2.Col lg={1}>
                    <p>
                      <b>OR</b>
                    </p>
                  </Grid2.Col>
                  <Grid2.Col lg={4}>
                    <span style={{ float: 'right' }}>
                      <ActionButton
                        id={'add-spectrum-record'}
                        data-automation-id={'add-spectrum-record'}
                        text="Add a spectrum record"
                        onClick={() => setRenderCreateSpectrum(true)}
                        iconProps={iconProps}
                        styles={addLinkStyle}
                      />
                    </span>
                  </Grid2.Col>
                </>
              )}
            </Grid2>
          )}
          {renderCreateSpectrum && (
            <EmbeddedCreateFrqSpectrum
              onCancel={handleCancelCreateSpectrum}
              onAdd={(val) => handleAddCreateSpectrum(val).then((spectrums) => continueEditing(formProps, spectrums))}
              serviceType={licence?.selectedServiceLevels?.serviceLevel1?.id || 0}
              startDate={licence?.commencementDate || ''}
              endDate={licence?.expiryDate || ''}
              spectrumConfigs={props.licenceSpectrumConfig}
              licence={licence!!}
            />
          )}
          <LicenceSpectrumList
            onFocus={() => submit.isDone || setSubmit(submitSpectrum)}
            licence={licence}
            onRemoveSpectrum={(id) => onRemoveSpectrum(id).then((spectrums) => continueEditing(formProps, spectrums))}
            onViewSpectrum={setViewSpectrumId}
            onSaveSpectrum={() => {
              setSubmit(submitSpectrum);
              formProps.submitForm().then(() => continueEditingRadiationPatterns(formProps));
            }}
            onCancelSpectrum={() => continueEditingRadiationPatterns(formProps)}
            readOnly={isDone || props.isModifySpectrumLicence}
            lastSaved={lastSaved}
          />
          <RadiationPatternsSection
            onFocus={() => submit.isDone || setSubmit(submitRadiationPatterns)}
            licence={licence}
            maxPower={maxPower}
            serviceTypes={props.serviceTypes}
            onSave={() => {
              setSubmit(submitRadiationPatterns);
              formProps.submitForm().then(() => continueEditingSpectrum(formProps));
            }}
            onCancel={() => continueEditingSpectrum(formProps)}
            readOnly={isDone || props.isModifySpectrumLicence}
          />
        </LicenceSpectrumAccordion>
      )}
    </Formik>
  );
};

const LicenceSpectrumAccordion: React.FC<{
  expanded?: boolean;
  done?: boolean;
  onDone?: () => void;
  onValidateDone: () => void;
  onExpandCollapsed?: () => void;
  licence?: Licence;
  serviceTypes: ServiceTypeDto[];
  children?: React.ReactNode;
  baseCallSignCapture?: boolean;
  mobileCallSignCapture?: boolean;
}> = (props) => {
  const { validateSpectrums } = useDoneValidation();
  const { initialValues, errors, values } = useFormikContext<{ spectrums?: SpectrumDto[]; radiationPatterns?: RadiationPatternsFormProps }>();
  const [hasInitialError, setHasInitialError] = useState<boolean | undefined>();

  const hasError = !isEmpty(errors);
  const hasSpectrumFieldError = typeof errors?.spectrums === 'object';
  const licence = props.licence;
  const isInitialised = !!licence && !!initialValues.spectrums && !!initialValues.radiationPatterns && initialValues.spectrums.length > 0;
  const { classification, baseCallsigns, mobileCallsigns, craftingProgress } = licence ?? {};

  // initialErrors holds errors on load, where as "errors" holds errors when Done is pressed
  useEffect(() => {
    if (isInitialised && !craftingProgress?.isSpectrumDetailsDone) {
      const spectrums = initialValues.spectrums ?? [];
      const radiationPatterns = initialValues.radiationPatterns ?? { horizontal: [], vertical: [] };
      validateSpectrums(
        licence!,
        props.serviceTypes,
        { spectrums, radiationPatterns },
        (props.baseCallSignCapture || props.mobileCallSignCapture) ?? false,
      ).then((valid) => setHasInitialError(!valid));
    }
    // eslint-disable-next-line
  }, [isInitialised]);

  useEffect(() => {
    if (licence && isInitialised) {
      // Trigger validation and display any error when basic details licence classification has changed on save as UELs depends on this option.
      props.onValidateDone();
    }
  }, [classification, baseCallsigns, mobileCallsigns, licence?.managementRightId]);

  return (
    <Accordion {...props} id="spectrum-details" title="Spectrum details" error={!props.done && isInitialised && (hasInitialError || hasError)}>
      <ErrorLabel name="spectrums" showIcon alwaysValidate />
      {hasSpectrumFieldError && !!values.spectrums?.length && <Error errorMessage="One or more spectrums are invalid." showIcon />}
      {props.children}
    </Accordion>
  );
};

export const spectrumsAsFormValues = (spectrums: SpectrumDto[], classification?: LicenceClassificationType) =>
  spectrums.map(
    (spectrum) =>
      ({
        ...spectrum,
        // lowerBound and upperBound on frequency will be used for spectrum range validation
        frequencies: spectrum.frequencies?.map((frequency) => freqWithBounds(frequency, spectrum)),
        unwantedEmissionLimits:
          classification === LicenceClassificationType.C ? [] : spectrum.unwantedEmissionLimits?.map((uel) => uelWithBounds(uel, spectrum)),
      } as SpectrumDto),
  );

export default LicenceSpectrumDetails;
