import * as yup from 'yup';

import { requiredString } from '../../../../common/validation/yupUtils';

export const licenceAuthoritiesSchema = yup.object().shape({}).nullable(true);

export const licenceAuthoritiesDoneSchema = yup.object().shape({
  transferAuthority: requiredString,
  cancelAuthority: requiredString,
  modifyAuthority: requiredString,
});
