import {
  AntennaDto,
  BaseLocationDto,
  DefinedAreaDto,
  EquipmentDto,
  EquipmentSearchCriteria,
  LocationNameDto,
  MultipointDto,
  PointDto,
  SpectrumMaskSearchCriteria,
  SpectrumMaskDto,
  SpectrumDto,
  EventCriteria,
  EventResults,
  GeoreferenceDto,
  GeoreferenceGroupDto,
} from 'api-client';

import { ApiConfig, useApi } from '../../common/api/ApiHook';
import { referenceDataApi } from '../../common/api/api';

export const useReferenceDataHookApi = () => {
  const { aroundApi } = useApi();

  return {
    createEquipment: (equipment: EquipmentDto, config?: ApiConfig): Promise<EquipmentDto> =>
      aroundApi(referenceDataApi.createEquipment(equipment), config),
    updateEquipment: (equipment: EquipmentDto, config?: ApiConfig): Promise<EquipmentDto> =>
      aroundApi(referenceDataApi.updateEquipment(equipment), config),
    getEquipment: (equipmentId: number, config?: ApiConfig): Promise<EquipmentDto> => aroundApi(referenceDataApi.getEquipment(equipmentId), config),
    searchEquipment: (criteria: EquipmentSearchCriteria, config?: ApiConfig) => aroundApi(referenceDataApi.searchEquipment(criteria), config),
    createPoint: (point: PointDto, config?: ApiConfig): Promise<PointDto> =>
      aroundApi(referenceDataApi.createPoint({ ...point, locationDiscriminator: 'PointDto' }), config),
    createDefinedArea: (definedArea: DefinedAreaDto, config?: ApiConfig): Promise<DefinedAreaDto> =>
      aroundApi(referenceDataApi.createDefinedArea({ ...definedArea, locationDiscriminator: 'DefinedAreaDto' }), config),
    createLocationName: (definedArea: LocationNameDto, config?: ApiConfig): Promise<LocationNameDto> =>
      aroundApi(referenceDataApi.createLocationName({ ...definedArea, locationDiscriminator: 'LocationNameDto' }), config),
    createMultipoint: (multipointDto: MultipointDto, config?: ApiConfig): Promise<MultipointDto> =>
      aroundApi(referenceDataApi.createMultipoint({ ...multipointDto, locationDiscriminator: 'MultipointDto' }), config),
    getLocation: (locationId: number, config?: ApiConfig): Promise<BaseLocationDto> => aroundApi(referenceDataApi.getLocation(locationId), config),
    updatePoint: (pointDto: PointDto, options?: ApiConfig): Promise<BaseLocationDto> =>
      aroundApi(referenceDataApi.updatePoint({ ...pointDto, locationDiscriminator: 'PointDto' }), options),
    updateMultipoint: (multipointDto: MultipointDto, options?: ApiConfig): Promise<BaseLocationDto> =>
      aroundApi(referenceDataApi.updateMultipoint({ ...multipointDto, locationDiscriminator: 'MultipointDto' }), options),
    updateLocationName: (locationNameDto: LocationNameDto, options?: ApiConfig): Promise<BaseLocationDto> =>
      aroundApi(referenceDataApi.updateLocationName({ ...locationNameDto, locationDiscriminator: 'LocationNameDto' }), options),
    updateDefinedArea: (definedAreaDto: DefinedAreaDto, options?: ApiConfig): Promise<BaseLocationDto> =>
      aroundApi(referenceDataApi.updateDefinedArea({ ...definedAreaDto, locationDiscriminator: 'DefinedAreaDto' }), options),
    createAntenna: (antenna: AntennaDto, config?: ApiConfig): Promise<AntennaDto> => aroundApi(referenceDataApi.createAntenna(antenna), config),
    updateAntenna: (antenna: AntennaDto, config?: ApiConfig): Promise<AntennaDto> => aroundApi(referenceDataApi.updateAntenna(antenna), config),
    getAntenna: (antennaId: number, config?: ApiConfig): Promise<AntennaDto> => aroundApi(referenceDataApi.getAntenna(antennaId), config),
    searchSpectrumMask: (criteria: SpectrumMaskSearchCriteria, config?: ApiConfig) =>
      aroundApi(referenceDataApi.searchSpectrumMask(criteria), config),
    createSpectrumMask: (spectrumMask: SpectrumMaskDto, config?: ApiConfig): Promise<SpectrumMaskDto> =>
      aroundApi(referenceDataApi.createSpectrumMask(spectrumMask), config),
    updateSpectrumMask: (spectrumMask: SpectrumMaskDto, config?: ApiConfig): Promise<SpectrumMaskDto> =>
      aroundApi(referenceDataApi.updateSpectrumMask(spectrumMask), config),
    getSpectrumMask: (spectrumMaskId: number, config?: ApiConfig): Promise<SpectrumMaskDto> =>
      aroundApi(referenceDataApi.getSpectrumMask(spectrumMaskId), config),
    updateSpectrumMaskVisibility: (spectrumMask: SpectrumMaskDto, config?: ApiConfig): Promise<SpectrumMaskDto> =>
      aroundApi(referenceDataApi.updateSpectrumMaskVisibility(spectrumMask), config),
    createSpectrumRecord: (spectrumRecord: SpectrumDto, config?: ApiConfig): Promise<SpectrumDto> =>
      aroundApi(referenceDataApi.createSpectrumRecord(spectrumRecord), config),
    getSpectrumRecord: (spectrumId: number, config?: ApiConfig): Promise<SpectrumDto> =>
      aroundApi(referenceDataApi.getSpectrumById(spectrumId), config),
    updateSpectrumRecord: (spectrum: SpectrumDto, config?: ApiConfig): Promise<SpectrumDto> =>
      aroundApi(referenceDataApi.updateSpectrumRecord(spectrum), config),
    getLocationEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(referenceDataApi.getLocationEvents(entityId, criteria), config),
    getSpectrumEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(referenceDataApi.getSpectrumEvents(entityId, criteria), config),
    getSpectrumMaskEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(referenceDataApi.getSpectrumMaskEvents(entityId, criteria), config),
    convertGeoReferenceType: (geoReferenceType: GeoreferenceDto, config?: ApiConfig): Promise<GeoreferenceGroupDto> =>
      aroundApi(referenceDataApi.convertGeoReferenceType(geoReferenceType), config),
  };
};
