import { IDropdownOption } from '@fluentui/react';

import { isEmpty } from '../common/utils/objectUtils';

const qs = require('qs');

export const stringifyQueryObject = (queryObj: any) => {
  return qs.stringify(queryObj, { skipNulls: true, arrayFormat: 'indices' });
};

export const parseQueryString = (defaultValues: {}) => {
  const queryStringValues = window.location.search
    ? qs.parse(window.location.search.substr(1), { parseNumbers: true, parseBooleans: true, arrayFormat: 'indices' })
    : {};
  return isEmpty(queryStringValues) ? defaultValues : queryStringValues;
};

export const hiddenOptions = [
  { key: '', text: '' },
  { key: 'true', text: 'Hidden' },
  { key: 'false', text: 'Visible' },
] as IDropdownOption[];

export class AppliedFilters {
  private appliedFilters: string[] = [];

  push(aFilter?: any, label?: string) {
    if (Array.isArray(aFilter) && !aFilter.length) {
      return this;
    }

    if (aFilter || aFilter === 0) {
      this.appliedFilters.push(!label ? aFilter : `${label}: ${aFilter}`);
    }
    return this;
  }

  pushRange(from?: any, to?: any, label?: string) {
    const rangeMessage = !from && from !== 0 ? (!to && to !== 0 ? undefined : '- ' + to) : !to && to !== 0 ? from + ' -' : from + ' - ' + to;
    this.push(rangeMessage, label);
    return this;
  }

  get(): string[] {
    return this.appliedFilters;
  }

  isEmpty(): boolean {
    return this.appliedFilters.length === 0;
  }
}

export const toSortProps = (orderBy?: string) => {
  if (!orderBy) return undefined;
  const items = orderBy.split(' ');
  if (items.length !== 2) return undefined;
  return {
    sortKey: items[0],
    descending: items[1] === 'desc',
  };
};

export const searchResultsFocus = () => {
  document?.getElementById('search-button')?.scrollIntoView({ behavior: 'smooth' });
};
