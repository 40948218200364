import * as React from 'react';
import { Switch } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';

const Form11Page = React.lazy(() => import('licence_management/mortgage_caveat/form_11/Form11Page'));
const Form18Page = React.lazy(() => import('licence_management/mortgage_caveat/form_18/Form18Page'));
const Form20Page = React.lazy(() => import('licence_management/mortgage_caveat/form_20/Form20Page'));
const Form21Page = React.lazy(() => import('licence_management/mortgage_caveat/form_21/Form21Page'));

export const useMortgageCaveatNavigator = () => {
  const history = useHistory();

  return {
    navigateToCreateForm11: (formId?: number) => history.push(`/ui/app/licence/mortgage-caveat/form-11/${formId}`),
    navigateToCreateForm18: (formId?: number) => history.push(`/ui/app/licence/mortgage-caveat/form-18/${formId}`),
    navigateToCreateForm20: (formId?: number) => history.push(`/ui/app/licence/mortgage-caveat/form-20/${formId}`),
    navigateToCreateForm21: (formId?: number) => history.push(`/ui/app/licence/mortgage-caveat/form-21/${formId}`),
  };
};

const MortgageCaveatRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ProtectedRoute path={`${path}/form-11/:formId`} component={Form11Page} />
      <ProtectedRoute path={`${path}/form-18`} component={Form18Page} exact={true} />
      <ProtectedRoute path={`${path}/form-18/:formId`} component={Form18Page} />
      <ProtectedRoute path={`${path}/form-20`} component={Form20Page} exact={true} />
      <ProtectedRoute path={`${path}/form-20/:formId`} component={Form20Page} />
      <ProtectedRoute path={`${path}/form-21`} component={Form21Page} exact={true} />
      <ProtectedRoute path={`${path}/form-21/:formId`} component={Form21Page} />
    </Switch>
  );
};

export default MortgageCaveatRouter;
