import { useFormikContext } from 'formik';

import { GeoreferenceGroupDto } from 'api-client';

import { Grid } from 'ui-library';

import Checkbox from 'common/controls/inputs/Checkbox';
import Dropdown from 'common/controls/inputs/Dropdown';
import { TextField } from 'common/controls/inputs/TextField';
import { SectionHeading } from 'common/layout/SectionHeadings';

import { georeferenceTypes } from '../locationSupportData';
import Georeferences from './Georeferences';
import HeaderInformation from './HeaderInformation';
import LocationAuditInformation from './LocationAuditInformation';
import { LocationFormProps } from './PointFields';

const MultipointFields = (props: LocationFormProps) => {
  const { values, setValues } = useFormikContext<any>();
  const georefTypeChange = (key?: any) => {
    setValues(
      {
        ...values,
        georeferenceType: key,
        georeferences: values.georeferences.map((g: GeoreferenceGroupDto) => ({
          ...g,
          origin: {
            ...g.origin,
            type: key,
            mapNumber: undefined,
            easting: undefined,
            northing: undefined,
          },
        })),
      },
      true,
    );
  };

  return (
    <>
      <HeaderInformation type={'Multiple Points'} />
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Location description'} name={'description'} multiline />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Site owner details'} name={'siteDetails'} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Checkbox name={'verified'} label={'Verified'} />
        </Grid.Col>
        {props.showHidden && (
          <Grid.Col sm={12} lg={5}>
            <Checkbox name={'hidden'} label={'Hidden'} />
          </Grid.Col>
        )}
      </Grid.Row>
      {props.showAudit && <LocationAuditInformation />}
      <SectionHeading title={'Georeference details'} hint="Note - Graph points can be re-ordered using drag and drop" />
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Dropdown name="georeferenceType" label={'Georeference type'} options={georeferenceTypes} required onChange={georefTypeChange} />
        </Grid.Col>
      </Grid.Row>

      <Georeferences />
    </>
  );
};

export default MultipointFields;
