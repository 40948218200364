import { useFormikContext } from 'formik';
import React from 'react';

import { LicenceClassificationType, LicenceDto, LicenceDtoLicenceTypeCodeEnum, SpectrumDtoSpectrumTypeEnum as SpectrumType } from 'api-client';

import { Grid } from 'ui-library';

import Dropdown from 'common/controls/inputs/Dropdown';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { SubSectionHeading } from 'common/layout/SectionHeadings';
import { formatDateFromISO } from 'common/utils/dateUtils';

import { polarisationOptions } from '../../../reference_data/spectrum_record/references';

const channelMap = new Map<SpectrumType, string>([
  [SpectrumType.BND, 'Band'],
  [SpectrumType.CHL, 'Channel'],
  [SpectrumType.FRQ, 'Frequency'],
  [SpectrumType.MRT, 'Management Right'],
  [SpectrumType.ITU, 'ITU Allocation'],
]);

const SpectrumDetailsSection = ({ spectrumIdx, licence }: { spectrumIdx: number; licence?: LicenceDto }) => {
  const { values } = useFormikContext<any>();

  const name = `spectrums[${spectrumIdx}]`;

  const isRepeaterLicence = [67].includes(licence?.licenceTypeId ?? 0);
  const isSimplexLicence = [71, 73, 211].includes(licence?.licenceTypeId ?? 0);
  const isAboveThirtyMHz = values.spectrums.at(spectrumIdx).lowerBound >= 30;

  return (
    <>
      <SubSectionHeading title="Spectrum details" />
      <Grid.Row>
        <Grid.Col lg={3} sm={12}>
          <ReadOnlyField label="Spectrum type" name={`${name}.spectrumType`} renderFormat={(t) => channelMap.get(t)} />
        </Grid.Col>
        <Grid.Col lg={3} sm={12}>
          <ReadOnlyField label="Spectrum status" name={`${name}.spectrumStatus`} />
        </Grid.Col>
        <Grid.Col lg={3} sm={12}>
          <ReadOnlyField label="Service type" name={`${name}.serviceType`} />
        </Grid.Col>
        {(licence?.licenceTypeCode !== LicenceDtoLicenceTypeCodeEnum.SPL || licence?.classification !== LicenceClassificationType.C) && (
          <Grid.Col lg={3} sm={12}>
            <Dropdown label="Polarisation" name={`${name}.polarisation`} options={polarisationOptions} required />
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={3} sm={12}>
          <ReadonlyData label="Start date" value={formatDateFromISO(licence?.commencementDate)} />
        </Grid.Col>
        <Grid.Col lg={3} sm={12}>
          <ReadonlyData label="End date" value={formatDateFromISO(licence?.expiryDate)} />
        </Grid.Col>
        {(isSimplexLicence || isRepeaterLicence) && isAboveThirtyMHz && (
          <Grid.Col lg={3} sm={12}>
            <TextField label="Access code" name={`${name}.accessCode`} maxLength={20} required={isSimplexLicence} />
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6} sm={12}>
          <TextField label="Remarks" name={`${name}.remarks`} multiline maxLength={400} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default SpectrumDetailsSection;
