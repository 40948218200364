const heightUnits = [
  { key: 'M', text: 'metres' },
  { key: 'K', text: 'kilometres' },
];

const sources = [
  { key: 'GPS', text: 'GPS' },
  { key: 'Map', text: 'Map' },
  { key: 'Surveyed', text: 'Surveyed' },
  { key: 'Unknown', text: 'Unknown' },
];

const verifiedSources = [
  { key: 'GPS', text: 'GPS' },
  { key: 'Surveyed', text: 'Surveyed' },
];

const georeferenceTypes = [
  { key: 'T', text: 'TOPO50' },
  { key: 'TM2000', text: 'NZTM2000' },
  { key: 'D2000', text: 'LAT/LONG (NZGD2000)' },
  { key: 'M', text: 'NZMS260 (METRIC)' },
  { key: 'L', text: 'NZMG (LONG REF)' },
];

const geoRefDescFromType = (keyMatch: string) => {
  for (let grt of georeferenceTypes) {
    if (grt.key === keyMatch) {
      return grt.text;
    }
  }
  return '';
};

const locationTypes = [
  { key: 'P', text: 'Point', navigationType: 'point' },
  { key: 'M', text: 'Multiple Points', navigationType: 'multiplepoints' },
  { key: 'A', text: 'Defined Area', navigationType: 'definedarea' },
  { key: 'N', text: 'Name', navigationType: 'locationname' },
];

const offices = [
  { key: 'NL', text: 'Northland' },
  { key: 'AK', text: 'Auckland' },
  { key: 'WK', text: 'Waikato' },
  { key: 'BP', text: 'Bay of Plenty' },
  { key: 'GS', text: 'Gisborne' },
  { key: 'TK', text: 'Taranaki/King Country' },
  { key: 'TP', text: 'Taupo' },
  { key: 'HB', text: "Hawke's Bay" },
  { key: 'MW', text: 'Manawatu/Wanganui' },
  { key: 'WN', text: 'Wellington' },
  { key: 'MB', text: 'Marlborough' },
  { key: 'NT', text: 'Nelson/Tasman' },
  { key: 'WC', text: 'West Coast' },
  { key: 'CB', text: 'Canterbury' },
  { key: 'OT', text: 'Otago' },
  { key: 'SL', text: 'Southland' },
  { key: 'CI', text: 'Chatham Islands' },
  { key: 'NZ', text: 'New Zealand' },
  { key: 'OS', text: 'Overseas' },
];

const validNZMS260MapNumbers = [
  'A44',
  'A45',
  'B41',
  'B42',
  'B43',
  'B44',
  'B45',
  'B46',
  'C40',
  'C41',
  'C42',
  'C43',
  'C44',
  'C45',
  'C46',
  'C49',
  'C50',
  'D38',
  'D39',
  'D40',
  'D41',
  'D42',
  'D43',
  'D44',
  'D45',
  'D46',
  'D47',
  'D48',
  'D49',
  'D50',
  'E37',
  'E38',
  'E39',
  'E40',
  'E41',
  'E42',
  'E43',
  'E44',
  'E45',
  'E46',
  'E47',
  'E48',
  'E49',
  'F36',
  'F37',
  'F38',
  'F39',
  'F40',
  'F41',
  'F42',
  'F43',
  'F44',
  'F45',
  'F46',
  'F47',
  'F48',
  'G35',
  'G36',
  'G37',
  'G38',
  'G39',
  'G40',
  'G41',
  'G42',
  'G43',
  'G44',
  'G45',
  'G46',
  'G47',
  'H34',
  'H35',
  'H36',
  'H37',
  'H38',
  'H39',
  'H40',
  'H41',
  'H42',
  'H43',
  'H44',
  'H45',
  'H46',
  'H47',
  'I33',
  'I34',
  'I35',
  'I36',
  'I37',
  'I38',
  'I39',
  'I40',
  'I41',
  'I42',
  'I43',
  'I44',
  'I45',
  'J31',
  'J32',
  'J33',
  'J34',
  'J35',
  'J36',
  'J37',
  'J38',
  'J39',
  'J40',
  'J41',
  'J42',
  'J43',
  'J44',
  'K29',
  'K30',
  'K31',
  'K32',
  'K33',
  'K34',
  'K35',
  'K36',
  'K37',
  'K38',
  'K39',
  'L01',
  'L25',
  'L26',
  'L27',
  'L28',
  'L29',
  'L30',
  'L31',
  'L32',
  'L33',
  'L34',
  'L35',
  'L36',
  'L37',
  'M02',
  'M24',
  'M25',
  'M26',
  'M27',
  'M28',
  'M29',
  'M30',
  'M31',
  'M32',
  'M33',
  'M34',
  'M35',
  'M36',
  'M37',
  'N02',
  'N03',
  'N04',
  'N05',
  'N24',
  'N25',
  'N26',
  'N27',
  'N28',
  'N29',
  'N30',
  'N31',
  'N32',
  'N33',
  'N34',
  'N36',
  'N37',
  'O03',
  'O04',
  'O05',
  'O06',
  'O07',
  'O26',
  'O27',
  'O28',
  'O29',
  'O30',
  'O31',
  'O32',
  'O33',
  'P04',
  'P05',
  'P06',
  'P07',
  'P08',
  'P09',
  'P19',
  'P20',
  'P21',
  'P25',
  'P26',
  'P27',
  'P28',
  'P29',
  'P30',
  'P31',
  'Q04',
  'Q05',
  'Q06',
  'Q07',
  'Q08',
  'Q09',
  'Q10',
  'Q11',
  'Q12',
  'Q15',
  'Q18',
  'Q19',
  'Q20',
  'Q21',
  'Q22',
  'Q26',
  'Q27',
  'Q29',
  'R06',
  'R07',
  'R08',
  'R09',
  'R10',
  'R11',
  'R12',
  'R13',
  'R14',
  'R15',
  'R16',
  'R17',
  'R18',
  'R19',
  'R20',
  'R21',
  'R22',
  'R23',
  'R25',
  'R26',
  'R27',
  'R28',
  'S07',
  'S08',
  'S09',
  'S10',
  'S11',
  'S12',
  'S13',
  'S14',
  'S15',
  'S16',
  'S17',
  'S18',
  'S19',
  'S20',
  'S21',
  'S22',
  'S23',
  'S24',
  'S25',
  'S26',
  'S27',
  'S28',
  'T08',
  'T09',
  'T10',
  'T11',
  'T12',
  'T13',
  'T14',
  'T15',
  'T16',
  'T17',
  'T18',
  'T19',
  'T20',
  'T21',
  'T22',
  'T23',
  'T24',
  'T25',
  'T26',
  'T27',
  'T28',
  'U10',
  'U11',
  'U12',
  'U13',
  'U14',
  'U15',
  'U16',
  'U17',
  'U18',
  'U19',
  'U20',
  'U21',
  'U22',
  'U23',
  'U24',
  'U25',
  'U26',
  'V14',
  'V15',
  'V16',
  'V17',
  'V18',
  'V19',
  'V20',
  'V21',
  'V22',
  'V23',
  'V24',
  'W13',
  'W14',
  'W15',
  'W16',
  'W17',
  'W18',
  'W19',
  'W20',
  'W21',
  'W22',
  'X14',
  'X15',
  'X16',
  'X17',
  'X18',
  'X19',
  'X20',
  'Y14',
  'Y15',
  'Y16',
  'Y17',
  'Y18',
  'Y19',
  'Y20',
  'Z14',
  'Z15',
  'Z16',
  'Z17',
];

const validTopo50MapNumbers = [
  'AS21',
  'AT24',
  'AT25',
  'AU25',
  'AU26',
  'AU27',
  'AU28',
  'AU29',
  'AV25',
  'AV26',
  'AV27',
  'AV28',
  'AV29',
  'AV30',
  'AW26',
  'AW27',
  'AW28',
  'AW29',
  'AW30',
  'AW31',
  'AW32',
  'AX27',
  'AX28',
  'AX29',
  'AX30',
  'AX31',
  'AX32',
  'AX33',
  'AY28',
  'AY29',
  'AY30',
  'AY31',
  'AY32',
  'AY33',
  'AY34',
  'AZ29',
  'AZ30',
  'AZ31',
  'AZ32',
  'AZ34',
  'AZ35',
  'AZ36',
  'BA30',
  'BA31',
  'BA32',
  'BA33',
  'BA34',
  'BA35',
  'BA36',
  'BB30',
  'BB31',
  'BB32',
  'BB33',
  'BB34',
  'BB35',
  'BB36',
  'BB37',
  'BC31',
  'BC32',
  'BC33',
  'BC34',
  'BC35',
  'BC36',
  'BC37',
  'BC40',
  'BD31',
  'BD32',
  'BD33',
  'BD34',
  'BD35',
  'BD36',
  'BD37',
  'BD38',
  'BD39',
  'BD40',
  'BD42',
  'BD43',
  'BD44',
  'BD45',
  'BE31',
  'BE32',
  'BE33',
  'BE34',
  'BE35',
  'BE36',
  'BE37',
  'BE38',
  'BE39',
  'BE40',
  'BE41',
  'BE42',
  'BE43',
  'BE44',
  'BE45',
  'BF31',
  'BF32',
  'BF33',
  'BF34',
  'BF35',
  'BF36',
  'BF37',
  'BF38',
  'BF39',
  'BF40',
  'BF41',
  'BF42',
  'BF43',
  'BF44',
  'BF45',
  'BG30',
  'BG31',
  'BG32',
  'BG33',
  'BG34',
  'BG35',
  'BG36',
  'BG37',
  'BG38',
  'BG39',
  'BG40',
  'BG41',
  'BG42',
  'BG43',
  'BG44',
  'BH28',
  'BH29',
  'BH30',
  'BH31',
  'BH32',
  'BH33',
  'BH34',
  'BH35',
  'BH36',
  'BH37',
  'BH38',
  'BH39',
  'BH40',
  'BH41',
  'BH42',
  'BH43',
  'BJ28',
  'BJ29',
  'BJ30',
  'BJ31',
  'BJ32',
  'BJ33',
  'BJ34',
  'BJ35',
  'BJ36',
  'BJ37',
  'BJ38',
  'BJ39',
  'BJ40',
  'BJ43',
  'BK28',
  'BK29',
  'BK30',
  'BK31',
  'BK32',
  'BK33',
  'BK34',
  'BK35',
  'BK36',
  'BK37',
  'BK38',
  'BK39',
  'BK40',
  'BL31',
  'BL32',
  'BL33',
  'BL34',
  'BL35',
  'BL36',
  'BL37',
  'BL38',
  'BL39',
  'BM24',
  'BM25',
  'BM33',
  'BM34',
  'BM35',
  'BM36',
  'BM37',
  'BM38',
  'BM39',
  'BN22',
  'BN23',
  'BN24',
  'BN25',
  'BN28',
  'BN29',
  'BN32',
  'BN33',
  'BN34',
  'BN35',
  'BN36',
  'BN37',
  'BN38',
  'BP22',
  'BP23',
  'BP24',
  'BP25',
  'BP26',
  'BP27',
  'BP28',
  'BP29',
  'BP30',
  'BP31',
  'BP32',
  'BP33',
  'BP34',
  'BP35',
  'BP36',
  'BQ21',
  'BQ22',
  'BQ23',
  'BQ24',
  'BQ25',
  'BQ26',
  'BQ27',
  'BQ28',
  'BQ29',
  'BQ31',
  'BQ32',
  'BQ33',
  'BQ34',
  'BQ35',
  'BQ36',
  'BR20',
  'BR21',
  'BR22',
  'BR23',
  'BR24',
  'BR25',
  'BR26',
  'BR27',
  'BR28',
  'BR29',
  'BR33',
  'BR34',
  'BS19',
  'BS20',
  'BS21',
  'BS22',
  'BS23',
  'BS24',
  'BS25',
  'BS26',
  'BS27',
  'BS28',
  'BS29',
  'BT19',
  'BT20',
  'BT21',
  'BT22',
  'BT23',
  'BT24',
  'BT25',
  'BT26',
  'BT27',
  'BT28',
  'BU18',
  'BU19',
  'BU20',
  'BU21',
  'BU22',
  'BU23',
  'BU24',
  'BU25',
  'BU26',
  'BU27',
  'BV16',
  'BV17',
  'BV18',
  'BV19',
  'BV20',
  'BV21',
  'BV22',
  'BV23',
  'BV24',
  'BV25',
  'BV26',
  'BW14',
  'BW15',
  'BW16',
  'BW17',
  'BW18',
  'BW19',
  'BW20',
  'BW21',
  'BW22',
  'BW23',
  'BW24',
  'BW25',
  'BX12',
  'BX13',
  'BX14',
  'BX15',
  'BX16',
  'BX17',
  'BX18',
  'BX19',
  'BX20',
  'BX21',
  'BX22',
  'BX23',
  'BX24',
  'BX25',
  'BY10',
  'BY11',
  'BY12',
  'BY13',
  'BY14',
  'BY15',
  'BY16',
  'BY17',
  'BY18',
  'BY19',
  'BY20',
  'BY21',
  'BY22',
  'BY23',
  'BY24',
  'BY25',
  'BZ09',
  'BZ10',
  'BZ11',
  'BZ12',
  'BZ13',
  'BZ14',
  'BZ15',
  'BZ16',
  'BZ17',
  'BZ18',
  'BZ19',
  'BZ20',
  'BZ21',
  'CA07',
  'CA08',
  'CA09',
  'CA10',
  'CA11',
  'CA12',
  'CA13',
  'CA14',
  'CA15',
  'CA16',
  'CA17',
  'CA18',
  'CA19',
  'CB06',
  'CB07',
  'CB08',
  'CB09',
  'CB10',
  'CB11',
  'CB12',
  'CB13',
  'CB14',
  'CB15',
  'CB16',
  'CB17',
  'CB18',
  'CB19',
  'CC05',
  'CC06',
  'CC07',
  'CC08',
  'CC09',
  'CC10',
  'CC11',
  'CC12',
  'CC13',
  'CC14',
  'CC15',
  'CC16',
  'CC17',
  'CC18',
  'CC19',
  'CD04',
  'CD05',
  'CD06',
  'CD07',
  'CD08',
  'CD09',
  'CD10',
  'CD11',
  'CD12',
  'CD13',
  'CD14',
  'CD15',
  'CD16',
  'CD17',
  'CD18',
  'CE04',
  'CE05',
  'CE06',
  'CE07',
  'CE08',
  'CE09',
  'CE10',
  'CE11',
  'CE12',
  'CE13',
  'CE14',
  'CE15',
  'CE16',
  'CE17',
  'CE18',
  'CF04',
  'CF05',
  'CF06',
  'CF07',
  'CF08',
  'CF09',
  'CF10',
  'CF11',
  'CF12',
  'CF13',
  'CF14',
  'CF15',
  'CF16',
  'CG05',
  'CG06',
  'CG07',
  'CG08',
  'CG09',
  'CG10',
  'CG11',
  'CG12',
  'CG13',
  'CG14',
  'CG15',
  'CH05',
  'CH08',
  'CH09',
  'CH10',
  'CH11',
  'CH12',
  'CH13',
  'CI01',
  'CI02',
  'CI03',
  'CI04',
  'CI05',
  'CI06',
  'CJ07',
  'CJ08',
  'CJ09',
  'CJ10',
  'CK08',
];

export {
  heightUnits,
  sources,
  georeferenceTypes,
  geoRefDescFromType,
  offices,
  verifiedSources,
  validNZMS260MapNumbers,
  validTopo50MapNumbers,
  locationTypes,
};
