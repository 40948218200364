import * as React from 'react';

import { SpectrumDto } from 'api-client';

import Card from '../../../../common/controls/surfaces/Card';
import Form from '../../../../common/form/Form';
import { SubSectionHeading } from '../../../../common/layout/SectionHeadings';
import SpectrumDetailsList, { SpectrumDetails } from './SpectrumDetailsList';

const SpectrumDetailsForm = ({
  spectrum,
  onSaveSpectrum,
  onRemoveSpectrum,
  isDone,
  lastSaved,
  title,
  onFocus,
}: {
  spectrum: SpectrumDto;
  onSaveSpectrum: () => void;
  onRemoveSpectrum: (spectrumId: number) => Promise<any>;
  isDone: boolean;
  lastSaved?: Date;
  title?: string;
  onFocus: () => void;
}) => {
  return !spectrum?.id ? (
    <div className="ms-Grid-row" onFocus={onFocus}>
      <Card>
        <SubSectionHeading title={title ?? 'Create a spectrum record'} />
        <Form id="spectrum-details" onSubmit={onSaveSpectrum} showFormButtonsBottom mode="EDIT" hideBack hideFormButtonsTop disableErrorMessage>
          <SpectrumDetails />
        </Form>
      </Card>
    </div>
  ) : (
    <div className="ms-Grid-row" onFocus={onFocus}>
      <SpectrumDetailsList
        spectrums={[spectrum]}
        onRemoveSpectrum={onRemoveSpectrum}
        onSaveSpectrum={onSaveSpectrum}
        readOnly={isDone}
        lastSaved={lastSaved}
      />
    </div>
  );
};

export default SpectrumDetailsForm;
