import { Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import { useForm } from 'common/form/FormHook';

const EquipmentFields = () => {
  const { mode } = useForm();
  const maintainForm = mode === 'EDIT' || mode === 'VIEW';
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Make" name={'make'} required />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Model" name={'model'} required />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Band" name={'band'} />
        </Grid.Col>
      </Grid.Row>
      {maintainForm && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <TextField label="Channel size" name={'channelSize'} />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Remarks" name={'remarks'} multiline />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default EquipmentFields;
