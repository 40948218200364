import { Label } from '@fluentui/react';
import * as React from 'react';

import { LicenceTypeDto } from 'api-client';

import Grid from 'ui-library/grid';

import TagPicker from '../../../common/controls/inputs/TagPicker';
import { TextField } from '../../../common/controls/inputs/TextField';
import TooltipHint from '../../../common/controls/items/TooltipHint';
import { ApplyLicencePageContext } from '../ApplyLicencePage';

const BaseMobileCallsignSection = ({ baseCallsign, mobileCallsign }: Pick<LicenceTypeDto, 'baseCallsign' | 'mobileCallsign'>) => {
  const { baseCallSignCapture, mobileCallSignCapture } = React.useContext(ApplyLicencePageContext);

  return (
    <>
      {baseCallsign && baseCallSignCapture && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <TagPicker
              label="Base callsign(s)"
              name="baseCallsigns"
              onResolveSuggestions={() => []}
              allowFreeForm
              inputToFieldValue={(callSign) => callSign.toUpperCase()}
              required={baseCallSignCapture}
            />
          </Grid.Col>
          <Grid.Col lg={7}>
            <Label>&nbsp;</Label>
            <TooltipHint content="Use 'Tab' or a mouse click outside the field to add the base callsign" />
          </Grid.Col>
        </Grid.Row>
      )}

      {baseCallsign && !baseCallSignCapture && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <TextField name="baseCallsignNotes" multiline label="Base callsign(s)" />
          </Grid.Col>
          <Grid.Col lg={7}>
            <Label>&nbsp;</Label>
            <TooltipHint content="You can enter one or more free-form text callsigns here. Multiple callsigns must be separated by a comma." />
          </Grid.Col>
        </Grid.Row>
      )}

      {mobileCallsign && mobileCallSignCapture && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <TagPicker
              label="Mobile callsign(s)"
              name="mobileCallsigns"
              onResolveSuggestions={() => []}
              allowFreeForm
              inputToFieldValue={(callSign) => callSign.toUpperCase()}
              required={mobileCallSignCapture}
            />
          </Grid.Col>
          <Grid.Col lg={7}>
            <Label>&nbsp;</Label>
            <TooltipHint content="Use 'Tab' or a mouse click outside the field to add the mobile callsign" />
          </Grid.Col>
        </Grid.Row>
      )}

      {mobileCallsign && !mobileCallSignCapture && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <TextField name="mobileCallsignNotes" multiline label="Mobile callsign(s)" />
          </Grid.Col>
          <Grid.Col lg={7}>
            <Label>&nbsp;</Label>
            <TooltipHint content="You can enter one or more free-form text callsigns here. Multiple callsigns must be separated by a comma." />
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};
export default BaseMobileCallsignSection;
