import { Icon, Label, Text, useTheme } from '@fluentui/react';
import * as React from 'react';

interface Props {
  errorMessage?: string;
  showIcon?: boolean;
}

const Error: React.FC<Props> = (props) => {
  const theme = useTheme();
  const color = theme.semanticColors.errorText;
  const styles = { root: { color } };
  const iconStyle = { lineHeight: '32px', color, fontSize: 20, verticalAlign: 'middle', marginRight: 5 };

  return (
    <Label role="alert">
      {props.showIcon && <Icon iconName="InfoSolid" style={iconStyle} />}
      <Text styles={styles}>{props.errorMessage}</Text>
    </Label>
  );
};

export default Error;
