import React, { CSSProperties, FC } from 'react';

import './grid.scss';

interface ColumnProps {
  align?: 'center' | 'left' | 'right';
  lg?: number | 'hidden';
  md?: number | 'hidden';
  sm?: number | 'hidden';
  colStart?: number;
  style?: CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

interface Grid2Props {
  layout?: Partial<{
    lg: number;
    md: number;
    sm: number;
  }>;
  colGap?: 1 | 2 | 3 | 4 | 5;
  rowGap?: 1 | 2 | 3 | 4 | 5;
  styles?: CSSProperties;
  children?: React.ReactNode;
}

interface IGrid2 extends FC<Grid2Props> {
  Col: FC<ColumnProps>;
  children?: React.ReactNode;
}

/**
 * @deprecated Use Grid instead of Grid2.
 */
const Col: FC<ColumnProps> = (props) => {
  const { align = 'left', children, style } = props;
  const { layout } = props as any;

  const lg = `lg-${props.lg || layout.lg}`;
  const md = `md-${props.md || props.lg || layout.lg}`;
  const sm = `sm-${props.sm || 1}`;

  const columnStyle = `col ${sm} ${md} ${lg} ${props.colStart ? `start-${props.colStart}` : ''}`;

  return (
    <div
      className={columnStyle.trim() + ' ' + (props.className ?? '')}
      style={{
        ...style,
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
};
/**
 * @deprecated Use Grid instead of Grid2.
 */
const Grid2: IGrid2 = ({ children, colGap = 1, layout = {}, rowGap = 1, styles }) => {
  const { lg = 12, sm = 1 } = layout;
  const md = layout.md || lg;

  const childrenArray = React.Children.toArray(children);

  const cloneElement = (child: any) => {
    return React.cloneElement(child, {
      layout: {
        lg: lg === 12 ? lg : Math.ceil(lg / childrenArray.length),
        md: md === 12 ? md : Math.ceil(md / childrenArray.length),
        sm,
      },
    });
  };

  return (
    <div className={`grid sm-${sm} md-${md} lg-${lg} col-gap-${colGap} row-gap-${rowGap} `} style={styles}>
      {childrenArray.map((child: any) => {
        if (child.type === React.Fragment) {
          return React.Children.toArray(child.props.children).map(cloneElement);
        }

        return cloneElement(child);
      })}
    </div>
  );
};

Grid2.Col = Col;

export default Grid2;
