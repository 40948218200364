import { setFocusVisibility, useTheme } from '@fluentui/react';
import { FormikContextType } from 'formik';
import * as React from 'react';
import { useState } from 'react';

import AgreeViewSuppressedDialog from '../../licence_management/AgreeViewSuppressedDialog';
import ErrorBoundary from '../controls/error/ErrorBoundary';
import OverlaySpinner from '../controls/progress/OverlaySpinner';
import { FormMode } from '../form/Form';
import BackToTop from './BackToTop';
import Footer from './Footer';
import { Header } from './Header';
import { useMedia } from './MediaHook';
import { Navigation, NavigationContext } from './Navigation';
import { OutageNotificationBanner } from './OutageNotificationBanner';
import { getClassNames } from './Page.classNames';
import usePage from './PageHook';
import { PageInstruction } from './PageInstruction';

interface Props {
  title: string;
  leftNav?: JSX.Element;
  children?: React.ReactNode;
}

const Page: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { isMobile } = useMedia();
  const classNames = getClassNames(isMobile, theme);
  const { isLoading, outageNotificationsState } = usePage();
  const [formikContext, setFormikContext] = useState<FormikContextType<any>>();
  const [mode, setMode] = useState<FormMode>();

  setFocusVisibility(false);

  React.useEffect(() => {
    window.document.title = props.title;
  }, [props.title]);

  return (
    <>
      <div id="outage-notification-banner-div">
        {outageNotificationsState &&
          outageNotificationsState.length > 0 &&
          outageNotificationsState.map((item) => <OutageNotificationBanner item={item} preview={item.status === 'INACTIVE'} />)}
      </div>
      <div className={classNames.root} id="container">
        {isLoading && <OverlaySpinner label={isLoading === true ? 'Loading...' : isLoading} />}
        <NavigationContext.Provider value={{ formikContext, setFormikContext, mode, setMode }}>
          <Header />
          <Navigation title={props.title} />
          <PageInstruction />
          <main className={`ms-Grid ${classNames.main}`} dir="ltr">
            <ErrorBoundary>{props.children}</ErrorBoundary>
          </main>
          <BackToTop />
          <Footer />
        </NavigationContext.Provider>
        <AgreeViewSuppressedDialog />
      </div>
    </>
  );
};

export default Page;
