import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import CasualUserRoute from 'common/auth/CausualUserRoute';

import ProtectedRoute from '../../common/auth/ProtectedRoute';

const GrantDelegationPage = React.lazy(() => import('../delegation/GrantDelegationPage'));
const CreateClientPage = React.lazy(() => import('./create/CreateClientPage'));
const MaintainClientPage = React.lazy(() => import('./maintain/MaintainClientPage'));
const ReviewClientApplicationPage = React.lazy(() => import('./selfRegisterClient/ReviewClientApplicationPage'));
const SelfRegisterClientPage = React.lazy(() => import('./selfRegisterClient/SelfRegisterClientPage'));

export const MAINTAIN_CLIENT_URL = '/ui/app/client/maintain/';

const ClientRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute
          path={`${path}/delegation/:clientId`}
          component={GrantDelegationPage}
          exact={true}
          skipPermissionsForExternal={true}
          permission={['UPDATE_DELEGATION']}
        />
        <ProtectedRoute path={`${path}/create`} component={CreateClientPage} permission={['CREATE_CLIENT', 'CREATE_RESTRICTED_ORG_CLIENT']} />
        <ProtectedRoute
          path={`${path}/maintain/:clientId`}
          component={MaintainClientPage}
          skipPermissionsForExternal={true}
          permission={['CREATE_CLIENT', 'CREATE_RESTRICTED_ORG_CLIENT', 'UPDATE_CLIENT_INT_DETAILS', 'UPDATE_CLIENT_DETAILS']}
        />
        <ProtectedRoute path={`${path}/review/:taskId`} component={ReviewClientApplicationPage} exact={true} />

        <CasualUserRoute path={`${path}/self/create`} component={SelfRegisterClientPage} permission={['SELF_REG_CLIENT']} />
      </Switch>
    </>
  );
};

export const useClientNavigation = () => {
  const history = useHistory();

  return {
    navigateToCreateClient: () => history.push('/ui/app/client/create'),
    navigateToMaintainClient: (clientId: number) => history.push(`${MAINTAIN_CLIENT_URL}${clientId}`),
    navigateToGrantDelegation: (clientId: number) => history.push(`/ui/app/client/delegation/${clientId}`),
    navigateToSelfRegisterClient: () => history.push('/ui/app/client/self/create'),
    navigateToReviewClientApplication: (taskId: number) => history.push(`/ui/app/client/review/${taskId}`),
  };
};

export default ClientRouter;
