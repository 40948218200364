import { FieldAttributes, useField } from 'formik';
import { Checkbox as FluentUICheckbox, ICheckboxProps, ICheckboxStyles, Label, useTheme } from '@fluentui/react';
import * as React from 'react';
import { useForm } from '../../form/FormHook';
import ErrorLabel from './ErrorLabel';
import { renderHelpText } from './HelpTextRenderer';
import ReadOnlyField from './ReadOnlyField';
import { renderAsYesNo } from '../items/ReadonlyData';

interface CheckboxProps extends Omit<ICheckboxProps, 'checked' | 'onBlur'> {
  topMargin?: boolean;
  errorMessage?: string;
  helpText?: string;
  readOnly?: boolean;
}

const Checkbox: React.FC<FieldAttributes<CheckboxProps>> = (props) => {
  const theme = useTheme();
  const { mode } = useForm();
  const [field, meta, fieldHelper] = useField<any>(props);
  const errorStyles: ICheckboxStyles = { checkbox: { borderColor: theme.semanticColors.errorText } };
  const handleOnChange = (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    fieldHelper.setValue(checked);
    if (props.onChange) {
      props.onChange(_event, checked);
    }
  };

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField {...props} renderFormat={renderAsYesNo} />
  ) : (
    <>
      {props.topMargin && <Label />}
      <FluentUICheckbox
        {...props}
        {...field}
        inputProps={{ 'data-automation-id': props.name } as any}
        onChange={handleOnChange}
        checked={field.value ? field.value : false}
        styles={meta.error || props.errorMessage ? { ...props.styles, ...errorStyles } : props.styles}
        onRenderLabel={renderHelpText}
        disabled={mode === 'DISABLED' || props.disabled}
      />
      <ErrorLabel {...props} />
    </>
  );
};

export default Checkbox;
