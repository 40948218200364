import { MessageBarType } from '@fluentui/react';
import { Formik, useFormikContext } from 'formik';
import React from 'react';

import { AtonDto, AtonDtoStatusEnum } from 'api-client';

import { Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import Dialog from 'common/controls/surfaces/Dialog';
import Form from 'common/form/Form';
import usePage from 'common/layout/PageHook';

import { useAtonApi } from '../AtonApiHook';
import { atonCancelValidationSchema } from '../create/validationSchema';

interface AtonCancelConfirmPopupProps {
  operation?: string;
  onCancel: () => void;
  atonId: number;
  aton?: AtonDto;
  onUpdateAton: (aton: AtonDto) => void;
}

const AtonCancelConfirmPopup = (props: AtonCancelConfirmPopupProps) => {
  const title = 'You are about to cancel this AtoN record. To continue enter a reason and press OK.';
  const reasonLabel = 'Cancel AtoN reason';
  const { cancelAton } = useAtonApi();
  const { setPageInstruction } = usePage();
  const formikContext = useFormikContext<any>();

  return (
    <Dialog title={title} isOpen={true}>
      <Formik
        initialValues={
          {
            reason: undefined,
          } as any
        }
        onSubmit={async (values) => {
          cancelAton(props.atonId!, {
            ...props.aton!,
            reason: values.reason,
          }).then((response) => {
            props.onCancel();
            props.onUpdateAton(response);
            if (response.status === AtonDtoStatusEnum.Cancelled) {
              setPageInstruction(
                `AtoN record  ${props.atonId} has been cancelled. A notification has been sent to ${formikContext.values.email}`,
                MessageBarType.success,
              );
            }
          });
        }}
        validationSchema={atonCancelValidationSchema}
      >
        {({ values }) => (
          <Form
            id={'cancel-aton-form'}
            hideBack
            hideFormButtonsTop
            showFormButtonsBottom
            disableErrorMessage
            inModal
            submitButtonText={'OK'}
            additionalButtons={() => [
              {
                id: 'cancel-aton',
                text: 'Cancel',
                onClick: props.onCancel,
              },
            ]}
            mode="CREATE"
          >
            <Grid.Row>
              <Grid.Col>
                <TextField required name="reason" label={reasonLabel} multiline maxLength={400} showMaxHint />
              </Grid.Col>
            </Grid.Row>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AtonCancelConfirmPopup;
