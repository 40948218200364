import { DefaultButton, IDropdownOption, Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useEffect } from 'react';

import {
  FrequencyDtoHourCodeEnum,
  FrequencyDtoPowerTypeEnum,
  LicenceClassificationType,
  LicenceDtoLicenceTypeCodeEnum,
  SpectrumDto,
} from 'api-client';

import { Grid } from 'ui-library';

import Dropdown from '../../../../common/controls/inputs/Dropdown';
import { NumberField } from '../../../../common/controls/inputs/NumberField';
import ReadOnlyField from '../../../../common/controls/inputs/ReadOnlyField';
import { TextField } from '../../../../common/controls/inputs/TextField';
import Card from '../../../../common/controls/surfaces/Card';
import { useForm } from '../../../../common/form/FormHook';
import { formatTime } from '../../../../common/utils/dateUtils';
import Emissions from '../../../reference_data/spectrum_record/Emissions';
import { referenceFrequencyOptions } from './ReferenceFrequenciesSection';

const powerOptions = Object.values(FrequencyDtoPowerTypeEnum).map((key) => ({ key, text: key }));
const hourOptions = [{ key: '', text: '' }, ...Object.values(FrequencyDtoHourCodeEnum).map((key) => ({ key, text: key }))];

const ReferenceFrequencyCard = ({
  name,
  spectrum,
  isFreqReadOnly,
  onRemove,
  isSatellite,
  licenceClassification,
  licenceTypeCode,
}: {
  name: string;
  spectrum: SpectrumDto;
  isFreqReadOnly: boolean;
  onRemove?: () => void;
  isSatellite?: boolean;
  licenceClassification?: LicenceClassificationType;
  licenceTypeCode?: LicenceDtoLicenceTypeCodeEnum;
}) => {
  const editMode = useForm().mode === 'EDIT';
  const { values, setFieldValue } = useFormikContext<{ spectrums: SpectrumDto[] }>();
  const { hourCode, referenceFrequency, referenceFrequencyType } = get(values, name);
  const lt30Mhz = isSatellite || spectrum.upperBound < 30 || (spectrum.lowerBound < 30 && (!referenceFrequency || referenceFrequency < 30));
  const byUnselectedOption = (option: IDropdownOption) =>
    referenceFrequencyType === option.key || !spectrum.frequencies?.find((frequency) => frequency.referenceFrequencyType === option.key);
  const availableReferenceFrequencyOptions = referenceFrequencyOptions.filter(byUnselectedOption);

  useEffect(() => {
    if (hourCode || !lt30Mhz) {
      setFieldValue(`${name}.isoStartTime`, null);
      setFieldValue(`${name}.isoStopTime`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourCode, lt30Mhz]);

  return (
    <Card darker>
      <Grid.Row>
        {isFreqReadOnly ? (
          <>
            <Grid.Col lg={2} sm={12}>
              <ReadOnlyField label="Ref. Freq. (MHz)" name={`${name}.referenceFrequency`} />
            </Grid.Col>
            <Grid.Col lg={3} sm={12}>
              <ReadOnlyField label="Frequency type" name={`${name}.referenceFrequencyType`} />
            </Grid.Col>
            {(licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL || licenceClassification !== LicenceClassificationType.C) && (
              <>
                <Grid.Col lg={2} sm={12}>
                  <NumberField
                    label="Power dBW (eirp)"
                    name={`${name}.power`}
                    required
                    maxLength={5}
                    decimalDigit={1}
                    readOnlyRenderFormat={(p) => p?.toFixed(1)}
                  />
                </Grid.Col>
                <Grid.Col lg={3} sm={12}>
                  <ReadOnlyField label="Power type" name={`${name}.powerType`} />
                </Grid.Col>
                <Grid.Col lg={2} sm={12}>
                  <ReadOnlyField label="Tolerance" name={`${name}.tolerance`} />
                </Grid.Col>
              </>
            )}
          </>
        ) : (
          <>
            <Grid.Col lg={2} sm={12}>
              <NumberField
                label="Ref. Freq. (MHz)"
                name={`${name}.referenceFrequency`}
                required
                maxLength={13}
                decimalDigit={6}
                readOnlyRenderFormat={(f) => f?.toFixed(6)}
              />
            </Grid.Col>
            <Grid.Col lg={3} sm={12}>
              <Dropdown label="Frequency type" name={`${name}.referenceFrequencyType`} required options={availableReferenceFrequencyOptions} />
            </Grid.Col>
            {(licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL || licenceClassification !== LicenceClassificationType.C) && (
              <>
                <Grid.Col lg={2} sm={12}>
                  <NumberField
                    label="Power dBW (eirp)"
                    name={`${name}.power`}
                    required
                    maxLength={5}
                    decimalDigit={1}
                    readOnlyRenderFormat={(p) => p?.toFixed(1)}
                  />
                </Grid.Col>
                <Grid.Col lg={3} sm={12}>
                  <Dropdown label="Power type" name={`${name}.powerType`} options={powerOptions} required />
                </Grid.Col>
                <Grid.Col lg={2} sm={12}>
                  <TextField label="Tolerance" name={`${name}.tolerance`} maxLength={10} readOnlyEmptyFormat=" " />
                </Grid.Col>
              </>
            )}
          </>
        )}
      </Grid.Row>
      <Grid.Row>
        {(licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL || licenceClassification !== LicenceClassificationType.C) && (
          <Grid.Col lg={5} sm={12}>
            <Emissions name={`${name}.frequencyEmissions`} requiredEmissions={true} />
          </Grid.Col>
        )}
        {lt30Mhz && (
          <>
            <Grid.Col lg={2} sm={6}>
              <TextField
                label="Start time"
                name={`${name}.isoStartTime`}
                placeholder={hourCode ? undefined : 'HH:MM:SS'}
                disabled={!!hourCode}
                formatText={formatTime}
              />
            </Grid.Col>
            <Grid.Col lg={2} sm={6}>
              <TextField
                label="Stop time"
                name={`${name}.isoStopTime`}
                placeholder={hourCode ? undefined : 'HH:MM:SS'}
                disabled={!!hourCode}
                formatText={formatTime}
              />
            </Grid.Col>
            <Grid.Col sm={0} md={0} lg={1} />
            <Grid.Col lg={2} sm={12}>
              <Dropdown label="Hours of operation" name={`${name}.hourCode`} options={hourOptions} />
            </Grid.Col>
          </>
        )}
        {!lt30Mhz && editMode && onRemove && !isFreqReadOnly && (
          <Grid.Col lg={7} sm={12}>
            <Label>&nbsp;</Label>
            <DefaultButton data-automation-id={`${name}-remove-button`} text="Remove" onClick={onRemove} style={{ float: 'right' }} />
          </Grid.Col>
        )}
      </Grid.Row>
      {lt30Mhz && editMode && onRemove && !isFreqReadOnly && (
        <Grid.Row>
          <Grid.Col lg={12} sm={12}>
            <DefaultButton data-automation-id={`${name}-remove-button`} text="Remove" onClick={onRemove} style={{ float: 'right', marginTop: 5 }} />
          </Grid.Col>
        </Grid.Row>
      )}
    </Card>
  );
};

export default ReferenceFrequencyCard;
