import {
  ApplicationFeeSearchCriteriaFeeStatusEnum,
  AuditFurtherActionEnum,
  AuditMethodEnum,
  AuditRecommendationEnum,
  CaseFinalResultEnum,
  CaseStatusEnum,
  ComplianceCheckResultEnum,
  DelegationDtoStatusEnum,
  DelegationDtoTypeEnum,
  FileNoteDtoTypeEnum,
  RenewalFeeSearchCriteriaFeeStatusEnum,
} from 'api-client';

export const yesNo = [
  { key: 'true', text: 'Yes' },
  { key: 'false', text: 'No' },
];

export const delegationType = [
  {
    key: DelegationDtoTypeEnum.ARE,
    text: 'Radio Engineer',
    hint: 'The person will be able to apply and craft radio and spectrum licences, confirm, and pay on your behalf if required.',
  },
  {
    key: DelegationDtoTypeEnum.ARC,
    text: 'Radio Certifier',
    hint: 'The person will be able to apply and craft radio licences, confirm, and pay on your behalf if required.',
  },
  {
    key: DelegationDtoTypeEnum.ARX,
    text: 'Radio Examiner',
    hint: 'The person will be able to apply and create certificates and callsigns, confirm, and pay on your behalf if required.',
  },
  { key: DelegationDtoTypeEnum.AGENT, text: 'Agent', hint: 'The person will be able to manage your client record and licences on your behalf.' },
];

export const delegationStatus = [
  { key: DelegationDtoStatusEnum.ACT, text: 'Active' },
  { key: DelegationDtoStatusEnum.CNL, text: 'Cancelled' },
  { key: DelegationDtoStatusEnum.EXP, text: 'Expired' },
  { key: DelegationDtoStatusEnum.RVK, text: 'Revoked' },
  { key: DelegationDtoStatusEnum.RJT, text: 'Rejected' },
  { key: DelegationDtoStatusEnum.PND, text: 'Pending' },
];

export const delegationTimeType = [
  { key: 'INDF', text: 'Indefinite' },
  { key: 'FIXED', text: 'Set an end date' },
];

export const statusUser = [
  { key: 'ACTIVE', text: 'Active' },
  { key: 'LOCKED', text: 'Locked' },
];

export const statusDelegationsSorting = [
  { key: 'PND', text: 'Pending', order: 1 },
  { key: 'ACTIVE', text: 'Active', order: 2 },
  { key: 'ACT', text: 'Active', order: 2 },
  { key: 'RVK', text: 'Revoked', order: 3 },
  { key: 'CNL', text: 'Cancelled', order: 4 },
  { key: 'RJT', text: 'Rejected', order: 5 },
  { key: 'EXP', text: 'Expired', order: 6 },
];

export const REFERENCE_DATA_ROLES_LOGIN = [
  { key: 'ROLE_REGISTRAR', text: 'Registrar' },
  { key: 'ROLE_RADIO_INVESTIGATOR', text: 'Radio investigator' },
  { key: 'ROLE_SENIOR_RADIO_INVESTIGATOR', text: 'Senior radio investigator' },
  { key: 'ROLE_INVESTIGATIONS_MANAGER', text: 'Investigations manager' },
  { key: 'ROLE_GENERAL_USER', text: 'General user' },
  { key: 'ROLE_SPECIAL_USER', text: 'Special user' },
  { key: 'ROLE_MSG_SERVICE_CENTRE', text: 'MSG Service Centre' },
  { key: 'ROLE_SYSTEM_ADMINISTRATOR', text: 'System administrator' },
  { key: 'ROLE_LICENSING_OFFICER', text: 'Licensing officer' },
  { key: 'ROLE_RSM_RADIO_ENGINEER', text: 'RSM radio engineer' },
  { key: 'ROLE_LICENSING_MANAGER', text: 'Licensing manager' },
  { key: 'ROLE_APPROVED_RADIO_ENGINEER', text: 'ARE' },
  { key: 'ROLE_APPROVED_RADIO_CERTIFIER', text: 'ARC' },
  { key: 'ROLE_APPROVED_RADIO_EXAMINER', text: 'ARX' },
  { key: 'ROLE_LICENSING_AGENCY', text: 'Licensing agency' },
  { key: 'ROLE_CLIENT_DA', text: 'Agent' },
  { key: 'ROLE_CLIENT_EA', text: 'Client' },
  { key: 'ROLE_RIGHT_HOLDER', text: 'Rightholder' },
  { key: 'ROLE_PRIVATE_BAND_MANAGER', text: 'Private band manager' },
  { key: 'ROLE_CROWN_BAND_MANAGER', text: 'Crown band manager' },
  { key: 'ROLE_MORTGAGOR', text: 'Mortgagor' },
  { key: 'ROLE_MORTGAGEE', text: 'Mortgagee' },
  { key: 'ROLE_CAVEATOR', text: 'Caveator' },
];

export const REFERENCE_DATA_ROLES = [
  { key: 'ROLE_REGISTRAR', text: 'Registrar' },
  { key: 'ROLE_RADIO_INVESTIGATOR', text: 'Radio investigator' },
  { key: 'ROLE_SENIOR_RADIO_INVESTIGATOR', text: 'Senior radio investigator' },
  { key: 'ROLE_INVESTIGATIONS_MANAGER', text: 'Investigations manager' },
  { key: 'ROLE_GENERAL_USER', text: 'General user' },
  { key: 'ROLE_SPECIAL_USER', text: 'Special user' },
  { key: 'ROLE_MSG_SERVICE_CENTRE', text: 'MSG Service Centre' },
  { key: 'ROLE_SYSTEM_ADMINISTRATOR', text: 'System administrator' },
  { key: 'ROLE_LICENSING_OFFICER', text: 'Licensing officer' },
  { key: 'ROLE_RSM_RADIO_ENGINEER', text: 'RSM radio engineer' },
  { key: 'ROLE_LICENSING_MANAGER', text: 'Licensing manager' },
  { key: 'ROLE_APPROVED_RADIO_ENGINEER', text: 'Approved radio engineer' },
  { key: 'ROLE_APPROVED_RADIO_CERTIFIER', text: 'Approved radio certifier' },
  { key: 'ROLE_APPROVED_RADIO_EXAMINER', text: 'Approved radio examiner' },
  { key: 'ROLE_LICENSING_AGENCY', text: 'Licensing agency' },
  { key: 'ROLE_CLIENT', text: 'Client' },
  { key: 'ROLE_RIGHT_HOLDER', text: 'Rightholder' },
  { key: 'ROLE_PRIVATE_BAND_MANAGER', text: 'Private band manager' },
  { key: 'ROLE_CROWN_BAND_MANAGER', text: 'Crown band manager' },
  { key: 'ROLE_MORTGAGOR', text: 'Mortgagor' },
  { key: 'ROLE_MORTGAGEE', text: 'Mortgagee' },
  { key: 'ROLE_CAVEATOR', text: 'Caveator' },
];

export const REFERENCE_INTERNAL_DATA_ROLES = [
  //EA
  { key: 'ROLE_REGISTRAR', text: 'Registrar' },
  { key: 'ROLE_RADIO_INVESTIGATOR', text: 'Radio investigator' },
  { key: 'ROLE_SENIOR_RADIO_INVESTIGATOR', text: 'Senior radio investigator' },
  { key: 'ROLE_INVESTIGATIONS_MANAGER', text: 'Investigations manager' },
  { key: 'ROLE_GENERAL_USER', text: 'General user' },
  { key: 'ROLE_SPECIAL_USER', text: 'Special user' },
  { key: 'ROLE_MSG_SERVICE_CENTRE', text: 'MSG Service Centre' },
  { key: 'ROLE_SYSTEM_ADMINISTRATOR', text: 'System administrator' },
  { key: 'ROLE_LICENSING_OFFICER', text: 'Licensing officer' },
  { key: 'ROLE_RSM_RADIO_ENGINEER', text: 'RSM radio engineer' },
  { key: 'ROLE_LICENSING_MANAGER', text: 'Licensing manager' },
  { key: 'ROLE_APPROVED_RADIO_ENGINEER', text: 'Approved radio engineer' },
  { key: 'ROLE_APPROVED_RADIO_CERTIFIER', text: 'Approved radio certifier' },
  { key: 'ROLE_APPROVED_RADIO_EXAMINER', text: 'Approved radio examiner' },
  { key: 'ROLE_CLIENT', text: 'Client' },
  { key: 'ROLE_LICENSING_AGENCY', text: 'Licensing agency ' },
  { key: 'ROLE_MORTGAGOR', text: 'Mortgagor' },
  { key: 'ROLE_MORTGAGEE', text: 'Mortgagee' },
  { key: 'ROLE_CAVEATOR', text: 'Caveator' },
];

export const REFERENCE_CLIENT_DATA_ROLES = [
  { key: 'ROLE_CLIENT', text: 'Client' },
  { key: 'ROLE_RIGHT_HOLDER', text: 'Rightholder' },
  { key: 'ROLE_PRIVATE_BAND_MANAGER', text: 'Private band manager' },
  { key: 'ROLE_CROWN_BAND_MANAGER', text: 'Crown band manager' },
  { key: 'ROLE_MORTGAGOR', text: 'Mortgagor' },
  { key: 'ROLE_MORTGAGEE', text: 'Mortgagee' },
  { key: 'ROLE_CAVEATOR', text: 'Caveator' },
];

export const REFERENCE_DELEGATE_ROLES = [
  { key: 'ROLE_APPROVED_RADIO_ENGINEER', text: 'Approved radio engineer', acronym: 'ARE' },
  { key: 'ROLE_APPROVED_RADIO_CERTIFIER', text: 'Approved radio certifier', acronym: 'ARC' },
  { key: 'ROLE_APPROVED_RADIO_EXAMINER', text: 'Approved radio examiner', acronym: 'ARX' },
  { key: 'ROLE_CLIENT', text: 'Client', acronym: 'AGENT' },
];
export const APPLICATION_STATUSES = new Map<string, string>([
  ['INCOMPLETE', 'An application has been created but is not yet ready for submital'],
  ['AWAITING LA', 'Awaiting Licensing Agency Recommendation'],
  ['NO LA DECISION', 'No Licensing Agency Decision'],
  ['AWAITING LMGR', 'Application rejected by LA and awaiting recommendation from the RSM Licensing Manager'],
  ['NO LM DECISION', 'No Licensing Manager Decision'],
  ['AWAITING ENG', 'Awaiting Engineering Certification'],
  ['REJECTED', 'Rejected for licence creation'],
  ['CERT EXPIRED', 'Expired application'],
  ['DELETED', 'Deleted'],
  ['PLANNED', 'Planned'],
  ['AWAITING SECOND ENG', 'Awaiting Engineering Certification'],
  ['COMPLETED', 'Completed'],
  ['AWAITING REGISTRAR', 'Awaiting Registrar'],
]);

export const CERTIFICATE_TYPE = [
  { text: 'General Amateur Operator’s Certificate (GAOC)', key: 'GAOC' },
  { text: 'Approved Overseas Amateur Radio Certificate', key: 'AOARC' },
  { text: 'Maritime General Operator’s Certificate (MGOC)', key: 'MGOC' },
  { text: 'Maritime General Radiotelephone Operator’s Certificate (MGROC)', key: 'MGROC' },
  { text: 'Maritime Long-range Operator’s Certificate (MLROC)', key: 'MLROC' },
  { text: 'Maritime Restricted Radiotelephone Operator’s Certificate (MRROC)', key: 'MRROC' },
  { text: 'Maritime Short-range Operator’s Certificate (MSROC)', key: 'MSROC' },
];

export const CERTIFICATE_TYPE_WITHOUT_SHORT = [
  { text: 'General Amateur Operator’s Certificate', key: 'GAOC' },
  { text: 'Approved Overseas Amateur Radio Certificate', key: 'AOARC' },
  { text: 'Maritime General Operator’s Certificate', key: 'MGOC' },
  { text: 'Maritime General Radiotelephone Operator’s Certificate', key: 'MGROC' },
  { text: 'Maritime Long-range Operator’s Certificate', key: 'MLROC' },
  { text: 'Maritime Restricted Radiotelephone Operator’s Certificate', key: 'MRROC' },
  { text: 'Maritime Short-range Operator’s Certificate', key: 'MSROC' },
];

export const GROUNDS_FOR_GRANTING = [
  { key: 'passExamination', text: 'Pass Examination' },
  { key: 'historicQualification', text: 'Historic Qualification' },
  { key: 'other', text: 'Other' },
];

export const RELEASE_CALLSIGN_REASON = [
  { key: 'ClientDeceased', text: 'Client deceased' },
  { key: 'Other', text: 'Other' },
];

export const CALLSIGN_TYPE = [
  { key: 'AMATEUR_CLUB_CALLSIGN', text: 'Amateur club callsign' },
  { key: 'SHIP_CALLSIGN', text: 'Ship callsign' },
];

export const SHIP_CALLSIGN_TYPE = [
  { key: 'SHIP_MF/HF', text: 'MF/HF (SSB) only' },
  { key: 'SHIP_MF/HF&VHF', text: 'MF/HF & VHF' },
  {
    key: 'SHIP_VHF',
    text: 'VHF only',
    disabled: true,
    hint: 'Only the approved radio examiners from Coastguard Boating Education Service can allocate VHF only ship callsign.',
  },
  {
    key: 'SHIP_HAND_VHF',
    text: 'Handheld VHF only',
    disabled: true,
    hint: 'Only the approved radio examiners from Coastguard Boating Education Service can allocate Handheld VHF only ship callsign',
  },
];

export const CERTIFICATE_STATUS = [
  { key: 'Draft', text: 'Draft' },
  { key: 'Incomplete', text: 'Incomplete' },
  { key: 'Current', text: 'Current' },
  { key: 'Suspended', text: 'Suspended' },
  { key: 'Revoked', text: 'Revoked' },
];

export const PRINT_CERTIFICATE = [
  { key: 'Display', text: 'Display extract within browser window' },
  { key: 'Send', text: 'Send PDF to this email address' },
];

export const CALLSIGN_SEARCH_TYPE = [
  { key: 'CALLSIGN', text: 'Callsign' },
  { key: 'VESSEL', text: 'Vessel name' },
  { key: 'MMSI', text: 'MMSI' },
  { key: 'SCN', text: 'SCN' },
];

export const CALLSIGN_STATUS = [
  { key: 'ALLOCATED', text: 'Allocated' },
  { key: 'RESERVED', text: 'Reserved' },
];

export const CALLSIGN_COMMENCEMENT_DATE_TYPE = [
  { key: 'PAST', text: 'Past and current' },
  { key: 'FUTURE', text: 'Future' },
];

export const CALLSIGN_EXPIRY_DATE_TYPE = [
  { key: 'CURRENT', text: 'Current and future' },
  { key: 'PAST', text: 'Past' },
];

export const ASSOCIATED_RECORD_TYPE = [
  { key: 'AMATEUR_CLUB', text: 'Amateur club callsign' },
  { key: 'SHIP_CALLSIGN', text: 'Ship callsign' },
  { key: 'CERTIFICATE', text: 'Certificate' },
  { key: 'LICENCE', text: 'Licence' },
];

export const ASSOCIATED_RECORD_STATUS = [
  { key: 'CURRENT', text: 'Current' },
  { key: 'NON_CURRENT', text: 'Non-current' },
];

export const COMMON_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.NOTE, text: 'Note' },
  { key: FileNoteDtoTypeEnum.OFFICIAL_INFORMATION_ACT, text: 'Official Information Act' },
  { key: FileNoteDtoTypeEnum.OMBUDSMAN_ENQUIRY, text: 'Ombudsman Enquiry' },
  { key: FileNoteDtoTypeEnum.MINISTERIAL, text: 'Ministerial' },
];

export const CLIENT_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.NOTE, text: 'Note' },
  { key: FileNoteDtoTypeEnum.OFFICIAL_INFORMATION_ACT, text: 'Official Information Act' },
  { key: FileNoteDtoTypeEnum.OMBUDSMAN_ENQUIRY, text: 'Ombudsman Enquiry' },
  { key: FileNoteDtoTypeEnum.MINISTERIAL, text: 'Ministerial' },
  { key: FileNoteDtoTypeEnum.CONFIDENTIAL_NOTE, text: 'Confidential Note' },
  { key: FileNoteDtoTypeEnum.GENERAL_NOTE, text: 'General Note' },
  { key: FileNoteDtoTypeEnum.PRIVACY_ACT, text: 'Privacy Act' },
];

export const SHIP_CALLSIGN_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.NOTE, text: 'Note' },
  { key: FileNoteDtoTypeEnum.OFFICIAL_INFORMATION_ACT, text: 'Official Information Act' },
  { key: FileNoteDtoTypeEnum.OMBUDSMAN_ENQUIRY, text: 'Ombudsman Enquiry' },
  { key: FileNoteDtoTypeEnum.MINISTERIAL, text: 'Ministerial' },
  { key: FileNoteDtoTypeEnum.MARITIME_SHIP_SAFETY_FORM, text: 'Maritime Ship Safety form' },
];

export const USER_ACCOUNT_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.NOTE, text: 'Note' },
];

export const CASE_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.ACKNOWLEDGEMENT_REQUEST_REVIEW, text: 'Acknowledgement of Request for Review' },
  { key: FileNoteDtoTypeEnum.INFRINGEMENT_NOTICE, text: 'Infringement Notices', onlyMigrated: true },
  { key: FileNoteDtoTypeEnum.REQUEST_TO_RAISE_INVOICE, text: 'Request to raise an invoice', onlyMigrated: true },
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.CONFIDENTIAL_NOTE, text: 'Confidential Note', onlyMigrated: true },
  { key: FileNoteDtoTypeEnum.AUDIT_REPORT, text: 'Audit Report' },
  { key: FileNoteDtoTypeEnum.AUDIT_REQUEST_LETTER, text: 'Audit Request Letter' },
  { key: FileNoteDtoTypeEnum.CLIENT_EDUCATION, text: 'Client Education' },
  { key: FileNoteDtoTypeEnum.FIRST_RESPONSE_TO_CLIENT, text: 'First response to client' },
  { key: FileNoteDtoTypeEnum.GENERAL_NOTE, text: 'General Notes' },
  { key: FileNoteDtoTypeEnum.DOCUMENTATION, text: 'Documentation' },
  { key: FileNoteDtoTypeEnum.INFRINGEMENT_BRIEF, text: 'Infringement Brief' },
  { key: FileNoteDtoTypeEnum.INTERFERENCE_COMPLAINT_LODGED, text: 'Interference complaint lodged' },
  { key: FileNoteDtoTypeEnum.INFRINGEMENT_NOTICE_COVERING_LETTER, text: 'Infringement Notice Covering Letter' },
  { key: FileNoteDtoTypeEnum.INFRINGEMENT_REFERRAL_TO_COURT, text: 'Infringement Referral to Court' },
  { key: FileNoteDtoTypeEnum.INFRINGEMENT_REVIEW_NOTIFICATION_LETTER, text: 'Infringement Review Notification Letter' },
  { key: FileNoteDtoTypeEnum.INFRINGEMENT_REMINDER_LETTER_SP10, text: 'Infringement reminder Letter SP10' },
  { key: FileNoteDtoTypeEnum.INTERFERENCE_INVESTIGATION, text: 'Interference Investigation' },
  { key: FileNoteDtoTypeEnum.INTERIM_AUDIT_ACKNOWLEDGEMENT, text: 'Interim Audit acknowledgement' },
  { key: FileNoteDtoTypeEnum.MEASUREMENT, text: 'Measurement' },
  { key: FileNoteDtoTypeEnum.REQUEST_COURT_HEARING, text: 'Request for a Court Hearing' },
  { key: FileNoteDtoTypeEnum.RESULT_SENT, text: 'Result ‘Thank you’ sent' },
  { key: FileNoteDtoTypeEnum.REVIEW_REQUEST, text: 'Review Request' },
  { key: FileNoteDtoTypeEnum.SATISFACTORY_AUDIT_LETTER, text: 'Satisfactory Audit ‘Thank you’ Letter' },
  { key: FileNoteDtoTypeEnum.WARNING_NOTICE, text: 'Warning Notices' },
];

export const LICENCE_APPLICATION_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.NOTE, text: 'Note' },
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.EXTERNAL_ENGINEERING_CORRECTIVE_ACTION_REPORT, text: 'External engineering corrective action report' },
  { key: FileNoteDtoTypeEnum.LICENCE_CHECK_SHEET, text: 'Licence check sheet' },
  { key: FileNoteDtoTypeEnum.LICENCE_REVOKED_FOR_NON_PAYMENT, text: 'Licence revoked for non-payment' },
  { key: FileNoteDtoTypeEnum.MINISTERIAL, text: 'Ministerial' },
  { key: FileNoteDtoTypeEnum.OFFICIAL_INFORMATION_ACT, text: 'Official Information Act' },
  { key: FileNoteDtoTypeEnum.OMBUDSMAN_ENQUIRY, text: 'Ombudsman Enquiry' },
  { key: FileNoteDtoTypeEnum.REVOKED_LICENCE_ADVICE_LETTER, text: 'Revoked Licence Advice Letter' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_ENGINEERING_REPORT, text: 'Radio Licence Engineering Report' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_FREQUENCY_CO_ORDINATION, text: 'Radio Licence Frequency Co-ordination' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_MEASUREMENT_REPORT, text: 'Radio Licence Measurement Report' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_NOTIFICATION_LETTER, text: 'Radio Licence Notification Letter' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_CONTRACT, text: 'Spectrum Licence Contract' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_ENGINEERING_REPORT, text: 'Spectrum Licence Engineering Report' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_MEASUREMENT_REPORT, text: 'Spectrum Licence Measurement Report' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_NOTIFICATION_LETTER, text: 'Spectrum Licence Notification Letter' },
];

export const LICENCE_FILE_NOTE_TYPE = [
  { key: FileNoteDtoTypeEnum.NOTE, text: 'Note' },
  { key: FileNoteDtoTypeEnum.CORRESPONDENCE, text: 'Correspondence' },
  { key: FileNoteDtoTypeEnum.EXTERNAL_ENGINEERING_CORRECTIVE_ACTION_REPORT, text: 'External engineering corrective action report' },
  { key: FileNoteDtoTypeEnum.LICENCE_CHECK_SHEET, text: 'Licence check sheet' },
  { key: FileNoteDtoTypeEnum.LICENCE_REVOKED_FOR_NON_PAYMENT, text: 'Licence revoked for non-payment' },
  { key: FileNoteDtoTypeEnum.MINISTERIAL, text: 'Ministerial' },
  { key: FileNoteDtoTypeEnum.OFFICIAL_INFORMATION_ACT, text: 'Official Information Act' },
  { key: FileNoteDtoTypeEnum.OMBUDSMAN_ENQUIRY, text: 'Ombudsman Enquiry' },
  { key: FileNoteDtoTypeEnum.REVOKED_LICENCE_ADVICE_LETTER, text: 'Revoked Licence Advice Letter' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_ENGINEERING_REPORT, text: 'Radio Licence Engineering Report' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_FREQUENCY_CO_ORDINATION, text: 'Radio Licence Frequency Co-ordination' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_MEASUREMENT_REPORT, text: 'Radio Licence Measurement Report' },
  { key: FileNoteDtoTypeEnum.RADIO_LICENCE_NOTIFICATION_LETTER, text: 'Radio Licence Notification Letter' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_CONTRACT, text: 'Spectrum Licence Contract' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_ENGINEERING_REPORT, text: 'Spectrum Licence Engineering Report' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_MEASUREMENT_REPORT, text: 'Spectrum Licence Measurement Report' },
  { key: FileNoteDtoTypeEnum.SPECTRUM_LICENCE_NOTIFICATION_LETTER, text: 'Spectrum Licence Notification Letter' },
];

export const RENEWAL_FEE_STATUS = [
  { key: RenewalFeeSearchCriteriaFeeStatusEnum.DUE, text: 'Due' },
  { key: RenewalFeeSearchCriteriaFeeStatusEnum.INVOICED, text: 'Invoiced' },
  { key: RenewalFeeSearchCriteriaFeeStatusEnum.PAID, text: 'Paid' },
  { key: RenewalFeeSearchCriteriaFeeStatusEnum.BEING_MODIFIED, text: 'Being modified' },
];

export const APPLICATION_FEE_STATUS = [
  { key: ApplicationFeeSearchCriteriaFeeStatusEnum.DUE, text: 'Due' },
  { key: ApplicationFeeSearchCriteriaFeeStatusEnum.INVOICED, text: 'Invoiced' },
];

export const CBES_CLIENT = 522510;

export const CASE_STATUS = [
  { key: CaseStatusEnum.AUDIT_CPL, text: 'Audit complete' },
  { key: CaseStatusEnum.APPEAL, text: 'Client appealed' },
  { key: CaseStatusEnum.CLSD, text: 'Closed' },
  { key: CaseStatusEnum.CPL_CHECK, text: 'Requires compliance check' },
  { key: CaseStatusEnum.EMAIL, text: 'Email client' },
  { key: CaseStatusEnum.INFRINGE, text: 'Infringement' },
  { key: CaseStatusEnum.IPRG, text: 'In progress' },
  { key: CaseStatusEnum.PEND, text: 'Pending' },
  { key: CaseStatusEnum.PROSECUTE, text: 'Prosecute' },
  { key: CaseStatusEnum.RECOMMD, text: 'Recommendation' },
];

export const CASE_RESULT = [
  {
    key: CaseFinalResultEnum.DISCARD,
    text: 'Discard',
  },
  {
    key: CaseFinalResultEnum.OTHER,
    text: 'Other',
  },
  {
    key: CaseFinalResultEnum.SATISFACTORY,
    text: 'Satisfactory',
  },
  {
    key: CaseFinalResultEnum.UNSATISFACTORY,
    text: 'Unsatisfactory',
  },
  {
    key: CaseFinalResultEnum.RESOLVED_NO_FAULT_FOUND,
    text: 'Resolved - no fault found',
  },
  {
    key: CaseFinalResultEnum.RESOLVED_FOUND_NO_FURTHER_ACTION,
    text: 'Resolved - found no futher action',
  },
  {
    key: CaseFinalResultEnum.RESOLVED_DUE_TO_PRODUCT,
    text: 'Resolved - due to product (requires product audit)',
  },
  {
    key: CaseFinalResultEnum.RESOLVED_TRANSMISSION,
    text: 'Resolved - transmission (requires licence audit)',
  },
  {
    key: CaseFinalResultEnum.COMPLIANT,
    text: 'Compliant',
  },
  {
    key: CaseFinalResultEnum.NON_COMPLIANT,
    text: 'Non compliant',
  },
];

export enum ComplaintResultsEnum {
  RESOLVED_NO_FAULT_FOUND = 'Resolved - no fault found',
  RESOLVED_FOUND_NO_FURTHER_ACTION = 'Resolved - found no futher action',
  RESOLVED_DUE_TO_PRODUCT = 'Resolved - due to product (requires product audit)',
  RESOLVED_TRANSMISSION = 'Resolved - transmission (requires licence audit)',
  DISCARD = 'Discard',
}

export const COMPLAINT_RESULT_OPTIONS = Object.keys(ComplaintResultsEnum).map((key) => ({
  key,
  text: ComplaintResultsEnum[key as keyof typeof ComplaintResultsEnum],
}));

export const AUDIT_FURTHER_ACTION_OPTIONS = [
  { key: AuditFurtherActionEnum.SATISFACTORY, text: 'Satisfactory' },
  { key: AuditFurtherActionEnum.MINOR_INFRINGEMENT_WARNING, text: 'Minor infringement - warning' },
  { key: AuditFurtherActionEnum.MINOR_INFRINGEMENT_EDUCATION, text: 'Minor infringement - education' },
  { key: AuditFurtherActionEnum.INFRINGEMENT_NOTICE, text: 'Infringement notice' },
  { key: AuditFurtherActionEnum.PROSECUTE, text: 'Prosecute' },
  { key: AuditFurtherActionEnum.DISCARD, text: 'Discard' },
  { key: AuditFurtherActionEnum.RI_REVISION, text: 'RI revision' },
];

export const AUDIT_METHOD_OPTIONS = [
  { key: AuditMethodEnum.OVER_THE_AIR, text: 'Over the air' },
  { key: AuditMethodEnum.DIRECT_COUPLED, text: 'Direct-coupled' },
];

export const AUDIT_RECOMMENDATION_OPTIONS = [
  { key: AuditRecommendationEnum.EDUCATION, text: 'Education' },
  { key: AuditRecommendationEnum.MINOR_INFRINGEMENT, text: 'Minor infringement' },
  { key: AuditRecommendationEnum.MAJOR_INFRINGEMENT, text: 'Major infringement' },
];

export const AUDIT_RESULT_OPTIONS = [
  { key: CaseFinalResultEnum.SATISFACTORY, text: 'Satisfactory' },
  { key: CaseFinalResultEnum.UNSATISFACTORY, text: 'Unsatisfactory' },
  { key: CaseFinalResultEnum.COMPLIANT, text: 'Compliant' },
  { key: CaseFinalResultEnum.NON_COMPLIANT, text: 'Non-Compliant' },
  { key: CaseFinalResultEnum.DISCARD, text: 'Discard' },
];

export const COMPLIANCE_CHECK_RESULT_OPTIONS = [
  { key: ComplianceCheckResultEnum.COMPLIANT, text: 'Compliant' },
  { key: ComplianceCheckResultEnum.NON_COMPLIANT, text: 'Non-Compliant' },
];
