import { useTheme as fUIUseTheme } from '@fluentui/react';
import { IPalette, PartialTheme } from '@fluentui/theme';
import { RRFSemanticColors } from './RRFSematicColors';

export interface RRFTheme extends PartialTheme {
  palette: Partial<IPalette>;
  semanticColors: Partial<RRFSemanticColors>;
}

export const useTheme = () => {
  const theme = fUIUseTheme();
  return theme as Required<RRFTheme>;
};
