import { Text, MessageBarType } from '@fluentui/react';
import { ENVIRONMENT } from 'env-config';
import { Form as FormikForm, Formik, FormikErrors, FormikHelpers, FormikValues, useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';
import * as Yup from 'yup';

import FormButtons, { BaseFormButtonsProps, FormButtonsProps } from 'ui-library/form/FormButtons';

import OverlaySpinner from 'common/controls/progress/OverlaySpinner';
import ConfirmDialog from 'common/controls/surfaces/ConfirmDialog';
import { FormMode, FormContext } from 'common/form/Form';
import usePage from 'common/layout/PageHook';

import { AdditionalFormButton } from './FormButtons';

export interface FormProps<T> extends FormContentProps<T> {
  children: ReactNode;
  /**
   * Called when the form passed the form validation.
   */
  handleSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => any;
  initialValues?: Partial<{ [p in keyof T]: any }>;
  validationSchema?: ObjectSchema | FieldValidationType[];
  disabledSubmitWhenNoDirty?: boolean;
}

interface FormButtonsOptions<T> extends BaseFormButtonsProps<T> {
  submitButtonText?: string;
  additionalButtons?: AdditionalFormButton[];
}

interface FormContentProps<T> {
  children: ReactNode;
  id: string;
  isInDialog?: boolean;
  canEdit?: boolean;
  disableErrorMessage?: boolean;
  formButtonsOptions?: FormButtonsOptions<T>;
  mode?: FormMode;
  hideFormButtonsTop?: boolean;
  showFormButtonsBottom?: boolean;
  submittingSpinnerLabel?: string;
  validate?: (values: T) => void | object | Promise<FormikErrors<T>>;
  validateOnBlur?: boolean;
  initialErrors?: FormikErrors<T>;
}

export interface FieldValidationType {
  name: string;
  notRequired?: boolean;
  schema?: any;
  validationType?: 'string' | 'number' | 'custom' | 'boolean';
}

const Form = <T extends FormikValues>({ initialValues = {}, validationSchema, ...props }: FormProps<T>) => {
  let _validationSchema;
  let _initialValues;

  if (Array.isArray(validationSchema)) {
    _validationSchema = createValidationSchema(validationSchema);
    _initialValues = validationSchema
      .filter(({ notRequired }) => !notRequired)
      .reduce(
        (output: any, { name }: FieldValidationType) => {
          if (output[name]) {
            return output;
          }

          return { ...output, [name]: undefined };
        },
        { ...initialValues },
      );
  } else {
    _validationSchema = validationSchema;
    _initialValues = initialValues;
  }

  return (
    <Formik<T>
      initialValues={_initialValues as T}
      validationSchema={_validationSchema}
      onSubmit={(values, formikHelpers) => props.handleSubmit && props.handleSubmit(values, formikHelpers)}
      enableReinitialize
      validate={props.validate}
      initialErrors={props.initialErrors}
      validateOnBlur={props.validateOnBlur}
    >
      {() => <FormContent<T> {...props} />}
    </Formik>
  );
};

const createValidationSchema = (rules: FieldValidationType[]) => {
  return Yup.object().shape(
    rules.reduce((output: any, { name: fieldName, notRequired = false, schema, validationType = 'string' }: FieldValidationType) => {
      let validationSchema;

      switch (validationType) {
        case 'custom':
          if (!schema) {
            throw new Error(`Missing schema property [form field=${fieldName}]`);
          }

          validationSchema = schema;
          break;
        case 'number':
          validationSchema = Yup.number();
          break;
        case 'boolean':
          validationSchema = Yup.boolean().nullable();
          break;
        default:
          validationSchema = Yup.string().nullable();
      }

      return {
        ...output,
        [fieldName]: notRequired ? validationSchema : validationSchema.required('Required'),
      };
    }, {}),
  );
};

const FormContent = <T extends FormikValues>(props: FormContentProps<T>) => {
  const computedMode = props.mode || 'VIEW';

  const formikContext = useFormikContext();
  const { setPageInstruction } = usePage();
  const [mode, setMode] = useState<FormMode>(computedMode);

  const { isSubmitting, isValid } = formikContext;

  useEffect(() => {
    setMode(computedMode);
  }, [props.mode]);

  useEffect(() => {
    if (!props.isInDialog) {
      if (!isValid && isSubmitting && !props.disableErrorMessage) {
        setPageInstruction('There are errors on the form.', MessageBarType.error);
      } else if (isValid && isSubmitting) {
        setPageInstruction(undefined);
      }
    }

    // eslint-disable-next-line
  }, [isValid, isSubmitting]);

  if (ENVIRONMENT !== 'PRD') {
    console.groupCollapsed(`Form info for form ${props.id}`);
    console.log(`Mode: ${mode}`);
    console.log(`Values`, formikContext.values);
    console.log(`%cErrors: \n${JSON.stringify(formikContext.errors, null, 2)}`, 'color:red;');
    console.log(`Touched fields: \n${JSON.stringify(formikContext.touched, null, 2)}`);
    console.groupEnd();
  }

  const formButtonsProps: FormButtonsProps<T> = {
    ...props.formButtonsOptions,
    canEdit: props.canEdit,
    id: props.id,
  };

  const spinnerLabel = props.submittingSpinnerLabel || (mode === 'SEARCH' ? 'Searching...' : 'Saving...');

  return (
    <FormContext.Provider
      value={{
        mode,
        setMode,
      }}
    >
      <FormikForm id={props.id} data-automation-id={props.id}>
        {formikContext.isSubmitting && <OverlaySpinner label={spinnerLabel} />}
        {!props.hideFormButtonsTop && <FormButtons<T> {...formButtonsProps} canEdit={props.canEdit} top />}
        {props.children}
        {props.showFormButtonsBottom && <FormButtons<T> {...formButtonsProps} />}
      </FormikForm>
      <ConfirmWarningDialog />
    </FormContext.Provider>
  );
};

const ConfirmWarningDialog = () => {
  const { showWarningModal, setShowWarningModal, modalContext, warnings } = usePage();

  return (
    <ConfirmDialog
      visible={showWarningModal}
      title="Do you want to continue?"
      notDangerous={true}
      yesText="OK"
      noText="Cancel"
      onCancel={() => {
        setShowWarningModal(false);
        if (modalContext?.onCancel) {
          modalContext?.onCancel();
        }
      }}
      onOk={() => {
        setShowWarningModal(false);
        if (modalContext?.onConfirm) {
          modalContext?.onConfirm();
        }
      }}
      body={
        <div>
          {warnings?.map((warning, idx) => (
            <Text>{warning}</Text>
          ))}
        </div>
      }
    />
  );
};

export default Form;
