import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from '../common/auth/ProtectedRoute';

const ApplyLicenceToSupplyPage = React.lazy(() => import('./apply/ApplyLicenceToSupplyPage'));
const MaintainLicenceToSupplyPage = React.lazy(() => import('./maintain/MaintainLicenceToSupplyPage'));

interface Props {}

export const useApplyLicenceToSupplyNavigation = () => {
  const history = useHistory();

  return {
    navigateToApplyLicenceToSupply: () => history.push('/ui/app/licence-to-supply/apply'),
  };
};

export const useMaintainLicenceToSupplyNavigation = () => {
  const history = useHistory();
  return {
    navigateToMaintainLicenceToSupply: (id: number) => history.push(`/ui/app/licence-to-supply/maintain/${id}`),
  };
};

const LicenceToSupplyRouter: React.FC<Props> = (props) => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/apply`} permission={['CREATE_LICENCE_TO_SUPPLY']} component={ApplyLicenceToSupplyPage} />
        <ProtectedRoute path={`${path}/maintain/:id`} permission={['VIEW_LICENCE_TO_SUPPLY']} component={MaintainLicenceToSupplyPage} />
      </Switch>
    </>
  );
};

export default LicenceToSupplyRouter;
