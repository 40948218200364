import { ITextFieldProps, ITextFieldStyles, Spinner, TextField as FluentUITextField } from '@fluentui/react';
import { FieldAttributes, FieldInputProps, FieldMetaProps, useField as useFormikField } from 'formik';
import * as React from 'react';

import { FormMode } from 'common/form/Form';
import { useForm } from 'common/form/FormHook';
import { formatMonetaryValue, formatMonetaryValueNoCurrency, isMonetary, unformatMonetaryValue } from 'common/utils/monetaryUtils';
import { isNotEmpty } from 'common/validation/yupUtils';

import { renderHelpText } from './HelpTextRenderer';
import ReadOnlyField from './ReadOnlyField';

export interface TextFieldProps extends ITextFieldProps {
  showMaxHint?: boolean;
  loading?: boolean;
  formatText?: (text: string) => string;
  errorImmediate?: boolean;
  helpText?: string;
  readOnlyRenderFormat?: (value: any) => any;
  readOnlyEmptyFormat?: any;
}

const error = (meta: FieldMetaProps<string>, errorMessage?: string | JSX.Element) => {
  if (errorMessage) {
    return errorMessage;
  }

  return meta.touched && meta.error ? meta.error : undefined;
};

const commonProps = (
  props: FieldAttributes<TextFieldProps>,
  field: FieldInputProps<string>,
  meta: FieldMetaProps<string>,
  mode: FormMode,
): FieldAttributes<TextFieldProps> => {
  return {
    ...props,
    ...field,
    onChange: props.onChange
      ? (_: any) => {
          field.onChange(_);
          props.onChange!(_);
        }
      : field.onChange,
    errorMessage: error(meta, props.errorMessage),
    disabled: mode === 'DISABLED' || props.disabled,
    onRenderLabel: renderHelpText,
  };
};
const isEmpty = (value?: number | string) => !value && value !== 0;

const wrapper = (element: JSX.Element, props: TextFieldProps): JSX.Element => {
  return <div data-input-wrapper>{element}</div>;
};

export const TextField: React.FC<FieldAttributes<TextFieldProps>> = (props) => {
  const { mode } = useForm();
  const field = useField(props);

  const [description, setDescription] = React.useState<string>('');

  if (mode === 'VIEW' || props.readOnly) {
    return <ReadOnlyField {...props} renderFormat={props.readOnlyRenderFormat} emptyFormat={props.readOnlyEmptyFormat} />;
  }

  return wrapper(
    <FluentUITextField
      {...field}
      aria-required={props.required}
      data-automation-id={props.name}
      description={props.description ?? description}
      errorMessage={description ? '' : field.errorMessage}
      onBlur={(event) => {
        field.onBlur(event);

        if (props.showMaxHint && props.maxLength) {
          /**
           * This is to delay the screen elements from moving up right after the user finished typing
           * and avoid the need for users to click button twice because its position has changed.
           */
          setTimeout(() => {
            setDescription('');
          }, 500);
        }
      }}
      onFocus={(event) => {
        if (props.showMaxHint && props.maxLength) {
          setDescription(`Max ${props.maxLength} characters`);
        }

        props.onFocus && props.onFocus(event);
      }}
      styles={getStyles()}
    />,
    props,
  );
};

export const MoneyTextField: React.FC<FieldAttributes<TextFieldProps>> = (props) => {
  const [field, meta, fieldHelper] = useFormikField<string>(props as any);
  const { mode } = useForm();
  const toNumber = (value?: string | number): number | undefined =>
    value || typeof value === 'number' ? unformatMonetaryValue('' + value) : undefined;
  const isChanged = (value: any) => toNumber(meta.initialValue) !== toNumber(value);
  const formattedInitialValue = !isEmpty(meta.initialValue) ? formatMonetaryValueNoCurrency(toNumber(meta.initialValue)) : undefined;

  if (mode === 'VIEW' || props.readOnly) {
    return <ReadOnlyField {...props} renderFormat={formatMonetaryValue} emptyFormat={props.readOnlyEmptyFormat} />;
  }

  const wrappedProps: FieldAttributes<TextFieldProps> = {
    ...commonProps(props, !isEmpty(field.value) ? field : { ...field, value: '' }, meta, mode),
    // Format the initial value without dirty'ing the form.
    value: !isEmpty(meta.initialValue) && meta.initialValue === field.value ? formattedInitialValue : field.value ?? '',
    maxLength: 16,
    onBlur: (_: any) => {
      field.onBlur(_);
      if (!isEmpty(field.value) && isMonetary(field.value)) {
        if (isChanged(field.value)) {
          fieldHelper.setValue(formatMonetaryValueNoCurrency(toNumber(field.value)) as string);
        } else {
          // If initial value is the same as the unformatted value then make value the initial value, to pristine the form field.
          // e.g. if value='1.0' and initialValue=1 then value=1
          if (meta.initialValue !== field.value) {
            fieldHelper.setValue(meta.initialValue!);
          }
        }
      }
    },
  };

  return props.loading
    ? wrapper(<FluentUITextField {...wrappedProps} onRenderSuffix={() => <Spinner />} />, wrappedProps)
    : wrapper(<FluentUITextField {...wrappedProps} />, wrappedProps);
};

const useField = (props: FieldAttributes<TextFieldProps>) => {
  const { formatText, loading, onBlur, onChange, ...fieldProps } = props;
  const [field, meta, fieldHelper] = useFormikField<string>(props.name);
  const { mode } = useForm();

  const formattedInitialValue = meta.initialValue && props.formatText ? props.formatText(meta.initialValue) : meta.initialValue ?? '';
  const value = isNotEmpty(meta.initialValue) && meta.initialValue === field.value ? formattedInitialValue : field.value ?? '';

  const onRenderSuffix = loading && { onRenderSuffix: () => <Spinner /> };

  return {
    ...field,
    ...fieldProps,
    ...(onRenderSuffix ?? {}),
    disabled: mode === 'DISABLED' || props.disabled,
    errorMessage: props.errorMessage ? props.errorMessage : meta.touched && meta.error ? meta.error : '',
    onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      field.onBlur(event);
      onBlur && onBlur(event);

      if (field.value && props.formatText) {
        const formattedText = props.formatText(field.value);
        if (formattedText !== field.value) {
          fieldHelper.setValue(formattedText);
        }
      }
    },
    onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      field.onChange(event);
      onChange && onChange(event, newValue);
    },
    onRenderLabel: renderHelpText,
    value,
  };
};

const getStyles = (): Partial<ITextFieldStyles> => {
  return {
    description: {
      float: 'right',
      marginTop: 5,
      textAlign: 'right',
    },
  };
};
