import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import React, { useState } from 'react';

import { ManagementRightsSearchCriteria, ManagementRightsSearchResult, ManagementRightsSearchResults, ManagementRightsStatusEnum } from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import { defaultUserPreference, getPreferenceSortAndPageSize, searchManagementRightOptions } from 'common/user/components/UserPreference';
import { isEmpty } from 'common/utils/objectUtils';
import { usePublicApi } from 'public/PublicApiHook';
import { searchResultsFocus } from 'search/utils';

import { validateWithFormValues } from '../../common/validation/validationWithFormValues';
import { SEARCH_FORM_MODE } from '../SearchForm';
import { useSearchNavigation } from '../SearchRouter';
import SearchManagementRightsForm from './SearchManagementRightsForm';
import SearchManagementRightsResults from './SearchManagementRightsResults';
import { searchManagementRightsValidationSchema } from './validationSchema';

export const defaultValues: ManagementRightsSearchCriteria = {
  searchText: undefined,
  refFrequency: undefined,
  refFrequencyFrom: undefined,
  refFrequencyTo: undefined,
  managementRightsStatuses: [ManagementRightsStatusEnum.Current, ManagementRightsStatusEnum.Registered],
};

interface Props {
  mode: SEARCH_FORM_MODE;
  initialValues: ManagementRightsSearchCriteria;
  onSelect?: (item: ManagementRightsSearchResult) => void;
  hideManagementRightsStatuses?: boolean;
}

const SearchManagementRights: React.FC<Props> = ({ mode, initialValues, onSelect, hideManagementRightsStatuses }: Props) => {
  const [searchCriteria, setSearchCriteria] = useState<ManagementRightsSearchCriteria>();
  const [searchResults, setSearchResults] = useState<ManagementRightsSearchResults>();
  const { searchManagementRightsPublic } = usePublicApi();
  const { navigateToSearchManagementRights } = useSearchNavigation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { userPreference } = useAuth();

  const handleSearch =
    mode === 'STAND_ALONE'
      ? undefined
      : (values: any, formProps: FormikHelpers<any>) => {
          formProps.validateForm(values).then((errors) => {
            formProps.setErrors(errors);
            if (isEmpty(errors)) {
              return handleSubmit(values);
            }
          });
        };

  const handleSubmit = (formValues: ManagementRightsSearchCriteria) => {
    const up = userPreference ?? defaultUserPreference;
    const searchPreference = up.searchManagementRightPreference ?? defaultUserPreference.searchManagementRightPreference;
    const preference = getPreferenceSortAndPageSize(searchPreference, up?.otherPreference!, searchManagementRightOptions);
    const searchCriteria: ManagementRightsSearchCriteria = {
      ...formValues,
      orderBy: formValues.orderBy ?? preference.orderBy,
      pageSize: formValues.pageSize ?? preference.pageSize,
    };
    if (mode === 'STAND_ALONE') {
      navigateToSearchManagementRights(searchCriteria, true);
    }
    return searchManagementRightsPublic(searchCriteria, { showLoadingSpinner: 'Searching...' }).then((searchResults) => {
      setSearchCriteria({
        ...formValues,
        orderBy: searchResults.orderBy ?? formValues.orderBy,
      });
      setSearchResults(searchResults);
      setSubmitted(true);
      searchResultsFocus();
      return searchResults;
    });
  };

  const handleClear = () => {
    setSearchCriteria(defaultValues as any);
    setSearchResults(undefined);
    setSubmitted(false);
  };

  const handlePaging = (page: number, pageSize: number) => {
    const paging = {
      ...searchCriteria,
      page,
      pageSize,
    };
    mode === 'STAND_ALONE'
      ? navigateToSearchManagementRights(paging, true)
      : searchManagementRightsPublic(paging).then((results) => {
          setSearchCriteria(paging);
          setSearchResults(results);
        });
  };

  const handleOrderBy = (orderBy: string) => {
    const sort = {
      ...searchCriteria,
      orderBy,
    };
    mode === 'STAND_ALONE'
      ? navigateToSearchManagementRights(sort, true)
      : searchManagementRightsPublic(sort).then((results) => {
          setSearchCriteria(sort);
          setSearchResults(results);
        });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnBlur
        validate={validateWithFormValues(searchManagementRightsValidationSchema)}
      >
        {(formProps) => (
          <SearchManagementRightsForm
            onSearch={handleSearch ? (values) => handleSearch(values, formProps) : undefined}
            onClear={handleClear}
            mode={mode}
            hideManagementRightsStatuses={hideManagementRightsStatuses}
          />
        )}
      </Formik>

      {submitted && (
        <SearchManagementRightsResults
          searchCriteria={searchCriteria}
          searchResults={searchResults}
          onPaging={handlePaging}
          onOrderBy={handleOrderBy}
          onSelect={onSelect}
        />
      )}
    </>
  );
};

export default SearchManagementRights;
