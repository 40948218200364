import { useFormikContext } from 'formik';
import React from 'react';

import { CaseFinalResultEnum } from 'api-client';

import { Dropdown, Grid } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import Error from 'common/controls/items/Error';
import { AUDIT_RECOMMENDATION_OPTIONS, AUDIT_RESULT_OPTIONS } from 'common/reference/referenceData';

import { AuditRecommendationFormProps } from './AuditRecommendationDialog';
import AuditRecommendationInfringement from './AuditRecommendationInfringement';
import AuditRecommendationOffences from './AuditRecommendationOffences';

const AuditRecommendationDialogContent = () => {
  const resultOptions = AUDIT_RESULT_OPTIONS.filter(({ key }) =>
    [CaseFinalResultEnum.SATISFACTORY, CaseFinalResultEnum.UNSATISFACTORY, CaseFinalResultEnum.DISCARD].includes(key),
  );

  const { errors, touched, values } = useFormikContext<AuditRecommendationFormProps>();
  const { caseNumber, caseType, isOffencesRequired, ...props } = values.caseDetails;
  const offenceError = (errors as any).offence;
  const isUnsatisfactory = values.finalResult === CaseFinalResultEnum.UNSATISFACTORY;
  const isDiscarded = values.finalResult === CaseFinalResultEnum.DISCARD;

  const notesLabel = isDiscarded ? 'Reason for discarding case' : 'Notes';

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={6} lg={6} style={{ display: 'inline-block' }}>
          <ReadOnlyField label="Case number" name="caseDetails.caseNumber" />
        </Grid.Col>
        <Grid.Col md={6} lg={6} style={{ display: 'inline-block' }}>
          <ReadOnlyField label="Case type" name="caseDetails.caseType" />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6} style={{ display: 'inline-block' }}>
          <Dropdown label="Audit result" name="finalResult" options={resultOptions} required />
        </Grid.Col>
        {isUnsatisfactory && (
          <Grid.Col lg={6} style={{ display: 'inline-block' }}>
            <Dropdown label="Recommendation" name="recommendation" options={AUDIT_RECOMMENDATION_OPTIONS} required />
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <TextField label={notesLabel} name="finalResultNotes" multiline maxLength={400} showMaxHint required={isDiscarded} />
        </Grid.Col>
      </Grid.Row>

      {isUnsatisfactory && (
        <Grid.Row>
          <Grid.Col>
            <AuditRecommendationInfringement />
          </Grid.Col>
          {isOffencesRequired && (
            <Grid.Col>
              <AuditRecommendationOffences
                caseTypeCode={props.caseTypeCode}
                emcAuditType={props.emcAuditType}
                radioAuditType={props.radioAuditType}
              />
              {(touched as any).offence && offenceError && <Error errorMessage={offenceError} />}
            </Grid.Col>
          )}
        </Grid.Row>
      )}
    </Grid>
  );
};

export default AuditRecommendationDialogContent;
