import { CommandBarButton, DefaultButton } from '@fluentui/react';
import { ResponsiveLine, LineSvgProps } from '@nivo/line';
import React, { useMemo, useState } from 'react';

import { EmissionLimitDto } from 'api-client';

import { Grid } from 'ui-library';

import { calculateRange, interpolatePoints } from 'common/utils/chartUtils';

interface EmissionLimit extends Pick<EmissionLimitDto, 'limitFrequency' | 'limitValue'> {}

interface UelGraphProps {
  adjacentFrequencyEmissionLimitLow: EmissionLimit[];
  adjacentFrequencyEmissionLimitHigh: EmissionLimit[];
  lowerBound?: number;
  upperBound?: number;
  chartProps?: Partial<LineSvgProps>;
  onViewUelPts?: () => void;
  onEditUelPts?: () => void;
}

const UelGraph: React.FC<UelGraphProps> = ({
  adjacentFrequencyEmissionLimitLow,
  adjacentFrequencyEmissionLimitHigh,
  lowerBound = 0,
  upperBound = 0,
  chartProps,
  onViewUelPts,
  onEditUelPts,
}) => {
  const [hiddenIds, setHiddenIds] = useState<(string | number)[]>([]);

  const mapLimit = (afel: EmissionLimit) => {
    return {
      x: afel.limitFrequency,
      y: afel.limitValue,
    };
  };

  const { data, interpolatedData, xRange, yRange } = useMemo(() => {
    const data = [
      {
        id: 'Lower UEL',
        data: hiddenIds.includes('Lower UEL') ? [] : adjacentFrequencyEmissionLimitLow.map(mapLimit),
      },
      {
        id: 'Upper UEL',
        data: hiddenIds.includes('Upper UEL') ? [] : adjacentFrequencyEmissionLimitHigh.map(mapLimit),
      },
    ];
    const range = calculateRange(data, hiddenIds);
    return {
      data,
      interpolatedData: data.map((series) => {
        return {
          id: series.id + ' (interpolated)',
          data: interpolatePoints(series.data),
        };
      }),
      ...range,
    };
  }, [adjacentFrequencyEmissionLimitHigh, adjacentFrequencyEmissionLimitLow, hiddenIds]);

  const colours = ['#b20000', '#ff0000', '#0000b2', '#00b200', '', '', '', ''];

  return (
    <>
      <Grid.Row>
        <Grid.Col lg={7}></Grid.Col>
        <Grid.Col lg={5} style={{ textAlign: 'right', display: 'inline-block' }}>
          {onViewUelPts && <DefaultButton id="uel-button" text="UEL" onClick={onViewUelPts} />}
          {onEditUelPts && <DefaultButton id="uel-points-button" text="UEL Points" onClick={onEditUelPts} style={{ marginLeft: 10 }} />}
        </Grid.Col>
      </Grid.Row>
      <div style={{ height: '400px' }} data-automation-id="emission-limits-chart" role="img" aria-label="Unwanted emission limits chart">
        <ResponsiveLine
          role="presentation"
          data={[...data, ...interpolatedData]}
          margin={{ top: 20, right: 0, bottom: 50, left: 50 }}
          xScale={{ type: 'linear', min: xRange.min - xRange.padding, max: xRange.max + xRange.padding }}
          yScale={{ type: 'linear', min: yRange.min - yRange.padding, max: yRange.max + yRange.padding }}
          colors={colours}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Frequency (MHz)',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Power (dBW)',
            legendOffset: -40,
            legendPosition: 'middle',
          }}
          axisTop={{
            tickValues: [],
          }}
          axisRight={{
            tickValues: [],
          }}
          enablePoints={false}
          markers={[
            {
              axis: 'x',
              value: lowerBound,
              lineStyle: { strokeDasharray: 4 },
            },
            {
              axis: 'x',
              value: upperBound,
              lineStyle: { strokeDasharray: 4 },
            },
          ]}
          useMesh
          theme={{
            fontFamily: 'inherit',
            axis: {
              domain: {
                line: {
                  stroke: '#dddddd',
                  strokeWidth: 1,
                },
              },
            },
            markers: {
              lineColor: '#000',
              lineStrokeWidth: 2,
            },
            grid: {
              line: {
                strokeDasharray: 2,
              },
            },
          }}
          tooltip={(input) => {
            return (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc',
                }}
              >
                <table>
                  <caption>
                    <b>{input.point.serieId.toString().replace(' (interpolated)', '')}</b>
                  </caption>
                  <tbody>
                    <tr>
                      <td>
                        <b>Frequency:</b>
                      </td>
                      <td>{Number(input.point.data.x)?.toFixed(6)} MHz</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Power:</b>
                      </td>
                      <td>{Number(input.point.data.y)?.toFixed(1)} dBW</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          }}
          {...chartProps}
        />
      </div>

      <div style={{ textAlign: 'center', fontSize: '12px' }} role="presentation">
        <div style={{ fontWeight: 'bold', display: 'inline-block', verticalAlign: 'top' }}>Layers:</div>
        {data.map((series, index) => {
          const checked = hiddenIds.includes(series.id);
          return (
            <CommandBarButton
              key={series.id}
              iconProps={{
                iconName: 'ToggleFilled',
                style: {
                  color: colours[index],
                },
              }}
              onClick={() => setHiddenIds((state) => (checked ? state.filter((item) => item !== series.id) : [...state, series.id]))}
              toggle
              checked={checked}
              style={{
                fontSize: '12px',
              }}
            >
              {series.id}
            </CommandBarButton>
          );
        })}
        <div style={{ marginTop: 10 }}>Select a label above to show / hide the related data points on the graph.</div>
      </div>
    </>
  );
};

export default UelGraph;
