import { BlockBlobClient } from '@azure/storage-blob';
import { DefaultButton, Icon, Link, mergeStyles, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react';
import React, { useState } from 'react';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';

import { AvUploadLocation } from 'api-client';

import ErrorLabel from '../../common/controls/inputs/ErrorLabel';
import Error from '../../common/controls/items/Error';
import { removeFromArray } from '../../common/utils/arrayUtils';
import { useDocumentManagementApi } from '../DocumentManagementApiHook';

export interface FileUploadProps {
  id: string;
  validator?: (file: File) => FileError | null;
  asyncValidator?: (file: File) => Promise<void>;
  onFileUploaded: (file: DrmDocument) => Promise<void>;
  onFileRemoved: (file: DrmDocument) => Promise<void>;
  documentType: string;
  singleFile?: boolean;
  acceptedFileTypes?: string[];
  formFieldName?: string;
  existingFiles?: DrmDocument[];
  readonly?: boolean;
  canViewFile?: boolean;
  onFileView?: (file: DrmDocument) => Promise<void>;
}

export type DrmFile = File & {
  tempUploadLocation: string;
  uploadCompleteUrl?: string;
  statusUrl?: string;
};

export interface DrmDocument {
  drmId: string;
  name: string;
  file?: File;
}

const fileListRowClass = mergeStyles({
  padding: '5px 5px 5px 10px',
  backgroundColor: 'white',
});

const buttonRowDivClass = mergeStyles({
  marginTop: 10,
});

const spinnerStyle = {
  label: {
    color: '#ff6900',
  },
  circle: {
    borderColor: '#ff6900', // circle color
    borderRightColor: 'rgba(0, 0, 0, 0)', // transparent
  },
};

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
  const theme = useTheme();
  const outerDivClass = mergeStyles({
    padding: '10px 15px',
    marginBottom: 10,
    background: '#ededed',
    color: theme.semanticColors.bodyText,
  });

  const [fileList, setFileList] = useState<DrmFile[]>([]);
  const [error, setError] = useState<string>();
  const [storedFiles, setStoredFiles] = useState<DrmDocument[]>([]);
  const [uploadStatus, setUploadStatus] = useState<'UPLOADING' | 'UPLOAD COMPLETE' | 'PROCESSING' | 'SCAN SUCCESS' | 'COMPLETE' | 'ERROR' | null>(
    null,
  );
  const { createDocumentUpload, storeDocument } = useDocumentManagementApi();

  const uniqueFileValidator = (file: File): FileError | null => {
    if (fileList.some((selectedFile) => selectedFile.name === file.name)) {
      return {
        code: 'non-unique-file',
        message: 'the name of the file is not unique',
      };
    }
    return null;
  };

  const validateFilesAsync = (file: File): Promise<void> => {
    if (props.asyncValidator) {
      return props.asyncValidator(file);
    }
    return Promise.resolve();
  };

  const handleOnDrop = (files: File[], rejectedFiles: FileRejection[]) => {
    const fileToUpload = files[0]; // restricting to a single file
    if (rejectedFiles && rejectedFiles.length > 0) {
      //defaulting to a single file for now
      const rejectedFile = rejectedFiles[0];
      const rrfErrors = rejectedFile.errors
        .filter((error) => error.code.startsWith('rrf-'))
        .map((error) => error.message)
        .join(' ');
      if (rrfErrors) {
        setUploadStatus('ERROR');
        setError(rrfErrors);
        return;
      }
    }
    setUploadStatus('UPLOADING');
    setError(undefined);
    validateFilesAsync(fileToUpload)
      // getting AV temp location
      .then(() => getAvUploadLocation(fileToUpload))
      // uploading files to AV location
      .then((avLocation) => uploadFiles(fileToUpload, avLocation))
      .then((uploadedFile) => setFileList((currentFiles) => [...currentFiles, uploadedFile]))
      .then(() => setUploadStatus('UPLOAD COMPLETE'))
      .catch((reason: FileError) => {
        setUploadStatus('ERROR');
        setError(reason.message);
      });
  };

  const { getInputProps, getRootProps, open } = useDropzone({
    onDrop: handleOnDrop,
    validator: props.validator ? props.validator : uniqueFileValidator,
    multiple: false,
    accept: props.acceptedFileTypes,
    noClick: true,
  });

  const uploadFiles = (file: File, location: AvUploadLocation): Promise<DrmFile> => {
    const blockBlobClient = new BlockBlobClient(location.uploadLocationUrl);
    const fileToUpload = file && (file as DrmFile); //for now assume there's only 1 file
    const options = { metadata: { filename: encodeURIComponent(fileToUpload.name) } };
    return blockBlobClient.uploadBrowserData(fileToUpload, options).then(() => {
      fileToUpload.tempUploadLocation = location.uploadFolder;
      fileToUpload.uploadCompleteUrl = location.uploadCompleteUrl;
      fileToUpload.statusUrl = location.statusUrl;
      return fileToUpload;
    });
  };

  const notifyUploadComplete = (files: DrmFile[]): void => {
    // assume just one file
    if (files.length >= 1 && files[0].uploadCompleteUrl && files[0].statusUrl) {
      // notify AV function that file uplod is complete
      fetch(files[0].uploadCompleteUrl)
        .then((res) => res.json())
        .then(
          (result) => {
            setUploadStatus('PROCESSING'); // notify successful, start timer to poll for Av status
            setTimeout(pollAvStatus, 1000, files[0].statusUrl);
          },
          (error) => {
            console.log('* error from notify', error);
            setUploadStatus('ERROR'); // notify failed
          },
        );
    } else {
      setUploadStatus('ERROR'); // no files in notify, fail
    }
  };

  const pollAvStatus = (pollUrl: string) => {
    fetch(pollUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          if ('QUEUED' === result.avScanStatus || 'SCAN_PASS' === result.avScanStatus || 'SCAN_PASS_PARTIAL' === result.avScanStatus) {
            // these re the valid non-terminal states that require us to continue polling
            setTimeout(pollAvStatus, 1000, pollUrl);
          } else if ('MOVED' === result.avScanStatus || 'MOVED_PARTIAL' === result.avScanStatus) {
            // success or partial success states (as we are currently doing one file should never have to consider the partial success)
            setUploadStatus('SCAN SUCCESS');
          } else {
            // else scan fail
            console.log('scan fail, status: ', result.avScanStatus);
            setUploadStatus('ERROR'); // fail
          }
        },
        (error) => {
          setUploadStatus('ERROR'); // fail
        },
      );
  };

  const processScannedFiles = (files: DrmFile[], documentType: string): Promise<DrmDocument> => {
    return storeDocument({ fileName: files[0].name, tempLocation: files[0].tempUploadLocation, documentType }).then((storedDocument) => {
      return Promise.resolve({
        drmId: storedDocument.drmId,
        name: files[0].name,
        file: files[0],
      });
    });
  };

  const getAvUploadLocation = (file: File): Promise<AvUploadLocation> => {
    //let's retrieve the upload location
    const filename = file && file.name; //for now assume there's only 1 file
    return createDocumentUpload({ filename });
  };

  const handleFileRemoved = (index: number) => {
    props
      .onFileRemoved(storedFiles[index])
      .then(() => setStoredFiles((currentFiles) => removeFromArray(index, currentFiles)))
      .then(() => {
        setUploadStatus(null);
        setError(undefined);
      })
      .catch((reason) => console.log(`TODO error handling: ${reason}`));
  };

  React.useEffect(() => {
    if (uploadStatus === 'UPLOAD COMPLETE') {
      //poll AV until completed, then notify DRM and pass location to DRM so it can move files across
      notifyUploadComplete(fileList);
      // notify and then start polling
    } else if (uploadStatus === 'SCAN SUCCESS') {
      processScannedFiles(fileList, props.documentType)
        .then((storedDocument) => {
          if (props.singleFile) {
            setStoredFiles([storedDocument]);
          } else {
            setStoredFiles((currentFiles) => [...currentFiles, storedDocument]);
          }
          return storedDocument;
        })
        .then((storedDocument) => props.onFileUploaded(storedDocument))
        .then(() => setUploadStatus('COMPLETE'))
        .then(() => setFileList([]))
        .catch(() => setUploadStatus('ERROR'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, fileList]);

  React.useEffect(() => {
    if (props.existingFiles && props.existingFiles.length > 0 && storedFiles.length === 0) {
      setStoredFiles(props.existingFiles);
    } else if (props.existingFiles && props.existingFiles.length === 0) {
      setStoredFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.existingFiles]);

  const showSpinner = uploadStatus && !['COMPLETE', 'ERROR'].some((value) => value === uploadStatus);
  const uploadComplete = uploadStatus && uploadStatus === 'COMPLETE';
  const uploadError = uploadStatus && uploadStatus === 'ERROR';
  const isDisabled = uploadStatus === 'UPLOADING' || uploadStatus === 'PROCESSING';

  return (
    <div {...getRootProps()} className={outerDivClass}>
      {!props.readonly && (
        <div>
          <div>
            <input {...getInputProps()} disabled={isDisabled} />
            <p>Drag and drop files here to upload them or click the upload button to choose a file.</p>
          </div>
        </div>
      )}
      <div>
        {storedFiles.map((file, index) => {
          return (
            <div className={fileListRowClass} key={'uploaded-file-' + index}>
              {props.canViewFile && props.onFileView ? (
                <Link
                  onClick={() => {
                    props.onFileView && props.onFileView(file);
                  }}
                >
                  {file.name}
                </Link>
              ) : (
                file.name
              )}
              {!props.readonly && (
                <div style={{ float: 'right', height: 0, width: 0, cursor: 'pointer' }} onClick={() => handleFileRemoved(index)}>
                  <div style={{ position: 'relative', paddingTop: 1, marginLeft: -22 }}>
                    <Icon iconName="Cancel" style={{ color: '#333333' }} />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!props.readonly && (
        <div className={buttonRowDivClass}>
          <div>
            <Stack horizontal={true} verticalAlign={'center'} tokens={{ childrenGap: 10 }}>
              <DefaultButton
                onClick={open}
                iconProps={{ iconName: 'PublishContent' }}
                disabled={isDisabled}
                id={props.id}
                data-automation-id={props.id}
              >
                Upload
              </DefaultButton>
              {showSpinner && (
                <>
                  <Spinner size={SpinnerSize.small} styles={spinnerStyle} />
                  <p style={{ color: '#ff6900' }}>File uploading...</p>
                </>
              )}
              {uploadComplete && (
                <>
                  <Icon iconName="CompletedSolid" style={{ marginLeft: '5px', color: '#ff6900', fontSize: 20, verticalAlign: 'middle' }} />
                  <p style={{ color: '#ff6900' }}>Upload complete</p>
                </>
              )}
              {uploadError && (
                <>
                  <Icon iconName="AlertSolid" style={{ marginLeft: '5px', color: '#ce0404', fontSize: 20, verticalAlign: 'middle' }} />
                  <p style={{ color: '#ce0404' }}>Upload failed</p>
                </>
              )}
            </Stack>
            <div>
              {props.formFieldName && <ErrorLabel name={props.formFieldName} showIcon />}
              {error && <Error errorMessage={error} showIcon />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
