import * as yup from 'yup';

import { GeoreferenceDto, GeoreferenceGroupDto, GeoreferenceTypeEnum } from 'api-client';

import { getValueByPath } from '../../../common/utils/objectUtils';
import { requiredList, requiredString } from '../../../common/validation/yupUtils';
import { validNZMS260MapNumbers, validTopo50MapNumbers } from './locationSupportData';

/**
 * This whole number check is similar with yupUtils.requiredWholePositiveNumber. just to solve the white space issue being trimmed.
 */
const requiredWholeNumberInRange = (range: number[], message: string) =>
  yup
    .string()
    .nullable(true)
    .required('Required')
    .test('value check', '', function () {
      const value = getValueByPath(this.options.context, this.path);
      if (/^\d+$/.test(value)) {
        const numberVal = Number.parseInt(value);
        if (numberVal >= range[0] && numberVal <= range[1]) {
          return true;
        }
      }
      return this.createError({ message });
    });

const isValidTopo50Format = (val: any) => /^[a-zA-Z]{2}\d{2}$/.test(val ?? '');
const isValidNZMS260Format = (val: any) => /^[a-zA-Z]\d{2}$/.test(val ?? '');

const isValidTopo50NZMS260EastingNorthingValue = (isNorthing: boolean) =>
  function (val: any) {
    // @ts-ignore
    const me: any = this;

    if (/^\d{0,3}(\.\d{0,2})?$/.test(val) && val !== '.') return true;

    if (/^\d*(\.\d*)$/.test(val ?? '')) {
      const decimal = val.toString().split('.')[1].length || 0;
      const numberVal = Number.parseFloat(val);
      if (decimal > 2 && numberVal >= 0 && numberVal < 1000) {
        return me.createError({
          message: 'Maximum of 2 decimal places allowed.',
        });
      }

      return me.createError({
        message: `Must be a number between 0 and 999.99 inclusive.`,
      });
    }
  };

const isValidLatLongValue = (isLat: boolean) =>
  function (val: any) {
    // @ts-ignore
    const me: any = this;
    if (/^(-?\d*)(\.\d*)?$/.test(val) && val !== '.') {
      const numberVal = Number.parseFloat(val);
      if (isLat ? numberVal >= -90 && numberVal <= 90 : numberVal >= -180 && numberVal <= 180) {
        if (!/^(-?\d*)(\.\d{0,7})?$/.test(val)) {
          // more than 7 decimal places.
          return me.createError({
            message: 'Maximum of 7 decimal places allowed.',
          });
        } else {
          return true;
        }
      }
    }
    return me.createError({
      message: isLat ? 'Must be a number between -90 and 90 inclusive.' : 'Must be a number between -180 and 180 inclusive.',
    });
  };

const topo50MapNumberShape = requiredString
  .test('valid format', 'Topo50 Map must be 4 characters - two letters then two digits, e.g. AS21.', isValidTopo50Format)
  .test('valid value', 'The Topo50 Map Reference is invalid: it is not part of the Topo50 map series. Please re-enter.', (val) =>
    isValidTopo50Format(val!) ? validTopo50MapNumbers.indexOf(val!.toUpperCase()) >= 0 : true,
  );

const nzms260MapNumberShape = requiredString
  .test('valid format', 'NZMS260 Map must be 3 characters - one letter then two digits, e.g. T15.', isValidNZMS260Format)
  .test('valid value', 'The NZMS260 Map Reference is invalid: it is not part of the NZMS260 map series. Please re-enter.', (val) =>
    isValidNZMS260Format(val!) ? validNZMS260MapNumbers.indexOf(val!.toUpperCase()) >= 0 : true,
  );

const isEqual = (a: GeoreferenceDto, b: GeoreferenceDto) => {
  return (
    a.mapNumber?.toUpperCase() === b.mapNumber?.toUpperCase() &&
    parseFloat(a.easting) === parseFloat(b.easting) &&
    parseFloat(a.northing) === parseFloat(b.northing)
  );
};

const eastingShape = requiredString
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.T,
    then: requiredString.test('format check', 'Must be a number between 0 and 999.99 inclusive.', isValidTopo50NZMS260EastingNorthingValue(false)),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.D2000,
    then: requiredString.test('valid value', '', isValidLatLongValue(false)),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.L,
    then: requiredWholeNumberInRange([1460592, 3175356], 'Must be a number between 1460592 and 3175356 inclusive.'),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.TM2000,
    then: requiredWholeNumberInRange([1084000, 3530000], 'Must be a number between 1084000 and 3530000 inclusive.'),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.M,
    then: requiredString
      .test('format check', 'Must be a number between 0 and 999.99 inclusive.', isValidTopo50NZMS260EastingNorthingValue(false))
      .test('easting range check', '', function () {
        // we do not care any exception inside. If exception happened, it means it will fail somewhere else.
        try {
          const validRange = calculateNZMS260EastingRange(this.parent.mapNumber);
          const inputVal = Number.parseFloat(this.parent.easting);
          if (!isInMapRange(inputVal, validRange) && isValidNZMS260Format(this.parent.mapNumber)) {
            return this.createError({
              message: `Easting range is ${validRange[0]} to ${validRange[1]}`,
            });
          } else {
            return true;
          }
        } catch (e) {
          return true;
        }
      }),
  });

const isInMapRange: (inputVal: number, range: number[]) => boolean = (inputVal: number, range: number[]) => {
  let valid = false;
  if (range[0] < range[1]) {
    if (inputVal >= range[0] && inputVal <= range[1]) {
      valid = true;
    }
  } else {
    if (inputVal >= range[0] || inputVal <= range[1]) {
      valid = true;
    }
  }
  return valid;
};

const calculateNZMS260EastingRange: (mapNumber: string) => number[] = (mapNumber: string) => {
  const mapLetterNo = mapNumber.toUpperCase().charCodeAt(0) - 64;
  const eStartRef = mapLetterNo * 400 + 19300;
  const eEndRef = eStartRef + 400;
  return [eStartRef % 1000, eEndRef % 1000];
};

const northingShape = requiredString
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.T,
    then: requiredString.test('format check', 'Must be a number between 0 and 999.99 inclusive.', isValidTopo50NZMS260EastingNorthingValue(true)),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.D2000,
    then: requiredString.test('valid value', '', isValidLatLongValue(true)),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.L,
    then: requiredWholeNumberInRange([4967744, 7021817], 'Must be a number between 4967744 and 7021817 inclusive.'),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.TM2000,
    then: requiredWholeNumberInRange([4722000, 6234000], 'Must be a number between 4722000 and 6234000 inclusive.'),
  })
  .when(['type'], {
    is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.M,
    then: requiredString
      .test('format check', 'Must be a number between 0 and 999.99 inclusive.', isValidTopo50NZMS260EastingNorthingValue(true))
      .test('northing range check', '', function () {
        // we do not care any exception inside. If exception happened, it means it will fail somewhere else.
        try {
          const validRange = calculateNZMS260NorthingRange(this.parent.mapNumber);
          const inputVal = Number.parseFloat(this.parent.northing);
          if (!isInMapRange(inputVal, validRange) && isValidNZMS260Format(this.parent.mapNumber)) {
            return this.createError({
              message: `Northing range is ${validRange[0]} to ${validRange[1]}`,
            });
          } else {
            return true;
          }
        } catch (e) {
          return true;
        }
      }),
  })
  .test('check dup', 'Each map/grid reference defined for a multiple point location must be unique.', function () {
    try {
      const validationCtx: any = this.options.context;
      const georeferences: GeoreferenceGroupDto[] = validationCtx.georeferences;
      return !georeferences.map((g) => g.origin).some((g: any) => g.order !== this.parent.order && isEqual(g, this.parent));
    } catch (e) {
      return true;
    }
  });

const calculateNZMS260NorthingRange: (mapNumber: string) => number[] = (mapNumber: string) => {
  const mapNo = Number.parseInt(mapNumber.substring(1));
  const nStartRef = (51 - mapNo) * 300 + 52600;
  const nEndRef = nStartRef + 300;

  return [nStartRef % 1000, nEndRef % 1000];
};

const georeferenceSchema = yup.object().shape({
  type: requiredString,
  mapNumber: yup
    .string()
    .nullable(true)
    .when(['type'], {
      is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.T,
      then: topo50MapNumberShape,
    })
    .when(['type'], {
      is: (type?: GeoreferenceTypeEnum) => type === GeoreferenceTypeEnum.M,
      then: nzms260MapNumberShape,
    }),
  northing: northingShape,
  easting: eastingShape,
});

const georeferenceGroupSchema = yup.object().shape({
  origin: georeferenceSchema,
});

const createPointSchema = yup.object().shape({
  locationName: requiredString.max(200),
  height: requiredString.test('', 'Must be an integer', (val) => /^-?\d*$/.test(val ?? '')),
  source: requiredString.test(
    'check source type for verifed location',
    'The Source must be either GPS or Surveyed for a verified location.',
    function () {
      return !this.parent.verified || this.parent.source === 'GPS' || this.parent.source === 'Surveyed';
    },
  ),
  georeferenceType: requiredString,
  georeference: georeferenceGroupSchema,
  description: yup.string().nullable(true).max(4000),
  siteDetails: yup.string().nullable(true).max(400),
});

const createDefinedAreaSchema = yup.object().shape({
  locationName: requiredString.max(200),
  nameQualifier: requiredString.max(60),
  districts: requiredList,
  description: yup.string().nullable(true).max(4000),
});

const createLocationNameSchema = yup.object().shape({
  locationName: requiredString,
  districts: requiredList,
});

const createMultipointSchema = yup.object().shape({
  locationName: requiredString,
  georeferenceType: requiredString,
  georeferences: yup.array().of(georeferenceGroupSchema),
});

export {
  createPointSchema,
  createDefinedAreaSchema,
  createLocationNameSchema,
  createMultipointSchema,
  topo50MapNumberShape,
  nzms260MapNumberShape,
  eastingShape,
  georeferenceGroupSchema,
  georeferenceSchema,
};
