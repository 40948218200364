import { Stack } from '@fluentui/react';
import * as React from 'react';
import { useEffect } from 'react';

import {
  CraftingConfigurationDto,
  LicenceClassificationType,
  LicenceSpectrumConfigurationDto,
  RadioApplicationDto,
  RadioLicenceDto,
} from 'api-client';

import { useLicenceApi } from 'licence_management/LicenceApiHooks';
import { useLicenceCraftingApi } from 'licence_management/craft/LicenceCraftingApiHook';
import LicenceConditions from 'licence_management/craft/components/Conditions/LicenceConditions';

import { getLicenceSpectrumConfig } from '../../../craft/components/SpectrumDetails/LicenceSpectrumDetails';
import LocationSection from './LocationSection';

export const earthToSpaceAndSpaceToEarth = LicenceClassificationType.A;
export const earthToSpaceOnly = LicenceClassificationType.B;
export const spaceToEarthOnly = LicenceClassificationType.C;

const SatelliteDetailsOtherSections = ({
  application,
  licence,
  onLicenceUpdated,
}: {
  application: RadioApplicationDto;
  licence: RadioLicenceDto;
  onLicenceUpdated: (licence: RadioLicenceDto, application: RadioApplicationDto) => void;
}) => {
  const { getSpectrumConfigs, getSatelliteAssociatedLicence } = useLicenceApi();
  const { getCraftingConfig, updateCraftingProgress } = useLicenceCraftingApi();
  const displayTransmitLocation =
    application.satellite?.licenceClassification === earthToSpaceOnly || application.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth;
  const displayReceiveLocation =
    application.satellite?.licenceClassification === spaceToEarthOnly || application.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth;
  const [craftingConfig, setCraftingConfig] = React.useState<CraftingConfigurationDto>();
  const [licenceSpectrumConfigs, setLicenceSpectrumConfigs] = React.useState<LicenceSpectrumConfigurationDto[] | undefined>();
  const [associatedLicence, setAssociatedLicence] = React.useState<RadioLicenceDto>();

  const licenceSpectrumConfig = getLicenceSpectrumConfig(licenceSpectrumConfigs, licence);

  const { isTransmitLocationDetailsDone, isReceiveLocationDetailsDone } = licence?.craftingProgress ?? {};

  // eslint-disable-next-line
  const isConditionsPrevSectionsDone =
    (application.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth &&
      !!isTransmitLocationDetailsDone &&
      !!isReceiveLocationDetailsDone) ||
    (application.satellite?.licenceClassification === earthToSpaceOnly && !!isTransmitLocationDetailsDone) ||
    (application.satellite?.licenceClassification === spaceToEarthOnly && !!isReceiveLocationDetailsDone);

  const isRcvLocPrevSectionsDone =
    (application.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth && !!isTransmitLocationDetailsDone) ||
    application.satellite?.licenceClassification === spaceToEarthOnly;

  useEffect(() => {
    getCraftingConfig(licence.id!, { showLoadingSpinner: true }).then(setCraftingConfig);
    getSpectrumConfigs({ showLoadingSpinner: true }).then(setLicenceSpectrumConfigs);
    if (application.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth) {
      getSatelliteAssociatedLicence(application.id ?? 0).then(setAssociatedLicence);
    } else {
      setAssociatedLicence(licence);
    }
  }, [application]);

  return (
    <Stack tokens={{ childrenGap: '5px' }}>
      {displayTransmitLocation && (
        <LocationSection
          locationType={'TRANSMIT'}
          licence={licence}
          locationConfiguration={craftingConfig?.locationConfiguration ?? {}}
          onDone={(isTransmitLocationDetailsDone) => {
            const craftingProgress = {
              ...licence.craftingProgress,
              isTransmitLocationDetailsDone,
            };
            return updateCraftingProgress(licence.id!, craftingProgress).then(() => onLicenceUpdated({ ...licence, craftingProgress }, application));
          }}
          isPreviousSectionsDone={true}
        />
      )}
      {displayReceiveLocation && associatedLicence && (
        <LocationSection
          locationType={'RECEIVE'}
          licence={licence.id !== associatedLicence.id ? associatedLicence : licence}
          locationConfiguration={craftingConfig?.locationConfiguration ?? {}}
          onDone={(isReceiveLocationDetailsDone: boolean) => {
            const craftingProgress = {
              ...licence.craftingProgress,
              isReceiveLocationDetailsDone,
            };
            if (licence.id !== associatedLicence?.id) {
              const craftingProgressAssociatedLic = {
                ...associatedLicence.craftingProgress,
                isReceiveLocationDetailsDone,
              };
              return updateCraftingProgress(associatedLicence.id!, craftingProgressAssociatedLic).then(() =>
                updateCraftingProgress(licence.id!, craftingProgress).then(() => {
                  onLicenceUpdated({ ...licence, craftingProgress }, application);
                  setAssociatedLicence({ ...associatedLicence, craftingProgress });
                }),
              );
            } else {
              return updateCraftingProgress(licence.id!, craftingProgress).then(() =>
                onLicenceUpdated({ ...licence, craftingProgress }, application),
              );
            }
          }}
          isPreviousSectionsDone={isRcvLocPrevSectionsDone}
        />
      )}
      {craftingConfig && licenceSpectrumConfig && (
        <LicenceConditions
          licence={licence}
          licenceSpectrumConfig={licenceSpectrumConfig}
          locationConfiguration={craftingConfig.locationConfiguration}
          onDone={(isConditionsDone) => {
            const craftingProgress = {
              ...licence.craftingProgress,
              isConditionsDone,
            };
            return updateCraftingProgress(licence.id!, craftingProgress).then(() => onLicenceUpdated({ ...licence, craftingProgress }, application));
          }}
          onSaved={(conditions) => onLicenceUpdated({ ...licence, ...conditions }, application)}
          isPrevSectionsDone={isConditionsPrevSectionsDone}
        />
      )}
    </Stack>
  );
};

export default SatelliteDetailsOtherSections;
