import { DefaultButton, IButtonProps } from '@fluentui/react';
import * as React from 'react';
import { useTheme } from '../../theme/RRFTheme';

interface Props extends IButtonProps {}

const SecondaryButton: React.FC<Props> = (props) => {
  const { semanticColors } = useTheme();
  const styles = {
    root: { backgroundColor: semanticColors.secondaryButtonBackground, borderColor: semanticColors.secondaryButtonBorder },
    rootHovered: { backgroundColor: semanticColors.secondaryButtonBackgroundHovered, borderColor: semanticColors.secondaryButtonBorderHovered },
  };

  return <DefaultButton {...props} styles={styles} />;
};

export default SecondaryButton;
