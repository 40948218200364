import { IColumn } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { OutageNotificationDto, OutageNotificationDtoStatusEnum } from 'api-client';

import { FormMode } from 'common/form/Form';

import InlineButton from '../common/controls/buttons/InlineButton';
import DetailsList, { SortProps } from '../common/controls/lists/DetailsList';
import { useMedia } from '../common/layout/MediaHook';
import Page from '../common/layout/Page';
import usePage from '../common/layout/PageHook';
import { SectionHeading } from '../common/layout/SectionHeadings';
import { formatDateFromISO, ISO_8601_NO_T_SEC } from '../common/utils/dateUtils';
import { useOutageNotificationApi } from './OutageNotificationApiHook';
import OutageNotificationRow from './OutageNotificationRow';

interface Props {
  onPreview: (item: OutageNotificationDto) => void;
  onRemove: (item: OutageNotificationDto, index: number) => void;
}

export enum OutageNotificationDtoPriorityEnum {
  High = 'High',
  Moderate = 'Moderate',
  Low = 'Low',
}

export const parsePriority = (priority: number) => {
  switch (priority) {
    case 1:
      return 'High';
    case 2:
      return 'Moderate';
    case 3:
      return 'Low';
    default:
      return 'High';
  }
};

const parseActiveStatus = (status: string) => {
  switch (status.toUpperCase()) {
    case 'ACTIVE':
      return 'Yes';
    case 'INACTIVE':
      return 'No';
    default:
      return 'Yes';
  }
};

const OutageNotificationsPage: React.FC<Props> = (props) => {
  const { getOutageNotifications, removeOutageNotification } = useOutageNotificationApi();
  const { isMobile } = useMedia();
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(-1);
  const { addOutageNotification, setShowWarningModal } = usePage();
  const [items, setItems] = useState<OutageNotificationDto[]>();

  const [mode] = useState<FormMode>('VIEW');
  const [itemToBeAdded, setItemToBeAdded] = useState<OutageNotificationDto>();
  const [deleteDisabledArray, setDeleteDisabledArray] = useState<boolean[]>();

  useEffect(() => {
    callOutageNotificationsApi();
  }, []);

  const callOutageNotificationsApi = () => {
    getOutageNotifications().then((result) => {
      setItems(result);
      setDeleteDisabledArray(Array(result?.length).fill(true));
    });
  };

  const addItem = mode === 'CREATE' ? (item: OutageNotificationDto) => setItems([item, ...(items || [])]) : callOutageNotificationsApi;
  const onRemove = (item: OutageNotificationDto, index: number) =>
    // (
    // <ConfirmDialog
    //   title="Delete outage notification"
    //   body={`You are about to delete outage notification ${index}. Do you wish to continue?`}
    //   onCancel={() => setExpandedIndex(-1)}
    //   onOk={() => removeOutageNotification(+item.id!, item).then(callOutageNotificationsApi)}
    // />);

    {
      setShowWarningModal(true, [`You are about to remove outage notification #${item.id}.`], false, {
        onConfirm: () => {
          setExpandedIndex(-1);
          if (deleteDisabledArray) deleteDisabledArray[index!] = true;
          setDeleteDisabledArray(deleteDisabledArray);
          removeOutageNotification(+item.id!, item).then(callOutageNotificationsApi);
        },
      });
    };
  const onPreview = (item: OutageNotificationDto) => addOutageNotification(item);

  const [sortProps, setSortProps] = useState<SortProps>({
    sortKey: ' ',
    descending: false,
  });

  const sort = (sourceItems: OutageNotificationDto[], sortName: string, descending: boolean) =>
    [...sourceItems].sort((a: any, b: any) => {
      const sortVal = a[sortName] < b[sortName] ? -1 : 1;
      return descending ? 0 - sortVal : sortVal;
    });

  const itemColumns = (
    isMobile: boolean,
    onView: (index: number) => void,
    onRemove: (item: OutageNotificationDto, index: number) => void,
    onPreview: (item: OutageNotificationDto) => void,
  ): IColumn[] => [
    {
      key: 'id',
      name: 'ID',
      fieldName: 'id',
      minWidth: isMobile ? 0 : 20,
      maxWidth: 50,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
    },
    {
      key: 'priority',
      name: 'Priority',
      fieldName: 'priority',
      minWidth: isMobile ? 0 : 30,
      maxWidth: 75,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: ({ priority }: OutageNotificationDto) => parsePriority(priority),
    },
    {
      key: 'from',
      name: 'From',
      fieldName: 'from',
      minWidth: isMobile ? 0 : 75,
      maxWidth: 150,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: ({ from }: OutageNotificationDto) => formatDateFromISO(from, ISO_8601_NO_T_SEC),
    },
    {
      key: 'to',
      name: 'To',
      fieldName: 'to',
      minWidth: isMobile ? 0 : 75,
      maxWidth: 150,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: ({ to }: OutageNotificationDto) => formatDateFromISO(to, ISO_8601_NO_T_SEC),
    },
    {
      key: 'message',
      name: 'Message',
      fieldName: 'message',
      minWidth: isMobile ? 0 : 150,
      maxWidth: 400,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
    },
    {
      key: 'status',
      name: 'Active',
      fieldName: 'status',
      minWidth: isMobile ? 0 : 50,
      maxWidth: 100,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: ({ status }: OutageNotificationDto) => parseActiveStatus(status),
    },
    {
      key: 'action',
      name: '',
      minWidth: isMobile ? 0 : 270,
      maxWidth: 270,
      isResizable: true,
      onRender: (item: OutageNotificationDto, index) => (
        <>
          <InlineButton
            text={'View'}
            id={'otg-notif-view-' + item.id}
            data-automation-id={'otg-notif-view-' + item.id}
            onClick={() => {
              onView(index!);
              if (deleteDisabledArray) {
                deleteDisabledArray[expandedIndex!] = true;
                deleteDisabledArray[index!] = false;
              }
              setDeleteDisabledArray(deleteDisabledArray);
            }}
          />
          <InlineButton
            text={'Delete'}
            id={'otg-notif-remove-' + item.id}
            data-automation-id={'otg-notif-remove-' + item.id}
            onClick={() => onRemove(item, index!)}
            disabled={deleteDisabledArray ? deleteDisabledArray[index!] : true}
          />
          <InlineButton
            text={'Preview'}
            id={'otg-notif-preview-' + item.id}
            data-automation-id={'otg-notif-preview-' + item.id}
            onClick={() => onPreview(item)}
            disabled={item.status === 'ACTIVE'}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Page title="Outage Notifications">
        <SectionHeading
          title={'Outage notifications'}
          actionButtonId={'add-outage-notif'}
          expanded
          actionButtonOnClick={() => {
            setItemToBeAdded({
              from: '',
              message: '',
              status: OutageNotificationDtoStatusEnum.INACTIVE,
              priority: 1,
            });
          }}
        >
          {itemToBeAdded && (
            <OutageNotificationRow
              item={{ ...itemToBeAdded }}
              mode={'create'}
              addItem={addItem}
              onClose={() => {
                setItemToBeAdded(undefined);
                if (deleteDisabledArray) deleteDisabledArray[expandedIndex!] = true;
                setExpandedIndex(-1);
                setDeleteDisabledArray(deleteDisabledArray);
              }}
            />
          )}
          <DetailsList
            loading={items?.length === 0}
            columns={itemColumns(isMobile, setExpandedIndex, onRemove, onPreview)}
            items={sort(items || [], sortProps.sortKey, sortProps.descending)}
            expandedIndex={expandedIndex}
            renderExpandPanel={(item: OutageNotificationDto, index?: number) => (
              <OutageNotificationRow
                item={item}
                mode={'view'}
                addItem={addItem}
                onClose={() => {
                  setExpandedIndex(-1);
                  if (deleteDisabledArray) deleteDisabledArray[index!] = true;
                  setDeleteDisabledArray(deleteDisabledArray);
                }}
              />
            )}
            sortProps={sortProps}
            onSort={(newSortProps) => {
              const excludeColumns = ['action'];
              if (excludeColumns.indexOf(newSortProps.sortKey) < 0) {
                setSortProps(newSortProps);
              }
            }}
          />
        </SectionHeading>
      </Page>
    </>
  );
};

export default OutageNotificationsPage;
