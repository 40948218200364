import React from 'react';

import YesNoButtons from '../inputs/YesNoButtons';
import Dialog from './Dialog';

export interface ConfirmDialogProps {
  onOk: () => void;
  onCancel: () => void;
  title: string;
  body: string | JSX.Element;
  id?: string;
  yesText?: string;
  noText?: string;
  visible?: boolean;
  disableOk?: boolean;
  // Has the effect of displaying the Yes button as a primary button instead of as a danger button.
  // Set this to true if the confirmation isn't regarding a negative warning i.e. isn't confirming to remove or reject
  // something.
  notDangerous?: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => (
  <Dialog title={props.title} isOpen={props.visible} id={props.id}>
    {props.body}

    <YesNoButtons
      yesText={props.yesText ? props.yesText : 'Yes'}
      noText={props.noText ? props.noText : 'No'}
      onYes={props.onOk}
      onNo={props.onCancel}
      disableYes={props.disableOk}
      notDangerous={props.notDangerous}
    />
  </Dialog>
);

export default ConfirmDialog;
