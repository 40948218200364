import { ITag, Label, TagItemSuggestion } from '@fluentui/react';
import React, { useState } from 'react';

import { LicenceSpectrumConfigurationDto, SpectrumDto, SpectrumSearchCriteriaSpectrumTypeEnum, SpectrumSearchResult } from 'api-client';

import { Grid } from 'ui-library';

import TagPicker from '../../../../common/controls/inputs/TagPicker';
import { highlightOrValue, SearchResultsHighlight } from '../../../../common/controls/items/Pagination';
import TooltipHint from '../../../../common/controls/items/TooltipHint';
import { FormContext } from '../../../../common/form/Form';
import { useSearchApi } from '../../../../search/SearchApiHooks';
import { Licence } from '../../CraftLicencePage';

const Channel = SpectrumSearchCriteriaSpectrumTypeEnum.Channel;
const Frequency = SpectrumSearchCriteriaSpectrumTypeEnum.Frequency;
const Channel_Frequency = SpectrumSearchCriteriaSpectrumTypeEnum.ChannelFrequency;

interface Props {
  // Existing licence spectrums to filter out in search results
  spectrums: SpectrumDto[];
  licenceSpectrumConfig: LicenceSpectrumConfigurationDto;
  licence?: Licence;
  onSelectedSpectrum: (id: number) => void;
}

const SpectrumTemplateSearch = (props: Props) => {
  return (
    <FormContext.Provider value={{ mode: 'SEARCH' }}>
      <Grid.Row>
        <Grid.Col sm={12} lg={10}>
          <SpectrumTagPicker {...props} />
        </Grid.Col>
        <Grid.Col sm={12} lg={2}>
          <Label>&nbsp;</Label>
          <TooltipHint content="Spectrum channel or frequency" />
        </Grid.Col>
      </Grid.Row>
    </FormContext.Provider>
  );
};

const SpectrumTagPicker: React.FC<
  Props & {
    onSelectedSpectrum: (id: number) => void;
  }
> = (props) => {
  const { incrementalSearchSpectrum } = useSearchApi();
  const [moreResultsAvailable, setMoreResultsAvailable] = useState<boolean>(true);
  const managementRights = props.licence?.managementRight;
  const refFrequencyMin = props.licenceSpectrumConfig.minFrequency ?? managementRights?.lowerBound;
  const refFrequencyMax = props.licenceSpectrumConfig.maxFrequency ?? managementRights?.upperBound;
  const bandwidthMin = props.licenceSpectrumConfig.minBandwidth;
  const bandwidthMax = props.licenceSpectrumConfig.maxBandwidth;
  const service = props.licence?.selectedServiceLevels?.serviceLevel1?.description;

  const resolveSuggestions = (searchText: string, page: number = 1) => {
    const { spectrumChannel, spectrumFrequency } = props.licenceSpectrumConfig;
    const spectrumType = spectrumChannel ? (spectrumFrequency ? Channel_Frequency : Channel) : spectrumFrequency ? Frequency : undefined;
    const pageSize = 100;

    const asTag = (spectrum: SpectrumSearchResult): ITag & { spectrum: SpectrumSearchResult } => ({
      key: spectrum.id,
      name: `${spectrum.channel} ${spectrum.lowerBound} ${spectrum.upperBound} ${spectrum.refFrequency}`,
      spectrum,
    });
    const byNotSelected = (spectrum: SpectrumSearchResult) => !props.spectrums.find((selected) => selected.createdFromId === spectrum.id);
    return incrementalSearchSpectrum({
      searchText,
      spectrumType,
      page,
      pageSize,
      refFrequencyMin,
      refFrequencyMax,
      bandwidthMin,
      bandwidthMax,
      service,
    }).then((searchResults) => {
      setMoreResultsAvailable(searchResults.totalItems > pageSize);
      return searchResults.results.filter(byNotSelected).map(asTag);
    });
  };

  return (
    <TagPicker
      label="Search for a spectrum record"
      name="spectrum"
      onResolveSuggestions={(searchText: string, selectedItems?: ITag[]) => resolveSuggestions(searchText)}
      onGetMoreResults={(searchText: string, selectedItems?: ITag[]) => resolveSuggestions(searchText, 2)}
      onRenderSuggestionsItem={(tag, itemProps) => {
        const spectrum: SpectrumSearchResult = (tag as any).spectrum;
        const longDash = <>&#8212;</>;
        return (
          <TagItemSuggestion {...(itemProps as any)}>
            <SearchResultsHighlight>
              {highlightOrValue(spectrum.channelHighlight, spectrum.channel) ?? longDash}&nbsp;&nbsp;
              {highlightOrValue(spectrum.lowerBoundHighlight, spectrum.lowerBound?.toFixed(6)) ?? longDash}
              {' - '}
              {highlightOrValue(spectrum.upperBoundHighlight, spectrum.upperBound?.toFixed(6)) ?? longDash}&nbsp;&nbsp;
              {highlightOrValue(spectrum.refFrequencyHighlight, spectrum.refFrequency?.toFixed(6)) ?? longDash}&nbsp;&nbsp;
              {spectrum.service ?? longDash}
            </SearchResultsHighlight>
          </TagItemSuggestion>
        );
      }}
      onItemSelected={(selectedItem) => {
        if (selectedItem) {
          props.onSelectedSpectrum(+selectedItem.key);
        }
        return null;
      }}
      inputProps={{ placeholder: 'Start typing to search for a spectrum record' }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for spectrum records',
        suggestionsHeaderText: 'Channel Low(MHz)-High(MHz) Ref.freq. Service',
        noResultsFoundText: 'No spectrum records found',
        searchForMoreText: moreResultsAvailable ? 'Get more spectrum records' : undefined,
      }}
    />
  );
};

export default SpectrumTemplateSearch;
