import React, { ReactNode, useEffect } from 'react';

import MasterButton from '../buttons/MasterButton';

export type MoreOrLessProps = {
  initiallyMore?: boolean;
  more: string | JSX.Element | ReactNode;
  less?: string | JSX.Element | ReactNode;
  lessText?: string;
  moreText?: string;
  id?: string;
  preserveMoreState?: boolean;
};

const MoreOrLess = (props: MoreOrLessProps) => {
  const [more, setShowMore] = React.useState<boolean>();

  useEffect(() => {
    setShowMore(!!props.initiallyMore);
  }, [props.initiallyMore]);

  return (
    <>
      {props.less}
      <div>
        <MoreOrLessBtn {...props} more={more} setShowMore={setShowMore} />
      </div>
      <div style={{ display: more ? 'block' : 'none' }}>{props.more}</div>
    </>
  );
};

const MoreOrLessBtn = (props: MoreOrLessProps & { setShowMore: (expanded: boolean) => void }) => {
  const iconProps = {
    iconName: props.more ? 'CalculatorSubtract' : 'Add',
  };
  const lessText = props.lessText ? props.lessText : 'Less';
  const moreText = props.moreText ? props.moreText : 'More';

  return (
    <MasterButton
      id={props.id}
      iconProps={iconProps}
      text={props.more ? lessText : moreText}
      onClick={() => props.setShowMore(!!!props.more)}
      aria-expanded={!!props.more}
    />
  );
};

export { MoreOrLessBtn };

export default MoreOrLess;
