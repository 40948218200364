import { useFormikContext } from 'formik';

import { Grid } from 'ui-library';

import Checkbox from 'common/controls/inputs/Checkbox';
import Dropdown from 'common/controls/inputs/Dropdown';
import { TextField } from 'common/controls/inputs/TextField';
import { SectionHeading } from 'common/layout/SectionHeadings';

import { georeferenceTypes, heightUnits, sources } from '../locationSupportData';
import GeoreferenceDetail from './GeoreferenceDetail';
import HeaderInformation from './HeaderInformation';
import LocationAuditInformation from './LocationAuditInformation';

export interface LocationFormProps {
  showHidden?: boolean;
  showAudit?: boolean;
  isSatellite?: boolean;
}

const PointFields = (props: LocationFormProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const georefTypeChange = (type?: any) => {
    setFieldValue(
      'georeference.origin',
      {
        ...values.georeference.origin,
        type,
        mapNumber: undefined,
        easting: undefined,
        northing: undefined,
      },
      true,
    );
    setFieldValue('georeference.convertions', [], true);
  };

  return (
    <>
      <HeaderInformation type={'Point'} />
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Location description'} name={'description'} multiline maxLength={4000} showMaxHint />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Site owner details'} name={'siteDetails'} maxLength={400} />
        </Grid.Col>
      </Grid.Row>
      {!props.isSatellite && (
        <Grid.Row>
          <Grid.Col sm={12} lg={1}>
            <TextField label={'Altitude'} name={'height'} required />
          </Grid.Col>
          <Grid.Col sm={12} lg={2}>
            <Dropdown name="heightUom" options={heightUnits} label={'Unit'} />
          </Grid.Col>
          <Grid.Col sm={12} lg={2} />
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Checkbox name={'verified'} label={'Verified'} />
        </Grid.Col>
        {props.showHidden && (
          <Grid.Col sm={12} lg={5}>
            <Checkbox name={'hidden'} label={'Hidden'} />
          </Grid.Col>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Dropdown name="source" label={'Source'} options={sources} required />
        </Grid.Col>
      </Grid.Row>
      {props.showAudit && <LocationAuditInformation />}
      <SectionHeading title={'Georeference details'} />
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Dropdown
            name="georeferenceType"
            label={'Georeference type'}
            options={georeferenceTypes}
            required
            onChange={georefTypeChange}
            readOnly={props.isSatellite}
          />
        </Grid.Col>
      </Grid.Row>
      <GeoreferenceDetail namePrefix={'georeference.origin.'} showRequired showDetails={true} />
    </>
  );
};

export default PointFields;
