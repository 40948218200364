import { ITag } from '@fluentui/react';
import _ from 'lodash';
import React from 'react';

import { ClientSearchCriteria, ClientSearchResult, ClientSearchResults } from 'api-client';

import { ApiConfig } from 'common/api/ApiHook';
import { useForm } from 'common/form/FormHook';
import { isEmpty } from 'common/utils/objectUtils';
import { useSearchApi } from 'search/SearchApiHooks';

import ReadOnlyField from '../ReadOnlyField';
import TagPicker from '../TagPicker';

interface ClientTagPickerProps {
  initialClientId?: string;
  initialClientName?: string;
  name: string;
  label?: string;
  criteria?: ClientSearchCriteria;
  disabled?: boolean;
  isRequired?: boolean;
  onChange?: (items?: ITag[]) => void;
  asClientTag?: (tag: ClientSearchResult) => { key: string; name: string };
  alwaysValidate?: boolean;
  hint?: string;
  readOnly?: boolean;
  selectedItem?: string;
  excludedClientIds?: number[];
  searchApi?: (criteria: ClientSearchCriteria, config?: ApiConfig) => Promise<ClientSearchResults>;
}

const ClientTagPicker: React.FC<ClientTagPickerProps> = (props) => {
  const { searchClient } = useSearchApi();
  const { mode } = useForm();

  const onResolveSuggestions = async (searchText: string, selectedItems?: ITag[]): Promise<ITag[]> => {
    const notASelectedItem = (suggestion: ITag) => !selectedItems?.find((s) => suggestion.key === s.key);
    const notAnExcludedClient = (suggestion: any) => !props.excludedClientIds?.find((s) => (suggestion as ClientSearchResult).id === s);

    const asClientTag =
      props.asClientTag ??
      ((client: ClientSearchResult) => ({
        key: `${client.clientName} (${client.id})`,
        name: `${client.clientName} (${client.id})`,
        ...client,
      }));

    const api = props.searchApi ?? searchClient;

    if (!_.isEmpty(searchText)) {
      const results = await api(
        {
          searchText,
          pageSize: 20,
          ...(props.criteria ?? {}),
        },
        { callerHandleErrors: true, disableErrorMessage: true, showLoadingSpinner: false, showSuccessMessage: false },
      );

      return results?.results!.map(asClientTag).filter(notASelectedItem).filter(notAnExcludedClient).slice(0, 10);
    }

    return [];
  };

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField {...props} />
  ) : (
    <TagPicker
      alwaysValidate={props.alwaysValidate}
      label={props.label ?? ''}
      name={props.name}
      hint={props.hint}
      // @ts-ignore
      onResolveSuggestions={onResolveSuggestions}
      inputProps={{ placeholder: 'You can search by Client name, Client number, NZBN, or Billing name.' }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for clients',
        suggestionsHeaderText: 'Suggested clients',
        noResultsFoundText: 'No clients found',
      }}
      defaultSelectedItems={
        isEmpty(props.initialClientId) || isEmpty(props.initialClientName)
          ? []
          : [
              {
                key: props.initialClientId!,
                name: props.initialClientName!,
              },
            ]
      }
      allowFreeForm={false}
      itemLimit={1}
      disabled={mode === 'DISABLED' || props.disabled}
      required={props.isRequired}
      onChange={(items: any) => (props.onChange ? props.onChange(items) : undefined)}
    />
  );
};

export default ClientTagPicker;
