import { mergeStyleSets } from '@fluentui/merge-styles';

import { RRFTheme } from '../theme/RRFTheme';

export interface IComponentClassNames {
  expandable: string;
}

export const getClassNames = (theme: RRFTheme): IComponentClassNames => {
  return mergeStyleSets({
    expandable: {
      background: theme.semanticColors.inlinePanelBackgroundColor,
      padding: '29.1px 27.5px 6.4px 22px',
    },
  });
};
