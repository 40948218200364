const getSymbolFromCurrency = require('currency-symbol-map');

export const formatMonetaryValue = (value?: number, currencyCode?: string, defaultValue?: number): string | undefined => {
  const symbol = currencyCode ? getSymbolFromCurrency(currencyCode) : '$'; // default to dollars
  if (value === undefined || value === null) {
    return defaultValue !== undefined ? formatMonetaryValueWithCurrency(defaultValue, symbol) : undefined;
  }
  return formatMonetaryValueWithCurrency(value, symbol);
};

export const formatMonetaryValueNoCurrency = (value?: number): string | undefined => {
  return value !== undefined ? value.toLocaleString(undefined, { minimumFractionDigits: 2 }) : undefined;
};

export const unformatMonetaryValue = (value?: string): number => (value ? +(value + '').replace(/,/g, '') : 0);

export const isMonetary = (value: string | number) =>
  value ? toString(value).match(/^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/) !== null : false;

export const toString = (value: string | number) => {
  return '' + value;
};

const formatMonetaryValueWithCurrency = (value: number, symbol: string): string => {
  let formattedCurrencyValue = symbol + Math.abs(value).toLocaleString(undefined, { minimumFractionDigits: 2 });
  return value < 0 ? '(' + formattedCurrencyValue + ')' : formattedCurrencyValue;
};

export const formatAmount = (amount?: number | string) =>
  typeof amount === 'number' ? formatMonetaryValueNoCurrency(amount) : typeof amount === 'string' ? amount : '';
