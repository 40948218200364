import { mergeStyles } from '@fluentui/react';
import * as React from 'react';

import { useAuth } from 'common/auth/AuthHook';

import { geoRefDescFromType } from '../../licence_management/reference_data/location/locationSupportData';
import './search_map.css';

export enum PopupType {
  // eslint-disable-next-line no-unused-vars
  POPUP_TYPE_DETAIL = 'POPUP_TYPE_DETAIL',
  POPUP_TYPE_SEARCH = 'POPUP_TYPE_SEARCH',
}

interface Props {
  count: number;
  index: number;
  border: boolean;
  showLink: boolean;
  showCount: boolean;
  data?: any;
  dataType: PopupType;
  detailFuncName?: string;
}

const borderOn = mergeStyles({
  border: '1px solid black',
  borderRadius: '4px',
  padding: '5px',
  marginTop: '5px',
});

const borderOff = mergeStyles({
  border: 'none',
});

// TSX ------------------------------------------------------------------------
const MapLicenceDetailCard: React.FC<Props> = (props) => {
  const cardClass = props.border ? borderOn : borderOff;
  const { loggedIn } = useAuth();
  return (
    <div className={cardClass}>
      <div className="rmap-lic-popup-grid">
        <div>Licence Id</div>
        <div>
          {props.showLink ? (
            <a href={`/ui/${loggedIn ? 'app/' : ''}licence/${props.data.licenceTypeCode === 'SPL' ? 'spectrum' : 'radio'}/view/${props.data.LicId}`}>
              {props.data.LicId} &nbsp;{props.data.configType}
            </a>
          ) : (
            <span>
              {props.data.LicId} &nbsp;{props.data.configType}
            </span>
          )}
        </div>
        <div>Licence Number</div>
        <div>{props.data.licNumber}</div>
        <div>Type</div>
        <div>{props.data.licType}</div>
        <div>Channel</div>
        <div>{props.data.channel}</div>
        <div>Ref. freq. (MHz)</div>
        <div>{props.data.refFrequency?.toFixed(6)}</div>
        <div>Location</div>
        <div>{props.data.location}</div>
        <div>{geoRefDescFromType(props.data.georefType)}</div>
        <div>{props.data.georefGridRef}</div>
        <div>Licensee</div>
        <div>{props.data.licensee}</div>
        {props.dataType === PopupType.POPUP_TYPE_DETAIL && (
          <>
            <div>Polarisation</div>
            <div>{props.data.polarisation}</div>
            <div>Antenna Type</div>
            <div>{props.data.antennaType}</div>
            <div>Antenna Height</div>
            <div>{props.data.antennaHeight}</div>
          </>
        )}
      </div>
      {props.showCount && (
        <div style={{ color: 'red' }}>
          <b>
            {props.index + 1} of {props.count}
          </b>
        </div>
      )}
      {props.showLink && props.dataType !== PopupType.POPUP_TYPE_DETAIL && props.detailFuncName && (
        <span dangerouslySetInnerHTML={{ __html: `<a href="javascript:${props.detailFuncName}(${props.data.LicId});">click for details</a>` }} />
      )}
    </div>
  );
};

export default MapLicenceDetailCard;
