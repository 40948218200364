import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { ComplianceCheckResultEnum } from 'api-client';

import { Dropdown, Form, Grid2 } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import { TextField } from 'common/controls/inputs/TextField';
import { COMPLIANCE_CHECK_RESULT_OPTIONS } from 'common/reference/referenceData';
import { useAuditApi } from 'investigation/audit/hooks';

import { useAppRouter } from '../../../Routes';
import usePage from '../../../common/layout/PageHook';

interface AuditComplianceFormProps {
  id: number;
  complianceDate?: string;
  complianceNotes?: string;
  complianceCheckResult?: ComplianceCheckResultEnum;
}

const ActionCardAuditCompliance = (partialAudit: AuditComplianceFormProps) => {
  const { auditComplianceApi } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  const handleSubmit = (values: AuditComplianceFormProps) => {
    auditComplianceApi({
      id: values.id,
      complianceDate: values.complianceDate!,
      complianceNotes: values.complianceNotes || '',
      complianceCheckResult: values.complianceCheckResult!,
    }).then(() => {
      navigateToDashboard();
      setPageInstruction('Compliance check details successfully saved.', MessageBarType.success);
    });
  };

  return (
    <Form<AuditComplianceFormProps>
      formButtonsOptions={{
        hideBackButton: true,
        hideCancelButton: true,
        hideSubmitButton: true,
      }}
      handleSubmit={handleSubmit}
      hideFormButtonsTop
      id="auditComplianceActionCard"
      isInDialog
      initialValues={partialAudit}
      mode="CREATE"
      showFormButtonsBottom
      validationSchema={[{ name: 'complianceDate' }, { name: 'complianceNotes' }, { name: 'complianceCheckResult' }]}
    >
      <AuditComplianceContent />
    </Form>
  );
};

const AuditComplianceContent = () => {
  const { resetForm, submitForm } = useFormikContext<AuditComplianceFormProps>();

  return (
    <MessageBar
      data-automation-id={`audit-compliance-message-bar`}
      messageBarType={MessageBarType.warning}
      styles={{ root: { '.ms-MessageBar-innerText': { width: '50%' } } }}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="audit-compliance-action" onClick={() => submitForm()}>
              Follow up compliance
            </PrimaryButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <DefaultButton data-automation-id="audit-compliance-clear" onClick={() => resetForm()}>
              Clear
            </DefaultButton>
          </div>
        </>
      }
    >
      <Text>Complete after compliance follow-up check.</Text>
      <Grid2>
        <Grid2.Col lg={2}>
          <DatePicker label="Date" name="complianceDate" dateOnlyAsString required maxDate={new Date()} />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <Dropdown label="Compliance check result" name="complianceCheckResult" required options={COMPLIANCE_CHECK_RESULT_OPTIONS} />
        </Grid2.Col>
        <Grid2.Col colStart={1}>
          <TextField label="Notes" maxLength={400} multiline name="complianceNotes" required showMaxHint />
        </Grid2.Col>
      </Grid2>
    </MessageBar>
  );
};

export default ActionCardAuditCompliance;
