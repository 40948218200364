import * as azmaps from 'azure-maps-control';

/** Geolocation Control options interface */
export interface GeolocationControlOptions {
  startActive?: boolean; // show userposition at startup, default false
  markerColor?: string; // Default: DodgerBlue
  markerPulse?: boolean; // default true
  /** zoom in to this level if currently greater and updateMapCamera is true */
  maxZoom?: number;
  positionOptions?: PositionOptions;
  showUserLocation?: boolean; // Shows location with a marker. Default: true
  updateMapCamera?: boolean; // pan/zoom map to the location, default true
  skipFirstZoom?: boolean; // skip first pan/zoom, default false, set to true if there is search results at start
  style?: azmaps.ControlStyle | string; // default light
  /** callback when control is initialised, so can later call methods on control */
  initCallback?: (iglc: IGeolocationControl, gpsSupported: boolean) => void;
  /** callback when clicked to notify position and optionally the marker set, radius in meters */
  positionCallback?: (
    position: azmaps.data.Feature<azmaps.data.Point, GeolocationProperties>,
    marker: azmaps.HtmlMarker | undefined,
    radius: number | undefined,
  ) => void;
  /** callback when marker is removed */
  markerRemovedCallback?: () => void;
  dblClickReposition?: boolean; // default false
  dragReposition?: boolean; // default false
  dragRadius?: boolean; // default false
  radiusColour?: string; // default orange
  radiusOpacity?: number; // default .33
  manualOnly?: boolean; // default false, no GPS, only manual positionaing/dragging
}

/** geolocation data */
export interface GeolocationProperties {
  accuracy: number; // accuracy level of the latitude and longitude coordinates
  altitude: number | null; // meters above the [WGS84] ellipsoid
  altitudeAccuracy: number | null; // in meters
  heading: number | null; // in degrees clockwise from north
  latitude: number; // latitude position
  longitude: number; // longitude position
  speed: number | null; // m/s
  timestamp: Date; // timestamp
  _timestamp: number; // timestamp in milliseconds
}

export interface IGeolocationControl {
  setPosition(pos: azmaps.data.Position, forceShow?: boolean, radius?: number): void;
  hidePin(): void;
  getLastKnownPosition(): azmaps.data.Feature<azmaps.data.Point, GeolocationProperties> | null; // null if inactive
  getLastKnownRadius(): number | null; // meters, null if inactive
  skipNextZoom(skip: boolean): void;
}

interface Circle {
  datasource: azmaps.source.DataSource;
  layer: azmaps.layer.PolygonLayer;
  lineLayer: azmaps.layer.LineLayer;
  symbolLayer: azmaps.layer.SymbolLayer;
}

//----------------------------------------------------------------------------
/** A control that uses the browser's geolocation API to locate the user on the map. */
export class GeolocationControl implements azmaps.Control, IGeolocationControl {
  // Private Properties
  private _container: HTMLElement | undefined;
  private _button: HTMLButtonElement | undefined;
  private _options: GeolocationControlOptions = {
    startActive: false,
    style: 'light',
    positionOptions: {
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: 6000,
    },
    showUserLocation: true,
    updateMapCamera: true,
    skipFirstZoom: false,
    markerColor: 'DodgerBlue',
    markerPulse: true,
    maxZoom: 15,
    dblClickReposition: false,
    dragReposition: false,
    dragRadius: false,
    radiusColour: 'Orange',
    radiusOpacity: 0.33,
    manualOnly: false,
  };
  private _darkColor = '#011c2c';
  private _hclStyle: azmaps.ControlStyle | undefined = undefined;
  private _map: azmaps.Map | undefined;

  /** Resource array values: 0 - enableTracking, 1 - disableTracking, 2 - myLocation, 3 - title */
  private static _buttonLabel: string = 'My Location';
  private _gpsMarker: azmaps.HtmlMarker | undefined;

  private _isActive = false;
  private _lastKnownPosition: azmaps.data.Feature<azmaps.data.Point, GeolocationProperties> | undefined;
  private _radius: number | undefined = undefined;
  private _radiusDragCurrent: azmaps.data.Position | undefined;
  private _radiusDragActive: boolean = false;
  private _circle: Circle | undefined = undefined;

  private static _gpsPulseDotIcon = '<div class="azmaps-gpsPulseIcon" style="background-color:{color}"></div>';
  private static _gpsDotIcon = '<div class="azmaps-gpsDotIcon" style="background-color:{color}"></div>';

  private static _iconTemplate =
    "data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 561 561' xml:space='preserve'><g fill='{color}'><path d='M280.5,178.5c-56.1,0-102,45.9-102,102c0,56.1,45.9,102,102,102c56.1,0,102-45.9,102-102C382.5,224.4,336.6,178.5,280.5,178.5z M507.45,255C494.7,147.9,410.55,63.75,306,53.55V0h-51v53.55C147.9,63.75,63.75,147.9,53.55,255H0v51h53.55C66.3,413.1,150.45,497.25,255,507.45V561h51v-53.55C413.1,494.7,497.25,410.55,507.45,306H561v-51H507.45z M280.5,459C181.05,459,102,379.95,102,280.5S181.05,102,280.5,102S459,181.05,459,280.5S379.95,459,280.5,459z'/></g></svg>";

  private static _gpsBtnCss =
    '.azmaps-gpsBtn{margin:0;padding:0;border:none;border-collapse:collapse;width:32px;height:32px;text-align:center;cursor:pointer;line-height:32px;background-repeat:no-repeat;background-size:20px;background-position:center center;z-index:200;box-shadow:0px 0px 4px rgba(0,0,0,0.16);}' +
    '.azmaps-gpsDisabled{background-image:url("{grayIcon}");}' +
    '.azmaps-gpsDisabled:hover{background-image:url("{blueIcon}");filter:brightness(90%);}' +
    '.azmaps-gpsEnabled{background-image:url("{blueIcon}");}' +
    '.azmaps-gpsEnabled:hover{background-image:url("{blueIcon}");filter:brightness(90%);}' +
    '.azmaps-gpsPulseIcon{display:block;width:15px;height:15px;border-radius:50%;background:orange;border:2px solid white;cursor:pointer;box-shadow:0 0 0 rgba(0, 204, 255, 0.6);animation:pulse 2s infinite;}@keyframes pulse {0% {box-shadow:0 0 0 0 rgba(0, 204, 255, 0.6);}70% {box-shadow:0 0 0 20px rgba(0, 204, 255, 0);}100% {box-shadow:0 0 0 0 rgba(0, 204, 255, 0);}}' +
    '.azmaps-gpsDotIcon{display:block;width:15px;height:15px;border-radius:50%;background:orange;border:2px solid white;cursor:pointer;box-shadow:0 0 0 rgba(0, 204, 255, 0.6)}';

  // Constructor --------------------------------------------------------------
  /**
   * A control that uses the browser's geolocation API to locate the user on the map.
   * @param options Options for defining how the control is rendered and functions.
   */
  constructor(options?: GeolocationControlOptions | any) {
    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  // public -------------------------------------------------------------------

  /**
   * Allow client to ensure geoloc does not do a zoom, as geo is async and this can cause a race with the search results.
   * Note that the next geoloc update or manual toggle of button will reset so we still get zoom when desired.
   */
  public skipNextZoom(skip: boolean): void {
    this._options.skipFirstZoom = skip;
  }

  /* external interface to set position */
  public setPosition(pos: azmaps.data.Position, forceShow?: boolean, radius?: number): void {
    const self = this;
    if (forceShow && !self._isActive) {
      self._isActive = true;
      self._updateButtonState();
    }
    self._radius = radius && radius > 0 ? radius : undefined;
    self._onGpsSuccess({ coords: { longitude: pos[0], latitude: pos[1] } }, true, true); // update position, but do not send notification or zoom
  }

  /** disable control and hide pin */
  public hidePin(): void {
    const self = this;
    if (self._isActive) {
      self._isActive = false;
      self._updateButtonState(); // disable button
      if (self._gpsMarker) {
        // hide pin without notification
        self._gpsMarker.setOptions({ visible: false });
      }
      self._updateCircle(false);
    }
  }

  /** Disposes the control. */
  public dispose(): void {
    const self = this;
    if (self._map) {
      self._map.controls.remove(self);
    }

    Object.keys(self).forEach((k) => {
      // @ts-ignore
      self[k] = null;
    });
  }

  /** Get sthe last known position from the geolocation control. */
  public getLastKnownPosition(): azmaps.data.Feature<azmaps.data.Point, GeolocationProperties> | null {
    return this._isActive && this._lastKnownPosition ? JSON.parse(JSON.stringify(this._lastKnownPosition)) : null;
  }

  public getLastKnownRadius(): number | null {
    return this._isActive && this._radius ? this._radius : null;
  }

  /**
   * Action to perform when the control is added to the map.
   * @param map The map the control was added to.
   * @param options The control options used when adding the control to the map.
   * @returns The HTML Element that represents the control.
   */
  public onAdd(map: azmaps.Map, options?: azmaps.ControlOptions): HTMLElement {
    const self = this;
    self._map = map;

    const mcl = map.getMapContainer().classList;
    if (mcl.contains('high-contrast-dark')) {
      self._hclStyle = 'dark' as azmaps.ControlStyle;
    } else if (mcl.contains('high-contrast-light')) {
      self._hclStyle = 'light' as azmaps.ControlStyle;
    }

    // Create different color icons and merge into CSS.
    const gc = GeolocationControl;
    const grayIcon = gc._iconTemplate.replace('{color}', 'Gray');
    const blueIcon = gc._iconTemplate.replace('{color}', 'DeepSkyBlue');
    const css = gc._gpsBtnCss.replace(/{grayIcon}/g, grayIcon).replace(/{blueIcon}/g, blueIcon);

    // Add the CSS style for the control to the DOM.
    const style = document.createElement('style');
    style.innerHTML = css;
    document.body.appendChild(style);

    // Create the button.
    const c = document.createElement('div');
    c.classList.add('azure-maps-control-container');
    c.setAttribute('aria-label', GeolocationControl._buttonLabel);
    c.style.flexDirection = 'column';

    // Hide the button by default.
    c.style.display = 'none';
    self._container = c;

    const b = document.createElement('button');
    b.classList.add('azmaps-gpsBtn');
    b.classList.add('azmaps-gpsDisabled');
    b.setAttribute('title', GeolocationControl._buttonLabel);
    b.setAttribute('alt', GeolocationControl._buttonLabel);
    b.setAttribute('type', 'button');
    b.addEventListener('click', self._toggleBtn);
    self._button = b;

    self._updateState();
    self.setOptions(self._options);
    c.appendChild(b);

    // Check that geolocation is supported.
    gc.isSupported().then((supported) => {
      if (self._options.initCallback) {
        // init callback notification
        self._options.initCallback(self, supported);
      }
      let enableAnyway = false;
      if (!supported && (self._options.dblClickReposition || self._options.dragReposition || self._options.dragRadius)) {
        // if we can drag/click position then enable (in non-GPS mode) even if GPS not supported
        self._options.manualOnly = true;
        enableAnyway = true;
      }

      if (supported || enableAnyway) {
        // Show the button when we know geolocation is supported.
        if (self && self._container) {
          self._container.style.display = '';
          self.setOptions(self._options);
          if (supported && self._options.startActive) {
            self.toggle(true); // if initially active then toggle on
          }
        }
      } else {
        // Device doesn't support getting position.
      }
    });

    return c;
  }

  /**
   * Action to perform when control is removed from the map.
   */
  public onRemove(): void {
    const self = this;
    if (self._container) {
      self._container.remove();
    }

    if (self._gpsMarker) {
      self._map?.markers.remove(self._gpsMarker);
    }

    // remove circle datasource  and layers
    if (self._map && self._circle) {
      if (self._circle.layer) {
        self._map.layers.remove(self._circle.layer);
      }
      if (self._circle.lineLayer) {
        self._map.layers.remove(self._circle.lineLayer);
      }
      if (self._circle.symbolLayer) {
        self._map.layers.remove(self._circle.symbolLayer);
      }
      if (self._circle.datasource) {
        self._map.sources.remove(self._circle.datasource);
      }
      self._circle = undefined;
    }

    self._map = undefined;
  }

  /** Gets the options of the geolocation control. */
  public getOptions(): GeolocationControlOptions {
    return Object.assign({}, this._options);
  }

  /**
   * Sets the options of the geolocation control.
   * @param options The options.
   */
  public setOptions(options: GeolocationControlOptions): void {
    const self = this;
    const o = self._options;

    if (options) {
      let color = 'white';

      if (self._hclStyle) {
        if (self._hclStyle === 'dark') {
          color = self._darkColor;
        }
      } else {
        o.style = options.style;
        switch (options.style) {
          case 'dark':
            color = self._darkColor;
            break;
          //case 'light': break;
        }
      }

      if (self._button) {
        self._button.style.backgroundColor = color;
      }

      if (options.markerColor) {
        o.markerColor = options.markerColor;
        if (self._gpsMarker) {
          self._gpsMarker.setOptions({
            color: options.markerColor,
          });
        }
      }

      if (typeof options.maxZoom === 'number') {
        o.maxZoom = Math.min(Math.max(options.maxZoom, 0), 24);
      }

      if (typeof options.showUserLocation === 'boolean') {
        o.showUserLocation = options.showUserLocation;

        if (self._gpsMarker) {
          self._gpsMarker.setOptions({
            visible: self._isActive && options.showUserLocation,
          });
        } else if (self._lastKnownPosition) {
          self._onGpsSuccess();
        }
      }

      if (options.positionOptions) {
        let opt: PositionOptions = {};

        if (options.positionOptions.enableHighAccuracy) {
          opt.enableHighAccuracy = options.positionOptions.enableHighAccuracy;
        }

        if (typeof options.positionOptions.maximumAge === 'number') {
          opt.maximumAge = options.positionOptions.maximumAge;
        }

        if (typeof options.positionOptions.timeout === 'number') {
          opt.timeout = options.positionOptions.timeout;
        }

        if (Object.keys(opt).length > 0) {
          o.positionOptions = Object.assign(o.positionOptions!, opt);
          self._updateState();
        }
      }
    }
  }

  /**
   * Toggles the state of the Geolocation control button. If a boolean state is not passed in, will toggle to opposite of current state.
   * @param isActive The state to toggle to. If not specified, will toggle to opposite of current state.
   */
  public toggle(isActive?: boolean): void {
    const self = this;
    self._isActive = typeof isActive === 'boolean' ? isActive : !self._isActive;
    self._updateState();
  }

  /** Checks to see if the geolocation API is supported in the browser. */
  public static async isSupported(): Promise<boolean> {
    if (window.navigator.permissions) {
      // navigator.permissions has incomplete browser support
      // http://caniuse.com/#feat=permissions-api
      // Test for the case where a browser disables Geolocation because of an insecure origin.
      const p = await window.navigator.permissions.query({ name: 'geolocation' });
      return p.state !== 'denied';
    }

    return !!window.navigator.geolocation;
  }

  // private ------------------------------------------------------------------
  /** Toggles the state of the control. */
  private _toggleBtn = () => {
    this._options.skipFirstZoom = false; // manual/desired, so ensure any zoom override is disabled
    this.toggle();
  };

  /** Updates the state of the button and marker. */
  private _updateState(): void {
    const self = this;

    if (self._gpsMarker) {
      let showMarker = self._isActive && self._options.showUserLocation;
      if (self._gpsMarker.getOptions().visible && !showMarker && self._options.markerRemovedCallback) {
        self._options.markerRemovedCallback(); // was visible and removing, send notification
        self._lastKnownPosition = undefined;
        self._radius = undefined;
      }
      self._gpsMarker.setOptions({ visible: showMarker });
      self._updateCircle(showMarker);
    }

    if (self._isActive) {
      if (self._options.manualOnly) {
        // manual, not GPS, set to map center
        try {
          // @ts-ignore
          let lng = self._map.getCamera().center[0];
          // @ts-ignore
          let lat = self._map.getCamera().center[1];
          let fakeGps = { coords: { longitude: lng, latitude: lat } };
          self._onGpsSuccess(fakeGps, false, true);
        } catch (ex) {
          console.log('Error geting camera coords', ex);
        }
      } else {
        window.navigator.geolocation.getCurrentPosition(self._onGpsSuccess, self._onGpsError, self._options.positionOptions);
      }
    }

    // update styling of button bases on current state
    self._updateButtonState();
  }

  /** */
  private _updateButtonState() {
    const self = this;
    const b = self._button;
    if (b) {
      let removeClass = 'azmaps-gpsEnabled';
      let addClass = 'azmaps-gpsDisabled';

      if (self._isActive) {
        removeClass = 'azmaps-gpsDisabled';
        addClass = 'azmaps-gpsEnabled';
      }
      b.classList.remove(removeClass);
      b.classList.add(addClass);
    }
  }

  /* handle manual reposition through doubleclick or drag */
  private _manualReposition(pos: azmaps.data.Position): void {
    const self = this;
    const options = self._options;
    const gpsMarker = self._gpsMarker;

    self._setLastKnownPosition(pos);

    if (gpsMarker && options.showUserLocation) {
      const icon = self._getMarkerIcon();
      gpsMarker.setOptions({
        position: pos,
        pixelOffset: [0, 10],
        htmlContent: icon,
        visible: self._isActive && options.showUserLocation,
      });

      self._updateCircle(self._isActive && options.showUserLocation);
    }

    self._notifyPosition(); // uses lastKnownPosition
  }

  /**  */
  private _setLastKnownPosition(pos: azmaps.data.Position): azmaps.data.Feature<azmaps.data.Point, GeolocationProperties> {
    const self = this;
    let geopos: GeolocationProperties = { longitude: pos[0], latitude: pos[1] } as GeolocationProperties;
    let lastKnownPosition = new azmaps.data.Feature(new azmaps.data.Point(pos), geopos);
    self._lastKnownPosition = lastKnownPosition;
    return lastKnownPosition;
  }

  /** do position notification callback if available */
  private _notifyPosition(): void {
    const self = this;
    const options = self._options;
    if (options.positionCallback && self._lastKnownPosition) {
      let lnp = this.getLastKnownPosition();
      if (lnp) {
        options.positionCallback(lnp, options.showUserLocation && self._gpsMarker ? self._gpsMarker : undefined, self._radius);
      }
    }
  }

  /**
   * Callback for when we successfully get the location.
   * @param position The GPS position information.
   */
  private _onGpsSuccess = (position?: any, skipNotification?: boolean, skipZoom?: boolean) => {
    const self = this;
    const options = self._options;
    const map = self._map;
    let lastKnownPosition = self._lastKnownPosition;
    let gpsMarker = self._gpsMarker;
    let pos: azmaps.data.Position | null = null;

    if (position) {
      pos = [position.coords.longitude, position.coords.latitude];
      lastKnownPosition = self._setLastKnownPosition(pos);
    }

    if (lastKnownPosition) {
      if (!pos) {
        pos = lastKnownPosition.geometry.coordinates;
      }

      if (self._isActive) {
        const icon = self._getMarkerIcon();

        if (options.showUserLocation) {
          if (!gpsMarker) {
            self._gpsMarker = new azmaps.HtmlMarker({
              position: pos,
              htmlContent: icon,
              color: options.markerColor,
              draggable: options.dragReposition || options.dragRadius,
            });

            map?.markers.add(self._gpsMarker);

            if (options.dragReposition || options.dragRadius) {
              map?.events.add('dragstart', self._gpsMarker, (e) => {
                if (e.target && e.target.getOptions().position && options.dragRadius) {
                  this._radiusDragCurrent = e.target.getOptions().position;
                  this._radiusDragActive = true;
                }
              });

              map?.events.add('drag', self._gpsMarker, (e) => {
                if (options.dragRadius && this._lastKnownPosition && e.target && e.target.getOptions().position) {
                  this._radius = azmaps.math.getDistanceTo(
                    e.target.getOptions().position!,
                    this._lastKnownPosition.geometry.coordinates,
                    azmaps.math.DistanceUnits.meters,
                  );
                  this._radiusDragCurrent = e.target.getOptions().position;
                  this._updateCircle(true, true);
                }
              });

              map?.events.add('dragend', self._gpsMarker, (e) => {
                if (e.target && e.target.getOptions().position) {
                  if (options.dragRadius && this._lastKnownPosition) {
                    this._radius = azmaps.math.getDistanceTo(
                      e.target.getOptions().position!,
                      this._lastKnownPosition.geometry.coordinates,
                      azmaps.math.DistanceUnits.meters,
                    );
                    this._radiusDragActive = false; // must be before updateCircle
                    this._updateCircle(true);
                    this._manualReposition(this._lastKnownPosition.geometry.coordinates);
                    this._radiusDragCurrent = undefined;
                  } else {
                    this._manualReposition(e.target.getOptions().position!);
                  }
                }
              });
            }

            if (options.dblClickReposition) {
              map?.events.add('dblclick', (e) => {
                const self = this;
                if (e.position && self._isActive) {
                  this._manualReposition(e.position);
                }
                e.preventDefault();
              });
            }
          } else {
            gpsMarker.setOptions({
              position: pos,
              htmlContent: icon,
              visible: self._isActive && options.showUserLocation,
            });
          }
          self._updateCircle(self._isActive && options.showUserLocation);
        } else {
          gpsMarker?.setOptions({ visible: false });
          self._updateCircle(false);
          // no marker, so revert back to inactive so we do not toggle
          self._isActive = false;
          // revert button style
          const b = self._button;
          if (b) {
            b.classList.remove('azmaps-gpsEnabled');
            b.classList.add('azmaps-gpsDisabled');
          }
        }

        if (self._options.updateMapCamera && !skipZoom) {
          const opt: any = { center: pos };

          // Only adjust zoom if the user is zoomed out too much.
          const currentZoom: number = map?.getCamera().zoom || 1;
          if (currentZoom < 15) {
            opt.zoom = 15;
          }

          if (self._options.skipFirstZoom) {
            self._options.skipFirstZoom = false;
          } else {
            map?.setCamera(opt);
          }
        }

        // notify pos is set
        if (!skipNotification) {
          self._notifyPosition();
        }
      }
    }
  };

  /**
   * Callback for when an error occurs when getting the users location.
   * @param error The error that occured.
   */
  private _onGpsError = (error: any) => {
    const self = this;
    self._isActive = false;
    self._updateState();
  };

  /** Generates the mark icon HTML */
  private _getMarkerIcon(): string {
    const self = this;
    return self._options.markerPulse ? GeolocationControl._gpsPulseDotIcon : GeolocationControl._gpsDotIcon;
  }

  /** create circle and add DS and layer to map */
  private static _buildCircle(map: azmaps.Map, colour: string, opacity: number): Circle {
    let ds = new azmaps.source.DataSource();
    map.sources.add(ds);
    let ly = new azmaps.layer.PolygonLayer(ds, undefined, {
      visible: false,
      fillColor: colour,
      fillOpacity: opacity,
      filter: ['==', ['geometry-type'], 'Polygon'],
    });
    map.layers.add(ly);

    let ll = new azmaps.layer.LineLayer(ds, undefined, {
      visible: false,
      strokeColor: 'blue',
      strokeOpacity: 1,
      strokeWidth: 2,
      filter: ['==', ['geometry-type'], 'LineString'],
    });
    map.layers.add(ll);

    let sl = new azmaps.layer.SymbolLayer(ds, undefined, {
      visible: false,
      iconOptions: { image: '' },
      textOptions: { allowOverlap: true, ignorePlacement: true, textField: ['get', 'length'] },
    });
    map.layers.add(sl);

    let circle: Circle = {
      datasource: ds,
      layer: ly,
      lineLayer: ll,
      symbolLayer: sl,
    };
    return circle;
  }

  /** draw or hide the radius circle */
  private _updateCircle(show: boolean | undefined, showLine?: boolean | undefined) {
    const self = this;
    // circle
    if (show && self._map && self._radius && self._radius > 0 && self._lastKnownPosition) {
      if (!self._circle) {
        self._circle = GeolocationControl._buildCircle(self._map, self._options.radiusColour!, self._options.radiusOpacity!);
      }
      self._circle.datasource.clear();
      self._circle.datasource.add(GeolocationControl._buildCircleFeature(self._lastKnownPosition, self._radius));
      if (self._radiusDragActive && self._radiusDragCurrent) {
        self._circle.datasource.add(
          GeolocationControl._buildLineFeature(self._lastKnownPosition.geometry.coordinates, self._radiusDragCurrent, self._radius),
        );
      }
      self._circle.layer.setOptions({ visible: true });
    } else {
      // hide circle
      if (self._circle && self._circle.layer) {
        self._circle.layer.setOptions({ visible: false });
      }
    }

    // show/hide line / symbol text
    if (self._circle && self._circle.lineLayer) {
      self._circle.lineLayer.setOptions({ visible: show && showLine && self._radiusDragActive });
    }
    if (self._circle && self._circle.symbolLayer) {
      self._circle.symbolLayer.setOptions({ visible: show && showLine && self._radiusDragActive });
    }
  }

  /** construct a new circle feature */
  private static _buildCircleFeature = (
    pos: azmaps.data.Feature<azmaps.data.Point, GeolocationProperties>,
    radius: number,
  ): azmaps.data.Feature<azmaps.data.Polygon, any> => {
    // note that a feature/point of subtype circle does not render correctly if crossing the antimeridian
    // hence te hack below to manyally generate a circle polygon and avoid longitude sign transitions
    // const pt = new azmaps.data.Point([pos.geometry.coordinates[0], pos.geometry.coordinates[1]]);
    // const cf = new azmaps.data.Feature(pt, { subType: "Circle", radius: radius });

    var posArray = [];
    for (let a = 0; a <= 360; a = a + 10) {
      let pp = azmaps.math.getDestination(pos.geometry.coordinates, a, radius, 'meters');
      if (pp[0] < 0) {
        pp[0] = pp[0] + 360;
      }
      posArray.push(pp);
    }

    const cf = new azmaps.data.Feature(new azmaps.data.Polygon(posArray));

    return cf;
  };

  private static _buildLineFeature = (
    pos1: azmaps.data.Position,
    pos2: azmaps.data.Position,
    radius: number | undefined,
  ): azmaps.data.Feature<azmaps.data.LineString, any> => {
    return new azmaps.data.Feature(new azmaps.data.LineString([pos1, pos2]), { length: radius ? (radius / 1000).toFixed(2) + ' km' : '' });
  };
}
