import * as yup from 'yup';
import { isBlank, notRequiredString, requiredString } from '../../../common/validation/yupUtils';

export const searchEquipmentValidationSchema = yup.object().shape({
  searchTextError: yup.string().when(['$searchText'], {
    is: (searchText) => isBlank(searchText),
    then: requiredString.required('Please specify your query'),
    otherwise: notRequiredString,
  }),
});
