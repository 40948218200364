import { ISearchBoxProps, Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Grid } from 'ui-library';

import ErrorLabel from '../common/controls/inputs/ErrorLabel';
import { TextField } from '../common/controls/inputs/TextField';
import TooltipHint from '../common/controls/items/TooltipHint';
import MoreOrLess from '../common/controls/surfaces/MoreOrLess';
import Form from '../common/form/Form';
import { FormButton } from '../common/form/FormButtons';
import { SectionHeading } from '../common/layout/SectionHeadings';
import SearchButtons from './SearchButtons';

export type SEARCH_FORM_MODE = 'EMBEDDED' | 'STAND_ALONE';

interface SearchBoxProps extends ISearchBoxProps {
  label?: string;
  hint?: string | JSX.Element | JSX.Element[];
  required?: boolean;
}

interface SearchFormProps {
  id: string;
  title: string;
  titleHint?: string;
  mode: SEARCH_FORM_MODE;
  onSearch?: (values: any) => void;
  onClear?: () => void;
  additionalButtons?: FormButton[];
  hideSearchBox?: boolean;
  searchBoxProps?: SearchBoxProps;
  collapsible?: boolean;
  disableErrorMessage?: boolean;
  hideSearchClearButtons?: boolean;
  hideFormButtonsTop?: boolean;
  initiallyMore?: boolean;
  readOnly?: boolean;
  children?: React.ReactNode;
}

const AutoSubmitQueryParams = () => {
  const { submitForm, isSubmitting } = useFormikContext();
  const location = useLocation();

  React.useEffect(() => {
    if (location.search && !isSubmitting) {
      submitForm();
    }
    // eslint-disable-next-line
  }, [submitForm, location.search]);

  return null;
};

const SearchForm: React.FC<SearchFormProps> = (props) => (
  <Form
    id={props.id}
    data-automation-id={props.id}
    hideSubmit
    mode="SEARCH"
    additionalButtons={() => props.additionalButtons}
    disableErrorMessage={props.disableErrorMessage}
    submittingSpinnerLabel="Searching..."
    hideBack={props.mode === 'EMBEDDED'}
    hideFormButtonsTop={props.hideFormButtonsTop}
  >
    {props.mode !== 'EMBEDDED' && props.title && <SectionHeading title={props.title} hint={props.titleHint} />}
    <ErrorLabel name="searchTextError" showIcon={true} alwaysValidate={true} />
    {!props.hideSearchBox && (
      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          <TextField
            label={props.searchBoxProps?.label ?? 'Search'}
            name="searchText"
            placeholder={props.searchBoxProps?.placeholder ?? 'Search'}
            maxLength={200}
            required={props.searchBoxProps?.required}
            disabled={props.readOnly}
          />
        </Grid.Col>
        {props.searchBoxProps?.hint && (
          <Grid.Col sm={12} lg={6}>
            <Label>&nbsp;</Label>
            <TooltipHint id="search-box-tooltip-hint" content={props.searchBoxProps!.hint} />
          </Grid.Col>
        )}
      </Grid.Row>
    )}
    {props.collapsible && <MoreOrLess more={props.children} moreText="More Options" lessText="Less Options" initiallyMore={props.initiallyMore} />}
    {!props.collapsible && props.children}
    {!props.hideSearchClearButtons && (
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <SearchButtons onSearch={props.onSearch} onClear={props.onClear} />
        </Grid.Col>
      </Grid.Row>
    )}
    <AutoSubmitQueryParams />
  </Form>
);

export default SearchForm;
