interface CountryCode {
  code: string;
  std: number;
  name: string;
}

// TODO country codes should be retrieved from API

// Note: These country codes have come from the Oasis 4
export const countryCodes: CountryCode[] = [
  { code: 'NZ', std: 64, name: 'New Zealand' },
  { code: 'AF', std: 93, name: 'Afghanistan' },
  { code: 'AL', std: 355, name: 'Albania' },
  { code: 'DZ', std: 213, name: 'Algeria' },
  { code: 'AS', std: 684, name: 'American Samoa' },
  { code: 'AD', std: 376, name: 'Andorra' },
  { code: 'AO', std: 244, name: 'Angola' },
  { code: 'AI', std: 1264, name: 'Anguilla' },
  { code: 'AQ', std: 10, name: 'Antarctica' },
  { code: 'AG', std: 1268, name: 'Antigua and Barbuda' },
  { code: 'AR', std: 54, name: 'Argentina' },
  { code: 'AM', std: 374, name: 'Armenia' },
  { code: 'AW', std: 297, name: 'Aruba' },
  { code: 'AU', std: 61, name: 'Australia' },
  { code: 'AT', std: 43, name: 'Austria' },
  { code: 'AZ', std: 994, name: 'Azerbaijan' },
  { code: 'ZO', std: 351, name: 'Azores' },
  { code: 'BS', std: 1242, name: 'Bahamas' },
  { code: 'BH', std: 973, name: 'Bahrain' },
  { code: 'BD', std: 880, name: 'Bangladesh' },
  { code: 'BB', std: 1246, name: 'Barbados' },
  { code: 'BY', std: 375, name: 'Belarus' },
  { code: 'BE', std: 32, name: 'Belgium' },
  { code: 'BZ', std: 501, name: 'Belize' },
  { code: 'BJ', std: 229, name: 'Benin' },
  { code: 'BM', std: 1441, name: 'Bermuda' },
  { code: 'BT', std: 975, name: 'Bhutan' },
  { code: 'BO', std: 591, name: 'Bolivia' },
  { code: 'BA', std: 387, name: 'Bosnia' },
  { code: 'BW', std: 267, name: 'Botswana' },
  { code: 'BV', std: 74, name: 'Bouvet Island' },
  { code: 'BR', std: 55, name: 'Brazil' },
  { code: 'IO', std: 86, name: 'British Indian Ocean Territory' },
  { code: 'VG', std: 1284, name: 'British Virgin Is.' },
  { code: 'BN', std: 673, name: 'Brunei' },
  { code: 'BG', std: 359, name: 'Bulgaria' },
  { code: 'BF', std: 226, name: 'Burkino Faso' },
  { code: 'BU', std: 95, name: 'Burma' },
  { code: 'BI', std: 257, name: 'Burundi' },
  { code: 'KH', std: 855, name: 'Cambodia' },
  { code: 'CM', std: 237, name: 'Cameroon' },
  { code: 'CA', std: 1, name: 'Canada' },
  { code: 'CV', std: 238, name: 'Cape Verde' },
  { code: 'KY', std: 1345, name: 'Cayman Islands' },
  { code: 'CF', std: 236, name: 'Central African' },
  { code: 'TD', std: 235, name: 'Chad' },
  { code: 'CL', std: 56, name: 'Chile' },
  { code: 'CN', std: 86, name: 'China' },
  { code: 'CX', std: 61, name: 'Christmas Island' },
  { code: 'CC', std: 166, name: 'Cocos (Keeling) Islands' },
  { code: 'CO', std: 57, name: 'Colombia' },
  { code: 'KM', std: 269, name: 'Comoros' },
  { code: 'CG', std: 242, name: 'Congo' },
  { code: 'CD', std: 243, name: 'Congo, The Republic of' },
  { code: 'CK', std: 682, name: 'Cook Islands' },
  { code: 'CR', std: 506, name: 'Costa Rica' },
  { code: 'CI', std: 225, name: `Cote D'Ivoire` },
  { code: 'HR', std: 385, name: 'Croatia' },
  { code: 'CU', std: 53, name: 'Cuba' },
  { code: 'CY', std: 357, name: 'Cyprus' },
  { code: 'CZ', std: 420, name: 'Czech Republic' },
  { code: 'DK', std: 45, name: 'Denmark' },
  { code: 'DJ', std: 253, name: 'Djibouti' },
  { code: 'DM', std: 1767, name: 'Dominica' },
  { code: 'DO', std: 1809, name: 'Dominican Republic' },
  { code: 'TL', std: 626, name: 'East Timor' },
  { code: 'EC', std: 593, name: 'Ecuador' },
  { code: 'EG', std: 20, name: 'Egypt' },
  { code: 'SV', std: 503, name: 'El Salvador' },
  { code: 'GQ', std: 240, name: 'Equatorial Guinea' },
  { code: 'ER', std: 291, name: 'Eritrea' },
  { code: 'EE', std: 372, name: 'Estonia' },
  { code: 'ET', std: 251, name: 'Ethiopia' },
  { code: 'FO', std: 298, name: 'Faeroe Islands' },
  { code: 'FK', std: 500, name: 'Falkland Islands' },
  { code: 'FJ', std: 679, name: 'Fiji' },
  { code: 'FI', std: 358, name: 'Finland' },
  { code: 'FR', std: 33, name: 'France' },
  { code: 'FX', std: 249, name: 'France, Metropolitan' },
  { code: 'GF', std: 594, name: 'French Guiana' },
  { code: 'PF', std: 689, name: 'French Polynesia' },
  { code: 'TF', std: 260, name: 'French Southern Territories' },
  { code: 'GA', std: 241, name: 'Gabon' },
  { code: 'GM', std: 220, name: 'Gambia' },
  { code: 'GE', std: 995, name: 'Georgia, Republic of' },
  { code: 'DE', std: 49, name: 'Germany' },
  { code: 'GH', std: 233, name: 'Ghana' },
  { code: 'GI', std: 350, name: 'Gibraltar' },
  { code: 'GR', std: 30, name: 'Greece' },
  { code: 'GL', std: 299, name: 'Greenland' },
  { code: 'GD', std: 1473, name: 'Grenada' },
  { code: 'GP', std: 590, name: 'Guadeloupe' },
  { code: 'GU', std: 1671, name: 'Guam' },
  { code: 'GT', std: 502, name: 'Guatemala' },
  { code: 'GN', std: 224, name: 'Guinea' },
  { code: 'GW', std: 245, name: 'Guinea-Bissau' },
  { code: 'GY', std: 592, name: 'Guyana' },
  { code: 'HT', std: 509, name: 'Haiti' },
  { code: 'HM', std: 334, name: 'Heard And Mc Donald Islands' },
  { code: 'HN', std: 504, name: 'Honduras' },
  { code: 'HK', std: 852, name: 'Hong Kong' },
  { code: 'HU', std: 36, name: 'Hungary' },
  { code: 'IS', std: 354, name: 'Iceland' },
  { code: 'IN', std: 91, name: 'India' },
  { code: 'ID', std: 62, name: 'Indonesia' },
  { code: 'IR', std: 98, name: 'Iran' },
  { code: 'IQ', std: 964, name: 'Iraq' },
  { code: 'IE', std: 353, name: 'Ireland' },
  { code: 'IL', std: 972, name: 'Israel' },
  { code: 'IT', std: 39, name: 'Italy' },
  { code: 'JM', std: 1876, name: 'Jamaica' },
  { code: 'JP', std: 81, name: 'Japan' },
  { code: 'JO', std: 962, name: 'Jordan' },
  { code: 'KZ', std: 7, name: 'Kazakhstan' },
  { code: 'KE', std: 254, name: 'Kenya' },
  { code: 'KI', std: 686, name: 'Kiribati' },
  { code: 'KR', std: 82, name: 'Korea' },
  { code: 'KW', std: 965, name: 'Kuwait' },
  { code: 'KG', std: 996, name: 'Kyrgyzstan' },
  { code: 'LA', std: 856, name: 'Laos' },
  { code: 'LV', std: 371, name: 'Latvia' },
  { code: 'LB', std: 961, name: 'Lebanon' },
  { code: 'LS', std: 266, name: 'Lesotho' },
  { code: 'LR', std: 231, name: 'Liberia' },
  { code: 'LY', std: 218, name: 'Libya' },
  { code: 'LI', std: 423, name: 'Liechtenstein' },
  { code: 'LT', std: 370, name: 'Lithuania' },
  { code: 'LU', std: 352, name: 'Luxembourg' },
  { code: 'MO', std: 853, name: 'Macau' },
  { code: 'MK', std: 389, name: 'Macedonia' },
  { code: 'MG', std: 261, name: 'Madagascar' },
  { code: 'MW', std: 265, name: 'Malawi' },
  { code: 'MY', std: 60, name: 'Malaysia' },
  { code: 'MV', std: 960, name: 'Maldives' },
  { code: 'ML', std: 223, name: 'Mali' },
  { code: 'MT', std: 356, name: 'Malta' },
  { code: 'MH', std: 692, name: 'Marshall Islands' },
  { code: 'MQ', std: 596, name: 'Martinique' },
  { code: 'MR', std: 222, name: 'Mauritania' },
  { code: 'MU', std: 230, name: 'Mauritius' },
  { code: 'YT', std: 175, name: 'Mayotte' },
  { code: 'MX', std: 52, name: 'Mexico' },
  { code: 'FM', std: 691, name: 'Micronesia' },
  { code: 'MD', std: 373, name: 'Moldova' },
  { code: 'MC', std: 377, name: 'Monaco' },
  { code: 'MN', std: 976, name: 'Mongolia' },
  { code: 'MS', std: 1664, name: 'Montserrat' },
  { code: 'MA', std: 212, name: 'Morocco' },
  { code: 'MZ', std: 258, name: 'Mozambique' },
  { code: 'MM', std: 95, name: 'Myanmar (Burma]' },
  { code: 'NA', std: 264, name: 'Namibia' },
  { code: 'NR', std: 674, name: 'Nauru' },
  { code: 'NP', std: 977, name: 'Nepal' },
  { code: 'NL', std: 31, name: 'Netherlands' },
  { code: 'AN', std: 599, name: 'Netherlands Antilles' },
  { code: 'NC', std: 687, name: 'New Caledonia' },
  { code: 'NI', std: 505, name: 'Nicaragua' },
  { code: 'NE', std: 227, name: 'Niger' },
  { code: 'NG', std: 234, name: 'Nigeria' },
  { code: 'NU', std: 570, name: 'Niue' },
  { code: 'NF', std: 672, name: 'Norfolk Island' },
  { code: 'KP', std: 850, name: 'North Korea' },
  { code: 'NO', std: 47, name: 'Norway' },
  { code: 'OM', std: 968, name: 'Oman' },
  { code: 'PK', std: 92, name: 'Pakistan' },
  { code: 'PW', std: 680, name: 'Palau' },
  { code: 'PS', std: 275, name: 'Palestinian Territory, Occupied' },
  { code: 'PA', std: 507, name: 'Panama' },
  { code: 'PG', std: 675, name: 'Papua New Guinea' },
  { code: 'PY', std: 595, name: 'Paraguay' },
  { code: 'PE', std: 51, name: 'Peru' },
  { code: 'PH', std: 63, name: 'Philippines' },
  { code: 'PN', std: 612, name: 'Pitcairn' },
  { code: 'PL', std: 48, name: 'Poland' },
  { code: 'PT', std: 351, name: 'Portugal' },
  { code: 'PR', std: 1787, name: 'Puerto Rico' },
  { code: 'QA', std: 974, name: 'Qatar' },
  { code: 'RE', std: 262, name: 'Reunion Island' },
  { code: 'RO', std: 40, name: 'Romania' },
  { code: 'RU', std: 7, name: 'Russia' },
  { code: 'RW', std: 250, name: 'Rwanda' },
  { code: 'MP', std: 1000, name: 'Saipan' },
  { code: 'SM', std: 378, name: 'San Marino' },
  { code: 'ST', std: 239, name: 'Sao Tome and Principe' },
  { code: 'SA', std: 966, name: 'Saudi Arabia' },
  { code: 'SN', std: 221, name: 'Senegal' },
  { code: 'SC', std: 248, name: 'Seychelles' },
  { code: 'SL', std: 232, name: 'Sierra Leone' },
  { code: 'SG', std: 65, name: 'Singapore' },
  { code: 'SK', std: 421, name: 'Slovak Republic' },
  { code: 'SI', std: 386, name: 'Slovenia' },
  { code: 'SB', std: 677, name: 'Solomon' },
  { code: 'SO', std: 252, name: 'Somalia' },
  { code: 'ZA', std: 27, name: 'South Africa' },
  { code: 'GS', std: 239, name: 'South Georgia And The South Sandwich Islands' },
  { code: 'ES', std: 34, name: 'Spain' },
  { code: 'LK', std: 94, name: 'Sri Lanka' },
  { code: 'SH', std: 654, name: 'St. Helena' },
  { code: 'KN', std: 1869, name: 'St. Kitts & Nevis' },
  { code: 'LC', std: 1758, name: 'St. Lucia' },
  { code: 'PM', std: 666, name: 'St. Pierre And Miquelon' },
  { code: 'VC', std: 1784, name: 'St. Vincent' },
  { code: 'SD', std: 249, name: 'Sudan' },
  { code: 'SR', std: 597, name: 'Suriname' },
  { code: 'SJ', std: 744, name: 'Svalbard And Jan Mayen Islands' },
  { code: 'SZ', std: 268, name: 'Swaziland' },
  { code: 'SE', std: 46, name: 'Sweden' },
  { code: 'CH', std: 41, name: 'Switzerland' },
  { code: 'SY', std: 963, name: 'Syria' },
  { code: 'TW', std: 886, name: 'Taiwan' },
  { code: 'TJ', std: 992, name: 'Tajikistan' },
  { code: 'TZ', std: 255, name: 'Tanzania' },
  { code: 'TH', std: 66, name: 'Thailand' },
  { code: 'TG', std: 228, name: 'Togo' },
  { code: 'TK', std: 690, name: 'Tokelau' },
  { code: 'TO', std: 676, name: 'Tonga' },
  { code: 'TT', std: 1868, name: 'Trinidad & Tobago' },
  { code: 'TN', std: 216, name: 'Tunisia' },
  { code: 'TR', std: 90, name: 'Turkey' },
  { code: 'TM', std: 993, name: 'Turkmenistan, Republic of' },
  { code: 'TC', std: 1649, name: 'Turks & Caicos Is.' },
  { code: 'TV', std: 688, name: 'Tuvalu' },
  { code: 'AE', std: 971, name: 'U.A.E.' },
  { code: 'VI', std: 1340, name: 'U.S. Virgin Islands' },
  { code: 'US', std: 1, name: 'U.S.A.' },
  { code: 'UG', std: 256, name: 'Uganda' },
  { code: 'UA', std: 380, name: 'Ukraine' },
  { code: 'GB', std: 44, name: 'United Kingdom' },
  { code: 'UM', std: 581, name: 'United States Minor Outlying Islands' },
  { code: 'UY', std: 598, name: 'Uruguay' },
  { code: 'UZ', std: 998, name: 'Uzbekistan' },
  { code: 'VU', std: 678, name: 'Vanuatu' },
  { code: 'VA', std: 39, name: 'Vatican City' },
  { code: 'VE', std: 58, name: 'Venezuela' },
  { code: 'VN', std: 84, name: 'Vietnam' },
  { code: 'WF', std: 681, name: 'Wallis & Futuna Islands' },
  { code: 'EH', std: 212, name: 'Western Sahara' },
  { code: 'WS', std: 685, name: 'Western Samoa' },
  { code: 'YE', std: 697, name: 'Yemen' },
  { code: 'YU', std: 891, name: 'Yugoslavia' },
  { code: 'ZM', std: 260, name: 'Zambia' },
  { code: 'ZW', std: 263, name: 'Zimbabwe' },
];

export const countryPhoneCodeOptions = countryCodes.map((c) => ({
  key: '' + c.code,
  text: c.name,
}));

export const countryCodeOptions = countryCodes.map((c) => ({
  key: c.code,
  text: c.name,
}));

const countryCodesPlusISO3166Ones = countryCodes
  .map((c) => ({ code: c.code, name: c.name }))
  .concat([
    { code: 'AX', name: 'Aland Islands' },
    { code: 'BL', name: 'Saint Barthelemy' },
    { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { code: 'CW', name: 'Curacao' },
    { code: 'GG', name: 'Guernsey' },
    { code: 'IM', name: 'Isle of Man' },
    { code: 'JE', name: 'Jersey' },
    { code: 'ME', name: 'Montenegro' },
    { code: 'MF', name: 'Saint-Martin (French part)' },
    { code: 'RS', name: 'Serbia' },
    { code: 'SS', name: 'South Sudan' },
    { code: 'SX', name: 'Saint-Maarten (Dutch Part)' },
  ]);

export function countryNameFromCode(code: string) {
  const countryCode = countryCodesPlusISO3166Ones.find((c) => c.code === code);
  return countryCode ? countryCode.name : code;
}
