import { useFormikContext } from 'formik';
import * as React from 'react';

import { Grid } from 'ui-library';

import ReadonlyData from 'common/controls/items/ReadonlyData';

interface AtonAuditSectionProps {}

const AtonAuditSection: React.FC<AtonAuditSectionProps> = (props) => {
  const formikContext = useFormikContext<any>();
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={6}>
          <ReadonlyData label={'Created by'} value={formikContext.values?.createdName} />
        </Grid.Col>
        <Grid.Col lg={6}>
          <ReadonlyData label={'Created date'} value={formikContext.values?.createdDate} formatDateFromISO />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={6}>
          <ReadonlyData label={'Last updated by'} value={formikContext.values?.lastUpdatedName} />
        </Grid.Col>
        <Grid.Col lg={6}>
          <ReadonlyData label={'Last updated date'} value={formikContext.values?.lastUpdatedDate} formatDateFromISO />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
export default AtonAuditSection;
