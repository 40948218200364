import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { CaseTypeCodeEnum } from 'api-client';

import { Form, Grid2 } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';

import { useAuditApi } from '../hooks';

type AuditCompleteFormProps = {
  id: number;
  startDate?: string;
  endDate: string;
  visible: boolean;
};

const ActionCardAuditComplete = (audit: AuditCompleteFormProps) => {
  const { completeAuditApi } = useAuditApi();
  const { navigateToAuditRecommendation, navigateToAuditView } = useInvestigationRouter();

  const handleSubmit = (values: AuditCompleteFormProps) => {
    completeAuditApi({ id: values.id, endDate: values.endDate }).then((ret) => {
      const caseTypeCode = ret?.type?.code;
      caseTypeCode && [CaseTypeCodeEnum.ENGINEER, CaseTypeCodeEnum.LICENCE_ENGINEERING].includes(caseTypeCode)
        ? navigateToAuditView(ret.id!)
        : navigateToAuditRecommendation(ret.id!);
    });
  };

  return audit.visible ? (
    <Form<AuditCompleteFormProps>
      formButtonsOptions={{
        hideBackButton: true,
        hideCancelButton: true,
        hideSubmitButton: true,
      }}
      handleSubmit={handleSubmit}
      hideFormButtonsTop
      id="completeAuditActionCard"
      isInDialog
      initialValues={audit}
      mode="CREATE"
      showFormButtonsBottom
      validationSchema={[{ name: 'endDate' }]}
    >
      <AuditCompleteContent />
    </Form>
  ) : (
    <></>
  );
};

const AuditCompleteContent = () => {
  const { values, resetForm, submitForm } = useFormikContext<AuditCompleteFormProps>();

  return (
    <MessageBar
      data-automation-id={`audit-complete-message-bar`}
      messageBarType={MessageBarType.warning}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="audit-complete-action" onClick={() => submitForm()}>
              Audit complete
            </PrimaryButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <DefaultButton data-automation-id="audit-complete-clear" onClick={() => resetForm()}>
              Clear
            </DefaultButton>
          </div>
        </>
      }
    >
      <Text>Check all details have been completed before completing the audit.</Text>
      <Grid2>
        <Grid2.Col lg={5}>
          <DatePicker dateOnlyAsString label="End date" maxDate={new Date()} minDate={values.startDate} name="endDate" required />
        </Grid2.Col>
      </Grid2>
    </MessageBar>
  );
};

export default ActionCardAuditComplete;
