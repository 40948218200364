import { Separator } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { CaseQuestionDto, QuestionInputTypeEnum } from 'api-client';

import { Grid } from 'ui-library';
import AddressPicker from 'ui-library/shared/AddressPicker';

import { useAuth } from 'common/auth/AuthHook';
import DatePicker from 'common/controls/inputs/DatePicker';
import { TextField } from 'common/controls/inputs/TextField';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { ComplaintFormProps } from 'investigation/interfaces';

const Question = ({ question, required }: { question: CaseQuestionDto; required: boolean }) => {
  const { id, inputType, question: label, maxLength = 0 } = question;
  const props = {
    label,
    name: `answers.question_${id}.answer`,
    required,
  };

  switch (inputType) {
    case QuestionInputTypeEnum.ADDRESS_PICKER:
      return <AddressPicker {...props} />;
    case QuestionInputTypeEnum.DATE_PICKER:
      return <DatePicker {...props} dateOnlyAsString />;
    default:
      return <TextField {...props} maxLength={maxLength} multiline={maxLength > 10} showMaxHint />;
  }
};

const LodgeComplaintQuestionnaire = () => {
  const { hasRole } = useAuth();
  const { mode } = useForm();
  const { values } = useFormikContext<ComplaintFormProps>();

  const isServiceCentreUser = hasRole('ROLE_MSG_SERVICE_CENTRE');

  return (
    <Grid.Row>
      <Grid.Col>
        <SectionHeading
          expanded={true}
          hint={isServiceCentreUser ? 'Enter N/A where a question is not relevant' : ''}
          title="Interference complaint questionnaire"
        >
          <Grid>
            {(values.caseQuestions || []).map((question) => (
              <div key={question.id}>
                <Grid.Row>
                  <Grid.Col lg={6}>
                    <Question question={question} required={isServiceCentreUser} />
                  </Grid.Col>
                </Grid.Row>
                {mode === 'VIEW' && <Separator />}
              </div>
            ))}
          </Grid>
        </SectionHeading>
      </Grid.Col>
    </Grid.Row>
  );
};

export default LodgeComplaintQuestionnaire;
