import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import TooltipHint from 'common/controls/items/TooltipHint';
import OverlaySpinner from 'common/controls/progress/OverlaySpinner';

import SecondaryButton from '../../common/controls/buttons/SecondaryButton';
import usePage from '../../common/layout/PageHook';

interface LabelKeyObject {
  label: string;
  key: string;
}

interface Props {
  currentPage?: number;
  data: any[] | (() => any[]);
  headers: LabelKeyObject[] | string[] | undefined;
  fileNamePrefix: string;
  confirmMessage?: string;
}

const ExportSearchCommonResults: React.FC<Props> = (props) => {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [csvData, setCsvData]: any[] = useState([]);
  const { setShowWarningModal } = usePage();
  const csvLink = useRef<any | null>(null);
  const zeroPad = (num?: number, places?: number) => String(num).padStart(places!, '0');

  const onExport = () => {
    if (props.confirmMessage) {
      setShowWarningModal(true, [props.confirmMessage], false, {
        onConfirm: () => {
          setShowSpinner(true);
          setCsvData(props.data);
        },
      });
    } else {
      setShowSpinner(true);
      setCsvData(props.data);
    }
  };

  useEffect(() => {
    if (csvData && csvData.length > 0 && csvLink && csvLink.current && csvLink.current.link) {
      setTimeout(() => {
        csvLink.current.link.click();
        setCsvData([]);
        setShowSpinner(false);
      });
    }
  }, [csvData]);

  return (
    <>
      <div style={{ position: 'relative', float: 'right' }}>
        <TooltipHint
          data-automation-id="export-file-hint"
          content="Only search results displayed in the current page will be included in the export file. To include more results, increase the number per page and search again."
        />
        <SecondaryButton style={{ marginLeft: '5px' }} data-automation-id="export-file" onClick={() => onExport()}>
          Export file
        </SecondaryButton>
      </div>
      {showSpinner && <OverlaySpinner label="Exporting" />}
      <CSVLink
        data={csvData}
        headers={props.headers}
        asyncOnClick={true}
        hidden
        ref={csvLink}
        target="_blank"
        filename={`${props.fileNamePrefix} - ${zeroPad(props.currentPage || 1, 4)}.csv`}
      />
    </>
  );
};

export default ExportSearchCommonResults;
