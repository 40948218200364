import { Label } from '@fluentui/react';
import * as React from 'react';

import { Grid } from 'ui-library';

export const SpectrumMaskPointTableHeader: React.FC<any> = () => {
  return (
    <Grid.Row>
      <Grid.Col sm={12} lg={1}>
        <Label>Graph Point</Label>
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <Label>Displacement (MHz)</Label>
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <Label>Frequency (MHz)</Label>
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <Label>Set Level dBW (eirp)</Label>
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <Label>Calc. Level dB</Label>
      </Grid.Col>
    </Grid.Row>
  );
};
