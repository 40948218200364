import * as yup from 'yup';

import { FileNoteDtoEntityTypeEnum } from 'api-client';

import { requiredString } from 'common/validation/yupUtils';

export const fileNoteFormSchema = yup.object().shape({
  noteDate: requiredString,
  type: requiredString,
  noteTitle: requiredString,
  timeSpent: yup
    .string()
    .nullable(true)
    .when(['entityType'], {
      is: (entityType: string) => entityType === FileNoteDtoEntityTypeEnum.CASE,
      then: requiredString.matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Time should follow HH:MM format'),
    }),
  restrictedType: requiredString,
});
