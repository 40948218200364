import { Grid } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';

const SpectrumMaskAuditInformation = () => {
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <ReadOnlyField label="Created by" name={'createdName'} />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <ReadOnlyField label="Created date" name={'createdDate'} renderFormat={formatDateTimeFromISO} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <ReadOnlyField label="Last updated by" name={'lastUpdatedName'} />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <ReadOnlyField label="Last updated date" name={'lastUpdated'} renderFormat={formatDateTimeFromISO} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default SpectrumMaskAuditInformation;
