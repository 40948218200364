import { ILinkStyles, Link as FluentLink, useTheme } from '@fluentui/react';
import * as React from 'react';

import { getClassNames } from './Footer.classNames';
import { useMedia } from './MediaHook';
import mbieLogo from './logo-mbie.png';
import nzGovtLogo from './logo-nz-govt.png';

interface LinkProps {
  href: string;
  children?: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ children, href }) => {
  const theme = useTheme();

  const linkStyles: ILinkStyles = {
    root: {
      color: theme.palette.white,
      selectors: {
        ':active:hover': {
          color: theme.palette.white,
        },
        ':hover': {
          color: theme.palette.white,
        },
        ':visited': {
          color: theme.palette.white,
        },
      },
    },
  };

  return (
    <FluentLink href={href} target="_blank" styles={linkStyles}>
      {children}
    </FluentLink>
  );
};

interface Props {}

const Footer: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { isMobile } = useMedia();
  const classNames = getClassNames(theme, isMobile);

  return (
    <footer className={classNames.root}>
      <div className={classNames.logos}>
        <FluentLink href="https://www.mbie.govt.nz" title="Ministry of Business, Innovation, and Employment.">
          <img src={mbieLogo} className={classNames.mbieLogo} alt="Ministry of Business, Innovation, and Employment." />
        </FluentLink>
        <FluentLink target="_blank" href="https://www.govt.nz" title="Open the New Zealand Government website in a new window.">
          <img src={nzGovtLogo} className={classNames.nzGovtLogo} alt="New Zealand Government Te Kāwanatanga." />
        </FluentLink>
      </div>
      <hr
        style={{
          margin: '19.5px 0 0 2px',
        }}
      />
      <div className={classNames.links}>
        <ul className={classNames.linkList}>
          <li>
            <Link href="https://www.rsm.govt.nz/privacy">PRIVACY</Link>
          </li>
          <li>
            <Link href="https://www.rsm.govt.nz/disclaimer">DISCLAIMER</Link>
          </li>
          <li>
            <Link href="https://www.rsm.govt.nz/copyright">COPYRIGHT</Link>
          </li>
          <li>
            <Link href="https://www.rsm.govt.nz/about/terms-of-use">TERMS OF USE</Link>
          </li>
          <li>
            <Link href="https://www.rsm.govt.nz/licensing/how-do-i/use-the-rrf">HOW TO GUIDES</Link>
          </li>
          <li>
            <Link href="https://www.rsm.govt.nz/about/contact-us">CONTACT US</Link>
          </li>
          <li>
            <Link href="https://www.rsm.govt.nz">RSM WEBSITE</Link>
          </li>
        </ul>
        <p className={classNames.copyright}>Crown copyright © {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
