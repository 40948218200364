import { validateYupSchema, yupToFormErrors } from 'formik';
import { Schema } from 'yup';

export const validateWithFormValues = <T>(schema: Schema<T>, context?: any) => {
  return (values: any) => {
    try {
      validateYupSchema(values, schema, true, { ...values, context: context });
    } catch (err) {
      return yupToFormErrors(err);
    }

    return {};
  };
};

/**
 * Example of when this one should be used is if you have validation that has an async Promise result such as an API call to validate the field.
 */
export const validateWithFormValuesAsync = <T>(schema: Schema<T>, context?: any) => (values: any) =>
  validateYupSchema(values, schema, false, { ...values, context: context })
    .then(() => {})
    .catch(yupToFormErrors);
