import { IBasePickerProps, ITag, Label, mergeStyles, TagPicker as FormikTagPicker } from '@fluentui/react';
import { FieldAttributes, useField } from 'formik';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

import { useForm } from '../../form/FormHook';
import { RRFTheme, useTheme } from '../../theme/RRFTheme';
import TooltipHint from '../items/TooltipHint';
import ErrorLabel from './ErrorLabel';
import ReadOnlyField from './ReadOnlyField';

export interface TagPickerProps extends Omit<IBasePickerProps<ITag>, 'selectedItems'> {
  name: string;
  label?: string;
  // Allows freeform user input, rather than restricting to the provided suggestions.
  allowFreeForm?: boolean;
  // When suggestion item selected (or selected item removed), use this to update the form field value instead of the default tag.key
  tagToFieldValue?: (tag: ITag) => any;
  // When free form input field is to be an item, use this to update the form field value instead of the default inputText
  inputToFieldValue?: (inputText: string) => any;
  // When TagPicker is initialised or form field value has changed, use this to set the selected items instead of the default tag key & name as the value
  fieldValueToTag?: (value: any) => ITag;
  alwaysValidate?: boolean;
  readOnly?: boolean;
  hint?: string;
}

export const tagItemStyleClass = ({ semanticColors }: RRFTheme) =>
  mergeStyles({
    nav: {
      selectors: {
        '& .ms-TagItem, &:hover .ms-TagItem': {
          backgroundColor: semanticColors.tagPickerTextBackgroundColor,
          color: semanticColors.tagPickerTextColor,
        },
        '&:hover .ms-TagItem-close': { backgroundColor: semanticColors.tagPickerCloseButtonBackgroundColor },
        '&:hover .ms-TagItem-close.is-disabled': { backgroundColor: semanticColors.disabledBackground, color: semanticColors.disabledText },
      },
    },
  });

const TagPicker: React.FC<FieldAttributes<TagPickerProps>> = (props) => {
  const theme = useTheme();
  const [field, meta, fieldHelper] = useField<any>(props);
  const [inputText, setInputText] = useState<string | undefined>();
  const [selectedItems, setSelectedItems] = useState<ITag[]>([]);
  const [key, setKey] = useState<number>(0);
  const { mode } = useForm();
  const ref = useRef<HTMLDivElement | null>(null);
  const [getMoreResultsSelected, setGetMoreResultsSelected] = useState<boolean>(false);
  const multiSelect = (props.itemLimit ?? 2) > 1;
  const tagToFieldValue = (tag: ITag) => (props.tagToFieldValue ? props.tagToFieldValue(tag) : tag.key);
  const inputToFieldValue = (inputText: string) => (props.inputToFieldValue ? props.inputToFieldValue(inputText) : inputText);
  const fieldValueToTag = (value: any): ITag => (props.fieldValueToTag ? props.fieldValueToTag(value) : { key: value, name: '' + value });
  const pickerId = props.name;

  const setFieldValue = (value?: any) => {
    if (multiSelect) {
      if (Array.isArray(value)) {
        fieldHelper.setValue(value);
      } else if (value !== undefined && value !== null) {
        fieldHelper.setValue(Array.isArray(field.value) ? [...field.value, value] : [value]);
      }
    } else {
      if (Array.isArray(value)) {
        fieldHelper.setValue(value?.length ? value[0] : undefined);
      } else {
        fieldHelper.setValue(value);
      }
    }
  };

  const toTagItems = (value?: any): ITag[] => {
    if (Array.isArray(value)) {
      return value.map(fieldValueToTag);
    }
    return value ? [fieldValueToTag(value)] : [];
  };

  useEffect(() => {
    if (!field.value) {
      setSelectedItems([]);
    } else {
      fieldHelper.setTouched(true);
      setSelectedItems(toTagItems(field.value));
    }
    // eslint-disable-next-line
  }, [field.value]);

  useEffect(() => {
    // Touch the field after change but not during initialising.
    if (key > 0) {
      // Manually set touched as it doesn't seem to be automatically done by the component, to trigger validation on this field to run.
      fieldHelper.setTouched(true);
    }
    // eslint-disable-next-line
  }, [selectedItems]);

  // Handle the case when the user has chosen a suggestion item or removed an item.
  const onChange = (items?: ITag[]) => {
    setFieldValue(items?.map((item) => tagToFieldValue(item)));
    setSelectedItems(items ? items : []);
    setInputText(undefined);
    if (props.onChange) {
      props.onChange(items);
    }
  };

  // Handle the case when the user opts to not choose a suggestion item either by tabbing away or mouse clicking away
  // from the input field. Append to the selected items the value of the input text if it isn't a selected item yet.
  const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(ev);
    if (props.allowFreeForm) {
      const inputTextAsTag = inputText?.trim() ? fieldValueToTag(inputToFieldValue(inputText)) : undefined;
      const nameIsInputText = (item: ITag) => item.name === inputTextAsTag!.name;

      if (inputTextAsTag && !selectedItems.find(nameIsInputText)) {
        setFieldValue(inputToFieldValue(inputText!));
      }
      setInputText(undefined);
      // Work-around to clear the input field onBlur, as this component out of the box doesn't do it as a controlled component.
      setKey(key + 1);
    } else {
      setInputText(undefined);
      // Work-around to clear the input field onBlur, as this component out of the box doesn't do it as a controlled component.
      setKey(key + 1);
    }
    if (props.onBlur) {
      props.onBlur(ev);
    }
  };

  const onInputChange = (input: string) => {
    input = props.onInputChange ? props.onInputChange(input) : input;
    setInputText(input.trim());
    return input;
  };

  // The following block of code is a work-around to automatically pop open the suggestions list when the Get More Results
  // button is clicked.
  const onGetMoreResults = !props.onGetMoreResults
    ? undefined
    : (filter: string, selectedItems?: ITag[]) => {
        const results = props.onGetMoreResults!(filter, selectedItems);
        if (Array.isArray(results)) {
          setGetMoreResultsSelected(true);
          return results;
        }
        return results.then((results) => {
          setGetMoreResultsSelected(true);
          return results;
        });
      };
  useEffect(() => {
    if (getMoreResultsSelected) {
      (ref?.current?.querySelector('input') as any)?.click();
      setGetMoreResultsSelected(false);
    }
  }, [getMoreResultsSelected]);

  const errorBorderClass = (props.alwaysValidate || meta.touched) && meta.error ? 'pickerErrorBorder ' : '';

  const styles = {
    ...props.styles,
    text: {
      // The default TagPicker background is transparent which means that it takes on the background of the InlineForm,
      // therefore override it to be that of other input fields.
      backgroundColor: theme.semanticColors.inputBackground,
      ...(props.styles as any)?.text,
    },
  };

  if (mode === 'VIEW' || props.readOnly) {
    return <ReadOnlyField {...props} renderFormat={() => (selectedItems.length ? selectedItems.map((item) => item.name).join(', ') : ' ')} />;
  }
  return (
    <div ref={ref} id={`${pickerId}-tag-picker`} data-automation-id={`${pickerId}-tag-picker`}>
      {props.label && (
        <Label required={props.required} htmlFor={pickerId}>
          {props.label}
          {props.hint && <TooltipHint id="tag-picker-tooltip-hint" content={props.hint} styles={{ root: { marginLeft: '5px' } }} />}
        </Label>
      )}
      <FormikTagPicker
        key={key}
        {...props}
        {...field}
        styles={styles}
        onGetMoreResults={onGetMoreResults}
        selectedItems={selectedItems}
        onChange={onChange}
        onBlur={onBlur}
        onInputChange={onInputChange}
        className={errorBorderClass + tagItemStyleClass(theme)}
        disabled={mode === 'DISABLED' || props.disabled}
        inputProps={{ ...(props.inputProps || {}), id: pickerId }}
      />
      <ErrorLabel {...props} />
    </div>
  );
};

export default TagPicker;
