import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { useForm } from 'common/form/FormHook';

import SpectrumMaskAuditInformation from '../SpectrumMaskAuditInformation';
import SpectrumMaskFields from '../SpectrumMaskFields';

const UpdateSpectrumMaskFields = () => {
  const { isInternalUser } = useAuth();
  const { mode } = useForm();
  const maintainForm = mode === 'EDIT' || mode === 'VIEW';

  return (
    <>
      {maintainForm && (
        <>
          <Grid.Row>
            <Grid.Col sm={12} lg={5}>
              <ReadOnlyField label="Spectrum Mask ID" name={'id'} />
            </Grid.Col>
          </Grid.Row>
          <SpectrumMaskFields />

          {maintainForm && isInternalUser && (
            <Grid.Row>
              <Grid.Col lg={5} sm={12}>
                <Checkbox name="hidden" label="Hidden" />
              </Grid.Col>
            </Grid.Row>
          )}
        </>
      )}

      {maintainForm && isInternalUser && <SpectrumMaskAuditInformation />}
    </>
  );
};

export default UpdateSpectrumMaskFields;
