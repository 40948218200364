import { useTheme } from '@fluentui/react';
import * as React from 'react';

interface Props {}

const HorizontalDivider: React.FC<Props> = (props) => {
  const theme = useTheme();
  return <hr style={{ color: theme.semanticColors.bodyDivider, borderStyle: 'solid', borderWidth: '1px', borderBottomWidth: 0 }} />;
};

export default HorizontalDivider;
