import moment from 'moment';
import * as yup from 'yup';

import { dateSchema, notRequiredString, requiredString } from 'common/validation/yupUtils';

import { concatenatesDateTime, ISO_8601_NO_TIME } from '../common/utils/dateUtils';

const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const outageNotificationUpdateFormSchema = yup.object().shape({
  priority: requiredString,
  status: requiredString,
  fromDate: requiredString,
  toDate:
    notRequiredString &&
    dateSchema.test('toDate', 'Must be the same or later than the From Date', function (this: yup.TestContext, value) {
      return !value || !this.parent['fromDate'] || moment(value).isSameOrAfter(this.parent['fromDate']);
    }),
  message: yup.string().required().max(4000, 'Message must be less than 4000 characters.'),
  fromTime: yup.string().required('Required').matches(TIME_REGEX, 'Time should follow HH:MM format'),
  toTime: yup
    .string()
    .notRequired()
    .matches(TIME_REGEX, 'Time should follow HH:MM format')
    .test('toDate', 'Must be the same or later than the From date and time', function (this: yup.TestContext, value) {
      const from = concatenatesDateTime(moment(this.parent['fromDate']).format(ISO_8601_NO_TIME), this.parent['fromTime']!);
      const to = concatenatesDateTime(moment(this.parent['toDate']).format(ISO_8601_NO_TIME), value!);
      return !value || !this.parent['fromDate'] || !this.parent['fromTime'] || !this.parent['toDate'] || moment(to).isSameOrAfter(from);
    }),
});
