import React from 'react';

import { DocumentDto } from 'api-client';

import FileView from './FileView';

export interface FileViewsProps {
  fileList: DocumentDto[];
}

const FileViews: React.FC<FileViewsProps> = (props: FileViewsProps) => {
  return (
    <>
      {props.fileList.map((x) => (
        <FileView key={x.drmId} nameFile={x.fileName!} />
      ))}
    </>
  );
};

export default FileViews;
