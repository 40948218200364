import { MessageBarType } from '@fluentui/react';
import React from 'react';

import { CaseTypeCodeEnum, CaseTypeGroupEnum } from 'api-client';

import Form from 'ui-library/form';

import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import ComplaintFormContent from 'investigation/complaint/components/ComplaintFormContent';
import { useCaseQuestions } from 'investigation/complaint/hooks';
import { AssociatedToByEnum } from 'investigation/components/CaseAssociatedTo';
import {
  REQUIRED_FIELDS,
  useCamundaGroupOptions,
  useCaseTypeOptions,
  useCreateCase,
  useCurrentUserGroups,
  useValidateForm,
} from 'investigation/hooks';
import { ComplaintFormProps } from 'investigation/interfaces';

const CreateComplaintForm = () => {
  const { hasRole, myUserId } = useAuth();
  const { caseTypeOptions } = useCaseTypeOptions();
  const { caseQuestions } = useCaseQuestions();
  const { createCase } = useCreateCase();
  const { camundaGroupOptions } = useCamundaGroupOptions({ isForComplaint: true });
  const { currentRadioInvestigatorGroup } = useCurrentUserGroups();
  const { navigateToMaintainComplaint } = useInvestigationRouter();
  const { setPageInstruction } = usePage();
  const { validateForm } = useValidateForm(CaseTypeGroupEnum.COMPLAINT, caseQuestions);

  const isServiceCentreUser = hasRole('ROLE_MSG_SERVICE_CENTRE');

  return (
    <Form<ComplaintFormProps>
      id="create-complaint-form"
      mode="CREATE"
      formButtonsOptions={{
        submitButtonText: 'Save',
      }}
      showFormButtonsBottom
      handleSubmit={(values, formikHelpers) => {
        createCase(values)
          .then(({ id = 0 }) => {
            navigateToMaintainComplaint(id);
            setPageInstruction(
              <span>
                Case <strong>#{id}</strong> has been successfully saved.
              </span>,
              MessageBarType.success,
            );
          })
          .finally(() => formikHelpers.setSubmitting(false));
      }}
      initialValues={{
        ...REQUIRED_FIELDS.reduce((output, field) => ({ ...output, [field]: undefined }), {}),

        allocatedTo: currentRadioInvestigatorGroup?.id,
        allocatedToGroups: camundaGroupOptions,
        allocatedToUser: currentRadioInvestigatorGroup?.id ? myUserId : undefined,
        associatedTo: undefined,
        associatedToBy: isServiceCentreUser ? AssociatedToByEnum.CLIENT : undefined,

        answers: caseQuestions
          .map(({ id }) => id)
          .reduce(
            (output, id) => ({
              ...output,
              [`question_${id}`]: {
                answer: undefined,
              },
            }),
            {},
          ),
        caseTypeId: isServiceCentreUser ? caseTypeOptions.find(({ data }) => CaseTypeCodeEnum.DOMESTIC === data?.code)?.key : undefined,
        caseTypeOptions,
        caseQuestions,
      }}
      validate={validateForm}
    >
      <ComplaintFormContent />
    </Form>
  );
};

export const CreateComplaintPage = () => {
  return (
    <Page title="Create Interference Complaint">
      <CreateComplaintForm />
    </Page>
  );
};
