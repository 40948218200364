import MaintainAtonPage from 'aton_management/aton/maintain/MaintainAtonPage';
import * as React from 'react';
import { Route } from 'react-router';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

const FormTenPage = React.lazy(() => import('licence_management/cancel_spectrum_licence_form_ten/FormTenPage'));
const LegacyFormTenPage = React.lazy(() => import('licence_management/cancel_spectrum_licence_form_ten/LegacyFormTenPage'));
const FormSevenPage = React.lazy(() => import('licence_management/create_spectrum_form_seven/FormSevenPage'));
const LegacyFormSevenPage = React.lazy(() => import('licence_management/create_spectrum_form_seven/LegacyFormSevenPage'));
const ManagementRightApplicationPage = React.lazy(() => import('licence_management/management_right/apply/ManagementRightApplicationPage'));
const FormFivePage = React.lazy(() => import('licence_management/management_right/modify/form_five/FormFivePage'));
const FormFourPage = React.lazy(() => import('licence_management/management_right/modify/form_four/FormFourPage'));
const Form22Page = React.lazy(() => import('licence_management/management_right/modify/form_22/Form22Page'));
const FormSixPage = React.lazy(() => import('licence_management/management_right/modify/form_six/FormSixPage'));
const FormTwoPage = React.lazy(() => import('licence_management/management_right/modify/form_two/FormTwoPage'));
const ManagementRightSummaryPage = React.lazy(() => import('licence_management/management_right/summary/ManagementRightSummaryPage'));
const FormEightPage = React.lazy(() => import('licence_management/modify_spectrum_licence_form_eight/FormEightPage'));
const GeoReferenceTypeConverterPage = React.lazy(() => import('licence_management/reference_data/geoReferenceType/GeoReferenceTypeConverterPage'));
const FormNinePage = React.lazy(() => import('licence_management/transfer_spectrum_licence_form_nine/FormNinePage'));
const LegacyFormNinePage = React.lazy(() => import('licence_management/transfer_spectrum_licence_form_nine/LegacyFormNinePage'));
const MaintainLicenceToSupplyPage = React.lazy(() => import('licence_to_supply_management/maintain/MaintainLicenceToSupplyPage'));
const SearchAtonPage = React.lazy(() => import('search/aton/SearchAtonPage'));
const SearchCallsignPage = React.lazy(() => import('search/callsign/SearchCallsignPage'));
const SearchCertificatePage = React.lazy(() => import('search/certificate/SearchCertificatePage'));
const SearchManagementRightsPage = React.lazy(() => import('search/management_rights/SearchManagementRightsPage'));
const Form11Page = React.lazy(() => import('../licence_management/mortgage_caveat/form_11/Form11Page'));
const Form18Page = React.lazy(() => import('../licence_management/mortgage_caveat/form_18/Form18Page'));
const Form20Page = React.lazy(() => import('../licence_management/mortgage_caveat/form_20/Form20Page'));
const Form21Page = React.lazy(() => import('../licence_management/mortgage_caveat/form_21/Form21Page'));
const Form22PageHandler = React.lazy(() => import('../licence_management/management_right/Form22PageHandler'));
const SearchLicencePage = React.lazy(() => import('../search/licence/SearchLicencePage'));
const SearchLicencesToSupplyPage = React.lazy(() => import('../search/licences_to_supply/SearchLicencesToSupplyPage'));
const ViewClientPage = React.lazy(() => import('./client/ViewClientPage'));
const ViewLicenceSummaryPage = React.lazy(() => import('./licence/ViewLicenceSummaryPage'));
const LandingPage = React.lazy(() => import('common/layout/LandingPage'));

export const VIEW_CLIENT_URL = '/ui/client/';

export const PUBLIC_FORM_ELEVEN_URL = '/ui/form-eleven';
export const PUBLIC_FORM_EIGHTEEN_URL = '/ui/form-eighteen';
export const PUBLIC_FORM_TWENTY_URL = '/ui/form-twenty';
export const PUBLIC_FORM_TWENTY_ONE_URL = '/ui/form-twenty-one';
export const PUBLIC_FORM_TWENTY_TWO_URL = '/ui/form-twenty-two';

const PublicRouter: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/view/:licenceId`} component={ViewLicenceSummaryPage} />
      <Route path={`${path}/client/:clientId`} component={ViewClientPage} />
      <Route path={`${path}/search/licence`} component={SearchLicencePage} />
      <Route path={`${path}/search/licence-to-supply`} component={SearchLicencesToSupplyPage} />
      <Route path={`${path}/search/management-rights`} component={SearchManagementRightsPage} />
      <Route path={`${path}/search/certificate`} component={SearchCertificatePage} />
      <Route path={`${path}/search/callsign`} component={SearchCallsignPage} />
      <Route path={`${path}/search/aton`} component={SearchAtonPage} />
      <Route path={`${path}/licence-to-supply/:id`} component={MaintainLicenceToSupplyPage} />
      <Route path={`${path}/management-right/:id`} component={ManagementRightSummaryPage} />
      <Route path={`${path}/licence/reference/geo-reference-converter`} component={GeoReferenceTypeConverterPage} />
      <Route path={`${path}/licence/:type(radio|spectrum)/view/:licenceId`} component={ViewLicenceSummaryPage} />
      <Route path={`${path}/aton/:atonId`} component={MaintainAtonPage} />
      <Route path={`${path}/form-one/:formId`} component={ManagementRightApplicationPage} />
      <Route path={`${path}/form-two/:formId`} component={FormTwoPage} />
      <Route path={`${path}/form-four/:formId`} component={FormFourPage} />
      <Route path={`${path}/form-five/:formId`} component={FormFivePage} />
      <Route path={`${path}/form-six/:formId`} component={FormSixPage} />
      <Route path={`${path}/form-seven/legacy/:formId`} component={LegacyFormSevenPage} />
      <Route path={`${path}/form-seven/:formId`} component={FormSevenPage} />
      <Route path={`${path}/form-eight/legacy/:formId`} component={FormEightPage} />
      <Route path={`${path}/form-eight/:formId`} component={FormEightPage} />
      <Route path={`${path}/form-nine/legacy/:formId`} component={LegacyFormNinePage} />
      <Route path={`${path}/form-nine/:formId`} component={FormNinePage} />
      <Route path={`${path}/form-ten/legacy/:formId`} component={LegacyFormTenPage} />
      <Route path={`${path}/form-ten/:formId`} component={FormTenPage} />
      <Route path={`${path}/form-eleven/:formId`} component={Form11Page} />
      <Route path={`${path}/form-eighteen/:formId`} component={Form18Page} />
      <Route path={`${path}/form-twenty/:formId`} component={Form20Page} />
      <Route path={`${path}/form-twenty-one/:formId`} component={Form21Page} />
      <Route path={`${path}/form-twenty-two/:formId`} component={Form22PageHandler} />
      <Route path={`${path}`} component={LandingPage} />
    </Switch>
  );
};

export const usePublicNavigation = () => {
  const history = useHistory();
  return {
    navigateToViewClient: (clientId: number) => history.push(`${VIEW_CLIENT_URL}${clientId}`),
    navigateToViewLicenceToSupply: (id: number) => history.push(`/ui/licence-to-supply/${id}`),
    navigateToViewManagementRight: (id: number) => history.push(`/ui/management-right/${id}`),
    navigateToViewLicencePublic: (licenceId: number, type: 'spectrum' | 'radio' = 'radio', newTab?: boolean) =>
      newTab ? window.open(`/ui/licence/${type}/view/${licenceId}`, '_blank') : history.push(`/ui/licence/${type}/view/${licenceId}`),
    navigateToViewAtonPublic: (atonId: number) => history.push(`/ui/aton/${atonId}`),
  };
};

export default PublicRouter;
