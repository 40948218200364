import { Reducer } from 'redux';

import { PageState } from '../../store';
import {
  ClearOutageNotificationAction,
  ClearPageInstructionAction,
  PageStateActionType,
  SetIsLoadingAction,
  SetOutageNotificationAction,
  SetPageInstructionAction,
  ShowMobileNavAction,
  TypeKeys,
  WarningModalAction,
} from '../actions/pageActions';

const pageStateReducer: Reducer = (state: PageState, action: PageStateActionType): PageState => {
  switch (action.type) {
    case TypeKeys.SET_IS_LOADING:
      return handleSetIsLoading(state, action);
    case TypeKeys.SET_PAGE_INSTRUCTION:
      return handleSetPageInstruction(state, action);
    case TypeKeys.CLEAR_PAGE_INSTRUCTION:
      return handleClearPageInstruction(state, action);
    case TypeKeys.SHOW_MOBILE_NAV:
      return handleShowMobileNav(state, action);
    case TypeKeys.SET_SHOW_WARNING_MODAL:
      return handleShowWarningModal(state, action);
    case TypeKeys.SET_OUTAGE_NOTIFICATION:
      return handleSetOutageNotification(state, action);
    case TypeKeys.CLEAR_OUTAGE_NOTIFICATION:
      return handleClearOutageNotification(state, action);
    default:
      return { ...state } as PageState;
  }
};

const handleSetIsLoading = (state: PageState, action: SetIsLoadingAction): PageState => {
  return { ...state, isLoading: action.payload };
};

const handleSetPageInstruction = (state: PageState, action: SetPageInstructionAction): PageState => {
  return { ...state, pageInstruction: action.payload };
};

const handleClearPageInstruction = (state: PageState, action: ClearPageInstructionAction): PageState => {
  return { ...state, pageInstruction: undefined };
};

const handleSetOutageNotification = (state: PageState, action: SetOutageNotificationAction): PageState => {
  return {
    ...state,
    outageNotificationsState: [...state.outageNotificationsState, action.payload],
  };
};

const handleClearOutageNotification = (state: PageState, action: ClearOutageNotificationAction): PageState => {
  return {
    ...state,
    outageNotificationsState: state.outageNotificationsState.filter((item) => item.id !== action.payload.id),
  };
};

const handleShowMobileNav = (state: PageState, action: ShowMobileNavAction): PageState => {
  return { ...state, showMobileNav: action.payload };
};

const handleShowWarningModal = (state: PageState, action: WarningModalAction): PageState => {
  return { ...state, ...action.payload };
};

export default pageStateReducer;
