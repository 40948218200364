import { DefaultButton, Label } from '@fluentui/react';
import { Formik, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';

// Do not change import order
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
//------------------------------------
import ReactAce from 'react-ace/lib/ace';

import { OutageNotificationDto } from 'api-client';

import ChoiceGroup from 'common/controls/inputs/ChoiceGroup';
import Dropdown from 'common/controls/inputs/Dropdown';
import { FormContext, FormMode } from 'common/form/Form';

import DatePicker from '../common/controls/inputs/DatePicker';
import ErrorLabel from '../common/controls/inputs/ErrorLabel';
import { TextField } from '../common/controls/inputs/TextField';
import InlineForm from '../common/form/InlineForm';
import usePage from '../common/layout/PageHook';
import { concatenatesDateTime, formatDateFromISO, formatTime, ISO_8601_NO_T_SEC, ISO_8601_NO_TIME } from '../common/utils/dateUtils';
import { asOptionsSimple } from '../common/utils/dropdownUtils';
import { Grid } from '../ui-library';
import { useOutageNotificationApi } from './OutageNotificationApiHook';
import { OutageNotificationDtoPriorityEnum, parsePriority } from './OutageNotificationsPage';
import { outageNotificationFormSchema } from './validationSchema';
import { outageNotificationUpdateFormSchema } from './validationSchemaForUpdate';

interface Props {
  item: OutageNotificationDto;
  mode: 'create' | 'update' | 'view';
  addItem: (item: OutageNotificationDto) => void;
  onClose: () => void;
}

const OutageNotificationRow: React.FC<Props> = (props) => {
  const initValues = {
    id: props.item.id ?? undefined,
    priority: parsePriority(props.item.priority) ?? undefined,
    message: props.item.message ?? undefined,
    fromDate: props.item.from ? formatDateFromISO(props.item.from) : undefined,
    fromTime: props.item.from ? formatTime(props.item.from.split('T')[1]).slice(0, -3) : undefined,
    toDate: props.item.to ? formatDateFromISO(props.item.to) : undefined,
    toTime: props.item.to ? formatTime(props.item.to.split('T')[1]).slice(0, -3) : undefined,
    status: props.item.status ?? undefined,
    createdDate: props.item.createdDate ?? undefined,
    createdBy: props.item.createdBy ?? undefined,
  };

  const [panelMode, setPanelMode] = useState(props.mode);
  const { createOutageNotification, updateOutageNotification } = useOutageNotificationApi();
  const { setPageInstruction } = usePage();

  const priorityOptions = asOptionsSimple(Object.values(OutageNotificationDtoPriorityEnum));

  const statusOptions = [
    { key: 'ACTIVE', text: 'Yes' },
    { key: 'INACTIVE', text: 'No' },
  ];

  const convertPriority = (priority: string) => {
    switch (priority) {
      case 'High':
        return 1;
      case 'Moderate':
        return 2;
      case 'Low':
        return 3;
      default:
        return 1;
    }
  };

  const additionalButtons = (mode: FormMode) => {
    switch (mode) {
      case 'EDIT':
        return [];
      case 'CREATE':
        return [
          {
            id: `cancel-outage-notif-form`,
            onClick: () => {
              props.onClose();
              setPageInstruction();
            },
            text: 'Cancel',
            shouldConfirm: true,
          },
        ];
      case 'VIEW':
        return [
          {
            id: 'close-outage-notif-form',
            onClick: props.onClose,
            text: 'Close',
          },
        ];
    }
  };

  const MessageEditor = (props: { name: string; height?: string; enableSnippets?: boolean }) => {
    const { setFieldValue, getFieldMeta } = useFormikContext<OutageNotificationDto>();
    const editor = useRef<ReactAce>(null);

    const HandleAddSnipped = () => {
      const currentEditor = editor.current;
      currentEditor?.editor.insert('test');
    };

    return (
      <>
        {props.enableSnippets && (
          <div>
            <DefaultButton text="Add snippet" onClick={() => HandleAddSnipped()} id={'add-snippet'} data-automation-id={'add-snippet'} />
          </div>
        )}
        <div style={{ border: '1px solid' }}>
          <AceEditor
            ref={editor}
            mode="html"
            width="auto"
            height={props.height}
            theme="tomorrow"
            onChange={(value) => setFieldValue(props.name, value)}
            name={props.name}
            editorProps={{ $blockScrolling: false }}
            value={getFieldMeta(props.name).value as string}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              wrap: true,
              useWorker: false,
            }}
            readOnly={panelMode === 'view'}
          />
        </div>
        <ErrorLabel name={props.name} />
      </>
    );
  };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      onSubmit={(values) => {
        const convertedValues = {
          ...values,
          from: concatenatesDateTime(formatDateFromISO(values.fromDate, ISO_8601_NO_TIME), values.fromTime),
          to: values.toDate && values.toTime ? concatenatesDateTime(formatDateFromISO(values.toDate, ISO_8601_NO_TIME), values.toTime) : undefined,
          priority: convertPriority(values.priority),
        };
        delete convertedValues['fromDate'];
        delete convertedValues['fromTime'];
        delete convertedValues['toDate'];
        delete convertedValues['toTime'];
        panelMode === 'create'
          ? createOutageNotification(convertedValues).then(props.addItem)
          : updateOutageNotification(convertedValues.id || -1, convertedValues).then(props.addItem);
        setPageInstruction();
        props.onClose();
      }}
      validationSchema={panelMode === 'create' ? outageNotificationFormSchema : outageNotificationUpdateFormSchema}
    >
      <InlineForm
        id={`${panelMode}-outage-notif-form`}
        canEdit={true}
        hideBack
        hideFormButtonsTop
        showFormButtonsBottom
        mode={panelMode === 'create' ? 'CREATE' : 'VIEW'}
        additionalButtons={additionalButtons}
        onEditClick={() => setPanelMode('update')}
        onCancelEdit={() => setPanelMode('view')}
      >
        <Grid.Row>
          <div hidden={panelMode === 'create'}>
            <Grid.Col lg={3}>
              <TextField label="ID" name="id" readOnly />
            </Grid.Col>
          </div>
          <Grid.Col lg={3}>
            <Dropdown label="Priority" name="priority" options={priorityOptions} required />
          </Grid.Col>
          <Grid.Col lg={3}>
            <ChoiceGroup label="Active" name="status" direction={'row'} options={statusOptions} required />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={3}>
            <FormContext.Provider value={{ mode: 'EDIT' }}>
              <DatePicker label="From" name="fromDate" readOnly={panelMode === 'view'} required />
            </FormContext.Provider>
          </Grid.Col>
          <Grid.Col lg={2}>
            <TextField label="Time" name="fromTime" data-automation-id="fromTime" required />
          </Grid.Col>
          <Grid.Col lg={3}>
            <FormContext.Provider value={{ mode: 'EDIT' }}>
              <DatePicker label="To" name="toDate" readOnly={panelMode === 'view'} />
            </FormContext.Provider>
          </Grid.Col>
          <Grid.Col lg={2}>
            <TextField label="Time" name="toTime" data-automation-id="toTime" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={9}>
            <Label>Message</Label>
            <br />
            <MessageEditor name="message" height={'70px'} />
          </Grid.Col>
        </Grid.Row>
        <div className="ms-Grid-row" hidden={panelMode === 'create' || panelMode === 'update'}>
          <Grid.Col lg={3}>
            <TextField label="Created date" name="createdDate" readOnlyRenderFormat={(d) => formatDateFromISO(d, ISO_8601_NO_T_SEC)} readOnly />
          </Grid.Col>
          <Grid.Col lg={3}>
            <TextField label="Created by" name="createdBy" readOnly />
          </Grid.Col>
        </div>
      </InlineForm>
    </Formik>
  );
};

export default OutageNotificationRow;
