import { IDropdownOption } from '@fluentui/react';
import moment from 'moment';

import { ChannelDto, LicenceTypeDto, PreferredBandDto, ServiceTypeDto, ITUServiceTypeDto } from 'api-client';

import { asOptions, KeyValue } from '../../common/utils/dropdownUtils';

export type ChannelMap = {
  band: number;
  channels: IDropdownOption[];
};

export const tenMonthsFromLastDayOfCurrentMonth = moment().add(1, 'months').date(1).subtract(1, 'days').add(10, 'months');

export const landSimplexAutograntedLocations = [
  { key: 'ALL NEW ZEALAND', text: 'All New Zealand' },
  { key: 'NORTH ISLAND', text: 'North Island' },
  { key: 'SOUTH ISLAND', text: 'South Island' },
  { key: 'CHATHAM ISLANDS', text: 'Chatham Islands' },
];

export const toTransmitLocationDto = (locationName: string) => {
  return {
    locationType: 'ABL',
    locationTypeId: 5, //Name
    locationTypeDiscriminator: 'ApplicationTransmitLocationDto',
    locationName,
  };
};

export const mobileReceiveLocation = {
  locationType: 'AOL',
  locationTypeId: 5, //Name
  locationTypeDiscriminator: 'ApplicationReceiveLocationDto',
  locationName: 'MOBILE',
};

export const radioLicenceModifyReasons = new Map<string, string>([
  ['FIXED_TERM_LICENCE', 'Fixed term radio licences cannot be modified. Please submit a new licence application.'],
  [
    'LICENCE_UNPAID_FEES',
    'An unpaid invoice exists related to this licence for annual licence fee renewal. The invoice must be paid before this licence can be modified.',
  ],
  ['LICENCE_PERMISSION_ERROR', 'The permissions related to your current logged in role do not allow you to modify this licence type.'],
  ['LICENCE_BEING_MODIFIED', 'An application to modify this or an associated licence has already been started.'],
  ['PRIMARY_LICENCE_BEING_MODIFIED', 'The primary licence related to this licence is currently being modified.'],
  ['ASSOCIATED_LICENCE_BEING_MODIFIED', 'The associated licence related to this licence is currently being modified.'],
  ['LICENCE_AIRCRAFT_ERROR', 'Aircraft licences cannot be modified. Please submit a new licence application.'],
  [
    'LICENCE_LAND_SIMPLEX_GENERAL_CHANNELS_ERROR',
    'Land simplex - General channel licences cannot be modified. Please submit a new licence application.',
  ],
  ['LICENCE_SHIP_ERROR', 'Ship licences cannot be modified. Please submit a new licence application.'],
  ['IMPROPER_ROLE_ERROR', 'Your login session role does not have permission to initiate spectrum licence modification.'],
  ['MANAGER_ONLY_ERROR', 'Only the manager is authorised to initiate modification for this spectrum licence.'],
  ['RIGHT_HOLDER_ERROR', 'Only the rightholder is authorised to initiate modification for this spectrum licence.'],
  ['LICENCE_UNDER_MODIFY_ERROR', 'An application to modify this licence has already been started.'],
  ['LICENCE_UNDER_ACTION_ERROR', 'A spectrum form related to this licence is under action.'],
  ['MR_NO_MANAGER_ERROR', 'The associated Management Right for this spectrum licence does not have a manager.'],
]);

export const spectrumLicenceModifyReasons = new Map<string, string>([
  ['FIXED_TERM_LICENCE', 'Fixed term radio licences cannot be modified. Please submit a new licence application.'],
  [
    'LICENCE_UNPAID_FEES',
    'An unpaid invoice exists related to this licence for annual licence fee renewal. The invoice must be paid before this licence can be modified.',
  ],
  ['LICENCE_PERMISSION_ERROR', 'The permissions related to your current logged in role do not allow you to modify this licence type.'],
  ['LICENCE_BEING_MODIFIED', 'An application to modify this licence has already been started.'],
  ['PRIMARY_LICENCE_BEING_MODIFIED', 'The primary licence related to this licence is currently being modified.'],
  ['ASSOCIATED_LICENCE_BEING_MODIFIED', 'The associated licence related to this licence is currently being modified.'],
  ['LICENCE_AIRCRAFT_ERROR', 'Aircraft licences cannot be modified. Please submit a new licence application.'],
  ['LICENCE_SHIP_ERROR', 'Ship licences cannot be modified. Please submit a new licence application.'],
  ['IMPROPER_ROLE_ERROR', 'Your login session role does not have permission to initiate spectrum licence modification.'],
  ['MANAGER_ONLY_ERROR', 'Only the manager is authorised to initiate modification for this spectrum licence.'],
  ['RIGHT_HOLDER_ERROR', 'Only the rightholder is authorised to initiate modification for this spectrum licence.'],
  ['LICENCE_UNDER_MODIFY_ERROR', 'An application to modify this licence has already been started.'],
  ['LICENCE_UNDER_ACTION_ERROR', 'A spectrum form related to this licence is under action.'],
  ['MR_NO_MANAGER_ERROR', 'The associated Management Right for this spectrum licence does not have a manager.'],
  ['SPL_NO_RIGHTHOLER_ERROR', 'There is no manager associated with this spectrum licence.'],
]);

export const defatultRadioLicenceApplicationConfiguration = {
  applicationType: 'RAD',
  status: 'INCOMPLETE',
  applicationTypeDiscriminator: 'RadioApplicationDto',
};

export const defaultSpectrumLicenceApplicationConfiguration = {
  applicationType: 'SAP',
  status: 'INCOMPLETE',
  applicationTypeDiscriminator: 'SpectrumApplicationDto',
};

export const toBandDisplayDescription = (dto: PreferredBandDto): string => {
  return dto.lowerBound + ' - ' + dto.upperBound + ' MHz ( ' + dto.remarks + ' )';
};

export const toChannelMap = (bandId: number, channels: ChannelDto[]): ChannelMap => {
  return {
    band: bandId,
    channels: asOptions(
      channels.map((dto) => {
        return {
          key: dto.id + '',
          value:
            dto.label +
            (dto.usageCount && dto.usageCount > 0 ? ' (' + dto.usageCount + ' Existing Licence' + (dto.usageCount > 1 ? 's' : '') + ')' : ''),
        };
      }),
      true,
      true,
    ),
  };
};

export const toSpectrumObject = (id: number | string) => {
  return { spectrumId: id };
};

export const serviceTypeToKeyValue = (dto: ServiceTypeDto | undefined): KeyValue => {
  return {
    key: dto!.name ? dto!.name : '',
    value: dto!.description ? dto!.description : '',
  };
};

export const serviceTypeToKeyValuePair = (dto: ServiceTypeDto | undefined): KeyValue => {
  return {
    key: dto!.id ? dto!.id + '' : '',
    value: dto!.description ? dto!.description : '',
  };
};

export const ITUServiceTypeToKeyValuePair = (dto: ITUServiceTypeDto | undefined): KeyValue => {
  return {
    key: dto!.id ? dto!.id + '' : '',
    value: dto!.description ? dto!.description : '',
  };
};

export const licenceTypeToKeyValue = (dto: LicenceTypeDto): KeyValue => {
  return {
    key: dto!.id ? dto!.id + '' : '',
    value: dto!.description ? dto!.description : '',
  };
};

export function findServices1(
  serviceTypes: ServiceTypeDto[] | undefined,
  level1name: string | undefined,
  level2name: string | undefined,
  level3name: string | undefined,
) {
  return serviceTypes!
    .find((serviceType) => level1name === serviceType.name)
    ?.nextLevelServices?.find((serviceType) => level2name === serviceType.name)
    ?.nextLevelServices?.find((serviceType) => level3name === serviceType.name);
}

export function findServices(serviceTypes: ServiceTypeDto[] | undefined, level1name: string | undefined, level2name?: string, level3name?: string) {
  if (level1name) {
    const level1service = findService(serviceTypes, level1name);
    if (!!!level2name && !!!level3name) return level1service;
    if (level2name) {
      const level2service = findService(level1service?.nextLevelServices, level2name);
      if (!!!level3name) return level2service;
      else return findService(level2service?.nextLevelServices, level3name);
    }
  }
  return {};
}

function findService(serviceTypes: ServiceTypeDto[] | undefined, nextLevelName: string) {
  return serviceTypes ? serviceTypes.find((serviceType) => nextLevelName === serviceType.name) : undefined;
}

// TODO CNZRRF-1635. Hardcoded for now.
export const licenceTypeOptions = [
  { key: 15, text: 'Aircraft' },
  { key: 16, text: 'Aero Base (Route)' },
  { key: 17, text: 'Aero Base (Off Route)' },
  { key: 18, text: 'Aero Repeater' },
  { key: 32, text: 'HF AM (Radio)' },
  { key: 34, text: 'Two Frequency (SSR, DME)' },
  { key: 36, text: 'Fixed Uni-directional Point-to-Multipoint' },
  { key: 37, text: 'Fixed Bi-directional Point-to-Multipoint' },
  { key: 38, text: 'Fixed <1 GHz; BW <=50 kHz (Bi-directional)' },
  { key: 40, text: 'Fixed < 1GHz; BW >50kHz (Bi-directional)' },
  { key: 44, text: 'Fixed >=1 GHz (Bi-directional)' },
  { key: 49, text: 'Fixed Television Outside Broadcast 2G8 Band' },
  { key: 54, text: 'Fixed Television Outside Broadcast 7G Band' },
  { key: 55, text: 'Satellite' },
  { key: 57, text: 'Amateur Repeater' },
  { key: 58, text: 'Amateur Beacon' },
  { key: 64, text: 'Meteorological Radar' },
  { key: 65, text: 'Meteorological Aid (Radiosonde)' },
  { key: 67, text: 'Land Repeater' },
  { key: 71, text: 'Land Simplex Govt. use only' },
  { key: 73, text: 'Land Simplex' },
  { key: 111, text: 'Aero Repeater - Mobile Transmit' },
  { key: 112, text: 'Amateur Digipeater' },
  { key: 113, text: 'Amateur Fixed' },
  { key: 114, text: 'Amateur TV Repeater' },
  { key: 116, text: 'Land Mobile - Mobile Transmit' },
  { key: 120, text: 'Ship - Voluntary' },
  { key: 121, text: 'Ship - Compulsory' },
  { key: 132, text: 'Maritime Coast Station' },
  { key: 138, text: 'Maritime Repeater' },
  { key: 140, text: 'Maritime Mobile - Mobile Transmit' },
  { key: 142, text: 'Radiodet - Radio Beacons (VOR, NDB, ILS)' },
  { key: 144, text: 'Paging' },
  { key: 152, text: 'PRS Repeater' },
  { key: 153, text: 'Telemetry/Telecommand (Uni-directional)' },
  { key: 157, text: 'MF AM' },
  { key: 162, text: 'VHF FM' },
  { key: 169, text: 'UHF TV' },
  { key: 178, text: 'Other (Spectrum)' },
  { key: 193, text: 'Radiodetermination - Mobile Transmit' },
  { key: 194, text: 'Satellite - Satellite Transmit' },
  { key: 196, text: 'Amateur Repeater - Mobile Transmit' },
  { key: 197, text: 'General user licence (Radio)' },
  { key: 198, text: 'General user licence (Spectrum)' },
  { key: 202, text: 'Aero Mobile (Route)' },
  { key: 203, text: 'Aero Mobile (Off Route)' },
  { key: 206, text: 'Fixed <1 GHz; BW <=50 kHz (Uni-directional)' },
  { key: 207, text: 'Fixed < 1GHz; BW >50kHz (Uni-directional)' },
  { key: 208, text: 'Fixed >=1 GHz (Uni-directional)' },
  { key: 217, text: 'Radar (PSR)' },
  { key: 218, text: 'PRS - Mobile Transmit' },
  { key: 210, text: 'Telemetry/Telecommand (Bi-directional)' },
  { key: 211, text: 'Land Simplex - General Channels' },
  { key: 212, text: 'Space operations earth stations' },
  { key: 213, text: 'Space operations spacecraft' },
  { key: 214, text: 'White Space' },
  { key: 215, text: 'Managed Spectrum Park' },
  { key: 216, text: 'Land Mobile (Spectrum)' },
  { key: 219, text: 'Base stations (AtoN)' },
  { key: 220, text: 'Radar' },
  { key: 221, text: 'Radar (Other)' },
  { key: 222, text: 'Miscellaneous' },
].sort((o1, o2) => o1.text.localeCompare(o2.text));
