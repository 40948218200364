import { Icon, IDropdownOption, Label, Link, mergeStyles, useTheme } from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import * as React from 'react';

import { Grid } from 'ui-library';

import { ChannelMap } from '../../../licence_management/apply/utils';
import { removeFromArray } from '../../utils/arrayUtils';
import MultiSelectDropdown from './MultiSelectDropdown';

export interface PreferredBandsProps {
  onPreferredBandsChange: (option: any, key?: any) => void;
  preferredBands: IDropdownOption[];
  channelOptions?: ChannelMap[];
  selectedLicenceDescription?: string;
}

const PreferredBandSelector: React.FC<PreferredBandsProps> = (props) => {
  const theme = useTheme();
  const color = theme.semanticColors.buttonBackground;
  const iconStyle = { color, verticalAlign: 'middle', marginRight: 5 };
  const colourClass = mergeStyles({ color: theme.semanticColors.buttonBackground, fontSize: 20 });
  const formikContext = useFormikContext<any>();
  const bandMandatory = formikContext.values?.showPreferredBand === true;

  const removeChannel = (index: number) => {
    formikContext.setFieldValue('channelSpectrums', removeFromArray(index, formikContext.values.channelSpectrums));
  };

  const handleChannel = (option: any) => {
    if (option.selected) {
      formikContext.setFieldValue('channelSpectrums', formikContext.values.channelSpectrums ? [...formikContext.values.channelSpectrums, []] : [[]]);
    } else {
      const channelArrayIndex = formikContext.values.bandSpectrums.findIndex((bandId: number) => bandId === option.key);
      removeChannel(channelArrayIndex);
    }
  };

  return (
    <>
      <FieldArray name="bandSpectrums">
        {(helper) => (
          <>
            <Grid.Row>
              <Grid.Col sm={12} lg={5}>
                <MultiSelectDropdown
                  name="bandSpectrums"
                  label="Preferred band(s)"
                  options={props.preferredBands}
                  placeholder="-- Select a value --"
                  onChange={(option: any, key?: any) => {
                    handleChannel(option); //add an empty array to trigger validation
                    props.onPreferredBandsChange(option, key);
                  }}
                  required={bandMandatory}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              {helper.form.values?.bandSpectrums && helper.form.values?.bandSpectrums.length > 0 && (
                <Grid.Col sm={12} lg={5}>
                  <div
                    style={{
                      borderBottom: '1px solid #AAAAAA',
                      marginTop: 5,
                      paddingBottom: 5,
                    }}
                  ></div>
                </Grid.Col>
              )}
            </Grid.Row>
            {helper.form.values?.bandSpectrums?.map((band: number, i: number) => (
              <Grid.Row key={`band-${i}`}>
                <Grid.Col sm={12} lg={5}>
                  <div
                    style={{
                      borderBottom: '1px solid #AAAAAA',
                      paddingBottom: 10,
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <Label>{props.preferredBands.find((option) => option.key === band)?.text}</Label>
                      <div style={{ position: 'absolute', right: 0, top: 0 }}>
                        <Link
                          className={colourClass}
                          onClick={(e) => {
                            helper.remove(i);
                            removeChannel(i);
                            e.preventDefault();
                          }}
                        >
                          <Icon iconName="StatusErrorFull" style={iconStyle} />
                        </Link>
                      </div>
                    </div>
                    {props.selectedLicenceDescription === 'Land Simplex - General Channels' &&
                      props.channelOptions?.find((option) => option.band === band)?.channels &&
                      props.channelOptions?.find((option) => option.band === band)!.channels!.length > 0 && (
                        <>
                          <MultiSelectDropdown
                            placeholder="-- Select a value --"
                            name={`channelSpectrums.${i}`}
                            label="Channels"
                            options={
                              props.channelOptions?.find((option) => option.band === band)?.channels
                                ? props.channelOptions?.find((option) => option.band === band)?.channels!
                                : []
                            }
                            helpText="TBD by Chris Zawodny insert hint text here"
                            required
                          />
                        </>
                      )}
                  </div>
                </Grid.Col>
              </Grid.Row>
            ))}
          </>
        )}
      </FieldArray>
    </>
  );
};

export default PreferredBandSelector;
