import {
  ColumnActionsMode,
  DetailsList as FluentDetailsList,
  DetailsRow,
  FocusZoneTabbableElements,
  IColumn,
  IDetailsListProps,
  IDetailsRowProps,
  IGroup,
  mergeStyles,
  SelectionMode,
} from '@fluentui/react';
import * as React from 'react';
import { useState } from 'react';

import { TransmitLocationDto } from 'api-client';

import renderSevereWarningRow from 'common/controls/lists/SevereWarningRow';
import { useTheme } from 'common/theme/RRFTheme';

import { useMedia } from '../../layout/MediaHook';
import DetailsListShimmer from './DetailsListShimmer';
import renderErrorRow from './ErrorRow';
import renderSuccessRow from './SuccessRow';
import renderWarningRow from './WarningRow';

export interface Column extends IColumn {
  isNumber?: boolean;
  minWidthMobile?: number;
}

export interface DetailListGroup extends IGroup {
  hasError?: boolean;
  highlightRow?: boolean;
}

interface Props extends IDetailsListProps {
  renderExpandPanel?: (item: any, itenIdx?: number) => JSX.Element;
  expandedIndex?: number;
  sortProps?: SortProps;
  onSort?: (sortProps: SortProps) => void;
  renderRowAsWarning?: (props: IDetailsRowProps) => boolean;
  renderRowAsSevereWarning?: (props: IDetailsRowProps) => boolean;
  renderRowAsError?: (props: IDetailsRowProps) => boolean;
  renderRowAsSuccess?: (props: IDetailsRowProps) => { shouldRender: boolean; callback: () => void };
  columns: Column[];
  id?: string;
  handleTabKey?: FocusZoneTabbableElements;
  groups?: DetailListGroup[];
  loading?: boolean;
  isHeaderVisible?: boolean;
}

export interface ColumnFactoryFunctionProps {
  isMobile: boolean;
  onView: (index?: number) => void;
  onRemove: (item?: any, idx?: number) => void;
  hideRemove?: boolean;
  hasAuthority?: boolean;
  onShowMap?: (config: TransmitLocationDto) => void;
}

export interface SortProps {
  sortKey: string;
  descending: boolean;
}

const DetailsList: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { isMobile } = useMedia();
  const [showOtherCols, setShowOtherCols] = useState<boolean[]>([]);
  const mobileCols = 3;
  const isNumberClassName = (column: Column) =>
    column.isNumber
      ? {
          className: `${column.className ?? ''} is-number`.trim(),
          headerClassName: `${column.headerClassName ?? ''} is-number`.trim(),
        }
      : {};

  let columns = props.columns?.map(
    (column) =>
      ({
        ...column,
        minWidth: isMobile ? column.minWidthMobile ?? 0 : column.minWidth,
        styles: {
          sortIcon: {
            fontSize: '15px',
          },
          root: {
            backgroundColor: theme.semanticColors.detailsListBackground,
            color: theme.semanticColors.detailsListText,
            ':hover': {
              cursor: column.columnActionsMode === ColumnActionsMode.disabled ? 'not-allowed' : 'initial',
            },
          },
        },
        ...isNumberClassName(column),
      } as IColumn),
  );

  if (props.onSort) {
    columns = columns?.map(({ key, ...rest }) => {
      return {
        key,
        ...rest,
        isSorted: key === props.sortProps?.sortKey,
        isSortedDescending: key === props.sortProps?.sortKey && props.sortProps.descending,
        onColumnClick: () => {
          const newSorKey = props.sortProps?.sortKey === key ? props.sortProps?.sortKey : key;
          const newDescending = props.sortProps?.sortKey === key ? !props.sortProps.descending : false;
          props.onSort!({
            sortKey: newSorKey,
            descending: newDescending,
          });
        },
      };
    });
  }

  const buttonStyleClass = mergeStyles({
    '& button': {
      marginRight: '5px',
    } as React.CSSProperties,
  });

  const isNumberStyleClass = mergeStyles({
    '& .is-number span.ms-DetailsHeader-cellTitle': {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    '& .is-number': {
      textAlign: 'right',
    },
  });

  const headerStyleClass = mergeStyles({
    // The default header has a padding-top: 16px. This is fine on a white background, but inside a grey Card component
    // the white space above the header is obvious. Therefore change this to a margin-top so that the space above the
    // header is the same colour as e.g. the grey Card component.
    '& .ms-DetailsHeader': {
      paddingTop: 0,
      marginTop: 16,
    },
    '& .ms-DetailsHeader-cell': {
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .ms-DetailsHeader-cellTitle': {
      height: '100%',
      alignItems: 'center',
    },
    '& .ms-DetailsRow-cell': {
      paddingRight: 8,
    },
  });

  const renderRow = (rowProps: IDetailsRowProps) => {
    const onRenderRow = (rowProps: IDetailsRowProps) => {
      if (props.onRenderRow) {
        return props.onRenderRow(rowProps);
      }
      return <DetailsRow {...rowProps} />;
    };

    if (props.renderRowAsSuccess && props.renderRowAsSuccess(rowProps).shouldRender) {
      return renderSuccessRow(
        { ...rowProps, onRenderRow: (successRowProps) => onRenderRow(successRowProps!) },
        props.renderRowAsSuccess(rowProps).callback,
      );
    }

    if (props.renderRowAsError && props.renderRowAsError(rowProps)) {
      return renderErrorRow({ ...rowProps, onRenderRow: (errorRowProps) => onRenderRow(errorRowProps!) });
    }

    if (props.renderRowAsSevereWarning && props.renderRowAsSevereWarning(rowProps)) {
      return renderSevereWarningRow({ ...rowProps, onRenderRow: (errorRowProps) => onRenderRow(errorRowProps!) });
    }

    if (props.renderRowAsWarning && props.renderRowAsWarning(rowProps)) {
      return renderWarningRow({ ...rowProps, onRenderRow: (errorRowProps) => onRenderRow(errorRowProps!) });
    }

    return onRenderRow(rowProps);
  };

  const handleRenderMobileRow = (rowProps: IDetailsRowProps) => {
    const { item, itemIndex } = rowProps;
    return (
      <div>
        <div className={buttonStyleClass}>
          <div
            onClick={() => {
              showOtherCols[rowProps.itemIndex] = !showOtherCols[rowProps.itemIndex];
              setShowOtherCols([...showOtherCols]);
            }}
            role="button"
            aria-label={`${!showOtherCols[rowProps.itemIndex] ? 'Expand ' : 'Collapse '} row ${rowProps.itemIndex + 1} button`}
            aria-expanded={!!showOtherCols[rowProps.itemIndex]}
            tabIndex={0}
          >
            {renderRow({ ...rowProps })}
          </div>
          {columns!.map((_, i) => {
            if (i < mobileCols || !showOtherCols[rowProps.itemIndex]) {
              return undefined;
            }
            const column = columns[i] as Column;
            const mobileColumns: IColumn[] = [
              {
                ...columns![i],
                calculatedWidth:
                  column.minWidthMobile && column.minWidthMobile > (rowProps.rowWidth ?? 0) ? column.minWidthMobile : rowProps.rowWidth,
                onRender: (item, index?: number) => (
                  <>
                    {columns![i].name && (
                      <b
                        style={{
                          width: '25%',
                          height: '100%',
                          display: 'block',
                          float: 'left',
                          boxSizing: 'border-box',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {columns![i].name}
                      </b>
                    )}
                    <div>{!!columns![i].onRender ? columns![i].onRender!(item, index) : (item as any)[columns![i].key]}</div>
                  </>
                ),
              },
            ];

            return renderRow({ ...rowProps, columns: mobileColumns });
          })}
        </div>
        {props.renderExpandPanel && itemIndex === props.expandedIndex && props.renderExpandPanel(item, itemIndex)}
      </div>
    );
  };

  const expandableRowRender = (rowProps: IDetailsRowProps) => {
    const { item, itemIndex } = rowProps;

    return (
      <div>
        <div className={buttonStyleClass}>{renderRow(rowProps)}</div>
        {props.renderExpandPanel && itemIndex === props.expandedIndex && props.renderExpandPanel(item, itemIndex)}
      </div>
    );
  };

  return props.loading ? (
    <DetailsListShimmer
      columns={props.columns}
      enableShimmer={true}
      ariaLabelForShimmer="Content is being fetched"
      ariaLabelForGrid="Item details"
      selectionMode={SelectionMode.none}
      items={[]}
    />
  ) : (
    <div data-automation-id={props.id} className={isNumberStyleClass + ' ' + headerStyleClass}>
      <FluentDetailsList
        {...props}
        selectionMode={props.selectionMode ? props.selectionMode : SelectionMode.none}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        columns={isMobile ? columns?.slice(0, mobileCols) : columns}
        onRenderRow={isMobile && columns ? (handleRenderMobileRow as any) : expandableRowRender}
        checkButtonAriaLabel="Row checkbox"
        focusZoneProps={{
          handleTabKey: props.handleTabKey,
        }}
        delayFirstMeasure
      />
    </div>
  );
};

export default DetailsList;
