import * as React from 'react';
import SearchManagementRights, { defaultValues } from './SearchManagementRights';
import { ManagementRightsSearchResult } from 'api-client';

interface Props {
  onSelect: (selected: ManagementRightsSearchResult) => void;
}

const EmbeddedSearchManagementRights: React.FC<Props> = (props) => {
  return <SearchManagementRights initialValues={defaultValues} mode="EMBEDDED" onSelect={props.onSelect} hideManagementRightsStatuses />;
};

export default EmbeddedSearchManagementRights;
