import { Formik } from 'formik';
import React from 'react';

import { useAuth } from 'common/auth/AuthHook';
import InlineForm from 'common/form/InlineForm';

import { SubSectionHeading } from '../../../../common/layout/SectionHeadings';
import DefinedAreaFields from '../components/DefinedAreaFields';
import { createDefinedAreaSchema } from '../validationSchema';

interface Props {
  onCancel: () => void;
  onAdd: (DefinedAreaDto: any) => void;
}

const EmbeddedCreateDefinedArea = (props: Props) => {
  const initValue = {
    nameQualifier: undefined,
    locationName: undefined,
    districts: [],
  };

  const { isInternalUser } = useAuth();

  if (!isInternalUser) {
    return <></>;
  }

  const additionalButtons = () => {
    return [
      {
        id: 'cancel-create-defined-area-location',
        onClick: props.onCancel,
        text: 'Cancel',
      },
    ];
  };

  return (
    <Formik initialValues={initValue} onSubmit={props.onAdd} validationSchema={createDefinedAreaSchema}>
      <InlineForm
        id={'create-embedded-defined-area-location-form'}
        showFormButtonsBottom
        hideFormButtonsTop
        hideBack
        mode="CREATE"
        additionalButtons={additionalButtons}
      >
        <SubSectionHeading title={'Add a new location'} />
        <DefinedAreaFields />
      </InlineForm>
    </Formik>
  );
};

export default EmbeddedCreateDefinedArea;
