import { MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CaseStatusEnum, CaseTypeGroupEnum, FileNoteDtoEntityTypeEnum, Link } from 'api-client';

import { Form } from 'ui-library';
import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { hasAction } from 'common/api/hateosUtils';
import { usePageNavigator } from 'common/auth/AppRoute';
import { useAuth } from 'common/auth/AuthHook';
import { FormMode } from 'common/form/Form';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { CASE_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import ComplaintFormContent from 'investigation/complaint/components/ComplaintFormContent';
import { useMaintainCase, useValidateForm } from 'investigation/hooks';
import { ComplaintFormProps } from 'investigation/interfaces';

import ActionCardCloseComplaint from './components/ActionCardCloseComplaint';
import { useComplaintDataFetcher } from './hooks';

const MaintainComplaintForm = () => {
  const { hasAnyPermission, hasPermission } = useAuth();

  const { maintainCase } = useMaintainCase();
  const { setPageInstruction } = usePage();
  const [complaintFormMode, setComplaintFormMode] = useState<FormMode>('VIEW');

  const { caseDto, caseDetails } = useComplaintDataFetcher();
  const [updatedValues, setUpdatedValues] = useState<Partial<ComplaintFormProps>>();

  const calculateCouldClose = (caseLinks?: Link[], caseStatus?: CaseStatusEnum) =>
    hasAction('close-complaint', caseLinks) && caseStatus === CaseStatusEnum.IPRG;

  const { validateForm } = useValidateForm(CaseTypeGroupEnum.COMPLAINT, caseDetails.caseQuestions);
  const [canBeClosed, setCanBeClosed] = useState<boolean>(calculateCouldClose());

  const { navigateTo } = usePageNavigator();
  const { navigateToViewComplaint } = useInvestigationRouter();

  const { caseId } = useParams<{ caseId: string }>();
  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  useEffect(() => {
    if (caseDto?.id) {
      if (caseDto.type?.group !== CaseTypeGroupEnum.COMPLAINT) {
        navigateTo('/not-found');
      } else if (caseDto.status === CaseStatusEnum.CLSD) {
        navigateToViewComplaint(caseDto.id);
      }
      setCanBeClosed(calculateCouldClose(caseDto.links, caseDto.status));
    }
  }, [caseDto]);

  return (
    <>
      {complaintFormMode === 'VIEW' && canBeClosed && <ActionCardCloseComplaint id={caseDto?.id} startDate={caseDto.startDate} />}
      <Form<ComplaintFormProps>
        canEdit={!!hasAnyPermission(['BASIC_UPDATE_COMPLAINT', 'UPDATE_COMPLAINT']) && caseDto?.status !== CaseStatusEnum.CLSD}
        formButtonsOptions={{
          submitButtonText: 'Save',
        }}
        handleSubmit={(values, formikHelpers) => {
          setCanBeClosed(false);
          maintainCase(values)
            .then(({ id, lastUpdatedName, lastUpdatedDate, startUser, status, links, startDate }) => {
              setUpdatedValues({
                ...values,
                links: links,
                lastUpdatedBy: lastUpdatedName,
                lastUpdatedDate: formatDateTimeFromISO(lastUpdatedDate, { showSeconds: true }),
                startUser: startUser,
                statusEnum: status,
              });
              caseDto.startDate = startDate;
              setPageInstruction(
                <span>
                  Case <strong>#{id}</strong> has been successfully updated.
                </span>,
                MessageBarType.success,
              );
              setCanBeClosed(calculateCouldClose(links, status));
            })
            .finally(() => {
              formikHelpers.setSubmitting(false);
            });
        }}
        id="maintain-complaint-form"
        initialValues={{
          ...caseDetails,
          ...updatedValues,
        }}
        showFormButtonsBottom
        validate={validateForm}
      >
        <ComplaintFormContent setModeForm={setComplaintFormMode} />
        {canViewFileNote && <FileNoteSection entityId={+caseId} entityType={FileNoteDtoEntityTypeEnum.CASE} noteTypes={CASE_FILE_NOTE_TYPE} />}
        <EventLogSection entityName={'CASE'} entityId={+caseId} />
      </Form>
    </>
  );
};

export const MaintainComplaintPage = () => {
  return (
    <Page title="Maintain Interference Complaint">
      <MaintainComplaintForm />
    </Page>
  );
};
