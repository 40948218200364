import { DirectionalHint, Icon, Label, mergeStyles, TooltipHost } from '@fluentui/react';
import { IRenderFunction } from '@fluentui/utilities';
import * as React from 'react';

const HelpToolTip = (props: any) => (
  <TooltipHost
    directionalHint={DirectionalHint.rightCenter}
    closeDelay={1000}
    tooltipProps={{ maxWidth: '1200' }}
    {...props}
    className={mergeStyles({ border: '1px solid #C84504' })}
    content={props.helpText as string}
  >
    <Icon iconName="InfoSolid" style={{ marginLeft: '5px', color: '#C84504', fontSize: 20, verticalAlign: 'middle' }} />
  </TooltipHost>
);

export const renderHelpText: IRenderFunction<any> = (props?: any, defaultRender?: (props?: any) => JSX.Element | null) => {
  return props?.helpText ? (
    <>
      <Label styles={{ root: { display: 'inline-block' } }} required={props?.required}>
        {props?.label}
      </Label>
      {props?.helpText && <HelpToolTip {...props} />}
    </>
  ) : defaultRender ? (
    defaultRender(props)
  ) : (
    <></>
  );
};
