import React from 'react';

import { ClientSearchResult } from 'api-client';

import { SingleTagPicker, SingleTagPickerProps } from 'ui-library/tagpicker';

import { useSearchApi } from 'search/SearchApiHooks';

interface ClientPickerProps extends Omit<SingleTagPickerProps<ClientSearchResult>, 'onRenderLabel' | 'onResolveSuggestions'> {}

const ClientPicker = (props: ClientPickerProps) => {
  const { searchClient } = useSearchApi();

  return (
    <SingleTagPicker<ClientSearchResult>
      {...props}
      onResolveSuggestions={async (searchText: string) => {
        const { results = [] } = await searchClient({
          searchText,
        });

        return results.map((client) => ({
          key: `${client.clientName} (${client.id})`,
          name: `${client.clientName} (${client.id})`,
          data: client,
        }));
      }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for clients',
        suggestionsHeaderText: 'Suggested clients',
        manualTagText: 'Or create new client',
        onClickManualTag: () => window.open('/ui/app/client/create'),
      }}
      placeholder={props.placeholder || 'Type client name, Client number, NZBN or Billing name'}
    />
  );
};

export default ClientPicker;
