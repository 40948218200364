import { DetailsRow, IDetailsRowProps, IRenderFunction } from '@fluentui/react';
import * as React from 'react';
import { useTheme } from '../../theme/RRFTheme';

const renderSuccessRow = (props: IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }, callback: () => void) =>
  props ? <SuccessRow {...props} callback={callback} /> : null;

const SuccessRow = (props: IDetailsRowProps & { callback: () => void; onRenderRow?: IRenderFunction<IDetailsRowProps> }) => {
  const { semanticColors } = useTheme();
  const [recentlyCreated, setRecentlyCreated] = React.useState(true);

  setTimeout(() => {
    setRecentlyCreated(false);
    props.callback();
  }, 5000);

  const recentlyCreatedStyle = recentlyCreated
    ? {
        background: semanticColors.successBackground,
      }
    : { transition: '1s' };

  const styledProps = {
    ...props,
    styles: { root: recentlyCreatedStyle },
  };
  return props.onRenderRow ? props.onRenderRow(styledProps) : <DetailsRow {...styledProps} />;
};

export default renderSuccessRow;
