import { IDropdownOption } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { AuditFurtherActionEnum, AuditRecommendationEnum, CamundaGroupDto, CamundaUserDto, CaseTypeDto } from 'api-client';
import { AuditMethodEnum, AuditTypeEnum } from 'api-client/api';

import { Dropdown, Grid } from 'ui-library';
import { DropdownOption } from 'ui-library/dropdown';

import DatePicker from 'common/controls/inputs/DatePicker';
import MultiSelectDropdown from 'common/controls/inputs/MultiSelectDropdown';
import { AUDIT_FURTHER_ACTION_OPTIONS, CASE_RESULT, CASE_STATUS } from 'common/reference/referenceData';
import { formatDate } from 'common/utils/dateUtils';

import { CaseFinalResultEnum } from '../../../gen/api/api';

export interface SearchCaseFormProps {
  allocatedToGroupId: string | undefined;
  allocatedToUserId: number | undefined;
  allocatedToUserIds: string[] | undefined;
  auditMethods: AuditMethodEnum[] | undefined;
  auditTypes?: AuditTypeEnum[];
  caseTypeIds: number[];
  clientAppealed: boolean;

  allocatedToGroupOptions: DropdownOption<CamundaGroupDto>[];
  allocatedToUserOptions: DropdownOption<CamundaUserDto>[];
  caseTypeOptions: DropdownOption<CaseTypeDto>[];

  orderBy?: string;
  recommendations: AuditRecommendationEnum[] | undefined;
  result: CaseFinalResultEnum[];
  furtherAction: AuditFurtherActionEnum[];
  searchText?: string;
  startDateFrom: string;
  startDateTo: string;
  status: string[];
  tags: string[] | undefined;
  assigneeId?: number;
}

const SearchCaseCriteria = (props: { assigneeOptions: IDropdownOption<CamundaUserDto>[] }) => {
  const { values } = useFormikContext<SearchCaseFormProps>();
  const { caseTypeOptions = [] } = values;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={5}>
          <MultiSelectDropdown label="Case type" name="caseTypeIds" options={caseTypeOptions} />
        </Grid.Col>
        <Grid.Col lg={2}>
          <DatePicker label="Start date" name="startDateFrom" maxDate={new Date()} dateOnlyAsString />
        </Grid.Col>
        <Grid.Col lg={2}>
          <DatePicker
            label="End date"
            name="startDateTo"
            disabled={!values.startDateFrom}
            minDate={values.startDateFrom || formatDate(new Date())}
            maxDate={new Date()}
            dateOnlyAsString
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <MultiSelectDropdown label="Case status" name="status" options={CASE_STATUS} />
        </Grid.Col>
        <Grid.Col lg={5}>
          <Dropdown label="Case currently allocated to" name="assigneeId" options={props.assigneeOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <MultiSelectDropdown label="Audit/Interference Complaint result" name="result" options={CASE_RESULT} />
        </Grid.Col>
        <Grid.Col lg={5}>
          <MultiSelectDropdown label="Further action" name="furtherAction" options={AUDIT_FURTHER_ACTION_OPTIONS} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default SearchCaseCriteria;
