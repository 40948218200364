import { useFormikContext } from 'formik';
import React from 'react';

import { CaseStatusEnum } from 'api-client';

import { Grid2 } from 'ui-library';
import { SingleTagPicker } from 'ui-library/tagpicker';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { convertStatus } from 'investigation/hooks';
import { ComplaintFormProps } from 'investigation/interfaces';
import { useSearchApi } from 'search/SearchApiHooks';

const CaseOrigin = () => {
  const { mode } = useForm();
  const { values, setFieldValue } = useFormikContext<ComplaintFormProps>();
  const { searchCase } = useSearchApi();

  return (
    <SectionHeading title="Origin case" expanded={!!values.originCaseId}>
      <Grid2>
        {(mode === 'CREATE' || mode === 'EDIT') && (
          <Grid2.Col lg={8}>
            <SingleTagPicker
              label="Search case"
              name="originCaseId"
              onChange={(selectedTag) => {
                setFieldValue('originCase', selectedTag?.data);
              }}
              onResolveSuggestions={async (searchText: string) => {
                const { results = [] } = await searchCase({
                  orderBy: 'clientName',
                  searchText,
                  status: [CaseStatusEnum.CLSD],
                });

                return results.map((caseAudit) => {
                  const status = convertStatus(caseAudit.status as CaseStatusEnum);
                  return {
                    key: `${caseAudit.id} | ${caseAudit.type} | ${caseAudit.clientName} | ${status} | ${caseAudit.result}`,
                    name: `${caseAudit.id} | ${caseAudit.type} | ${caseAudit.clientName} | ${status} | ${caseAudit.result}`,
                    data: caseAudit,
                  };
                });
              }}
              pickerSuggestionsProps={{
                loadingText: 'Searching for cases',
                noResultsFoundText: 'No cases found',
                suggestionsHeaderText: 'Suggested cases',
              }}
              placeholder="Type case number or client name"
            />
          </Grid2.Col>
        )}

        <Grid2.Col lg={3} colStart={1}>
          <ReadOnlyField
            label="Case number"
            name="originCase.id"
            href={`/ui/app/investigation/${values.originCase?.typeGroup?.toLocaleLowerCase()}/view/${values.originCase?.id}`}
          />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <ReadOnlyField label="Case type" name="originCase.type" />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <ReadOnlyField label="Client name" name="originCase.clientName" />
        </Grid2.Col>

        <Grid2.Col lg={3} colStart={1}>
          <ReadOnlyField label="Status" name="originCase.status" renderFormat={convertStatus} />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <ReadOnlyField label="Result" name="originCase.result" />
        </Grid2.Col>
      </Grid2>
    </SectionHeading>
  );
};

export default CaseOrigin;
