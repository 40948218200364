import * as yup from 'yup';

import { LicenceClassificationType, LicenceDtoLicenceTypeCodeEnum, LicenceSpectrumConfigurationDto } from 'api-client';

import {
  requiredString,
  requiredValidRangeWithDecimal,
  notRequiredString,
  requiredNumber,
  requiredDecimal,
  isEmpty,
  timeSchema,
  requiredDate,
  dateSchema,
} from '../../../common/validation/yupUtils';
import {
  frequencyEmissionsSchema,
  unwantedEmissionLimitSchema,
} from '../../../licence_management/craft/components/SpectrumDetails/spectrumDetailsValidationSchema';

const freqObject = (type?: LicenceDtoLicenceTypeCodeEnum, classification?: LicenceClassificationType) => {
  const spectrumClassificationC = type === LicenceDtoLicenceTypeCodeEnum.SPL && classification === LicenceClassificationType.C;
  return yup.object({
    referenceFrequency: requiredDecimal(6, 'Frequency must be a valid number')
      .test('', 'Frequency must be a positive number', (value) => (value ? value.toString().match(/^(\d*\.)?\d+$/) !== null : true))
      .test('', 'Frequency must not exceed decimal(5,6)', (value) =>
        value ? value.toString().match(/^(?!-0(\.0+)?$)-?(0|\d{1,5})(\.\d{0,6})?$/) !== null : true,
      )
      .required('Required'),
    referenceFrequencyType: requiredString,
    power: spectrumClassificationC
      ? yup.number().optional()
      : requiredNumber.test('power', 'Power dBW (eirp) must be between -76 and 100', function (this: yup.TestContext) {
          return this.parent['power'] >= -76 && this.parent['power'] <= 100;
        }),
    powerType: spectrumClassificationC ? yup.string().optional() : requiredString,
    isoStartTime: yup
      .string()
      .nullable(true)
      .when(['hourCode'], {
        is: (hourCode) => isEmpty(hourCode),
        then: timeSchema.test('required-with-stop-time', 'Required', function (this: yup.TestContext, time) {
          return isEmpty(this.parent.isoStopTime) || !isEmpty(time);
        }),
      }),
    isoStopTime: yup
      .string()
      .nullable(true)
      .when(['hourCode'], {
        is: (hourCode) => isEmpty(hourCode),
        then: timeSchema
          .test('required-with-start-time', 'Required', function (this: yup.TestContext, time) {
            return isEmpty(this.parent.isoStartTime) || !isEmpty(time);
          })
          .test('greater-than', 'Stop time must be later than the Start time ', function (this: yup.TestContext, stopTime) {
            if (stopTime !== null && stopTime !== undefined && this.parent.isoStartTime !== null && this.parent.isoStartTime !== undefined) {
              return stopTime >= this.parent.isoStartTime;
            }
            return true;
          }),
      }),
  });
};

const referenceFrequencySchema = (type?: LicenceDtoLicenceTypeCodeEnum, classification?: LicenceClassificationType) =>
  yup
    .array()
    .of(
      freqObject(type, classification).shape({
        frequencyEmissions: frequencyEmissionsSchema,
      }),
    )
    .nullable(true);

export const referenceFrequencySchemaWithEmissions = yup
  .array()
  .min(1, 'A reference frequency must be provided')
  .of(
    freqObject().shape({
      frequencyEmissions: frequencyEmissionsSchema.min(1, 'A reference frequency must have at least one emission'),
    }),
  )
  .nullable(true);

export const spectrumRecordSchema = yup.object().shape({
  spectrumType: requiredString,
  levelOneServiceId: yup.string().when(['spectrumType'], {
    is: (spectrumType) => spectrumType !== 'ITU',
    then: requiredString,
    otherwise: notRequiredString,
  }),
  ituAllocationServiceId: yup.string().when(['spectrumType'], {
    is: (spectrumType) => spectrumType === 'ITU',
    then: requiredString,
    otherwise: notRequiredString,
  }),
  label: yup.string().when(['spectrumType'], {
    is: (spectrumType) => spectrumType === 'BND' || spectrumType === 'CHL',
    then: requiredString,
    otherwise: notRequiredString,
  }),
  lowerBound: requiredValidRangeWithDecimal(0.009, 100000, 6, 'Must be six decimal places and in the range 0.009000 - 100000.000000').test(
    'compareLowFrequency',
    'The low frequency should be less than the high frequency.',
    function (this: yup.TestContext, value) {
      const upperbound = Number.parseFloat(this.parent['upperBound']);
      if (value !== null && value !== undefined && !Number.isNaN(upperbound)) {
        return value < upperbound;
      }
      return true;
    },
  ),
  upperBound: requiredValidRangeWithDecimal(0.009, 100000, 6, 'Must be six decimal places and in the range 0.009000 - 100000.000000'),
  polarisation: yup.string().when(['spectrumType'], {
    is: (spectrumType) => spectrumType !== 'ITU' && spectrumType !== 'BND',
    then: requiredString,
    otherwise: notRequiredString,
  }),
  startDate: requiredDate,
  endDate: dateSchema.min(yup.ref('startDate'), 'End date must be later than the Start date'),
  remarks: yup.string().when(['spectrumType'], {
    is: (spectrumType) => spectrumType === 'BND' || spectrumType === 'ITU',
    then: requiredString,
    otherwise: notRequiredString,
  }),
  frequencies: yup.array().when(['spectrumType'], {
    is: (spectrumType) => spectrumType === 'CHL' || spectrumType === 'FRQ',
    then: referenceFrequencySchema,
  }),
});

export const embeddedFrqSpectrumSchema = (
  configs: LicenceSpectrumConfigurationDto,
  type?: LicenceDtoLicenceTypeCodeEnum,
  classification?: LicenceClassificationType,
): any =>
  spectrumRecordSchema.shape({
    lowerBound: requiredValidRangeWithDecimal(0.009, 100000, 6, 'Must be six decimal places and in the range 0.009000 - 100000.000000')
      .test('compareLowFrequency', 'The low frequency should be less than the high frequency.', function (this: yup.TestContext, value) {
        return +this.parent['upperBound'] !== null && +this.parent['upperBound'] !== undefined && +value! < +this.parent['upperBound'];
      })
      .test(
        'test-min-if-present',
        'The low frequency should be greater than or equal to ' + configs.minFrequency,
        function (this: yup.TestContext, value) {
          return configs.minFrequency === null || +value! >= configs.minFrequency!;
        },
      ),
    upperBound: requiredValidRangeWithDecimal(0.009, 100000, 6, 'Must be six decimal places and in the range 0.009000 - 100000.000000').test(
      'test-max-if-present',
      'The high frequency should be lower than ' + configs.maxFrequency,
      function (this: yup.TestContext, value) {
        return configs.maxFrequency === null || +value! < configs.maxFrequency!;
      },
    ),
    frequencies:
      type === LicenceDtoLicenceTypeCodeEnum.SPL && classification === LicenceClassificationType.C
        ? referenceFrequencySchema(type, classification)
        : referenceFrequencySchemaWithEmissions,
    unwantedEmissionLimits: yup.array().of(unwantedEmissionLimitSchema).nullable(true),
  });
