import * as React from 'react';
import { Switch, useHistory } from 'react-router-dom';

import { TemplateSearchCriteria } from 'api-client';

import ProtectedRoute from 'common/auth/ProtectedRoute';
import { stringifyQueryObject } from 'search/utils';

const MaintainTemplatePage = React.lazy(() => import('./MaintainTemplatePage'));
const CorrespondenceDetailsPage = React.lazy(() => import('./correspondence_details/CorrespondenceDetailsPage'));
const SearchTemplatePage = React.lazy(() => import('./search/SearchTemplatePage'));

export const CORRESPONDENCE_TEMPLATE_URL = '/ui/app/correspondence/template/';

export const useCorrespondenceNavigation = () => {
  const history = useHistory();

  return {
    navigateToSearchTemplates: (criteria?: TemplateSearchCriteria, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/correspondence/search-templates?${stringifyQueryObject(criteria)}`)
        : history.push(`/ui/app/correspondence/search-templates?${stringifyQueryObject(criteria)}`),
    navigateToMaintainTemplate: (templateName: string, futureTemplate: boolean) =>
      history.push(`/ui/app/correspondence/template/${templateName}/maintain?futureTemplate=${futureTemplate ? 'true' : 'false'}`),
    navigateToCorrespondenceDetails: (correspondenceId: number) => history.push(`/ui/app/correspondence/correspondence-details/${correspondenceId}`),
  };
};

const CorrespondenceRouter: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path={'/ui/app/correspondence/search-templates'} component={SearchTemplatePage} role={['ROLE_SYSTEM_ADMINISTRATOR']} />
      <ProtectedRoute
        path={'/ui/app/correspondence/template/:templateName/maintain'}
        component={MaintainTemplatePage}
        role={['ROLE_SYSTEM_ADMINISTRATOR']}
      />
      <ProtectedRoute path={'/ui/app/correspondence/correspondence-details/:correspondenceId'} component={CorrespondenceDetailsPage} />
    </Switch>
  );
};

export default CorrespondenceRouter;
