import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FileNoteDtoEntityTypeEnum, SpectrumMaskDto } from 'api-client';

import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { useAuth } from 'common/auth/AuthHook';
import { COMMON_FILE_NOTE_TYPE } from 'common/reference/referenceData';

import { hasAction } from '../../../../common/api/hateosUtils';
import Form from '../../../../common/form/Form';
import Page from '../../../../common/layout/Page';
import EventLogSection from '../../../../ui-library/shared/EventLogSection';
import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import { convertSpectrumMaskPointToString, replaceGraphPointIndex } from '../utils';
import { spectrumMaskSchema } from '../validationSchema';
import UpdateSpectrumMaskFields from './UpdateSpectrumMaskFields';

const UpdateSpectrumMaskPage = () => {
  const { updateSpectrumMask, getSpectrumMask } = useReferenceDataHookApi();
  const { spectrumMaskId } = useParams<{ spectrumMaskId: string }>();
  const [spectrumMask, setSpectrumMask] = useState<SpectrumMaskDto>();
  const { hasPermission } = useAuth();

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  useEffect(() => {
    getSpectrumMask(+spectrumMaskId).then(setSpectrumMask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spectrumMaskId]);

  const submitForm = (values: any) =>
    updateSpectrumMask(replaceGraphPointIndex(values), {
      successMessage: 'Spectrum Mask has been updated.',
    }).then(setSpectrumMask);
  return (
    <Page title="Update Spectrum Mask">
      {spectrumMask ? (
        <Formik
          initialValues={convertSpectrumMaskPointToString(spectrumMask)}
          onSubmit={submitForm}
          validationSchema={spectrumMaskSchema}
          enableReinitialize
          validateOnBlur
          validateOnChange
        >
          <Form
            id={'update-spectrum-mask-form'}
            showFormButtonsBottom
            canEdit={hasAction('maintain-spectrum-mask', spectrumMask.links)}
            mode={'VIEW'}
            disabledSubmitWhenNoDirty
          >
            <UpdateSpectrumMaskFields />
            {canViewFileNote && (
              <FileNoteSection entityId={+spectrumMaskId} entityType={FileNoteDtoEntityTypeEnum.SPECTRUM_MASK} noteTypes={COMMON_FILE_NOTE_TYPE} />
            )}
            <EventLogSection entityName={'SPECTRUM_MASK'} entityId={+spectrumMaskId} entityToReloadOn={spectrumMask} />
          </Form>
        </Formik>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default UpdateSpectrumMaskPage;
