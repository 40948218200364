import { DefaultButton, IButtonProps, IButtonStyles } from '@fluentui/react';
import * as React from 'react';

interface Props extends IButtonProps {}

const WarningButton: React.FC<Props> = (props) => {
  const styles: IButtonStyles = {
    root: { backgroundColor: '#ff6900' },
    rootHovered: { backgroundColor: '#fc8332' },
  };

  return <DefaultButton {...props} styles={styles} />;
};

export default WarningButton;
