import * as React from 'react';

import { SelectedServiceLevels } from 'api-client';

import { useAuth } from '../../common/auth/AuthHook';
import Form from '../../common/form/Form';
import ClientDetailsSection from '../common/components/ClientDetailsSection';
import { ApplyLicencePageContext } from './ApplyLicencePage';
import AirCraftDetailsSection from './components/AirCraftDetailsSection';
import ApplicationDetailsForLADecision from './components/ApplicationDetailsForLADecision';
import BasicApplicationDetailsSection from './components/BasicApplicationDetailsSection';
import LandSimplexDetailsSection from './components/LandSimplexDetailsSection';
import ShipDetailsSection from './components/ShipDetailsSection';
import SatelliteDetailsSection from './components/satellite/SatelliteDetailsSection';

interface Props {
  selectedServiceLevels?: SelectedServiceLevels;
  initialSelectedApplicant?: number;
  satelliteDraftDone?: boolean;
}

const ApplyLicenceForm = (props: Props) => {
  const { selectedLicenceType } = React.useContext(ApplyLicencePageContext);
  const shipType = selectedLicenceType?.code === 'M2' || selectedLicenceType?.code === 'M3';
  const satelliteType = selectedLicenceType?.description === 'Satellite';
  const { hasRole } = useAuth();
  const isClientSearchVisible =
    hasRole('ROLE_SYSTEM_ADMINISTRATOR') ||
    hasRole('ROLE_LICENSING_OFFICER') ||
    hasRole('ROLE_RSM_RADIO_ENGINEER') ||
    hasRole('ROLE_LICENSING_MANAGER') ||
    hasRole('ROLE_APPROVED_RADIO_ENGINEER') ||
    hasRole('ROLE_APPROVED_RADIO_CERTIFIER') ||
    hasRole('ROLE_APPROVED_RADIO_EXAMINER');
  return (
    <Form
      id="apply-licence-form"
      showFormButtonsBottom
      submitButtonText={satelliteType && !props.satelliteDraftDone ? 'Save' : 'Submit'}
      mode="CREATE"
    >
      <ClientDetailsSection clientSearchVisible={isClientSearchVisible} initialApplicant={props.initialSelectedApplicant} />
      <BasicApplicationDetailsSection selectedServiceLevels={props.selectedServiceLevels} />
      {selectedLicenceType && selectedLicenceType.laDecision && <ApplicationDetailsForLADecision />}
      {selectedLicenceType && selectedLicenceType.description === 'Aircraft' && <AirCraftDetailsSection />}
      {selectedLicenceType && selectedLicenceType.description === 'Land Simplex - General Channels' && <LandSimplexDetailsSection />}
      {satelliteType && <SatelliteDetailsSection />}
      {shipType && <ShipDetailsSection />}
    </Form>
  );
};

export default ApplyLicenceForm;
