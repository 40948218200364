import { DefaultButton } from '@fluentui/react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

interface BackButtonProps {
  id?: string;
  onBack?: () => void;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const history = useHistory();

  return (
    <DefaultButton
      text="Back"
      onClick={() => {
        if (props.onBack) {
          props.onBack();
        } else {
          history.goBack();
        }
      }}
      id={props.id ?? 'back-btn'}
      data-automation-id={props.id ?? 'back-btn'}
    />
  );
};

export default BackButton;
