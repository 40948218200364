import { MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { AuditDto, AuditFurtherActionEnum } from 'api-client';

import { useAppRouter } from '../../../Routes';
import usePage from '../../../common/layout/PageHook';
import { Form } from '../../../ui-library';
import { useAuditApi } from '../hooks';

type EmailClientSentFormProps = {
  id: number;
  onActionCallback: (auditDto: AuditDto) => void;
};

const ActionCardEmailClientSent = (audit: EmailClientSentFormProps) => {
  const { confirmEmailSentApi } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  const handleSubmit = (values: EmailClientSentFormProps) => {
    confirmEmailSentApi(values.id).then((returnedAudit) => {
      if (returnedAudit.furtherAction === AuditFurtherActionEnum.SATISFACTORY) {
        navigateToDashboard();
        setPageInstruction('Case successfully closed.', MessageBarType.success);
      } else {
        audit.onActionCallback(returnedAudit);
      }
    });
  };

  return (
    <Form<EmailClientSentFormProps>
      formButtonsOptions={{
        hideBackButton: true,
        hideCancelButton: true,
        hideSubmitButton: true,
      }}
      handleSubmit={handleSubmit}
      hideFormButtonsTop
      id="confirmEmailSentActionCard"
      isInDialog
      initialValues={audit}
      mode="CREATE"
      showFormButtonsBottom
    >
      <ActionCardEmailClientSentContent />
    </Form>
  );
};
const ActionCardEmailClientSentContent = () => {
  const { submitForm } = useFormikContext<any>();

  return (
    <MessageBar
      data-automation-id={`audit-email-client-message-bar`}
      messageBarType={MessageBarType.warning}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="audit-email-client-action" onClick={() => submitForm()}>
              Confirm
            </PrimaryButton>
          </div>
        </>
      }
    >
      <Text>Please confirm an email has been sent to the client.</Text>
    </MessageBar>
  );
};

export default ActionCardEmailClientSent;
