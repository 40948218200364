import { ChoiceGroup as FabricChoiceGroup, IChoiceGroupOption, IChoiceGroupProps, IChoiceGroupOptionStyles } from '@fluentui/react';
import { FieldAttributes, useField } from 'formik';
import * as React from 'react';

import { useForm } from '../../form/FormHook';
import { yesNo } from '../../reference/referenceData';
import TooltipHint from '../items/TooltipHint';
import ErrorLabel from './ErrorLabel';
import ReadOnlyField, { renderAsOptions } from './ReadOnlyField';

export interface IChoiceGroupOptionHint extends IChoiceGroupOption {
  hint?: string;
}

export interface Props extends Omit<IChoiceGroupProps, 'checked' | 'onBlur' | 'onChange'> {
  options?: IChoiceGroupOptionHint[];
  onChoiceChange?: (option?: IChoiceGroupOption) => void;
  direction?: 'row' | 'column';
  errorMessage?: string;
  readOnly?: boolean;
  alwaysValidate?: boolean;
}

const rowStyle: IChoiceGroupOptionStyles = { choiceFieldWrapper: { marginRight: '10px' } };

const columnStyle: IChoiceGroupOptionStyles = {
  root: { cursor: 'initial' },
  choiceFieldWrapper: { float: 'left', clear: 'both', position: 'relative' },
};

const onRenderToolTip = (props: IChoiceGroupOptionHint, onRenderLabelDefault: any) => (
  <div>
    {onRenderLabelDefault!(props)}
    <span style={{ marginLeft: '2px' }}>&nbsp;</span>
    <TooltipHint id={`tooltip-hint-${props.key}`} content={props.hint} style={{ lineHeight: '20px' }} />
  </div>
);

const ChoiceGroup: React.FC<FieldAttributes<Props>> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [field, _, fieldHelper] = useField<any>(props);
  const { mode } = useForm();
  const options =
    props.direction === 'row'
      ? props.options?.map((opt) => ({
          ...opt,
          styles: rowStyle,
          onRenderLabel: opt.onRenderLabel ? opt.onRenderLabel : opt.hint ? onRenderToolTip : undefined,
        }))
      : props.options?.map((opt) => ({
          ...opt,
          onRenderLabel: opt.onRenderLabel ? opt.onRenderLabel : opt.hint ? onRenderToolTip : undefined,
          styles: columnStyle,
        }));
  const styles = props.direction === 'row' ? { flexContainer: { flexDirection: 'row', display: 'flex' } } : { display: 'table' };
  const handleOnChange = (_?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    fieldHelper.setValue(option?.key);
    if (props.onChoiceChange) {
      props.onChoiceChange(option);
    }
  };

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField {...props} renderFormat={renderAsOptions(props.options)} />
  ) : (
    <>
      <FabricChoiceGroup
        {...props}
        {...field}
        options={options as IChoiceGroupOption[]}
        onChange={handleOnChange}
        selectedKey={field.value || props.selectedKey}
        styles={styles}
        disabled={mode === 'DISABLED' || props.disabled}
      />
      <ErrorLabel {...props} />
    </>
  );
};

export default ChoiceGroup;

export const YesNoChoiceGroup: React.FC<FieldAttributes<Props>> = (props) => <ChoiceGroup {...props} options={yesNo} direction={'row'} />;
