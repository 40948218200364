import * as yup from 'yup';

import { isDigits, numberSchema, requiredNumber, requiredString, requiredValidRange, timeSchema } from '../../../../common/validation/yupUtils';
import { referenceFrequencySchemaWithEmissions } from '../../../reference_data/spectrum_record/validationSchema';

const satelliteTransmitLocationSchema = yup.object().shape({
  id: requiredNumber,
});

const satelliteReceiveLocationSchema = yup.object().shape({
  id: requiredNumber,
  noiseTemperature: numberSchema
    .notRequired()
    .test('digits', 'Please enter a digit number, decimal are not allowed', (value) => value == null || isDigits('' + value)),
});

export const satelliteTransmitLocationsSchema = yup.object().shape({
  locations: yup.array().nullable(true).of(satelliteTransmitLocationSchema),
});

export const satelliteReceiveLocationsSchema = yup.object().shape({
  locations: yup.array().nullable(true).of(satelliteReceiveLocationSchema),
});

const minValidationFrequency = yup.object().shape(
  {
    referenceFrequency: yup
      .number()
      .nullable(true)
      .when(['referenceFrequencyType', 'power', 'powerType'], {
        is: (referenceFrequencyType, power, powerType) => !!referenceFrequencyType || !!power || !!powerType,
        then: requiredNumber,
      }),
    referenceFrequencyType: yup
      .string()
      .nullable(true)
      .when(['referenceFrequency', 'power', 'powerType'], {
        is: (referenceFrequency, power, powerType) => !!referenceFrequency || !!power || !!powerType,
        then: requiredString,
      }),
    power: yup
      .number()
      .nullable(true)
      .when(['referenceFrequencyType', 'referenceFrequency', 'powerType'], {
        is: (referenceFrequencyType, referenceFrequency, powerType) => !!referenceFrequencyType || !!referenceFrequency || !!powerType,
        then: requiredNumber,
      }),
    powerType: yup
      .string()
      .nullable(true)
      .when(['referenceFrequencyType', 'power', 'referenceFrequency'], {
        is: (referenceFrequencyType, power, referenceFrequency) => !!referenceFrequencyType || !!power || !!referenceFrequency,
        then: requiredString,
      }),
    isoStartTime: timeSchema,
    isoStopTime: timeSchema,
  },
  [
    ['referenceFrequency', 'referenceFrequencyType'],
    ['referenceFrequency', 'power'],
    ['referenceFrequency', 'powerType'],
    ['referenceFrequencyType', 'power'],
    ['referenceFrequencyType', 'powerType'],
    ['power', 'powerType'],
  ],
);

export const satelliteSpectrumDetailsSchema = yup.object().shape({
  spectrum: yup.object().shape({
    lowerBound: requiredNumber,
    upperBound: requiredNumber,
    frequencies: yup.array().nullable(true).of(minValidationFrequency),
  }),
});

export const spectrumDoneSchema = yup.object().shape({
  lowerBound: requiredNumber,
  upperBound: requiredNumber,
  polarisation: requiredString,
  frequencies: referenceFrequencySchemaWithEmissions,
});

export const satelliteSpectrumsDoneSchema = yup.array().of(spectrumDoneSchema);

const locationCommonDoneSchema = yup.object().shape({
  id: requiredNumber,
  stationType: requiredString,
  antenna: yup.object().nullable(true).required('Required'),
  antennaPattern: requiredString,
  antennaPatternOther: yup
    .string()
    .nullable(true)
    .when(['antennaPattern'], {
      is: (pattern: string) => pattern === 'Other',
      then: requiredString,
    }),
});

export const transmitLocationDoneSchema = locationCommonDoneSchema;

export const receiveLocationDoneSchema = locationCommonDoneSchema.shape({
  noiseTemperature: requiredValidRange(50, 10000, 'Must be Kelvin units in the range 50 to 10000').test(
    'digits',
    'Please enter a digit number, decimal are not allowed',
    (value) => isDigits('' + value),
  ),
});

export const satelliteTransmitLocationsDoneSchema = yup.object().shape({
  locations: yup.array().nullable(true).of(transmitLocationDoneSchema),
});

export const satelliteReceiveLocationsDoneSchema = yup.object().shape({
  locations: yup.array().nullable(true).of(receiveLocationDoneSchema),
});

export const satelliteTransmitLocationAllDoneSchema = yup.object().shape({
  spectrum: spectrumDoneSchema,
  locations: yup.array(transmitLocationDoneSchema),
});

export const satelliteReceiveLocationAllDoneSchema = yup.object().shape({
  spectrum: spectrumDoneSchema,
  locations: yup.array(receiveLocationDoneSchema),
});
