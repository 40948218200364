import * as yup from 'yup';

import { getValueByPath } from 'common/utils/objectUtils';
import { notRequiredString, notRequiredNumber, requiredString } from 'common/validation/yupUtils';

const requiredWholeNumberInRange = (range: number[], message: string) =>
  yup
    .string()
    .nullable(true)
    .required('Required')
    .test('value check', '', function () {
      const value = getValueByPath(this.options.context, this.path);
      if (/^\d+$/.test(value)) {
        const numberVal = Number.parseInt(value);
        if (numberVal >= range[0] && numberVal <= range[1]) {
          return true;
        }
      }
      return this.createError({ message });
    });

export const atonValidationSchema = yup.object().shape({
  clientPicker: requiredString,

  type: requiredString,

  matonName: yup.string().when(['type'], {
    is: (type: string) => type === 'MAtoN',
    then: requiredString,
    otherwise: notRequiredString,
  }),

  locationId: yup.string().when(['type'], {
    is: (type: string) => type !== 'MAtoN',
    then: requiredString,
    otherwise: notRequiredString,
  }),

  accessMode: requiredString,

  ais1StartSlot: yup.string().when(['accessMode'], {
    is: (accessMode: string) => accessMode === 'FATDMA',
    then: requiredWholeNumberInRange([0, 2249], 'AIS1 Start Slot must be an integer between 0 and 2249.'),
    otherwise: notRequiredString,
  }),

  ais2StartSlot: yup.string().when(['accessMode'], {
    is: (accessMode: string) => accessMode === 'FATDMA',
    then: requiredWholeNumberInRange([0, 2249], 'AIS2 Start Slot must be an integer between 0 and 2249.').test(
      'check ais1StartSlot and  ais2StartSlot are equal',
      'AIS2 must be different from AIS1.',
      function () {
        const ais1StartSlot = this.parent.ais1StartSlot;
        const ais2StartSlot = this.parent.ais2StartSlot;
        return !(ais1StartSlot === ais2StartSlot);
      },
    ),
    otherwise: notRequiredString,
  }),

  mmsi: notRequiredNumber,
});

export const atonCancelValidationSchema = yup.object().shape({
  reason: yup.string().required(`Cancel AtoN reason is required and must be within 400 characters.`),
});
