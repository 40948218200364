import { Label } from '@fluentui/react';
import React, { useState } from 'react';

import { GeoreferenceTypeEnum, GeoreferenceGroupDto } from 'api-client';

import { Grid } from 'ui-library';

import ReadOnlyData from 'common/controls/items/ReadonlyData';
import { MoreOrLessBtn } from 'common/controls/surfaces/MoreOrLess';
import { useMedia } from 'common/layout/MediaHook';
import { validFormatEastingNorthing, formatEastingNorthing } from 'common/utils/locationUtils';
import GeoreferenceGroupPanel from 'licence_management/reference_data/location/components/GeoreferenceGroupPanel';

interface GeoreferenceProps {
  showDetails?: boolean;
  hideLabel?: boolean;
  showOrder?: boolean;
  georeferenceGroup: GeoreferenceGroupDto;
  index?: number;
}

const LocationGeoreferenceDetail = (props: GeoreferenceProps) => {
  const type = props.georeferenceGroup.origin?.type;
  const showMapNumber = type === GeoreferenceTypeEnum.T || type === GeoreferenceTypeEnum.M;
  const isLatLong = type === GeoreferenceTypeEnum.D2000;

  const { isMobile } = useMedia();

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <Grid.Row>
        {props.showOrder && (
          <Grid.Col lg={1}>
            {isMobile && <Label>Point</Label>}
            <ReadOnlyData label={props.hideLabel ? '' : 'Order'} value={props.georeferenceGroup.origin?.order} />
          </Grid.Col>
        )}
        {showMapNumber && (
          <Grid.Col sm={4} lg={2}>
            <ReadOnlyData label={props.hideLabel ? '' : 'Map'} value={props.georeferenceGroup.origin?.mapNumber!} />
          </Grid.Col>
        )}
        <Grid.Col sm={showMapNumber ? 4 : 6} lg={3}>
          <ReadOnlyData
            label={props.hideLabel ? '' : isLatLong ? 'Latitude' : 'Easting'}
            value={
              isLatLong
                ? formatEastingNorthing(props.georeferenceGroup.origin?.northing, props.georeferenceGroup.origin?.type)
                : formatEastingNorthing(props.georeferenceGroup.origin?.easting, props.georeferenceGroup.origin?.type)
            }
            renderFormat={validFormatEastingNorthing(true, type)}
          />
        </Grid.Col>
        <Grid.Col sm={showMapNumber ? 4 : 6} lg={3}>
          <ReadOnlyData
            label={props.hideLabel ? '' : isLatLong ? 'Longitude' : 'Northing'}
            value={
              isLatLong
                ? formatEastingNorthing(props.georeferenceGroup.origin?.easting, props.georeferenceGroup.origin?.type)
                : formatEastingNorthing(props.georeferenceGroup.origin?.northing, props.georeferenceGroup.origin?.type)
            }
            renderFormat={validFormatEastingNorthing(false, type)}
          />
        </Grid.Col>

        <Grid.Col sm={7} lg={3}>
          {!props.hideLabel && <Label>&nbsp;</Label>}
          <MoreOrLessBtn moreText="Show all Geo ref types" lessText="Hide all Geo ref types" more={showDetails} setShowMore={setShowDetails} />
        </Grid.Col>
      </Grid.Row>
      {showDetails && <GeoreferenceGroupPanel georeferenceGroup={props.georeferenceGroup} hideOriginal={false} originalValue={true} />}
    </>
  );
};

export default LocationGeoreferenceDetail;
