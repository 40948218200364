import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { CaseStatusEnum, InfringementFeeDtoTypeEnum, OffenceDto } from 'api-client';

import { Grid2 } from 'ui-library';

import InlineButton from 'common/controls/buttons/InlineButton';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatDateFromISO, formatDateTimeFromISO } from 'common/utils/dateUtils';
import { doOpenFile } from 'common/utils/download';
import { useAuditApi } from 'investigation/audit/hooks';
import { AuditFormProps } from 'investigation/interfaces';

import { hasAction } from '../../../common/api/hateosUtils';
import { useInvestigationApi } from '../../hooks';

interface Props {
  setIsWithdrawalDialogOpen?: (isOpen: boolean) => void;
}

const AuditInfringementSection = (props: Props) => {
  const { values } = useFormikContext<AuditFormProps>();
  const { getInfringementNotice } = useAuditApi();

  const { getOffences } = useInvestigationApi();
  const [availableOffences, setAvailableOffences] = useState<OffenceDto[]>();

  useEffect(() => {
    getOffences().then(setAvailableOffences);
  }, []);

  const handlePdfNoticeGenerate = async (infringementId: number) => {
    const pdf = await getInfringementNotice(infringementId, { showLoadingSpinner: true });
    doOpenFile(new Blob([pdf], { type: 'application/pdf' }), `schedule_5_infringement_notice.pdf`);
  };

  const caseOffences: { offenceId: number; otherDesc?: string }[] | undefined = values.offences
    ? Object.keys(values.offences)
        .filter((key) => (values.offences ? values.offences[key] : undefined))
        .map((key) => ({
          offenceId: Number(key),
          otherDesc: values.offenceOther
            ? Object.keys(values.offenceOther)
                .filter((otherKey) => otherKey === key)
                .map((otherKey) => (values.offenceOther ? values.offenceOther[otherKey] : undefined))
                .at(0)
            : undefined,
        }))
    : undefined;

  return (
    <>
      <SectionHeading title="Infringement" />
      <Grid2>
        <Grid2.Col lg={9}>
          <span>Infringement(s)</span>
          <ul>
            {values.infringement?.infringementFees?.map((infringementFee) => {
              const typeLabel = infringementFee.type === InfringementFeeDtoTypeEnum.INDIVIDUAL ? 'Individual' : 'Body Corporate';
              return (
                <li style={{ margin: '10px 0', fontWeight: 'bold' }} key={infringementFee.id}>
                  {`${typeLabel} - ${infringementFee.offenceShortDesc}`}
                </li>
              );
            })}
          </ul>
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <Grid2>
            {values.client?.type.toUpperCase() === 'PERSON' && (
              <Grid2.Col>
                <ReadOnlyField label="Birth date" name="infringement.clientBirthDate" renderFormat={formatDateFromISO} />
              </Grid2.Col>
            )}
            <Grid2.Col>
              <ReadOnlyField label="Infringement location" name="infringement.location" />
            </Grid2.Col>
            <Grid2.Col>
              <ReadOnlyField label="Infringement date" name="infringement.infringementDate" renderFormat={formatDateTimeFromISO} />
            </Grid2.Col>
          </Grid2>
        </Grid2.Col>
        <Grid2.Col colStart={10}>
          <Grid2 styles={{ width: 200 }}>
            {values.statusEnum === CaseStatusEnum.INFRINGE && (
              <>
                <Grid2.Col lg={1}>
                  <InlineButton
                    id={`generate-pdf-notice-${values.infringement?.id}`}
                    data-automation-id={`generate-pdf-notice-${values.infringement?.id}`}
                    text="View"
                    onClick={() => handlePdfNoticeGenerate(values.infringement?.id || 0)}
                  />
                </Grid2.Col>
                {values.infringement && !values.infringement.paidDate && hasAction('withdraw-manually', values?.links) && (
                  <Grid2.Col lg={1}>
                    <InlineButton
                      id={`withdraw-${values.infringement?.id}`}
                      data-automation-id={`withdraw-${values.infringement?.id}`}
                      text="Withdraw"
                      onClick={() => props.setIsWithdrawalDialogOpen && props.setIsWithdrawalDialogOpen(true)}
                    />
                  </Grid2.Col>
                )}
              </>
            )}
          </Grid2>
        </Grid2.Col>
      </Grid2>
      {values.infringement && values.infringement.withdrawalReason && (
        <Grid2>
          <Grid2.Col lg={3}>
            <ReadOnlyField label="Withdrawal date" name="infringement.withdrawalDate" renderFormat={formatDateFromISO} />
          </Grid2.Col>
          <Grid2.Col lg={9}>
            <ReadonlyData label="Reason for withdrawal" value={values.infringement.withdrawalReason} multiline={true} />
          </Grid2.Col>
        </Grid2>
      )}
      {values.infringement && values.infringement.paidDate && (
        <Grid2>
          <Grid2.Col lg={3}>
            <ReadOnlyField label="Invoice paid date" name="infringement.paidDate" renderFormat={formatDateFromISO} />
          </Grid2.Col>
        </Grid2>
      )}
      {caseOffences && caseOffences.length > 0 && (
        <Grid2.Col lg={9}>
          <span>Offence(s)</span>
          <ul>
            {caseOffences.map((caseOffence) => {
              const offenceLabel = availableOffences
                ? availableOffences
                    .filter((offence) => offence.id === caseOffence.offenceId)
                    .map((offence) => offence.description)
                    .toString()
                : '';
              return (
                <li style={{ margin: '10px 0', fontWeight: 'bold' }} key={caseOffence.offenceId}>
                  {`${offenceLabel} ${caseOffence.otherDesc ? ' - ' + caseOffence.otherDesc : ''}`}
                </li>
              );
            })}
          </ul>
        </Grid2.Col>
      )}
    </>
  );
};

export default AuditInfringementSection;
