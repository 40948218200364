import { data, HtmlMarker } from 'azure-maps-control';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';

import { LocationSearchCriteria, LocationSearchResult, LocationSearchResults, GeoreferenceDto } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import { IGeolocationControl, GeolocationProperties } from 'common/controls/map/GeolocationControl';
import { getPreferenceGeoReferenceType } from 'common/user/components/UserPreference';
import { isEmpty } from 'common/utils/objectUtils';
import { isNotEmpty } from 'common/validation/yupUtils';

import { validateWithFormValues } from '../../common/validation/validationWithFormValues';
import { useSearchApi } from '../SearchApiHooks';
import { SEARCH_FORM_MODE } from '../SearchForm';
import { useSearchNavigation } from '../SearchRouter';
import { searchResultsFocus, toSortProps } from '../utils';
import LocationSearchMap from './LocationSearchMap';
import SearchLocationForm, { LocationSearchOptions } from './SearchLocationForm';
import SearchLocationResults from './SearchLocationResults';
import validationSchema, { isNotEmptyGeoreference } from './validationSchema';

interface Props {
  id?: string;
  initialSearchCriteria?: any;
  defaultSearchCriteria?: any;
  mode: SEARCH_FORM_MODE;
  onSelect?: (item: LocationSearchResult) => void;
  searchLabel?: string;
  searchOptions?: LocationSearchOptions;
  mapEnabled?: boolean;
  showMap?: boolean;
}

const SearchLocation: React.FC<Props> = ({
  defaultSearchCriteria,
  initialSearchCriteria,
  mode,
  onSelect,
  searchLabel,
  id,
  searchOptions,
  mapEnabled,
  showMap,
}) => {
  const { navigateToSearchLocation } = useSearchNavigation();
  const { searchLocation } = useSearchApi();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [searchResults, setSearchResults] = useState<LocationSearchResults>();
  const [searchCriteria, setSearchCriteria] = useState<LocationSearchCriteria>({});
  const [mapVisible, setMapVisible] = useState<boolean>(showMap || false);
  const [mapGeoref, setMapGeoref] = useState<GeoreferenceDto | undefined>(undefined);
  const geolocationControl = React.useRef<IGeolocationControl>();
  const { userPreference } = useAuth();
  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  const handlePaging = (page: number, pageSize: number) => {
    let paging = {
      ...(searchCriteria ?? defaultSearchCriteria),
      georeference: isNotEmptyGeoreference(searchCriteria.georeference) ? searchCriteria.georeference : undefined,
      page,
      pageSize,
    };

    if (mode === 'EMBEDDED') {
      setSearchCriteria(paging);
      searchLocation(paging).then((response) => setSearchResults(response));
    } else {
      navigateToSearchLocation(paging, true);
    }
  };

  const handleSorting = (orderBy: string) => {
    let sort = {
      ...(searchCriteria ?? {}),
      georeference: isNotEmptyGeoreference(searchCriteria.georeference) ? searchCriteria.georeference : undefined,
      orderBy,
    };

    if (mode === 'EMBEDDED') {
      setSearchCriteria(sort);
      searchLocation(sort).then((response) => {
        setSearchResults(response);
        setSubmitted(true);
        searchResultsFocus();
        return response;
      });
    } else {
      navigateToSearchLocation(sort, true);
    }
  };

  const handleClear = () => {
    setSearchCriteria(defaultSearchCriteria);
    setSearchResults(undefined);
    setSubmitted(false);
    setMapGeoref(undefined);
    if (geolocationControl.current) {
      geolocationControl.current.hidePin();
    }
  };

  const handleSelect = (item: LocationSearchResult) => {
    onSelect && onSelect(item);
    setSubmitted(false);
  };

  const handleMapToggle = (formProps: FormikHelpers<any>, values: any) => {
    // refer to new state in variable to avoid confusion with stale (not yet updated/refreshed) state
    let newMapVisible = !mapVisible;
    setMapVisible(newMapVisible);

    if (mode === 'STAND_ALONE') {
      formProps.setSubmitting(true);
      setSearchCriteria(values);
      // Required to rewrite the query string to remember the search for browser back navigation
      let savedCrit = {
        ...(searchCriteria ?? {}),
        ...(values ?? {}),
        mapVisible: newMapVisible,
      };
      navigateToSearchLocation(savedCrit, true);
    }
  };

  /** save geoloc ref for later use */
  const geolocInitCallback = (glc: IGeolocationControl, supported: boolean) => {
    geolocationControl.current = glc;
  };

  /** map has set pin, update form georef criteria */
  const geolocPositionCallback = (position: data.Feature<data.Point, GeolocationProperties>, marker?: HtmlMarker) => {
    if (position && position.geometry && position.geometry.coordinates && position.geometry.coordinates.length >= 2) {
      setMapGeoref({
        mapNumber: '',
        easting: position.geometry.coordinates[0].toFixed(7),
        northing: position.geometry.coordinates[1].toFixed(7),
        type: 'D2000',
      } as any as GeoreferenceDto);
    }
  };

  /** */
  const geolocMarkerRemovedCallback = () => {
    setMapGeoref(undefined);
  };

  /** */ const updateMapPin = (resp: LocationSearchResults) => {
    if (resp && geolocationControl.current && resp.mapSearchGeoref) {
      geolocationControl.current.setPosition(
        [Number.parseFloat(resp.mapSearchGeoref.easting), Number.parseFloat(resp.mapSearchGeoref.northing)],
        true,
      );
    }
  };

  const handleSearch =
    mode === 'STAND_ALONE'
      ? undefined
      : (values: any, formProps: FormikHelpers<any>) => {
          formProps.validateForm(values).then((errors) => {
            formProps.setErrors(errors);
            if (isEmpty(errors)) {
              return handleSubmit(values);
            }
          });
        };

  const handleSubmit = (formValues: LocationSearchCriteria) => {
    if (mode === 'STAND_ALONE') {
      // Required to rewrite the query string to remember the search for browser back navigation
      navigateToSearchLocation(formValues, true);
    }

    const searchCriteria: LocationSearchCriteria = {
      ...formValues,
      georeferenceType: isNotEmpty(formValues.georeferenceType) ? formValues.georeferenceType : geoRefTypeUserPreference,
      georeference: isNotEmptyGeoreference(formValues.georeference) ? formValues.georeference : undefined,
      orderBy: formValues.orderBy ?? 'name asc',
    };

    return searchLocation(searchCriteria, { showLoadingSpinner: 'Searching...' }).then((searchResults) => {
      setSearchCriteria({
        ...formValues,
        georeference: isNotEmptyGeoreference(formValues.georeference) ? formValues.georeference : undefined,
        orderBy: searchResults.orderBy ?? formValues.orderBy,
      });
      setSearchResults(searchResults);
      setSubmitted(true);
      updateMapPin(searchResults);
      return searchResults;
    });
  };

  //------------------------------------------------------------
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Col lg={mapVisible && mapEnabled ? 6 : 12}>
            <Formik
              initialValues={initialSearchCriteria}
              onSubmit={handleSubmit}
              validate={validateWithFormValues(validationSchema)}
              enableReinitialize
              validateOnBlur
            >
              {(formProps) => (
                <SearchLocationForm
                  id={id ?? 'search-location-form'}
                  onSearch={handleSearch ? (values) => handleSearch(values, formProps) : undefined}
                  onClear={handleClear}
                  showAddLocationButton={mode !== 'EMBEDDED' && submitted}
                  mode={mode}
                  searchLabel={searchLabel}
                  searchOptions={searchOptions}
                  onMapToggle={(values: any) => handleMapToggle(formProps, values)}
                  mapEnabled={mapEnabled}
                  mapVisible={mapVisible}
                  mapGeoref={mapGeoref}
                />
              )}
            </Formik>
          </Grid.Col>
          {mapEnabled && mapVisible && (
            <Grid.Col lg={6}>
              <LocationSearchMap
                searchData={searchResults?.mapFeatureCollection}
                geolocInitCallback={geolocInitCallback}
                geolocPositionCallback={geolocPositionCallback}
                geolocMarkerRemovedCallback={geolocMarkerRemovedCallback}
              />
            </Grid.Col>
          )}
        </Grid.Row>
      </Grid>

      {submitted && (
        <SearchLocationResults
          onPaging={handlePaging}
          onSorting={({ sortKey, descending }) => handleSorting(sortKey + (descending ? ' desc' : ' asc'))}
          searchResults={searchResults}
          searchCriteria={searchCriteria}
          sortProps={toSortProps(initialSearchCriteria.orderBy || searchCriteria?.orderBy)}
          onSelect={onSelect ? handleSelect : undefined}
        />
      )}
    </>
  );
};

export default SearchLocation;
