import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

import { TemporaryCallsignDto } from 'api-client';

import { isBeforeToday, requiredDate, requiredString } from '../../../common/validation/yupUtils';

const isOneDigitCallsign = (cs?: string | null) => /^[A-Za-z]{2}\d[A-Za-z]$/.test(cs ?? '');

const nonEmptyCallsigns = (ctx: any) =>
  [ctx.secondaryCallsign?.callsign, ctx.primaryCallsign?.callsign, ...(ctx.temporaryCallsigns ?? []).map((cs: any) => cs.callsign)].filter(
    (cs) => !_.isEmpty(cs),
  );

const oneDigitCallsignCheck = function (val: any) {
  // @ts-ignore
  const thisRef: any = this;

  const tempCallsigns = thisRef.options.context.temporaryCallsigns?.map((cs: any) => cs.callsign);
  if (tempCallsigns.filter(isOneDigitCallsign).length > 1 && isOneDigitCallsign(val)) {
    return (thisRef as any).createError({
      message: 'Only one single-letter temporary Callsign can be allocated to each Certificate.',
    });
  }
  return true;
};

export const uniqueCallsignCheck = function (val: any) {
  // @ts-ignore
  const thisRef: any = this;

  const callsigns = nonEmptyCallsigns(thisRef.options.context);
  const duplicatedCallsigns = _.filter(callsigns, (val, i, iteratee) => _.includes(iteratee, val, i + 1));

  if (duplicatedCallsigns.indexOf(val) >= 0) {
    return thisRef.createError({
      message: 'Each Callsign must be unique, please enter a different one.',
    });
  }
  return true;
};

export const certificateHolderSchema = yup.object().shape({
  photoDrmId: requiredString,

  lastName: requiredString,

  firstName: requiredString,

  dateOfBirth: requiredDate.test(
    'not-future-date',
    'The Birth date must be a date in the past. Please re-enter a valid Birth date.',
    (date) => !date || isBeforeToday(date),
  ),

  birthPlace: requiredString,

  birthCountry: requiredString,

  examinerRemarks: requiredString,
});

export const primaryCallsignCheck = (nullable: boolean) => {
  return yup
    .object()
    .nullable(nullable)
    .shape({
      callsign: yup
        .string()
        .nullable(nullable)
        .test('check primary callsign', '', function () {
          const ctx: any = this.options.context;
          const hasTempCallsign = ctx.temporaryCallsigns?.some((tcs: TemporaryCallsignDto) => tcs.callsign);
          if (!_.isEmpty(ctx.secondaryCallsign?.callsign) || !_.isEmpty(ctx.temporaryCallsigns) || hasTempCallsign) {
            if (_.isEmpty(ctx.primaryCallsign?.callsign)) {
              return this.createError({
                message: 'Required',
              });
            }
          }
          return true;
        })
        .test('unique callsign', '', uniqueCallsignCheck),
    });
};

export const callSignValidations = {
  primaryCallsign: primaryCallsignCheck(true),

  secondaryCallsign: yup
    .object()
    .nullable(true)
    .shape({
      callsign: yup.string().nullable(true).test('unique callsign', '', uniqueCallsignCheck),
    }),

  temporaryCallsigns: yup
    .array()
    .nullable()
    .of(
      yup.object().shape({
        callsign: requiredString.test('one digit callsign check', '', oneDigitCallsignCheck).test('unique callsign', '', uniqueCallsignCheck),
        commenceDate: requiredDate.test('new temporary callsign date check', '', function (val) {
          if (this.parent.commenceDateEditable !== true) {
            return true;
          }
          if (
            moment(val).startOf('day').diff(moment().startOf('day'), 'days') < 0 ||
            moment(val).startOf('day').diff(moment().add(21, 'months').startOf('day'), 'days') >= 0
          ) {
            return this.createError({
              message: 'The Commencement Date must not be in the past and no more than 21 months in the future',
            });
          }
          if (moment(val).startOf('day').diff(moment(this.parent.expiryDate).startOf('day'), 'days') > 0) {
            return this.createError({
              message: 'The Temporary Callsign commencement date is not before the expiry date. Please re-enter the commencement or expiry dates.',
            });
          }
          if (
            moment(val).add(3, 'months').subtract(1, 'day').startOf('day').diff(moment(this.parent.expiryDate).startOf('day'), 'days') > 0 &&
            !!this.parent.expiryDate
          ) {
            return this.createError({
              message: 'The Temporary Callsign period must be at least 3 month.',
            });
          }

          if (/^ZL\d+$/i.test(this.parent.callsign)) {
            if (moment(val).add(3, 'months').startOf('day').diff(moment(this.parent.expiryDate).startOf('day'), 'days') <= 0) {
              return this.createError({
                message: 'The Temporary Callsign period for ZL10-ZL100 must not be greater than 3 months.',
              });
            }
          } else {
            if (moment(val).add(1, 'year').startOf('day').diff(moment(this.parent.expiryDate).startOf('day'), 'days') <= 0) {
              return this.createError({
                message: 'The Temporary Callsign period must not be greater than 12 months.',
              });
            }
          }
          return true;
        }),
        expiryDate: requiredDate
          .when(['commenceDateEditable'], {
            is: (commenceDateEditable?: boolean) => !commenceDateEditable,
            then: requiredDate.test('existing callsign expiry date check', '', function (val) {
              if (moment(val).startOf('day').diff(moment(this.parent.commenceDate).add(12, 'months').startOf('day'), 'days') >= 0) {
                return this.createError({
                  message: 'The Temporary Callsign period must not be greater than 12 months.',
                });
              }
              return true;
            }),
          })
          .when(['commenceDateEditable'], {
            is: (commenceDateEditable?: boolean) => commenceDateEditable === true,
            then: requiredDate.test('new callsign expiry date check', '', function (val) {
              if (
                moment(val).startOf('day').diff(moment().startOf('day'), 'days') < 0 ||
                moment(val).startOf('day').diff(moment().add(24, 'months').startOf('day'), 'days') >= 0
              ) {
                return this.createError({
                  message: 'The Temporary Callsign Expiry Date must be no more than 24 months in the future.',
                });
              }
              return true;
            }),
          }),
        remarks: requiredString,
      }),
    ),
};

export const grantCertificateSchema = yup.object().shape({
  clientPicker: requiredString,

  type: requiredString,

  groundsForGranting: requiredString,

  noteForGranting: yup.string().when('groundsForGranting', {
    is: (groundsForGranting: string) => groundsForGranting === 'other' || groundsForGranting === 'historicQualification',
    then: requiredString,
  }),

  holder: certificateHolderSchema,

  ...callSignValidations,
});

export const saveDraftCertificateSchema = yup.object().shape({
  clientPicker: requiredString,

  type: requiredString,

  ...callSignValidations,
});
