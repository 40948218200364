import { mergeStyleSets } from '@fluentui/merge-styles';

export interface IComponentClassNames {
  root: string;
  value: string;
}

export const getClassNames = (multiline?: boolean): IComponentClassNames =>
  mergeStyleSets({
    root: {
      margin: '8px 0px',
    },
    value: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      fontWeight: 'bold',
      marginTop: '10px',
      wordBreak: multiline ? 'break-word' : 'normal',
    },
  });
