import { useFormikContext } from 'formik';
import * as React from 'react';

import { GeoreferenceDto } from 'api-client';

import { Grid } from 'ui-library';

import { FormButton } from 'common/form/FormButtons';

import { useAuth } from '../../common/auth/AuthHook';
import Dropdown from '../../common/controls/inputs/Dropdown';
import { SectionHeading } from '../../common/layout/SectionHeadings';
import { useLicenceReferenceDataNavigator } from '../../licence_management/reference_data/ReferenceDataRouter';
import GeoreferenceDetail from '../../licence_management/reference_data/location/components/GeoreferenceDetail';
import { georeferenceTypes, locationTypes } from '../../licence_management/reference_data/location/locationSupportData';
import SearchForm, { SEARCH_FORM_MODE } from '../SearchForm';
import { hiddenOptions } from '../utils';

export enum LocationTypeOptionsEnum {
  POINT = 'P',
  DEFINED_AREA = 'A',
  MULTIPLE_POINT = 'M',
  NAME = 'N',
}

const buildLocationTypeOptions = (locationOptionToBeDisplayed?: LocationTypeOptionsEnum[]) =>
  locationOptionToBeDisplayed
    ? locationTypes.filter((oneLocation) => locationOptionToBeDisplayed.includes(oneLocation.key as LocationTypeOptionsEnum))
    : locationTypes;

export type LocationSearchOptions = {
  locationTypeOptions?: LocationTypeOptionsEnum[];
  hideVisibiltiy?: boolean;
};

interface Props {
  id: string;
  onClear?: () => void;
  showAddLocationButton?: boolean;
  mode: SEARCH_FORM_MODE;
  searchLabel?: string;
  searchOptions?: LocationSearchOptions;
  onMapToggle?: (values: any) => void;
  mapVisible?: boolean;
  mapEnabled?: boolean;
  mapGeoref?: GeoreferenceDto;
  onSearch?: (values: any) => void;
}

const SearchLocationForm = (props: Props) => {
  const { isInternalUser } = useAuth();
  const { values, setValues } = useFormikContext<any>();
  const { navigateToCreateLocation } = useLicenceReferenceDataNavigator();

  React.useEffect(() => {
    // guard to avoid even same set if no map props so as not to unnecesarily trigger validation
    if (props.mapGeoref && props.mapGeoref.type && props.mapGeoref.easting && props.mapGeoref.northing) {
      setValues((currentValues: any) => {
        if (props.mapGeoref && props.mapGeoref.type && props.mapGeoref.easting && props.mapGeoref.northing) {
          return {
            ...currentValues,
            georeferenceType: props.mapGeoref.type,
            georeference: {
              type: props.mapGeoref.type,
              easting: props.mapGeoref.easting,
              northing: props.mapGeoref.northing,
              mapNumber: props.mapGeoref.mapNumber,
            },
          };
        } else {
          return currentValues;
        }
      }, false);
    }
  }, [props.mapGeoref]);

  const onGeoreferenceTypeChange = (key: any) => {
    setValues({
      ...values,
      georeferenceType: key,
      georeference: key
        ? {
            type: key,
          }
        : undefined,
    });
  };
  const showGeoreference = values.locationType === 'P' || values.locationType === 'M';
  const { hasPermission, hasRole } = useAuth();
  const isAreOrArcRole = hasRole('ROLE_APPROVED_RADIO_ENGINEER') || hasRole('ROLE_APPROVED_RADIO_CERTIFIER');
  const additionalConditions = (values.locationType === 'A' || values.locationType === 'N') && isAreOrArcRole;
  var additionalButtons: Array<FormButton> = [];
  if (props.showAddLocationButton && hasPermission('CREATE_LOCATION') && !additionalConditions) {
    additionalButtons.push({
      id: 'add-location',
      text: 'Add a Location',
      onClick: () => {
        const locType = locationTypes.find((t) => t.key === values.locationType)?.navigationType;
        navigateToCreateLocation(locType!, values.georeference);
      },
    });
  }
  if (props.mapEnabled && props.onMapToggle) {
    additionalButtons.push({
      id: 'toggle-map-visible',
      text: props.mapVisible ? 'Hide map' : 'Show map',
      onClick: () => {
        if (props.onMapToggle) {
          props.onMapToggle(values);
        }
      },
    });
  }

  //-----------------------------------
  return (
    <SearchForm
      id={props.id}
      mode={props.mode}
      title="Search location"
      searchBoxProps={{
        hint: 'Input keyword to search by Location Name, Location ID or Location description.',
        label: props.searchLabel,
      }}
      onClear={props.onClear}
      additionalButtons={additionalButtons}
      collapsible
      onSearch={props.onSearch}
    >
      <Grid.Row>
        <Grid.Col lg={5}>
          <Dropdown label="Location Type" name="locationType" options={buildLocationTypeOptions(props.searchOptions?.locationTypeOptions)} />
        </Grid.Col>
        {isInternalUser && !props.searchOptions?.hideVisibiltiy && (
          <Grid.Col lg={5}>
            <Dropdown label="Location visibility" name="hidden" options={hiddenOptions} />
          </Grid.Col>
        )}
      </Grid.Row>
      {showGeoreference && (
        <>
          <SectionHeading title={'Georeference details'} />
          <Grid.Row>
            <Grid.Col lg={7}>
              <Dropdown
                name="georeferenceType"
                label={'Georeference type'}
                options={[{ key: null as any, text: '' }].concat(...georeferenceTypes)}
                onChange={onGeoreferenceTypeChange}
              />
            </Grid.Col>
          </Grid.Row>
          {values.georeferenceType && <GeoreferenceDetail namePrefix={'georeference.'} label={'Grid reference'} textFieldSize={3} />}
        </>
      )}
    </SearchForm>
  );
};

export default SearchLocationForm;
