import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';

import OutageNotificationsPage from './OutageNotificationsPage';

const OutageNotificationRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/outage-notifications`} component={OutageNotificationsPage} role={['ROLE_SYSTEM_ADMINISTRATOR']} />
      </Switch>
    </>
  );
};

export const useOutageNotificationNavigation = () => {
  const history = useHistory();

  return {
    navigateToOutageNotifications: () => history.push('/ui/app/outage-notification/outage-notifications'),
  };
};

export default OutageNotificationRouter;
