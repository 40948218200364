import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  BaseLocationDto,
  FeatureCollection,
  FileNoteDtoEntityTypeEnum,
  GeoreferenceDto,
  GeoreferenceTypeEnum,
  GJObjectType,
  LocationTypeEnum,
} from 'api-client';

import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { ApiConfig } from 'common/api/ApiHook';
import { hasAction } from 'common/api/hateosUtils';
import { useAuth } from 'common/auth/AuthHook';
import Form from 'common/form/Form';
import { FormButton } from 'common/form/FormButtons';
import Page from 'common/layout/Page';
import { COMMON_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { validateWithFormValues } from 'common/validation/validationWithFormValues';

import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import DefinedAreaFields from '../components/DefinedAreaFields';
import ModalMap from '../components/ModalMap';
import MultipointFields from '../components/MultipointFields';
import NameFields from '../components/NameFields';
import PointFields from '../components/PointFields';
import { reOrderGeoReferenceIndex } from '../utils';
import { createDefinedAreaSchema, createLocationNameSchema, createMultipointSchema, createPointSchema } from '../validationSchema';

const MaintainLocationPage = () => {
  const { getLocation, updatePoint, updateMultipoint, updateDefinedArea, updateLocationName } = useReferenceDataHookApi();

  const { locationId } = useParams<{ locationId: string }>();
  const { isInternalUser, hasPermission } = useAuth();

  const [modalMap, setModalMap] = useState<boolean>(false);
  const [location, setLocation] = useState<any>();
  const [formMode, setFormMode] = useState<any>('VIEW');

  useEffect(() => {
    getLocation(+locationId).then(setLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const getReferencePoint = (geoReference: any) => {
    return geoReference?.origin?.type === GeoreferenceTypeEnum.D2000
      ? geoReference.origin
      : geoReference?.convertions?.find((x: any) => x.type === GeoreferenceTypeEnum.D2000 || x.type === GeoreferenceTypeEnum.D);
  };

  const mapGeoReferenceToMapPoint = (locationDto: any): FeatureCollection => {
    let features: any[] = [];
    let polygonPoints;
    if (locationDto.locationDiscriminator === 'PointDto') {
      const referencePoint: GeoreferenceDto = getReferencePoint(locationDto?.georeference);
      if (referencePoint?.easting && referencePoint?.northing) {
        const geometry = { type: GJObjectType.Point, coordinates: [referencePoint.easting, referencePoint.northing] };
        features.push({
          type: GJObjectType.Feature,
          geometry: geometry,
          properties: {
            locNorthing: referencePoint.northing,
            locGeorefType: referencePoint.type,
            georefGridRef: `${referencePoint.northing} ${referencePoint.easting}`,
            LocId: locationDto.id,
            locName: locationDto.locationName,
            locMap: referencePoint.mapNumber,
            locType: referencePoint.type,
            locEasting: referencePoint.easting,
            locDesc: locationDto.description,
          },
        });
      }
    } else {
      if (locationDto.locationDiscriminator === 'MultipointDto') {
        //Pass only the point for Polygons
        const locDetailsFirstPoint = locationDto?.georeferences[0];
        const referencePoint: GeoreferenceDto = getReferencePoint(locDetailsFirstPoint);
        if (referencePoint?.easting && referencePoint?.northing) {
          const geometry = { type: GJObjectType.Point, coordinates: [referencePoint.easting, referencePoint.northing] };
          features.push({
            type: GJObjectType.Feature,
            geometry: geometry,
            properties: {
              locNorthing: referencePoint.northing,
              locGeorefType: referencePoint.type,
              LocId: locationDto.id,
              georefGridRef: `${referencePoint.northing} ${referencePoint.easting}`,
              locName: locationDto.locationName,
              locMap: referencePoint.mapNumber,
              locType: referencePoint.type,
              locEasting: referencePoint.easting,
              locDesc: locationDto.description,
              locNameShort: locationDto.locationName.substr(0, 3),
            },
          });
        }
        polygonPoints = {
          type: GJObjectType.Feature,
          geometry: {
            type: GJObjectType.Polygon,
            coordinates: [
              locationDto?.georeferences?.map((element: any) => {
                const referencePoint: GeoreferenceDto = getReferencePoint(element);
                if (referencePoint?.easting && referencePoint?.northing) return [+referencePoint.easting, +referencePoint.northing];
                else return [];
              }),
            ],
          },
          properties: {
            georefGridRef: '',
            location: locationDto?.locationName,
          },
          id: null,
        };
      }
    }
    const collection: FeatureCollection = {
      type: GJObjectType.FeatureCollection,
      features: [...features, locationDto?.locationDiscriminator === 'MultipointDto' ? polygonPoints : ''],
    };
    return collection;
  };

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  const renderForm = (helper: any) => {
    const { values } = helper;

    const additionalButtons: FormButton[] =
      values.type === LocationTypeEnum.P || values.type === LocationTypeEnum.M
        ? [
            {
              id: 'displayLocationMap',
              text: 'Show on Map',
              onClick: () => setModalMap(!modalMap),
            },
          ]
        : [];

    return (
      <Form
        id={'maintain-location-form'}
        showFormButtonsBottom
        mode={formMode}
        additionalButtons={(mode) => (mode === 'VIEW' ? additionalButtons : [])}
        canEdit={hasAction('maintain-location', location.links)}
        disabledSubmitWhenNoDirty
      >
        {values.type === LocationTypeEnum.P && <PointFields showHidden={isInternalUser} showAudit />}
        {values.type === LocationTypeEnum.M && <MultipointFields showHidden={isInternalUser} showAudit />}
        {values.type === LocationTypeEnum.A && <DefinedAreaFields showHidden={isInternalUser} showAudit />}
        {values.type === LocationTypeEnum.N && <NameFields showHidden={isInternalUser} showAudit />}
        {canViewFileNote && (
          <FileNoteSection entityId={+locationId} entityType={FileNoteDtoEntityTypeEnum.LOCATION} noteTypes={COMMON_FILE_NOTE_TYPE} />
        )}
        <EventLogSection entityName={'LOCATION'} entityId={+locationId} entityToReloadOn={location} />
        <ModalMap isOpen={modalMap} isModeless={true} closeModalMap={() => setModalMap(false)} pointsToDisplay={mapGeoReferenceToMapPoint(values)} />
      </Form>
    );
  };

  const getValidationSchema = () => {
    switch (location.type) {
      case LocationTypeEnum.P:
        return createPointSchema;
      case LocationTypeEnum.M:
        return createMultipointSchema;
      case LocationTypeEnum.A:
        return createDefinedAreaSchema;
      case LocationTypeEnum.N:
        return createLocationNameSchema;
    }
  };

  const updateLocation: (value: any, config: ApiConfig) => Promise<BaseLocationDto> = (value: any, config: ApiConfig) => {
    switch (location.type) {
      case LocationTypeEnum.P:
        return updatePoint(value, config);
      case LocationTypeEnum.M:
        return updateMultipoint(value, config);
      case LocationTypeEnum.A:
        return updateDefinedArea(value, config);
      case LocationTypeEnum.N:
        return updateLocationName(value, config);
    }
    throw new Error('Unknow location type:' + location.type);
  };

  const onSubmit = (value: any) => {
    return updateLocation(reOrderGeoReferenceIndex(value), {
      successMessage: 'Location has been updated.',
    }).then((location) => {
      setLocation(location);
      if (!isInternalUser) {
        setFormMode('EDIT');
        setFormMode('VIEW');
      }
    });
  };

  return (
    <Page title="Maintain Location">
      {location ? (
        <Formik
          initialValues={location}
          onSubmit={onSubmit}
          // @ts-ignore
          validate={validateWithFormValues(getValidationSchema(), {})}
          enableReinitialize
          validateOnBlur
          validateOnChange
        >
          {renderForm}
        </Formik>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default MaintainLocationPage;
