import React from 'react';

import { ClientSearchResult, ClientTypeDto } from 'api-client';

import { hasAction } from 'common/api/hateosUtils';
import { useAuth } from 'common/auth/AuthHook';
import { countryCodes } from 'common/reference/countryCodes';

import { useClientApi } from '../../client_management/client/ClientApiHook';
import { usePublicApi } from '../../public/PublicApiHook';

export const getClientName = (client: any) => {
  if (client) {
    if (client.clientType === ClientTypeDto.PER || client.clientType === 'Person') {
      return client.firstName + ' ' + client.lastName;
    } else {
      return client.organisationName;
    }
  }
  return '';
};

export const useGetClientWarning = (clientId: any, creditStatus: any) => {
  const { isInternalUser, loggedIn } = useAuth();

  const { getClientDetails } = usePublicApi();
  const [hasExecutiveORDelegatedAuth, setHasExecutiveORDelegatedAuthe] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isInternalUser && loggedIn && clientId > 0) {
      getClientDetails(clientId).then((result: any) => {
        setHasExecutiveORDelegatedAuthe(hasAction('Maintain', result.links));
      });
    }
  }, [clientId]);

  if ((creditStatus === 3 || creditStatus === 4) && (isInternalUser || hasExecutiveORDelegatedAuth)) {
    return 'Check credit status';
  }
  return undefined;
};

export const useDisplayAddress = (client?: ClientSearchResult) => {
  const { isInternalUser, hasAnyPermission } = useAuth();

  const { getClientExternal } = useClientApi();
  const [hasExecutiveORDelegatedAuth, setHasExecutiveORDelegatedAuthe] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<string>();

  React.useEffect(() => {
    if (!isInternalUser && client?.id) {
      getClientExternal(client?.id).then((result: any) => {
        setHasExecutiveORDelegatedAuthe(hasAction('Maintain', result.links));
        setAddress(result.residentialAddress.singleLineAddress);
      });
    }
  }, [client]);

  if (client && (client?.type === ClientTypeDto.PER || client.type === 'Person')) {
    if (client?.privacyAct && (!!hasAnyPermission(['UPDATE_CLIENT_INT_DETAILS', 'VIEW_CLIENT_INT_DETAILS']) || hasExecutiveORDelegatedAuth)) {
      if (address != null) {
        return address + ' (Hidden from public)';
      } else {
        return client?.address !== null ? client?.address + ' (Hidden from public)' : '';
      }
    } else if (client.privacyAct) {
      return 'Address withheld';
    }
  }
  return client?.address;
};

export const getClientFormattedAddress = (client: any) => {
  const renderAddressRow = (addressRow?: string) =>
    addressRow && (
      <>
        {addressRow} <br />
      </>
    );

  return (
    <>
      {getClientName(client)}
      <br />
      {renderAddressRow(client?.residentialAddress?.streetName)} <br />
      {renderAddressRow(client?.residentialAddress?.suburb)}
      {renderAddressRow(client?.residentialAddress?.cityTown)}
      {renderAddressRow(client?.residentialAddress?.postCode)}
      {countryCodes.find((c) => c.code === client?.residentialAddress?.countryCode)?.name ?? ''}
      <br />
    </>
  );
};
