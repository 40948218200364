import React, { useEffect } from 'react';

import { CaseFinalResultEnum, CaseStatusEnum, CaseTypeGroupEnum, FileNoteDtoEntityTypeEnum } from 'api-client';

import { Form } from 'ui-library';
import { AdditionalFormButton } from 'ui-library/form/FormButtons';
import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { usePageNavigator } from 'common/auth/AppRoute';
import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import { CASE_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import ComplaintFormContent from 'investigation/complaint/components/ComplaintFormContent';
import { ComplaintFormProps } from 'investigation/interfaces';

import { useComplaintDataFetcher } from './hooks';

const ViewComplaintForm = () => {
  const { hasAnyPermission, hasPermission } = useAuth();

  const { caseDto, caseDetails } = useComplaintDataFetcher();

  const { navigateTo } = usePageNavigator();
  const { navigateToCreateAudit, navigateToMaintainComplaint } = useInvestigationRouter();

  useEffect(() => {
    if (caseDto?.id) {
      if (caseDto.type?.group !== CaseTypeGroupEnum.COMPLAINT) {
        navigateTo('/not-found');
      } else if (caseDto.status !== CaseStatusEnum.CLSD) {
        navigateToMaintainComplaint(caseDto.id);
      }
    }
  }, [caseDto]);

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');
  const shouldBeAuditted =
    caseDto?.finalResult && [CaseFinalResultEnum.RESOLVED_DUE_TO_PRODUCT, CaseFinalResultEnum.RESOLVED_TRANSMISSION].includes(caseDto?.finalResult);

  const additionalButtons: AdditionalFormButton[] =
    hasAnyPermission(['CREATE_AUDIT']) && shouldBeAuditted
      ? [
          {
            id: 'createAudit',
            isPrimary: true,
            onClick: () => {
              navigateToCreateAudit({ openInNewTab: true });
            },
            showModes: ['VIEW'],
            text: 'Create audit case',
          },
        ]
      : [];

  return (
    <Form<ComplaintFormProps>
      formButtonsOptions={{
        additionalButtons,
      }}
      id="view-complaint-form"
      initialValues={caseDetails}
      showFormButtonsBottom
    >
      <ComplaintFormContent />
      {caseDetails?.caseNumber && canViewFileNote && (
        <FileNoteSection entityId={caseDetails.caseNumber} entityType={FileNoteDtoEntityTypeEnum.CASE} noteTypes={CASE_FILE_NOTE_TYPE} />
      )}
      {caseDetails?.caseNumber && <EventLogSection entityName={'CASE'} entityId={+caseDetails.caseNumber} />}
    </Form>
  );
};

export const ViewComplaintPage = () => {
  return (
    <Page title="View Interference Complaint">
      <ViewComplaintForm />
    </Page>
  );
};
