import { Label } from '@fluentui/react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';

import { useForm } from 'common/form/FormHook';

import './taginput.scss';

interface TagInputProps {
  label: string;
  name: string;
  maxTagsAllowed?: number;
  readOnly?: boolean;
  required?: boolean;
}

interface TagInputWrapperProps extends Pick<TagInputProps, 'label' | 'name' | 'readOnly' | 'required'> {
  tags?: string[];
  onRemoveTag?: (tag: string) => void;
  children?: React.ReactNode;
}

const TagInputWrapper: React.FC<TagInputWrapperProps> = (props) => {
  return (
    <>
      <Label htmlFor={props.name} required={props.required}>
        {props.label}
      </Label>
      <div className={`ui-tagPicker-container ${!!props.readOnly && 'ui-tagPicker-readOnly'}`}>
        <div className="ui-tagPicker-tagsWrapper">
          {props.tags?.map((tag) => (
            <span className="ui-tagPicker-tagItem" key={tag}>
              <span className="ui-tagPicker-tagItemText">{tag}</span>
              {!props.readOnly && (
                <FontIcon className="ui-tagPicker-tagItemClose" iconName="ChromeClose" onClick={() => props.onRemoveTag && props.onRemoveTag(tag)} />
              )}
            </span>
          ))}
        </div>
        {props.children}
      </div>
    </>
  );
};

const TagInput = (props: TagInputProps) => {
  const [field, meta, helper] = useField(props.name);
  const [tags, setTags] = useState<string[]>([]);
  const [value, setValue] = useState<string>('');

  const { mode } = useForm();
  const { maxTagsAllowed = 5 } = props;

  useEffect(() => {
    helper.setValue(tags);
  }, [tags]);

  useEffect(() => {
    const defaultTags = field.value as string[];
    if (defaultTags?.length && (mode === 'EDIT' || mode === 'SEARCH')) {
      setTags(defaultTags);
    }
  }, [mode]);

  if (mode === 'VIEW') {
    return <TagInputWrapper {...props} tags={field.value as string[]} readOnly />;
  }

  return (
    <TagInputWrapper {...props} onRemoveTag={(tag) => setTags(tags.filter((_) => _ !== tag))} tags={tags}>
      {tags?.length < maxTagsAllowed && (
        <input
          id={props.name}
          className="ui-tagPicker-tagInput"
          onChange={(e: any) => {
            tags.length < maxTagsAllowed && setValue(e.target.value);
          }}
          onKeyPress={(e) => {
            const value = (e.target as any).value;

            if (e.key === 'Enter' && value) {
              e.preventDefault();

              if (value && value.trim() !== '' && !tags.find((tag) => value === tag)) {
                setTags([...tags, value]);
              }

              setValue('');
            }
          }}
          onBlur={(e) => {
            const value = (e.target as any).value;

            e.preventDefault();
            if (value && value.trim() !== '' && !tags.find((tag) => value === tag)) {
              setTags([...tags, value]);
            }

            setValue('');
          }}
          value={value}
        />
      )}
    </TagInputWrapper>
  );
};

export default TagInput;
