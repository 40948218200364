import { IBasePickerSuggestionsProps, IInputProps, IPickerItemProps, ITag, Label, TagItem, TagPicker } from '@fluentui/react';
import { useField } from 'formik';
import * as React from 'react';
import { useForm } from '../../../common/form/FormHook';
import { useTheme } from '../../../common/theme/RRFTheme';
import ErrorLabel from '../../../common/controls/inputs/ErrorLabel';
import ReadOnlyField from '../../../common/controls/inputs/ReadOnlyField';
import { tagItemStyleClass } from '../../../common/controls/inputs/TagPicker';
import { ITUServiceTypesITags } from './references';

interface Props {
  name: string;
  label: string;
  required?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

const ITUServiceType: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [field, meta, fieldHelper] = useField(props.name);
  const { mode } = useForm();

  const resolveSuggestions = (filter: string): Promise<ITag[]> => {
    const result = ITUServiceTypesITags?.filter((type) => type.name.toUpperCase()?.includes(filter.toUpperCase()));
    return filter ? Promise.resolve(result ?? []) : Promise.resolve([]);
  };

  const handleItemSelected = (selectedItem?: ITag) => {
    return selectedItem ? selectedItem : null;
  };
  const handleOnChange = (tags?: ITag[]) => {
    const first = tags ? tags[0] : undefined;
    fieldHelper.setValue(first ? first.key : undefined);
  };
  const handleRenderItem = (itemProps: IPickerItemProps<ITag>) => (
    <TagItem
      {...itemProps}
      onRemoveItem={() => {
        if (itemProps.onRemoveItem) itemProps.onRemoveItem();
      }}
    >
      {itemProps.item.name}
    </TagItem>
  );
  const suggestionProps: IBasePickerSuggestionsProps = {
    loadingText: 'Searching for ITU Service Type...',
    noResultsFoundText: 'No ITU Service Type found',
  };
  const inputProps: IInputProps = {
    placeholder: 'Start typing to search for an ITU service type',
    name: props.name,
  };

  const errorClassName = meta.touched && meta.error ? 'pickerErrorBorder' : '';

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField {...props} renderFormat={() => ITUServiceTypesITags.find((val) => field.value === val.key)?.name ?? ''} />
  ) : (
    <>
      <Label required={props.required}>{props.label}</Label>
      <TagPicker
        onBlur={field.onBlur}
        selectedItems={field.value ? [{ key: field.value, name: ITUServiceTypesITags.find((val) => field.value === val.key)?.name ?? '' }] : []}
        onItemSelected={handleItemSelected}
        onChange={handleOnChange}
        itemLimit={1}
        onResolveSuggestions={(filter) => resolveSuggestions(filter)}
        pickerSuggestionsProps={suggestionProps}
        onRenderItem={handleRenderItem}
        inputProps={inputProps}
        className={errorClassName + tagItemStyleClass(theme)}
        disabled={mode === 'DISABLED' || props.disabled}
      />
      <ErrorLabel {...props} />
    </>
  );
};

export default ITUServiceType;
