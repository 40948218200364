import * as React from 'react';
import { Icon, IDropdownOption, IDropdownProps, ITag, Label, mergeStyles, TagPicker, Dropdown as FluentUIDropdown } from '@fluentui/react';
import { useField } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useForm } from '../../form/FormHook';
import ReadOnlyField, { renderAsOptions } from './ReadOnlyField';
import { renderHelpText } from './HelpTextRenderer';
import ErrorLabel from './ErrorLabel';
import { asHtmlId } from '../utils';
import { tagItemStyleClass } from './TagPicker';
import { useTheme } from '../../theme/RRFTheme';

export interface MultiTagDropdownProps extends Pick<IDropdownProps, 'placeholder' | 'options' | 'disabled' | 'errorMessage' | 'label' | 'required'> {
  onChange?: (option: IDropdownOption) => void;
  id?: string;
  name: string;
  helpText?: string;
  readOnly?: boolean;
  // The default tag item width doesn't use up available input field space resulting in longer tag names being truncated.
  tagItemMaxWidth?: number | string;
  alwaysValidate?: boolean;
  includeSelectAll?: boolean;
}

const allOption = {
  key: 'all',
  text: 'All',
};

// This component is a hybrid component consisting of the TagPicker as the input field and the Dropdown for the options selection.
const MultiSelectDropdown: React.FC<MultiTagDropdownProps> = (props) => {
  const theme = useTheme();
  const [field, meta, fieldHelper] = useField<(string | number)[] | undefined>(props as any);
  const { mode } = useForm();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const tagPickerRef = useRef<HTMLDivElement | null>(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
  let [tagPickerSelectedItemWasRemoved, setTagPickerSelectedItemWasRemoved] = useState<boolean>(false);
  const [tagPickerKey, setTagPickerKey] = useState<number>(0);
  const id = props.id ?? 'select-' + asHtmlId(props.name);

  useEffect(() => {
    // tagPickerKey is used to force re-render of the TagPicker Div wrapper to prevent the onClick from triggering the
    // opening the dropdown when it is already open.
    if (tagPickerKey) {
      setDropdownIsOpen(false);
      (tagPickerRef?.current?.querySelector('input') as any)?.focus();
    }
  }, [tagPickerKey]);

  const values = Array.isArray(field.value) ? (field.value as any[]) : field.value ? [field.value] : [];

  const options = [...props.options, ...(props.includeSelectAll ? [allOption] : [])];

  const tagPickerSelectedItems = values.map((key) => ({
    key,
    name: props.options.find((o) => o.key === key)?.text ?? '',
  }));
  const availableDropdownOptions = props.options.length === values.length ? [] : options.filter((o) => !values.find((value) => value === o.key));

  const onTagPickerInputClicked = () => {
    if (dropdownIsOpen) {
      return;
    }
    if (tagPickerSelectedItemWasRemoved) {
      setTagPickerSelectedItemWasRemoved(false);
      return;
    }
    const dropdownDiv = dropdownRef?.current;
    if (dropdownDiv) {
      const inner = dropdownDiv.querySelector('.ms-Dropdown');
      if (inner) {
        setDropdownIsOpen(true);
        (inner as HTMLElement).click();
      }
    }
  };

  const onDropdownOptionSelected = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (option?.key === allOption.key) {
      fieldHelper.setValue(props.options.map((option) => option.key as string));
    } else {
      fieldHelper.setValue([...values, option!.key]);
    }
    setDropdownIsOpen(false);
    if (props.onChange && option) {
      props.onChange({ ...option, selected: true });
    }
  };

  const onTagPickerSelectedItemRemoved = (items?: ITag[]) => {
    fieldHelper.setValue(items?.map((item) => item.key) ?? []);
    setTagPickerSelectedItemWasRemoved(true);
    // Set this immediately, as setTagPickerSelectedItemWasRemoved will not be effective until next React render cycle.
    tagPickerSelectedItemWasRemoved = true;

    if (props.onChange) {
      const notInItems = (value: any) => !items?.find((i) => i.key === value);
      const toOption = (key: any) => props.options.find((o) => o.key === key)!;
      const removedOptions = values
        .filter(notInItems)
        .map(toOption)
        .map((option) => ({ ...option, selected: false }));
      removedOptions.forEach((option) => props.onChange!(option));
    }
  };

  const errorBorderClass = (props.alwaysValidate || meta.touched) && (meta.error || props.errorMessage) ? 'pickerErrorBorder' : '';

  const tagItemMaxWidthClass = props.tagItemMaxWidth
    ? mergeStyles({
        nav: { selectors: { '& .ms-TagItem': { maxWidth: props.tagItemMaxWidth } } },
      })
    : '';

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField {...props} renderFormat={renderAsOptions(props.options)} />
  ) : (
    <div id={id} onBlur={(_) => field.onBlur(_)}>
      {renderHelpText(props, (props) => (props.label ? <Label required={props.required}>{props.label}</Label> : <></>))}
      <div style={{ backgroundColor: '#FFFFFF' }}>
        <div style={{ float: 'right', height: 0, width: 0 }}>
          <div style={{ position: 'relative', paddingTop: 10, marginLeft: -22 }}>
            <Icon iconName="ChevronDown" style={{ color: '#333333' }} />
          </div>
        </div>
        <div
          key={`tag-picker-${tagPickerKey}`}
          style={{ cursor: 'pointer' }}
          onClick={onTagPickerInputClicked}
          onKeyPress={onTagPickerInputClicked}
          ref={tagPickerRef}
        >
          <TagPicker
            onResolveSuggestions={() => []}
            selectedItems={tagPickerSelectedItems}
            inputProps={{ readOnly: true, placeholder: props.placeholder, id: `${id}-input` }}
            onChange={onTagPickerSelectedItemRemoved}
            disabled={mode === 'DISABLED' || props.disabled}
            styles={{ input: { cursor: 'pointer' } }}
            className={`${errorBorderClass} ${tagItemMaxWidthClass} ${tagItemStyleClass(theme)}`}
          />
          <ErrorLabel {...props} />
        </div>
      </div>
      <div style={{ visibility: 'hidden', marginTop: -32 }}>
        <FluentUIDropdown
          ref={dropdownRef}
          label={undefined}
          options={availableDropdownOptions}
          onChange={onDropdownOptionSelected}
          selectedKeys={[]}
          onDismiss={() => setTagPickerKey(tagPickerKey + 1)}
          disabled={mode === 'DISABLED' || props.disabled}
          data-automation-id={`drop-down-${props.name}`}
        />
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
