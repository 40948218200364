import React from 'react';

import { LicenceSearchCriteriaLicenceStatusEnum, LicenceSearchResult } from 'api-client';

import { SingleTagPicker, SingleTagPickerProps } from 'ui-library/tagpicker';

import { useSearchApi } from 'search/SearchApiHooks';

interface LicencePickerProps extends Omit<SingleTagPickerProps<LicenceSearchResult>, 'onResolveSuggestions'> {}

const LicencePicker = (props: LicencePickerProps) => {
  const { searchLicence } = useSearchApi();

  return (
    <SingleTagPicker<LicenceSearchResult>
      {...props}
      onResolveSuggestions={async (searchText: string) => {
        const { results = [] } = await searchLicence({
          licenceStatus: [
            LicenceSearchCriteriaLicenceStatusEnum.Certificate_Expired,
            LicenceSearchCriteriaLicenceStatusEnum.Current,
            LicenceSearchCriteriaLicenceStatusEnum.Expired,
          ],
          orderBy: 'licensee',
          searchText,
          isRelevanceSort: true,
        });

        return results.map((licence) => {
          const key = `${licence.id} - ${licence.licensee} ${licence.location ? `(${licence.location})` : ''} ${
            licence.channel ? `(${licence.channel} - ${licence.refFrequency})` : ''
          }`;
          return {
            key,
            name: key,
            data: licence,
          };
        });
      }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for licence',
        suggestionsHeaderText: 'Suggested licences',
        noResultsFoundText: 'No licences found',
      }}
      placeholder={props.placeholder || 'Type licence number, licence ID, licensee name, client number or NZBN'}
    />
  );
};

export default LicencePicker;
