import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { ApprovalSearchCriteria } from 'api-client';

import ProtectedRoute from '../../common/auth/ProtectedRoute';
import { stringifyQueryObject } from '../../search/utils';

const SearchApprovalPage = React.lazy(() => import('./search/SearchApprovalPage'));
const CreateUpdateApprovalPage = React.lazy(() => import('./create/CreateUpdateApprovalPage'));

const ApprovalRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/search`} component={SearchApprovalPage} permission={['VIEW_APPROVAL']} />
        <ProtectedRoute
          path={`${path}/:approvalId`}
          component={CreateUpdateApprovalPage}
          permission={['CREATE_APPROVAL', 'UPDATE_APPROVAL', 'VIEW_APPROVAL']}
        />
        <ProtectedRoute path={`${path}`} component={CreateUpdateApprovalPage} permission={['CREATE_APPROVAL']} />
      </Switch>
    </>
  );
};

export const useApprovalNavigation = () => {
  const history = useHistory();

  return {
    navigateToMaintainApproval: (approvalId: number, replace?: boolean) =>
      replace ? history.replace(`/ui/app/approval/${approvalId}`) : history.push(`/ui/app/approval/${approvalId}`),
    navigateToSearchApproval: (criteria?: ApprovalSearchCriteria, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/approval/search?${stringifyQueryObject(criteria)}`)
        : history.push(`/ui/app/approval/search?${stringifyQueryObject(criteria)}`),
    navigateToCreateApproval: () => history.push('/ui/app/approval'),
  };
};

export default ApprovalRouter;
