import { MessageBarType } from '@fluentui/react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as React from 'react';

import { Field } from 'api-client';

import usePage from '../layout/PageHook';

export interface ApiConfig extends AxiosRequestConfig {
  // shows a blocking spinner while fetching, true or a custom message
  showLoadingSpinner?: boolean | string;
  // don't render error message
  disableErrorMessage?: boolean;
  // display success message
  showSuccessMessage?: boolean;
  // specify a success message, defaults to 'success'
  successMessage?: string;
  // Let caller handler errors
  callerHandleErrors?: boolean;
}

export interface ApiError {
  name: string;
  error: string;
}

/**
 * API wrapper sets page instructions in redux state based on response status
 *
 * onSuccess set's page instruction to success
 * onError set's page instruction to error with error message returned from API
 */
export const useApi = () => {
  const { setPageInstruction, setIsLoading } = usePage();

  const renderFieldErrors = (errFields?: Field[]) => (
    <ul style={{ display: 'table-cell' }}>
      {errFields?.map((field) => (
        <li>{field.error}</li>
      ))}
    </ul>
  );

  const renderErrorMessage = async (err?: any) => {
    if (err?.config?.responseType === 'blob') {
      const errorBlob = await err.response?.data?.text();
      err.response.data = errorBlob ? JSON.parse(errorBlob) : '';
    }
    if (err.response?.data?.fields) {
      setPageInstruction(renderFieldErrors(err.response?.data.fields), MessageBarType.error);
    } else {
      setPageInstruction(err.response?.data.message || err.message, MessageBarType.error);
    }
  };

  const handleErr = async (err: any, config?: ApiConfig): Promise<any> => {
    const status = err?.response?.status;

    if (!config?.callerHandleErrors) {
      if (status === 403) {
        window.location.href = '/ui/forbidden';
        return Promise.reject(err);
      }

      if (status === 404) {
        window.location.href = '/ui/not-found';
        return Promise.reject(err);
      }

      if (status === 500) {
        window.location.href = '/ui/server-error';
        return Promise.reject(err);
      }
    }

    if (status === 412) {
      setPageInstruction('Data has been updated by another user or process, please refresh the page and try again.', MessageBarType.warning);
      return Promise.reject(err);
    }

    if (!config?.disableErrorMessage) {
      await renderErrorMessage(err);
    }

    return Promise.reject(err);
  };

  const aroundApi = async (promise: Promise<any>, config?: ApiConfig) => {
    try {
      if (config?.showLoadingSpinner) {
        setIsLoading(config.showLoadingSpinner);
      }
      const resp: AxiosResponse = await promise;
      if (config?.showSuccessMessage || config?.successMessage) {
        setPageInstruction(config.successMessage ? config.successMessage : 'Success', MessageBarType.success);
      }
      // redirect if location header is present
      if (resp.headers?.location) {
        window.location.href = resp.headers?.location;
      }

      return Promise.resolve(resp.data);
    } catch (err) {
      return handleErr(err, config);
    } finally {
      if (config?.showLoadingSpinner) {
        setIsLoading(false);
      }
    }
  };

  return {
    aroundApi,
  };
};
