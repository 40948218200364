import React, { useEffect, useState } from 'react';

import { CaseStatusEnum, CaseTypeGroupEnum, FileNoteDtoEntityTypeEnum } from 'api-client';

import { Form } from 'ui-library';
import { AdditionalFormButton } from 'ui-library/form/FormButtons';
import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { hasAction } from 'common/api/hateosUtils';
import { usePageNavigator } from 'common/auth/AppRoute';
import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import { CASE_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { AuditFormProps } from 'investigation/interfaces';

import AuditFormContent from './components/AuditFormContent';
import AuditReviewDialog from './components/AuditReviewDialog';
import { useAuditFormState } from './hooks';

const AuditReviewForm = () => {
  const { hasPermission } = useAuth();
  const { caseDto, formState } = useAuditFormState();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { navigateTo } = usePageNavigator();

  useEffect(() => {
    if (caseDto?.id && caseDto.type?.group !== CaseTypeGroupEnum.AUDIT) {
      navigateTo('/not-found');
    }
  }, [caseDto]);

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');
  const caseType = formState.caseTypeOptions?.find(({ key }) => key === formState.caseTypeId)?.text;
  const hasManagerReviewAction = hasAction('manager-review', caseDto?.links);
  const additionalButtons: AdditionalFormButton[] =
    caseDto.status === CaseStatusEnum.RECOMMD && hasManagerReviewAction
      ? [
          {
            id: 'auditReviewBtn',
            isPrimary: true,
            onClick: () => {
              setIsDialogOpen(true);
            },
            showModes: ['VIEW'],
            text: 'Manager Review',
          },
        ]
      : [];

  return (
    <>
      <AuditReviewDialog
        caseDetails={{
          caseNumber: formState?.caseNumber,
          caseType,
          finalResult: formState.finalResult,
          finalResultNotes: formState.finalResultNotes,
          infringement: formState.infringement,
          recommendation: formState.recommendation,
        }}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
      />
      <Form<AuditFormProps>
        formButtonsOptions={{
          additionalButtons,
        }}
        id="audit-recommendation-form"
        initialValues={formState}
        showFormButtonsBottom
      >
        <AuditFormContent />
        {formState?.caseNumber && canViewFileNote && (
          <FileNoteSection entityId={formState.caseNumber} entityType={FileNoteDtoEntityTypeEnum.CASE} noteTypes={CASE_FILE_NOTE_TYPE} />
        )}
        {formState?.caseNumber && <EventLogSection entityName={'CASE'} entityId={+formState.caseNumber} />}
      </Form>
    </>
  );
};

const AuditReviewPage = () => {
  return (
    <Page title="Manager Review">
      <AuditReviewForm />
    </Page>
  );
};

export { AuditReviewPage };
