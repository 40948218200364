/*
 * You can find variations on this code in various places on the internet; like this they are usually themselves
 * variations of other bits of code found on the internet and combined with slight tweaks. I would like to credit
 * the original author/s but exactly who the original author/s are is lost in time, like tears in rain.
 */

export function downloadFile(response: Response) {
  let filename = '';
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  response.blob().then((blob) => doDownload(blob, filename));
}

export function getAttachmentFilename(headers: any): string {
  console.log(`headers: ${JSON.stringify(headers)}`);
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export function doDownload(blob: Blob, filename: string) {
  //@ts-ignore
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    //@ts-ignore
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(new Blob([blob]));
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    tempLink.setAttribute('target', '_blank');

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}

export function doOpenFile(blob: Blob, filename: string, target: string = '_blank', features?: string) {
  const file = new File([blob], filename, { type: blob.type });
  const fileURL = window.URL.createObjectURL(file);
  window.open(fileURL, target, features);
}
