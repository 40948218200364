import { useFormikContext } from 'formik';
import * as React from 'react';

import { ClientSearchCriteria, ClientSearchCriteriaStatusEnum, ClientSearchCriteriaTypeEnum, ClientSearchResult } from 'api-client';

import { Grid } from 'ui-library';

import { useCertificateApi } from 'certificate_management/certificate/CertificateApiHook';
import CertificateCallSign from 'certificate_management/certificate/components/CertificateCallSign';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { TextField } from 'common/controls/inputs/TextField';
import ClientTagPicker from 'common/controls/inputs/client/ClientTagPicker';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';

interface AmateurClubCallsignProps {}

const trusteeSearchCriteria: ClientSearchCriteria = {
  status: ClientSearchCriteriaStatusEnum.Active,
  type: ClientSearchCriteriaTypeEnum.Person,
  hidden: false,
};

const AmateurClubCallsign: React.FC<AmateurClubCallsignProps> = (props) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { isATrusteeClient } = useCertificateApi();
  const { mode } = useForm();
  const asClientTag = ({ id, clientName }: ClientSearchResult) => ({
    key: `${clientName} (${id})`,
    name: `${clientName} (${id})`,
    clientName,
    id,
  });

  const onChangeTrusteePicker = (item: any) => {
    setFieldValue('trusteeClientId', item[0]?.id);
    setFieldValue('trusteeName', item[0]?.clientName);
  };

  React.useEffect(() => {
    if (mode === 'VIEW') {
      setFieldValue('trusteeCertificate', true, true);
    } else if (values.trusteeClientId) {
      isATrusteeClient(values.trusteeClientId, {
        callerHandleErrors: true,
        showLoadingSpinner: false,
        disableErrorMessage: true,
        showSuccessMessage: false,
      }).then((trusteeCertificate) => {
        setFieldValue('trusteeCertificate', trusteeCertificate, true);
      });
    } else {
      setFieldValue('trusteeCertificate', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.trusteeClientId]);

  return (
    <>
      <SectionHeading title="Amateur club callsign details" />
      <Grid>
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <ClientTagPicker
              name="trusteePicker"
              label="Trustee name"
              asClientTag={asClientTag}
              criteria={trusteeSearchCriteria}
              onChange={onChangeTrusteePicker}
              isRequired
              hint="The Trustee must be a person who holds a NZ General Amateur Operator's Certificate"
            />
            <ErrorLabel name="trusteeCertificate" alwaysValidate />
          </Grid.Col>
        </Grid.Row>

        <CertificateCallSign
          primaryCallsignLabel="Primary callsign"
          secondaryCallsignLabel="Secondary callsign"
          primaryCallsignMandatory
          hideHeader
          actionName="save-callsign"
          validationAmateurCallsign
        />
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <TextField name="note" label="Note" multiline maxLength={400} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default AmateurClubCallsign;
