import * as yup from 'yup';
import { TestContext } from 'yup';

import {
  FrequencyDtoReferenceFrequencyTypeEnum,
  LicenceClassificationType,
  LicenceDtoLicenceTypeCodeEnum,
  MaintainUnwantedEmissionLimitDto,
  SpectrumDto,
} from 'api-client';

import { getValueByPath } from '../../../../common/utils/objectUtils';
import { isEmpty, numberSchema, requiredNumber, requiredString, timeSchema, validRange } from '../../../../common/validation/yupUtils';
import { radiationPatternsDoneSchema } from './radiationPatternValidationSchema';

const C = LicenceClassificationType.C;
const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;

export const requiresUnwantedEmissionLimits = ({ classification }: { classification?: any }) => classification !== C;

const bandwidth = (emission: string): number => {
  const divisorMap = new Map<string, number>([
    ['H', 1000000],
    ['K', 1000],
    ['M', 1],
    ['G', 0.001],
  ]);
  const first4 = emission.substring(0, 4);
  const [a, b] = first4.split(/[HKMG]/);
  const divisor = divisorMap.get(first4.replace(/^[0-9]*([HKMG]).*/, '$1'))!;

  let decimalMlt = first4.search(/[HKMG]/) === 1 ? 0.01 : 0.1;
  return (+a + +b * decimalMlt) / divisor;
};

const referenceFrequencySchema = numberSchema
  .nullable(true)
  .test(
    'within spectrum range',
    'The reference frequency must be between the Spectrum Low and Spectrum High values',
    function (this: yup.TestContext, f) {
      const lower: number = this.parent.lowerBound ?? 0;
      const upper: number = this.parent.upperBound ?? Number.MAX_SAFE_INTEGER;
      return f === undefined || f === null || (f >= lower && f <= upper);
    },
  );

const powerSchema = validRange(-76, 100, 'Power dBW (eirp) must between -76 and 100');

export const frequencyEmissionsSchema = yup
  .array()
  .nullable(true)
  .test('7 or 9 chars', 'Emission value should be 7 or 9 characters long', (v) => !v?.find((e: any) => !e.emission.match(/^.{7}$|^.{9}$/)))
  .test('1st char', 'First character of the Emission should be one of 123456789H', (v) => !v?.find((e: any) => !e.emission.match(/^[123456789H]/)))
  .test(
    '2nd char',
    'Second character of the Emission must be one of 0123456789GHKM',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{1}[0123456789GHKM]/)),
  )
  .test(
    '3rd char',
    'Third character of the Emission must be one of 0123456789GHKM',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{2}[0123456789GHKM]/)),
  )
  .test(
    '4th char',
    'Fourth character of the Emission must be one of 0123456789GHKM',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{3}[0123456789GHKM]/)),
  )
  .test(
    '5th char',
    'Fifth character of the Emission must be one of ABCDFGHJKLMNPQRVWX',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{4}[ABCDFGHJKLMNPQRVWX]/)),
  )
  .test(
    '6th char',
    'Sixth character of the Emission should be one of 0123456789X',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{5}[0123456789X]/)),
  )
  .test(
    '7th char',
    'Seventh character of the Emission should be one of ABCDEFNWX',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{6}[ABCDEFNWX]/)),
  )
  .test(
    '8th char',
    'Eighth character of the Emission must be one of ABCDEFGHJKLMNWX',
    (v) => !v?.find((e: any) => !e.emission.match(/^.{7}[ABCDEFGHJKLMNWX]?.?$/)),
  )
  .test('9th char', 'Ninth character of the Emission must be one of CFNTWX', (v) => !v?.find((e: any) => !e.emission.match(/^.{7}.?[CFNTWX]?$/)))
  .test(
    '1 of 1st 4 chars',
    'One and only one of the first four characters in Emission must be one of H, K, M or G',
    (v) => !v?.find((e: any) => !e.emission.substring(0, 4).match(/^[^HKMG]*[HKMG][^HKMG]*$/)),
  )
  .test('1st 4 chars', 'The first four characters must not be H000', (v) => !v?.find((e: any) => e.emission.match(/^H000/)))
  .test(
    'within spectrum range',
    'The emission bandwidth is greater than the bandwidth of the preferred channel spectrum',
    function (this: yup.TestContext, emissions) {
      const lower: number = this.parent.lowerBound ?? 0;
      const upper: number = this.parent.upperBound ?? 1000000;
      const maxBandwith = (upper * 1000000 - lower * 1000000) / 1000000;
      const roundedMaxBandwith = parseFloat(maxBandwith.toFixed(6));
      const bandwidthExceedsSpectrumRange = (e: any) => parseFloat(bandwidth(e.emission).toFixed(6)) > roundedMaxBandwith;
      return !emissions?.find(bandwidthExceedsSpectrumRange);
    },
  );
export const frequencyDoneSchema = yup.object().shape({
  referenceFrequency: referenceFrequencySchema.required('Required'),
  referenceFrequencyType: requiredString,
  power: powerSchema.required('Required'),
  powerType: requiredString,
  frequencyEmissions: frequencyEmissionsSchema.min(1, 'A reference frequency must have at least one emission'),
  isoStartTime: timeSchema.test('required-with-stop-time', 'Required', function (this: yup.TestContext, time) {
    return isEmpty(this.parent.isoStopTime) || !isEmpty(time);
  }),
  isoStopTime: timeSchema.test('required-with-start-time', 'Required', function (this: yup.TestContext, time) {
    return isEmpty(this.parent.isoStartTime) || !isEmpty(time);
  }),
});

export const unwantedEmissionLimitSchema = yup.object().shape({
  limitValue: requiredNumber
    .min(-100, 'eirp values should fall within the range -100 dBW to 200 dBW')
    .max(200, 'eirp values should fall within the range -100 dBW to 200 dBW'),
  limitFrequency: requiredNumber
    .min(0.009, 'The frequency values entered must be six decimal places and in the range 0.009000 to 1000000')
    .max(1000000, 'The frequency values entered must be six decimal places and in the range 0.009000 to 1000000')
    .test('outside spectrum range', 'All Unwanted Emission Limits should fall outside the Spectrum range', function (this: yup.TestContext, freq) {
      const lower: number = this.parent.lowerBound ?? 0;
      const upper: number = this.parent.upperBound ?? 1000000;
      return isEmpty(freq as any) || freq! <= lower || freq! >= upper;
    }),
  isUnique: yup
    .string()
    .nullable(true)
    .test(
      'is-unique',
      'It is not possible to have duplicate Unwanted Emission Limit eirp/frequency combination',
      function (this: yup.TestContext, x) {
        if (!this.options.context || !this.path.match(/unwantedEmissionLimits/)) {
          return true;
        }
        const limitValue = this.parent.limitValue;
        const limitFrequency = this.parent.limitFrequency;
        const uelsPath = this.path.split('unwantedEmissionLimits')[0] + 'unwantedEmissionLimits';
        const uels: MaintainUnwantedEmissionLimitDto[] = getValueByPath(this.options.context, uelsPath) ?? [];

        const isNumber = (n: any = '') => !isNaN(+n);
        return (
          !isNumber(limitValue) ||
          !isNumber(limitFrequency) ||
          uels
            .filter((uel) => isNumber(uel.limitValue) && isNumber(uel.limitFrequency))
            .filter((uel) => +uel.limitValue === +limitValue! && +uel.limitFrequency === +limitFrequency!).length < 2
        );
      },
    ),
});

export const unwantedEmissionLimitsDoneSchema = yup
  .array()
  .of(unwantedEmissionLimitSchema)
  .nullable(true)
  .when('$context.classification', {
    is: (classification: string) => requiresUnwantedEmissionLimits({ classification }),
    then: yup.array().test('min-2', 'At least 2 UEL points must be entered', (uels) => (uels?.length ?? 0) >= 2),
    otherwise: yup
      .array()
      .test(
        'required-empty',
        "The selected licence classification can not have UEL points. Please remove the UEL's or change the classification.",
        (uels) => !uels?.length,
      ),
  })
  .test('has-matching-lower', 'UEL point for spectrum low frequency must be provided', function (this: yup.TestContext, uels) {
    const lower: number = this.parent.lowerBound ?? 0;
    return (uels?.length ?? 0) < 2 || !!uels?.find((uel) => uel?.limitFrequency === +lower);
  })
  .test('has-matching-upper', 'UEL point for spectrum high frequency must be provided', function (this: yup.TestContext, uels) {
    const upper: number = this.parent.upperBound ?? 1000000;
    return (uels?.length ?? 0) < 2 || !!uels?.find((uel) => uel?.limitFrequency === +upper);
  });

export const registeredFrequencyDoneSchema = yup
  .array()
  .nullable(true)
  .test(
    'atleast-one-reg-frequency',
    'At least one reference frequency must be of type Registered Frequency',
    function (this: yup.TestContext, frequencies) {
      return (
        (frequencies?.length ?? 0) < 1 ||
        !!frequencies?.find((frequency: any) => frequency?.referenceFrequencyType === FrequencyDtoReferenceFrequencyTypeEnum.Registered_Frequency)
      );
    },
  );

export const spectrumDoneSchema = (licenceType?: LicenceDtoLicenceTypeCodeEnum, isSimplexLicence?: boolean) =>
  yup.object().shape({
    frequencies: yup.array().min(1, 'At least one reference frequency must be provided').of(frequencyDoneSchema),
    ...(licenceType === SPL ? { unwantedEmissionLimits: unwantedEmissionLimitsDoneSchema } : {}),
    ...(licenceType === SPL ? { frequencies: registeredFrequencyDoneSchema } : {}),
    accessCode: yup
      .string()
      .nullable(true)
      .test('mandatory if Land Mobile/Simplex LH or LF or LG AND >= 30MHz', 'Required', function (this: yup.TestContext, value) {
        return isSimplexLicence && this.parent.lowerBound >= 30 ? !!value : true;
      }),
  });

export const spectrumDetailsAllDoneSchema = (
  licenceType?: LicenceDtoLicenceTypeCodeEnum,
  maxPower?: number,
  licenceTypeCanHaveCallsign?: boolean,
  isSimplexLicence?: boolean,
) => {
  const isLt30Mhz = (spectrums: SpectrumDto[] = []): boolean => {
    const refFreqs = (spectrums as SpectrumDto[])
      ?.flatMap((spectrum) => spectrum.frequencies)
      .map((f) => f?.referenceFrequency)
      .filter((f) => !isEmpty(f)) as number[];
    const minRefFreq = [...refFreqs, 99999].reduce((a, b) => (a < b ? a : b));
    return minRefFreq < 30;
  };

  const isGt30Mhz = (spectrums: SpectrumDto[] = []): boolean => {
    const refFreqs = (spectrums as SpectrumDto[])
      ?.flatMap((spectrum) => spectrum.frequencies)
      .map((f) => f?.referenceFrequency)
      .filter((f) => !isEmpty(f)) as number[];
    const minRefFreq = [...refFreqs, 99999].reduce((a, b) => (a < b ? a : b));
    return minRefFreq > 30;
  };
  return yup.object().shape({
    spectrums: yup
      .array()
      .min(1, 'Please add a spectrum record by selecting one from the search field')
      .nullable(true)
      .of(spectrumDoneSchema(licenceType, isSimplexLicence))
      .when(['$context.baseCallsigns', '$context.mobileCallsigns', '$context.baseCallsignNotes', '$context.mobileCallsignNotes'], {
        is: (baseCallsigns?: [], mobileCallsigns?: [], baseCallsignNotes?: any, mobileCallsignNotes?: any) => {
          return (
            (!!baseCallsigns && baseCallsigns?.length > 0 && baseCallsignNotes == null) ||
            (!!mobileCallsigns && mobileCallsigns?.length > 0 && mobileCallsignNotes == null)
          );
        },
        then: yup
          .array()
          .test(
            'less-than-30Mhz',
            'The licence spectrum record(s) must be <30 MHz as per the application. Please withdraw the application and submit a new one with the correct band selected.',
            (spectrums) => !!licenceTypeCanHaveCallsign && isLt30Mhz(spectrums as any),
          ),
        otherwise: yup
          .array()
          .test(
            'required',
            'Spectrum records <30 MHz require mandatory base/mobile callsigns in the application. Please withdraw the application and submit a new one with the correct band selected.',
            (spectrums) => !licenceTypeCanHaveCallsign || !isLt30Mhz(spectrums as any),
          ),
      })
      .when(['$context.baseCallsigns', '$context.mobileCallsigns', '$context.baseCallsignNotes', '$context.mobileCallsignNotes'], {
        is: (baseCallsigns?: [], mobileCallsigns?: [], baseCallsignNotes?: any, mobileCallsignNotes?: any) => {
          return (!baseCallsigns && !!baseCallsignNotes) || (!mobileCallsigns && !!mobileCallsignNotes);
        },
        then: yup
          .array()
          .test(
            'greater-than-30Mhz',
            'Spectrum records <30 MHz require mandatory base/mobile callsigns in the application. Please withdraw the application and submit a new one with the correct band selected.',
            (spectrums) => !!licenceTypeCanHaveCallsign && isGt30Mhz(spectrums as any),
          ),
      })
      .test(
        'within-mr-bounds',
        'Spectrums low and high must be between the Management Right low and high frequencies',
        function (this: TestContext, spectrums) {
          const { managementRight, managementRightId } = (this.options.context as any).context ?? {};
          if (!managementRightId) {
            return true;
          }
          const { lowerBound, upperBound } = managementRight!;

          const belowLowerBound = (spectrum: any) => spectrum.lowerBound < lowerBound!;
          const aboveUpperBound = (spectrum: any) => spectrum.upperBound > upperBound!;
          return !spectrums?.find(belowLowerBound) && !spectrums?.find(aboveUpperBound);
        },
      ),
    radiationPatterns: radiationPatternsDoneSchema(maxPower),
  });
};

export const frequencySchema = yup.object().shape({
  referenceFrequency: referenceFrequencySchema,
  power: powerSchema,
  frequencyEmissions: frequencyEmissionsSchema,
  isoStartTime: timeSchema,
  isoStopTime: timeSchema,
});

export const frequencyForSpectrumMaskSchema = yup.object().shape({
  referenceFrequency: referenceFrequencySchema.required('Required'),
  power: powerSchema.required('Required'),
});

export const spectrumDetailsSchema = (spectrumId: number) => {
  const spectrumSchema = yup.object().shape({
    frequencies: yup
      .array()
      .when('id', {
        is: (id) => id === spectrumId,
        then: yup.array().of(frequencySchema),
      })
      .nullable(true),
    unwantedEmissionLimits: yup
      .array()
      .when('id', {
        is: (id) => id === spectrumId,
        then: yup.array().of(unwantedEmissionLimitSchema),
      })
      .nullable(true),
  });

  return yup.object().shape({
    spectrums: yup.array().nullable(true).of(spectrumSchema),
  });
};
