import { ActionButton, IButtonStyles, IconButton, IIconProps, Label, mergeStyleSets, useTheme } from '@fluentui/react';
import { IButtonProps } from '@fluentui/react/lib/components/Button/Button.types';
import * as React from 'react';

import TooltipHint from '../controls/items/TooltipHint';
import TooltipWarning from '../controls/items/TooltipWarning';
import MoreOrLess, { MoreOrLessProps } from '../controls/surfaces/MoreOrLess';
import HorizontalDivider from './HorizontalDivider';

/**
 * title: The section title
 * actionButtonText: The text to use for the right side button
 * actionButtonOnClick: onClick when right side button is clicked
 * acctionButtonId: right side button id
 * hideActionButton: hide right side button
 * expanded: makes the section render as expand/collapse, with expanded being the initial value
 * hint: displays a hint next to title
 * more: renders section as a more/less
 * required: add the required mark * as per other fluent ui component
 */
interface SectionHeadingProps extends ActionButtonProps {
  title: string;
  hideActionButton?: boolean;
  expanded?: boolean;
  hint?: string | JSX.Element | JSX.Element[];
  warning?: string;
  moreOrLess?: MoreOrLessProps;
  hideIconChevron?: boolean;
  required?: boolean;
  headingStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

const toId = (text?: string) => (text ? text.toLocaleLowerCase().replace(/\s+/g, '-') : text);

const classNames = mergeStyleSets({
  heading: { color: '#414042', marginBottom: '10px' },
  actionButton: { float: 'right' },
  required: { display: 'inline' },
});

export const SectionHeading: React.FC<SectionHeadingProps> = (props) => {
  const { semanticColors } = useTheme();
  const [expanded, setExpanded] = React.useState<boolean | undefined>(props.expanded);

  React.useEffect(() => setExpanded(props.expanded), [props.expanded]);

  const isExpandable = (props.moreOrLess || props.children) && !props.hideIconChevron;
  const showAddButton = !!!props.hideActionButton && props.actionButtonOnClick;
  const expandableStyle = isExpandable ? { cursor: 'pointer' } : {};

  return (
    <div data-automation-id={`${toId(props.title)}-section`} id={`${toId(props.title)}-section`}>
      <h2 className={classNames.heading} style={{ ...expandableStyle, ...props.headingStyle }}>
        <span onClick={() => isExpandable && setExpanded(!expanded)}>{props.title}</span>
        {props.hint && <TooltipHint id="search-box-tooltip-hint" content={props.hint} styles={{ root: { marginLeft: '5px' } }} />}
        {props.warning && <TooltipWarning id="client-details-warning" content={props.warning} styles={{ root: { marginLeft: '5px' } }} />}
        {props.required && <Label required={props.required} className={classNames.required}></Label>}
        {isExpandable && (
          <IconButton
            onClick={() => setExpanded(!expanded)}
            iconProps={{ iconName: expanded ? 'ChevronUpMed' : 'ChevronDownMed', style: { fontSize: '12px', color: semanticColors.bodyText } }}
            aria-label={`${expanded ? 'Expand ' : 'Collapse '} ${props.title}`}
          />
        )}
        {showAddButton && (
          <AddButton
            {...props}
            actionButtonOnClick={() => {
              setExpanded(true);
              props.actionButtonOnClick && props.actionButtonOnClick();
            }}
          />
        )}
      </h2>
      <HorizontalDivider />
      {expanded && !!!props.moreOrLess && props.children}
      {expanded && props.moreOrLess && <MoreOrLess {...props.moreOrLess} />}
    </div>
  );
};

interface ActionButtonProps {
  actionButtonText?: string;
  actionButtonOnClick?: () => void;
  actionButtonId?: string;
  actionButtonProps?: IButtonProps;
}

const AddButton = (props: ActionButtonProps) => {
  const { semanticColors } = useTheme();
  const styles: IButtonStyles = {
    root: { height: '30px' },
    label: {
      fontSize: 'larger',
      color: semanticColors.primaryButtonBackground,
    },
    labelHovered: { color: semanticColors.primaryButtonBackgroundHovered },
    iconHovered: { color: semanticColors.primaryButtonBackgroundHovered },
    iconPressed: { color: semanticColors.primaryButtonBackgroundHovered },
  };
  const iconProps: IIconProps = {
    iconName: 'Add',
    styles: {
      root: {
        fontSize: '15px',
        color: semanticColors.primaryButtonBackground,
        fontWeight: 'bolder',
      },
    },
  };

  return (
    <span className={classNames.actionButton}>
      <ActionButton
        id={props.actionButtonId ? props.actionButtonId : toId(props.actionButtonText)}
        data-automation-id={props.actionButtonId ? props.actionButtonId : toId(props.actionButtonText)}
        text={props.actionButtonText ? props.actionButtonText : 'Add'}
        styles={styles}
        onClick={props.actionButtonOnClick}
        iconProps={iconProps}
        {...props.actionButtonProps}
      />
    </span>
  );
};

export const SubSectionHeading = (
  props: {
    title?: string;
    hint?: string;
    warning?: string;
    required?: boolean;
    expanded?: boolean;
    moreOrLess?: MoreOrLessProps;
    children?: React.ReactNode;
  } & ActionButtonProps,
) => {
  const [expanded, setExpanded] = React.useState<boolean | undefined>(props.expanded);
  const styles = mergeStyleSets({
    heading: {
      color: '#006272',
      marginBottom: '0px',
      paddingBottom: '5px',
    } as React.CSSProperties,
    nav: { float: 'right' } as React.CSSProperties,
  });

  return (
    <div className="sub-section-heading">
      {props.required && (
        <style
          dangerouslySetInnerHTML={{
            __html: '.sub-section-heading span.title.required::after {content:" *"; color: rgb(206,4,4); padding-right:12px}',
          }}
        />
      )}
      {props.title && (
        <h3 className={styles.heading}>
          <span className={`title${props.required ? ' required' : ''}`}>{props.title}</span>
          {props.hint && <TooltipHint id="sub-section-tooltip-hint" content={props.hint} styles={{ root: { marginLeft: '5px' } }} />}
          {props.warning && <TooltipWarning id="client-details-warning" content={props.warning} styles={{ root: { marginLeft: '5px' } }} />}
          {props.actionButtonOnClick && (
            <AddButton
              {...props}
              actionButtonOnClick={() => {
                props.actionButtonOnClick && props.actionButtonOnClick();
              }}
            />
          )}
        </h3>
      )}
      <HorizontalDivider />
      {expanded && !!!props.moreOrLess && props.children}
      {expanded && props.moreOrLess && <MoreOrLess {...props.moreOrLess} />}
    </div>
  );
};
