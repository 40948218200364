export const replaceGraphPointIndex = (values: any) => {
  return {
    ...values,
    spectrumMaskPoints: values.spectrumMaskPoints.map((spectrumMaskPoint: any, graphPointIndex: number) => ({
      ...spectrumMaskPoint,
      graphPointIndex,
    })),
  };
};

export const convertSpectrumMaskPointToString = (values: any) => {
  return {
    ...values,
    spectrumMaskPoints: values.spectrumMaskPoints.map((spectrumMaskPoint: any) => ({
      ...spectrumMaskPoint,
      displacement: spectrumMaskPoint.displacement?.toFixed(6),
      frequency: spectrumMaskPoint.frequency?.toFixed(6),
      calcLevel: spectrumMaskPoint.calcLevel?.toFixed(1),
      dbwSetLevel: spectrumMaskPoint.dbwSetLevel?.toFixed(1),
    })),
  };
};
