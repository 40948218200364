import { Label, TagItemSuggestion, Text } from '@fluentui/react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { useState } from 'react';

import {
  LicenceSearchCriteriaConfigTypeEnum,
  LicenceSearchCriteriaLicenceStatusEnum,
  LicenceSearchResult,
  LicenceTypeDto,
  SelectedServiceLevels,
} from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import DatePicker from 'common/controls/inputs/DatePicker';
import Dropdown from 'common/controls/inputs/Dropdown';
import PreferredBandSelector from 'common/controls/inputs/PreferredBandSelector';
import ServiceLevelSelector from 'common/controls/inputs/ServiceLevelSelector';
import TagPicker from 'common/controls/inputs/TagPicker';
import { TextField } from 'common/controls/inputs/TextField';
import { highlightOrValue, SearchResultsHighlight } from 'common/controls/items/Pagination';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatDateFromISO } from 'common/utils/dateUtils';

import { months } from '../../../client_management/client/utils';
import { useSearchApi } from '../../../search/SearchApiHooks';
import { canHaveReservedLicence } from '../../common/utils';
import { ApplyLicencePageContext } from '../ApplyLicencePage';
import BaseMobileCallsignSection from './BaseMobileCallsignSection';
import ManagementRightSection from './MangementRightSection';

const Planned = LicenceSearchCriteriaLicenceStatusEnum.Planned;
const TRN = LicenceSearchCriteriaConfigTypeEnum.TRN;

const BasicApplicationDetailsSection = (props: { selectedServiceLevels?: SelectedServiceLevels }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const {
    onLevel4Change,
    onPreferredBandsChange,
    showPreferredBand,
    showMangementRight,
    setSelectedManagementRight,
    showBaseMobileCallsign,
    showEngineers,
    engineers,
    preferredBands,
    channelOptions,
    selectedLicenceType,
    level1,
    serviceTypes,
    setNextLevelToValidate,
    copyApplication,
    selectedBands,
    copyRadioApplication,
    copySpectrumApplication,
  } = React.useContext(ApplyLicencePageContext);
  const showReservedLicence = canHaveReservedLicence(selectedLicenceType?.id, selectedLicenceType?.compositionMatrixId);
  const airCraftShipLicenceType =
    values.licenceTypeId === '15' || values.licenceTypeId === '117' || values.licenceTypeId === '120' || values.licenceTypeId === '121';
  const autograntedLicence = values.licenceTypeId === '211' || airCraftShipLicenceType;
  const { isInternalUser } = useAuth();
  const defaultManagementRightId = typeof showMangementRight === 'number' ? showMangementRight : undefined;

  return (
    <>
      <SectionHeading title="Basic details" />
      <ServiceLevelSelector
        serviceTypes={serviceTypes}
        level1={level1}
        setNextLevelToValidate={setNextLevelToValidate}
        onLevel4Change={onLevel4Change}
        selectedLevels={props.selectedServiceLevels}
        canEditLicenceType={false} // If copy state, then enable
        canEditForCopy={copyRadioApplication || copySpectrumApplication}
      />

      {showPreferredBand && (
        <PreferredBandSelector
          preferredBands={preferredBands ? preferredBands : []}
          onPreferredBandsChange={onPreferredBandsChange}
          channelOptions={channelOptions}
          selectedLicenceDescription={selectedLicenceType?.description}
        />
      )}

      {showReservedLicence && selectedLicenceType && <ReservedLicence licenceType={selectedLicenceType} />}
      {/* New Application Search */}
      {showMangementRight && !copyApplication && (
        <ManagementRightSection onSelected={setSelectedManagementRight} initialSelected={defaultManagementRightId} />
      )}
      {/* Copy Application Selected MR */}
      {showMangementRight && copyApplication && defaultManagementRightId && (
        <ManagementRightSection onSelected={setSelectedManagementRight} initialSelected={defaultManagementRightId} />
      )}

      {values.rsmToPerformEngineering === false && showEngineers && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <Dropdown name="approvedEngineerId" label="Engineer" options={engineers ? engineers : []} placeholder="-- Select a value --" required />
          </Grid.Col>
        </Grid.Row>
      )}
      {showEngineers && (
        <>
          {isInternalUser && (
            <Grid.Row>
              <Grid.Col lg={4}>
                <Checkbox
                  label="RSM Engineering"
                  name="rsmToPerformEngineering"
                  id="rsmToPerformEngineering"
                  onChange={() => (values.approvedEngineerId = undefined)}
                />
              </Grid.Col>
            </Grid.Row>
          )}
        </>
      )}
      {values?.suppressLicenceDetails && (
        <>
          {values?.id === undefined && (
            // no saved application exists, checkbox value from client
            <Grid.Row>
              <Grid.Col sm={12} lg={5}>
                <Checkbox name="applicantSuppressDetails" label="Suppress licence details" topMargin />
              </Grid.Col>
            </Grid.Row>
          )}
          {values?.id && (
            // saved application exists, checkbox value from application
            <Grid.Row>
              <Grid.Col sm={12} lg={5}>
                <Checkbox name="suppressDetails" label="Suppress licence details" topMargin />
              </Grid.Col>
            </Grid.Row>
          )}
        </>
      )}
      {!airCraftShipLicenceType && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <TextField name="purposeOfService" multiline label="Purpose of the service (4000 Characters max)" />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Checkbox
            name="fixedTerm"
            label="Fixed term"
            topMargin
            onChange={() => setFieldValue('periodEnding', undefined)}
            readOnly={!!showMangementRight}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={12} lg={2}>
          {autograntedLicence && (
            <>
              <Label>Commencement date:</Label>
              <Text>{formatDateFromISO(values.periodCommencementDate)}</Text>
            </>
          )}
          {!autograntedLicence && <DatePicker name="periodCommencementDate" label="Commencement date" isRequired minDate={new Date()} />}
        </Grid.Col>
        {values.fixedTerm && (
          <>
            <Grid.Col sm={12} lg={1}></Grid.Col>
            <Grid.Col sm={12} lg={2}>
              <DatePicker name="periodEnding" label="Expiry date" isRequired minDate={new Date()} />
            </Grid.Col>
          </>
        )}
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={12} lg={2}>
          <Dropdown name="anniversaryMonth" label="Anniversary month" options={months} required />
        </Grid.Col>
      </Grid.Row>

      {!airCraftShipLicenceType && (
        <Grid.Row>
          <Grid.Col sm={12} lg={5}>
            <TextField name="additionalInformation" multiline label="Additional information" />
          </Grid.Col>
        </Grid.Row>
      )}
      {!airCraftShipLicenceType && showBaseMobileCallsign && <BaseMobileCallsignSection {...selectedLicenceType} />}
    </>
  );
};

const ReservedLicence = ({ licenceType }: { licenceType: LicenceTypeDto }) => {
  const [reservedLicence, setReservedLicence] = useState<LicenceSearchResult>();
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <ReservedLicencePicker licenceType={licenceType} onChange={setReservedLicence} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <ReadonlyData label="Reserved licence ID" value={reservedLicence?.id} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ReadonlyData label="Channel" value={reservedLicence?.channel} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ReadonlyData label="Ref. freq. (MHz)" value={reservedLicence?.refFrequency?.toFixed(6)} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ReadonlyData label="Location" value={reservedLicence?.location} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const ReservedLicencePicker = (props: { licenceType: LicenceTypeDto; onChange: (lic?: LicenceSearchResult) => void }) => {
  const { searchLicence } = useSearchApi();

  return (
    <TagPicker
      label="Reserved licence"
      name="reservedLicenceId"
      onResolveSuggestions={async (searchText: string) => {
        const asTag = (lic: LicenceSearchResult) => ({
          key: lic.id,
          name: `${lic.id} ${lic.channel ?? ''} ${lic.refFrequency ?? ''} ${lic.location ?? ''}`,
          data: lic,
        });
        if (!_.isEmpty(searchText)) {
          return searchLicence({
            searchText,
            licenceStatus: [Planned],
            licenceType: [props.licenceType.id!],
            compositionMatrixId: [props.licenceType.compositionMatrixId!],
            configType: TRN,
            reserved: true,
            searchNumberPartialMatch: true,
            searchFields: 'idKey, channel, refFrequencyString, location',
            orderBy: 'refFrequencyString, location',
            pageSize: 10,
          }).then((searchResults) => searchResults.results!.map(asTag).slice(0, 10));
        }
        return [];
      }}
      onRenderSuggestionsItem={(tag, itemProps) => {
        const lic: LicenceSearchResult = (tag as any).data;
        return (
          <TagItemSuggestion {...(itemProps as any)}>
            <SearchResultsHighlight>
              {highlightOrValue(lic.idHighlight, lic.id)}&nbsp;&nbsp;
              {highlightOrValue(lic.channelHighlight, lic.channel) ?? '-'}&nbsp;&nbsp;
              {highlightOrValue(lic.refFrequencyHighlight, lic.refFrequency?.toFixed(6)) ?? '-'}&nbsp;&nbsp;
              {highlightOrValue(lic.locationHighlight, lic.location) ?? '-'}
            </SearchResultsHighlight>
          </TagItemSuggestion>
        );
      }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for licence',
        suggestionsHeaderText: 'Licence ID Channel Ref.freq. Location',
        noResultsFoundText: 'No licences found',
      }}
      inputProps={{ placeholder: 'Type licence ID, channel, ref. freq. or location' }}
      itemLimit={1}
      onChange={(tag: any) => props.onChange([...tag][0]?.data)}
    />
  );
};

export default BasicApplicationDetailsSection;
