import { useField } from 'formik';
import React from 'react';

import Dropdown from '../../../common/controls/inputs/Dropdown';
import { TextField } from '../../../common/controls/inputs/TextField';
import { useForm } from '../../form/FormHook';
import ReadonlyData from '../items/ReadonlyData';

type Props = { textInputName: string; label: string; dropdownName: string; readonly?: boolean; required?: boolean };

const options = [
  { key: 7, text: 'GHz' },
  { key: 6, text: 'Hz' },
  { key: 2, text: 'MHz' },
  { key: 9, text: 'W e.i.r.p.' },
  { key: 11, text: 'Watts' },
  { key: 10, text: 'dB' },
  { key: 14, text: 'dBW' },
  { key: 5, text: 'dBW e.i.r.p.' },
  { key: 16, text: 'dBW/kHz' },
  { key: 12, text: 'dBm' },
  { key: 1, text: 'dB\u00B5V/m' },
  { key: 19, text: 'dB\u00B5V/m/100kHz' },
  { key: 20, text: 'dB\u00B5V/m/1GHz' },
  { key: 17, text: 'dB\u00B5V/m/1MHz' },
  { key: 18, text: 'dB\u00B5V/m/1kHz' },
  { key: 15, text: 'dB\u00B5V/m/Hz' },
  { key: 3, text: 'kHz' },
  { key: 8, text: 'kW e.i.r.p.' },
  { key: 4, text: 'mW e.i.r.p.' },
];

export const TextFieldAndUnitOfMeasure: React.FC<Props> = (props) => {
  const { mode } = useForm();

  return mode === 'VIEW' || props.readonly ? (
    <ReadOnlyValueAndUnitOfMeasure {...props} />
  ) : (
    <TextField
      name={props.textInputName}
      label={props.label}
      maxLength={6}
      onRenderSuffix={() => (
        <span style={{ minWidth: 150, maxWidth: 150 }}>
          <Dropdown name={props.dropdownName} options={options} placeholder="unit" />
        </span>
      )}
      required={props.required}
    />
  );
};

export const ReadOnlyValueAndUnitOfMeasure: React.FC<Props> = (props) => {
  const [textField] = useField<string>(props.textInputName);
  const [uomDropDown] = useField(props.dropdownName);
  const uomValue = options.find((option) => option.key === uomDropDown.value);
  return (
    <ReadonlyData
      label={props.label}
      value={textField.value || uomValue?.text ? `${textField ? textField.value : ''} ${uomValue ? uomValue.text : ''}` : undefined}
    />
  );
};
