import {
  CraftingConfigurationDto,
  LicenceDtoLicenceTypeCodeEnum,
  RadioLicenceDto,
  ServiceTypeDto,
  SpectrumDto,
  SpectrumLicenceDto,
} from 'api-client';

import { useLicenceCraftingApi } from 'licence_management/craft/LicenceCraftingApiHook';

import { useLicenceApi } from '../LicenceApiHooks';
import {
  satelliteReceiveLocationsDoneSchema,
  satelliteSpectrumsDoneSchema,
  satelliteTransmitLocationsDoneSchema,
} from '../apply/components/satellite/validationSchema';
import { findLicenceType } from '../common/utils';
import { licenceAuthoritiesDoneSchema } from './components/Authorities/licenceAuthoritiesValidationSchema';
import { licenceAsFormValues } from './components/BasicDetails/LicenceBasicDetails';
import { basicDetailsSchemaFull as basicDetailsDoneSchema } from './components/BasicDetails/basicDetailsValidationSchemaFull';
import { availableLicenceClassificationsByLicenceType } from './components/BasicDetails/utils';
import { licenceConditionsSchema as licenceConditionsDoneSchema } from './components/Conditions/licenceConditionsValidationSchema';
import { spectrumsAsFormValues } from './components/SpectrumDetails/LicenceSpectrumDetails';
import { maximumPower, radiationPatternsAsFormValues, RadiationPatternsFormProps } from './components/SpectrumDetails/RadiationPatternsSection';
import { spectrumDetailsAllDoneSchema as spectrumDetailsDoneSchema } from './components/SpectrumDetails/spectrumDetailsValidationSchema';
import {
  doneReceiveLocationsSchema,
  doneReceiveLocationsValidationContext,
  doneTransmitLocationsSchema,
  doneTransmitLocationsValidationContext,
} from './components/licence_locations/common/doneValidationSchema';

const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;
type Licence = RadioLicenceDto & SpectrumLicenceDto;

export const useDoneValidation = () => {
  const { getSpectrums, getTransmitLocationsExternal, getReceiveLocationsExternal } = useLicenceApi();
  const { getTransmitLocations, getReceiveLocations } = useLicenceCraftingApi();
  const requiresClassification = (licence: Licence, serviceTypes: ServiceTypeDto[]) =>
    !!availableLicenceClassificationsByLicenceType(licence, serviceTypes).length;

  return {
    validateBasicDetails: (licence: Licence, serviceTypes: ServiceTypeDto[]): Promise<boolean> =>
      basicDetailsDoneSchema.isValid(licenceAsFormValues(licence), {
        // No need to validate callsigns as these are already persisted valid with the callsign allocation table.
        context: {
          context: {
            requiresClassification: requiresClassification(licence, serviceTypes),
            modifyRadioLicence: licence.isBeingModified,
            validateCallsign: undefined,
            setTotalRequired: findLicenceType(licence, serviceTypes)?.setTotalRequired,
          },
        },
        abortEarly: false,
      }),

    validateSpectrums: (
      licence: Licence,
      serviceTypes: ServiceTypeDto[],
      {
        maxSpectrums,
        spectrums,
        radiationPatterns,
      }: { maxSpectrums?: number; spectrums?: SpectrumDto[]; radiationPatterns?: RadiationPatternsFormProps },
      callsignRequired: boolean,
    ): Promise<boolean> => {
      if (maxSpectrums === 0) {
        return Promise.resolve(true);
      }
      radiationPatterns = radiationPatterns ?? radiationPatternsAsFormValues(licence);
      return (spectrums ? Promise.resolve(spectrums) : getSpectrums(licence.id!).then(spectrumsAsFormValues)).then((spectrums) =>
        spectrumDetailsDoneSchema(licence.licenceTypeCode, maximumPower(spectrums), callsignRequired).isValidSync(
          { spectrums, radiationPatterns },
          {
            context: {
              context: { ...licence },
              spectrums,
              radiationPatterns,
            },
            abortEarly: false,
          },
        ),
      );
    },

    validateSatelliteTransmitLocation: async (licence: RadioLicenceDto): Promise<boolean> => {
      const spectrums = await getSpectrums(licence.id!);
      const isSpectrumsValid = satelliteSpectrumsDoneSchema.isValidSync(
        { spectrums },
        {
          abortEarly: false,
        },
      );

      if (!isSpectrumsValid) {
        return Promise.resolve(false);
      }

      const transmitLocations = await getTransmitLocations(licence.id!);
      const isTranmistLocationValid = satelliteTransmitLocationsDoneSchema.isValidSync({ transmitLocations }, { abortEarly: false });

      return Promise.resolve(isTranmistLocationValid);
    },

    validateSatelliteReceiveLocation: async (licence: RadioLicenceDto): Promise<boolean> => {
      const spectrums = await getSpectrums(licence.id!);
      const isSpectrumsValid = satelliteSpectrumsDoneSchema.isValidSync(
        { spectrums },
        {
          abortEarly: false,
        },
      );

      if (!isSpectrumsValid) {
        return Promise.resolve(false);
      }

      const receiveLocations = await getReceiveLocations(licence.id!);
      const isReceiveLocationsLocationValid = satelliteReceiveLocationsDoneSchema.isValidSync(
        { transmitLocations: receiveLocations },
        { abortEarly: false },
      );

      return Promise.resolve(isReceiveLocationsLocationValid);
    },

    validateTransmitLocations: (licence: Licence, { locationConfiguration }: CraftingConfigurationDto): Promise<boolean> =>
      locationConfiguration?.txLocationNumberConstraint !== null
        ? getTransmitLocationsExternal(licence.id!).then((locations) =>
            doneTransmitLocationsSchema.isValidSync(
              { locations },
              { context: { context: doneTransmitLocationsValidationContext(licence, locationConfiguration) }, abortEarly: false },
            ),
          )
        : Promise.resolve(true),

    validateReceiveLocations: (licence: Licence, { locationConfiguration }: CraftingConfigurationDto): Promise<boolean> =>
      locationConfiguration?.rxLocationNumberConstraint !== null
        ? getReceiveLocationsExternal(licence.id!).then((locations) =>
            doneReceiveLocationsSchema.isValidSync(
              { locations },
              { context: { context: doneReceiveLocationsValidationContext(licence, locationConfiguration) }, abortEarly: false },
            ),
          )
        : Promise.resolve(true),

    validateAuthorities: async (licence: Licence): Promise<boolean> =>
      licence.licenceTypeCode === SPL ? await licenceAuthoritiesDoneSchema.isValid(licence, { abortEarly: false }) : true,
    validateConditions: ({ specificConditions }: Licence): Promise<boolean> =>
      licenceConditionsDoneSchema.isValid(specificConditions, { abortEarly: false }),
  };
};
