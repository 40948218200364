import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { AuditAppealResultEnum } from 'api-client';

import { Dropdown, Form, Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import { useAuditApi } from 'investigation/audit/hooks';

import { useAppRouter } from '../../../Routes';
import usePage from '../../../common/layout/PageHook';

interface AuditClientAppealedFormProps {
  id: number;
  appealResult?: AuditAppealResultEnum;
  appealResultDetails?: string;
}

const ActionCardAppealResult = (partialAudit: AuditClientAppealedFormProps) => {
  const { auditAppealResultApi } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  const handleSubmit = (values: AuditClientAppealedFormProps) => {
    auditAppealResultApi({
      id: values.id,
      appealResult: values.appealResult!!,
      appealResultDetails: values.appealResultDetails || '',
    }).then((auditDto) => {
      navigateToDashboard();
      auditDto.appealResult === AuditAppealResultEnum.SUCCESSFUL
        ? setPageInstruction('Infringement successfully withdrawn for the case.', MessageBarType.success)
        : setPageInstruction('Unsuccessful appeal. Case awaiting payment.', MessageBarType.success);
    });
  };

  return (
    <Form<AuditClientAppealedFormProps>
      formButtonsOptions={{
        hideBackButton: true,
        hideCancelButton: true,
        hideSubmitButton: true,
      }}
      handleSubmit={handleSubmit}
      hideFormButtonsTop
      id="appealResultActionCard"
      isInDialog
      initialValues={partialAudit}
      mode="CREATE"
      showFormButtonsBottom
      validationSchema={[{ name: 'appealResult' }, { name: 'appealResultDetails' }]}
    >
      <AppealResultContent />
    </Form>
  );
};

const LABEL_PER_RESULT_TYPE = {
  [AuditAppealResultEnum.SUCCESSFUL]: 'Enter details below. Clicking OK will record the appeal result against the infringement and close the case.',
  [AuditAppealResultEnum.UNSUCCESSFUL]:
    'Enter details below and click OK. The appeal result will be recorded against the infringement. The audit status will remain as ‘Infringement’.',
};

const AppealResultContent = () => {
  const { values, resetForm, submitForm } = useFormikContext<AuditClientAppealedFormProps>();

  return (
    <MessageBar
      data-automation-id={`audit-appeal-result-message-bar`}
      messageBarType={MessageBarType.warning}
      styles={{ root: { '.ms-MessageBar-innerText': { width: '50%' } } }}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="audit-appeal-result-action" onClick={() => submitForm()}>
              Submit
            </PrimaryButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <DefaultButton data-automation-id="audit-appeal-result-clear" onClick={() => resetForm()}>
              Clear
            </DefaultButton>
          </div>
        </>
      }
    >
      <Text>Select the result of the client's appeal.</Text>
      <Grid>
        <Grid.Row>
          <Grid.Col lg={4}>
            <Dropdown
              label="Appeal result"
              name="appealResult"
              options={[
                { key: AuditAppealResultEnum.SUCCESSFUL, text: 'Client Appeal successful' },
                { key: AuditAppealResultEnum.UNSUCCESSFUL, text: 'Client Appeal unsuccessful' },
              ]}
              required
            />
          </Grid.Col>
        </Grid.Row>
        {values.appealResult && (
          <Grid.Row>
            <Grid.Col>
              <TextField
                label={LABEL_PER_RESULT_TYPE[values.appealResult]}
                maxLength={400}
                multiline
                name="appealResultDetails"
                required
                showMaxHint
              />
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>
    </MessageBar>
  );
};

export default ActionCardAppealResult;
