import { DefaultButton } from '@fluentui/react';
import React from 'react';

import { EquipmentDto, EquipmentSearchCriteria, EquipmentSearchResults } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Pagination, { withSorting } from 'common/controls/items/Pagination';
import DetailsList from 'common/controls/lists/DetailsList';
import { useMedia } from 'common/layout/MediaHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { parseBoolean } from 'common/utils/objectUtils';
import ExportSearchCommonResults from 'search/common/ExportSearchCommonResults';
import { AppliedFilters } from 'search/utils';

import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';

interface Props {
  searchCriteria?: EquipmentSearchCriteria;
  searchResults?: EquipmentSearchResults;
  onPaging: (page: number, pageSize: number) => void;
  onOrderBy: (orderBy: string) => void;
  submitted: boolean;
}

const SearchEquipmentResults: React.FC<Props> = (props) => {
  const { navigateToMaintainEquipment } = useLicenceReferenceDataNavigator();
  const { isInternalUser, hasRole } = useAuth();
  const { isMobile } = useMedia();
  const isSystemAdmin = hasRole('ROLE_SYSTEM_ADMINISTRATOR');
  const columns = [
    ...(isInternalUser
      ? [
          {
            key: 'id',
            fieldName: 'id',
            name: 'Id',
            minWidth: isMobile ? 0 : 30,
            maxWidth: 50,
            isResizable: true,
            isNumber: true,
            onRender: (item: EquipmentDto) => item.id,
          },
        ]
      : []),
    {
      key: 'identifier',
      fieldName: 'identifier',
      name: 'Identifier',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isNumber: true,
      onRender: (item: EquipmentDto) => item.identifier,
    },
    {
      key: 'versionNumber',
      fieldName: 'versionNumber',
      name: 'Version number',
      minWidth: 120,
      maxWidth: 120,
      isMultiline: true,
      isResizable: true,
      isNumber: true,
      onRender: (item: EquipmentDto) => item.versionNumber,
    },
    ...(isInternalUser
      ? [
          {
            key: 'hidden',
            fieldName: 'hidden',
            name: 'Hidden',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            onRender: (item: EquipmentDto) => (item.hidden ? 'Yes' : ''),
          },
        ]
      : []),
    {
      key: 'make',
      fieldName: 'make',
      name: 'Make',
      minWidth: isMobile ? 0 : 120,
      maxWidth: 120,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: 'model',
      fieldName: 'model',
      name: 'Model',
      minWidth: 110,
      maxWidth: 110,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: 'band',
      fieldName: 'band',
      name: 'Band',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: 'channelSize',
      fieldName: 'channelSize',
      name: 'Channel size',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: 'remarks',
      fieldName: 'remarks',
      name: 'Remarks',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: 'view',
      name: '',
      minWidth: 75,
      maxWidth: 75,
      onRender: (item: EquipmentDto) => (
        <DefaultButton
          id={`view-${item.id}`}
          text="View"
          ariaLabel={`View equipment ${item.identifier} ${item.make} ${item.model} button`}
          onClick={() => navigateToMaintainEquipment(item.id!)}
        />
      ),
    },
  ];

  const filtersSummary = (filter: EquipmentSearchCriteria = {}) => {
    if (isSystemAdmin) {
      return new AppliedFilters()
        .push(parseBoolean(filter.hidden) ? 'Hidden' : parseBoolean(filter.hidden) === false ? 'Visible' : '', 'Equipment visibility')
        .push(filter.includeOlderVersions ? 'Yes' : 'No', 'Show old versions')
        .get();
    } else {
      return new AppliedFilters()
        .push(parseBoolean(filter.hidden) ? 'Hidden' : parseBoolean(filter.hidden) === false ? 'Visible' : '', 'Equipment visibility')
        .get();
    }
  };

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Identifier', key: 'identifier' },
    { label: 'Version number', key: 'versionNumber' },
    { label: 'Hidden', key: 'hidden' },
    { label: 'Make', key: 'make' },
    { label: 'Model', key: 'model' },
    { label: 'Band', key: 'band' },
    { label: 'Channel size', key: 'channelSize' },
    { label: 'Remarks', key: 'remarks' },
  ];

  return (
    <>
      <SectionHeading title="Search results" />
      {props.searchResults?.results && props.searchResults.results.length > 0 ? (
        <>
          <Grid.Row>
            <ExportSearchCommonResults
              headers={isInternalUser ? csvHeaders : csvHeaders.filter((h) => h.key !== 'hidden')}
              data={props.searchResults?.results ?? []}
              currentPage={props.searchCriteria?.page}
              fileNamePrefix="Search Equipment"
            />
          </Grid.Row>
          <Pagination
            filtersSummary={isInternalUser ? filtersSummary(props.searchCriteria) : undefined}
            renderResults={() => (
              <DetailsList columns={withSorting(columns, props.searchCriteria, props.onOrderBy)} items={props.searchResults?.results ?? []} />
            )}
            onPaging={props.onPaging}
            showIncreasedPageSizes
            {...props.searchResults}
          />
        </>
      ) : props.submitted ? (
        <span>Your search returned no results.</span>
      ) : (
        ''
      )}
    </>
  );
};

export default SearchEquipmentResults;
