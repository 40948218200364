import { Checkbox, ComboBox, DefaultButton, Dialog, IComboBox, IComboBoxOption, IComboBoxProps } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { EngineerDto, EngineerDtoApprovalTypeEnum, LicenceDtoLicenceTypeCodeEnum, AssignEngineerDtoToEnum, Link } from 'api-client';

import { hasAction } from '../../../../common/api/hateosUtils';
import { useAuth } from '../../../../common/auth/AuthHook';
import YesNoButtons from '../../../../common/controls/inputs/YesNoButtons';
import { useLicenceApi } from '../../../LicenceApiHooks';
import { useViewLicenceNavigation } from '../../../LicenceRouter';
import { Licence } from '../../CraftLicencePage';
import { engineerName } from './utils';

const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;
const ARC = EngineerDtoApprovalTypeEnum.ARC;
const { ME, ENGINEER, ANOTHER_ENGINEER, RSM_ENGINEERING } = AssignEngineerDtoToEnum;

interface Props {
  licence: Licence;
  engineer?: EngineerDto;
  // The engineer or undefined to RSM Engineering
  onEngineerUpdated: (engineer?: EngineerDto) => void;
}

const AssignEngineerButton: React.FC<Props> = ({ licence, engineer, onEngineerUpdated }) => {
  const [engineers, setEngineers] = useState<EngineerDto[]>();
  const [showAssignDialog, setShowAssignDialog] = useState<boolean>();
  const [hateoasLinks, setHateoasLinks] = useState<Link[] | undefined>(licence.links);

  const licenceType = licence.licenceTypeCode === SPL ? 'spectrum' : 'radio';
  const { hasRole } = useAuth();
  const isAreOrArcRole = hasRole('ROLE_APPROVED_RADIO_ENGINEER') || hasRole('ROLE_APPROVED_RADIO_CERTIFIER');
  const isRreOrSaRole = hasRole('ROLE_RSM_RADIO_ENGINEER') || hasRole('ROLE_SYSTEM_ADMINISTRATOR');
  const isLmOrLoRole = hasRole('ROLE_LICENSING_MANAGER') || hasRole('ROLE_LICENSING_OFFICER');
  const { assignEngineer, getEngineers, getAllEngineers, getEngineerByApprovalId, getLicence } = useLicenceApi();
  const viewLicenceNavigation = useViewLicenceNavigation().navigateToViewLicence;
  const navigateToViewLicence = () => viewLicenceNavigation(licence.id!, licenceType);
  const otherEngineers = engineers?.filter((e) => e.id !== engineer?.id) ?? [];
  const updateHateoasLinks = () => getLicence(licence.id!, licenceType).then((licence) => setHateoasLinks(licence.links));
  const canAssignToMe = hasAction('Assign to me', hateoasLinks);
  const canAssign = hasAction('Assign', hateoasLinks) && !canAssignToMe;
  const canReassign = hasAction('Reassign', hateoasLinks) && !canAssign && !canAssignToMe;
  const isSpl = licence.licenceTypeCode === SPL;
  const outArc = ({ approvalType }: EngineerDto) => approvalType !== ARC;
  const getAvailableEngineers = () =>
    (isAreOrArcRole ? getEngineers : getAllEngineers)().then((engineers) => (isSpl ? engineers.filter(outArc) : engineers));
  const savingSpinner = { showLoadingSpinner: 'Saving...' };

  const assignToMe = () =>
    assignEngineer(licence.id!, { to: ME }, savingSpinner).then(({ id }) => {
      updateHateoasLinks();
      getEngineerByApprovalId(id).then(onEngineerUpdated);
    });

  const assign = (engineer: EngineerDto) =>
    assignEngineer(licence.id!, { to: ENGINEER, id: engineer.id! }, savingSpinner).then(() => {
      updateHateoasLinks();
      onEngineerUpdated(engineer);
    });

  const reassignToRsmEngineering = () =>
    assignEngineer(licence.id!, { to: RSM_ENGINEERING }, savingSpinner).then(() => {
      updateHateoasLinks();
      onEngineerUpdated();
    });

  const reassign = (engineer: EngineerDto) =>
    assignEngineer(licence.id!, { to: ANOTHER_ENGINEER, id: engineer.id }, savingSpinner).then(() => {
      updateHateoasLinks();
      onEngineerUpdated(engineer);
    });

  useEffect(() => {
    if ((canAssign || canReassign) && showAssignDialog && !engineers) {
      getAvailableEngineers().then(setEngineers);
    }
  }, [showAssignDialog]);

  return (
    <>
      {canAssignToMe && <DefaultButton text="Assign to me" id="assign-button" onClick={assignToMe} />}
      {canAssign && (
        <>
          <DefaultButton text="Assign" id="assign-button" onClick={() => setShowAssignDialog(true)} />
          <EngineerSelectionDialog
            isOpen={showAssignDialog}
            engineers={otherEngineers}
            showRsmEngineering={false}
            onSave={(engineer) => {
              assign(engineer!);
              setShowAssignDialog(false);
            }}
            onCancel={() => setShowAssignDialog(false)}
          />
        </>
      )}
      {canReassign && (
        <>
          <DefaultButton text="Re-assign" id="assign-button" onClick={() => setShowAssignDialog(true)} />
          <EngineerSelectionDialog
            isOpen={showAssignDialog}
            engineers={otherEngineers}
            showRsmEngineering={isRreOrSaRole || isLmOrLoRole}
            onSave={(engineer) => {
              (engineer ? reassign(engineer) : reassignToRsmEngineering()).then(() => {
                if (isAreOrArcRole) {
                  navigateToViewLicence();
                }
              });
              setShowAssignDialog(false);
            }}
            onCancel={() => setShowAssignDialog(false)}
          />
        </>
      )}
    </>
  );
};

const EngineerSelectionDialog = (props: {
  isOpen?: boolean;
  onSave: (engineer?: EngineerDto) => void;
  onCancel: () => void;
  engineers: EngineerDto[];
  showRsmEngineering: boolean;
}) => {
  const [selectedRsmEngineering, setSelectedRsmEngineering] = useState<boolean>(false);
  const [selectedEngineer, setSelectedEngineer] = useState<EngineerDto>();

  useEffect(() => {
    setSelectedRsmEngineering(false);
    setSelectedEngineer(undefined);
  }, [props.isOpen]);

  return (
    <Dialog isOpen={props.isOpen} title="Select an engineer">
      {props.showRsmEngineering && <RsmEngineeringCheckbox onChange={setSelectedRsmEngineering} />}
      {!selectedRsmEngineering && <EngineerDropdown engineers={props.engineers} onEngineerChanged={setSelectedEngineer} />}
      <YesNoButtons
        yesText="Save"
        noText="Cancel"
        disableYes={!selectedEngineer && !selectedRsmEngineering}
        onYes={() => props.onSave(selectedRsmEngineering ? undefined : selectedEngineer)}
        onNo={props.onCancel}
        notDangerous
      />
    </Dialog>
  );
};

const RsmEngineeringCheckbox = ({ onChange }: { onChange: (checked: boolean) => void }) => (
  <div style={{ marginBottom: 10 }}>
    <Checkbox label="RSM Engineering" onChange={(_, checked) => onChange(!!checked)} />
  </div>
);

const EngineerDropdown = (props: Partial<IComboBoxProps> & { onEngineerChanged: (engineer: EngineerDto) => void; engineers: EngineerDto[] }) => {
  const engineerOptions: (IComboBoxOption & { engineer: EngineerDto })[] = props.engineers.map((e) => ({
    engineer: e,
    key: e.id!,
    text: engineerName(e),
  }));
  const comboBoxRef = React.useRef<IComboBox>(null);

  return (
    <ComboBox
      componentRef={comboBoxRef}
      label="Engineer"
      placeholder="-- Select a value --"
      {...props}
      options={engineerOptions}
      onChange={(_, option: any) => props.onEngineerChanged(option.engineer)}
    />
  );
};

export default AssignEngineerButton;
