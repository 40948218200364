import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useAppRouter } from 'Routes';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { CaseFinalResultEnum } from 'api-client';

import { Dropdown, Form, Grid2 } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import usePage from 'common/layout/PageHook';
import { AUDIT_RESULT_OPTIONS } from 'common/reference/referenceData';
import { useAuditApi } from 'investigation/audit/hooks';
import { isOptionalString, isRequiredString } from 'investigation/hooks';

type CloseAuditFormProps = {
  id: number;
  finalResult?: CaseFinalResultEnum;
  finalResultNotes?: string;
};

const ActionCardCloseAudit = (audit: CloseAuditFormProps) => {
  const { closeAuditApi } = useAuditApi();
  const { setPageInstruction } = usePage();
  const { navigateToDashboard } = useAppRouter();

  const handleSubmit = (values: CloseAuditFormProps) => {
    closeAuditApi({ id: values.id, finalResult: values.finalResult!!, finalResultNotes: values.finalResultNotes }).then(() => {
      navigateToDashboard();
      setPageInstruction('Case successfully closed.', MessageBarType.success);
    });
  };

  return (
    <Form<CloseAuditFormProps>
      formButtonsOptions={{
        hideBackButton: true,
        hideCancelButton: true,
        hideSubmitButton: true,
      }}
      handleSubmit={handleSubmit}
      hideFormButtonsTop
      id="closeAuditActionCard"
      isInDialog
      initialValues={audit}
      mode="CREATE"
      showFormButtonsBottom
      validationSchema={[
        { name: 'finalResult' },
        {
          name: 'finalResultNotes',
          validationType: 'custom',
          schema: Yup.string().when('finalResult', {
            is: CaseFinalResultEnum.DISCARD,
            then: isRequiredString,
            otherwise: isOptionalString,
          }),
        },
      ]}
    >
      <CloseAuditContent />
    </Form>
  );
};

const CloseAuditContent = () => {
  const { values, resetForm, submitForm } = useFormikContext<CloseAuditFormProps>();

  const resultOptions = AUDIT_RESULT_OPTIONS.filter(({ key }) =>
    [CaseFinalResultEnum.COMPLIANT, CaseFinalResultEnum.DISCARD, CaseFinalResultEnum.NON_COMPLIANT].includes(key),
  );

  const isDiscard = values.finalResult === CaseFinalResultEnum.DISCARD;

  return (
    <MessageBar
      data-automation-id={`close-audit-message-bar`}
      messageBarType={MessageBarType.warning}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="close-audit-action" onClick={() => submitForm()}>
              Close case
            </PrimaryButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <DefaultButton data-automation-id="close-audit-clear" onClick={() => resetForm()}>
              Clear
            </DefaultButton>
          </div>
        </>
      }
    >
      <Text>Check all details have been completed before closing the audit.</Text>
      <Grid2 colGap={5}>
        <Grid2.Col lg={4}>
          <Dropdown label="Audit result" name="finalResult" options={resultOptions} required />
        </Grid2.Col>
        <Grid2.Col lg={8}>
          <TextField
            label={isDiscard ? 'Reason for discarding case' : 'Notes'}
            name="finalResultNotes"
            multiline
            maxLength={400}
            showMaxHint
            required={isDiscard}
          />
        </Grid2.Col>
      </Grid2>
    </MessageBar>
  );
};

export default ActionCardCloseAudit;
