import { LicenceDto, LicenceTypeDto, ServiceTypeDto } from 'api-client';

export const findLicenceType = (licence: Partial<LicenceDto>, serviceTypes: ServiceTypeDto[]): LicenceTypeDto | undefined => {
  const byLicenceTypeId = ({ id }: LicenceTypeDto) => id === licence.licenceTypeId;

  let licenceType = undefined;
  for (let serviceType of serviceTypes) {
    licenceType = serviceType.licenceTypes?.find(byLicenceTypeId) || findLicenceType(licence, serviceType.nextLevelServices ?? []);
    if (licenceType) {
      break;
    }
  }
  return licenceType;
};

export const findPrimaryAssociatedRecordLicenceType = (
  { licenceTypeId }: { licenceTypeId: number },
  serviceTypes: ServiceTypeDto[],
): LicenceTypeDto | undefined => {
  const byLicenceTypeId = ({ associatedRecord }: LicenceTypeDto) => associatedRecord === licenceTypeId;
  let licenceType = undefined;
  for (let serviceType of serviceTypes) {
    licenceType =
      serviceType.licenceTypes?.find(byLicenceTypeId) ||
      findPrimaryAssociatedRecordLicenceType({ licenceTypeId }, serviceType.nextLevelServices ?? []);
    if (licenceType) {
      break;
    }
  }
  return licenceType;
};

export const licenceTypeFullDescription = (licence?: LicenceDto) => {
  const { serviceLevel1, serviceLevel2, serviceLevel3, serviceLevel4 } = licence?.selectedServiceLevels ?? {};

  return (
    [serviceLevel1?.description, serviceLevel2?.description, serviceLevel3?.description, serviceLevel4?.description].filter(Boolean).join('/') +
    ` (${serviceLevel4?.code})`
  );
};

export const licenceTypeShortDescription = (licence?: LicenceDto) => {
  const { serviceLevel1, serviceLevel2, serviceLevel3, serviceLevel4 } = licence?.selectedServiceLevels ?? {};
  if (serviceLevel4?.description) {
    return serviceLevel4?.description;
  }
  return [serviceLevel1?.description, serviceLevel2?.description, serviceLevel3?.description].filter(Boolean).join('/');
};

export const reservedLicenceTypeIds = [157, 162];
export const reservedLicenceCompositionMatrixIds = [67, 68, 70, 71];

export const canHaveReservedLicence = (licenceTypeId: number = 0, compositionMatrixId: number = 0): boolean =>
  !!licenceTypeId &&
  !!compositionMatrixId &&
  !!reservedLicenceTypeIds.find((id) => id === licenceTypeId) &&
  !!reservedLicenceCompositionMatrixIds.find((id) => id === compositionMatrixId);

// Not: Aircraft, Land Simplex - General Channels, Ship - Compulsory & Voluntary, General user licence (Radio), General user licence (Spectrum)
export const canHaveSystemId = (licenceTypeId: number = 0) => ![15, 211, 120, 121, 197, 198].find((id) => id === licenceTypeId);

// Not: Aircraft, Land Simplex - General Channels, Ship - Compulsory & Voluntary, General user licence (Radio), General user licence (Spectrum), Other (Spectrum)
export const canBeReservedLicence = (licenceTypeId: number = 0) => ![15, 211, 120, 121, 197, 198, 178].find((id) => id === licenceTypeId);

export const generalPurposeBandsOnly = (licenceTypeId: number = 0) => licenceTypeId === 211;

export const isSatelliteType = (licenceTypeId: number = 0) => licenceTypeId === 55 || licenceTypeId === 194;
