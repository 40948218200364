import { IDropdownOption } from '@fluentui/react';
import { Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { SpectrumSearchCriteriaSpectrumTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import Dropdown from 'common/controls/inputs/Dropdown';
import { TextField } from 'common/controls/inputs/TextField';
import ITUServiceType from 'licence_management/reference_data/spectrum_record/ITUServiceTypes';
import SearchForm from 'search/SearchForm';

import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';

interface Props {
  onClear?: () => void;
  showAddSpectrumButton?: boolean;
  showITUServiceType?: boolean;
  onSpectrumTypeChange: (key: any) => void;
}

const generalPurposeTypeOptions = [
  { key: '', text: '' },
  { key: 'true', text: 'Yes' },
  { key: 'false', text: 'No' },
] as IDropdownOption[];

const types = Object.values(SpectrumSearchCriteriaSpectrumTypeEnum);

const spectrumTypeOptions = [
  { key: '', text: '' },
  ...types.filter((t) => t !== SpectrumSearchCriteriaSpectrumTypeEnum.ChannelFrequency).map((t) => ({ key: t, text: t })),
];

const visibilityOptions = [
  { key: '', text: '' },
  { key: 'Current', text: 'Current' },
  { key: 'Future', text: 'Future' },
  { key: 'Previous', text: 'Previous' },
] as IDropdownOption[];

const SearchSpectrumRecordForm: React.FC<Props> = (props) => {
  const { hasPermission, isInternalUser, hasRole } = useAuth();

  const { navigateToCreateSpectrumRecord } = useLicenceReferenceDataNavigator();

  const formikContext = useFormikContext<any>();

  const addSpectrumButtonProps =
    props.showAddSpectrumButton &&
    hasPermission('CREATE_SPEC_RECORD') &&
    !hasRole('ROLE_APPROVED_RADIO_ENGINEER') &&
    !hasRole('ROLE_APPROVED_RADIO_CERTIFIER')
      ? [{ id: 'add-spectrum', text: 'Add a Spectrum Record', onClick: () => navigateToCreateSpectrumRecord() }]
      : undefined;

  React.useEffect(() => {
    if (formikContext.values.ituServiceId) {
      formikContext.setFieldValue('ituServiceId', +formikContext.values.ituServiceId);
    }
    if (formikContext.values.refFrequencyExactMatch) {
      formikContext.setFieldValue('refFrequencyExactMatch', JSON.parse(formikContext.values.refFrequencyExactMatch));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikContext.values.ituServiceId, formikContext.values.refFrequencyExactMatch]);

  return (
    <>
      <SearchForm
        id="search-spectrum-form"
        mode="STAND_ALONE"
        title="Search spectrum"
        hideSearchBox={true}
        onClear={props.onClear}
        additionalButtons={addSpectrumButtonProps}
        disableErrorMessage
      >
        <Grid.Row>
          <Grid.Col sm={12} lg={3}>
            <Dropdown
              name="spectrumType"
              label="Spectrum type"
              options={spectrumTypeOptions}
              onChange={(key: any) => {
                props.onSpectrumTypeChange(key);
              }}
            />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <Dropdown name="generalPurpose" label="General use" options={generalPurposeTypeOptions} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {props.showITUServiceType && (
            <Grid.Col sm={12} lg={4}>
              <ITUServiceType name="ituServiceId" label="ITU Service type" />
            </Grid.Col>
          )}
          {(formikContext.values.spectrumType === 'Channel' || formikContext.values.spectrumType === 'Band') && (
            <Grid.Col sm={12} lg={3}>
              <TextField label={formikContext.values.spectrumType} name="channel" maxLength={10} />
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row>
          {isInternalUser && (
            <Grid.Col sm={12} lg={3}>
              <Dropdown name="visibility" label="Spectrum visibility" options={visibilityOptions} />
            </Grid.Col>
          )}

          <Grid.Col sm={12} lg={3}>
            <TextField label="Frequency (MHz)" name="refFrequency" maxLength={13} errorImmediate />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <Label style={{ marginBottom: 6 }}>&nbsp;</Label>
            <Checkbox label="Exact match on Ref. Freq. (MHz)" name="refFrequencyExactMatch" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12} lg={3}>
            <TextField label="Frequency range (MHz)" name="refFrequencyFrom" maxLength={13} errorImmediate placeholder="From" />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <Label>&nbsp;</Label>
            <TextField name="refFrequencyTo" maxLength={13} placeholder="To" errorImmediate />
          </Grid.Col>
        </Grid.Row>
      </SearchForm>
    </>
  );
};

export default SearchSpectrumRecordForm;
