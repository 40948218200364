import { Text } from '@fluentui/react';
import React from 'react';

import { GeoreferenceTypeEnum, OtherPreference, SearchPreference } from 'api-client';
import { UserDto } from 'api-client/dist/api';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import ChoiceGroup from 'common/controls/inputs/ChoiceGroup';
import Dropdown from 'common/controls/inputs/Dropdown';
import { SectionHeading, SubSectionHeading } from 'common/layout/SectionHeadings';
import { georeferenceTypes } from 'licence_management/reference_data/location/locationSupportData';

const ascDescOptions = [
  { key: 'false', text: 'Ascending' },
  { key: 'true', text: 'Descending' },
];

export const searchLicenceOptions = [
  { key: 1, text: 'Channel', orderBy: 'channel' },
  { key: 2, text: 'Grid reference', orderBy: 'gridReference' },
  { key: 3, text: 'Licence Id', orderBy: 'id' },
  { key: 4, text: 'Licence number', orderBy: 'licenceNo' },
  { key: 5, text: 'Licence type', orderBy: 'licenceTypeDescription' },
  { key: 6, text: 'Licensee', orderBy: 'licensee' },
  { key: 7, text: 'Location', orderBy: 'location' },
  { key: 8, text: 'Ref frequency', orderBy: 'refFrequency' },
  { key: 51, text: 'TX/RX', orderBy: 'configType' },
];

export const areaSearchLicenceOptions = [
  { key: 11, text: 'Distance (km)', orderBy: 'distance' },
  { key: 13, text: 'Licence Id', orderBy: 'id' },
  { key: 14, text: 'Licence number', orderBy: 'licenceNo' },
  { key: 15, text: 'Licence type', orderBy: 'licenceTypeDescription' },
  { key: 17, text: 'Location', orderBy: 'location' },
  { key: 18, text: 'Ref frequency', orderBy: 'refFrequency' },
];

export const searchManagementRightOptions = [
  { key: 19, text: 'Commencement', orderBy: 'commencementDate' },
  { key: 20, text: 'Expiry', orderBy: 'expiryDate' },
  { key: 21, text: 'High frequency', orderBy: 'upperBound' },
  { key: 22, text: 'Low frequency', orderBy: 'lowerBound' },
  { key: 23, text: 'Manager', orderBy: 'manager' },
  { key: 24, text: 'MR number', orderBy: 'id' },
  { key: 25, text: 'Status', orderBy: 'managementRightsStatus' },
];

export const searchCertificateOptions = [
  { key: 51, text: 'Approved radio examiner/RSM', orderBy: 'approvalTypeId' },
  { key: 52, text: 'Callsign', orderBy: 'callsign' },
  { key: 53, text: 'Certificate number', orderBy: 'certificateNumber' },
  { key: 54, text: 'Certificate status', orderBy: 'status' },
  { key: 55, text: 'Certificate type', orderBy: 'type' },
  { key: 56, text: 'Client name', orderBy: 'clientName' },
  { key: 57, text: 'Client number', orderBy: 'clientId' },
];

export const searchCallsignOptions = [
  { key: 26, text: 'Callsign', orderBy: 'callsign' },
  { key: 34, text: 'Vessel name', orderBy: 'vesselName' },
  { key: 31, text: 'MMSI', orderBy: 'mmsi' },
  { key: 61, text: 'SCN', orderBy: 'scn' },
  { key: 30, text: 'Client name', orderBy: 'clientName' },
  { key: 62, text: 'Associated type', orderBy: 'associatedEntityType' },
  { key: 63, text: 'Associated id', orderBy: 'associatedEntityId' },
  { key: 64, text: 'Expiry date', orderBy: 'expiryDate' },
];

export const searchClientOptions = [
  { key: 35, text: 'Billing name', orderBy: 'billingName' },
  { key: 36, text: 'Client name', orderBy: 'clientName' },
  { key: 37, text: 'Client number', orderBy: 'id' },
  { key: 50, text: 'Client type', orderBy: 'type' },
];

export const searchLicenceToModifyOptions = [
  { key: 38, text: 'Channel', orderBy: 'channel' },
  { key: 39, text: 'Grid reference', orderBy: 'gridReference' },
  { key: 40, text: 'Licence Id', orderBy: 'id' },
  { key: 41, text: 'Licence number', orderBy: 'licenceNo' },
  { key: 42, text: 'Licence type', orderBy: 'licenceTypeDescription' },
  { key: 43, text: 'Location', orderBy: 'location' },
  { key: 44, text: 'Ref frequency', orderBy: 'refFrequency' },
  { key: 45, text: 'TX/RX', orderBy: 'configType' },
];

export const searchAtonOptions = [
  { key: 81, text: 'MMSI', orderBy: 'mmsi' },
  { key: 82, text: 'AtoN/MAtoN name', orderBy: 'atonName,mmsi' },
  { key: 83, text: 'Latitude', orderBy: 'latitude,longitude,mmsi' },
  { key: 84, text: 'Longitude', orderBy: 'longitude,latitude,mmsi' },
  { key: 85, text: 'Client name', orderBy: 'clientName,mmsi' },
  { key: 86, text: 'AtoN record status', orderBy: 'status,mmsi' },
];

export const searchRenewalFeeOptions = [
  { key: 91, text: 'Licence holder', orderBy: 'letterNumber' },
  { key: 92, text: 'Letter number', orderBy: 'letterNumber' },
  { key: 93, text: 'Licence number', orderBy: 'licenceNumber' },
  { key: 94, text: 'Licence type', orderBy: 'licenceType' },
  { key: 95, text: 'Fee status', orderBy: 'feeStatus' },
  { key: 96, text: 'Frequency range (MHz) from', orderBy: 'refFrequencyFrom' },
  { key: 97, text: 'To', orderBy: 'refFrequencyTo' },
  { key: 98, text: 'Frequency (MHz) (Exact match)', orderBy: 'refFrequency' },
  { key: 98, text: 'Channel', orderBy: 'channel' },
];

export const searchApplicationFeeOptions = [
  { key: 101, text: 'Application no.', orderBy: 'applicationNumber,licenceId asc' },
  { key: 102, text: 'Licence id', orderBy: 'licenceId' },
  { key: 103, text: 'Licence type', orderBy: 'licenceTypeDescription' },
];

const resultsPerPageOptions = [20, 50, 100, 200, 300, 500].map((v) => ({ key: v, text: '' + v }));

export const getPreferenceGeoReferenceType = (otherPreference: OtherPreference) => {
  const preferredOption = otherPreference?.geoRefTypeIdentifier ?? defaultUserPreference.otherPreference.geoRefTypeIdentifier;
  const georeferenceType = GeoreferenceTypeEnum[preferredOption as keyof typeof GeoreferenceTypeEnum];
  return georeferenceType;
};

export const getPreferenceSortAndPageSize = (searchPreference: SearchPreference, otherPreference: OtherPreference, options: any) => {
  const preferredOption = options.find((o: any) => o.key === searchPreference?.searchColumnId);
  const orderBy = `${preferredOption!.orderBy}${searchPreference.descending === true ? ' desc' : ''}`;
  const pageSize = otherPreference.searchResultsPerPage;
  const isRelevanceSort = searchPreference.isRelevanceSort;
  return {
    orderBy,
    pageSize,
    isRelevanceSort,
  };
};

export const defaultUserPreference = {
  otherPreference: {
    searchResultsPerPage: 20,
    geoRefTypeIdentifier: 'T',
  },
  searchLicencePreference: {
    searchId: 1,
    searchColumnId: 3,
    descending: true,
    isRelevanceSort: true,
  },
  areaSearchLicencePreference: {
    searchId: 2,
    searchColumnId: 11,
    descending: false,
    isRelevanceSort: false,
  },
  searchManagementRightPreference: {
    searchId: 3,
    searchColumnId: 22,
    descending: false,
    isRelevanceSort: false,
  },
  searchCertificatePreference: {
    searchId: 7,
    searchColumnId: 53,
    descending: true,
    isRelevanceSort: false,
  },
  searchCallsignPreference: {
    searchId: 4,
    searchColumnId: 26,
    descending: false,
    isRelevanceSort: false,
  },
  searchClientPreference: {
    searchId: 5,
    searchColumnId: 36,
    descending: false,
    isRelevanceSort: true,
  },
  searchLicenceToModifyPreference: {
    searchId: 6,
    searchColumnId: 41,
    descending: true,
    isRelevanceSort: false,
  },
  searchAtonPreference: {
    searchId: 8,
    searchColumnId: 81,
    descending: false,
    isRelevanceSort: false,
  },
  searchRenewalFeePreference: {
    searchId: 9,
    searchColumnId: 91,
    descending: false,
    isRelevanceSort: false,
  },
  searchApplicationFeePreference: {
    searchId: 10,
    searchColumnId: 101,
    descending: false,
    isRelevanceSort: false,
  },
};

const booleanToString = (v: any) => (v.descending ? { ...v, descending: 'true' } : { ...v, descending: 'false' });

export const wrapUserDto = (userDto: UserDto) => {
  const up = userDto.userPreference ? userDto.userPreference : defaultUserPreference;
  return {
    ...userDto,
    userPreference: {
      searchLicencePreference: booleanToString(up.searchLicencePreference ?? defaultUserPreference.searchLicencePreference),
      areaSearchLicencePreference: booleanToString(up.areaSearchLicencePreference ?? defaultUserPreference.areaSearchLicencePreference),
      searchCertificatePreference: booleanToString(up.searchCertificatePreference ?? defaultUserPreference.searchCertificatePreference),
      searchManagementRightPreference: booleanToString(up.searchManagementRightPreference ?? defaultUserPreference.searchManagementRightPreference),
      searchCallsignPreference: booleanToString(up.searchCallsignPreference ?? defaultUserPreference.searchCallsignPreference),
      searchAtonPreference: booleanToString(up.searchAtonPreference ?? defaultUserPreference.searchAtonPreference),
      searchClientPreference: booleanToString(up.searchClientPreference ?? defaultUserPreference.searchClientPreference),
      searchLicenceToModifyPreference: booleanToString(up.searchLicenceToModifyPreference ?? defaultUserPreference.searchLicenceToModifyPreference),
      searchRenewalFeePreference: booleanToString(up.searchRenewalFeePreference ?? defaultUserPreference.searchRenewalFeePreference),
      searchApplicationFeePreference: booleanToString(up.searchApplicationFeePreference ?? defaultUserPreference.searchApplicationFeePreference),
      otherPreference: userDto.userPreference?.otherPreference ? userDto.userPreference.otherPreference : defaultUserPreference.otherPreference,
    },
  };
};

const dropDownStyles = {
  label: {
    fontWeight: 'normal',
  },
};

const UserPreference = () => {
  const { hasPermission, hasEADAOnAKeyClient, hasAnyRole } = useAuth();
  const isAreArcArx = hasAnyRole(['ROLE_APPROVED_RADIO_ENGINEER', 'ROLE_APPROVED_RADIO_CERTIFIER', 'ROLE_APPROVED_RADIO_EXAMINER']);
  const showExportMaskSection = hasPermission('VIEW_UPDATE_USER_PROFILE') || isAreArcArx || hasEADAOnAKeyClient;

  return (
    <>
      <SectionHeading title={'User preference settings'} />
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown
            name="userPreference.otherPreference.searchResultsPerPage"
            label="Search results per page"
            options={resultsPerPageOptions}
            defaultValue={20}
          />
        </Grid.Col>
      </Grid.Row>
      {showExportMaskSection && (
        <Grid.Row>
          <Grid.Col lg={2}>
            <Checkbox name={'userPreference.otherPreference.showAllDataInLicenceExport'} label={'Show all data in licence export'} />
          </Grid.Col>
        </Grid.Row>
      )}
      <SubSectionHeading title={'Preferred sort order'} />
      <Grid.Row>
        <Grid.Col lg={3}>
          <Text>
            <strong>Search Licences</strong>
          </Text>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={2}>
          <Checkbox name={'userPreference.searchLicencePreference.isRelevanceSort'} label={'Sort by relevance'} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown
            styles={dropDownStyles}
            name="userPreference.searchLicencePreference.searchColumnId"
            label="Then by"
            options={searchLicenceOptions}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.searchLicencePreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown
            name="userPreference.areaSearchLicencePreference.searchColumnId"
            label="Area search licences"
            options={areaSearchLicenceOptions}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.areaSearchLicencePreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown
            name="userPreference.searchManagementRightPreference.searchColumnId"
            label="Search management rights"
            options={searchManagementRightOptions}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.searchManagementRightPreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown name="userPreference.searchCertificatePreference.searchColumnId" label="Search certificates" options={searchCertificateOptions} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.searchCertificatePreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown name="userPreference.searchCallsignPreference.searchColumnId" label="Search callsigns" options={searchCallsignOptions} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.searchCallsignPreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown name="userPreference.searchAtonPreference.searchColumnId" label="Search AtoNs" options={searchAtonOptions} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.searchAtonPreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={3}>
          <Text>
            <strong>Search Clients</strong>
          </Text>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={2}>
          <Checkbox name={'userPreference.searchClientPreference.isRelevanceSort'} label={'Sort by relevance'} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown
            styles={dropDownStyles}
            name="userPreference.searchClientPreference.searchColumnId"
            label="Then by"
            options={searchClientOptions}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup direction="row" name="userPreference.searchClientPreference.descending" label="&nbsp;" options={ascDescOptions} />
        </Grid.Col>
      </Grid.Row>

      <SubSectionHeading title={'Location setting'} />
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <Dropdown name="userPreference.otherPreference.geoRefTypeIdentifier" label="Default geo-ref type" options={georeferenceTypes} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};
export default UserPreference;
