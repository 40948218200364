import { useFormikContext } from 'formik';
import React from 'react';

import { CaseFinalResultEnum } from 'api-client';

import { Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { convertResult } from 'investigation/hooks';
import { AuditFormProps } from 'investigation/interfaces';

const AuditFinalResultSection = () => {
  const { values } = useFormikContext<AuditFormProps>();

  return (
    <>
      <SectionHeading title="Audit result" />
      <Grid2>
        <Grid2.Col colStart={1} lg={3}>
          <ReadOnlyField label="Audit result" name="finalResult" renderFormat={convertResult} />
        </Grid2.Col>
        <Grid2.Col lg={9}>
          <ReadOnlyField
            label={values.finalResult === CaseFinalResultEnum.DISCARD ? 'Reason for discarding case' : 'Notes'}
            name="finalResultNotes"
          />
        </Grid2.Col>
      </Grid2>
    </>
  );
};

export default AuditFinalResultSection;
