import { mergeStyleSets } from '@fluentui/merge-styles';
import { CSSProperties } from 'react';

import { RRFTheme } from 'common/theme/RRFTheme';

import border from './border.png';

export interface IComponentClassNames {
  root: string;
  title: string;
  personaContainer: string;
  menusContainer: string;
  menu: string;
  logos: string;
  realMeButton: string;
  realMelogo: string;
  mbieButton: string;
  mbielogo: string;
  logonText: string;
}

export const getClassNames = (theme: RRFTheme): IComponentClassNames =>
  mergeStyleSets({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: `${theme.semanticColors.menuHeader} url(${border}) repeat-x 0 bottom`,
      gridArea: 'nav',
      padding: '15px',
    },
    title: { color: theme.palette.white } as CSSProperties,
    personaContainer: {} as CSSProperties,
    menusContainer: {
      backgroundColor: 'transparent',
      justifySelf: 'center',
      color: 'transparent',
    },
    menu: {
      backgroundColor: 'transparent',
      color: theme.palette.white,
      borderColor: 'transparent',
    },
    logos: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignContent: 'center',
      rowGap: 0,
      //columnGap: '20px',
      flexWrap: 'wrap',
      background: theme.semanticColors.menuHeader,
      padding: '5px',
    } as React.CSSProperties,
    realMeButton: {
      marginLeft: '10px',
      display: 'inline-block',
      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
    } as React.CSSProperties,
    realMelogo: {
      height: '30px',
      width: '30px',
      cursor: 'pointer',
    } as React.CSSProperties,
    mbieButton: {
      marginLeft: '20px',
      display: 'inline-block',
      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
    } as React.CSSProperties,
    mbielogo: {
      height: '30px',
      width: '30px',
      cursor: 'pointer',
    } as React.CSSProperties,
    logonText: {
      color: theme.palette.white,
      marginTop: '5px',
      fontWeight: 'bold',
      cursor: 'pointer',
      fontSize: '14px',
      // borderRight: '1px solid #dcddde',
      padding: '2px',
      margingRight: '2px',
      paddingRight: '10px',
      backgroundColor: 'inherit',
      border: 'none',
    } as React.CSSProperties,
  });
