import {
  PersonAndInternalUseFields,
  OrganisationAndInternalUseFields,
  OrganisationDto,
  PersonDto,
  OrgUserDto,
  ContactDto,
  PersonAndReasonFields,
  OrganisationAndReasonFields,
  EventCriteria,
  EventResults,
  ExportMaskRequestDto,
  AttributeMaskDto,
  MaintainMaskDefaultDto,
} from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { clientApi, nzbnApi, orgUsersApi } from 'common/api/api';

export const useClientApi = () => {
  const { aroundApi } = useApi();

  return {
    createPerson: (person: PersonDto, config?: ApiConfig) =>
      aroundApi(clientApi.createPerson({ ...person, clientDiscriminator: 'PersonDto' }), config),
    maintainPersonExternal: (id: number, person: PersonDto, config?: ApiConfig) =>
      aroundApi(clientApi.maintainPerson(id, { ...person, clientDiscriminator: 'PersonDto' }), config),
    createOrganisation: (organisation: OrganisationDto, config?: ApiConfig) =>
      aroundApi(clientApi.createOrganisation({ ...organisation, clientDiscriminator: 'OrganisationDto' }), config),
    maintainOrganisationExternal: (id: number, organisation: OrganisationDto, config?: ApiConfig) =>
      aroundApi(clientApi.maintainOrganisation(id, { ...organisation, clientDiscriminator: 'OrganisationDto' }), config),
    createPersonInternal: (person: PersonAndInternalUseFields, config?: ApiConfig) =>
      aroundApi(clientApi.createPersonInternal({ ...person, clientDiscriminator: 'PersonAndInternalUseFields' }), config),
    maintainPersonInternal: (id: number, person: PersonAndInternalUseFields, config?: ApiConfig) =>
      aroundApi(clientApi.maintainPersonInternal(id, { ...person, clientDiscriminator: 'PersonAndInternalUseFields' }), config),
    createOrganisationInternal: (organisation: OrganisationAndInternalUseFields, config?: ApiConfig) =>
      aroundApi(clientApi.createOrganisationInternal({ ...organisation, clientDiscriminator: 'OrganisationAndInternalUseFields' }), config),
    maintainOrganisationInternal: (id: number, organisation: OrganisationAndInternalUseFields, config?: ApiConfig) =>
      aroundApi(clientApi.maintainOrganisationInternal(id, { ...organisation, clientDiscriminator: 'OrganisationAndInternalUseFields' }), config),
    getClientInternal: (id: number, config?: ApiConfig) => aroundApi(clientApi.getClientInternal(id), config),
    getClientExternal: (id: number, config?: ApiConfig) => aroundApi(clientApi.getClientExternal(id), config),
    searchNzbnEntity: (query: string, config?: ApiConfig) =>
      aroundApi(nzbnApi.searchNzbnEntity(query), { disableErrorMessage: true, callerHandleErrors: true, ...config }),
    getNzbnEntity: (nzbn: number, config?: ApiConfig) =>
      aroundApi(nzbnApi.getNzbnEntity(nzbn), { showLoadingSpinner: true, callerHandleErrors: true, ...config }),
    getOrgUsersInternal: (id: number, config?: ApiConfig) => aroundApi(orgUsersApi.getOrgUsersInternal(id), { disableErrorMessage: true, ...config }),
    getOrgUsersExternal: (id: number, config?: ApiConfig) => aroundApi(orgUsersApi.getOrgUsersExternal(id), { disableErrorMessage: true, ...config }),
    createOrganisationUserInternal: (organisationUser: OrgUserDto, config?: ApiConfig) =>
      aroundApi(clientApi.createOrgUserInternal(organisationUser), config),
    createOrganisationUserExternal: (organisationUser: OrgUserDto, config?: ApiConfig) =>
      aroundApi(clientApi.createOrgUserExternal(organisationUser), config),
    updateOrgUserInternal: (organisationUser: OrgUserDto, config?: ApiConfig) => aroundApi(clientApi.updateOrgUserInternal(organisationUser), config),
    updateOrgUserExternal: (organisationUser: OrgUserDto, config?: ApiConfig) => aroundApi(clientApi.updateOrgUserExternal(organisationUser), config),
    removeOrgUserInternal: (organisationUser: OrgUserDto, config?: ApiConfig) => aroundApi(clientApi.removeOrgUserInternal(organisationUser), config),
    removeOrgUserExternal: (organisationUser: OrgUserDto, config?: ApiConfig) => aroundApi(clientApi.removeOrgUserExternal(organisationUser), config),
    saveContact: (id: number, contact: ContactDto, config?: ApiConfig) => aroundApi(clientApi.saveContact(id, contact), config),
    removeContact: (id: number, contact: ContactDto, config?: ApiConfig) => aroundApi(clientApi.removeContact(id, contact), config),
    createPersonSelf: (person: PersonAndReasonFields, config?: ApiConfig) =>
      aroundApi(clientApi.submitPersonSelf({ ...person, clientDiscriminator: 'PersonAndReasonFields' }), config),
    createOrganisationSelf: (organisation: OrganisationAndReasonFields, config?: ApiConfig) =>
      aroundApi(clientApi.submitOrganisationSelf({ ...organisation, clientDiscriminator: 'OrganisationAndReasonFields' }), config),
    getClientEventsExternal: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(clientApi.getEventsExternal(entityId, criteria), config),
    getClientEventsInternal: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(clientApi.getEventsInternal(entityId, criteria), config),
    areAssetsLocked: (clientId: number, config?: ApiConfig): Promise<boolean> => aroundApi(clientApi.areAssetsLocked(clientId), config),
    resetActivationCode: (organisationUser: OrgUserDto, config?: ApiConfig): Promise<void> => {
      return aroundApi(clientApi.resetActivationCode(organisationUser), config);
    },
    getExportMasksByUser: (userId: number, config?: ApiConfig) => aroundApi(clientApi.getExportMasksByUser(userId), config),
    getExportMaskById: (id: number, config?: ApiConfig) => aroundApi(clientApi.getExportMaskById(id), config),
    createExportMask: (exportMaskRequestDto: ExportMaskRequestDto, config?: ApiConfig) =>
      aroundApi(clientApi.createExportMask(exportMaskRequestDto), config),
    updateExportMask: (exportMaskRequestDto: ExportMaskRequestDto, config?: ApiConfig) =>
      aroundApi(clientApi.updateExportMask(exportMaskRequestDto), config),
    renameExportMask: (attributeMaskDto: AttributeMaskDto, config?: ApiConfig) => aroundApi(clientApi.renameExportMask(attributeMaskDto), config),
    deleteExportMask: (maskId: number, config?: ApiConfig) => aroundApi(clientApi.deleteExportMask(maskId), config),
    maintainMaskDefault: (maintainMaskDefaultDto: MaintainMaskDefaultDto, config?: ApiConfig) =>
      aroundApi(clientApi.maintainMaskDefault(maintainMaskDefaultDto, config)),
  };
};
