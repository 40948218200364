import * as yup from 'yup';
import { requiredString, requiredValidRangeWithDecimal, validRangeWithDecimal } from '../../../common/validation/yupUtils';

export const antennaSchema = yup.object().shape({
  make: requiredString,
  model: requiredString,
  type: requiredString,
  lowFrequency: requiredValidRangeWithDecimal(0.009, 100000, 6, 'Must be six decimal places and in the range 0.009000 - 100000.000000'),
  highFrequency: requiredValidRangeWithDecimal(0.009, 100000, 6, 'Must be six decimal places and in the range 0.009000 - 100000.000000').test(
    'compareLowFrequency',
    'High frequency must be greater than low frequency.',
    function (this: yup.TestContext, value) {
      return +this.parent['lowFrequency'] < +value!;
    },
  ),
  beamWidth: requiredValidRangeWithDecimal(0.01, 360, 2, 'Must be two decimal places and in the range 0.01 to 360.00'),
  frontBackRatio: requiredValidRangeWithDecimal(0, 99.9, 1, 'Must be one decimal place and in the range 0.0 to 99.9'),
  diameter: validRangeWithDecimal(0, 99.99, 2, 'Must be two decimal places and in the range 0.00 to 99.99'),
  gainHigh: validRangeWithDecimal(-10.0, 99.9, 1, 'Must be one decimal place and in the range -10.0 to 99.9'),
  gainLow: validRangeWithDecimal(-10.0, 99.9, 1, 'Must be one decimal place and in the range -10.0 to 99.9'),
  gainMid: validRangeWithDecimal(-10.0, 99.9, 1, 'Must be one decimal place and in the range -10.0 to 99.9'),
  xPol: validRangeWithDecimal(0, 99.9, 1, 'Must be one decimal place and in the range 0.0 to 99.9'),
});
