import { ITag } from '@fluentui/react';
import _ from 'lodash';
import React from 'react';

import { LocationSearchResult } from 'api-client';

import { usePublicApi } from '../../../../public/PublicApiHook';
import TagPicker, { TagPickerProps } from '../TagPicker';

interface Props extends Pick<TagPickerProps, 'name' | 'label' | 'allowFreeForm' | 'itemLimit' | 'tagToFieldValue' | 'fieldValueToTag'> {
  onChange?: (locs: LocationSearchResult[]) => void;
  canCreatePointLocation?: boolean;
  canCreateDefinedAreaLocation?: boolean;
}

export const LocationTagPicker: React.FC<Props> = (props) => {
  const { searchLocation } = usePublicApi();

  const asLocationTag = (key: string) => {
    const [id, name] = `${key}=>`.split('=>');
    return name ? { key, name: `${id} ${name}` } : { key, name: key };
  };

  const handleResolveSuggestions = async (searchText: string, selectedItems?: ITag[]) => {
    const notASelectedItem = (suggestion: ITag) => !selectedItems?.find((s) => suggestion.key === s.key && suggestion.name === s.name);

    const asLocationTag = ({ id, name, ...rest }: LocationSearchResult) => ({
      key: `${id}=>${name}`,
      name: `${id} ${name}`,
      id,
      ...rest,
    });

    if (!_.isEmpty(searchText)) {
      return searchLocation({ searchText, pageSize: 7 }).then((searchResults) =>
        searchResults.results
          .map(asLocationTag)
          .filter(notASelectedItem)
          .concat(
            !props.canCreatePointLocation
              ? []
              : [
                  {
                    key: '',
                    name: '',
                    id: -100,
                  },
                  {
                    key: '',
                    name: 'Or Add Point Location',
                    id: 0,
                  },
                ],
          )
          .concat(
            !props.canCreateDefinedAreaLocation
              ? []
              : [
                  {
                    key: '',
                    name: 'Or Add Defined Area Location',
                    id: -1,
                  },
                ],
          )
          .slice(0, 10),
      );
    }
  };

  return (
    <TagPicker
      label="Location"
      onResolveSuggestions={handleResolveSuggestions}
      inputProps={{ placeholder: 'Start typing to search for a location' }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for locations',
        suggestionsHeaderText: 'Suggested locations',
        noResultsFoundText: 'No locations found',
      }}
      fieldValueToTag={asLocationTag}
      alwaysValidate
      {...props}
      onChange={props.onChange ? (items: any) => props.onChange!(items as LocationSearchResult[]) : undefined}
    />
  );
};

export default LocationTagPicker;
