import * as React from 'react';
import { useEffect, useState } from 'react';

import { ManagementRightsSearchResult } from 'api-client';
import { ManagementRightsStatusEnum } from 'api-client';

import { Grid } from 'ui-library';

import ErrorLabel from '../../../common/controls/inputs/ErrorLabel';
import ReadonlyData from '../../../common/controls/items/ReadonlyData';
import Card from '../../../common/controls/surfaces/Card';
import MoreOrLess from '../../../common/controls/surfaces/MoreOrLess';
import { SubSectionHeading } from '../../../common/layout/SectionHeadings';
import { formatDateFromISO } from '../../../common/utils/dateUtils';
import { isBeforeToday } from '../../../common/validation/yupUtils';
import { usePublicApi } from '../../../public/PublicApiHook';
import EmbeddedSearchManagementRights from '../../../search/management_rights/EmbeddedSearchManagementRights';

const { Current, Registered } = ManagementRightsStatusEnum;

interface Props {
  onSelected: (item: ManagementRightsSearchResult) => void;
  initialSelected?: number | ManagementRightsSearchResult;
  readonly?: boolean;
}

const ManagementRightSection = ({ onSelected, initialSelected, readonly }: Props) => {
  const [managementRight, setManagementRight] = useState<ManagementRightsSearchResult>();
  const { searchManagementRightsPublic } = usePublicApi();
  const [prefetching, setPrefetching] = useState<boolean>(typeof initialSelected === 'number');

  const setSelected = (item: ManagementRightsSearchResult) => {
    setManagementRight(item);
    onSelected(item);
  };

  useEffect(() => {
    if (typeof initialSelected === 'number') {
      searchManagementRightsPublic({ id: initialSelected, managementRightsStatuses: [Current, Registered] }).then((results) => {
        const item = results.results?.pop();
        if (item && (!item.expiryDate || !isBeforeToday(item.expiryDate))) {
          setSelected(item);
        }
        setPrefetching(false);
      });
    } else {
      setManagementRight(initialSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 10 }}>
      <Card>
        <SubSectionHeading title="Management Right" hint="Management right is mandatory for Spectrum licences" required />
        {!readonly && !prefetching && (
          <>
            <ErrorLabel name="managementRightId" showIcon />
            <MoreOrLess
              id="management-right-search"
              key={managementRight?.id}
              initiallyMore={!managementRight}
              moreText="Search"
              lessText="Hide Search"
              more={
                <Card darker>
                  <EmbeddedSearchManagementRights onSelect={setSelected} />
                </Card>
              }
            />
          </>
        )}
        {managementRight && (
          <>
            <Grid.Row>
              <Grid.Col sm={6} lg={3}>
                <ReadonlyData label="MR Number" value={managementRight?.id} emptyFormat=" " />
              </Grid.Col>
              <Grid.Col sm={6} lg={3}>
                <ReadonlyData label="Status" value={managementRight?.managementRightsStatus} emptyFormat=" " />
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <ReadonlyData label="Manager" value={managementRight?.manager} emptyFormat=" " />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm={6} lg={3}>
                <ReadonlyData label="Commencement" value={formatDateFromISO(managementRight?.commencementDate)} emptyFormat=" " />
              </Grid.Col>
              <Grid.Col sm={6} lg={3}>
                <ReadonlyData label="Expiry" value={formatDateFromISO(managementRight?.expiryDate)} />
              </Grid.Col>
              <Grid.Col sm={6} lg={3}>
                <ReadonlyData label="Low freq (MHz)" value={managementRight?.lowerBound?.toFixed(6)} emptyFormat=" " />
              </Grid.Col>
              <Grid.Col sm={6} lg={3}>
                <ReadonlyData label="High freq (MHz)" value={managementRight?.upperBound?.toFixed(6)} emptyFormat=" " />
              </Grid.Col>
            </Grid.Row>
          </>
        )}
      </Card>
    </div>
  );
};
export default ManagementRightSection;
