import { BubbleLayerOptions } from 'azure-maps-control';

/** settings for cluster/bubble layer */
export const BUBBLE_LAYER_OPTIONS: BubbleLayerOptions = {
  //Scale the size of the clustered bubble based on the number of points in the cluster.
  radius: [
    'step',
    ['get', 'point_count'],
    17, // Default of 17 pixel radius.
    10,
    23, // If point_count >= 10, radius is 23 pixels.
    50,
    30, // If point_count >= 50, radius is 30 pixels.
  ],

  //Change the color of the cluster based on the value on the point_cluster property of the cluster.
  color: [
    'step',
    ['get', 'point_count'],
    'rgba(130,30,210,0.8)', // Default to purple
    10,
    'rgba(255,255,0,0.8)', // If the point_count >= 10, color is yellow.
    50,
    'rgba(255,140,0,0.8)', // If the point_count >= 50, color is orange.
  ],
  strokeWidth: 0,
  filter: ['has', 'point_count'], // Only rendered data points which have a point_count property, which clusters do.
};

export const mouseOverCluster = (e: any) => {
  e.map.getCanvas().style.cursor = 'pointer';
};

export const mouseLeaveCluster = (e: any) => {
  e.map.getCanvas().style.cursor = '';
};

/** click on cluster to zoom/expand */
export const clusterClicked = (dsId: string, e: any) => {
  if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
    //Get the clustered point from the event.
    const cluster = e.shapes[0];

    // Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
    e.map.sources
      .getById(dsId)
      .getClusterExpansionZoom(cluster.properties.cluster_id)
      .then(function (zoom: any) {
        // Update the map camera to be centered over the cluster.
        e.map.setCamera({
          center: cluster.geometry.coordinates,
          zoom: zoom,
          type: 'ease',
          duration: 200,
        });
      });
  }
};
