import { DatePicker as FluentUIDatePicker, IDatePickerProps } from '@fluentui/react';
import { FieldAttributes, FieldInputProps, useField } from 'formik';
import moment from 'moment';
import * as React from 'react';

import { useForm } from '../../form/FormHook';
import { formatDate, RRF_DATE_FORMAT, toISODateString } from '../../utils/dateUtils';
import { renderHelpText } from './HelpTextRenderer';
import ReadOnlyField from './ReadOnlyField';

/**
 * @param props dateOnlyAsString - store the date in Formik without the time part as a string in ISO format YYYY-MM-DD
 * e.g. this is useful for date's as part of a query string request instead of as a date object in the request body.
 */
type DatePickerProps = Omit<IDatePickerProps, 'value' | 'onSelectDate' | 'onBlur' | 'onChange' | 'minDate'> & {
  dateOnlyAsString?: boolean;
  onSelectDate?: (selectedDate: Date) => void;
  errorMessage?: string;
  helpText?: string;
  readOnly?: boolean;
  alwaysValidate?: boolean;
  minDate?: string | Date;
  emptyFormat?: any;
};

const DatePicker: React.FC<FieldAttributes<DatePickerProps>> = (props) => {
  const [field, meta, fieldHelper] = useField<any>(props.name);

  const { mode } = useForm();

  const parseDate = (value: any) => {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (typeof value === 'object') {
      // Must be a date object.
      return value;
    }
    const rrfMoment = moment(value, RRF_DATE_FORMAT, true);
    const isoMoment = moment(value, 'YYYY-MM-DD', true);

    // If the manually entered date is invalid i.e. not in ISO (e.g. 2020-05-07) nor RRF (e.g. 7-May-2020) date formats,
    // then this will clear the text field. Note that date via the selection box will always be valid.
    // Note: I notice that if we return e.g. "Invalid date" Date object, the component hangs indefinitely when the
    // the text field is re-focused.
    if (!rrfMoment.isValid() && !isoMoment.isValid()) {
      return undefined;
    }

    return isoMoment.isValid() ? isoMoment.toDate() : rrfMoment.toDate();
  };

  const dateValue = (field: FieldInputProps<any>) => {
    const date = parseDate(field.value);

    if (props.onSelectDate) {
      props.onSelectDate(date);
    }
    return date;
  };

  const handleSelectDate = (date: Date | null | undefined) =>
    props.dateOnlyAsString && date !== null && date !== undefined
      ? toISODateString(date)
      : // Convert date to local date, as the one from the fabric Date picker is Zulu time (half a day earlier).
      date !== null && typeof date === 'object'
      ? new Date(toISODateString(date) as string)
      : date;

  // If the manually entered date is in the ISO format (e.g. 2020-05-07) then display it in the RRF format (e.g. 7-May-2020)
  const displayValue = (field: FieldInputProps<any>) => {
    if (field.value === undefined || field.value === null) {
      return undefined;
    }
    const likelyDateObject = typeof field.value === 'object';
    if (likelyDateObject) {
      return formatDate(field.value);
    }
    const isoMoment = moment(('' + field.value).substr(0, 10), 'YYYY-MM-DD', true);
    return isoMoment.isValid() ? formatDate(isoMoment.toDate()) : field.value;
  };

  // If the manually entered date is in the RRF format (e.g. 7-May-2020) then set the Formik form field to the ISO format
  // (e.g. 2020-05-07)
  if (typeof field.value === 'string') {
    const rrfMoment = moment(field.value, RRF_DATE_FORMAT, true);
    if (rrfMoment.isValid()) {
      fieldHelper.setValue(props.dateOnlyAsString ? rrfMoment.format('YYYY-MM-DD') : rrfMoment.toDate());
    }
  }

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField label={props.label} name={props.name} renderFormat={formatDate} emptyFormat={props.emptyFormat} />
  ) : (
    <FluentUIDatePicker
      {...props}
      {...field}
      onSelectDate={(date) => {
        fieldHelper.setValue(handleSelectDate(date));
        if (props.onSelectDate && !props.dateOnlyAsString && date !== null && date !== undefined) {
          props.onSelectDate(new Date(toISODateString(date) as string));
        }
      }}
      parseDateFromString={props.allowTextInput ? parseDate : undefined}
      formatDate={formatDate}
      minDate={parseDate(props.minDate)}
      value={dateValue(field)}
      disabled={mode === 'DISABLED' || props.disabled}
      isRequired={props.required}
      textField={{
        ...(props.allowTextInput ? { value: displayValue(field) } : undefined),
        name: field.name,
        errorMessage: props.alwaysValidate || meta.touched ? meta.error || props.errorMessage : undefined,
        onBlur: field.onBlur,
        onRenderLabel: (textFieldProps, defaultRender) => {
          if (props.helpText) {
            return renderHelpText(props);
          } else {
            return defaultRender ? defaultRender(textFieldProps) : <></>;
          }
        },
      }}
    />
  );
};

export default DatePicker;
