import { DirectionalHint, TooltipHost, Icon, mergeStyles } from '@fluentui/react';
import { ENVIRONMENT } from 'env-config';
import * as React from 'react';

import { DialogForm } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import { useTheme } from 'common/theme/RRFTheme';

import { loginExternalURL, loginInternalURL, stubLoginUrl } from '../auth/login';
import { getClassNames } from './DesktopNavBar.classNames';
import mbieAd from './mbie-ad.jpg';
import realMeLogo from './realme-logo.png';

const NotLoggedInMenuITems: React.FC = () => {
  const theme = useTheme();
  const classNames = getClassNames(theme);

  return (
    <div className={classNames.logos}>
      {ENVIRONMENT === 'LOCAL' && <StubLoginButton />}
      <TooltipHost
        directionalHint={DirectionalHint.bottomCenter}
        closeDelay={1000}
        tooltipProps={{ maxWidth: '600' }}
        content={<>Login with RealMe</>}
        className={mergeStyles({ border: '1px solid #C84504' })}
      >
        <button onClick={() => (window.location.href = loginExternalURL())} className={classNames.logonText}>
          Login &nbsp;
          <Icon iconName="NavigateExternalInline" style={{ color: 'white', fontSize: '20px' }} />
        </button>
      </TooltipHost>

      <TooltipHost
        directionalHint={DirectionalHint.bottomCenter}
        closeDelay={1000}
        tooltipProps={{ maxWidth: '600' }}
        content={<>Create a RealMe account</>}
        className={mergeStyles({ border: '1px solid #C84504' })}
      >
        <button onClick={() => (window.location.href = loginExternalURL())} className={classNames.logonText}>
          Create &nbsp;
          <Icon iconName="NavigateExternalInline" style={{ color: 'white', fontSize: '20px' }} />
        </button>
      </TooltipHost>

      <TooltipHost
        directionalHint={DirectionalHint.bottomCenter}
        closeDelay={1000}
        tooltipProps={{ maxWidth: '500' }}
        content={
          <>
            <p style={{ color: 'red' }}>To log in to the Register of Radio Frequencies you need a RealMe login.</p>

            <p>
              The Register of Radio Frequencies uses RealMe login to secure
              <br />
              and protect your personal information.
            </p>

            <p>
              RealMe login is a service from the New Zealand government that includes a single login,
              <br /> letting you use one username and password to access a wide range of services online.
            </p>

            <p> Find out more at www.realme.govt.nz.</p>
          </>
        }
      >
        <button onClick={() => (window.location.href = loginExternalURL())} className={classNames.realMeButton} aria-label="Realme login">
          <img src={realMeLogo} className={classNames.realMelogo} alt="Real me" />
        </button>
      </TooltipHost>
      <button
        onClick={() => {
          window.location.href = loginInternalURL();
        }}
        className={classNames.mbieButton}
        aria-label="MBIE AD Login"
      >
        <img src={mbieAd} className={classNames.mbielogo} alt="MBIE Ad" />
      </button>
    </div>
  );
};

const StubLoginButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();
  const classNames = getClassNames(theme);

  return (
    <>
      <DialogForm<{ username: string }>
        title="Enter username"
        buttonProps={{ cancelClicked: () => setIsOpen(false), okClicked: (values) => (window.location.href = stubLoginUrl(values.username)) }}
        initialValues={{ username: '' }}
        isOpen={isOpen}
        id="stub-logon"
      >
        <TextField name="username" label="Username" />
      </DialogForm>
      <button onClick={() => setIsOpen(true)} className={classNames.logonText} data-automation-id="stub-login">
        Stub login &nbsp;
        <Icon iconName="NavigateExternalInline" style={{ color: 'white', fontSize: '20px' }} />
      </button>
    </>
  );
};

export default NotLoggedInMenuITems;
