import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';

import { EquipmentSearchCriteria, EquipmentSearchResults } from 'api-client';

import { useAuth } from '../../../common/auth/AuthHook';
import Page from '../../../common/layout/Page';
import { parseBoolean } from '../../../common/utils/objectUtils';
import { validateWithFormValues } from '../../../common/validation/validationWithFormValues';
import { parseQueryString, searchResultsFocus } from '../../../search/utils';
import { useReferenceDataHookApi } from '../../reference_data/ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';
import SearchEquipmentForm from './SearchEquipmentForm';
import SearchEquipmentResults from './SearchEquipmentResults';
import { searchEquipmentValidationSchema } from './validationSchema';

const SearchEquipmentPage: React.FC = () => {
  const { isInternalUser } = useAuth();
  const [searchCriteria, setSearchCriteria] = useState<EquipmentSearchCriteria>();
  const [searchResults, setSearchResults] = useState<EquipmentSearchResults>();
  const { searchEquipment } = useReferenceDataHookApi();
  const { navigateToSearchEquipment } = useLicenceReferenceDataNavigator();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const defaultValues = {
    searchText: '',
    hidden: 'false',
    includeOlderVersions: 'false',
  };
  const values = parseQueryString(defaultValues);

  const initialValues = { ...values, includeOlderVersions: parseBoolean(values.includeOlderVersions) };

  const handleSubmit = (formValues: any) => {
    formValues = { ...formValues };
    if (!isInternalUser) {
      formValues = { ...formValues, hidden: false };
    }
    navigateToSearchEquipment(formValues, true);
    return searchEquipment(formValues, { showLoadingSpinner: 'Searching...' }).then((searchResults) => {
      setSearchCriteria({
        ...formValues,
        orderBy: searchResults.orderBy ?? formValues.orderBy,
      });
      setSearchResults(searchResults);
      setSubmitted(true);
      searchResultsFocus();
      return searchResults;
    });
  };

  const handleClear = () => {
    setSearchCriteria(defaultValues as any);
    setSearchResults(undefined);
    setSubmitted(false);
  };

  const handlePaging = (page: number, pageSize: number) => {
    let paging = searchCriteria ?? {};
    paging.page = page;
    paging.pageSize = pageSize;
    navigateToSearchEquipment(paging, true);
  };

  const handleOrderBy = (orderBy: string) => {
    let sort = searchCriteria ?? {};
    sort.orderBy = orderBy;
    navigateToSearchEquipment(sort, true);
  };

  return (
    <Page title="Search Equipment">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateWithFormValues(searchEquipmentValidationSchema)}
        enableReinitialize
        validateOnBlur
      >
        {() => <SearchEquipmentForm onClear={handleClear} showAddEquipmentButton={submitted} />}
      </Formik>
      {submitted && (
        <SearchEquipmentResults
          searchCriteria={searchCriteria}
          searchResults={searchResults}
          onPaging={handlePaging}
          onOrderBy={handleOrderBy}
          submitted={submitted}
        />
      )}
    </Page>
  );
};

export default SearchEquipmentPage;
