import { Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import {
  LocationConfigDto,
  GeoreferenceGroupDto,
  GeoreferenceTypeEnum,
  LicenceLocationGeoreferenceDto,
  GeoreferenceDto,
  LicenceClassificationType,
} from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import { getPreferenceGeoReferenceType } from 'common/user/components/UserPreference';
import { byPriorityThenOrder, formatAltitude } from 'common/utils/locationUtils';

import Dropdown from '../../../../../common/controls/inputs/Dropdown';
import ReadOnlyField from '../../../../../common/controls/inputs/ReadOnlyField';
import { TextField } from '../../../../../common/controls/inputs/TextField';
import { TextFieldAndUnitOfMeasure } from '../../../../../common/controls/inputs/TextFieldAndUnitOfMeasure';
import ReadOnlyData from '../../../../../common/controls/items/ReadonlyData';
import InlineForm from '../../../../../common/form/InlineForm';
import { SubSectionHeading } from '../../../../../common/layout/SectionHeadings';
import { Grid } from '../../../../../ui-library';
import { antennaPatternOptions, stationTypeOptions } from '../../../../apply/components/satellite/LocationSection';
import { useReferenceDataHookApi } from '../../../../reference_data/ReferenceDataApiHook';
import { MAINTAIN_ANTENNA_URL, MAINTAIN_EQUIPMENT_URL } from '../../../../reference_data/ReferenceDataRouter';
import LocationGeoreferenceDetail from '../common/locationGeoReferencePanel';
import AntennaSearch from './AntennaSearch';
import EquipmentSearch from './EquipmentSearch';

interface Props {
  licenceId: number;
  locationType: 'TRANSMIT' | 'RECEIVE';
  locIdx: number;
  onCancel: () => void;
  isDone?: boolean;
  onSave: () => void;
  locationConfig?: LocationConfigDto;
  isSatellite?: boolean;
  licenceClassification?: LicenceClassificationType;
}

const LocationInlineForm: React.FC<Props> = (props) => {
  return <Form {...props} />;
};

const Form: React.FC<Props> = (props) => {
  const { setFieldValue, values } = useFormikContext<any>();

  const name = `locations[${props.locIdx}]`;

  const { getAntenna, getEquipment } = useReferenceDataHookApi();
  const { userPreference } = useAuth();
  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  const handleAntennaSelected = (id: number) => {
    getAntenna(id).then((antenna) => setFieldValue(`${name}.antenna`, antenna));
  };
  const handleEquipmentSelected = (id: number) => {
    getEquipment(id).then((equipment) => setFieldValue(`${name}.equipment`, equipment));
  };
  const isAntennaRequired = () =>
    props.locationType === 'TRANSMIT' ? props.locationConfig?.txAntennaMandatory : props.locationConfig?.rxAntennaMandatory;

  const isAzimuthRequired = () =>
    props.locationType === 'TRANSMIT' ? props.locationConfig?.txAzimuthMandatory : props.locationConfig?.rxAzimuthMandatory;

  const isElevationRequired = () =>
    props.locationType === 'TRANSMIT' ? props.locationConfig?.txElevationMandatory : props.locationConfig?.rxElevationMandatory;

  // if the config is null: Required only for Point location
  const isHeightRequired = () =>
    props.locationType === 'TRANSMIT'
      ? props.locationConfig?.txHeightMandatory ?? values.locations.at(props.locIdx).locationType === 'POINT'
      : props.locationConfig?.rxHeightMandatory ??
        (values.locations.at(props.locIdx).locationType === 'POINT' && props.licenceClassification !== LicenceClassificationType.C);

  const isEquipmentRequired = () =>
    props.locationType === 'TRANSMIT' ? props.locationConfig?.txEquipmentMandatory : props.locationConfig?.rxEquipmentMandatory;

  var refGroups: GeoreferenceGroupDto[] = [];

  const getUserPreferenceValue = (geoRefs?: LicenceLocationGeoreferenceDto[]) => {
    let userPreferenceGeoRef = geoRefs?.filter((ref: LicenceLocationGeoreferenceDto) => GeoreferenceTypeEnum[ref.type!] === geoRefTypeUserPreference);

    const sortedGeoRef = geoRefs?.sort(byPriorityThenOrder) ?? [];
    const geoRefType = [...sortedGeoRef, undefined][0]?.type!;
    console.log(userPreferenceGeoRef);
    //If the location does not have the user's preferred geo reference type, then display next available in priority order geo reference type
    if (userPreferenceGeoRef?.length === 0) {
      return sortedGeoRef?.filter((ref) => GeoreferenceTypeEnum[ref?.type!] === GeoreferenceTypeEnum[geoRefType]);
    }
    return userPreferenceGeoRef;
  };

  const mapLocationGeoReftoGeoRef = (ref?: LicenceLocationGeoreferenceDto): GeoreferenceDto => {
    return {
      id: ref?.id,
      mapNumber: ref?.mapNumber,
      easting: ref?.easting ?? '',
      northing: ref?.northing ?? '',
      type: GeoreferenceTypeEnum[ref?.type!],
      original: ref?.original,
      order: ref?.order,
    };
  };

  if (
    values.locations.at(props.locIdx).geoReferenceGroups &&
    values.locations.at(props.locIdx).geoReferenceGroups.length >= 1 &&
    values.locations.at(props.locIdx).locationType === 'POINT'
  ) {
    //passing user preference georeference type value as a origin
    const filteredGeoRefs = getUserPreferenceValue(values.locations.at(props.locIdx).geoReferenceGroups[0].geoReferences);
    const o = [...filteredGeoRefs!, undefined][0];
    const c = values.locations.at(props.locIdx).geoReferenceGroups[0].geoReferences;

    refGroups.push({
      origin: o && o.type ? mapLocationGeoReftoGeoRef(o) : undefined,
      convertions: c ? c.map((s: any) => mapLocationGeoReftoGeoRef(s)) : undefined,
    });
  }
  if (values.locations.at(props.locIdx).geoReferenceGroups && values.locations.at(props.locIdx).locationType === 'MULTIPLE POINTS') {
    const filteredGeoRefs = getUserPreferenceValue(values.locations.at(props.locIdx).geoReferenceGroups[0].geoReferences);
    var originType: any = filteredGeoRefs ? filteredGeoRefs[0].type : null;

    var result = _(values.locations.at(props.locIdx).geoReferenceGroups[0].geoReferences)
      .groupBy((x) => x.order)
      .map((value) => ({ origin: value.find((ref) => ref.type === originType), conversions: value }))
      .value();

    result.forEach((ref) => {
      var geoConversions: GeoreferenceDto[] = [];
      ref.conversions.forEach((c) => geoConversions.push(mapLocationGeoReftoGeoRef(c)));
      var geoRef = {
        origin: mapLocationGeoReftoGeoRef(ref?.origin),
        convertions: geoConversions,
      };
      refGroups.push(geoRef);
    });
  }

  return (
    <InlineForm
      id="location-form"
      submitButtonId="save-location"
      hideBack
      hideFormButtonsTop
      showFormButtonsBottom
      mode={props.isDone ? 'VIEW' : 'EDIT'}
      onCancelEdit={props.onCancel}
      disableErrorMessage
      additionalButtons={(mode) => (mode === 'VIEW' ? [{ id: 'close-location', text: 'Close', onClick: props.onCancel }] : [])}
      onSubmit={props.onSave}
    >
      <SubSectionHeading title={props.locationType === 'TRANSMIT' ? 'Transmit location' : 'Receive location'} />
      <Grid.Row>
        <Grid.Col lg={3}>
          <ReadOnlyField label="Location identifier" name={`${name}.locationId`} />
        </Grid.Col>
        <Grid.Col lg={3}>
          <ReadOnlyField label="Location name" name={`${name}.locationName`} />
        </Grid.Col>
        <Grid.Col lg={3}>
          <ReadOnlyField label="Location type" name={`${name}.locationType`} />
        </Grid.Col>
        <Grid.Col lg={3}>
          <ReadOnlyData
            label="Location altitude"
            value={formatAltitude(values.locations.at(props.locIdx).locationAltitude, values.locations.at(props.locIdx).locationAltitudeUOM)}
          />
        </Grid.Col>
      </Grid.Row>

      {!!props.isSatellite && (
        <Grid.Row>
          <Grid.Col lg={3}>
            <Dropdown label="Station type" required name={`${name}.stationType`} options={stationTypeOptions} />
          </Grid.Col>
        </Grid.Row>
      )}

      {values.locations.at(props.locIdx).geoReferenceGroups &&
        values.locations.at(props.locIdx).geoReferenceGroups.length > 0 &&
        values.locations.at(props.locIdx).locationType === 'POINT' && <LocationGeoreferenceDetail georeferenceGroup={refGroups[0]} />}
      {values.locations.at(props.locIdx).geoReferenceGroups &&
        values.locations.at(props.locIdx).geoReferenceGroups.length > 0 &&
        values.locations.at(props.locIdx).locationType === 'MULTIPLE POINTS' && (
          <>
            <Grid.Row>
              <Grid.Col sm={12} lg={1}>
                <Label>Point</Label>
              </Grid.Col>
              {(refGroups[0].origin?.type === GeoreferenceTypeEnum.T || refGroups[0].origin?.type === GeoreferenceTypeEnum.M) && (
                <Grid.Col sm={12} lg={2}>
                  <Label>Map</Label>
                </Grid.Col>
              )}
              <Grid.Col sm={12} lg={3}>
                <Label>{refGroups[0].origin?.type === GeoreferenceTypeEnum.D2000 ? 'Latitude' : 'Easting'}</Label>
              </Grid.Col>
              <Grid.Col sm={12} lg={3}>
                <Label>{refGroups[0].origin?.type === GeoreferenceTypeEnum.D2000 ? 'Longitude' : 'Northing'}</Label>
              </Grid.Col>
            </Grid.Row>
            {refGroups.map((item, index) => (
              <LocationGeoreferenceDetail georeferenceGroup={refGroups[index]} hideLabel={true} showOrder={true} />
            ))}
          </>
        )}

      <SubSectionHeading title="Antenna" />
      {!props.isDone && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <AntennaSearch name={`${name}.antenna`} onAntennaSelected={handleAntennaSelected} required={isAntennaRequired()} />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col sm={6} lg={3}>
          <ReadOnlyField
            label="Antenna identifier"
            name={`${name}.antenna.identifier`}
            href={`${MAINTAIN_ANTENNA_URL}${values.locations.at(props.locIdx).antenna?.id}`}
          />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <ReadOnlyField label="Version" name={`${name}.antenna.bizVersion`} />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <ReadOnlyField label="Make" name={`${name}.antenna.make`} />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <ReadOnlyField label="Model" name={`${name}.antenna.model`} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={6} lg={3}>
          <ReadOnlyField label="Type" name={`${name}.antenna.type`} />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <ReadOnlyField label="Remarks" name={`${name}.antenna.remarks`} />
        </Grid.Col>
      </Grid.Row>
      {!!props.isSatellite && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <Dropdown label="Antenna pattern" name={`${name}.antennaPattern`} options={antennaPatternOptions} required />
          </Grid.Col>
          <Grid.Col lg={3}>
            {values.locations.at(props.locIdx).antennaPattern === 'Other' && (
              <>
                <Label>&nbsp;</Label>
                <TextField name={`${name}.antennaPatternOther`} ariaLabel="Antenna pattern details" />
              </>
            )}
          </Grid.Col>
        </Grid.Row>
      )}
      {!!props.isSatellite && props.locationType === 'RECEIVE' && (
        <Grid.Row>
          <Grid.Col lg={3}>
            <TextField name={`${name}.noiseTemperature`} label="System receive noise temperature (K)" required />
          </Grid.Col>
        </Grid.Row>
      )}

      {!props.isSatellite && (
        <>
          <Grid.Row>
            <Grid.Col lg={3}>
              <TextField name={`${name}.azimuth`} label="Azimuth (wrt True North)" maxLength={6} required={isAzimuthRequired()} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <TextField name={`${name}.elevation`} label="Elevation (wrt Horizontal)" maxLength={5} required={isElevationRequired()} />
            </Grid.Col>
            {(props.licenceClassification === undefined || props.licenceClassification !== LicenceClassificationType.C) && (
              <Grid.Col lg={3}>
                <TextField name={`${name}.antennaHeight`} label="Height (AGL metres)" maxLength={6} required={isHeightRequired()} />
              </Grid.Col>
            )}
          </Grid.Row>
          <SubSectionHeading title="Equipment" />
          {!props.isDone && (
            <Grid.Row>
              <Grid.Col lg={5}>
                <EquipmentSearch name={`${name}.equipment`} onEquipmentSelected={handleEquipmentSelected} required={isEquipmentRequired()} />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col sm={6} lg={3}>
              <ReadOnlyField
                label="Equipment identifier"
                name={`${name}.equipment.identifier`}
                href={`${MAINTAIN_EQUIPMENT_URL}${values.locations.at(props.locIdx).equipment?.id}`}
              />
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <ReadOnlyField label="Version" name={`${name}.equipment.versionNumber`} />
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <ReadOnlyField label="Make" name={`${name}.equipment.make`} />
            </Grid.Col>
            <Grid.Col sm={6} lg={3}>
              <ReadOnlyField label="Model" name={`${name}.equipment.model`} />
            </Grid.Col>
          </Grid.Row>
          <SubSectionHeading title="Propagation" />
          {props.locationType === 'TRANSMIT' ? (
            <TransmitLocationFields name={name} />
          ) : (
            <ReceiveLocationFields name={name} locationConfig={props.locationConfig} />
          )}
        </>
      )}
    </InlineForm>
  );
};

const TransmitLocationFields = ({ name }: { name: string }) => (
  <Grid.Row>
    <Grid.Col lg={3}>
      <TextField name={`${name}.configurationLoss`} label="Configuration loss (dB)" maxLength={6} />
    </Grid.Col>
  </Grid.Row>
);

const ReceiveLocationFields = ({ name, locationConfig }: { name: string; locationConfig?: LocationConfigDto }) => (
  <>
    <Grid.Row>
      <Grid.Col lg={3}>
        <TextField name={`${name}.configurationLoss`} label="Configuration loss (dB)" maxLength={6} />
      </Grid.Col>
      <Grid.Col lg={3}>
        <TextField name={`${name}.protectionRatio`} label="Protection ratio (dB)" maxLength={5} />
      </Grid.Col>
      <Grid.Col lg={3}>
        <TextField name={`${name}.bearing`} label="Bearing (Degrees)" maxLength={5} />
      </Grid.Col>
      <Grid.Col lg={3}>
        <TextField name={`${name}.pathLoss`} label="Path loss (dB)" maxLength={5} />
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col lg={3}>
        <TextFieldAndUnitOfMeasure label="Wanted signal" textInputName={`${name}.wantedSignal`} dropdownName={`${name}.wantedSignalUomId`} />
      </Grid.Col>
      <Grid.Col lg={3}>
        <TextFieldAndUnitOfMeasure label="Measured signal" textInputName={`${name}.measuredSignal`} dropdownName={`${name}.measuredSignalUomId`} />
      </Grid.Col>
      <Grid.Col lg={3}>
        <TextFieldAndUnitOfMeasure
          label="MPIS"
          textInputName={`${name}.mpis`}
          dropdownName={`${name}.mpisUomId`}
          required={locationConfig?.rxMpisMandatory}
        />
      </Grid.Col>
    </Grid.Row>
  </>
);

export default LocationInlineForm;
