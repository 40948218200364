import {
  ApplicantDto,
  AtonClientSearchCriteria,
  AtonDto,
  AtonSearchCriteria,
  BaseLocationDto,
  CallsignClientSearchCriteria,
  CallsignSearchCriteria,
  CertificateApprovalSearchCriteria,
  CertificateClientSearchCriteria,
  CertificateSearchCriteria,
  EngineerDto,
  EventCriteria,
  EventResults,
  FrequencyDto,
  LicenceSearchCriteria,
  LocationSearchCriteria,
  ManagementRightDto,
  ManagementRightsSearchCriteria,
  ManagementRightsSearchResults,
  MortgageCaveatDto,
  RadioApplicationDto,
  ServiceTypeDto,
  SpectrumDto,
} from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { licenceToSupplyApi, publicApi, publicAtonApi, publicLicenceApi, publicMapApi, publicSearchApi } from 'common/api/api';

export const usePublicApi = () => {
  const { aroundApi } = useApi();

  return {
    getClientDetails: (id: number, config?: ApiConfig) => aroundApi(publicApi.getClientPublic(id), config),
    getClientEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(publicApi.getClientEventsPublic(entityId, criteria), config),
    searchLicence: (criteria: LicenceSearchCriteria, config?: ApiConfig) => aroundApi(publicSearchApi.searchLicencePublic(criteria), config),
    searchLocation: (criteria: LocationSearchCriteria, config?: ApiConfig) => aroundApi(publicSearchApi.searchLocationPublic(criteria), config),
    listEngineers: (config?: ApiConfig) => aroundApi(publicSearchApi.listEngineersPublic(), config),
    searchSystemId: (searchText: string, config?: ApiConfig) => aroundApi(publicSearchApi.searchSystemIdPublic(searchText), config),
    searchManagementRightsPublic: (criteria: ManagementRightsSearchCriteria, config?: ApiConfig): Promise<ManagementRightsSearchResults> =>
      aroundApi(publicSearchApi.searchManagementRightsPublic(criteria), config),
    getManagementRight: (managementRightsId: number, config?: ApiConfig): Promise<ManagementRightDto> =>
      aroundApi(publicLicenceApi.getManagementRightPublic(managementRightsId), config),
    getMortgagesForManagementRight: (managementRightsId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(publicLicenceApi.getMortgagesPublic(managementRightsId), config),
    getCaveatsForManagementRight: (managementRightsId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(publicLicenceApi.getCaveatsPublic(managementRightsId), config),
    getManagementRightExtract: (managementRightsId: number, config?: ApiConfig) =>
      aroundApi(publicLicenceApi.getManagementRightExtract(managementRightsId, { responseType: 'blob' }), config),
    getMapConfig: (config?: ApiConfig) => aroundApi(publicMapApi.getMapConfig(), config),
    searchCertificatePublic: (criteria: CertificateSearchCriteria, config?: ApiConfig) =>
      aroundApi(publicSearchApi.searchCertificatePublic(criteria), config),
    searchCertificateClientPublic: (criteria: CertificateClientSearchCriteria, config?: ApiConfig) =>
      aroundApi(publicSearchApi.searchCertificateClientPublic(criteria), config),
    searchCertificateApprovalPublic: (criteria: CertificateApprovalSearchCriteria, config?: ApiConfig) =>
      aroundApi(publicSearchApi.searchCertificateApprovalPublic(criteria), config),
    searchCallsignPublic: (criteria: CallsignSearchCriteria, config?: ApiConfig) => aroundApi(publicSearchApi.searchCallsignPublic(criteria), config),
    getMapLicenceDetailsPublic: (licId: number, grt?: string) => aroundApi(publicMapApi.getLicenceDetailsPublic(licId, grt)),
    getManagementRightEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(publicLicenceApi.getManagementRightEventsPublic(entityId, criteria), config),
    searchAtonPublic: (criteria: AtonSearchCriteria, config?: ApiConfig) => aroundApi(publicSearchApi.searchAtonPublic(criteria), config),
    searchAtonClientPublic: (criteria: AtonClientSearchCriteria, config?: ApiConfig) =>
      aroundApi(publicSearchApi.searchAtonClientPublic(criteria), config),
    searchCallsignClientPublic: (criteria: CallsignClientSearchCriteria, config?: ApiConfig) =>
      aroundApi(publicSearchApi.searchCallsignClientPublic(criteria), config),
    getLicencePublic: (id: number, type: 'radio' | 'spectrum' = 'radio', config?: ApiConfig) => {
      if (type === 'spectrum') {
        return aroundApi(publicLicenceApi.getSpectrumLicencePublic(id), config);
      }
      return aroundApi(publicLicenceApi.getRadioLicencePublic(id), config);
    },
    getSpectrumsPublic: (licenceId: number, config?: ApiConfig): Promise<SpectrumDto[]> =>
      aroundApi(publicLicenceApi.getSpectrumsPublic(licenceId), config).then(sortByReferenceFrequencies),
    getServiceTypesPublic: (config?: ApiConfig): Promise<ServiceTypeDto[]> => aroundApi(publicLicenceApi.getServiceTypesPublic(), config),
    getApplicantDetailsPublic: (applicantId: number, config?: ApiConfig): Promise<ApplicantDto> =>
      aroundApi(publicLicenceApi.getApplicantDetailsPublic(applicantId), config),
    getEngineerDetailsPublic: (approvalId: number, config?: ApiConfig): Promise<EngineerDto> =>
      aroundApi(publicLicenceApi.getEngineerDetailsPublic(approvalId), config),
    getSpectrumConfigsPublic: (config?: ApiConfig) => aroundApi(publicLicenceApi.getSpectrumConfigurationsPublic(), config),
    getTransmitLocationsPublic: (id: number, config?: ApiConfig) => aroundApi(publicLicenceApi.getTransmitLocationsPublic(id), config),
    getReceiveLocationsPublic: (id: number, config?: ApiConfig) => aroundApi(publicLicenceApi.getReceiveLocationsPublic(id), config),
    getRelatedLicencesPublic: (id: number, agreedViewSuppressed: boolean, config?: ApiConfig) =>
      aroundApi(publicLicenceApi.getRelatedLicencesPublic(id, agreedViewSuppressed), config),
    getLicenceEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(publicLicenceApi.getLicenceEventsPublic(entityId, criteria), config),
    getSatelliteDetailsPublic: (id: number, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(publicLicenceApi.getSatelliteDetailsPublic(id), config),
    getLocationPublic: (locationId: number, config?: ApiConfig): Promise<BaseLocationDto> =>
      aroundApi(publicLicenceApi.getLocationPublic(locationId), config),
    getAtonPublic: (atonId: number, config?: ApiConfig): Promise<AtonDto> => aroundApi(publicAtonApi.getAtonPublic(atonId), config),
    getAtonEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(publicAtonApi.getAtonEventsPublic(entityId, criteria), config),
    getLicenceExtractPublic: (licenceId: number, licenceType: string, config?: ApiConfig): Promise<Blob> =>
      aroundApi(publicLicenceApi.getLicenceExtractPublic(licenceId, licenceType, { responseType: 'blob' }), config),
    getAssociatedLicenceSummaryPublic: (licenceId: number, config?: ApiConfig) =>
      aroundApi(publicLicenceApi.getAssociatedLicenceSummaryPublic(licenceId), config),
    getLicenceOwnerInformation: (licenceId: number, config?: ApiConfig) => aroundApi(publicLicenceApi.getLicenceOwnerInformation(licenceId), config),
    getLicenceToSupplyPublic: (id: number, config?: ApiConfig) => aroundApi(licenceToSupplyApi.getLicenceToSupplyPublic(id), config),
    getMortgagesForSpectrumLicencePublic: (licenceId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(publicLicenceApi.getMortgagesForSpectrumLicencePublic(licenceId), config),
    getCaveatsForSpectrumLicencePublic: (licenceId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(publicLicenceApi.getCaveatsForSpectrumLicencePublic(licenceId), config),
  };
};

const sortByReferenceFrequencies = (spectrums: SpectrumDto[]) => {
  const n = (n?: number) => (n || n === 0 ? n : -1);
  // undefined is considered lowest
  const byLowestReferenceFrequency = (f1: FrequencyDto, f2: FrequencyDto) => n(f1.referenceFrequency) - n(f2.referenceFrequency);

  spectrums = spectrums.map((spectrum) => ({ ...spectrum, frequencies: spectrum.frequencies?.sort(byLowestReferenceFrequency) }));
  return spectrums.sort((s1, s2) => {
    const frequency = (spectrum: SpectrumDto) => n([...(spectrum.frequencies ?? []), {}][0].referenceFrequency);
    return frequency(s1) - frequency(s2);
  });
};
