import { data } from 'azure-maps-control';

import { FeatureCollection, GJObjectType } from 'api-client/dist/api';

const zoomScaleFactor = 150; // adjust to get around the right zoom level
const minZoom = 0; // while 4 is our default NZ map zoom, this needs to be zero else satellites
const maxZoom = 15; // and antimeridian can leave us in the indian ocean
const metersToDeg = 111000;

// problematic label layers to remove
const LABEL_LAYERS_TO_REMOVE = [
  'microsoft.maps.base.labels_places.POI',
  'POI',
  'microsoft.maps.base.labels_places.Shopping centre label',
  'Shopping centre label',
  'microsoft.maps.base.labels_places.University/School label',
  'University/School label',
  'microsoft.maps.base.labels_places.Museum label',
  'Museum label',
  'microsoft.maps.base.labels_places.Hospital label',
  'Hospital label',
  'microsoft.maps.base.labels_places.Park/Garden label',
  'Park/Garden label',
  'microsoft.maps.base.labels_places.Landmark label',
  'Landmark label',
  'microsoft.maps.base.labels_places.Railway station',
  'Railway station',
  'microsoft.maps.base.labels_places.Zoo label',
  'Zoo label',
  'microsoft.maps.base.labels_places.Stadium label',
  'Stadium label',
  'microsoft.maps.base.labels_places.Military Territory label',
  'Military Territory label',
  'microsoft.maps.base.labels_places.Amusement area label',
  'Amusement area label',
  'microsoft.maps.base.labels_places.Airport label',
  'Airport label',
  'microsoft.maps.base.labels_places.Golf Course label',
  'Golf Course label',
  'microsoft.maps.base.labels_places.Cemetery label',
  'Cemetery label',
];

export interface CenterAndZoom {
  zoom?: number;
  center?: data.Position;
  boundingBox?: data.BoundingBox;
}

export const degreesToZoom = (deg: number, minZ: number | undefined, maxZ: number | undefined): number => {
  let pz = Math.log2(zoomScaleFactor / deg);
  if (minZ) {
    pz = Math.max(minZ, pz);
  }
  if (maxZ) {
    pz = Math.min(maxZ, pz);
  }
  return pz;
};

/**
 * Get a center and bounding zoom from data features.
 * Undefined if no data (in which case map should apply its default zoom and center).
 */
export const centerFromFeatures = (geoJson: FeatureCollection | data.FeatureCollection | undefined): CenterAndZoom => {
  if (geoJson && geoJson.features.length > 0) {
    let bounds = null;

    if (geoJson.features.length > 0) {
      bounds = data.BoundingBox.fromData(geoJson as data.FeatureCollection);
    }

    if (bounds !== null) {
      // BoundingBox and degreesToZoom are handling wide angles and crossesAntimeridian
      // provided minZoom is 0 or 1, else can be zoomed on a blank part of the map
      const w = data.BoundingBox.getWidth(bounds);
      const h = data.BoundingBox.getHeight(bounds);

      // If the bounding box is really small, likely a single point, use center/zoom.
      if (w < 0.000001 || h < 0.000001) {
        return { center: data.BoundingBox.getCenter(bounds), zoom: maxZoom };
      } else {
        const maxD = Math.max(w, h);
        const z = degreesToZoom(maxD, minZoom, maxZoom);
        return { center: data.BoundingBox.getCenter(bounds), zoom: z, boundingBox: bounds };
      }
    }
  }

  return {};
};

/** as above, but if doing a radius search and that is provided center on the radius */
export const centerFromRadiusOrFeatures = (
  pin: data.Position | null | undefined,
  radius: number | null | undefined,
  geoJson: FeatureCollection | data.FeatureCollection | undefined,
): CenterAndZoom => {
  if (pin && radius && radius > 0) {
    const deg = radius / metersToDeg;
    return { center: pin, zoom: degreesToZoom(deg, minZoom, maxZoom) };
  } else {
    return centerFromFeatures(geoJson);
  }
};

/**
 * filters out TRN and/or RCV data (points/poly only, other object types like lines preserved if present)
 * and also converts from api FeatureCollection to atalas.data.FeatureCollection
 * if no mapData use an empty one to ensure any old data is cleared
 */
export const filterTrnRcvFeatures = (geoJson: FeatureCollection | undefined, keepTrn: boolean, keepRcv: boolean): data.FeatureCollection => {
  if (geoJson) {
    const unfilteredFeatures = geoJson.features || [];
    if (keepTrn && keepRcv) {
      // keeping both so just return unfiltered
      return new data.FeatureCollection(unfilteredFeatures as any);
    } else {
      const filteredFeatures = unfilteredFeatures.filter((x) => {
        if (x.type === GJObjectType.Feature && (x.geometry.type === GJObjectType.Point || x.geometry.type === GJObjectType.Polygon)) {
          // only filter Feature/Point or Polygon
          // @ts-ignore
          if ((!keepTrn && x.properties.configType === 'TRN') || (!keepRcv && x.properties.configType === 'RCV')) {
            return false;
          }
        } else if (x.type === GJObjectType.Feature && x.geometry.type === GJObjectType.LineString) {
          // pt to pt lines hidden if eithr trn or rcv is hidden
          return keepTrn && keepRcv;
        }
        return true; // fall through to preserved
      });
      return new data.FeatureCollection(filteredFeatures as any);
    }
  }
  return new data.FeatureCollection([]);
};

/** convert api GeoJSON to azure maps data, empty if undefined to ensure data is cleared */
export const fcToAzureFC = (geoJson: FeatureCollection | undefined): data.FeatureCollection => {
  var features = [] as any;
  if (geoJson && geoJson.features) {
    features = geoJson.features;
  }
  return new data.FeatureCollection(features);
};

/**
 * Filter out all inaccurate map label layers as defined in LABEL_LAYERS_TO_REMOVE.
 * Call once after map is loaded.
 * Note: our impl seems only to use the short name, but have defined and conditionally try both.
 * Note: Changes seem to get reverted id map.setStyle is called (such as when building display toggled)
 * and timing of reapplying filters is then difficult.
 */
export const filterOutLocationLabels = (map: any) => {
  try {
    const removalFilter = ['==', 'name', '_matches_nothing_'];
    LABEL_LAYERS_TO_REMOVE.forEach((layerName) => {
      if (map._getMap().getLayer(layerName)) {
        map._getMap().setFilter(layerName, removalFilter);
        // console.log('layer filtering ' + layerName);
      }
    });
  } catch (ex) {
    console.error('Error in filterOutLocationLabels: ', ex);
  }
};

//-----------------------------------------------------------------------------
// Functions to set a function into a uniquified global for use in map popups
// where react popups are not easily accessible. Usage:
// call gfnGenerateUniqueId() and store in a memo.
// then in a useEffect call gfnSetFunctionRef() with the id to save the fn ref.
// in popup rendering use <a href={`javascript:!{gfnGetFunctionName(uid)}(${params})`}>
export const gfnGenerateUniqueId = (): string => {
  return 'GFN_UID_' + Math.floor(Math.random() * 10000);
};

export const gfnSetFunctionRef = (uniqueId: string, func: any) => {
  var lg_fn = (window as any).g_fn;
  if (!lg_fn) {
    lg_fn = {};
    (window as any).g_fn = lg_fn;
  }
  lg_fn[uniqueId] = func;
};

export const gfnGetFunctionName = (uniqueId: string): string | undefined => {
  if (window && (window as any).g_fn && (window as any).g_fn[uniqueId]) {
    return 'window.g_fn.' + uniqueId;
  }
};

/**
 * hacky internal inspection heuristic to try and determine if a map mouse event is
 * on the background or one of our markers that we are using for mouseover popups
 */
export const isMouseoverMarker = (e: any, layerIds: string[]): boolean => {
  if (e && e.shapes && e.shapes.length > 0) {
    if (e.shapes[0].data && e.shapes[0].data.geometry && 'Point' === e.shapes[0].data.geometry.type) {
      return true; // is a Point feature, so probably one of our markers
    }
    if (e.shapes[0].layer) {
      for (var lid of layerIds) {
        if (lid === e.shapes[0].layer.id) {
          return true; // matches an item on the defined layer(s)
        }
      }
    }
  }
  // fall through to false
  return false;
};
