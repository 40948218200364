import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import {
  AtonSearchCriteria,
  BasicSearchCriteria,
  CallsignSearchCriteria,
  CertificateSearchCriteria,
  ClientSearchCriteria,
  DocumentDeliveryLogSearchCriteria,
  LicenceSearchCriteria,
  LicenceToSupplySearchCriteria,
  LicenceTransferRequestSearchCriteria,
  LocationSearchCriteria,
  ManagementRightsSearchCriteria,
  RenewalFeeSearchCriteria,
  ApplicationFeeSearchCriteria,
  SearchMortgageCaveatByLicenceCriteria,
  SearchMortgageCaveatByManagementRightCriteria,
  SpectrumMaskSearchCriteria,
  TaskSearchCriteria,
} from 'api-client';

import { useAuth } from '../common/auth/AuthHook';
import ProtectedRoute from '../common/auth/ProtectedRoute';
import { stringifyQueryObject } from './utils';

const TransferPaymentResultForm = React.lazy(() => import('licence_management/licence_transfer_request/TransferPaymentResultForm'));
const SearchMcvBySPLForDischargePage = React.lazy(() => import('search/mortgage_caveat/licence/SearchMcvBySPLForDischargePage'));
const SearchSPLForForm11Page = React.lazy(() => import('search/mortgage_caveat/licence/SearchSPLForForm11Page'));
const SearchSPLForForm20Page = React.lazy(() => import('search/mortgage_caveat/licence/SearchSPLForForm20Page'));
const SearchMRForForm11Page = React.lazy(() => import('search/mortgage_caveat/management_right/SearchMRForForm11Page'));
const SearchMRForForm20Page = React.lazy(() => import('search/mortgage_caveat/management_right/SearchMRForForm20Page'));
const SearchMcvByMRForDischargePage = React.lazy(() => import('search/mortgage_caveat/management_right/SearchMcvByMRForDischargePage'));
const IncompleteTransferRequestPage = React.lazy(() => import('../licence_management/licence_transfer_request/IncompleteTransferRequestPage'));
const SearchAntennaPage = React.lazy(() => import('../licence_management/search/antenna/SearchAntennaPage'));
const SearchAtonPage = React.lazy(() => import('./aton/SearchAtonPage'));
const SearchCallsignPage = React.lazy(() => import('./callsign/SearchCallsignPage'));
const SearchCertificatePage = React.lazy(() => import('./certificate/SearchCertificatePage'));
const SearchClientPage = React.lazy(() => import('./client/SearchClientPage'));
const SearchDocumentDeliveryLogPage = React.lazy(() => import('./document_delivery_log/SearchDocumentDeliveryLogPage'));
const ApplicationPaymentResultForm = React.lazy(() => import('./fee/application/ApplicationPaymentResultForm'));
const SearchApplicationFeePage = React.lazy(() => import('./fee/application/SearchApplicationFeePage'));
const RenewalPaymentResultForm = React.lazy(() => import('./fee/renewal/RenewalPaymentResultForm'));
const SearchRenewalFeePage = React.lazy(() => import('./fee/renewal/SearchRenewalFeePage'));
const SearchLicencePage = React.lazy(() => import('./licence/SearchLicencePage'));
const SearchLicencesToSupplyPage = React.lazy(() => import('./licences_to_supply/SearchLicencesToSupplyPage'));
const SearchLocationPage = React.lazy(() => import('./location/SearchLocationPage'));
const SearchManagementRightsPage = React.lazy(() => import('./management_rights/SearchManagementRightsPage'));
const SearchTaskPage = React.lazy(() => import('./task/SearchTaskPage'));

export type SearchType =
  | 'client'
  | 'licence'
  | 'licence-to-supply'
  | 'equipment'
  | 'antenna'
  | 'spectrumMask'
  | 'location'
  | 'spectrum'
  | 'management-rights'
  | 'mr-form11'
  | 'mr-form20'
  | 'spl-form11'
  | 'spl-form20'
  | 'spl-form18'
  | 'spl-form21'
  | 'mr-form18'
  | 'mr-form21'
  | 'certificate'
  | 'callsign'
  | 'accept-transfer-request'
  | 'withdraw-transfer-request'
  | 'aton'
  | 'document-delivery-log'
  | 'renewal-fee'
  | 'application-fee'
  | 'task';

export type SearchCriteria =
  | BasicSearchCriteria
  | ClientSearchCriteria
  | LicenceSearchCriteria
  | LicenceToSupplySearchCriteria
  | SpectrumMaskSearchCriteria
  | ManagementRightsSearchCriteria
  | CertificateSearchCriteria
  | CallsignSearchCriteria
  | LicenceTransferRequestSearchCriteria
  | AtonSearchCriteria
  | DocumentDeliveryLogSearchCriteria
  | RenewalFeeSearchCriteria
  | ApplicationFeeSearchCriteria;

const SearchRouter: React.FC = () => {
  const { path } = useRouteMatch();
  const url = new URL(document.URL);
  const queryParams = url.searchParams;
  if (queryParams.get('resultId') || queryParams.get('payInvoiceId')) {
    if (JSON.stringify(url.pathname) === JSON.stringify('/ui/app/search/renewal-fee')) {
      return (
        <>
          <ProtectedRoute path={`${path}/renewal-fee`} component={RenewalPaymentResultForm} />
        </>
      );
    } else if (JSON.stringify(url.pathname) === JSON.stringify('/ui/app/search/application-fee')) {
      return (
        <>
          <ProtectedRoute path={`${path}/application-fee`} component={ApplicationPaymentResultForm} />
        </>
      );
    } else {
      return (
        <>
          <ProtectedRoute
            path={`${path}/accept-transfer-request`}
            permission={['DECLINE_LICENCE_TRANSFER_REQUEST']}
            component={TransferPaymentResultForm}
          />
        </>
      );
    }
  }
  return (
    <Switch>
      <ProtectedRoute
        path={`${path}/client`}
        component={SearchClientPage}
        permission={['SEARCH_ALL_CLIENT', 'SEARCH_ACTIVE_CLIENT']}
        blockedRole={['ROLE_PRIVATE_BAND_MANAGER', 'ROLE_RIGHT_HOLDER']}
      />
      <ProtectedRoute path={`${path}/licence`} component={SearchLicencePage} />
      <ProtectedRoute path={`${path}/licence-to-supply`} component={SearchLicencesToSupplyPage} permission={['VIEW_LICENCE_TO_SUPPLY']} />
      <ProtectedRoute path={`${path}/antenna`} component={SearchAntennaPage} permission={['VIEW_ANTENNA']} />
      <ProtectedRoute path={`${path}/location`} component={SearchLocationPage} permission={['VIEW_LOCATION']} />
      <ProtectedRoute path={`${path}/management-rights`} component={SearchManagementRightsPage} exact />
      <ProtectedRoute path={`${path}/mr-form11`} component={SearchMRForForm11Page} permission={['SEARCH_SPL_FOR_MORTGAGE']} />
      <ProtectedRoute path={`${path}/mr-form20`} component={SearchMRForForm20Page} />
      <ProtectedRoute path={`${path}/spl-form11`} component={SearchSPLForForm11Page} permission={['SEARCH_MR_FOR_MORTGAGE']} />
      <ProtectedRoute path={`${path}/spl-form20`} component={SearchSPLForForm20Page} />
      <ProtectedRoute path={`${path}/spl-form18`} component={SearchMcvBySPLForDischargePage} permission={['SEARCH_MORTGAGE_TO_DISCHARGE']} />
      <ProtectedRoute path={`${path}/mr-form18`} component={SearchMcvByMRForDischargePage} permission={['SEARCH_MORTGAGE_TO_DISCHARGE']} />
      <ProtectedRoute path={`${path}/mr-form21`} component={SearchMcvByMRForDischargePage} />
      <ProtectedRoute path={`${path}/spl-form21`} component={SearchMcvBySPLForDischargePage} />
      <ProtectedRoute path={`${path}/certificate`} component={SearchCertificatePage} />
      <ProtectedRoute path={`${path}/callsign`} component={SearchCallsignPage} />
      <ProtectedRoute path={`${path}/accept-transfer-request`} component={IncompleteTransferRequestPage} />
      <ProtectedRoute path={`${path}/withdraw-transfer-request`} component={IncompleteTransferRequestPage} />
      <ProtectedRoute path={`${path}/aton`} component={SearchAtonPage} />
      <ProtectedRoute path={`${path}/document-delivery-log`} component={SearchDocumentDeliveryLogPage} />
      <ProtectedRoute path={`${path}/renewal-fee`} component={SearchRenewalFeePage} />
      <ProtectedRoute path={`${path}/application-fee`} component={SearchApplicationFeePage} />
      <ProtectedRoute path={`${path}/task`} component={SearchTaskPage} />
    </Switch>
  );
};

export const useSearchNavigation = () => {
  const history = useHistory();
  const { loggedIn } = useAuth();

  const navigateToSearch = (searchType: SearchType, searchCriteria?: SearchCriteria, replaceHistory?: boolean, newTab?: boolean) => {
    // Logged in urls start with /ui/app. Public ones do not. Limited searching is available for both logged in and public users.
    const searchUrl = (searchType: SearchType, searchCriteria?: SearchCriteria) =>
      `${loggedIn ? '/ui/app' : '/ui'}/search/${searchType}?${stringifyQueryObject(searchCriteria)}`;

    if (replaceHistory) {
      history.replace(searchUrl(searchType, searchCriteria));
    } else if (newTab) {
      window.open(searchUrl(searchType, searchCriteria), '_blank');
    } else {
      history.push(searchUrl(searchType, searchCriteria));
    }
  };

  return {
    navigateToSearchClient: (searchCriteria?: ClientSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('client', searchCriteria, replaceHistory, newTab),
    navigateToSearchLicence: (searchCriteria?: LicenceSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('licence', searchCriteria, replaceHistory, newTab),
    navigateToSearchLicenceToSupply: (searchCriteria?: LicenceToSupplySearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('licence-to-supply', searchCriteria, replaceHistory, newTab),
    navigateToSearchAntenna: (searchCriteria?: ClientSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('antenna', searchCriteria, replaceHistory, newTab),
    navigateToSearchLocation: (searchCriteria?: LocationSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('location', searchCriteria, replaceHistory, newTab),
    navigateToSearchManagementRights: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('management-rights', searchCriteria, replaceHistory, newTab),
    navigateToSearchMRForForm11: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('mr-form11', searchCriteria, replaceHistory, newTab),
    navigateToSearchSLForForm11: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('spl-form11', searchCriteria, replaceHistory, newTab),
    navigateToSearchMRForForm20: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('mr-form20', searchCriteria, replaceHistory, newTab),
    navigateToSearchSLForForm20: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('spl-form20', searchCriteria, replaceHistory, newTab),
    navigateToSearchMRForForm18: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('mr-form18', searchCriteria, replaceHistory, newTab),
    navigateToSearchSLForForm18: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('spl-form18', searchCriteria, replaceHistory, newTab),
    navigateToSearchMRForForm21: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('mr-form21', searchCriteria, replaceHistory, newTab),
    navigateToSearchSLForForm21: (searchCriteria?: ManagementRightsSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('spl-form21', searchCriteria, replaceHistory, newTab),
    navigateToSearchMortgageCaveatBySplForForm18: (
      searchCriteria?: SearchMortgageCaveatByLicenceCriteria,
      replaceHistory?: boolean,
      newTab?: boolean,
    ) => navigateToSearch('spl-form18', searchCriteria, replaceHistory, newTab),
    navigateToSearchMortgageCaveatBySplForForm21: (
      searchCriteria?: SearchMortgageCaveatByLicenceCriteria,
      replaceHistory?: boolean,
      newTab?: boolean,
    ) => navigateToSearch('spl-form21', searchCriteria, replaceHistory, newTab),
    navigateToSearchMortgageCaveatByMrForForm18: (
      searchCriteria?: SearchMortgageCaveatByManagementRightCriteria,
      replaceHistory?: boolean,
      newTab?: boolean,
    ) => navigateToSearch('mr-form18', searchCriteria, replaceHistory, newTab),
    navigateToSearchMortgageCaveatByMrForForm21: (
      searchCriteria?: SearchMortgageCaveatByManagementRightCriteria,
      replaceHistory?: boolean,
      newTab?: boolean,
    ) => navigateToSearch('mr-form21', searchCriteria, replaceHistory, newTab),
    navigateToSearchCertificate: (searchCriteria?: CertificateSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('certificate', searchCriteria, replaceHistory, newTab),
    navigateToSearchCallsign: (searchCriteria?: CallsignSearchCriteria, replaceHistory?: boolean) =>
      navigateToSearch('callsign', searchCriteria, replaceHistory),
    navigateToSearchTransferRequestForAccept: (searchCriteria?: LicenceTransferRequestSearchCriteria, replaceHistory?: boolean) =>
      navigateToSearch('accept-transfer-request', searchCriteria, replaceHistory),
    navigateToSearchTransferRequestForWithdraw: (searchCriteria?: LicenceTransferRequestSearchCriteria, replaceHistory?: boolean) =>
      navigateToSearch('withdraw-transfer-request', searchCriteria, replaceHistory),
    navigateToSearchAton: (searchCriteria?: AtonSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('aton', searchCriteria, replaceHistory, newTab),
    navigateToSearchDocumentDeliveryLog: (searchCriteria?: DocumentDeliveryLogSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('document-delivery-log', searchCriteria, replaceHistory, newTab),
    navigateToSearchRenewalFee: (searchCriteria?: RenewalFeeSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('renewal-fee', searchCriteria, replaceHistory, newTab),
    navigateToSearchApplicationFee: (searchCriteria?: ApplicationFeeSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('application-fee', searchCriteria, replaceHistory, newTab),
    navigateToSearchTask: (searchCriteria?: TaskSearchCriteria, replaceHistory?: boolean, newTab?: boolean) =>
      navigateToSearch('task', searchCriteria, replaceHistory, newTab),
  };
};

export default SearchRouter;
