import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';

import {
  FrequencyDto,
  FrequencyDtoReferenceFrequencyTypeEnum,
  SpectrumDto,
  LicenceClassificationType,
  LicenceDtoLicenceTypeCodeEnum,
} from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { useForm } from 'common/form/FormHook';
import { SubSectionHeading } from 'common/layout/SectionHeadings';

import ReferenceFrequencyCard from './ReferenceFrequencyCard';

const emptyFrequency = (spectrum: SpectrumDto) =>
  withBounds(
    {
      referenceFrequency: undefined,
      referenceFrequencyType: undefined,
      power: undefined,
      powerType: undefined,
      frequencyEmissions: [],
      isoStartTime: undefined,
      isoStopTime: undefined,
    } as any,
    spectrum,
  );

export const withBounds = (frequency: FrequencyDto, { lowerBound, upperBound }: SpectrumDto) => ({
  ...frequency,
  lowerBound,
  upperBound,
});

export const referenceFrequencyOptions = Object.values(FrequencyDtoReferenceFrequencyTypeEnum).map((key) => ({ key, text: key }));

const ReferenceFrequenciesSection = ({
  spectrumIdx,
  licenceClassification,
  licenceTypeCode,
}: {
  spectrumIdx: number;
  licenceClassification?: LicenceClassificationType;
  licenceTypeCode?: LicenceDtoLicenceTypeCodeEnum;
}) => {
  const editMode = useForm().mode === 'EDIT';
  const { values } = useFormikContext<{ spectrums: SpectrumDto[] }>();
  const name = `spectrums[${spectrumIdx}].frequencies`;
  const spectrum = values.spectrums[spectrumIdx];
  const { hasRole } = useAuth();
  const isRreOrSaRole = hasRole('ROLE_RSM_RADIO_ENGINEER') || hasRole('ROLE_SYSTEM_ADMINISTRATOR');
  const isFreqReadOnly = licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL && spectrum.spectrumType === 'CHL' && !isRreOrSaRole;
  const canAddMore = (spectrum.frequencies?.length ?? 0) < referenceFrequencyOptions.length && !isFreqReadOnly;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div>
          <SubSectionHeading
            title="Reference frequencies"
            actionButtonOnClick={editMode && canAddMore ? () => arrayHelpers.push(emptyFrequency(spectrum)) : undefined}
          />
          <ErrorLabel name={name} showIcon />
          {(get(values, name) as []).map((_, idx) => (
            <ReferenceFrequencyCard
              name={`${name}[${idx}]`}
              spectrum={spectrum}
              isFreqReadOnly={isFreqReadOnly}
              onRemove={() => arrayHelpers.remove(idx)}
              key={idx}
              licenceClassification={licenceClassification}
              licenceTypeCode={licenceTypeCode}
            />
          ))}
        </div>
      )}
    />
  );
};

export default ReferenceFrequenciesSection;
