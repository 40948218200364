import { Text } from '@fluentui/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import OverlaySpinner from 'common/controls/progress/OverlaySpinner';

import Page from '../layout/Page';
import { loginExternalURL } from './login';

interface Props extends RouteComponentProps<{ returnPath?: string }> {}

const NotLoggedInPage: React.FC<Props> = (props) => {
  const redirectUrl = loginExternalURL();
  const [count, setCount] = React.useState(10);

  React.useEffect(() => {
    const id = setTimeout(() => setCount(count - 1), 1000);
    if (count === 0) {
      clearTimeout(id);
      window.location.href = redirectUrl;
    }
  }, [count, redirectUrl]);

  return (
    <Page title="Not logged in">
      {count === 0 && <OverlaySpinner label="Redirecting to RealMe..." />}
      <p>
        <Text>You need to be logged in to see this.</Text>
      </p>
      <p>
        <Text>You will be redirected to the login page in {count} seconds.</Text>
      </p>
      <a href={redirectUrl}>
        <Text>Click here to go straight to the login page.</Text>
      </a>
    </Page>
  );
};

export default NotLoggedInPage;
