import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ConfirmDialog from '../common/controls/surfaces/ConfirmDialog';
import { setAgreedViewSuppressed } from '../common/actions/loginActions';
import { useAuth } from '../common/auth/AuthHook';

/**
 * Show this prompt if the user logs in with the default role of ARE/ARC or switches to ARE/ARC. The result of the prompt
 * will persist for the logged in session. Agreeing yes to the prompt will give the user ability to search/view "suppressed"
 * current licences/applications.
 */
const AgreeViewSuppressedDialog = () => {
  const [showAgreeViewSuppressedDialog, setShowAgreeViewSuppressedDialog] = useState<boolean>(false);
  const { isInternalUser, hasRole, agreedViewSuppressed } = useAuth();
  const dispatch = useDispatch();
  const isAreOrArcRole = hasRole('ROLE_APPROVED_RADIO_ENGINEER') || hasRole('ROLE_APPROVED_RADIO_CERTIFIER');

  useEffect(() => {
    if (!isInternalUser && agreedViewSuppressed === undefined && isAreOrArcRole) {
      setShowAgreeViewSuppressedDialog(true);
    }
  }, [isInternalUser, agreedViewSuppressed, isAreOrArcRole]);

  return (
    <ConfirmDialog
      title="IMPORTANT NOTE FOR ARE's AND ARC's"
      visible={showAgreeViewSuppressedDialog}
      notDangerous
      onCancel={() => {
        dispatch(setAgreedViewSuppressed(false));
        setShowAgreeViewSuppressedDialog(false);
      }}
      onOk={() => {
        dispatch(setAgreedViewSuppressed(true));
        setShowAgreeViewSuppressedDialog(false);
      }}
      body={
        <div>
          <p>
            As an approved radio engineer (ARE) or certifier (ARC), you are authorised to search records that are withheld from general public viewing
            under the provisions of section 28 of the Radiocommunications Act 1989. Your LOGON will allow access to these records which are displayed
            in bold type. In using this search facility you accept that you:
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>Must only use the information contained in withheld records for the purposes of engineering certification.</li>
              <li>Must not download withheld records except for the purposes of engineering certification (including audit requirements).</li>
              <li>Must preserve the secrecy of all withheld records.</li>
            </ol>
          </p>
          <p>If you do not accept these conditions, the results will contain only Public records.</p>
        </div>
      }
      yesText="I Agree"
      noText="I Disagree"
    />
  );
};

export default AgreeViewSuppressedDialog;
