import { DefaultButton, IButtonStyles, Icon, IContextualMenuItem, IContextualMenuItemStyles } from '@fluentui/react';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuth } from 'common/auth/AuthHook';
import Persona from 'common/controls/items/Persona';
import { getClassNames } from 'common/layout/DesktopNavBar.classNames';

import { ConfirmNavigateModal, dirtyFormConfirmStrategy, neverConfirmStrategy } from '../controls/inputs/ConfirmOnClick';
import { RRFTheme, useTheme } from '../theme/RRFTheme';
import { NavigationContext } from './Navigation';

export interface DesktopNavProps {
  menus: MenuProps[];
  title: string;
}

export interface MenuProps {
  label: string;
  show: boolean;
  onClick?: () => void;
  items?: MenuItem[];
}

export interface MenuItem extends IContextualMenuItem {
  show: boolean;
  onClick?: () => void;
  subMenuItems?: MenuItem[];
}

const DesktopNavBar = (props: DesktopNavProps) => {
  const theme = useTheme();
  const { isCasualUser, loggedIn } = useAuth();
  const { push } = useHistory();
  const classNames = getClassNames(theme);
  const handleNavigation = () => (!isCasualUser() && loggedIn ? push('/ui/app/dashboard') : push('/ui/'));

  return (
    <div className={classNames.root} id="menu-bar">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.title !== undefined && props.title !== '' && (
          <Icon
            iconName="Home"
            onClick={handleNavigation}
            styles={{ root: { fontSize: '28px', cursor: 'pointer', color: 'white', marginRight: '5px' } }}
            title="Visit my RRF homepage"
          />
        )}
        <h3 className={classNames.title} style={{ display: 'inline-block' }}>
          {props.title}
        </h3>
      </div>
      <Menus {...props} />
      <div className={classNames.personaContainer}>
        <Persona />
      </div>
    </div>
  );
};

const Menus = (props: DesktopNavProps) => {
  const theme = useTheme();
  const classNames = getClassNames(theme);

  return (
    <div className={classNames.menusContainer}>
      {props.menus
        .filter((menu) => menu.show)
        .map((menu, idx) => (
          <span key={idx}>
            <Menu menu={menu} />
          </span>
        ))}
    </div>
  );
};

const Menu = (props: { menu: MenuProps }) => {
  const { formikContext, mode } = useContext(NavigationContext);
  const theme = useTheme();
  const classNames = getClassNames(theme);
  const [menuItemWasClickedProps, setMenuItemWasClickedProps] = useState<{
    onClick?: () => boolean | void;
  }>();

  const interceptMenuItemClicksForConfirming = (menus: IContextualMenuItem[]) =>
    menus.map((menu) => ({
      ...menu,
      onClick: () => setMenuItemWasClickedProps({ onClick: menu.onClick }),
      ...(menu.subMenuProps
        ? {
            subMenuProps: {
              ...menu.subMenuProps,
              items: menu.subMenuProps?.items.map((menuItem) => ({
                ...menuItem,
                onClick: () => setMenuItemWasClickedProps({ onClick: menuItem?.onClick }),
              })),
            },
          }
        : {}),
    }));

  const buttonStyles: IButtonStyles = {
    rootHovered: { backgroundColor: theme.semanticColors.menuHeader },
    rootPressed: { backgroundColor: theme.semanticColors.menuHeader, color: theme.palette.white },
  };

  return props.menu.items ? (
    <>
      <DefaultButton
        className={classNames.menu}
        text={props.menu.label}
        styles={buttonStyles}
        menuProps={{
          shouldFocusOnMount: true,
          items: interceptMenuItemClicksForConfirming(buildMenus({ ...props }, theme)),
          styles: {
            ...buttonStyles,
            list: {
              background: theme.semanticColors.menuHeader,
            },
          },
        }}
      />
      <ConfirmNavigateModal
        confirmStrategy={formikContext && mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy}
        onClick={menuItemWasClickedProps?.onClick!}
        onCancel={() => setMenuItemWasClickedProps(undefined)}
        visible={!!menuItemWasClickedProps?.onClick}
        formikContext={formikContext!}
      />
    </>
  ) : (
    <DefaultButton className={classNames.menu} styles={buttonStyles} text={props.menu.label} onClick={props.menu.onClick} />
  );
};

const subMenuItems = (item: MenuItem, subMenuStyle: Partial<IContextualMenuItemStyles>) => {
  return item.subMenuItems
    ? {
        subMenuProps: {
          items: item.subMenuItems
            .filter((subItem) => subItem.show)
            .map((subItem) => {
              return {
                ...subItem,
                itemProps: { styles: subMenuStyle },
                onClick: subItem.onClick,
              };
            }),
        },
      }
    : {};
};

const buildMenus = (props: { menu: MenuProps }, theme: RRFTheme): IContextualMenuItem[] => {
  const subMenuStyle: Partial<IContextualMenuItemStyles> = {
    root: {
      color: theme?.palette?.white,
      backgroundColor: theme.semanticColors.menuHeader,
      ':hover': { color: theme.palette.white, backgroundColor: theme.semanticColors.menuHeader },
      ':active': { color: theme.palette.white, backgroundColor: theme.semanticColors.menuHeader },
      ':focus': { color: theme.palette.white, backgroundColor: `${theme.semanticColors.menuHeader} !important` },
    },
  };

  return props.menu.items
    ? props.menu.items
        .filter((item) => item.show)
        .map((item) => {
          return {
            ...item,
            ...subMenuItems(item, subMenuStyle),
            itemProps: { styles: subMenuStyle },
            onClick: item.onClick,
          } as IContextualMenuItem;
        })
    : [];
};

export default DesktopNavBar;
