import { useFormikContext } from 'formik';
import React from 'react';

import { Dropdown, Grid2 } from 'ui-library';

import { AUDIT_METHOD_OPTIONS } from 'common/reference/referenceData';
import { AuditFormProps } from 'investigation/interfaces';

const AuditLicenceSection = () => {
  const { values } = useFormikContext<AuditFormProps>();
  return (
    <Grid2>
      <Grid2.Col colStart={1} lg={4}>
        <Dropdown label="Radio communication service of interest" options={values.serviceOfInterestOptions} name="serviceOfInterestId" />
      </Grid2.Col>
      <Grid2.Col colStart={1} lg={4}>
        <Dropdown label="Audit method" options={AUDIT_METHOD_OPTIONS} name="auditMethod" required />
      </Grid2.Col>
    </Grid2>
  );
};

export default AuditLicenceSection;
