import { DefaultButton } from '@fluentui/react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { CertificateDtoStatusEnum, TemporaryCallsignDto, ValidationCallsignRequestTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import { TextField } from 'common/controls/inputs/TextField';
import DetailsList from 'common/controls/lists/DetailsList';
import { useForm } from 'common/form/FormHook';
import { SectionHeading, SubSectionHeading } from 'common/layout/SectionHeadings';
import { isIsoDateFormat, RRF_DATE_FORMAT, toISODateString } from 'common/utils/dateUtils';

import { hasAction } from '../../../common/api/hateosUtils';
import { useCertificateApi } from '../CertificateApiHook';
import { validationCertificateForm } from '../Utils';

interface CertificateCallSignProps {
  hideHeader?: boolean;
  primaryCallsignMandatory?: boolean;
  primaryCallsignLabel?: string;
  secondaryCallsignLabel?: string;
  actionName: 'save-certificate' | 'save-callsign';
  validationAmateurCallsign?: boolean;
}

export interface ValidateCallsignContextProps {
  callsignErrors?: any;
  setCallsignErrors: (val: any) => void;
}

export const ValidateCallsignContext = React.createContext<ValidateCallsignContextProps>({
  callsignErrors: {},
  setCallsignErrors: (_) => {},
});

const CertificateCallSign: React.FC<CertificateCallSignProps> = (props) => {
  const { values, setValues, setErrors, setTouched } = useFormikContext<any>();
  const { validateCallsign, validateAmateurClubCallsign } = useCertificateApi();
  const { callsignErrors, setCallsignErrors } = React.useContext(ValidateCallsignContext);
  const { mode } = useForm();

  values.temporaryCallsigns?.forEach((tcs: any) => {
    if (tcs.commenceDateEditable === undefined) {
      tcs.commenceDateEditable = !tcs.id || moment(tcs.commenceDate).startOf('day').diff(moment().startOf('day'), 'days') > 0;
    }
  });

  const aYearLater = moment().add(1, 'year').subtract(1, 'day').toDate();

  const updateError = (path?: string, message?: string) => {
    const uiErrors = validationCertificateForm(values);
    const copiedError = JSON.parse(JSON.stringify(callsignErrors));
    if (path) {
      _.set(copiedError, path, message);
    }
    const cleanedApiErrors = cleanApiError(copiedError);
    setCallsignErrors(cleanedApiErrors);
    const errors = _.merge(uiErrors, cleanedApiErrors);

    setErrors(errors);
    setTouched(errors);
  };

  const cleanApiError = (apiErrors: any) => {
    const pathsToCheck = [
      'primaryCallsign.callsign',
      'secondaryCallsign.callsign',
      'temporaryCallsigns[0].callsign',
      'temporaryCallsigns[1].callsign',
    ];

    const result = {};
    pathsToCheck.forEach((path) => {
      const pathVal = _.get(apiErrors, path);
      if (!_.isEmpty(pathVal)) {
        _.set(result, path, pathVal);
      }
    });
    return result;
  };

  const handlePrimarySecondaryCallsignBlur = (isPrimary: boolean) => (e: any) => {
    const callsignId = _.get(values, isPrimary ? 'primaryCallsign.id' : 'secondaryCallsign.id');
    if (!_.isEmpty(e.target.value)) {
      (props.validationAmateurCallsign
        ? validateAmateurClubCallsign(
            {
              clientId: values.clientId,
              amateurClubCallsignId: values.id,
              validationCallsignRequest: {
                callsign: e.target.value,
                type: isPrimary ? ValidationCallsignRequestTypeEnum.PRIM : ValidationCallsignRequestTypeEnum.SECN,
                excludedIdList: !!callsignId ? [callsignId] : undefined,
              },
            },
            { showLoadingSpinner: true },
          )
        : validateCallsign(
            {
              callsign: e.target.value,
              type: isPrimary ? ValidationCallsignRequestTypeEnum.PRIM : ValidationCallsignRequestTypeEnum.SECN,
              excludedIdList: !!callsignId ? [callsignId] : undefined,
            },
            { showLoadingSpinner: true },
          )
      ).then(({ message, suggestions }) => {
        let callsignError = null;
        if (message !== null) {
          callsignError = message;
          callsignError +=
            suggestions && suggestions.length > 0 ? ' Available callsigns are: ' + suggestions.join(', ') : ' No callsign suggestions are found.';
        }
        updateError(isPrimary ? 'primaryCallsign.callsign' : 'secondaryCallsign.callsign', callsignError);
      });
    } else {
      updateError(isPrimary ? 'primaryCallsign.callsign' : 'secondaryCallsign.callsign');
    }
  };

  const handleTemporaryCallsignFieldBlur =
    (callsignDto: TemporaryCallsignDto, index: number, fieldName: string, isDateField?: boolean) => (e: any) => {
      const callsignToValidate = {
        ...callsignDto,
        [fieldName]: isDateField ? toISODateString(moment(e.target.value, RRF_DATE_FORMAT, true).toDate()) : e.target.value,
      };
      if (
        !_.isEmpty(callsignToValidate.callsign) &&
        !_.isEmpty(callsignToValidate.commenceDate) &&
        !_.isEmpty(callsignToValidate.expiryDate) &&
        isIsoDateFormat(callsignToValidate.commenceDate) &&
        isIsoDateFormat(callsignToValidate.expiryDate)
      ) {
        validateCallsign(
          {
            ...callsignToValidate,
            excludedIdList: !!callsignDto.id ? [callsignDto.id] : undefined,
            type: ValidationCallsignRequestTypeEnum.TEMP,
          },
          { showLoadingSpinner: true },
        ).then(({ message }) => updateError(`temporaryCallsigns.${index}.callsign`, message));
      } else {
        updateError(undefined, undefined);
      }
    };

  const canSave = mode === 'EDIT' && hasAction(props.actionName, values.links);
  const showRemove = canSave || mode === 'CREATE';
  const showAddTempCallsign = (mode === 'CREATE' || canSave) && (values.temporaryCallsigns?.length ?? 0) < 2;

  const primaryCallsignEditable =
    mode === 'CREATE' ||
    (hasAction(props.actionName, values.links) && values.status === CertificateDtoStatusEnum.Draft) ||
    (mode === 'EDIT' && !values?.primaryCallsign?.id);

  const temporaryCallsignColumns = [
    {
      key: 'callsign',
      fieldName: 'callsign',
      name: 'Callsign',
      minWidth: 100,
      maxWidth: 200,
      isMultiline: true,
      isResizable: true,
      onRender: (item: TemporaryCallsignDto, index?: number) => (
        <TextField
          name={`temporaryCallsigns.${index}.callsign`}
          onBlurCapture={handleTemporaryCallsignFieldBlur(item, index!, 'callsign', false)}
          readOnly={!!item.id}
        />
      ),
    },
    {
      key: 'commenceDate',
      fieldName: 'commenceDate',
      name: 'Commencement date',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (
        item: TemporaryCallsignDto & {
          commenceDateEditable?: boolean;
        },
        index?: number,
      ) => (
        <DatePicker
          name={`temporaryCallsigns.${index}.commenceDate`}
          dateOnlyAsString
          allowTextInput
          minDate={new Date()}
          maxDate={moment().add(21, 'months').subtract(1, 'day').toDate()}
          readOnly={!item.commenceDateEditable}
          onBlurCapture={handleTemporaryCallsignFieldBlur(item, index!, 'commenceDate', true)}
        />
      ),
    },
    {
      key: 'expiryDate',
      fieldName: 'expiryDate',
      name: 'Expiry date',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (item: TemporaryCallsignDto, index?: number) => (
        <DatePicker
          name={`temporaryCallsigns.${index}.expiryDate`}
          dateOnlyAsString
          allowTextInput
          minDate={new Date()}
          maxDate={moment().add(2, 'years').subtract(1, 'day').toDate()}
          onBlurCapture={handleTemporaryCallsignFieldBlur(item, index!, 'expiryDate', true)}
        />
      ),
    },
    {
      key: 'remarks',
      fieldName: 'remarks',
      name: 'Remarks',
      minWidth: 100,
      maxWidth: 380,
      isResizable: true,
      onRender: (item: TemporaryCallsignDto, index?: number) => <TextField name={`temporaryCallsigns.${index}.remarks`} maxLength={400} />,
    },
    {
      key: 'actions',
      fieldName: 'actions',
      name: '',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      onRender: (item: TemporaryCallsignDto, index?: number) => {
        return (
          <>
            {showRemove && (
              <DefaultButton
                text="Remove"
                onClick={() => {
                  setValues({
                    ...values,
                    temporaryCallsigns: values.temporaryCallsigns
                      .map((v: any, idx: number) => (index === idx ? undefined : v))
                      .filter((v: any) => !!v),
                  });
                }}
                id={'Remove-callsign-' + item.id}
                data-automation-id={'Remove-callsign-' + item.id}
              />
            )}
          </>
        );
      },
    },
  ];

  const labelPrimaryCallsign = props.primaryCallsignLabel ? props.primaryCallsignLabel : 'Primary personal callsign';
  return (
    <>
      {!!!props.hideHeader && <SectionHeading title="Callsign details" />}
      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          <TextField
            name={'primaryCallsign.callsign'}
            label={labelPrimaryCallsign}
            onBlurCapture={handlePrimarySecondaryCallsignBlur(true)}
            readOnly={!primaryCallsignEditable}
            required={props.primaryCallsignMandatory}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={6}>
          <TextField
            name={'secondaryCallsign.callsign'}
            label={props.secondaryCallsignLabel ? props.secondaryCallsignLabel : 'Secondary personal callsign'}
            onBlurCapture={handlePrimarySecondaryCallsignBlur(false)}
          />
        </Grid.Col>
      </Grid.Row>
      <SubSectionHeading
        title={'Temporary callsigns'}
        actionButtonText={'Add'}
        actionButtonId={'add-temporary-callsign'}
        hint={'Up to two temporary callsigns can be added.'}
        actionButtonOnClick={
          !showAddTempCallsign
            ? undefined
            : () => {
                setValues({
                  ...values,
                  temporaryCallsigns: [
                    ...(values.temporaryCallsigns ?? []),
                    {
                      callsign: undefined,
                      commenceDate: toISODateString(new Date()),
                      expiryDate: toISODateString(aYearLater),
                      remarks: undefined,
                    },
                  ],
                });
              }
        }
      />
      {values.temporaryCallsigns?.length > 0 ? (
        <DetailsList columns={temporaryCallsignColumns} items={(values.temporaryCallsigns ?? []).sort((a: any, b: any) => a.id - b.id)} />
      ) : (
        'There are no temporary callsigns.'
      )}
    </>
  );
};

export default CertificateCallSign;
