import { data, HtmlMarker } from 'azure-maps-control';
import * as React from 'react';
import { AzureMapDataSourceProvider, AzureMapLayerProvider, AzureMapsProvider } from 'react-azure-maps';

import { FeatureCollection, MapConfig } from 'api-client/dist/api';

import AzureLinzMap, {
  GeolocationMode,
  IMG_RRF_MARKER_LOC,
  IMG_RRF_MARKER_RCV,
  IMG_RRF_MARKER_SELECTED,
  IMG_RRF_MARKER_TRN,
} from 'common/controls/map/AzureLinzMap';
import { GeolocationProperties, IGeolocationControl } from 'common/controls/map/GeolocationControl';
import { CenterAndZoom, centerFromFeatures, fcToAzureFC, filterTrnRcvFeatures, isMouseoverMarker } from 'common/controls/map/MapUtil';
import { DEFAULT_POPUP_DETAILS, PopupDetails } from 'common/controls/map/PopupDetails';
import { usePublicApi } from 'public/PublicApiHook';
import { PopupType } from 'search/licence/MapLicenceDetailCard';

import { BUBBLE_LAYER_OPTIONS, clusterClicked, mouseLeaveCluster, mouseOverCluster } from '../../common/controls/map/BubbleUtils';
import '../licence/search_map.css';
import MapLocationDetailCard from './MapLocationDetailCard';

const LOCATION_SEARCH_DS = 'Location-Search-DS';
const LOCATION_SEARCH_SLP = 'LocationSearch-SLP';
const LOCATION_DETAIL_DS = 'Location-Detail-DS';
const LOCATION_DETAIL_SLP = 'LocationDetail-SLP';
const SYMBOL_LAYER_IDS = [LOCATION_SEARCH_SLP];

// properties interface -------------------------------------------------------
export interface LocationSearchMapProps {
  searchData: FeatureCollection | undefined;
  geolocInitCallback?: (iglc: IGeolocationControl, gpsSupported: boolean) => void;
  geolocPositionCallback?: (position: data.Feature<data.Point, GeolocationProperties>, marker?: HtmlMarker) => void;
  geolocMarkerRemovedCallback?: () => void;
}

// main react entity --------------------------

const LocationSearchMap: React.FC<LocationSearchMapProps> = (props) => {
  const { getMapConfig } = usePublicApi();
  const [mapConfig, setMapConfig] = React.useState<MapConfig | undefined>();
  const [popupDetails, setPopupDetails] = React.useState(DEFAULT_POPUP_DETAILS);
  const popupDetailsRef = React.useRef(DEFAULT_POPUP_DETAILS);
  const lastSearchDataRef = React.useRef<FeatureCollection | undefined | null>(null);
  const selectedGeoRef = React.useRef<string>('');
  const filteredDetailData = filterTrnRcvFeatures(props.searchData, true, true);

  console.log('Location Map data ', props.searchData);

  /**
   * in event handlers and callbacks state may be stale, so instead we use this setter which also stores
   * in an immediately accessible and up to date ref which can be checked in the event handlers
   */
  const _setPopupDetails = (val: PopupDetails) => {
    setPopupDetails(val); // update react state
    popupDetailsRef.current = val; // update immediate ref
  };

  React.useEffect(() => {
    // track changes to our search results to determine if we should re-zoom
    lastSearchDataRef.current = props.searchData;
  }, [props.searchData]);

  React.useEffect(() => {
    getMapConfig().then(setMapConfig);
    // eslint-disable-next-line
  }, []);

  const cz: CenterAndZoom = centerFromFeatures(props.searchData);

  const mouseLeaveLocation = (e: any) => {
    // mouse leave location, revert pointer and hide popup
    e.map.getCanvas().style.cursor = '';
    // hide popup if not already hidden and not modal. Note use of currrent ref to avoid stale state
    if (popupDetailsRef.current.visible && !popupDetailsRef.current.modal) {
      _setPopupDetails(DEFAULT_POPUP_DETAILS);
    }
  };

  const locationClicked = (e: any) => {
    selectedGeoRef.current = e?.shapes[0]?.data?.properties?.georefGridRef;
    showPopup(e, true);
  };

  /** mouse move callback from map, used instead of mouse leave on marker */
  const mapMouseMove = (e: any) => {
    if (!isMouseoverMarker(e, SYMBOL_LAYER_IDS)) {
      // if the map move is NOT over a point or one of our symbol layer items then count as a leave
      mouseLeaveLocation(e);
    }
  };

  /** mouse click callback from map, use to close modal if click on background */
  const mapMouseClick = (e: any) => {
    selectedGeoRef.current = '';
    if (!isMouseoverMarker(e, SYMBOL_LAYER_IDS)) {
      // if the map click is NOT over a point or one of our symbol layer items then use to close even if modal
      if (popupDetailsRef.current.visible) {
        _setPopupDetails(DEFAULT_POPUP_DETAILS);
      }
    }
  };

  const mouseOverLocation = (e: any) => {
    // mouse over location, change pointer and display popup
    e.map.getCanvas().style.cursor = 'pointer';
    // display non-modal if not already modal and visible. Note use of currrent ref to avoid stale state
  };

  const showPopup = (e: any, modal: boolean) => {
    if (e.shapes && e.shapes.length > 0 && e?.shapes[0]?.getProperties) {
      const pd: PopupDetails = {
        visible: true,
        modal: modal,
        dataType: PopupType.POPUP_TYPE_SEARCH,
        data: modal ? e.shapes.map((item: any) => item.getProperties()) : [e?.shapes[0]?.getProperties()],
        count: e.shapes.length,
        dataCount: modal ? e.shapes.length : 1,
      };
      _setPopupDetails(pd);
    } else {
      _setPopupDetails(DEFAULT_POPUP_DETAILS);
    }
  };

  const scrollStyle: React.CSSProperties = {
    overflowY: 'auto',
    maxHeight: '300px',
    minWidth: '170px',
    paddingRight: '2px',
    marginRight: '-2px',
  };

  // disply detail modal
  const detailMarkerClicked = (e: any) => {
    showInfoPoint(e, true, PopupType.POPUP_TYPE_DETAIL);
  };

  const showInfoPoint = (e: any, modal: boolean, dataType: PopupType) => {
    if (e?.shapes?.length > 0 && e?.shapes[0]?.getProperties) {
      const pd: PopupDetails = {
        visible: true,
        modal: true,
        dataType: dataType,
        data: e.shapes.map((item: any) => item.getProperties()),
        count: e?.shapes?.length,
        dataCount: e?.shapes?.length,
      };
      _setPopupDetails(pd);
    } else {
      _setPopupDetails(DEFAULT_POPUP_DETAILS);
    }
  };

  // TSX content -----------------------------
  return (
    <>
      {mapConfig && (
        <>
          {popupDetails.visible && (
            <div className="rmap-lic-popup" style={{ padding: '10px', marginTop: popupDetails.modal ? '7px' : '0px' }}>
              <div style={scrollStyle}>
                {popupDetails.data?.map((item, index) => (
                  <MapLocationDetailCard
                    key={index}
                    border={popupDetails.dataCount > 1 && popupDetails.modal}
                    index={index}
                    count={popupDetails.count}
                    data={item}
                    showLink={popupDetails.modal}
                    showCount={!popupDetails.modal && popupDetails.count > 1}
                  />
                ))}
              </div>
            </div>
          )}
          <AzureMapsProvider>
            <AzureLinzMap
              height={'600px'}
              width={'100%'}
              center={cz.center}
              zoom={cz.zoom}
              shouldZoom={lastSearchDataRef.current !== props.searchData}
              allowGeoLocation={true}
              geoLocationStartActive={true}
              geoLocationMode={GeolocationMode.GLM_LICENCE_SEARCH}
              geolocInitCallback={props.geolocInitCallback}
              geolocPositionCallback={props.geolocPositionCallback}
              geolocMarkerRemovedCallback={props.geolocMarkerRemovedCallback}
              allowTLA={true}
              mapConfig={mapConfig}
              mapMouseMoveCallback={mapMouseMove}
              mapMouseClickCallback={mapMouseClick}
            >
              <AzureMapDataSourceProvider
                id={LOCATION_SEARCH_DS}
                collection={fcToAzureFC(props.searchData)}
                options={{
                  cluster: true, // enable clustering
                  clusterRadius: 20, // The radius in pixels to cluster points together.
                  clusterMaxZoom: 13, // The maximium zoom level at which clustering occurs.
                }}
              >
                <AzureMapLayerProvider
                  id={'BubbleLayer-LayerProvider'}
                  options={BUBBLE_LAYER_OPTIONS}
                  type="BubbleLayer"
                  events={{
                    mouseenter: mouseOverCluster,
                    mouseleave: mouseLeaveCluster,
                    click: (e: any) => {
                      _setPopupDetails(DEFAULT_POPUP_DETAILS); // ensure any modal popups closed on cluster nav
                      clusterClicked(LOCATION_SEARCH_DS, e);
                    },
                  }}
                ></AzureMapLayerProvider>
                <AzureMapLayerProvider
                  id={'BubbleLayer-count-LayerProvider'}
                  options={{
                    iconOptions: { image: 'none' }, // Hide the icon image.
                    textOptions: { textField: ['get', 'point_count_abbreviated'], offset: [-0.05, 0.4] },
                  }}
                  type="SymbolLayer"
                ></AzureMapLayerProvider>
                <AzureMapLayerProvider
                  id={LOCATION_SEARCH_SLP}
                  options={{
                    filter: ['!', ['has', 'point_count']], // Filter out clustered points from this layer.
                    iconOptions: {
                      allowOverlap: true,
                      ignorePlacement: true,
                      optional: true,
                      anchor: 'bottom',
                      image: ['case', ['==', ['get', 'georefGridRef'], `${selectedGeoRef.current}`], IMG_RRF_MARKER_SELECTED, IMG_RRF_MARKER_LOC],
                    },
                    textOptions: {
                      allowOverlap: false,
                      ignorePlacement: false,
                      optional: true,
                      anchor: 'bottom',
                      textField: ['get', 'locNameShort'],
                      offset: [0.01, -0.2],
                    },
                  }}
                  // click and mousemove events to show popups
                  events={{
                    mouseenter: mouseOverLocation,
                    // mouseleave: mouseLeaveLicence, mouseleave is problematic, using map mousemove callback
                    click: locationClicked,
                  }}
                  type="SymbolLayer"
                ></AzureMapLayerProvider>
              </AzureMapDataSourceProvider>

              <AzureMapDataSourceProvider id={LOCATION_DETAIL_DS} collection={filteredDetailData}>
                <AzureMapLayerProvider
                  id={'LocationDetail-PolygonLayer'}
                  options={{
                    filter: ['==', ['geometry-type'], 'Polygon'], // Polygons only (area)
                    fillOpacity: 0.25,
                    fillColor: ['match', ['get', 'configType'], 'TRN', '#00ff00', '#ff0000'],
                  }}
                  type={'PolygonLayer'}
                />
                <AzureMapLayerProvider
                  id={'LocationDetail-PolygonLineLayer'}
                  options={{
                    filter: ['==', ['geometry-type'], 'Polygon'], // Polygons only (lines)
                    strokeWidth: 2,
                    strokeColor: ['match', ['get', 'configType'], 'TRN', '#00ff00', '#ff0000'],
                    offset: ['match', ['get', 'configType'], 'TRN', 1, -1],
                  }}
                  type={'LineLayer'}
                />
                <AzureMapLayerProvider
                  id={'LocationDetail-LineLayer'}
                  options={{
                    filter: ['==', ['geometry-type'], 'LineString'], // lines only (pt to pt)
                    strokeWidth: 1,
                    strokeColor: '#0000ff',
                  }}
                  type={'LineLayer'}
                />
                <AzureMapLayerProvider
                  id={LOCATION_DETAIL_SLP}
                  options={{
                    filter: ['==', ['geometry-type'], 'Point'], // only display points in symbol layer
                    iconOptions: {
                      allowOverlap: true,
                      ignorePlacement: true,
                      optional: true,
                      anchor: 'bottom',
                      //image: ['match', ['get', 'configType'], 'TRN', IMG_RRF_MARKER_TRN, IMG_RRF_MARKER_RCV],
                      image: [
                        'case',
                        ['==', ['get', 'georefGridRef'], `${selectedGeoRef.current}`],
                        IMG_RRF_MARKER_SELECTED,
                        ['==', ['get', 'configType'], 'TRN'],
                        IMG_RRF_MARKER_TRN,
                        IMG_RRF_MARKER_RCV,
                      ],
                    },
                    textOptions: {
                      allowOverlap: false,
                      ignorePlacement: false,
                      optional: true,
                      anchor: 'bottom',
                      textField: ['match', ['get', 'configType'], 'TRN', 'T', 'RCV', 'R', ['get', 'locNameShort']],
                      offset: [0.01, -0.2],
                    },
                  }}
                  // mousemove events to show/hide popups.
                  events={{
                    mouseenter: (e: any) => {
                      e.map.getCanvas().style.cursor = 'pointer';
                    },
                    mouseleave: (e: any) => {
                      e.map.getCanvas().style.cursor = '';
                    },
                    // mouseleave: mouseLeaveLicence, mouseleave is problematic, using map mousemove callback
                    click: detailMarkerClicked,
                  }}
                  type="SymbolLayer"
                ></AzureMapLayerProvider>
              </AzureMapDataSourceProvider>
            </AzureLinzMap>
          </AzureMapsProvider>
        </>
      )}
    </>
  );
};

export default LocationSearchMap;
