import { ColumnActionsMode, IColumn, Icon, Link, mergeStyles, MessageBar, MessageBarType, useTheme } from '@fluentui/react';
import React from 'react';

import { CollectionMetadata } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';

import { SearchCriteria } from '../../../search/SearchRouter';
import HorizontalDivider from '../../layout/HorizontalDivider';
import { isEmpty } from '../../utils/objectUtils';

interface Props extends Pick<CollectionMetadata, 'pageSize' | 'page' | 'totalPages' | 'totalItems'> {
  renderResults: () => JSX.Element;
  filtersSummary?: string[];
  onPaging: (page: number, pageSize: number) => void;
  enableTopPagingContols?: boolean;
  showIncreasedPageSizes?: boolean;
}

interface PSCProps extends Props {
  small?: boolean;
}

const Pagination: React.FC<Props> = (props) => {
  const { isInternalUser } = useAuth();
  return (
    <>
      {isInternalUser && props.totalItems > 100000 && (
        <Grid.Row style={{ marginBottom: 10 }}>
          <MessageBar messageBarType={MessageBarType.warning}>
            Only the first 100,000 records are returned below. Enter additional search criteria to reduce the number of results.
          </MessageBar>
        </Grid.Row>
      )}
      <Grid.Row>
        {props.filtersSummary ? (
          <Grid.Col sm={12} lg={8}>
            <span>
              Applied filters: <FiltersSummary {...props} />
            </span>
          </Grid.Col>
        ) : (
          <Grid.Col lg={8} style={{ paddingBottom: '12px' }} />
        )}
        <Grid.Col sm={12} lg={4}>
          <PageSizeSelection {...props} />
        </Grid.Col>
      </Grid.Row>
      {props.enableTopPagingContols && <PagedSearchControls small={true} {...props} />}
      <SearchResultsHighlight>{props.renderResults()}</SearchResultsHighlight>
      <PagedSearchControls {...props} />
    </>
  );
};

export const SearchResultsHighlight = (props: { children: any }) => {
  const searchResultHighlightBoldClass = mergeStyles({
    nav: { selectors: { '& em': { fontStyle: 'normal', fontWeight: 'bold' } } },
  });
  return <div className={searchResultHighlightBoldClass}>{props.children}</div>;
};

const FiltersSummary = ({ filtersSummary }: Props) => <b>{isEmpty(filtersSummary) ? 'None' : filtersSummary!.map((f) => ' ' + f).join()}</b>;

const PageSizeSelection = ({ totalItems, pageSize, onPaging, showIncreasedPageSizes }: Props) => (
  <span style={{ float: 'right' }}>
    {totalItems} results found | Showing&nbsp;
    <select value={pageSize} onChange={(ev) => onPaging(1, +ev.target.value)} title="Page size selector" aria-label="Page size selector">
      {[20, 50, 100, 200, 300, 500, ...(showIncreasedPageSizes ? [1000, 2000, 5000] : [])].map((value) => (
        <option key={`page-size-option-${value}`} value={value}>
          {value} per page
        </option>
      ))}
    </select>
  </span>
);

const PagedSearchControls = ({ pageSize, page, totalPages, onPaging, small }: PSCProps) => {
  const theme = useTheme();
  const colourClass = mergeStyles({ color: theme.semanticColors.buttonBackground });

  const maybeIcon = (key: string | number, disabled: boolean = false) => {
    if (key === '<' || key === '>') {
      return (
        <Icon
          iconName={`Chevron${key === '<' ? 'Left' : 'Right'}Small`}
          className={colourClass}
          style={{
            fontSize: theme.fonts.medium?.fontSize,
            color: disabled ? theme.semanticColors.inputIconDisabled : undefined,
          }}
        />
      );
    }
    return key;
  };

  /* eg:
        =1 2 ... 100 >
        < 1 =2 3 ... 100 >
        < 1 2 =3 4 ... 100 >
        < 1 ... 3 =4 5 ... 100 >
        < 1 ... 97 =98 99 100 >
        < 1 ... 98 =99 100 >
        < 1 ... 99 =100
     */
  const pages = pageLinks(page, totalPages)
    .map((p) =>
      p![1] ? (
        <Link
          className={colourClass}
          onClick={(e) => {
            onPaging(+p![1]!, pageSize);
            e.preventDefault();
          }}
          title={`Pagination Page ${p![0]!}`}
        >
          {maybeIcon(p![0]!)}
        </Link>
      ) : (
        maybeIcon(p![0]!, true)
      ),
    )
    .map((p, index) => (
      <span key={index} style={{ padding: 5 }}>
        {p}
      </span>
    ));

  return (
    <div style={{ marginBottom: small ? 0 : 48 }}>
      {!small && <HorizontalDivider />}
      <div style={{ margin: small ? 2 : 16, textAlign: 'center', verticalAlign: 'middle' }}>{pages}</div>
    </div>
  );
};

/**
 * @return Array of array pairs where each pair of the array represents a paging link text and page (or null if text only) respectively.
 */
export const pageLinks = (page: number, totalPages: number) =>
  [
    page > 1 ? ['<', page - 1] : ['<', null],
    page > 1 ? [1, 1] : null,
    page > 3 ? ['...', null] : null,
    page > 2 ? [page - 1, page - 1] : null,
    [page, null],
    page < totalPages - 1 ? [page + 1, page + 1] : null,
    page < totalPages - 2 ? ['...', null] : null,
    page < totalPages ? [totalPages, totalPages] : null,
    page < totalPages ? ['>', page + 1] : ['>', null],
  ].filter((p) => !!p);

export const withSorting = (
  columns: (IColumn & { isSortable?: boolean })[],
  // This must be the full search criteria object including filters
  searchCriteria: SearchCriteria = {},
  onOrderBy: (orderBy: string) => void,
) => {
  const sorting = (column: string): Pick<IColumn, 'isSorted' | 'isSortedDescending' | 'onColumnClick'> => {
    const orderByColumn = searchCriteria.orderBy?.split(',').find((o: string) => o.split(' ')[0] === column);
    const isSorted = !!orderByColumn;
    const isSortedDescending = !!orderByColumn?.endsWith(' desc');

    return {
      isSorted,
      isSortedDescending,
      onColumnClick: () => onOrderBy(column + (isSorted && !isSortedDescending ? ' desc' : '')),
    };
  };

  return columns.map((col) =>
    isEmpty(col.fieldName) || col.isSortable === false
      ? {
          ...col,
          columnActionsMode: ColumnActionsMode.disabled,
        }
      : {
          isPadded: true,
          ...col,
          ...sorting(col.fieldName!),
        },
  );
};

export function highlightOrValue(highlight?: string | null, value?: string | number | null) {
  if (highlight && highlight !== null) {
    return <span dangerouslySetInnerHTML={{ __html: highlight }} />;
  }
  return value;
}

export default Pagination;
