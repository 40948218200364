import { Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';

const LocationAuditInformation = () => {
  return (
    <>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Created by'} name={'createdName'} readOnly />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Created date'} name={'created'} readOnly readOnlyRenderFormat={formatDateTimeFromISO} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Last updated by'} name={'lastUpdatedName'} readOnly />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Last updated date'} name={'lastUpdated'} readOnly readOnlyRenderFormat={formatDateTimeFromISO} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default LocationAuditInformation;
