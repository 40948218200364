import { Dropdown, Label, TextField } from '@fluentui/react';
import Axios from 'axios';
import * as _ from 'lodash';
import * as React from 'react';

import { Grid } from 'ui-library';

import WarningButton from 'common/controls/inputs/WarningButton';
import Card from 'common/controls/surfaces/Card';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import useBpmMaintainApi from 'dashboard/camunda_manager/BpmMaintainApiHook';

interface Props {}

const BpmManagement: React.FC<Props> = (props) => {
  const [processDefinitionKeys, setProcessDefinitionKeys] = React.useState([]);
  const [processDefinitionKey, setProcessDefinitionKey] = React.useState<string | undefined>('');
  const [deleteBatchId, setDeleteBatchId] = React.useState<string | undefined>('');
  const [taskDefinitionKey, setTaskDefinitionKey] = React.useState<string | undefined>('');
  const [assignee, setAssignee] = React.useState<string | undefined>('');
  const [variable, setVariable] = React.useState<string | undefined>('');
  const { setIsLoading } = usePage();
  const { serializeObejct } = useBpmMaintainApi();

  React.useEffect(() => {
    setIsLoading(true);
    Axios.get('/development/bpm/process-definition-keys')
      .then((r) => setProcessDefinitionKeys(r.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleDeleteByProcessDefinitioKey = () => {
    if (processDefinitionKey !== '') {
      setIsLoading(true);
      Axios.delete(`/development/bpm/process/${processDefinitionKey}`, { timeout: 20000 })
        .then((r) => {
          setProcessDefinitionKey('');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleDeleteAllHistory = () => {
    setIsLoading(true);
    Axios.delete(`/development/bpm/process/history`, { timeout: 200000 })
      .then((r) => {
        setDeleteBatchId(r.data);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAssign = () => {
    if (processDefinitionKey !== '' && taskDefinitionKey !== '' && assignee !== '') {
      setIsLoading(true);
      Axios.put(`/development/bpm/process/${processDefinitionKey}/task/${taskDefinitionKey}/assign/${assignee}`, { timeout: 20000 })
        .then((r) => {
          setProcessDefinitionKey('');
          setTaskDefinitionKey('');
          setAssignee('');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleSerializeProcess = () => {
    if (processDefinitionKey && variable && processDefinitionKey !== '' && variable !== '') {
      serializeObejct(processDefinitionKey, variable).catch((err) => console.log(err));
    }
  };

  return (
    <Page title="Bpm management">
      <h3>Batch operations</h3>
      <Card>
        <h4>Delete</h4>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={3}>
              <Dropdown
                label="By process definition key"
                options={_.sortBy(processDefinitionKeys).map((p) => {
                  return { key: p, text: p };
                })}
                selectedKey={processDefinitionKey}
                onChange={(_, value) => setProcessDefinitionKey(value?.key as string)}
              />
            </Grid.Col>
            <Grid.Col lg={3}>
              <Label>&nbsp;</Label>
              <WarningButton text="Execute" onClick={handleDeleteByProcessDefinitioKey} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <Label>&nbsp;</Label>
              <WarningButton text="Delete all history (Beware!)" onClick={handleDeleteAllHistory} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <Label>&nbsp;</Label>
              {deleteBatchId && `Batch id: ${deleteBatchId}`}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Card>
      <Card>
        <h4>Assign</h4>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={3}>
              <TextField label="Process definition key" value={processDefinitionKey} onChange={(_, value) => setProcessDefinitionKey(value)} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <TextField label="Task definition key" value={taskDefinitionKey} onChange={(_, value) => setTaskDefinitionKey(value)} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <TextField label="Assignee (usr_id)" value={assignee} onChange={(_, value) => setAssignee(value)} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <Label>&nbsp;</Label>
              <WarningButton text="Execute" onClick={handleAssign} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Card>
      <Card>
        <h4>Update Serialize</h4>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={3}>
              <Dropdown
                label="By process definition key"
                options={_.sortBy(processDefinitionKeys).map((p) => {
                  return { key: p, text: p };
                })}
                selectedKey={processDefinitionKey}
                onChange={(_, value) => setProcessDefinitionKey(value?.key as string)}
              />
            </Grid.Col>
            <Grid.Col lg={3}>
              <TextField label="Variable" value={variable} onChange={(_, value) => setVariable(value)} />
            </Grid.Col>
            <Grid.Col lg={3}>
              <Label>&nbsp;</Label>
              <WarningButton text="Update Process" onClick={handleSerializeProcess} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Card>
    </Page>
  );
};

export default BpmManagement;
