import { FocusZoneTabbableElements, IColumn, Text } from '@fluentui/react';
import { useEventApi } from 'event/file_note/FileNoteApiHooks';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { FileNoteDto, FileNoteDtoEntityTypeEnum, FileNoteDtoRestrictedTypeEnum } from 'api-client';

import Grid from 'ui-library/grid';

import { useAuth } from 'common/auth/AuthHook';
import InlineButton from 'common/controls/buttons/InlineButton';
import DetailsList from 'common/controls/lists/DetailsList';
import { FormMode } from 'common/form/Form';
import { useMedia } from 'common/layout/MediaHook';
import usePage from 'common/layout/PageHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import {
  CASE_FILE_NOTE_TYPE,
  CLIENT_FILE_NOTE_TYPE,
  COMMON_FILE_NOTE_TYPE,
  LICENCE_APPLICATION_FILE_NOTE_TYPE,
  LICENCE_FILE_NOTE_TYPE,
  SHIP_CALLSIGN_FILE_NOTE_TYPE,
  USER_ACCOUNT_FILE_NOTE_TYPE,
} from 'common/reference/referenceData';
import { formatDateFromISO } from 'common/utils/dateUtils';

import { hasAction } from '../../../common/api/hateosUtils';
import FileNoteRow from './FileNoteRow';

export type EntityType =
  | 'AMATEUR_CLUB_CALLSIGN'
  | 'ANTENNA'
  | 'ATON'
  | 'CASE'
  | 'CERTIFICATE'
  | 'CLIENT'
  | 'EQUIPMENT'
  | 'FORM_QUEUE'
  | 'LICENCE_APPLICATION'
  | 'LICENCE'
  | 'LICENCE_TO_SUPPLY'
  | 'LOCATION'
  | 'MANAGEMENT_RIGHT'
  | 'MODIFIED_SPECTRUM_LICENCE'
  | 'SHIP_CALLSIGN'
  | 'SPECTRUM'
  | 'SPECTRUM_FORM'
  | 'SPECTRUM_MASK'
  | 'USER_ACCOUNT';

interface Props {
  entityId: number;
  entityType: EntityType;
  noteTypes: any[];
  suppressedHeading?: boolean;
}

export interface SortProps {
  sortKey: string;
  descending: boolean;
}

const fileNoteColumns = (
  isMobile: boolean,
  onReadOnly: (readOnly: boolean) => void,
  canRemove: boolean,
  onView: (rowIndex: number) => void,
  onRemove: (fileNote: FileNoteDto) => void,
): IColumn[] => [
  {
    key: 'noteDate',
    fieldName: 'noteDate',
    name: 'File note date',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item: FileNoteDto) => formatDateFromISO(item.noteDate),
  },
  {
    key: 'type',
    fieldName: 'type',
    name: 'File note type',
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isMultiline: true,
    onRender: (item: FileNoteDto) => {
      if (item.entityType === FileNoteDtoEntityTypeEnum.CASE) {
        return CASE_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
      }
      if (item.entityType === FileNoteDtoEntityTypeEnum.CLIENT) {
        return CLIENT_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
      }
      if (item.entityType === FileNoteDtoEntityTypeEnum.SHIP_CALLSIGN) {
        return SHIP_CALLSIGN_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
      }
      if (item.entityType === FileNoteDtoEntityTypeEnum.USER_ACCOUNT) {
        return USER_ACCOUNT_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
      }
      if (item.entityType === FileNoteDtoEntityTypeEnum.LICENCE) {
        return LICENCE_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
      }
      if (item.entityType === FileNoteDtoEntityTypeEnum.LICENCE_APPLICATION) {
        return LICENCE_APPLICATION_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
      }
      return COMMON_FILE_NOTE_TYPE.find(({ key }) => key === item.type)?.text;
    },
  },
  {
    key: 'noteTitle',
    fieldName: 'noteTitle',
    name: 'Note title',
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
  },
  {
    key: 'restricted',
    fieldName: 'restricted',
    name: 'Restricted',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    onRender: (item: FileNoteDto) => (item.restrictedType === FileNoteDtoRestrictedTypeEnum.Private ? 'Private' : 'RSM only'),
  },
  {
    key: 'createdName',
    fieldName: 'createdName',
    name: 'Created by',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'attachmentId',
    fieldName: 'attachmentId',
    name: 'Attachment',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: FileNoteDto) => (item.fileNoteDrmIds && item.fileNoteDrmIds.length > 0 ? 'Y' : ''),
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: isMobile ? 0 : 200,
    onRender: (item: FileNoteDto, index) => (
      <>
        <InlineButton
          text={'View'}
          onClick={() => {
            onView(index!);
            onReadOnly(true);
          }}
          id={`view-${item.id}`}
          data-automation-id={`view-${item.id}`}
        />
        {hasAction('remove-file-note', item?.links) && (
          <InlineButton
            text={'Remove'}
            onClick={() => onRemove(item)}
            id={`remove-${item.id}`}
            data-automation-id={`remove-${item.id}`}
            style={{ marginLeft: '10px' }}
          />
        )}
      </>
    ),
  },
];

export const FileNoteSection: React.FC<Props> = (props) => {
  const [mode] = useState<FormMode>('VIEW');
  const [fileNoteToBeAdded, setFileNoteToBeAdded] = useState<FileNoteDto>();
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const { isMobile } = useMedia();
  const { setShowWarningModal } = usePage();
  const { getFileNotes, removeFileNote } = useEventApi();
  const { hasPermission } = useAuth();
  const [canRemove] = useState<boolean>(hasPermission('REMOVE_FILE_NOTE_AND_ATTACHMENT'));
  const [readOnly, setReadOnly] = useState<boolean>(true);

  const [fileNotes, setFileNotes] = useState<FileNoteDto[]>();

  useEffect(() => {
    callFileNoteApi();
  }, []);

  const callFileNoteApi = () => {
    getFileNotes(props.entityId, props.entityType).then((results) => setFileNotes(results));
  };

  const onRemove = (fileNote: FileNoteDto) => {
    setShowWarningModal(true, [`You are about to remove the note, do you want to continue?`], false, {
      onConfirm: () => removeFileNote(+fileNote.id!, fileNote).then(callFileNoteApi),
    });
  };

  const [sortProps, setSortProps] = useState<SortProps>({
    sortKey: 'noteDate',
    descending: true,
  });

  const sort = (sourceItems: FileNoteDto[], sortName: string, descending: boolean) =>
    [...sourceItems].sort((a: any, b: any) => {
      const sortVal = a[sortName] < b[sortName] ? -1 : 1;
      return descending ? 0 - sortVal : sortVal;
    });

  const handleReadOnly = () => {
    setReadOnly(true);
  };

  const canAddFileNote = hasPermission('ADD_FILE_NOTE_AND_UPLOAD_ATTACHMENT');

  const addFileNote = mode === 'CREATE' && canAddFileNote ? (result: FileNoteDto) => setFileNotes([result, ...(fileNotes || [])]) : callFileNoteApi;

  return (
    <>
      <SectionHeading
        title={'File note and attachment'}
        actionButtonId={'add-file-note'}
        actionButtonOnClick={() => {
          setFileNoteToBeAdded({ entityId: 1 });
          setReadOnly(false);
        }}
        hideActionButton={!canAddFileNote}
        headingStyle={props.suppressedHeading ? { backgroundColor: '#FFF4CE' } : undefined}
      >
        {fileNoteToBeAdded && (
          <FileNoteRow
            entityId={props.entityId}
            entityType={props.entityType}
            noteTypes={props.noteTypes.filter((x) => x.onlyMigrated === undefined)}
            fileNote={{ ...fileNoteToBeAdded }}
            hideForm={() => setFileNoteToBeAdded(undefined)}
            mode={'create'}
            addFileNote={addFileNote}
            readOnly={readOnly}
          />
        )}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            {!fileNotes || fileNotes.length === 0 ? (
              <Text>There are no file notes.</Text>
            ) : (
              <DetailsList
                items={sort(fileNotes, sortProps.sortKey, sortProps.descending)}
                columns={fileNoteColumns(isMobile, handleReadOnly, !!canRemove, setExpandedIndex, onRemove)}
                expandedIndex={expandedIndex}
                renderExpandPanel={(item: FileNoteDto) => (
                  <FileNoteRow
                    fileNote={item}
                    entityId={props.entityId}
                    entityType={props.entityType}
                    noteTypes={props.noteTypes}
                    hideForm={() => setExpandedIndex(-1)}
                    mode={hasAction('edit-file-note', item?.links) ? 'update' : 'view'}
                    addFileNote={addFileNote}
                    readOnly={readOnly}
                  />
                )}
                onSort={(newSortProps) => {
                  const excludeColumns = ['actions', 'attachmentId'];
                  if (excludeColumns.indexOf(newSortProps.sortKey) < 0) {
                    setSortProps(newSortProps);
                  }
                }}
                sortProps={sortProps}
                handleTabKey={FocusZoneTabbableElements.all}
              />
            )}
          </Grid.Col>
        </Grid.Row>
      </SectionHeading>
    </>
  );
};

export default FileNoteSection;
