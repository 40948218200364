import { IconButton, Link, mergeStyles, useTheme } from '@fluentui/react';
import { ENVIRONMENT } from 'env-config';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { useAuth } from 'common/auth/AuthHook';

import { ConfirmNavigateModal, dirtyFormConfirmStrategy, neverConfirmStrategy } from '../controls/inputs/ConfirmOnClick';
import { useMedia } from './MediaHook';
import { NavigationContext } from './Navigation';
import usePage from './PageHook';
import rsmLogo from './logo.png';

/**
 * TODO use Header Theme
 * @returns
 */
export const Header: React.FC = () => {
  const { isMobile } = useMedia();
  const headerClass = mergeStyles({
    display: 'flex',
    gridArea: 'header',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  });

  const { formikContext, mode } = useContext(NavigationContext);
  const [logoClicked, setLogoClicked] = useState<boolean>(false);
  const rsmLogoUrl = 'https://www.rsm.govt.nz';

  const RsmLogo = () => {
    const logoClass = mergeStyles({ maxWidth: isMobile ? '100px' : '200px', marginTop: isMobile ? '0' : '15px' });

    return (
      <>
        <Link onClick={() => setLogoClicked(true)} title="Visit RSM Website">
          <img className={logoClass} src={rsmLogo} alt="Visit RSM Website" />
        </Link>
        {ENVIRONMENT !== 'PRD' && <h3 style={{ color: 'red', fontWeight: 'bolder' }}>{ENVIRONMENT}</h3>}
      </>
    );
  };

  return (
    <header className={headerClass}>
      <RsmLogo />
      <div style={{ display: 'flex', gap: '25px' }}>
        {!isMobile && <Home />}
        {isMobile && <MobileNavIcon />}
      </div>
      <ConfirmNavigateModal
        confirmStrategy={formikContext && mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy}
        onClick={() => window.open(rsmLogoUrl)}
        onCancel={() => setLogoClicked(false)}
        visible={logoClicked}
        formikContext={formikContext!}
      />
    </header>
  );
};

const Home = () => {
  const history = useHistory();
  const { isCasualUser, loggedIn } = useAuth();
  const handleNavigation = () => (!isCasualUser() && loggedIn ? history.push('/ui/app/dashboard') : history.push('/ui/'));

  const className = mergeStyles({
    fontSize: '1rem',
    transition: 'all .3s ease',
    '&:hover': {
      color: '#6dbe97',
    },
  });

  return (
    <div data-automation-id="home">
      <Link className={className} onClick={handleNavigation} title="Visit my RRF homepage">
        Register of Radio Frequencies (RRF)
      </Link>
    </div>
  );
};

const MobileNavIcon = () => {
  const { showMobileNav, setShowMobileNav } = usePage();
  const { semanticColors } = useTheme();
  const iconClass = mergeStyles({
    fontSize: 30,
    height: 30,
    width: 30,
    margin: '0 25px',
    justifySelf: 'end',
  });

  return (
    <IconButton
      className={iconClass}
      iconProps={{ iconName: 'CollapseMenu', style: { fontSize: 30, color: semanticColors.bodyText } }}
      onClick={() => setShowMobileNav(!showMobileNav)}
      ariaLabel="Show/hide mobile navigation"
    />
  );
};
