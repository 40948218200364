import { useFormikContext } from 'formik';
import React from 'react';

import { CaseStatusEnum, CaseTypeGroupEnum } from 'api-client';

import { Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import HorizontalDivider from 'common/layout/HorizontalDivider';
import { formatDate } from 'common/utils/dateUtils';
import { convertStatus } from 'investigation/hooks';
import { BaseCaseFormProps } from 'investigation/interfaces';

const CaseReadOnlyFields = () => {
  const { values } = useFormikContext<BaseCaseFormProps>();
  const caseType = values.caseTypeOptions.find(({ key }) => key === values.caseTypeId)?.data;
  return (
    <>
      <HorizontalDivider />
      <Grid2 layout={{ lg: 2 }}>
        <Grid2.Col lg={1}>
          <ReadOnlyField name="statusEnum" label="Status" renderFormat={(statusEnum) => convertStatus(statusEnum)} />
        </Grid2.Col>

        {values.statusEnum === CaseStatusEnum.CLSD && values.closedDate && (
          <Grid2.Col lg={1}>
            <ReadOnlyField name="closedDate" label="Closed date" renderFormat={(closedDate) => formatDate(closedDate)} />
          </Grid2.Col>
        )}

        {values.startUser && caseType?.group === CaseTypeGroupEnum.COMPLAINT && (
          <Grid2.Col lg={1}>
            <ReadOnlyField name="startUser" label="Start user" />
          </Grid2.Col>
        )}

        <Grid2.Col colStart={1} lg={1}>
          <ReadOnlyField name="createdBy" label="Created by" />
        </Grid2.Col>
        <Grid2.Col lg={1}>
          <ReadOnlyField name="createdDate" label="Created date" />
        </Grid2.Col>

        <Grid2.Col colStart={1} lg={1}>
          <ReadOnlyField name="lastUpdatedBy" label="Last updated by" />
        </Grid2.Col>
        <Grid2.Col lg={1}>
          <ReadOnlyField name="lastUpdatedDate" label="Last updated date" />
        </Grid2.Col>
      </Grid2>
    </>
  );
};

export default CaseReadOnlyFields;
