import * as yup from 'yup';
import { requiredNumber, validFrequency } from '../../common/validation/yupUtils';

export const searchManagementRightsValidationSchema = yup.object().shape({
  refFrequency: validFrequency.when(['refFrequencyFrom', 'refFrequencyTo'], {
    is: (refFrequencyFrom, refFrequencyTo) => refFrequencyFrom || refFrequencyTo,
    then: validFrequency.test(
      'Frequency',
      "Only one of 'Frequency' or 'Frequency range' parameter may be supplied, not both",
      (refFrequencyFrom) => !refFrequencyFrom && refFrequencyFrom !== 0,
    ),
  }),
  refFrequencyFrom: validFrequency
    .test('frequency-range', "The 'to' frequency must be greater than the 'from' frequency", function (this: yup.TestContext, refFrequencyFrom) {
      const refFrequencyTo = Number.parseFloat(this.parent.refFrequencyTo);
      if (refFrequencyFrom !== null && refFrequencyFrom !== undefined && !Number.isNaN(refFrequencyTo)) {
        return refFrequencyFrom < refFrequencyTo;
      }
      return true;
    })
    .test('required', 'Required', function (this: yup.TestContext, refFrequencyFrom) {
      const refFrequencyTo = this.parent.refFrequencyTo;
      return !!refFrequencyFrom || (!refFrequencyTo && refFrequencyFrom !== 0);
    }),
  refFrequencyTo: validFrequency.when('refFrequencyFrom', {
    is: (refFrequencyFrom) => refFrequencyFrom,
    then: requiredNumber,
  }),
});
