import { useFormikContext } from 'formik';
import * as React from 'react';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import ClientLink from 'common/controls/items/ClientLink';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';

import AtonClientSection from '../components/AtonClientSection';

interface MaintainAtonFormProps {}

const MaintainAtonForm: React.FC<MaintainAtonFormProps> = (props) => {
  const formikContext = useFormikContext<any>();
  const { mode } = useForm();
  const { hasPermission, hasAnyPermission } = useAuth();

  return mode === 'EDIT' && hasAnyPermission(['UPDATE_CLIENT_INT_DETAILS', 'VIEW_CLIENT_INT_DETAILS']) ? (
    <AtonClientSection isRequired={true} />
  ) : (
    <Grid>
      <SectionHeading title="Client details" />
      <Grid.Row>
        <Grid.Col lg={6}>
          <ClientLink clientId={formikContext.values?.clientId} />
        </Grid.Col>
        <Grid.Col lg={6}>
          <ReadonlyData label={'Client name'} value={formikContext.values?.clientName} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default MaintainAtonForm;
