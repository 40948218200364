import { useDispatch } from 'react-redux';

import { setUserPreferences } from 'common/actions/loginActions';
import { useAuth } from 'common/auth/AuthHook';
import { useShortcuts } from 'common/layout/NavigationShortcuts';

import { useUserApi } from '../../../client_management/user/UserApiHook';

export const useMaintainShortcut = () => {
  const dispatch = useDispatch();
  const { userPreference, myUserId } = useAuth();

  const menuItems = useShortcuts();
  const { updateUserOtherPreference } = useUserApi();

  const createShortcut = (currentShortcutList: number[], newItemKey: number): string => {
    if (currentShortcutList.indexOf(newItemKey) === -1) {
      currentShortcutList.push(newItemKey);
      return currentShortcutList.join(',');
    } else {
      return newItemKey ? newItemKey.toString() : '';
    }
  };

  const removeShortcut = (currentShortcutList: number[], removeItemKey: number): string => {
    let index: number = currentShortcutList.findIndex((a) => a === removeItemKey);
    if (index !== -1) {
      currentShortcutList.splice(index, 1);
      return currentShortcutList.join(',');
    }
    return ' ';
  };

  const orderShortcut = (formValues: any): string => {
    let shorcutList = formValues
      .map((item: any) => {
        return item.key;
      })
      .join(',');
    return shorcutList;
  };

  const maintainShortcut = (item: string | undefined, action: string, formValues?: any) => {
    var key;
    var shortcuts: number[] = [];
    if (item) {
      key = menuItems.find((i) => i.text === item)?.key;
    }

    if (userPreference?.otherPreference?.shortcuts && key) {
      shortcuts = userPreference?.otherPreference?.shortcuts.split(',').map(Number);
    }

    var finalShortcuts: string = '';
    if (action) {
      switch (action) {
        case 'CREATE':
          if (key) {
            var defaultList = [6, 3, 1, 20];
            shortcuts = shortcuts.length > 0 ? shortcuts : defaultList;
            finalShortcuts = createShortcut(shortcuts, key);
          }
          break;
        case 'REMOVE':
          if (key) {
            shortcuts = formValues.map((item: any) => {
              return item.key;
            });
            finalShortcuts = removeShortcut(shortcuts, key);
          }
          break;
        case 'REORDER':
          finalShortcuts = orderShortcut(formValues);
          break;
        default:
          break;
      }
    }

    return updateUserOtherPreference(
      { id: userPreference?.otherPreference?.id, shortcuts: finalShortcuts, userId: myUserId },
      {
        showSuccessMessage: false,
        showLoadingSpinner: false,
      },
    ).then((response) => {
      dispatch(
        setUserPreferences({
          ...userPreference,
          otherPreference: {
            ...userPreference?.otherPreference,
            shortcuts: finalShortcuts,
          },
        }),
      );
    });
  };
  return { maintainShortcut };
};
