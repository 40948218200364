import { MessageBarType } from '@fluentui/react';
import AtonRouter from 'aton_management/aton/AtonRouter';
import * as React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import CorrespondenceRouter from 'template_management/CorrespondenceRouter';

import CertificateRouter from 'certificate_management/certificate/CertificateRouter';
import ApprovalRouter from 'client_management/approval/ApprovalRouter';
import ClientRouter from 'client_management/client/ClientRouter';
import UserRouter from 'client_management/user/UserRouter';
import ProtectedRoute from 'common/auth/ProtectedRoute';
import { ROUTE_PATH_PREFIX } from 'common/constants';
import usePage from 'common/layout/PageHook';
import TaskRouter from 'dashboard/tasklist/TaskRouter';
import WelcomeTextRouter from 'dashboard/welcome_text/WelcomeTextRouter';
import DevelopmentRouter from 'development/DevelopmentRouter';
import InvestigationRouter from 'investigation/InvestigationRouter';
import LicenceRouter from 'licence_management/LicenceRouter';
import LicenceToSupplyRouter from 'licence_to_supply_management/LicenceToSupplyRouter';
import PublicRouter from 'public/PublicRouter';
import ScheduledJobRouter from 'scheduled_job_management/ScheduledJobRouter';
import SearchRouter from 'search/SearchRouter';

import OutageNotificationRouter from './outage_notification/OutageNotificationRouter';

const ActivateUserAccountPage = React.lazy(() => import('client_management/user/activate/ActivateUserAccountPage'));
const OnboardingPage = React.lazy(() => import('client_management/user/onboarding/OnboardingPage'));
const OnboardingWizardPage = React.lazy(() => import('client_management/user/onboarding/OnboardingWizardPage'));
const NotFoundPage = React.lazy(() => import('common/NotFoundPage'));
const ServerErrorPage = React.lazy(() => import('common/ServerErrorPage'));
const ErrorPage = React.lazy(() => import('common/auth/ErrorPage'));
const ForbiddenPage = React.lazy(() => import('common/auth/ForbiddenPage'));
const LoggedOffPage = React.lazy(() => import('common/auth/LoggedOffPage'));
const NotLoggedInPage = React.lazy(() => import('common/auth/NotLoggedInPage'));
const Dashboard = React.lazy(() => import('dashboard/Dashboard'));
const TestDocumentPage = React.lazy(() => import('document_management/TestDocumentPage'));
const ReviewEmailPage = React.lazy(() => import('email/ReviewEmailPage'));
const StyleGuide = React.lazy(() => import('style-guide/StyleGuide'));

export const useAppRouter = () => {
  const history = useHistory();

  return {
    navigateToDashboard: () => history.push('/ui/app/dashboard'),
    navigateToDashboardNotifications: (searchCriteria: any) => history.push(`/ui/app/dashboard`, { notificationsSearchCriteria: searchCriteria }),
  };
};

const Routes: React.FC = () => {
  const { pageInstruction, clearPageInstruction } = usePage();
  const location = useLocation();

  React.useEffect(() => {
    //clear error page instruction when location changes, i.e, user navigates to different page
    pageInstruction && pageInstruction.type === MessageBarType.error && clearPageInstruction();
    // eslint-disable-next-line
  }, [location]);

  return (
    <Switch>
      <ProtectedRoute path={'/ui/app/tasks'} component={TaskRouter} />
      <ProtectedRoute path={'/ui/app/dashboard'} component={Dashboard} />
      <ProtectedRoute path={'/ui/app/client'} component={ClientRouter} />
      <ProtectedRoute path={'/ui/app/licence'} component={LicenceRouter} />
      <ProtectedRoute path={'/ui/app/licence-to-supply'} component={LicenceToSupplyRouter} />
      <ProtectedRoute path={'/ui/app/user'} component={UserRouter} />
      <ProtectedRoute path={'/ui/app/approval'} component={ApprovalRouter} />
      <ProtectedRoute path={'/ui/app/search'} component={SearchRouter} />
      <ProtectedRoute path={'/ui/email/:emailName/review'} component={ReviewEmailPage} />
      <ProtectedRoute path={'/ui/app/aton'} component={AtonRouter} />
      <ProtectedRoute path={'/ui/app/certificate'} component={CertificateRouter} />
      <ProtectedRoute path={'/ui/app/scheduled-job'} component={ScheduledJobRouter} />
      <ProtectedRoute path={'/ui/app/outage-notification'} component={OutageNotificationRouter} />
      <ProtectedRoute path={`${ROUTE_PATH_PREFIX}/investigation`} component={InvestigationRouter} />
      <ProtectedRoute path={'/ui/app/correspondence'} component={CorrespondenceRouter} />
      <ProtectedRoute path={`/ui/app/development`} component={DevelopmentRouter} />
      <ProtectedRoute path={`${ROUTE_PATH_PREFIX}/welcome-text`} component={WelcomeTextRouter} />
      <Route path={`/ui/app/onboardingwizard`} component={OnboardingWizardPage} />
      <Route path={`/ui/app/onboarding`} component={OnboardingPage} exact />
      <Route path={'/ui/activate/:activationCode'} component={ActivateUserAccountPage} />
      <Route path={'/ui/style-guide'} component={StyleGuide} />
      <Route path={'/ui/not-logged-in/:returnPath'} component={NotLoggedInPage} />
      <Route path={'/ui/logged-off'} component={LoggedOffPage} />
      <Route path={'/ui/error'} component={ErrorPage} />
      <Route path={'/ui/forbidden'} component={ForbiddenPage} />
      <Route path={'/ui/not-found'} component={NotFoundPage} />
      <Route path={'/ui/server-error'} component={ServerErrorPage} />
      <Route path={'/ui'} component={PublicRouter} />
      <Route path={`${ROUTE_PATH_PREFIX}/*`} component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
