import { mergeStyleSets } from '@fluentui/merge-styles';

import { RRFTheme } from 'common/theme/RRFTheme';

export interface IComponentClassNames {
  root: string;
  main: string;
  footer: string;
}

export const getClassNames = (isMobile: boolean, theme: RRFTheme): IComponentClassNames => {
  const pagePadding = isMobile ? { padding: '0' } : {};

  return mergeStyleSets({
    root: {
      // Desktop layout
      display: 'grid',
      gridTemplateColumns: `auto minmax(800px, 90%) auto`,
      gridTemplateRows: '70px min-content min-content 1fr min-content',
      gridTemplateAreas: `
            ". header ." 
            "nav nav nav" 
            ". instructions ."
            ". main ."  
            "footer footer footer"  
        `,
      gridRowGap: isMobile ? '0px' : '10px',
      gridColumnGap: '20px',
      margin: 'auto',
      height: '98vh',
      // Mobile layout
      '@media(max-width: 639px)': {
        gridTemplateColumns: 'minmax(150px,100%)',
        gridTemplateRows: 'auto auto min-content 1.5fr auto',
        gridTemplateAreas: `
                "header"
                "title" 
                "instructions"
                "main"  
                "footer"
                `,
      },
    },
    main: { gridArea: 'main', ...pagePadding },
    footer: { gridArea: 'footer' },
  });
};
