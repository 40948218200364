import { ConstrainMode, DefaultButton } from '@fluentui/react';
import React from 'react';

import { ManagementRightsSearchCriteria, ManagementRightsSearchResult, ManagementRightsSearchResults, ManagementRightsStatusEnum } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import { formatDateFromISO } from 'common/utils/dateUtils';
import { useManagementRightNavigator } from 'licence_management/management_right/ManagementRightRouter';
import { usePublicNavigation } from 'public/PublicRouter';
import ExportSearchCommonResults from 'search/common/ExportSearchCommonResults';

import Pagination, { withSorting } from '../../common/controls/items/Pagination';
import DetailsList from '../../common/controls/lists/DetailsList';
import { SectionHeading } from '../../common/layout/SectionHeadings';
import { AppliedFilters } from '../utils';

interface Props {
  searchCriteria?: ManagementRightsSearchCriteria;
  searchResults?: ManagementRightsSearchResults;
  onPaging: (page: number, pageSize: number) => void;
  onOrderBy: (orderBy: string) => void;
  onSelect?: (item: ManagementRightsSearchResult) => void;
}

const SearchManagementRightsResults: React.FC<Props> = (props) => {
  const { loggedIn } = useAuth();
  const { navigateToViewManagementRight } = useManagementRightNavigator();
  const navigateToViewManagementRightPublic = usePublicNavigation().navigateToViewManagementRight;

  const columns = [
    {
      key: 'id',
      fieldName: 'id',
      name: 'MR No.',
      minWidth: 15,
      maxWidth: 70,
      isResizable: true,
      isNumber: true,
    },
    {
      key: 'commencementDate',
      fieldName: 'commencementDate',
      name: 'Commencement',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
      onRender: (item: ManagementRightsSearchResult) => formatDateFromISO(item.commencementDate),
    },
    {
      key: 'expiryDate',
      fieldName: 'expiryDate',
      name: 'Expiry',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
      onRender: (item: ManagementRightsSearchResult) => formatDateFromISO(item.expiryDate),
    },
    {
      key: 'managementRightsStatus',
      fieldName: 'managementRightsStatus',
      name: 'Status',
      minWidth: 30,
      maxWidth: 100,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: 'manager',
      fieldName: 'manager',
      name: 'Manager',
      minWidth: 75,
      maxWidth: 280,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: 'lowerBound',
      fieldName: 'lowerBound',
      name: 'Low Freq (MHz)',
      minWidth: 60,
      maxWidth: 140,
      isResizable: true,
      isNumber: true,
      onRender: (item: ManagementRightsSearchResult) => item.lowerBound?.toFixed(6),
    },
    {
      key: 'upperBound',
      fieldName: 'upperBound',
      name: 'High Freq (MHz)',
      minWidth: 50,
      maxWidth: 140,
      isResizable: true,
      isNumber: true,
      onRender: (item: ManagementRightsSearchResult) => item.upperBound?.toFixed(6),
    },
    {
      key: 'view',
      name: '',
      minWidth: props.onSelect ? 80 : 75,
      maxWidth: props.onSelect ? 80 : 75,
      onRender: (item: ManagementRightsSearchResult) =>
        props.onSelect ? (
          <DefaultButton id={`select-${item.id}`} text="Select" onClick={() => props.onSelect!(item)} />
        ) : (
          <DefaultButton
            id={`view-${item.id}`}
            text="View"
            ariaLabel={`View management right ${item.id} button`}
            onClick={() => (loggedIn ? navigateToViewManagementRight(item.id) : navigateToViewManagementRightPublic(item.id))}
          />
        ),
    },
  ];

  const csvHeaders = [
    { label: 'MR No.', key: 'id' },
    { label: 'Commencement', key: 'commencementDate' },
    { label: 'Expiry', key: 'expiryDate' },
    { label: 'Status', key: 'managementRightsStatus' },
    { label: 'Manager', key: 'manager' },
    { label: 'Low Freq (MHz)', key: 'lowerBound' },
    { label: 'High Freq (MHz)', key: 'upperBound' },
  ];

  return (
    <>
      <SectionHeading title="Search results" />
      {props.searchResults?.results && props.searchResults.results.length > 0 ? (
        <>
          <Grid.Row>
            <ExportSearchCommonResults
              headers={csvHeaders}
              data={props.searchResults?.results ?? []}
              currentPage={props.searchCriteria?.page}
              fileNamePrefix="Search Management Right"
            />
          </Grid.Row>
          <Pagination
            filtersSummary={filtersSummary(props.searchCriteria)}
            renderResults={() => (
              <DetailsList
                columns={withSorting(columns, props.searchCriteria, props.onOrderBy)}
                items={props.searchResults?.results ?? []}
                constrainMode={ConstrainMode.horizontalConstrained}
              />
            )}
            onPaging={props.onPaging}
            showIncreasedPageSizes
            {...props.searchResults}
          />
        </>
      ) : (
        <span>Your search returned no results.</span>
      )}
    </>
  );
};

const filtersSummary = (filter: ManagementRightsSearchCriteria = {}) =>
  new AppliedFilters()
    .push(
      filter.managementRightsStatuses
        ? '' + filter.managementRightsStatuses.join(', ')
        : [...Object.values(ManagementRightsStatusEnum).map((key) => key)].join(', '),
      'Status',
    )
    .get();

export default SearchManagementRightsResults;
