import {
  AmateurClubCallsignRecordDto,
  CertificateDto,
  ClientIdDto,
  ClientVessel,
  EventCriteria,
  EventResults,
  ReleaseCallsignDto,
  ReleaseDto,
  RevokeDto,
  ShipCallsignRecordDto,
  ValidationAmateurClubCallsignRequest,
  ValidationCallsignRequest,
  ValidationMMSI,
  ValidationSCN,
  ValidationShipCallsignCallsignRequest,
} from 'api-client';

import { ApiConfig, useApi } from '../../common/api/ApiHook';
import { certificateApi } from '../../common/api/api';

export const useCertificateApi = () => {
  const { aroundApi } = useApi();

  return {
    createCertificate: (certificateDto: CertificateDto, config?: ApiConfig) => aroundApi(certificateApi.createCertificate(certificateDto), config),
    getCertificateHolder: (id: number, config?: ApiConfig) => aroundApi(certificateApi.getCertificateHolder(id), config),
    getCertificateHolderPhoto: (id: number, config?: ApiConfig) => aroundApi(certificateApi.getCertificateHolderPhoto(id), config),
    grantCertificate: (certificateDto: CertificateDto, config?: ApiConfig) => aroundApi(certificateApi.grantCertificate(certificateDto), config),
    getCertificateTypesUsed: (clientId: number, config?: ApiConfig) => aroundApi(certificateApi.getCertificateTypesUsed(clientId), config),
    findApprovalsBy: (config?: ApiConfig) => aroundApi(certificateApi.findApprovalsBy(), config),
    validateCallsign: (request: ValidationCallsignRequest, config?: ApiConfig) => aroundApi(certificateApi.validateCallsign(request), config),
    getCertificate: (certificateId: number, config?: ApiConfig) => aroundApi(certificateApi.getCertificate(certificateId), config),
    deleteCertificate: (certificateId: number, config?: ApiConfig) => aroundApi(certificateApi.deleteCertificate(certificateId), config),
    saveCertificate: (certificateId: number, certificate: CertificateDto, config?: ApiConfig) =>
      aroundApi(certificateApi.saveCertificate(certificateId, certificate), config),
    grantCertificateAfterSave: (certificateId: number, certificate: CertificateDto, config?: ApiConfig) =>
      aroundApi(certificateApi.grantCertificateAfterSave(certificateId, certificate), config),
    createAmateurCallsign: (callsignAllocationDto: AmateurClubCallsignRecordDto, config?: ApiConfig) =>
      aroundApi(certificateApi.createAmateurCallsign(callsignAllocationDto), config),
    createShipCallsign: (shipCallsignRecordDto: ShipCallsignRecordDto, config?: ApiConfig) =>
      aroundApi(certificateApi.createShipCallsign(shipCallsignRecordDto), config),
    isATrusteeClient: (clientId: number, config?: ApiConfig) => aroundApi(certificateApi.isATrusteeClient(clientId), config),
    revokeCertificate: (certificateId: number, releaseCallsignDto: ReleaseCallsignDto, config?: ApiConfig) =>
      aroundApi(certificateApi.revokeCertificate(certificateId, releaseCallsignDto), config),
    suspendCertificate: (certificateId: number, certificateDto: CertificateDto, config?: ApiConfig) =>
      aroundApi(certificateApi.suspendCertificate(certificateId, certificateDto), config),
    reinstateCertificate: (certificateId: number, certificate: CertificateDto, config?: ApiConfig) =>
      aroundApi(certificateApi.reinstateCertificate(certificateId, certificate), config),
    releaseCertificateCallsign: (certificateId: number, releaseCallsignDto: ReleaseCallsignDto, config?: ApiConfig) =>
      aroundApi(certificateApi.releaseCertificateCallsign(certificateId, releaseCallsignDto), config),
    getAmateurClubCallsign: (callsignId: number, config?: ApiConfig) => aroundApi(certificateApi.getAmateurClubCallsign(callsignId), config),
    getShipCallsign: (callsignId: number, config?: ApiConfig) => aroundApi(certificateApi.getShipCallsign(callsignId), config),
    validateMMSI: (validationMMSI: ValidationMMSI, config?: ApiConfig) => aroundApi(certificateApi.validateMMSI(validationMMSI), config),
    validateSCN: (validationSCN: ValidationSCN, config?: ApiConfig) => aroundApi(certificateApi.validateSCN(validationSCN), config),
    getNextMaritimeCraftMMSI: (config?: ApiConfig) => aroundApi(certificateApi.getNextMaritimeCraftMMSI(), config),
    maintainShipCallsign: (shipCallsingRecordId: number, shipCallsignRecordDto: ShipCallsignRecordDto, config?: ApiConfig) =>
      aroundApi(certificateApi.maintainShipCallsign(shipCallsingRecordId, shipCallsignRecordDto), config),
    maintainAmateurCallsign: (amateurClubRecordId: number, callsignAllocationDto: AmateurClubCallsignRecordDto, config?: ApiConfig) =>
      aroundApi(certificateApi.maintainAmateurCallsign(amateurClubRecordId, callsignAllocationDto), config),
    releaseAmateurCallsign: (amateurClubRecordId: number, releaseReason: ReleaseDto, config?: ApiConfig) =>
      aroundApi(certificateApi.releaseAmateurCallsign(amateurClubRecordId, releaseReason), config),
    revokeAmateurCallsign: (amateurClubRecordId: number, revokeReason: RevokeDto, config?: ApiConfig) =>
      aroundApi(certificateApi.revokeAmateurCallsign(amateurClubRecordId, revokeReason), config),
    releaseShipCallsign: (shipRecordId: number, releaseReason: ReleaseDto, config?: ApiConfig) =>
      aroundApi(certificateApi.releaseShipCallsign(shipRecordId, releaseReason), config),
    revokeShipCallsign: (shipRecordId: number, revokeReason: RevokeDto, config?: ApiConfig) =>
      aroundApi(certificateApi.revokeShipCallsign(shipRecordId, revokeReason), config),
    transferShipCallsign: (shipRecordId: number, version: string, clientIdDto: ClientIdDto, config?: ApiConfig) =>
      aroundApi(certificateApi.transferShipCallsign(shipRecordId, version, clientIdDto), config),
    displayCertificate: (certificateId: number, config?: ApiConfig) =>
      aroundApi(certificateApi.displayCertificate(certificateId, { responseType: 'arraybuffer' }), config),
    sendCertificate: (certificateId: number, actionId: string, config?: ApiConfig) =>
      aroundApi(certificateApi.sendCertificate(certificateId, actionId), config),
    validateAmateurClubCallsign: (request: ValidationAmateurClubCallsignRequest, config?: ApiConfig) =>
      aroundApi(certificateApi.validateAmateurClubCallsign(request), config),
    validateClientVesselName: (request: ClientVessel, config?: ApiConfig) => aroundApi(certificateApi.validateClientVesselName(request), config),
    validateShipCallsign: (request: ValidationShipCallsignCallsignRequest, config?: ApiConfig) =>
      aroundApi(certificateApi.validateShipCallsign(request), config),
    getLoggedInUserEmail: (certificateId: number, userId: number, config?: ApiConfig) =>
      aroundApi(certificateApi.getLoggedInUserEmail(certificateId, userId), config),
    getCertificateEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(certificateApi.getCertificateEvents(entityId, criteria), config),
    getAmateurClubCallsignEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(certificateApi.getAmateurClubCallsignEvents(entityId, criteria), config),
    getShipCallsignEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(certificateApi.getShipCallsignEvents(entityId, criteria), config),
    convertToShipCallsign: (licenceId: number, refundRequest: boolean, config?: ApiConfig): Promise<ShipCallsignRecordDto> =>
      aroundApi(certificateApi.convertToShipCallsign(licenceId, refundRequest), config),
  };
};
