import { MessageBarType } from '@fluentui/react';
import { useAppRouter } from 'Routes';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { AuditDto, AuditFurtherActionEnum, AuditRecommendationEnum, CaseFinalResultEnum, InfringementDto } from 'api-client';

import { Dropdown, Form, Grid2 } from 'ui-library';

import MultiSelectDropdown from 'common/controls/inputs/MultiSelectDropdown';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import Dialog from 'common/controls/surfaces/Dialog';
import HorizontalDivider from 'common/layout/HorizontalDivider';
import usePage from 'common/layout/PageHook';
import { AUDIT_FURTHER_ACTION_OPTIONS } from 'common/reference/referenceData';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';
import { useAuditApi, useAuditFormState, useInfringementFeeOptions } from 'investigation/audit/hooks';
import { convertRecommendation, convertResult, isOptionalString, isRequiredString } from 'investigation/hooks';

import { useInvestigationRouter } from '../../InvestigationRouter';

type CaseDetails = {
  caseNumber: number;
  caseType: string;
  finalResult: CaseFinalResultEnum;
  finalResultNotes: string;
  infringement: InfringementDto | undefined;
  recommendation: AuditRecommendationEnum;
};

type AuditReviewDialogProps = {
  caseDetails: Partial<CaseDetails>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

type AuditReviewFormProps = {
  caseDetails: Partial<CaseDetails>;
  furtherAction: AuditFurtherActionEnum;
  furtherActionNotes: string;
  selectedInfringementFees: number[];
};

const AuditReviewDialog = ({ caseDetails, isOpen, setIsOpen }: AuditReviewDialogProps) => {
  const { reviewAuditApi, createInfringementInvoice } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { navigateToAuditView } = useInvestigationRouter();
  const { setPageInstruction } = usePage();
  const { formState } = useAuditFormState();

  const getSuccessMessage = (furtherAction: string) => {
    switch (furtherAction) {
      case AuditFurtherActionEnum.PROSECUTE:
        return 'Case successfully moved to prosecution.';
      case AuditFurtherActionEnum.RI_REVISION:
        return 'Case return to radio investigator.';
      case AuditFurtherActionEnum.MINOR_INFRINGEMENT_EDUCATION:
      case AuditFurtherActionEnum.MINOR_INFRINGEMENT_WARNING:
      case AuditFurtherActionEnum.SATISFACTORY:
        return 'Case sent to radio investigator to close.';
      case AuditFurtherActionEnum.INFRINGEMENT_NOTICE:
        return 'Infringement invoice generated. Please check your email.';
      case AuditFurtherActionEnum.DISCARD:
        return 'Case discarded.';
    }
  };
  return (
    <Dialog title="Manager review" isOpen={isOpen} width="950px">
      <Form<AuditReviewFormProps>
        formButtonsOptions={{
          additionalButtons: [
            {
              id: 'reviewDialogBtn',
              onClick: () => {
                setIsOpen(false);
              },
              text: 'Cancel',
            },
          ],
          hideBackButton: true,
          hideCancelButton: false,
          onRenderSubmitButtonText: (values) => {
            switch (values.furtherAction) {
              case AuditFurtherActionEnum.MINOR_INFRINGEMENT_EDUCATION:
              case AuditFurtherActionEnum.MINOR_INFRINGEMENT_WARNING:
              case AuditFurtherActionEnum.RI_REVISION:
              case AuditFurtherActionEnum.SATISFACTORY:
                return 'Send to investigator';
              case AuditFurtherActionEnum.INFRINGEMENT_NOTICE:
                return 'Create infringement';
              case AuditFurtherActionEnum.PROSECUTE:
                return 'Prosecute';
              case AuditFurtherActionEnum.DISCARD:
                return 'Discard';
            }
          },
        }}
        handleSubmit={({ caseDetails, selectedInfringementFees, ...values }, formikHelpers) => {
          if (values.furtherAction === AuditFurtherActionEnum.INFRINGEMENT_NOTICE) {
            createInfringementInvoice(
              {
                id: caseDetails.caseNumber || 0,
                infringement: {
                  ...caseDetails.infringement,
                  infringementFees: selectedInfringementFees.map((id) => ({ id })),
                },
                ...values,
              },
              { successMessage: 'Infringement invoice generated. Please check your email.' },
            )
              .then((returnedAudit) => {
                navigateToAuditView(returnedAudit.id || 0);
                setPageInstruction(getSuccessMessage(AuditFurtherActionEnum.INFRINGEMENT_NOTICE), MessageBarType.success);
              })
              .finally(() => formikHelpers.setSubmitting(false));
          } else {
            reviewAuditApi({ id: caseDetails?.caseNumber || 0, ...values })
              .then((response: AuditDto) => {
                setIsOpen(false);
                navigateToDashboard();
                setPageInstruction(getSuccessMessage(response.furtherAction!), MessageBarType.success);
              })
              .finally(() => formikHelpers.setSubmitting(false));
          }
        }}
        hideFormButtonsTop
        id="auditReviewDialog"
        isInDialog
        initialValues={{
          caseDetails,
          selectedInfringementFees: caseDetails.infringement?.infringementFees?.map((s) => s.id),
          furtherAction: formState.furtherAction,
          furtherActionNotes: formState.furtherActionNotes,
        }}
        mode="CREATE"
        showFormButtonsBottom
        validationSchema={[
          { name: 'furtherAction' },
          {
            name: 'selectedInfringementFees',
            validationType: 'custom',
            schema: Yup.array().when('furtherAction', {
              is: AuditFurtherActionEnum.INFRINGEMENT_NOTICE,
              then: isRequiredString,
              otherwise: isOptionalString,
            }),
          },
        ]}
      >
        <AuditReviewDialogContent />
      </Form>
    </Dialog>
  );
};

const AuditReviewDialogContent = () => {
  const { values, setFieldValue, initialValues } = useFormikContext<AuditReviewFormProps>();
  const infringementOptions = useInfringementFeeOptions();

  return (
    <Grid2>
      <Grid2.Col lg={4}>
        <ReadOnlyField label="Case number" name="caseDetails.caseNumber" />
      </Grid2.Col>
      <Grid2.Col lg={4}>
        <ReadOnlyField label="Case type" name="caseDetails.caseType" />
      </Grid2.Col>
      {values.caseDetails.recommendation && (
        <Grid2.Col lg={4}>
          <ReadOnlyField label="Recommendation" name="caseDetails.recommendation" renderFormat={convertRecommendation} />
        </Grid2.Col>
      )}
      <Grid2.Col colStart={1} lg={4}>
        <ReadOnlyField label="Audit result" name="caseDetails.finalResult" renderFormat={convertResult} />
      </Grid2.Col>
      <Grid2.Col lg={8}>
        <ReadOnlyField label="Notes" name="caseDetails.finalResultNotes" />
      </Grid2.Col>

      {values.caseDetails.finalResult === CaseFinalResultEnum.UNSATISFACTORY && (
        <Grid2.Col colStart={1}>
          <div style={{ marginBottom: 15 }}>
            <h3 style={{ margin: '5px 0' }}>Infringement</h3>
            <HorizontalDivider />
            <Grid2>
              <Grid2.Col>
                <MultiSelectDropdown
                  label="Infringement"
                  name="selectedInfringementFees"
                  options={infringementOptions}
                  readOnly={values.furtherAction !== AuditFurtherActionEnum.INFRINGEMENT_NOTICE}
                  required
                />
              </Grid2.Col>

              <Grid2.Col lg={9}>
                <ReadOnlyField label="Infringement location" name="caseDetails.infringement.location" />
              </Grid2.Col>
              <Grid2.Col lg={3}>
                <ReadOnlyField label="Infringement date" name="caseDetails.infringement.infringementDate" renderFormat={formatDateTimeFromISO} />
              </Grid2.Col>
            </Grid2>
          </div>
        </Grid2.Col>
      )}

      <Grid2.Col colStart={1} lg={8}>
        <Dropdown
          label="Further action"
          name="furtherAction"
          options={AUDIT_FURTHER_ACTION_OPTIONS.filter(({ key }) => {
            const isFinalResultSatisfactory = values.caseDetails.finalResult === CaseFinalResultEnum.SATISFACTORY;
            return !isFinalResultSatisfactory || (AuditFurtherActionEnum.INFRINGEMENT_NOTICE !== key && AuditFurtherActionEnum.PROSECUTE !== key);
          })}
          onChange={() => {
            setFieldValue('selectedInfringementFees', initialValues.selectedInfringementFees);
          }}
        />
      </Grid2.Col>
      <Grid2.Col colStart={1}>
        <TextField label="Notes" name="furtherActionNotes" multiline maxLength={400} showMaxHint />
      </Grid2.Col>
    </Grid2>
  );
};

export default AuditReviewDialog;
