import { mergeStyleSets } from '@fluentui/react';
import { CSSProperties } from 'react';

import { RRFTheme } from 'common/theme/RRFTheme';

export interface IComponentClassNames {
  root: string;
  icon: string;
}

export const getClassNames = (theme: RRFTheme, isMobile: boolean): IComponentClassNames =>
  mergeStyleSets({
    root: {
      position: 'fixed',
      bottom: 15,
      right: 15,
      transform: 'rotate(90deg)',
      opacity: isMobile ? '65%' : '100%',
      padding: '5px',
      fontSize: '28px',
      cursor: 'pointer',
      animation: 'fadeIn linear 1s',
      '-webkit-animation': 'fadeIn 1s',
      '-moz-animation': 'fadeIn 1s',
      '-o-animation': 'fadeIn 1s',
      '-ms-animation': 'fadeIn 1s',
      ':hover': { backgroundColor: 'transparent' },
    } as CSSProperties,
    icon: {
      color: theme.semanticColors.backToTopIconBackgroudColor,
      '&:hover': { color: theme.semanticColors.backToTopIconBackgroudColorHovered },
    },
  });
