import * as yup from 'yup';

import {
  LicenceClassificationType,
  LicenceDtoLicenceTypeCodeEnum,
  LocationConfigDto,
  LocationConfigDtoTxLocationNumberConstraintEnum,
} from 'api-client';
import { LocationConfigDtoRxLocationNumberConstraintEnum } from 'api-client/dist/api';

import { notRequiredNumber } from 'common/validation/yupUtils';
import {
  antennaHeight,
  azimuth,
  bearing,
  configurationLoss,
  elevation,
  measuredSignal,
  measuredSignalUomId,
  mpis,
  mpisUomId,
  pathLoss,
  protectionRatio,
  wantedSignal,
  wantedSignalUomId,
} from 'licence_management/craft/components/licence_locations/common/locationSchema';

import { Licence } from '../../../CraftLicencePage';

const { A, B, C } = LicenceClassificationType;

const commonSchema = {
  antenna: yup
    .object()
    .when(['$context.antennaMandatory'], {
      is: (antennaMandatory: boolean) => antennaMandatory,
      then: yup.object().required('Required'),
      otherwise: yup.object().notRequired(),
    })
    .nullable(true),
  equipment: yup
    .object()
    .when(['$context.equipmentMandatory'], {
      is: (equipmentMandatory: boolean) => equipmentMandatory,
      then: yup.object().required('Required'),
      otherwise: yup.object().notRequired(),
    })
    .nullable(true),
  azimuth: yup.number().when(['$context.antennaAzimuthMandatory'], {
    is: (antennaAzimuthMandatory: boolean) => antennaAzimuthMandatory,
    then: azimuth.required('Required').nullable(true),
    otherwise: azimuth.nullable(true),
  }),
  elevation: yup.number().when(['$context.elevationMandatory'], {
    is: (elevationMandatory: boolean) => elevationMandatory,
    then: elevation.required('Required').nullable(true),
    otherwise: notRequiredNumber.nullable(true),
  }),
  antennaHeight: yup.number().when(['$context.antennaHeightMandatory'], {
    is: (antennaHeightMandatory: boolean) => antennaHeightMandatory,
    then: antennaHeight.required('Required').nullable(true),
    otherwise: yup.number().when(['$context.antennaHeightMandatory'], {
      is: (antennaHeightMandatory: boolean) => antennaHeightMandatory === false,
      then: notRequiredNumber.nullable(true),
      otherwise: yup
        .number()
        .nullable(true)
        .test('mandatory if Point location', 'Required', function (this: yup.TestContext, value) {
          return this.parent.locationType === 'POINT' &&
            (this.parent?.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL ||
              (this.parent?.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.SPL && this.parent?.classification !== C))
            ? !!value
            : true;
        }),
    }),
  }),
  configurationLoss,
};

export const doneTransmitLocationSchema = yup.object(commonSchema);

export const doneReceiveLocationSchema = yup.object({
  ...commonSchema,
  mpis: yup.number().when(['$context.mpisMandatory'], {
    is: (mpisMandatory: boolean) => mpisMandatory,
    then: mpis.required('Required').nullable(true),
    otherwise: notRequiredNumber.nullable(true),
  }),
  protectionRatio,
  bearing,
  pathLoss,
  wantedSignalUomId,
  wantedSignal,
  measuredSignalUomId,
  measuredSignal,
  mpisUomId,
});

export const doneTransmitLocationsSchema = yup.object().shape({
  locations: yup
    .array(doneTransmitLocationSchema.nullable(true))
    .when('$context.multipleLocationRequired', {
      is: (multipleLocationRequired) => multipleLocationRequired,
      then: yup.array().required().min(2, 'At least two transmit locations are required'),
    })
    .when('$context.locationRequired', {
      is: (locationRequired) => locationRequired,
      then: yup.array().required(),
      otherwise: yup.array().max(0),
    })
    .nullable(true),
});

export const doneReceiveLocationsSchema = yup.object().shape({
  locations: yup
    .array(doneReceiveLocationSchema.nullable(true))
    .when('$context.multipleLocationRequired', {
      is: (multipleLocationRequired) => multipleLocationRequired,
      then: yup.array().required().min(2, 'At least two receive locations are required'),
    })
    .when('$context.locationRequired', {
      is: (locationRequired) => locationRequired,
      then: yup.array().required(),
      otherwise: yup.array().max(0),
    })
    .nullable(true),
});

export const doneTransmitLocationsValidationContext = (licence?: Licence, locationConfiguration?: LocationConfigDto) => ({
  antennaMandatory: locationConfiguration?.txAntennaMandatory,
  equipmentMandatory: locationConfiguration?.txEquipmentMandatory,
  antennaAzimuthMandatory: locationConfiguration?.txAzimuthMandatory,
  elevationMandatory: locationConfiguration?.txElevationMandatory,
  antennaHeightMandatory: locationConfiguration?.txHeightMandatory,
  locationRequired: (licence?.classification === A || licence?.classification === B) && locationConfiguration?.txLocationNumberConstraint !== null,
  multipleLocationRequired:
    (licence?.classification === A || licence?.classification === B) &&
    locationConfiguration?.txLocationNumberConstraint === LocationConfigDtoTxLocationNumberConstraintEnum.GT_OR_EQ_TWO &&
    (!licence.associated || licence.associated.length === 0 || licence.associated.map((lic) => lic.primaryAssociation).includes(true)),
});

export const doneReceiveLocationsValidationContext = (licence?: Licence, locationConfiguration?: LocationConfigDto) => ({
  antennaMandatory: locationConfiguration?.rxAntennaMandatory,
  equipmentMandatory: locationConfiguration?.rxEquipmentMandatory,
  antennaAzimuthMandatory: locationConfiguration?.rxAzimuthMandatory,
  elevationMandatory: locationConfiguration?.rxElevationMandatory,
  antennaHeightMandatory:
    locationConfiguration?.rxHeightMandatory &&
    (licence?.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL ||
      (licence?.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.SPL && licence?.classification !== C)),
  mpisMandatory: locationConfiguration?.rxMpisMandatory,
  locationRequired: (licence?.classification === A || licence?.classification === C) && locationConfiguration?.rxLocationNumberConstraint !== null,
  multipleLocationRequired:
    (licence?.classification === A || licence?.classification === C) &&
    locationConfiguration?.rxLocationNumberConstraint === LocationConfigDtoRxLocationNumberConstraintEnum.GT_OR_EQ_TWO &&
    (!licence.associated || licence.associated.length === 0 || licence.associated.map((lic) => lic.primaryAssociation).includes(true)),
});
