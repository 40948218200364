import { GeoreferenceDto } from 'api-client';

import { useReferenceDataHookApi } from 'licence_management/reference_data/ReferenceDataApiHook';

export const useGeoRefTypeConverter = () => {
  const { convertGeoReferenceType } = useReferenceDataHookApi();

  const convertGeoRefType = (values: GeoreferenceDto) => {
    return convertGeoReferenceType(values, {
      callerHandleErrors: true,
      showLoadingSpinner: false,
      successMessage: 'Converted sucessfully.',
    });
  };

  return { convertGeoRefType };
};
