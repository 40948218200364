import { useFormikContext } from 'formik';
import React from 'react';

import { Dropdown, Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { useRadioInvestigatorOptions } from 'investigation/hooks';
import { ComplaintFormProps } from 'investigation/interfaces';

const CaseAllocatedTo = () => {
  const { values, setFieldValue } = useFormikContext<ComplaintFormProps>();
  const { radioInvestigatorOptions } = useRadioInvestigatorOptions(values.allocatedTo);

  const { currentRole } = useAuth();
  const isMsgUser = currentRole === 'ROLE_MSG_SERVICE_CENTRE';

  return (
    <>
      <SectionHeading title="Case initiator" />
      <Grid.Row>
        <Grid.Col lg={3}>
          <Dropdown
            label="Group"
            name="allocatedTo"
            options={values.allocatedToGroups}
            required
            onChange={() => {
              setFieldValue('allocatedToUser', null, false);
            }}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={3}>
          <Dropdown
            disabled={!values.allocatedTo}
            label="Radio investigator"
            name="allocatedToUser"
            options={radioInvestigatorOptions}
            required={!!values.caseNumber && !isMsgUser}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default CaseAllocatedTo;
