import { DefaultButton } from '@fluentui/react';
import React from 'react';

import { GeoreferenceTypeEnum, LocationSearchCriteria, LocationSearchResult, LocationSearchResults } from 'api-client';

import { Grid } from 'ui-library';

import ExportSearchCommonResults from 'search/common/ExportSearchCommonResults';

import { useAuth } from '../../common/auth/AuthHook';
import InlineButton from '../../common/controls/buttons/InlineButton';
import Pagination, { highlightOrValue } from '../../common/controls/items/Pagination';
import DetailsList, { SortProps } from '../../common/controls/lists/DetailsList';
import { SectionHeading } from '../../common/layout/SectionHeadings';
import { getPreferenceGeoReferenceType } from '../../common/user/components/UserPreference';
import { formatEastingNorthing } from '../../common/utils/locationUtils';
import { parseBoolean } from '../../common/utils/objectUtils';
import { useLicenceReferenceDataNavigator } from '../../licence_management/reference_data/ReferenceDataRouter';
import { georeferenceTypes, locationTypes } from '../../licence_management/reference_data/location/locationSupportData';
import { AppliedFilters } from '../utils';

interface Props {
  onPaging: (page: number, pageSize: number) => void;
  onSorting: (sort: SortProps) => void;
  sortProps?: SortProps;
  searchResults?: LocationSearchResults;
  searchCriteria?: LocationSearchCriteria;
  onSelect?: (item: LocationSearchResult) => void;
}

const SearchLocationResults = (props: Props) => {
  const { navigateToMaintainLocation } = useLicenceReferenceDataNavigator();

  const { isInternalUser } = useAuth();
  const { userPreference } = useAuth();
  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  const renderEasting = (item: LocationSearchResult) => {
    return formatEastingNorthing(
      '' + (item.easting ?? ''),
      GeoreferenceTypeEnum[(item.replacingGeorefType || item.georefType) as keyof typeof GeoreferenceTypeEnum],
    );
  };
  const renderNorthing = (item: LocationSearchResult) => {
    return formatEastingNorthing(
      '' + (item.northing ?? ''),
      GeoreferenceTypeEnum[(item.replacingGeorefType || item.georefType) as keyof typeof GeoreferenceTypeEnum],
    );
  };

  const renderMapNumber = (item: LocationSearchResult) => item.mapNumber;

  let isLatLongType = false;
  const isLatLongTypeForSearch =
    !!props.searchCriteria?.georeferenceType &&
    (props.searchCriteria?.georeferenceType === GeoreferenceTypeEnum.D2000 || props.searchCriteria?.georeferenceType === GeoreferenceTypeEnum.D);
  const isLatLongTypeForUserPref = geoRefTypeUserPreference === GeoreferenceTypeEnum.D2000 || geoRefTypeUserPreference === GeoreferenceTypeEnum.D;
  if (!!props.searchCriteria?.georeferenceType) {
    isLatLongType = isLatLongTypeForSearch;
  } else {
    isLatLongType = isLatLongTypeForUserPref;
  }

  const getColumns = () => {
    if (isLatLongType) {
      const temp = columns[6];
      const clonedColumns = [...columns];
      clonedColumns.splice(6, 1, { ...columns[7], name: 'Lat' });
      clonedColumns.splice(7, 1, { ...temp, name: 'Long' });
      return clonedColumns;
    }
    return columns;
  };

  const columns = [
    {
      key: 'id',
      fieldName: 'id',
      name: 'ID',
      minWidth: 40,
      maxWidth: 70,
      isResizable: true,
      isNumber: true,
      isMultiline: true,
      onRender: (item: LocationSearchResult) => highlightOrValue(item.idHighlight, item.id),
    },
    {
      key: 'hidden',
      fieldName: 'hidden',
      name: 'Hidden',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      onRender: (item: LocationSearchResult) => (item.hidden ? 'Yes' : null),
    },
    {
      key: 'name',
      fieldName: 'name',
      name: 'Location name',
      minWidth: 100,
      maxWidth: 150,
      isMultiline: true,
      isResizable: true,
      onRender: (item: LocationSearchResult) => highlightOrValue(item.nameHighlight, item.name),
    },
    {
      key: 'description',
      fieldName: 'description',
      name: 'Location description',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (item: LocationSearchResult) => highlightOrValue(item.descriptionHightlight, item.description),
    },
    {
      key: 'altitude',
      fieldName: 'altitude',
      name: 'Altitude',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isMultiline: true,
      onRender: (item: LocationSearchResult) => item.altitude,
    },
    {
      key: 'mapNumber',
      fieldName: 'mapNumber',
      name: 'Map',
      minWidth: 30,
      maxWidth: 70,
      isResizable: true,
      isMultiline: true,
      onRender: renderMapNumber,
    },
    {
      key: 'easting',
      fieldName: 'easting',
      name: 'Easting',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isMultiline: true,
      onRender: renderEasting,
    },
    {
      key: 'northing',
      fieldName: 'northing',
      name: 'Northing',
      minWidth: 60,
      maxWidth: 90,
      isResizable: true,
      isMultiline: true,
      onRender: renderNorthing,
    },
    {
      key: 'district',
      fieldName: 'district',
      name: 'District',
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
      isMultiline: true,
      onRender: (item: LocationSearchResult) => item.district,
    },
    {
      key: 'view',
      name: '',
      minWidth: 75,
      maxWidth: 75,
      onRender: (item: LocationSearchResult) =>
        props.onSelect ? (
          <InlineButton
            data-automation-id={`select-${item.id}`}
            id={`select-${item.id}`}
            ariaLabel={`Select location ${item.id} ${item.name}`}
            onClick={() => props.onSelect && props.onSelect(item)}
            text="Select"
          />
        ) : (
          <DefaultButton
            data-automation-id={`view-${item.id}`}
            id={`view-${item.id}`}
            text="View"
            ariaLabel={`View location ${item.id} ${item.name} button`}
            onClick={() => navigateToMaintainLocation(item.id)}
          />
        ),
    },
  ];

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Hidden', key: 'hidden' },
    { label: 'Location name', key: 'name' },
    { label: 'Location description', key: 'description' },
    { label: 'Altitude', key: 'altitude' },
    { label: 'Map', key: 'mapNumber' },
    { label: isLatLongType ? 'Lat' : 'Easting', key: isLatLongType ? 'northing' : 'easting' },
    { label: isLatLongType ? 'Long' : 'Northing', key: isLatLongType ? 'easting' : 'northing' },
    { label: 'District', key: 'district' },
  ];

  return (
    <>
      <SectionHeading title="Search results" />
      {props.searchResults?.results && props.searchResults.results.length > 0 ? (
        <>
          <Grid.Row>
            <ExportSearchCommonResults
              headers={csvHeaders}
              data={props.searchResults?.results ?? []}
              currentPage={props.searchCriteria?.page}
              fileNamePrefix="Search Location"
            />
          </Grid.Row>
          <Pagination
            enableTopPagingContols={true}
            filtersSummary={filtersSummary(props.searchCriteria)}
            renderResults={() => (
              <DetailsList
                data-automation-id="searc-location-results"
                id="searc-location-results"
                columns={getColumns()}
                items={props.searchResults?.results ?? []}
                onSort={props.onSorting}
                sortProps={props.sortProps}
              />
            )}
            onPaging={props.onPaging}
            showIncreasedPageSizes
            {...props.searchResults!}
          />
        </>
      ) : (
        <span>Your search returned no results.</span>
      )}
    </>
  );
};

const filtersSummary = (filter: LocationSearchCriteria = {}) =>
  new AppliedFilters()
    .push(locationTypes.find((t) => t.key === filter.locationType)?.text, 'Location type')
    .push(georeferenceTypes.find((t) => t.key === filter.georeference?.type)?.text, 'Georeference type')
    .push(filter.georeference?.mapNumber, 'Map')
    .push(filter.georeference?.easting, 'Easting')
    .push(filter.georeference?.northing, 'Northing')
    .push(parseBoolean(filter.hidden) ? 'Hidden' : parseBoolean(filter.hidden) === false ? 'Visible' : '', 'Location visibility')
    .get();

export default SearchLocationResults;
