import { useEffect, useState } from 'react';

import { WelcomeTextDto } from 'api-client';

import { Form } from 'ui-library';

import Page from 'common/layout/Page';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { useDashboardApi } from 'dashboard/DashboardApiHooks';

import WelcomeTextMaintenanceForm from './WelcomeTextMaintenanceForm';

const WelcomeTextMaintenancePage: React.FC = () => {
  const { getWelcomeText, updateWelcomeText } = useDashboardApi();
  const [welcomeText, setWelcomeText] = useState<WelcomeTextDto>();

  const retrieveWelcomeText = () => getWelcomeText({ showLoadingSpinner: true }).then(setWelcomeText);

  useEffect(() => {
    retrieveWelcomeText();
  }, []);

  const onSubmit = (values: WelcomeTextDto) => {
    updateWelcomeText(values).then(retrieveWelcomeText);
  };

  return (
    <Page title="Welcome Text Maintenance">
      {welcomeText && (
        <Form id="maintain-template-form" initialValues={welcomeText} handleSubmit={onSubmit} mode="VIEW" canEdit>
          <SectionHeading title="Welcome Text Maintenance" />
          <WelcomeTextMaintenanceForm />
        </Form>
      )}
    </Page>
  );
};

export default WelcomeTextMaintenancePage;
