import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FileNoteDtoEntityTypeEnum, SpectrumDto } from 'api-client';

import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { COMMON_FILE_NOTE_TYPE } from 'common/reference/referenceData';

import { useAuth } from '../../../common/auth/AuthHook';
import Form from '../../../common/form/Form';
import Page from '../../../common/layout/Page';
import EventLogSection from '../../../ui-library/shared/EventLogSection';
import { useReferenceDataHookApi } from '../ReferenceDataApiHook';
import SpectrumRecordFields from './SpectrumRecordFields';
import { spectrumRecordSchema } from './validationSchema';

const UpdateSpectrumRecordPage = () => {
  const { getSpectrumRecord, updateSpectrumRecord } = useReferenceDataHookApi();
  const { spectrumId } = useParams<{ spectrumId: string }>();
  const [spectrum, setSpectrum] = useState<SpectrumDto>();

  useEffect(() => {
    getSpectrumRecord(+spectrumId).then(setSpectrum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spectrumId]);

  const submitForm = (values: any) =>
    updateSpectrumRecord(values, {
      successMessage: 'Spectrum record has been updated.',
    }).then((spectrum: SpectrumDto) => {
      setSpectrum(spectrum);
    });

  const initialValues = { ...spectrum, generalPurpose: spectrum?.generalPurpose + '' };

  const { hasPermission, hasRole } = useAuth();

  const canEdit = hasPermission('UPDATE_SPEC_RECORD') && !hasRole('ROLE_APPROVED_RADIO_ENGINEER') && !hasRole('ROLE_APPROVED_RADIO_CERTIFIER');

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  return (
    <Page title="Update Spectrum">
      {spectrum ? (
        <Formik initialValues={initialValues} onSubmit={submitForm} enableReinitialize validationSchema={spectrumRecordSchema}>
          <Form id={'update-spectrum-form'} showFormButtonsBottom canEdit={canEdit} mode="VIEW" disabledSubmitWhenNoDirty>
            <SpectrumRecordFields />
            {canViewFileNote && (
              <FileNoteSection entityId={+spectrumId} entityType={FileNoteDtoEntityTypeEnum.SPECTRUM} noteTypes={COMMON_FILE_NOTE_TYPE} />
            )}
            <EventLogSection entityName={'SPECTRUM'} entityId={+spectrumId} entityToReloadOn={spectrum} />
          </Form>
        </Formik>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default UpdateSpectrumRecordPage;
