import React, { CSSProperties } from 'react';

interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  sm?: number;
  md?: number;
  lg?: number;
  style?: CSSProperties;
  children?: React.ReactNode;
  classNames?: string;
}

const Col: React.FC<ColumnProps> = ({ children, sm = 12, md = 12, lg = 12, style, classNames, ...rest }) => {
  return (
    <div className={`ms-Grid-col ms-sm${sm} ms-md${md} ms-lg${lg} ${classNames ? classNames : ''}`} style={style} {...rest}>
      {children}
    </div>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: CSSProperties;
  children?: React.ReactNode;
}

const Row: React.FC<RowProps> = (props) => {
  const { children, style, ...rest } = props;
  return (
    <div className="ms-Grid-row" style={style} {...rest}>
      {children}
    </div>
  );
};

const Grid = ({ children }: any) => (
  <div className="ms-Grid" style={{ padding: 0 }}>
    {children}
  </div>
);

Grid.Col = Col;
Grid.Row = Row;

export default Grid;
