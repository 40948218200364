import DOMPurify from 'dompurify';
import { useFormikContext } from 'formik';

import { WelcomeTextDto } from 'api-client';

import { Grid2 as Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { replaceVariables } from 'common/controls/inputs/editor/EditorUtils';
import HTMLEditor from 'common/controls/inputs/editor/HtmlEditor';
import { SubSectionHeading } from 'common/layout/SectionHeadings';
import { useTheme } from 'common/theme/RRFTheme';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';

const WelcomeTextMaintenanceForm: React.FC = () => {
  const { semanticColors } = useTheme();
  const { firstName } = useAuth();
  const { values } = useFormikContext<WelcomeTextDto>();

  const widgetStyle: React.CSSProperties = {
    background: semanticColors.dashboardWidgetBackground,
    padding: '10px',
    maxWidth: '100%',
    overflowX: 'hidden',
    boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
  };
  return (
    <Grid>
      <Grid.Col lg={12}>
        <HTMLEditor label="Message" name="message" defaultVariables={[`[( \${firstName} )]`]} />
      </Grid.Col>
      <Grid.Col lg={3}>
        <ReadOnlyField label="Last updated date" name="lastUpdatedDate" renderFormat={formatDateTimeFromISO} />
      </Grid.Col>
      <Grid.Col lg={4}>
        <ReadOnlyField label="Last updated by" name="lastUpdatedName" />
      </Grid.Col>
      <Grid.Col lg={12}>
        <SubSectionHeading title="Preview" />
      </Grid.Col>
      <Grid.Col lg={8} style={widgetStyle}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(replaceVariables(values.message, [{ variableName: 'firstName', variableValue: firstName ?? 'firstName' }])),
          }}
        />
      </Grid.Col>
    </Grid>
  );
};

export default WelcomeTextMaintenanceForm;
