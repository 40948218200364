import * as yup from 'yup';

import { notRequiredString, requiredString, wholePositiveNumber } from '../../../common/validation/yupUtils';
import { callSignValidations } from '../../certificate/create/validationSchema';

export const callsignValidationSchema = yup.object().shape({
  callsignType: requiredString,
  clientPicker: requiredString,

  trusteePicker: yup.mixed().when(['callsignType'], {
    is: (type: string) => type === 'AMATEUR_CLUB_CALLSIGN',
    then: requiredString,
  }),
  ...callSignValidations,

  primaryCallsign: yup.mixed().when(['callsignType'], {
    is: (type: string) => type === 'AMATEUR_CLUB_CALLSIGN',
    then: yup.object().nullable(true).shape({
      callsign: requiredString,
    }),
    otherwise: notRequiredString,
  }),

  trusteeCertificate: yup.mixed().when(['callsignType'], {
    is: (type: string) => type === 'AMATEUR_CLUB_CALLSIGN',
    then: yup
      .mixed()
      .nullable(true)
      .test(
        'check-trustee-certificate',
        "The trustee who has been selected does not hold a NZ General Amateur Operator's Certificate or other equivalent certificate. Please select another person.",
        function (this: yup.TestContext, isTrustee) {
          return isTrustee === null || isTrustee;
        },
      ),
  }),

  note: yup.string().max(400, 'Note must be less than 400 characters.'),

  equipmentType: yup.mixed().when(['callsignType'], {
    is: (type: string) => type === 'SHIP_CALLSIGN',
    then: requiredString,
  }),

  isValidVesselName: yup.boolean(),

  vesselName: yup.mixed().when(['equipmentType', 'callsignType', 'isValidVesselName'], {
    is: (equipmentType: string, type: string) => equipmentType !== 'SHIP_HAND_VHF' && type === 'SHIP_CALLSIGN',
    then: requiredString.test('validVesselName', 'This vessel already has a callsign allocated.', function (this: yup.TestContext) {
      return this.parent.isValidVesselName && this.parent.isValidVesselName === true;
    }),
  }),

  isInternalUser: yup.boolean(),

  mmsi: yup.mixed().when(['isInternalUser', 'callsignType'], {
    is: (isInternalUser: boolean, type: string) => isInternalUser && type === 'SHIP_CALLSIGN',
    then: wholePositiveNumber.test('validMMSI', 'This MMSI is not available for allocation', function (this: yup.TestContext) {
      return this.parent.isValidMMSI && this.parent.isValidMMSI === true;
    }),
  }),

  isValidMMSI: yup.boolean(),

  isSelectiveCallNumber: yup.boolean().nullable(true),

  scn: yup.mixed().when(['isSelectiveCallNumber'], {
    is: (isSelectiveCallNumber: boolean) => isSelectiveCallNumber,
    then: wholePositiveNumber.test('validSCN', 'This SCN is not available for allocation', function (this: yup.TestContext) {
      return this.parent.isValidSCN && this.parent.isValidSCN === true;
    }),
  }),

  isValidSCN: yup.boolean(),

  callsign: yup.mixed().when(['equipmentType', 'isClassAShip'], {
    is: (equipmentType: string, isClassAShip: boolean) => equipmentType === 'SHIP_VHF' || equipmentType === 'SHIP_HAND_VHF' || isClassAShip,
    then: requiredString,
  }),
});
