import useResizeObserver from '@react-hook/resize-observer';
import { AuthenticationOptions, AuthenticationType, ControlOptions, ControlPosition, data, HtmlMarker } from 'azure-maps-control';
import React, { useContext, useEffect, useRef } from 'react';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapLayerProvider,
  AzureMapsContext,
  IAzureCustomControls,
  IAzureMapChildren,
  IAzureMapControls,
  IAzureMapEvent,
  IAzureMapImageSprite,
  IAzureMapOptions,
  IAzureMapsContextProps,
} from 'react-azure-maps';

import { MapConfig } from 'api-client/dist/api';

import { GeolocationControl, IGeolocationControl, GeolocationControlOptions, GeolocationProperties } from './GeolocationControl';
import { LinzSatelliteToggle, SAT_LAYER_ID, TLA_LAYER_ID } from './LinzSatelliteToggle';
import { filterOutLocationLabels } from './MapUtil';

// image names ----------------------------------------------------------------

export const IMG_RRF_MARKER_RCV = 'rrf-marker-rcv';
export const IMG_RRF_MARKER_TRN = 'rrf-marker-trn';
export const IMG_RRF_MARKER_LOC = 'rrf-marker-location';
export const IMG_RRF_MARKER_SELECTED = 'rrf-marker-selected';

// properties interface -------------------------------------------------------
export enum GeolocationMode {
  GLM_LICENCE_SEARCH,
  GLM_LOCATION_SEARCH,
}
export interface AzureLinzMapProps {
  height: string;
  width: string;
  mapConfig: MapConfig;
  zoom?: number;
  center?: data.Position;
  shouldZoom?: boolean | true; // if false will not use zoom/center to update map camera
  allowTLA?: boolean | false;
  allowStyle?: boolean | false;
  allowCompass?: boolean | false;
  allowPitch?: boolean | false;
  allowGeoLocation?: boolean | false;
  geoLocationStartActive?: boolean | false;
  geoLocationSkipFirstZoom?: boolean | false;
  geoLocationMode?: GeolocationMode;
  geolocInitCallback?: (iglc: IGeolocationControl, gpsSupported: boolean) => void;
  geolocPositionCallback?: (
    position: data.Feature<data.Point, GeolocationProperties>,
    marker: HtmlMarker | undefined,
    radius: number | undefined,
  ) => void;
  geolocMarkerRemovedCallback?: () => void;
  mapMouseMoveCallback?: (e: any) => void;
  mapMouseClickCallback?: (e: any) => void;
  children?: React.ReactNode;
  events?: IAzureMapEvent | any;
}

const rrfImages: IAzureMapImageSprite[] = [
  // "marker" is a standard template
  { id: IMG_RRF_MARKER_RCV, templateName: 'marker', scale: 0.8, color: 'rgba(255, 20, 50, 0.9)', secondaryColor: 'rgba(0, 0, 0, .2)' }, // red
  { id: IMG_RRF_MARKER_TRN, templateName: 'marker', scale: 0.8, color: 'rgba(20, 255, 50, 0.9)', secondaryColor: 'rgba(0, 0, 0, .2)' }, // green
  { id: IMG_RRF_MARKER_LOC, templateName: 'marker', scale: 0.8, color: 'rgba(20, 50, 255, 0.9)', secondaryColor: 'rgba(0, 0, 0, .2)' }, // blue
  { id: IMG_RRF_MARKER_SELECTED, templateName: 'marker', scale: 0.8, color: 'rgba(255, 255, 0, 0.9)', secondaryColor: 'rgba(0, 0, 0, .2)' }, // yellow
];

//----------------------------------------------------
/**
 * Main map Control
 * Important note: the Azure map is memoized and does NOT get recreated or changed when the
 * props / mapOptions change, so any custom controls must have external state or be memoized
 * so they do not get recreated or desync with the map.
 * See further comments related to the LinzSatelliteToggle and useState() hook that updates zoom.
 */
const AzureLinzMap: React.FC<AzureLinzMapProps> = (props) => {
  const resizeRef = useRef(null);
  let authOpt = {} as AuthenticationOptions;
  if (props.mapConfig.useSecureToken) {
    authOpt.authType = AuthenticationType.anonymous;
    authOpt.clientId = props.mapConfig.azureClientId;
    authOpt.getToken = function (resolve, reject, map) {
      fetch(props.mapConfig.mapTokenUrl)
        .then((r) => r.text())
        .then((token) => resolve(token));
    };
  } else {
    authOpt.authType = AuthenticationType.subscriptionKey;
    authOpt.subscriptionKey = props.mapConfig.azureSubscriptionKey;
  }

  // eslint-disable-next-line
  const mapOptions: IAzureMapOptions = {
    authOptions: authOpt,
    zoom: props.zoom || 4,
    view: 'Auto',
    style: 'road',
    showFeedbackLink: false,
    showLogo: false,
    center: props.center || [173, -40],
  };

  // set up allowed controls based on props -----
  const mapControls: IAzureMapControls[] = [];
  if (props.allowStyle) {
    mapControls.push({
      controlName: 'StyleControl',
      controlOptions: { mapStyles: ['road', 'road_shaded_relief', 'night', 'grayscale_dark', 'grayscale_light'] },
      options: { position: 'top-right' } as ControlOptions,
    });
  }
  mapControls.push({
    controlName: 'ZoomControl',
    options: { position: 'top-right' } as ControlOptions,
  });

  if (props.allowCompass) {
    mapControls.push({
      controlName: 'CompassControl',
      controlOptions: { rotationDegreesDelta: 10 },
      options: { position: 'top-right' } as ControlOptions,
    });
  }
  if (props.allowPitch) {
    mapControls.push({
      controlName: 'PitchControl',
      controlOptions: { pitchDegreesDelta: 5 },
      options: { position: 'top-right' } as ControlOptions,
    });
  }

  // custom controls -----
  // the linz satellite map toggle, must be memo-ized!
  const satelliteToggle = React.useMemo(() => {
    return new LinzSatelliteToggle({ allowTLA: props.allowTLA });
  }, [props.allowTLA]);

  const mapCustomControls: IAzureCustomControls[] = [
    {
      control: satelliteToggle,
      controlOptions: { position: ControlPosition.TopLeft },
    },
    //   {
    //     // if we want a fullscreen control include source from
    //     // https://github.com/Azure-Samples/azure-maps-fullscreen-control
    //     // @ts-ignore
    //     control:  FullscreenControl(),
    //     controlOptions: {
    //       position: ControlPosition.TopRight,
    //     },
    //   },
  ];

  const geoLocationControl = React.useMemo(() => {
    if (props.allowGeoLocation) {
      if (props.geoLocationMode === GeolocationMode.GLM_LOCATION_SEARCH) {
        return new GeolocationControl({
          startActive: props.geoLocationStartActive,
          updateMapCamera: false,
          skipFirstZoom: props.geoLocationSkipFirstZoom, // map has data and wants to zoom, so disable first geolocation control zoom
          showUserLocation: true,
          markerColor: 'Orange',
          markerPulse: false,
          dblClickReposition: true,
          dragReposition: true,
          manualOnly: true,
          initCallback: props.geolocInitCallback,
          positionCallback: props.geolocPositionCallback,
          markerRemovedCallback: props.geolocMarkerRemovedCallback,
        } as GeolocationControlOptions);
      } else {
        // default if undefined or GLM_LICENCE_SEARCH
        return new GeolocationControl({
          startActive: props.geoLocationStartActive,
          updateMapCamera: true,
          skipFirstZoom: props.geoLocationSkipFirstZoom, // map has data and wants to zoom, so disable first geolocation control zoom
          showUserLocation: true,
          dblClickReposition: true,
          dragRadius: true,
          initCallback: props.geolocInitCallback,
          positionCallback: props.geolocPositionCallback,
          markerRemovedCallback: props.geolocMarkerRemovedCallback,
        } as GeolocationControlOptions);
      }
    } else {
      return null;
    }
  }, [props.allowGeoLocation, props.geoLocationStartActive, props.geoLocationMode]);

  if (geoLocationControl) {
    mapCustomControls.push({
      control: geoLocationControl,
      controlOptions: { position: ControlPosition.TopRight },
    });
  }

  // map reference and init -----
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(AzureMapsContext);
  useEffect(() => {
    if (isMapReady && mapRef) {
      // set copyright info (note links are ugly and seem not to work so just text
      // @ts-ignore
      mapRef.copyrightControl.copyrightDiv.innerHTML =
        // @ts-ignore
        '© LINZ CC BY 4.0 © Imagery Basemap contributors ' + mapRef.copyrightControl.copyrightDiv.innerHTML;

      // We _should_ be able to set up the LINZ tile layer here, but have not been able to get to work properly
      // instead we add it to a TSX layer below, and ensure the Z-order is correct when we first toggle it visible in
      // LinzSatelliteToggle. This works provided we start initially NON-visible

      // filter place name labels as they are often wrong.
      if (props.mapConfig.hideBuildingLabels) {
        filterOutLocationLabels(mapRef);
      }

      // optional callbacks to send map mouse events
      if (props.mapMouseMoveCallback) {
        mapRef.events.add('mousemove', props.mapMouseMoveCallback);
      }
      if (props.mapMouseClickCallback) {
        mapRef.events.add('click', props.mapMouseClickCallback);
      }
    }
  }, [mapRef, isMapReady]);

  // hook to catch changes to the zoom/center options and update the camera
  useEffect(() => {
    if (isMapReady && mapRef && mapOptions && props.shouldZoom) {
      mapRef.setCamera({ center: mapOptions.center, zoom: mapOptions.zoom, type: 'ease', duration: 200 });
    }
    // eslint-disable-next-line
  }, [mapRef, isMapReady, mapOptions, props.shouldZoom]);

  useResizeObserver(resizeRef, () => mapRef?.resize());

  // Main TSX -----------------------------------------------------------------
  // Note for the context to work correctly the AzureMapsProvider must be wrapping this in the parent and not included in this component
  return (
    <div>
      <div style={{ height: props.height, width: props.width }} ref={resizeRef}>
        <AzureMap options={mapOptions} controls={mapControls} customControls={mapCustomControls} imageSprites={rrfImages}>
          <AzureMapDataSourceProvider id={'TileLayerDataSource '}>
            <AzureMapLayerProvider
              id={SAT_LAYER_ID}
              options={{
                // EPSG:3857 for Web Mercator, aerial for photos
                tileUrl: 'https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=' + props.mapConfig.linzApiKey,
                opacity: 1.0,
                tileSize: 256,
                minSourceZoom: 0,
                maxSourceZoom: 22,
                visible: satelliteToggle.satelliteVisible,
              }}
              type={'TileLayer'}
            />
            <AzureMapLayerProvider
              id={TLA_LAYER_ID}
              options={{
                // EPSG:3857 for Web Mercator, 104267 for TLA boundaries
                tileUrl:
                  'https://tiles-a.koordinates.com/services;key=' +
                  props.mapConfig.koordinatesApiKey +
                  '/tiles/v4/layer=104267/EPSG:3857/{z}/{x}/{y}.png',
                opacity: 0.75,
                tileSize: 256,
                minSourceZoom: 0,
                maxSourceZoom: 22,
                visible: props.allowTLA === true && satelliteToggle.tlaVisible === true,
              }}
              type={'TileLayer'}
            />
          </AzureMapDataSourceProvider>
          {props.children as IAzureMapChildren}
        </AzureMap>
      </div>
      <div style={{ border: '1px solid red', padding: '6px', marginTop: '6px' }}>
        The results displayed on the map are indicative georeference points.
        <br />
        The map shows the current page of the search results list only, to display more results you change the page or use the results page settings
        and navigation.
      </div>
    </div>
  );
};

export default AzureLinzMap;
