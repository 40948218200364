import { datadogLogs } from '@datadog/browser-logs';
import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}
interface State {
  hasError?: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    datadogLogs.logger.error('Error occured', error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log(`ErrorBoundary\n ${JSON.stringify(error, null, 2)}\n ${JSON.stringify(errorInfo)} `);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong. See dev tools for info</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
