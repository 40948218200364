import { Icon } from '@fluentui/react';
import * as React from 'react';

import InlineButton from 'common/controls/buttons/InlineButton';
import FormButtons from 'common/form/FormButtons';
import { useTheme } from 'common/theme/RRFTheme';

import { getClassNames } from './Accordion.classNames';

interface Props {
  id: string;
  title: string;
  expanded?: boolean;
  done?: boolean;
  error?: boolean;
  disabled?: boolean;
  onDone?: () => void;
  onExpandCollapsed?: () => void;
  showFormButtons?: boolean;
  hideIcon?: boolean;
  showUnchange?: boolean;
  children?: React.ReactNode;
}

const Accordion: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = React.useState<boolean | undefined>(props.expanded);
  const theme = useTheme();
  const classNames = getClassNames(theme, expanded, props.disabled);
  const CompletedIcon = <Icon iconName="SkypeCircleCheck" className={classNames.complete} />;
  const InCompleteIcon = <Icon iconName="CircleRing" className={classNames.inComplete} />;
  const ErrorIcon = <Icon iconName="Error" className={classNames.error} />;
  const handleClick = () => {
    if (props.disabled) return;
    setExpanded(!expanded);
    if (props.onExpandCollapsed) {
      props.onExpandCollapsed();
    }
  };
  const handleDone = () => {
    if (!expanded && props.done) {
      setExpanded(true);
    }
    props.onDone && props.onDone();
  };

  React.useEffect(() => {
    if (props.disabled) {
      setExpanded(false);
    } else {
      setExpanded(props.expanded);
    }
  }, [props.expanded, props.disabled]);

  return (
    <div className={classNames.root} id={props.id}>
      <div data-automation-id={`accordion-header-${props.id}`} id={`accordion-header-${props.id}`} className={classNames.header}>
        <div
          data-automation-id={`accordion-header-${props.id}-expand-collapse`}
          aria-expanded={expanded}
          className={classNames.headerTitle}
          role="button"
          onClick={(ev) => {
            handleClick();
          }}
          title="Expand or collapse accordion header"
        >
          {props.done && CompletedIcon}
          {!props.error && !props.done && !props.hideIcon && InCompleteIcon}
          {props.error && ErrorIcon}
          <h3 className={classNames.title} style={{ marginRight: 'auto' }}>
            {props.title}
          </h3>
        </div>
        {props.onDone && (
          <InlineButton
            className={classNames.doneBtn}
            onClick={(ev) => {
              handleDone();
              ev.stopPropagation();
            }}
            disabled={props.disabled}
          >
            {props.done ? 'Continue crafting' : 'Done'}
          </InlineButton>
        )}
        {props.showUnchange && <span className={classNames.unchangeLabel}>Unchanged</span>}
        <Icon
          onClick={handleClick}
          role="button"
          aria-expanded={expanded}
          className={classNames.chevron}
          iconName={expanded ? 'ChevronUpMed' : 'ChevronDownMed'}
          styles={{
            root: { fontSize: '12px', cursor: 'pointer' },
          }}
          title="Expand or collapse accordion"
          tabIndex={0}
        />
      </div>
      {expanded && (
        <>
          <div
            role="region"
            className={classNames.content}
            data-automation-id={`accordion-content-${props.id}`}
            aria-labelledby={`accordion-header-${props.id}`}
          >
            {props.children}
            {props.showFormButtons && (
              <FormButtons submitButtonId="save" submitButtonText="Save" hideBack onEditClick={() => {}} onCancelEdit={() => {}} noMargin />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Accordion;
