let environment: Environment;
let pafSearchUrl;
export const hostname = window && window.location && window.location.hostname;

type Environment = 'PRD' | 'PRE' | 'UAT' | 'TST' | 'CI' | 'LOCAL';

if (/(prd)/.test(hostname)) {
  environment = 'PRD';
} else if (/(pre)/.test(hostname)) {
  environment = 'PRE';
} else if (/(uat)/.test(hostname)) {
  environment = 'UAT';
} else if (/(tst)/.test(hostname)) {
  environment = 'TST';
} else if (/(dev)/.test(hostname)) {
  environment = 'CI';
} else if (/(local)/.test(hostname)) {
  environment = 'LOCAL';
} else if (/(127.0.0)/.test(hostname)) {
  environment = 'LOCAL';
} else {
  // PRD by default, as
  environment = 'PRD';
}

export const ENVIRONMENT = environment;
export const FORM_DEBUG = environment === 'LOCAL' && true;

switch (environment) {
  case 'PRD':
    pafSearchUrl = '/services/v2/shared-services/address-lookup/2/searchAddress?r=10&q=';
    break;
  case 'PRE':
    pafSearchUrl = '/services/v2/shared-services/address-lookup/2/searchAddress?r=10&q=';
    break;
  case 'TST':
    pafSearchUrl = '/services/v2/shared-services/address-lookup/2/searchAddress?r=10&q=';
    break;
  case 'CI':
    pafSearchUrl = '/services/v2/shared-services/address-lookup/2/searchAddress?r=10&q=';
    break;
  default:
    pafSearchUrl = '/services/v2/shared-services/address-lookup/2/searchAddress?limit=10&q=';
}

export const PAF_SEARCH_URL = pafSearchUrl;
