import * as React from 'react';
import { Route, RouteProps } from 'react-router';

import { useAuth } from './AuthHook';
import ForbiddenPage from './ForbiddenPage';
import NotLoggedInPage from './NotLoggedInPage';
import { Permission } from './Permissions';
import { Role } from './Roles';

interface ProtectedRouteProps extends RouteProps {
  permission?: Permission[];
  role?: Role[];
  blockedRole?: Role[];
  skipPermissionsForExternal?: boolean; // render to the page even if doesn't have permission
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { loggedIn, hasPermission, hasRole } = useAuth();

  if (!loggedIn) {
    return <Route {...props} component={NotLoggedInPage} />;
  }

  const canAccessPermission = !props.permission ? true : props.permission?.find((p) => hasPermission(p)) !== undefined;
  const canAccessRole = !props.role ? true : props.role?.find((r) => hasRole(r)) !== undefined;
  const hasBlockedRole = !props.blockedRole ? false : props.blockedRole?.find((r) => hasRole(r)) !== undefined;

  return (canAccessPermission && canAccessRole && !hasBlockedRole) || props.skipPermissionsForExternal ? (
    <Route {...props} />
  ) : (
    <Route {...props} component={ForbiddenPage} />
  );
};

export default ProtectedRoute;
