import { DefaultButton, Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useContext, useState } from 'react';

import { GeoreferenceTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import { TextField } from '../../../../common/controls/inputs/TextField';
import { MoreOrLessBtn } from '../../../../common/controls/surfaces/MoreOrLess';
import { FormContext } from '../../../../common/form/Form';
import { useMedia } from '../../../../common/layout/MediaHook';
import { validFormatEastingNorthing } from '../../../../common/utils/locationUtils';
import { getValueByPath } from '../../../../common/utils/objectUtils';
import GeoreferenceGroupPanel from './GeoreferenceGroupPanel';

interface GeoreferenceProps {
  namePrefix: string;
  hideLabel?: boolean;
  showDetails?: boolean;
  label?: string;
  showOrder?: boolean;
  showRequired?: boolean;
  onRemove?: () => void;
  geoReferenceIndex?: number;
  textFieldSize?: number;
}

const GeoreferenceDetail = (props: GeoreferenceProps) => {
  const { values } = useFormikContext<any>();
  const type = values.georeferenceType || values.georeference?.type;
  const showMapNumber = type === GeoreferenceTypeEnum.T || type === GeoreferenceTypeEnum.M;
  const isLatLong = type === GeoreferenceTypeEnum.D2000;
  const { mode } = useContext(FormContext);

  const order = getValueByPath(values, `${props.namePrefix}order`);
  const georeferenceGroup = getValueByPath(values, `georeferences.${order - 1}`) || values.georeference;
  const { isMobile } = useMedia();
  const addMoreOrLessSpaceLabel = values.georeference && !isMobile;

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <>
      <Grid.Row>
        {props.label && (
          <Grid.Col>
            <Label>{props.label}</Label>
          </Grid.Col>
        )}
        {props.showOrder && (
          <Grid.Col lg={1}>
            {isMobile && <Label>Point</Label>}
            <TextField name={`${props.namePrefix}order`} readOnly readOnlyRenderFormat={() => props.geoReferenceIndex ?? 0} />
          </Grid.Col>
        )}

        {showMapNumber && (
          <Grid.Col sm={4} lg={props.textFieldSize ?? 2}>
            <TextField label={props.hideLabel ? undefined : 'Map'} name={`${props.namePrefix}mapNumber`} required={props.showRequired} />
          </Grid.Col>
        )}
        <Grid.Col sm={showMapNumber ? 4 : 6} lg={props.textFieldSize ?? 2}>
          <TextField
            label={props.hideLabel ? undefined : isLatLong ? 'Latitude' : 'Easting'}
            name={isLatLong ? `${props.namePrefix}northing` : `${props.namePrefix}easting`}
            formatText={validFormatEastingNorthing(true, type)}
            readOnlyRenderFormat={validFormatEastingNorthing(true, type)}
            required={props.showRequired}
          />
        </Grid.Col>
        <Grid.Col sm={showMapNumber ? 4 : 6} lg={props.textFieldSize ?? 2}>
          <TextField
            label={props.hideLabel ? undefined : isLatLong ? 'Longitude' : 'Northing'}
            name={isLatLong ? `${props.namePrefix}easting` : `${props.namePrefix}northing`}
            formatText={validFormatEastingNorthing(false, type)}
            readOnlyRenderFormat={validFormatEastingNorthing(false, type)}
            required={props.showRequired}
          />
        </Grid.Col>
        {props.onRemove && (
          <Grid.Col sm={5} lg={2}>
            <DefaultButton style={{ marginTop: isMobile ? '5px' : 0 }} onClick={props.onRemove}>
              Remove
            </DefaultButton>
          </Grid.Col>
        )}
        {props.showDetails && georeferenceGroup?.origin?.id && (
          <Grid.Col sm={7} lg={3}>
            {addMoreOrLessSpaceLabel && <Label>&nbsp;</Label>}
            <MoreOrLessBtn moreText="Show all Geo ref types" lessText="Hide all Geo ref types" more={showDetails} setShowMore={setShowDetails} />
          </Grid.Col>
        )}
      </Grid.Row>
      {showDetails && <GeoreferenceGroupPanel georeferenceGroup={georeferenceGroup} hideOriginal={false} />}
    </>
  );
};

export default GeoreferenceDetail;
