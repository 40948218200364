import * as React from 'react';
import { Route, RouteProps } from 'react-router';

import { useAuth } from './AuthHook';
import ForbiddenPage from './ForbiddenPage';
import NotLoggedInPage from './NotLoggedInPage';
import { Permission } from './Permissions';

interface CasualUserRouteProps extends RouteProps {
  permission?: Permission[];
}

const CasualUserRoute: React.FC<CasualUserRouteProps> = (props) => {
  const { loggedIn, isCasualUser, hasPermission } = useAuth();

  const canAccess = !props.permission ? true : props.permission?.find((p) => hasPermission(p)) !== undefined;

  if (!loggedIn) {
    return <Route {...props} component={NotLoggedInPage} />;
  }

  if (!isCasualUser() && !canAccess) {
    return <Route {...props} component={ForbiddenPage} />;
  }

  return <Route {...props} />;
};

export default CasualUserRoute;
