import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { FormikContextType } from 'formik';
import { omit, PropertyName } from 'lodash';
import React, { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import Dialog, { DialogButtonsContainer } from '../surfaces/Dialog';

interface ConfirmStrategy {
  shouldConfirm?: boolean;
}

type ConfirmActionProps = {
  formikContext: FormikContextType<any>;
  confirmStrategy: ConfirmStrategy;
  children: JSX.Element;
  onCancel?: () => void;
};

const ConfirmOnClick: React.FC<ConfirmActionProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const childOnClick = props.children.props.onClick;
  const childHref = props.children.props.href;

  const handleOnClick = (e: any) => {
    if (props.confirmStrategy.shouldConfirm) {
      e.preventDefault();
      setIsOpen(true);
    } else if (childOnClick) {
      childOnClick();
    }
  };

  const handleOnOkDontSave = () => {
    if (childOnClick) {
      // Reset the form so that it is not dirty
      props.formikContext.resetForm();
      clickChild();
    }
  };

  const clickChild = () => {
    setIsOpen(false);
    if (childHref) {
      document.location.href = props.children.props.href;
    } else if (childOnClick) {
      childOnClick();
    }
  };

  const handleOnOkSave = () =>
    props.formikContext.submitForm().then(() => {
      if (props.formikContext.isValid) {
        clickChild();
      } else {
        setIsOpen(false);
      }
    });

  const handleOnCancel = () => {
    setIsOpen(false);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <>
      <Dialog isOpen={isOpen} title="Save changes?">
        You have unsaved changes! Do you wish to save these before you proceed?
        <DialogButtonsContainer>
          <>
            <DefaultButton onClick={handleOnOkDontSave}>Don't save</DefaultButton>
            <DefaultButton onClick={handleOnCancel}>Cancel</DefaultButton>
            <PrimaryButton onClick={handleOnOkSave}>Save</PrimaryButton>
          </>
        </DialogButtonsContainer>
      </Dialog>
      {/* Overrides child on click function */}
      {React.cloneElement(props.children, { onClick: handleOnClick })}
    </>
  );
};

export const dirtyFormConfirmStrategy = (formikContext: FormikContextType<any>, ignoreFieldsValidation?: PropertyName[]): ConfirmStrategy => {
  const initialValuesWithoutIgnoreFields = omit(formikContext.initialValues, ignoreFieldsValidation ? ignoreFieldsValidation : []);
  const valuesWithoutIgnoreFields = omit(formikContext.values, ignoreFieldsValidation ? ignoreFieldsValidation : []);
  return {
    shouldConfirm: !shallowEqual(initialValuesWithoutIgnoreFields, valuesWithoutIgnoreFields),
  };
};

export const neverConfirmStrategy: ConfirmStrategy = {
  shouldConfirm: false,
};

export const withConfirmOnClick = (wrapped: JSX.Element, confirmStrategy: ConfirmStrategy, formikContext: FormikContextType<any>) => {
  return (
    <ConfirmOnClick formikContext={formikContext} confirmStrategy={confirmStrategy}>
      {wrapped}
    </ConfirmOnClick>
  );
};

export const ConfirmNavigateModal: React.FC<{
  confirmStrategy: ConfirmStrategy;
  onClick: () => void;
  onCancel: () => void;
  visible?: boolean;
  formikContext: FormikContextType<any>;
}> = (props) => {
  const confirmOnClickRef = React.useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (props.visible && confirmOnClickRef?.current) {
      // Click on the hidden button to trigger the click confirm.
      confirmOnClickRef.current.click();
    }
  }, [confirmOnClickRef, props.visible, props.onClick]);

  return (
    <>
      {props.visible && (
        <ConfirmOnClick confirmStrategy={props.confirmStrategy} onCancel={props.onCancel} formikContext={props.formikContext}>
          <span
            ref={confirmOnClickRef}
            onClick={() => {
              props.onClick();
            }}
          />
        </ConfirmOnClick>
      )}
    </>
  );
};
