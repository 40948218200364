import { DetailsList, Dropdown, Label, PrimaryButton, SelectionMode } from '@fluentui/react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { FeatureFlagDto } from 'api-client';

import { Grid } from 'ui-library';

import { setFeatureFlags } from 'common/actions/loginActions';
import { featureFlagApi } from 'common/api/api';
import { useAuth } from 'common/auth/AuthHook';
import { FeatureFlag } from 'common/auth/FeatureFlags';
import Card from 'common/controls/surfaces/Card';
import Page from 'common/layout/Page';

const FeatureFlagManagement: React.FC = () => {
  const [selectedFeatureFlag, setSelectedFeatureFlag] = React.useState<FeatureFlag>('ENABLE_GOOGLE_TAG_MANAGER');
  const [flags, setFlags] = React.useState<FeatureFlagDto[]>([]);
  const dispatch = useDispatch();
  const { enableFeatureFlag, disableFeatureFlag } = useAuth();

  const handleEnableFeature = () => {
    enableFeatureFlag(selectedFeatureFlag).then((_) => fetchFeatureFlags());
  };

  const handleDisableFeature = () => {
    disableFeatureFlag(selectedFeatureFlag).then((_) => fetchFeatureFlags());
  };

  const fetchFeatureFlags = () =>
    featureFlagApi.getFeatureFlags().then((resp) => {
      setFlags(resp.data);
      dispatch(setFeatureFlags(resp.data));
    });

  React.useEffect(() => {
    fetchFeatureFlags();
  }, []);

  return (
    <Page title="Feature flag management">
      <h3>Feature flag management</h3>
      <Card>
        <h4>Enable/Disable</h4>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={3}>
              <Dropdown
                label="Feature flag"
                options={[
                  {
                    key: 'ENABLE_GOOGLE_TAG_MANAGER',
                    text: 'ENABLE_GOOGLE_TAG_MANAGER',
                  },
                  {
                    key: 'ENABLE_INVESTIGATION',
                    text: 'ENABLE_INVESTIGATION',
                  },
                  {
                    key: 'ENABLE_REGISTRAR_QUEUE_BATCH_PROCESSING',
                    text: 'ENABLE_REGISTRAR_QUEUE_BATCH_PROCESSING',
                  },
                  {
                    key: 'ENABLE_SPECTRUM_CHART',
                    text: 'ENABLE_SPECTRUM_CHART',
                  },
                ]}
                selectedKey={selectedFeatureFlag}
                onChange={(_, value) => setSelectedFeatureFlag(value?.key as FeatureFlag)}
              />
            </Grid.Col>
            <Grid.Col lg={3}>
              <Label>&nbsp;</Label>
              <PrimaryButton text="Enable" onClick={handleEnableFeature} />
              <PrimaryButton text="Disable" onClick={handleDisableFeature} style={{ marginLeft: '10px' }} />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Card>
      <Card>
        <h4>Feature flags</h4>
        <DetailsList selectionMode={SelectionMode.none} items={flags}></DetailsList>
      </Card>
    </Page>
  );
};

export default FeatureFlagManagement;
