import { Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import { useContext } from 'react';

import { GeoreferenceTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import { FormContext } from 'common/form/Form';

const MultipointGeoferenceHeader = () => {
  const { values } = useFormikContext<any>();
  const { mode } = useContext(FormContext);
  const canEdit = mode === 'EDIT' || mode === 'CREATE';
  const type = values.georeferenceType;
  const showMapNumber = type === GeoreferenceTypeEnum.T || type === GeoreferenceTypeEnum.M;
  const isLatLong = type === GeoreferenceTypeEnum.D2000;
  return (
    <Grid.Row>
      <Grid.Col sm={12} lg={1}>
        <Label>Point</Label>
      </Grid.Col>
      {showMapNumber && (
        <Grid.Col sm={12} lg={2}>
          <Label required={canEdit}>Map</Label>
        </Grid.Col>
      )}
      <Grid.Col sm={12} lg={2}>
        <Label required={canEdit}>{isLatLong ? 'Latitude' : 'Easting'}</Label>
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <Label required={canEdit}>{isLatLong ? 'Longitude' : 'Northing'}</Label>
      </Grid.Col>
    </Grid.Row>
  );
};

export default MultipointGeoferenceHeader;
