import { ChoiceGroup, Dialog, Label, PrimaryButton } from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';

import { EmissionLimitDto, MaintainUnwantedEmissionLimitDto, SpectrumDto, SpectrumMaskPointDto } from 'api-client';

import Grid from 'ui-library/grid';

import UnwantedEmissionLimitsView from 'licence_management/common/components/UnwantedEmissionLimitsView';
import UelGraph from 'licence_management/management_right/common/components/UelGraph';

import { FrequencyDto } from '../../../../../gen/api/api';
import ErrorLabel from '../../../../common/controls/inputs/ErrorLabel';
import TooltipHint from '../../../../common/controls/items/TooltipHint';
import { DialogButtonsContainer } from '../../../../common/controls/surfaces/Dialog';
import { useForm } from '../../../../common/form/FormHook';
import { SubSectionHeading } from '../../../../common/layout/SectionHeadings';
import SpectrumMaskPicker from '../../../search/spectrumMask/SpectrumMaskPicker';
import { Licence } from '../../CraftLicencePage';
import UnwantedEmissionLimitsDndView from './UnwantedEmissionLimitsListView';
import { frequencyForSpectrumMaskSchema, requiresUnwantedEmissionLimits } from './spectrumDetailsValidationSchema';

export const emptyUnwantedEmissionLimit = (spectrum: SpectrumDto): Partial<MaintainUnwantedEmissionLimitDto> =>
  withBounds(
    {
      id: undefined,
      limitFrequency: undefined,
      limitValue: undefined,
    } as any,
    spectrum,
  );

export const withBounds = (uel: MaintainUnwantedEmissionLimitDto, { lowerBound, upperBound }: SpectrumDto) => ({
  ...uel,
  lowerBound,
  upperBound,
});

export enum UelType {
  EditUelPoints = 'EditUelPoints',
  ViewUelPoints = 'ViewUelPoints',
  GraphUel = 'GraphUel',
}

const UnwantedEmissionLimitsSection = ({ spectrumIdx, licence }: { spectrumIdx: number; licence?: Licence }) => {
  const editMode = useForm().mode === 'EDIT';
  const { values, setFieldValue } = useFormikContext<{ spectrums: SpectrumDto[] }>();
  const [typeUEL, setTypeUel] = useState<UelType>(editMode ? UelType.EditUelPoints : UelType.ViewUelPoints);
  const name = `spectrums[${spectrumIdx}].unwantedEmissionLimits`;
  const uels: [] = get(values, name) ?? [];
  const spectrum = values.spectrums[spectrumIdx];
  const frequencies: FrequencyDto[] = spectrum.frequencies ?? [];
  const requiredUels = licence && requiresUnwantedEmissionLimits(licence);

  const replaceUnwantedEmissionLimits = (uels: MaintainUnwantedEmissionLimitDto[]) =>
    setFieldValue(
      name,
      uels.map((uel) => withBounds(uel, spectrum)),
    );

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayProps) => (
          <div style={{ marginTop: 30 }}>
            <SubSectionHeading
              title="Unwanted emission limits (UEL)"
              actionButtonOnClick={
                editMode && typeUEL === UelType.EditUelPoints ? () => arrayProps.push(emptyUnwantedEmissionLimit(spectrum)) : undefined
              }
              actionButtonId="add-unwanted-emission-limits-button"
            />
            {typeUEL === UelType.EditUelPoints && (
              <>
                {editMode && requiredUels && (
                  <SpectrumMask referenceFrequencies={frequencies} onUnwantedEmissionLimits={replaceUnwantedEmissionLimits} />
                )}
                {editMode && <ErrorLabel name={name} showIcon />}
                <UnwantedEmissionLimitsDndView
                  {...arrayProps}
                  lowerBound={spectrum.lowerBound}
                  upperBound={spectrum.upperBound}
                  onViewUel={() => setTypeUel(UelType.ViewUelPoints)}
                  onViewGraph={() => setTypeUel(UelType.GraphUel)}
                />
              </>
            )}
            {typeUEL === UelType.ViewUelPoints && (
              <UnwantedEmissionLimitsView
                unwantedEmissionLimits={uels}
                {...spectrum}
                onViewUelPts={() => setTypeUel(UelType.EditUelPoints)}
                onViewGraph={() => setTypeUel(UelType.GraphUel)}
              />
            )}
            {typeUEL === UelType.GraphUel && (
              <UelGraph
                onViewUelPts={() => setTypeUel(UelType.ViewUelPoints)}
                onEditUelPts={() => setTypeUel(UelType.EditUelPoints)}
                lowerBound={spectrum.lowerBound}
                adjacentFrequencyEmissionLimitLow={uels.filter((x: EmissionLimitDto) => x.limitFrequency <= spectrum.lowerBound)}
                upperBound={spectrum.upperBound}
                adjacentFrequencyEmissionLimitHigh={uels.filter((x: EmissionLimitDto) => x.limitFrequency >= spectrum.upperBound)}
              />
            )}
          </div>
        )}
      />
    </>
  );
};

export const SpectrumMask = ({
  referenceFrequencies,
  onUnwantedEmissionLimits,
}: {
  referenceFrequencies: FrequencyDto[];
  onUnwantedEmissionLimits: (euls: MaintainUnwantedEmissionLimitDto[]) => void;
}) => {
  const [selectedSpectrumMaskPoints, setSelectedSpectrumMaskPoints] = useState<SpectrumMaskPointDto[]>();
  const [selectedReferenceFrequency, setSelectedReferenceFrequency] = useState<FrequencyDto>([...referenceFrequencies].pop() ?? ({} as any));

  const referenceFrequencyOptions = referenceFrequencies.map((f: FrequencyDto, i) => {
    const isValid = !!frequencyForSpectrumMaskSchema.isValidSync(f);
    return {
      key: i + '',
      //@ts-ignore
      text: isValid ? (+f.referenceFrequency).toFixed(6) : (f.referenceFrequency ?? '') + '',
      disabled: !isValid,
    };
  });

  return (
    <Grid.Row>
      <Grid.Col lg={6}>
        <SpectrumMaskPicker
          disabled={!referenceFrequencyOptions.find((o) => !o.disabled)}
          onSelectedSpectrumMask={({ spectrumMaskPoints }) => {
            if (referenceFrequencies.length <= 1) {
              onUnwantedEmissionLimits(calculateUnwantedEmissionLimits(spectrumMaskPoints, selectedReferenceFrequency));
            } else {
              setSelectedSpectrumMaskPoints(spectrumMaskPoints);
            }
          }}
        />
        <Dialog title="Please choose a reference frequency to calculate the spectrum mask from." hidden={!selectedSpectrumMaskPoints}>
          <ChoiceGroup
            defaultSelectedKey={referenceFrequencyOptions.findIndex((o) => !o.disabled) + ''}
            options={referenceFrequencyOptions}
            onChange={(_, option) => setSelectedReferenceFrequency(referenceFrequencies[+option!.key])}
          />
          <DialogButtonsContainer>
            <PrimaryButton
              onClick={() => {
                onUnwantedEmissionLimits(calculateUnwantedEmissionLimits(selectedSpectrumMaskPoints, selectedReferenceFrequency));
                setSelectedSpectrumMaskPoints(undefined);
              }}
            >
              OK
            </PrimaryButton>
          </DialogButtonsContainer>
        </Dialog>
      </Grid.Col>
      <Grid.Col lg={6}>
        <Label>&nbsp;</Label>
        <TooltipHint content="Identifier or description" />
      </Grid.Col>
    </Grid.Row>
  );
};

export const calculateUnwantedEmissionLimits = (
  spectrumMaskPoints: SpectrumMaskPointDto[] = [],
  referenceFrequency: FrequencyDto,
): MaintainUnwantedEmissionLimitDto[] => {
  const power = referenceFrequency.power ?? 0;
  const refFreq = referenceFrequency.referenceFrequency ?? 0;

  return spectrumMaskPoints.map((pt, idx) => {
    const isPresent = (n?: number) => typeof n === 'number';
    const limitFrequency = isPresent(pt.displacement) ? refFreq + pt.displacement! : pt.frequency;
    let limitValue = undefined;
    if ((pt.dbwSetLevel ?? 0) !== 0 && !pt.calcLevel) {
      limitValue = pt.dbwSetLevel;
    } else if ((pt.calcLevel ?? 0) !== 0 && !pt.dbwSetLevel) {
      limitValue = power + pt.calcLevel!;
    } else if (isPresent(pt.calcLevel) && isPresent(pt.dbwSetLevel)) {
      limitValue = Math.max(pt.dbwSetLevel!, power + pt.calcLevel!);
    }

    return {
      limitFrequency,
      limitValue,
    } as MaintainUnwantedEmissionLimitDto;
  });
};

export default UnwantedEmissionLimitsSection;
