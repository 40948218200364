import React from 'react';

import { Grid } from 'ui-library';

import Page from '../common/layout/Page';
import { SectionHeading } from '../common/layout/SectionHeadings';
import FileUpload, { DrmDocument } from './components/FileUploadRrf';

const TestDocumentPage: React.FC = () => {
  const handleFileRemoved = (file: DrmDocument): Promise<void> => {
    return Promise.resolve(console.log(`Removing reference to file ${file.name} : ${file.drmId}`));
  };

  const handleFileUploaded = (file: DrmDocument): Promise<void> => {
    return Promise.resolve(console.log(`Adding reference to file ${file.name} : ${file.drmId}`));
  };

  return (
    <Page title="Test Document Management">
      <SectionHeading title="Test page for document management" />
      <Grid.Row>
        <Grid.Col sm={12} lg={4}>
          <FileUpload id="test-document-upload" onFileRemoved={handleFileRemoved} onFileUploaded={handleFileUploaded} documentType="OTHER" />
        </Grid.Col>
      </Grid.Row>
    </Page>
  );
};

export default TestDocumentPage;
