import { mergeStyles } from '@fluentui/react';
import * as React from 'react';

interface Props {
  darker?: boolean;
  children?: React.ReactNode;
}

export const Card: React.FC<Props> = (props) => {
  const className = mergeStyles({
    padding: '10px 15px',
    marginBottom: 10,
    background: props.darker ? '#e2e6f0' : '#f0f2f8',
    border: '1px solid #e2e2e2',
  });

  return <div className={className}>{props.children}</div>;
};

export default Card;
