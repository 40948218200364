import { Formik } from 'formik';
import React from 'react';

import { LicenceDtoLicenceTypeCodeEnum, LicenceSpectrumConfigurationDto } from 'api-client';

import InlineForm from 'common/form/InlineForm';
import SpectrumRecordFields from 'licence_management/reference_data/spectrum_record/SpectrumRecordFields';
import { embeddedFrqSpectrumSchema } from 'licence_management/reference_data/spectrum_record/validationSchema';

import { Licence } from '../../CraftLicencePage';
import { requiresUnwantedEmissionLimits } from './spectrumDetailsValidationSchema';

interface Props {
  onCancel: () => void;
  onAdd: (spectrumDto: any) => void;
  serviceType: number;
  startDate: string;
  endDate: string;
  spectrumConfigs: LicenceSpectrumConfigurationDto;
  licence: Licence;
}

const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;

const EmbeddedCreateFrqSpectrum = (props: Props) => {
  const initValues = {
    spectrumStatus: 'Pending',
    spectrumType: 'FRQ',
    levelOneServiceId: props.serviceType,
    frequencies: [],
    startDate: props.startDate,
    endDate: props.endDate,
    ituAllocationServiceId: undefined,
    lowerBound: '',
    upperBound: '',
    polarisation: undefined,
    generalPurpose: 'true',
    remarks: '',
    accessCode: '',
  };

  const additionalButtons = () => {
    return [
      {
        id: 'cancel-create-spectrum-form',
        onClick: props.onCancel,
        text: 'Cancel',
      },
    ];
  };

  const showUelSection = props.licence.licenceTypeCode === SPL && requiresUnwantedEmissionLimits(props.licence);

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(values) => props.onAdd(values)}
      validationSchema={embeddedFrqSpectrumSchema(props.spectrumConfigs, props.licence?.licenceTypeCode, props.licence?.classification)}
    >
      <InlineForm
        id={'create-spectrum-record-form'}
        showFormButtonsBottom
        hideFormButtonsTop
        hideBack
        mode="CREATE"
        additionalButtons={additionalButtons}
      >
        <SpectrumRecordFields
          craftEmbedded
          showUelSection={showUelSection}
          licenceClassification={props.licence.classification}
          licenceTypeCode={props.licence.licenceTypeCode}
        />
      </InlineForm>
    </Formik>
  );
};

export default EmbeddedCreateFrqSpectrum;
