import * as React from 'react';

import { Grid } from 'ui-library';

import { TextField } from '../../../common/controls/inputs/TextField';
import { SectionHeading } from '../../../common/layout/SectionHeadings';

const AirCraftDetailsSection = () => {
  const upperCase = (val: any) => {
    if (val !== undefined) return val.toUpperCase();
  };

  return (
    <>
      <SectionHeading title="Aircraft details" />
      <Grid.Row>
        <Grid.Col sm={12} lg={2}>
          <TextField name="callsign" label="Callsign" formatText={upperCase} required />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField name="additionalEquipment" multiline label="Additional Equipment Fitted" />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default AirCraftDetailsSection;
