import { FileNoteDto } from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { eventApi } from 'common/api/api';

export const useEventApi = () => {
  const { aroundApi } = useApi();

  return {
    saveFileNote: (fileNoteDto: FileNoteDto, config?: ApiConfig) => aroundApi(eventApi.saveFileNote(fileNoteDto), config),
    getFileNotes: (entityId: number, entityType: string, config?: ApiConfig) => aroundApi(eventApi.getFileNotes(entityId, entityType), config),
    hasEntityViewAccessForFileNote: (entityId: number, entityType: string, config?: ApiConfig) =>
      aroundApi(eventApi.hasEntityViewAccessForFileNote(entityId, entityType), config),
    removeFileNote: (fileNoteId: number, fileNoteDto: FileNoteDto, config?: ApiConfig) =>
      aroundApi(eventApi.removeFileNote(fileNoteId, fileNoteDto), config),
    getFileNoteAttachment: (drmId: string, config?: ApiConfig) =>
      aroundApi(eventApi.getFileNoteAttachment(drmId, { responseType: 'arraybuffer' }), config),
  };
};
