import { useAuth } from 'common/auth/AuthHook';
import { useShortcuts } from 'common/layout/NavigationShortcuts';

export const useDisplayIcon = () => {
  const menuItems = useShortcuts();

  const { userPreference } = useAuth();

  const displayShortcutIcon = (text: string | undefined): boolean | undefined => {
    const key = menuItems.find((i: any) => i.text === text)?.key;
    var shortcuts;
    if (userPreference?.otherPreference?.shortcuts) {
      shortcuts = userPreference?.otherPreference?.shortcuts.split(',').map(Number);
    } else {
      shortcuts = [6, 3, 1, 20];
    }
    if (key) {
      if (shortcuts.indexOf(key) === -1) {
        return true;
      } else {
        return false;
      }
    }
  };
  return { displayShortcutIcon: displayShortcutIcon };
};
