import { IconButton } from '@fluentui/react';
import * as React from 'react';

import { getClassNames } from 'common/layout/BackToTop.classNames';
import { useMedia } from 'common/layout/MediaHook';
import { useTheme } from 'common/theme/RRFTheme';
import { useScroll } from 'common/utils/ScrollHook';

const BackToTop: React.FC = () => {
  const theme = useTheme();
  const { isMobile } = useMedia();
  const [showBackToTop, setShowBackToTop] = React.useState(false);
  const { scrollToTop } = useScroll();
  const classNames = getClassNames(theme, isMobile);
  const offsetToDisplay = 50;

  React.useEffect(() => {
    const onScroll = () => setShowBackToTop(window.pageYOffset > offsetToDisplay);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return showBackToTop ? (
    <IconButton
      iconProps={{
        iconName: 'SkypeCircleArrow',
        className: classNames.icon,
        style: { fontSize: '28px' },
      }}
      onClick={scrollToTop}
      ariaLabel="Scroll back to top"
      className={classNames.root}
    />
  ) : (
    <></>
  );
};

export default BackToTop;
