import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';

import { SpectrumMaskSearchCriteria, SpectrumMaskSearchResults } from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import { validateWithFormValues } from 'common/validation/validationWithFormValues';
import { parseQueryString, searchResultsFocus } from 'search/utils';

import { useReferenceDataHookApi } from '../../reference_data/ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';
import SearchSpectrumMaskForm from './SearchSpectrumMaskForm';
import SearchSpectrumMaskResults from './SearchSpectrumMaskResults';
import { searchSpectrumMaskValidationSchema } from './validationSchema';

const SearchSpectrumMaskPage: React.FC = () => {
  const { isInternalUser } = useAuth();
  const [searchCriteria, setSearchCriteria] = useState<SpectrumMaskSearchCriteria>();
  const [searchResults, setSearchResults] = useState<SpectrumMaskSearchResults>();
  const { searchSpectrumMask } = useReferenceDataHookApi();
  const { navigateToSearchSpectrumMask } = useLicenceReferenceDataNavigator();
  const [submitted, setSubmitted] = useState<boolean>(false);

  const defaultValues = {
    searchText: '',
    hidden: 'false',
  };
  const initialValues = parseQueryString(defaultValues);

  const handleSubmit = (formValues: any) => {
    formValues = { ...formValues };
    if (!isInternalUser) {
      formValues = { ...formValues, hidden: false };
    }
    navigateToSearchSpectrumMask(formValues, true);
    return searchSpectrumMask(formValues, { showLoadingSpinner: 'Searching...' }).then((searchResults) => {
      setSearchCriteria({
        ...formValues,
        orderBy: searchResults.orderBy ?? formValues.orderBy,
      });
      setSearchResults(searchResults);
      setSubmitted(true);
      searchResultsFocus();
      return searchResults;
    });
  };

  const handleClear = () => {
    setSearchCriteria(defaultValues as any);
    setSearchResults(undefined);
    setSubmitted(false);
  };

  const handlePaging = (page: number, pageSize: number) => {
    let paging = searchCriteria ?? {};
    paging.page = page;
    paging.pageSize = pageSize;
    navigateToSearchSpectrumMask(paging, true);
  };

  const handleOrderBy = (orderBy: string) => {
    let sort = searchCriteria ?? {};
    sort.orderBy = orderBy;
    navigateToSearchSpectrumMask(sort, true);
  };

  return (
    <Page title="Search Spectrum Mask">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateWithFormValues(searchSpectrumMaskValidationSchema)}
        enableReinitialize
        validateOnBlur
      >
        {() => <SearchSpectrumMaskForm onClear={handleClear} showAddSpectrumMaskButton={submitted} />}
      </Formik>
      {submitted && (
        <SearchSpectrumMaskResults
          searchCriteria={searchCriteria}
          searchResults={searchResults}
          onPaging={handlePaging}
          onOrderBy={handleOrderBy}
          submitted={submitted}
        />
      )}
    </Page>
  );
};

export default SearchSpectrumMaskPage;
