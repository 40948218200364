import { MessageBarType } from '@fluentui/react';
import React from 'react';

import { CaseTypeGroupEnum } from 'api-client';

import { Form } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { formatDate } from 'common/utils/dateUtils';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import { AssociatedToByEnum } from 'investigation/components/CaseAssociatedTo';
import { useCamundaGroupOptions, useCaseAssociatedTo, useCaseTypeOptions, useCurrentUserGroups, useQueryParams } from 'investigation/hooks';
import { AuditFormProps } from 'investigation/interfaces';

import AuditFormContent from './components/AuditFormContent';
import { useCreateAuditApi, useValidateAuditForm } from './hooks';

const CreateAuditForm = () => {
  const createAuditApi = useCreateAuditApi();

  const { myUserId } = useAuth();
  const { caseTypeOptions } = useCaseTypeOptions(CaseTypeGroupEnum.AUDIT);
  const { camundaGroupOptions } = useCamundaGroupOptions();
  const { currentRadioInvestigatorGroup } = useCurrentUserGroups();
  const { navigateToMaintainAudit } = useInvestigationRouter();
  const { setPageInstruction } = usePage();

  const { validateForm } = useValidateAuditForm();

  const queryParams = useQueryParams();

  let associatedToBy = undefined;

  const clientId = queryParams.get('clientId') || '';
  const licenceId = queryParams.get('licenceId') || '';

  let associatedTo = undefined;

  if (clientId) {
    associatedToBy = AssociatedToByEnum.CLIENT;
    associatedTo = clientId;
  } else if (licenceId) {
    associatedToBy = AssociatedToByEnum.LICENCE;
    associatedTo = licenceId;
  }

  const { client, licence } = useCaseAssociatedTo(associatedToBy, { entityId: associatedTo });

  return (
    <Form<AuditFormProps>
      formButtonsOptions={{
        submitButtonText: 'Save',
      }}
      id="audit-form"
      handleSubmit={(values, formikHelpers) => {
        createAuditApi(values)
          .then(({ id = 0 }) => {
            navigateToMaintainAudit(id);
            setPageInstruction(
              <span>
                Case <strong>#{id}</strong> has been successfully saved.
              </span>,
              MessageBarType.success,
            );
          })
          .finally(() => formikHelpers.setSubmitting(false));
      }}
      initialValues={{
        allocatedTo: currentRadioInvestigatorGroup?.id,
        allocatedToGroups: camundaGroupOptions,
        allocatedToUser: currentRadioInvestigatorGroup?.id ? myUserId : undefined,
        associatedTo,
        associatedToBy,
        caseTypeId: undefined,
        caseClassificationId: undefined,
        caseTypeOptions,
        client,
        licence,
        isAssociatedToOptional: !!(clientId || licenceId),
        startDate: formatDate(new Date()),
      }}
      mode="CREATE"
      showFormButtonsBottom
      validate={validateForm}
    >
      <AuditFormContent />
    </Form>
  );
};

const CreateAuditPage = () => {
  return (
    <Page title="Create Audit">
      <CreateAuditForm />
    </Page>
  );
};

export { CreateAuditPage };
