import moment from 'moment';

export const RRF_DATE_FORMAT = 'D-MMM-YYYY';
export const RRF_DATETIME_FORMAT = 'D-MMM-YYYY HH:mm';
export const RRF_DATETIME_FORMAT_SLASH = 'D/MMM/YYYY HH:mm';
export const ISO_8601_NO_T_SEC = 'YYYY-MM-DD HH:mm';
export const NZ_DATE_FORMAT = 'DD-MMM-YYYY HH:mm';
export const ISO_8601_NO_TIME = 'YYYY-MM-DD';
export const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const formatDate = (date?: Date): string => {
  return date === undefined || date === null ? '' : moment(date).format(RRF_DATE_FORMAT);
};

/**
 * formats the date using UTC timezone, i.e 0 hours from GMT
 * @param date
 */
export const formatDateNoTZ = (date?: Date): string => {
  if (typeof date === 'string') {
    return '' + date;
  }
  return moment.utc(date).format(RRF_DATE_FORMAT);
};

export const formatDateAndTime = (date?: Date): string => {
  return date === undefined || date === null ? '' : date.toLocaleString('en-NZ');
};

export const formatTime = (value: string) => (value.match(/^[0-9]{2}:[0-9]{2}$/) ? value + ':00' : value);

export const getTimeFromIsoDate = (dateTime?: string): string | undefined => {
  return dateTime ? moment(dateTime, 'YYYY-MM-DDTHH:mm').format('HH:mm') : undefined;
};

export const toISODateString = (date?: Date | null): string | undefined => {
  if (!date || date === null) {
    return undefined;
  }

  const yyyy = date.getFullYear();
  const M = date.getMonth() + 1;
  const d = date.getDate();

  return yyyy + '-' + (M < 10 ? '0' : '') + M + '-' + (d < 10 ? '0' : '') + d;
};

/**
 * Formats the given string | Date using the default oasis date format unless a format is given
 *
 * @param dateMaybeTime
 * @param format - to override the default oasis date format (better have a good reason if you do this)
 */
export const formatDateFromISO = (dateMaybeTime?: string | Date, format?: string): string | undefined => {
  const useFormat = format ? format : RRF_DATE_FORMAT;
  return dateMaybeTime ? moment(dateMaybeTime, 'YYYY-MM-DDTHH:mm').format(useFormat) : undefined;
};

export const concatenatesDateTime = (date: string | undefined, time: string | undefined) => `${date}T${time}:00`;

export const formatDateTimeFromISO = (
  dateTime?: string | Date,
  options?: {
    showSeconds?: boolean;
  },
): string | undefined => {
  return dateTime
    ? moment(dateTime, `YYYY-MM-DDTHH:mm${options?.showSeconds ? ':ss' : ''}`).format(`${RRF_DATETIME_FORMAT}${options?.showSeconds ? ':ss' : ''}`)
    : undefined;
};

export const removeTime = (dateAsStr?: string): string | undefined | null => {
  return dateAsStr ? toISODateString(new Date(dateAsStr)) : undefined;
};

/**
 * @returns {boolean} true if time in 24 hour format hh:mm and range 00:00 to 23:59; or if empty
 */
export function isTimeFormat(t?: string): boolean {
  if (!t || t === null || t === '') {
    return true;
  }
  return t.match(/^(2[0-3])|([0-1][0-9]):[0-5][0-9]$/) !== null;
}

export const isIsoDateFormat = (stringDate: string): boolean => moment(stringDate, 'YYYY-MM-DD', true).isValid();

export const months = new Map<string, string>([
  ['JAN', 'January'],
  ['FEB', 'February'],
  ['MAR', 'March'],
  ['APR', 'April'],
  ['MAY', 'May'],
  ['JUN', 'June'],
  ['JUL', 'July'],
  ['AUG', 'August'],
  ['SEP', 'September'],
  ['OCT', 'October'],
  ['NOV', 'November'],
  ['DEC', 'December'],
]);
