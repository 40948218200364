import { IDropdownOption } from '@fluentui/react';
import { Formik, FormikConfig, FormikState } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  BaseClientDto,
  EngineerDto,
  LicenceApplicationDto,
  LicenceCallsignTypeEnum,
  LicenceTypeDto,
  LicenceTypeDtoCategoryEnum,
  LicenceTypeDtoPreferredBandEnum,
  ManagementRightsSearchResult,
  PreferredBandDto,
  RadioApplicationDto,
  RadioLicenceDto,
  ServiceTypeDto,
  SpectrumApplicationDto,
  SpectrumLicenceDto,
  ValidationCallsignResponse,
} from 'api-client';

import { initialCallsign, validate as validateShipCallsignFields } from 'certificate_management/callsign/create/CreateCallsignPage';
import { ValidateCallsignContext as ValidateShipCallsignContext } from 'certificate_management/certificate/components/CertificateCallSign';
import { ApiConfig } from 'common/api/ApiHook';
import { apiErrorToFormErrors } from 'common/api/apiUtils';
import { hasAction } from 'common/api/hateosUtils';
import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { toISODateString } from 'common/utils/dateUtils';
import { asOptions } from 'common/utils/dropdownUtils';
import { validateWithFormValuesAsync } from 'common/validation/validationWithFormValues';
import { isBeforeToday, isEmpty } from 'common/validation/yupUtils';

import { useLicenceApi } from '../LicenceApiHooks';
import { useApplyLicenceNavigation, useCraftLicenceNavigation, useViewLicenceNavigation } from '../LicenceRouter';
import { canHaveReservedLicence, generalPurposeBandsOnly } from '../common/utils';
import ApplyLicenceForm from './ApplyLicenceForm';
import SatelliteDetailsOtherSections, {
  earthToSpaceAndSpaceToEarth,
  earthToSpaceOnly,
  spaceToEarthOnly,
} from './components/satellite/SatelliteDetailsOtherSections';
import { initialSatelliteValues } from './components/satellite/SatelliteDetailsSection';
import {
  ChannelMap,
  defatultRadioLicenceApplicationConfiguration,
  defaultSpectrumLicenceApplicationConfiguration,
  mobileReceiveLocation,
  serviceTypeToKeyValue,
  toBandDisplayDescription,
  toChannelMap,
  toSpectrumObject,
  toTransmitLocationDto,
} from './utils';
import { applyLicenceSchema } from './validationSchema';

const Spectrum = LicenceTypeDtoCategoryEnum.Spectrum;
const LICENCE_SHIP = LicenceCallsignTypeEnum.LICENCE_SHIP;
const LICENCE_AIR = LicenceCallsignTypeEnum.LICENCE_AIR;

interface CreateLicencePageContextProps {
  preferredBands?: IDropdownOption[];
  engineers?: IDropdownOption[];
  selectedEngineer?: string | number;
  channelOptions?: ChannelMap[];
  onLevel4Change: (key: any, licenceTypes: LicenceTypeDto[], level1Name: any) => void;
  onPreferredBandsChange: (option: any, key?: any) => void;
  setNextLevelToValidate: React.Dispatch<React.SetStateAction<string | undefined>>;
  showPreferredBand: boolean;
  showBaseMobileCallsign: boolean;
  selectedLicenceType?: LicenceTypeDto;
  showMangementRight: boolean | number;
  setSelectedManagementRight: (item: ManagementRightsSearchResult) => void;
  showEngineers: boolean;
  client?: BaseClientDto;
  level1: IDropdownOption[];
  serviceTypes: ServiceTypeDto[];
  onLicenceClassificationChange: () => void;
  baseCallSignCapture: boolean;
  mobileCallSignCapture: boolean;
  copyApplication?: boolean;
  copyRadioApplication?: boolean;
  copySpectrumApplication?: boolean;
  selectedBands?: any;
}

const defaultContextProps: CreateLicencePageContextProps = {
  preferredBands: [],
  engineers: [],
  channelOptions: [],
  onLevel4Change: () => {},
  onPreferredBandsChange: () => {},
  setNextLevelToValidate: () => {},
  showPreferredBand: false,
  showBaseMobileCallsign: false,
  showMangementRight: false,
  setSelectedManagementRight: () => {},
  showEngineers: false,
  level1: [],
  serviceTypes: [],
  onLicenceClassificationChange: () => {},
  baseCallSignCapture: false,
  mobileCallSignCapture: false,
  copyApplication: false,
  selectedBands: [],
};

export const ApplyLicencePageContext = React.createContext<CreateLicencePageContextProps>(defaultContextProps);

export const CopyApplicationPage = () => {
  const { applicationId, applicationType } = useParams<{ applicationId: string; applicationType: 'radio' | 'spectrum' }>();
  return <ApplyLicencePage applicationId={+applicationId} applicationType={applicationType} copy={true} />;
};

export const ContinueApplyLicencePage = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  return <ApplyLicencePage applicationId={+applicationId} />;
};

const ApplyLicencePage: React.FC<{ applicationId?: number; applicationType?: string; copy?: boolean }> = ({
  applicationId,
  applicationType,
  copy,
}) => {
  const {
    getServiceTypesForApplication,
    getPreferredBands,
    getChannels,
    submitRadioApplication,
    submitSpectrumApplication,
    submitSatelliteDoneApplication,
    getEngineers,
    getRadioApplication,
    getRadioApplicationLicence,
    getRadioApplicationForCopy,
    getRadioApplicationCopy,
    getSpectrumApplication,
    getSpectrumApplicationForCopy,
    getApplicantDetails,
    updateLicenceClassification,
    updateRadioApplication,
    validateLicenceCallsign,
    getBaseMobileCallSignCaptureDetails,
  } = useLicenceApi();
  const { setIsLoading } = usePage();
  const submitOrUpdateRadioApplication = applicationId && !copy ? updateRadioApplication : submitRadioApplication;
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeDto[]>([]);

  const [preferredBands, setPreferredBands] = useState<IDropdownOption[]>();
  const [engineerOptions, setEngineers] = useState<IDropdownOption[]>();

  const [channelOptions, setChannelOptions] = useState<ChannelMap[]>([]);
  const [nextLevelToValidate, setNextLevelToValidate] = useState<string>();
  const [level1, setLevel1] = useState<IDropdownOption[]>([]);
  const [showPreferredBand, setShowPreferredBand] = useState<boolean>(false);
  const [showBaseMobileCallsign, setshowBaseMobileCallsign] = useState<boolean>(false);
  const [showMangementRight, setShowManagementRight] = useState<boolean | number>(false);
  const [selectedManagementRight, setSelectedManagementRight] = useState<ManagementRightsSearchResult | undefined>();

  const [showEngineers, setShowEngineers] = useState<boolean>(false);
  const [selectedLicenceType, setSelectedLicenceType] = useState<LicenceTypeDto>();
  const [baseCallSignCapture, setBaseCallSignCapture] = useState<boolean>(false);
  const [mobileCallSignCapture, setMobileCallSignCapture] = useState<boolean>(false);
  const selectedBands = React.useRef<number[]>([]);
  const callsignType = selectedLicenceType?.callsignType;
  const validateCallsign = (callsign: string): Promise<ValidationCallsignResponse> => {
    return callsignType && callsignType !== LICENCE_SHIP ? validateLicenceCallsign({ callsign, callsignType }) : Promise.resolve({ success: true });
  };
  const { navigateToViewLicence } = useViewLicenceNavigation();
  const { navigateToCraftLicence } = useCraftLicenceNavigation();
  const { navigateToApplyLicence } = useApplyLicenceNavigation();
  const { myUserId, isInternalUser, currentRole } = useAuth();
  const type = showMangementRight ? 'spectrum' : 'radio';
  const [callsignErrors, setCallsignErrors] = useState<any>({});
  const isSatelliteType = (licenceType?: LicenceTypeDto) => licenceType?.description === 'Satellite';
  const [draftLicence, setDraftLicence] = useState<RadioLicenceDto>();
  const [draftApplication, setDraftApplication] = useState<RadioApplicationDto>();
  const [draftRadioLicence, setDraftRadioLicence] = useState<RadioLicenceDto>();
  const [draftRadioApplication, setDraftRadioApplication] = useState<RadioApplicationDto>();
  const [draftSpectrumLicence, setDraftSpectrumLicence] = useState<SpectrumLicenceDto>();
  const [draftSpectrumApplication, setDraftSpectrumApplication] = useState<SpectrumApplicationDto>();
  const [satelliteDraftDone, setSatelliteDraftDone] = useState<boolean>();
  const [enableOnChangeValidation, setEnableOnChangeValidation] = useState(true);
  const [copyApplication, setCopyApplication] = useState<boolean>(false);
  const [copyRadioApplication, setCopyRadioApplication] = useState<boolean>(false);
  const [copySpectrumApplication, setCopySpectrumApplication] = useState<boolean>(false);

  const successMsg =
    isSatelliteType(selectedLicenceType) && !satelliteDraftDone
      ? 'The Licence Application has been saved'
      : 'The Licence Application has been submitted';

  const updateDraftRadioApplication = (application: RadioApplicationDto) =>
    getApplicantDetails(application.applicant).then((applicant) =>
      setDraftRadioApplication({
        ...application,
        approvedEngineerId: application.approvedEngineerId ? application.approvedEngineerId + '' : undefined,
        client: {
          displayName: applicant.clientName,
          address: applicant.residentialAddress,
          email: applicant.email,
        },
        bandSpectrums: application?.preferredBands?.map((band) => band.createdFromId + ''),
        baseCallsigns: undefined,
        mobileCallsigns: undefined,
        baseCallsignNotes: undefined,
        mobileCallsignNotes: undefined,
      } as RadioApplicationDto),
    );

  const updateDraftSpectrumApplication = (application: SpectrumApplicationDto) =>
    getApplicantDetails(application.applicant).then((applicant) =>
      setDraftSpectrumApplication({
        ...application,
        approvedEngineerId: application.approvedEngineerId ? application.approvedEngineerId + '' : undefined,
        client: {
          displayName: applicant.clientName,
          address: applicant.residentialAddress,
          email: applicant.email,
        },
        bandSpectrums: [],
      } as SpectrumApplicationDto),
    );

  const updateDraftRadioLicence = (lic: RadioLicenceDto, app: RadioApplicationDto) => {
    setDraftRadioLicence(lic);
    if (lic.licenceTypeId === 55) {
      // satellite
      const { isTransmitLocationDetailsDone, isReceiveLocationDetailsDone, isConditionsDone } = lic.craftingProgress ?? {};
      const isDone =
        (app.satellite?.licenceClassification === earthToSpaceOnly && isConditionsDone && isTransmitLocationDetailsDone) ||
        (app.satellite?.licenceClassification === spaceToEarthOnly && isConditionsDone && isReceiveLocationDetailsDone) ||
        (app.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth &&
          isConditionsDone &&
          isTransmitLocationDetailsDone &&
          isReceiveLocationDetailsDone);
      setSatelliteDraftDone(isDone);
    }
  };

  const updateDraftSpectrumLicence = (lic: SpectrumLicenceDto, app: SpectrumApplicationDto) => {
    setDraftSpectrumLicence(lic);
    setCopyApplication(true);
    setShowManagementRight(app?.managementRightId);
  };

  const updateDraftApplication = (application: RadioApplicationDto) =>
    getApplicantDetails(application.applicant).then((applicant) =>
      setDraftApplication({
        ...application,
        approvedEngineerId: application.approvedEngineerId ? application.approvedEngineerId + '' : undefined,
        client: {
          displayName: `${applicant.clientName} (${applicant.id})`,
          address: applicant.residentialAddress,
          email: applicant.email,
        },
        suppressLicenceDetails: applicant.suppressLicenceDetails,
      } as RadioApplicationDto),
    );

  const updateDraftLicence = (lic: RadioLicenceDto, app: RadioApplicationDto) => {
    setDraftLicence(lic);
    if (lic.licenceTypeId === 55) {
      // satellite
      const { isTransmitLocationDetailsDone, isReceiveLocationDetailsDone, isConditionsDone } = lic.craftingProgress ?? {};
      const isDone =
        (app.satellite?.licenceClassification === earthToSpaceOnly && isConditionsDone && isTransmitLocationDetailsDone) ||
        (app.satellite?.licenceClassification === spaceToEarthOnly && isConditionsDone && isReceiveLocationDetailsDone) ||
        (app.satellite?.licenceClassification === earthToSpaceAndSpaceToEarth &&
          isConditionsDone &&
          isTransmitLocationDetailsDone &&
          isReceiveLocationDetailsDone);
      setSatelliteDraftDone(isDone);
    }
  };

  useEffect(() => {
    setEnableOnChangeValidation(callsignType !== LICENCE_AIR);
  }, [callsignType]);

  useEffect(() => {
    getServiceTypesForApplication().then((result) => {
      setServiceTypes(result);
      setLevel1(asOptions(result.map(serviceTypeToKeyValue), true, true));
    });

    if (applicationId) {
      if (copy) {
        if (applicationType === 'radio') {
          getRadioApplicationCopy(applicationId, true).then((app) => {
            updateDraftRadioApplication(app).then(() => {
              getRadioApplicationForCopy(applicationId).then((lic: RadioLicenceDto) => {
                updateDraftRadioLicence(lic, app);
              });
            });
          });
        } else if (applicationType === 'spectrum') {
          getSpectrumApplication(applicationId).then((app) => {
            updateDraftSpectrumApplication(app).then(() => {
              getSpectrumApplicationForCopy(applicationId).then((lic: SpectrumLicenceDto) => {
                updateDraftSpectrumLicence(lic, app);
              });
            });
          });
        }
      } else {
        getRadioApplication(applicationId).then((app) => {
          updateDraftApplication(app).then(() => {
            getRadioApplicationLicence(applicationId).then((lic: RadioLicenceDto) => {
              updateDraftLicence(lic, app);
            });
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (formValues: any, formikHelpers: FormikHelpers<any>) => {
    const apiConfig: ApiConfig = {
      showSuccessMessage: true,
      successMessage: successMsg,
    };
    const channels = formValues.channelSpectrums.flat().map((spectrumId: string | number) => toSpectrumObject(spectrumId));
    const bands = formValues.bandSpectrums.map((spectrumId: string | number) => toSpectrumObject(spectrumId));
    const transmitLocation = toTransmitLocationDto(formValues.location);
    const isSuppressed =
      formValues?.id === undefined && formValues?.suppressDetails === undefined ? formValues.applicantSuppressDetails : formValues.suppressDetails;

    const navigate = (result: LicenceApplicationDto) =>
      hasAction('Craft', result.links)
        ? navigateToCraftLicence(result?.linkedLicenceId ?? formValues.licenceId, type)
        : isSatelliteType(selectedLicenceType) && !satelliteDraftDone
        ? navigateToApplyLicence(result.id, true)
        : navigateToViewLicence(result?.linkedLicenceId ?? formValues.licenceId, type);

    if (selectedLicenceType?.category === Spectrum) {
      submitSpectrumApplication(
        {
          ...formValues,
          ...defaultSpectrumLicenceApplicationConfiguration,
          suppressDetails: isSuppressed,
        },
        apiConfig,
      )
        .then(navigate)
        .catch((reason) => apiErrorToFormErrors(reason, formikHelpers))
        .finally(() => formikHelpers.setSubmitting(false));
    } else if (isSatelliteType(selectedLicenceType) && satelliteDraftDone) {
      submitSatelliteDoneApplication(
        {
          ...formValues,
          ...defatultRadioLicenceApplicationConfiguration,
        },
        apiConfig,
      )
        .then(navigate)
        .catch((reason) => apiErrorToFormErrors(reason, formikHelpers))
        .finally(() => formikHelpers.setSubmitting(false));
    } else {
      submitOrUpdateRadioApplication(
        {
          ...formValues,
          shipCallsignRecord: formValues.level2 === 'Ship' ? formValues : undefined,
          ...defatultRadioLicenceApplicationConfiguration,
          channelSpectrums: channels,
          bandSpectrums: bands,
          applicationTransmitLocation: formValues.licenceTypeId === '211' ? transmitLocation : null,
          applicationReceiveLocation: formValues.licenceTypeId === '211' ? mobileReceiveLocation : null,
          suppressDetails: isSuppressed,
        },
        apiConfig,
      )
        .then((application) => {
          updateDraftApplication(application);
          navigate(application);
        })
        .catch((reason) => apiErrorToFormErrors(reason, formikHelpers))
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  };

  const renderForm = (helper: FormikHelpers<any> & Partial<FormikConfig<any>> & FormikState<any>) => {
    const fetchPreferredBands = ({ preferredBandMin, preferredBandMax, id }: LicenceTypeDto, level1Name: any) => {
      const serviceLevelOneId = serviceTypes!.find((value) => value.name === (level1Name || helper.values?.level1))!.id!;
      const allOrGeneralPurpose = ({ generalPurpose }: PreferredBandDto) => !generalPurposeBandsOnly(id) || generalPurpose;
      const withinMinMax = ({ lowerBound, upperBound }: PreferredBandDto) => {
        return (
          (isEmpty(preferredBandMin) || isEmpty(lowerBound) || lowerBound! >= preferredBandMin!) &&
          (isEmpty(preferredBandMax) || isEmpty(upperBound) || upperBound! <= preferredBandMax!)
        );
      };
      getPreferredBands(serviceLevelOneId, { showLoadingSpinner: true }).then((response) =>
        setPreferredBands(
          asOptions(
            response
              .filter(withinMinMax)
              .filter(allOrGeneralPurpose)
              .map((dto) => {
                return {
                  key: dto.id + '',
                  value: toBandDisplayDescription(dto),
                };
              }),
            true,
            true,
          ),
        ),
      );
    };

    const fetchEngineers = async () => {
      let engineerApprovals: EngineerDto[] = [];
      await getEngineers().then((response) => engineerApprovals.push(...response));

      setEngineers(
        asOptions(
          engineerApprovals.map((dto) => {
            return {
              key: dto.id + '',
              value: dto.firstName + ' ' + dto.lastName,
            };
          }),
          true,
        ),
      );

      if (!copy && (currentRole === 'ROLE_APPROVED_RADIO_CERTIFIER' || currentRole === 'ROLE_APPROVED_RADIO_ENGINEER'))
        setLoggedInUserAsEngineer(engineerApprovals);
    };

    const setLoggedInUserAsEngineer = (engineers: EngineerDto[]) => {
      const found = engineers.find((dto) => myUserId === dto.uid);
      if (found) {
        helper.setFieldValue('approvedEngineerId', found.id + '');
      }
    };

    const handleLevel4Change = (key: any, licenceTypes: LicenceTypeDto[], level1Name: any) => {
      const selectedLicence = licenceTypes?.find((licence) => licence.id + '' === key + '');
      const autoGrantedLicenceTypes = [15, 117, 120, 121, 211]; //so far Aicraft, Land-simplex, Maritime Ship
      if (selectedLicence?.id !== undefined) {
        if (!autoGrantedLicenceTypes.includes(selectedLicence?.id)) {
          fetchEngineers();
          setShowEngineers(true);
        } else {
          helper.setFieldValue('periodCommencementDate', new Date(toISODateString(new Date()) as string));
          setShowEngineers(false);
        }
      }

      setShowManagementRight(selectedLicence?.defaultManagementRightId || !!selectedLicence?.managementRight);
      if (selectedLicence?.managementRight) {
        helper.setFieldValue('fixedTerm', true);
      } else {
        helper.setFieldValue('managementRightId', undefined);
        setSelectedManagementRight(undefined);
      }
      if (!canHaveReservedLicence(selectedLicence?.id, selectedLicence?.compositionMatrixId)) {
        helper.setFieldValue('reservedLicenceId', undefined);
      }
      if (
        selectedLicence?.preferredBand === LicenceTypeDtoPreferredBandEnum.Mandatory ||
        selectedLicence?.preferredBand === LicenceTypeDtoPreferredBandEnum.Optional
      ) {
        fetchPreferredBands(selectedLicence, level1Name);
        setShowPreferredBand(true);
      } else {
        setShowPreferredBand(false);
      }
      setshowBaseMobileCallsign(!!selectedLicence?.baseCallsign || !!selectedLicence?.mobileCallsign);
      helper.setFieldValue('baseCallsigns', undefined);
      helper.setFieldValue('mobileCallsigns', undefined);
      helper.setFieldValue('callsign', undefined);
      if (!copy) {
        helper.setFieldValue('bandSpectrums', []);
        selectedBands.current = [];
      } else {
        selectedBands.current = helper.values.bandSpectrums;
        setBMCDetails(+helper.values.licenceTypeId, helper.values.bandSpectrums);
      }
      helper.setFieldValue('channelSpectrums', []);
      helper.setFieldValue('compositionMatrixId', selectedLicence?.compositionMatrixId);
      helper.setFieldValue(
        'showPreferredBand',
        selectedLicence?.preferredBand !== undefined && selectedLicence?.preferredBand === 'Mandatory' ? true : false,
      );
      helper.setFieldValue('satellite', isSatelliteType(selectedLicence) ? initialSatelliteValues(helper.initialValues) : undefined);

      setSelectedLicenceType(selectedLicence);
    };

    const handlePreferredBandsChange = (option: any) => {
      if (!option.selected) {
        let index: number = selectedBands.current.findIndex((a) => +a === +option?.key);
        if (index !== -1) {
          selectedBands.current.splice(index, 1);
        }
        setBMCDetails(+helper.values.licenceTypeId, selectedBands.current);
        return; //band deselected
      }
      if (selectedLicenceType?.description === 'Land Simplex - General Channels') {
        getChannels(+option.key, { showLoadingSpinner: true }).then((result) =>
          setChannelOptions((channelOptions) => [...channelOptions, toChannelMap(option.key, result)]),
        );
      }

      if (showBaseMobileCallsign) {
        selectedBands.current.push(+option?.key);
        setBMCDetails(+helper.values.licenceTypeId, selectedBands.current);
      }
    };

    const setBMCDetails = (licenceTypeId: number, bandIds: Array<number>) => {
      if (bandIds && bandIds.length > 0) {
        getBaseMobileCallSignCaptureDetails(licenceTypeId, bandIds).then((result) => {
          setBaseCallSignCapture(result !== undefined && result?.baseCallSignCapture ? true : false);
          setMobileCallSignCapture(result !== undefined && result?.mobileCallSignCapture ? true : false);
          helper.setFieldValue('baseCallSignCapture', result !== undefined && result?.baseCallSignCapture ? true : false);
          helper.setFieldValue('mobileCallSignCapture', result !== undefined && result?.mobileCallSignCapture ? true : false);
        });
      }
    };

    const handleManagementRightSelected = (managementRight: ManagementRightsSearchResult) => {
      const today = toISODateString(moment().toDate())!;
      const commencementDate = managementRight.commencementDate ?? today;
      helper.values.periodCommencementDate = !isBeforeToday(commencementDate) ? commencementDate : today;
      helper.values.periodEnding = managementRight.expiryDate;
      helper.setFieldValue('managementRightId', managementRight.id);
      setSelectedManagementRight(managementRight);
    };

    const handleLicenceClassification = () => {
      setIsLoading(true);
      updateLicenceClassification({
        ...helper.values,
        ...defatultRadioLicenceApplicationConfiguration,
      })
        .then((application) => {
          updateDraftApplication(application).then(() =>
            getRadioApplicationLicence(application.id || 0).then((lic: RadioLicenceDto) => {
              updateDraftLicence(lic, application);
            }),
          );
        })
        .finally(() => setIsLoading(false));
    };

    return (
      <ApplyLicencePageContext.Provider
        value={{
          onLevel4Change: handleLevel4Change,
          onPreferredBandsChange: handlePreferredBandsChange,
          setNextLevelToValidate,
          channelOptions,
          showPreferredBand: showPreferredBand,
          showBaseMobileCallsign: showBaseMobileCallsign,
          showMangementRight: showMangementRight,
          setSelectedManagementRight: handleManagementRightSelected,
          showEngineers: showEngineers,
          selectedLicenceType,
          engineers: engineerOptions,
          preferredBands,
          level1,
          serviceTypes,
          onLicenceClassificationChange: handleLicenceClassification,
          baseCallSignCapture: baseCallSignCapture,
          mobileCallSignCapture: mobileCallSignCapture,
          copyApplication: copyApplication,
          selectedBands: selectedBands,
          copyRadioApplication: copy,
          copySpectrumApplication: copy,
        }}
      >
        <ValidateShipCallsignContext.Provider value={{ setCallsignErrors, callsignErrors }}>
          <ApplyLicenceForm
            selectedServiceLevels={
              !copy
                ? draftLicence?.selectedServiceLevels
                : applicationType === 'radio'
                ? draftRadioLicence?.selectedServiceLevels
                : draftSpectrumLicence?.selectedServiceLevels
            }
            initialSelectedApplicant={draftApplication?.applicant}
            satelliteDraftDone={satelliteDraftDone}
          />
        </ValidateShipCallsignContext.Provider>
      </ApplyLicencePageContext.Provider>
    );
  };

  return (
    <Page title="Apply for a New Licence">
      {applicationId && !draftApplication && !draftSpectrumApplication && !draftRadioApplication ? (
        <></>
      ) : (
        <>
          <Formik
            initialValues={{
              ...initialCallsign,
              bandSpectrums: undefined,
              channelSpectrums: undefined,
              level1: undefined,
              level2: undefined,
              level3: undefined,
              licenceTypeId: undefined,
              managementRightId: undefined,
              periodCommencementDate: new Date(toISODateString(new Date()) as string),
              periodEnding: undefined,
              applicant: undefined,
              noOfSetsRequired: undefined,
              location: undefined,
              anniversaryMonth: undefined,
              enteredByRsm: false,
              active: true,
              engineeringRequired: false,
              defenceClient: undefined,
              aircraftType: undefined,
              callsign: undefined,
              radioTelephony: undefined,
              rsmToPerformEngineering: false,
              approvedEngineerId: undefined,
              ...draftApplication,
              ...draftSpectrumApplication,
              ...draftRadioApplication,
            }}
            onSubmit={handleSubmit}
            validate={(values) => {
              return validateWithFormValuesAsync(applyLicenceSchema, {
                nextLevelToValidate,
                showMangementRight,
                selectedManagementRight,
                validateCallsign,
                selectedLicenceType,
              })(values).then((formErrors) => {
                const shipCallsignValues = {
                  ...values,
                  callsignType: 'SHIP_CALLSIGN',
                  clientPicker: 'DUMMY',
                  isInternalUser,
                };
                return callsignType === LICENCE_SHIP
                  ? _.merge(formErrors, validateShipCallsignFields(shipCallsignValues, callsignErrors))
                  : formErrors;
              });
            }}
            validateOnBlur
            validateOnChange={enableOnChangeValidation}
            enableReinitialize
          >
            {renderForm}
          </Formik>
          {isSatelliteType(selectedLicenceType) && draftApplication && draftLicence && (
            <SatelliteDetailsOtherSections application={draftApplication} licence={draftLicence} onLicenceUpdated={updateDraftLicence} />
          )}
        </>
      )}
    </Page>
  );
};

export default ApplyLicencePage;
