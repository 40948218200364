import Page from '../../../../common/layout/Page';
import { Formik } from 'formik';
import React from 'react';
import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import { createLocationNameSchema } from '../validationSchema';
import NameFields from '../components/NameFields';
import Form from '../../../../common/form/Form';
import { useLicenceReferenceDataNavigator } from '../../ReferenceDataRouter';
import { useAuth } from '../../../../common/auth/AuthHook';

const CreateLocationNamePage = () => {
  const { createLocationName } = useReferenceDataHookApi();
  const { navigateToMaintainLocation } = useLicenceReferenceDataNavigator();
  const initValue = {
    locationName: undefined,
    districts: [],
  };

  const { isInternalUser } = useAuth();

  if (!isInternalUser) {
    window.location.href = '/ui/forbidden';
    return <></>;
  }

  return (
    <Page title="Create Location">
      <Formik
        initialValues={initValue}
        onSubmit={(value: any) => {
          return createLocationName(value, {
            successMessage: 'Location has been created.',
          }).then((v) => {
            navigateToMaintainLocation(v.id!);
          });
        }}
        validationSchema={createLocationNameSchema}
      >
        <Form id={'create-location-name-form'} showFormButtonsBottom mode={'CREATE'}>
          <NameFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreateLocationNamePage;
