import { Dropdown as FluentUIDropdown, IDropdownProps } from '@fluentui/react';
import { FieldAttributes, useField } from 'formik';
import * as React from 'react';

import { useForm } from '../../form/FormHook';
import { renderHelpText } from './HelpTextRenderer';
import ReadOnlyField, { renderAsOptions } from './ReadOnlyField';

export interface FormikDropdownProps {
  onChange?: (key: any) => void;
  helpText?: string;
  readOnly?: boolean;
}

const Dropdown: React.FC<FormikDropdownProps & FieldAttributes<IDropdownProps>> = (props) => {
  const [field, meta, fieldHelper] = useField<string | number | undefined>(props as any);
  const { mode } = useForm();
  const errorMessage = meta.touched && meta.error ? meta.error : props.errorMessage;

  const styling = { ...props.styles, caretDown: { color: '#333333' } };
  const isOptionOneEmptyString = props.options.length && props.options[0].key === '';
  const undefinedIfEmpty = (optionKey: any) => (isOptionOneEmptyString && optionKey === '' ? undefined : optionKey);
  const emptyIfUndefined = (fieldValue: any) => (isOptionOneEmptyString && fieldValue === undefined ? '' : fieldValue);

  return mode === 'VIEW' || props.readOnly ? (
    <ReadOnlyField {...props} renderFormat={renderAsOptions(props.options)} />
  ) : (
    <FluentUIDropdown
      {...props}
      {...field}
      onChange={(_event, option) => {
        fieldHelper.setValue(undefinedIfEmpty(option?.key));
        if (props.onChange) {
          props.onChange(option?.key);
        }
      }}
      onBlur={field.onBlur(props.name)}
      selectedKey={isOptionOneEmptyString || field.value || (typeof field.value).match(/boolean|string/) ? emptyIfUndefined(field.value) : null}
      errorMessage={errorMessage}
      styles={styling}
      onRenderLabel={renderHelpText}
      disabled={mode === 'DISABLED' || props.disabled}
      data-automation-id={`drop-down-${props.name}`}
    />
  );
};

export default Dropdown;
