import { useAtonApi } from 'aton_management/aton/AtonApiHook';
import React, { useEffect, useState } from 'react';

import { EventCriteria, EventDto, EventResults } from 'api-client';

import { Grid } from 'ui-library';

import { useUserApi } from 'client_management/user/UserApiHook';
import InlineButton from 'common/controls/buttons/InlineButton';
import Pagination, { withSorting } from 'common/controls/items/Pagination';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import DetailsList from 'common/controls/lists/DetailsList';
import { getClassNames } from 'common/layout/ExpandableRow.classNames';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { useTheme } from 'common/theme/RRFTheme';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';
import { useInvestigationApi } from 'investigation/hooks';

import { useCertificateApi } from '../../certificate_management/certificate/CertificateApiHook';
import { useClientApi } from '../../client_management/client/ClientApiHook';
import { useAuth } from '../../common/auth/AuthHook';
import { defaultUserPreference } from '../../common/user/components/UserPreference';
import { useLicenceApi } from '../../licence_management/LicenceApiHooks';
import { useManagementRightApi } from '../../licence_management/management_right/ManagementRightApiHook';
import { useReferenceDataHookApi } from '../../licence_management/reference_data/ReferenceDataApiHook';
import { usePublicApi } from '../../public/PublicApiHook';

export type EntityName =
  | 'AMATEUR_CLUB_CALLSIGN_RECORD'
  | 'SHIP_CALLSIGN_RECORD'
  | 'CERTIFICATE'
  | 'LICENCE'
  | 'CASE'
  | 'CLIENT_INT'
  | 'CLIENT_EXT'
  | 'CLIENT_PUBLIC'
  | 'LICENCE_APPLICATION'
  | 'MANAGEMENT_RIGHT'
  | 'LOCATION'
  | 'ONLINE_FORM'
  | 'MODIFIED_SPECTRUM_LICENCE'
  | 'SPECTRUM_MASK'
  | 'SPECTRUM'
  | 'ATON'
  | 'USER_PROFILE_RECORD';

interface Props {
  entityName: EntityName;
  entityId: number;
  entityToReloadOn?: object;
  suppressedHeading?: boolean;
  formLink?: (event: EventDto) => string;
}

const EventLogSection: React.FC<Props> = (props) => {
  const { loggedIn, userPreference, isCasualUser } = useAuth();
  const { getCertificateEvents, getAmateurClubCallsignEvents, getShipCallsignEvents } = useCertificateApi();
  const { getClientEventsExternal, getClientEventsInternal } = useClientApi();
  const { getLocationEvents, getSpectrumEvents, getSpectrumMaskEvents } = useReferenceDataHookApi();

  const mrApi = useManagementRightApi();
  const licenceApi = useLicenceApi();
  const publicApi = usePublicApi();
  const atonApi = useAtonApi();
  const { getManagementRightEvents } = !loggedIn || isCasualUser() ? publicApi : mrApi;
  const { getLicenceEvents } = !loggedIn || isCasualUser() ? publicApi : licenceApi;
  const { getAtonEvents } = !loggedIn || isCasualUser() ? publicApi : atonApi;
  const { getClientEvents } = publicApi;
  const { getUserEvents } = useUserApi();
  const { getCaseEvents } = useInvestigationApi();

  const theme = useTheme();
  const classNames = getClassNames(theme);

  const [expandedIndex, setExpandedIndex] = useState<number | undefined>();

  const preferedPageSize =
    userPreference && userPreference.otherPreference
      ? userPreference.otherPreference.searchResultsPerPage
      : defaultUserPreference.otherPreference.searchResultsPerPage;

  const [criteria, setCriteria] = useState<EventCriteria>({
    page: 1,
    pageSize: preferedPageSize,
    orderBy: 'createdDate desc',
  });

  const [eventResults, setEventResults] = useState<EventResults>();

  const columns = [
    {
      key: 'createdDate',
      fieldName: 'createdDate',
      name: 'Event date',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSortable: true,
      onRender: (item: EventDto) => formatDateTimeFromISO(item.createdDate),
    },
    {
      key: 'title',
      fieldName: 'title',
      name: 'Event title',
      minWidth: 300,
      maxWidth: 400,
      isResizable: true,
      isSortable: true,
      isMultiline: true,
    },
    {
      key: 'eventCode',
      fieldName: 'eventType.code',
      name: 'Event code',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      isSortable: true,
      onRender: (item: EventDto) => item.eventCode,
    },
    {
      key: 'attachmentId',
      fieldName: 'attachmentId',
      name: 'Attachment',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSortable: true,
      onRender: (item: EventDto) => (item.attachmentId ? 'Y' : 'N'),
    },
    {
      key: 'action',
      fieldName: 'actions',
      name: '',
      minWidth: 50,
      maxWidth: 100,
      onRender: (item: EventDto, index?: number) => {
        const isLess = expandedIndex === index;
        return (
          <InlineButton
            text={isLess ? 'Less' : 'More'}
            id={`${isLess ? 'less' : 'more'}-${item.id}`}
            data-automation-id={`${isLess ? 'less' : 'more'}-${item.id}`}
            onClick={() => setExpandedIndex(isLess ? undefined : index)}
          />
        );
      },
    },
  ];

  const renderExpandPanel = (item: EventDto) => {
    return (
      <div data-automation-id={`expanded-panel-${item.id}`} className={classNames.expandable}>
        <Grid.Row>
          <Grid.Col lg={3}>
            <ReadonlyData label="Event id" value={item.id} />
          </Grid.Col>
          <Grid.Col lg={3}>
            <ReadonlyData label="Event type" value={item.type} />
          </Grid.Col>
          <Grid.Col lg={3}>
            <ReadonlyData label="Created by" value={item.createdName} />
          </Grid.Col>
          {item.formId && props.formLink && (
            <Grid.Col lg={3}>
              <ReadonlyData label="Application number" value={item.formId} href={props.formLink(item)} />
            </Grid.Col>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={12}>
            <ReadonlyData label="Description" value={item.description} />
          </Grid.Col>
        </Grid.Row>
      </div>
    );
  };

  useEffect(() => {
    callEventApi();
  }, [props.entityToReloadOn]);

  const callEventApi = () => {
    switch (props.entityName) {
      case 'LICENCE': {
        getLicenceEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'CERTIFICATE': {
        getCertificateEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'CLIENT_INT': {
        getClientEventsInternal(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'CLIENT_EXT': {
        getClientEventsExternal(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'CLIENT_PUBLIC': {
        getClientEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'LOCATION': {
        getLocationEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'SPECTRUM': {
        getSpectrumEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'SPECTRUM_MASK': {
        getSpectrumMaskEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'MANAGEMENT_RIGHT': {
        getManagementRightEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'AMATEUR_CLUB_CALLSIGN_RECORD': {
        getAmateurClubCallsignEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'SHIP_CALLSIGN_RECORD': {
        getShipCallsignEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'USER_PROFILE_RECORD': {
        getUserEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'ATON': {
        getAtonEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      case 'CASE': {
        getCaseEvents(props.entityId, criteria).then((results) => setEventResults(results));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handlePaging = (page: number, pageSize: number) => {
    let paging = criteria ?? {};
    paging.page = page;
    paging.pageSize = pageSize;
    setCriteria(paging);
    callEventApi();
  };

  const handleOrdering = (orderBy: string) => {
    let sorting = criteria ?? {};
    sorting.orderBy = orderBy;
    setCriteria(sorting);
    callEventApi();
  };

  return (
    <SectionHeading
      title="Event log"
      headingStyle={props.suppressedHeading ? { backgroundColor: '#FFF4CE', fontSize: 'medium' } : { fontSize: 'medium' }}
    >
      {eventResults?.results && eventResults.results.length > 0 ? (
        <Pagination
          renderResults={() => (
            <DetailsList
              columns={withSorting(columns, criteria, handleOrdering)}
              items={eventResults?.results ?? []}
              expandedIndex={expandedIndex}
              renderExpandPanel={renderExpandPanel}
            />
          )}
          onPaging={handlePaging}
          {...eventResults}
        />
      ) : (
        <span>No event log.</span>
      )}
    </SectionHeading>
  );
};

export default EventLogSection;
