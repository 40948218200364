import { Formik, FormikState, validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';
import * as React from 'react';

import { AmateurClubCallsignRecordDto } from 'api-client';

import { useCertificateApi } from 'certificate_management/certificate/CertificateApiHook';
import { useCertificateNavigation } from 'certificate_management/certificate/CertificateRouter';
import { ValidateCallsignContext } from 'certificate_management/certificate/components/CertificateCallSign';
import { useAuth } from 'common/auth/AuthHook';
import ConfirmDialog from 'common/controls/surfaces/ConfirmDialog';
import Page from 'common/layout/Page';

import CreateCallsignForm from './CreateCallsignForm';
import { callsignValidationSchema } from './validationSchema';

export const initialCallsign: any = {
  clientId: -1,
  clientName: '',
  clientPicker: '',
  trusteePicker: '',
  equipmentType: null,
  isValidMMSI: true,
  isValidSCN: true,
  trusteeCertificate: null,
  callsignType: '',
  approvalRadio: '',
  primaryCallsign: {
    callsign: null,
    callsignDiscriminator: 'PrimaryCallsignDto',
  },
  scn: '',
  mmsi: '',
  callsign: '',
  vesselName: '',
  mmsiCraftAssociated: [],
  maritimeVesselSafetyDrmIds: [],
};

const CreateCallsignPage: React.FC = () => {
  const [callsignErrors, setCallsignErrors] = React.useState<any>({});
  const { isInternalUser, firstName, lastName } = useAuth();

  const [confirmationModal, setConfirmationModal] = React.useState<boolean>(false);
  const [hasApproval, setHasApproval] = React.useState<boolean>(false);
  const [callsignDto, setCallsignDto] = React.useState<any>(initialCallsign);

  const { createAmateurCallsign, createShipCallsign, findApprovalsBy } = useCertificateApi();
  const { navigateToMaintainAmateurCallsign } = useCertificateNavigation();
  const { navigateToMaintainShipCallsign } = useCertificateNavigation();

  React.useEffect(() => {
    findApprovalsBy({ callerHandleErrors: true, disableErrorMessage: true })
      .then((response) => {
        setHasApproval(true);
        if (isInternalUser) {
          setCallsignDto({ ...callsignDto, approvalRadio: `${response.type}${response.id} (${response.name})`, isInternalUser });
        } else {
          setCallsignDto({ ...callsignDto, approvalRadio: `${response.type}${response.id} (${firstName} ${lastName})`, isInternalUser });
        }
      })
      .catch(() => (window.location.href = '/ui/forbidden'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCallsignDiscriminator = (values: any) => {
    const copied = JSON.parse(JSON.stringify(values));
    if (_.isEmpty(values.secondaryCallsign?.callsign)) {
      copied.secondaryCallsign = undefined;
    } else {
      copied.secondaryCallsign.callsignDiscriminator = 'SecondaryCallsignDto';
    }
    if (!_.isEmpty(values.temporaryCallsigns)) {
      copied.temporaryCallsigns = copied.temporaryCallsigns
        .map((tc: any) =>
          _.isEmpty(tc.callsign)
            ? undefined
            : {
                ...tc,
                callsignDiscriminator: 'TemporaryCallsignDto',
              },
        )
        .filter((tc: any) => !!tc);
    }
    return copied;
  };

  const handleSubmit = (values: any) => {
    if (values.callsignType === 'AMATEUR_CLUB_CALLSIGN') {
      createAmateurCallsign(addCallsignDiscriminator(values), {
        showSuccessMessage: true,
        successMessage: `You have allocated amateur club callsign to ${values.clientName}`,
      }).then((response) => navigateToMaintainAmateurCallsign(response.id));
    } else {
      createShipCallsign(values, {
        showSuccessMessage: true,
        callerHandleErrors: true,
        successMessage: `You have allocated ship callsign to ${values.clientName}`,
      }).then((response) => navigateToMaintainShipCallsign(response.id));
    }
  };

  const renderForm = (helper: FormikState<any>) => {
    return (
      <ValidateCallsignContext.Provider value={{ setCallsignErrors, callsignErrors }}>
        <ConfirmDialog
          title={'Callsign record will be saved. Do you want to continue?'}
          onOk={() => {
            handleSubmit(helper.values);
            setConfirmationModal(false);
          }}
          onCancel={() => setConfirmationModal(false)}
          body={''}
          visible={confirmationModal}
          yesText="Ok"
          noText="Cancel"
          notDangerous
        />
        <CreateCallsignForm handleSubmit={() => setConfirmationModal(true)} />
      </ValidateCallsignContext.Provider>
    );
  };

  return (
    <Page title="Create Callsign">
      {hasApproval && (
        <Formik<AmateurClubCallsignRecordDto>
          initialValues={callsignDto}
          onSubmit={handleSubmit}
          enableReinitialize
          validate={(values: any) => validate(values, callsignErrors)}
          validateOnBlur
        >
          {renderForm}
        </Formik>
      )}
    </Page>
  );
};

export const validate = (values: any, callsignErrors: any) => {
  try {
    validateYupSchema(values, callsignValidationSchema, true, { ...values });
  } catch (err) {
    return _.merge(yupToFormErrors(err), callsignErrors);
  }

  return { ...callsignErrors };
};

export default CreateCallsignPage;
