import { ITag } from '@fluentui/react';
import React, { useState } from 'react';

import { SpectrumMaskDto } from '../../../../gen/api/api';
import TagPicker from '../../../common/controls/inputs/TagPicker';
import { useReferenceDataHookApi } from '../../reference_data/ReferenceDataApiHook';

interface Props {
  onSelectedSpectrumMask: (spectrumMask: SpectrumMaskDto) => void;
  disabled?: boolean;
}

const SpectrumMaskPicker: React.FC<Props> = (props) => {
  const { searchSpectrumMask } = useReferenceDataHookApi();
  const [moreResultsAvailable, setMoreResultsAvailable] = useState<boolean>(true);

  const resolveSuggestions = (searchText: string, page: number = 1) => {
    const pageSize = 100;

    const asTag = (spectrumMask: SpectrumMaskDto): ITag & { spectrumMask: SpectrumMaskDto } => ({
      key: spectrumMask.id!,
      name: `${spectrumMask.identifier} ${spectrumMask.description}`,
      spectrumMask,
    });
    return searchSpectrumMask({ searchText, hidden: false, page, pageSize }).then((searchResults) => {
      setMoreResultsAvailable(searchResults.totalItems > pageSize);
      return searchResults.results.map(asTag);
    });
  };

  return (
    <TagPicker
      label="Search for a spectrum mask record"
      name="spectrum"
      onResolveSuggestions={(searchText: string, selectedItems?: ITag[]) => resolveSuggestions(searchText)}
      onGetMoreResults={(searchText: string, selectedItems?: ITag[]) => resolveSuggestions(searchText, 2)}
      onItemSelected={(selectedItem?: ITag & { spectrumMask?: SpectrumMaskDto }) => {
        if (selectedItem?.spectrumMask) {
          props.onSelectedSpectrumMask(selectedItem.spectrumMask);
        }
        return null;
      }}
      inputProps={{ placeholder: 'Start typing to search for a spectrum mask record' }}
      pickerSuggestionsProps={{
        loadingText: 'Searching for spectrum mask records',
        suggestionsHeaderText: 'Suggested spectrum masks',
        noResultsFoundText: 'No spectrum mask records found',
        searchForMoreText: moreResultsAvailable ? 'Get more spectrum mask records' : undefined,
      }}
      disabled={props.disabled}
    />
  );
};

export default SpectrumMaskPicker;
