import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';

import { CaseStatusEnum } from 'api-client';

import { Dropdown, Grid2 } from 'ui-library';

import Checkbox from 'common/controls/inputs/Checkbox';
import Error from 'common/controls/items/Error';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { AuditFormProps } from 'investigation/interfaces';

const AuditProductOrSupplierAuditSection = () => {
  const { mode } = useForm();
  const { errors, touched, setFieldValue, values } = useFormikContext<AuditFormProps>();

  const { auditType: auditTypeErrorMessage } = errors as any;

  return (
    <>
      <SectionHeading title="Audit type" required={values.statusEnum === CaseStatusEnum.IPRG} />
      {mode === 'EDIT' && !isEmpty(touched) && auditTypeErrorMessage && <Error errorMessage={auditTypeErrorMessage} />}
      <Grid2 styles={{ paddingTop: 10 }}>
        <Grid2.Col lg={1} style={{ alignSelf: 'center' }}>
          <Checkbox
            label="EMC"
            name="emcAuditType"
            onChange={(event: any) => {
              if (!event.target.checked) {
                setFieldValue('emcStandardId', null);
              }
            }}
          />
        </Grid2.Col>
        {values.emcAuditType && (
          <Grid2.Col lg={3}>
            <Dropdown label="EMC product of interest" options={values.emcStandardOptions} name="emcStandardId" required />
          </Grid2.Col>
        )}

        <Grid2.Col colStart={1} lg={1} style={{ alignSelf: 'center' }}>
          <Checkbox
            label="Radio"
            name="radioAuditType"
            onChange={(event: any) => {
              if (!event.target.checked) {
                setFieldValue('serviceOfInterestId', null);
              }
            }}
          />
        </Grid2.Col>

        {values.radioAuditType && (
          <Grid2.Col lg={3}>
            <Dropdown label="Radio communication service of interest" options={values.serviceOfInterestOptions} name="serviceOfInterestId" required />
          </Grid2.Col>
        )}
      </Grid2>
    </>
  );
};

export default AuditProductOrSupplierAuditSection;
