import { CommandMenuItem, DropdownButton, useCommands } from '@remirror/react';

interface VariableMenuProps {
  variables?: Set<string>;
}

const VariableMenu: React.FC<VariableMenuProps> = ({ variables }) => {
  const { insertText } = useCommands();
  return variables && variables.size > 0 ? (
    <DropdownButton label="Insert variable" aria-label="Insert variable" icon="inputCursorMove">
      {[...variables].map((variable) => (
        <CommandMenuItem
          key={variable}
          commandName="insertVariable"
          onSelect={() => insertText(variable)}
          enabled
          label={<span>Insert {variable}</span>}
        />
      ))}
    </DropdownButton>
  ) : (
    <></>
  );
};

export default VariableMenu;
