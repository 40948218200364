import { mergeStyleSets } from '@fluentui/merge-styles';
import { Theme } from '@fluentui/theme';
import React from 'react';

export interface IComponentClassNames {
  root: string;
  logos: string;
  mbieLogo: string;
  nzGovtLogo: string;
  links: string;
  linkList: string;
  copyright: string;
}

export const getClassNames = (theme: Theme, isMobile: boolean): IComponentClassNames => {
  return mergeStyleSets({
    root: {
      gridArea: 'footer',
      backgroundColor: theme.palette.black,
      padding: isMobile ? '32px 15.5px 34.2px 15px' : '50px 96.5px 18.2px 100px',
    },
    logos: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignContent: isMobile ? '' : 'center',
      rowGap: isMobile ? '20px' : 0,
      flexWrap: 'wrap',
    } as React.CSSProperties,
    mbieLogo: {
      width: 280,
      height: 51.6,
    },
    nzGovtLogo: {
      width: 250,
      height: 60,
    },
    links: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignContent: isMobile ? '' : 'center',
      margin: '19.3px 0px 2px 2px',
      color: theme.palette.white,
    },
    linkList: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      alignSelf: isMobile ? '' : 'center',
      '& > li': {
        float: 'left',
        paddingRight: 10,
      },
    },
    copyright: {
      alignSelf: isMobile ? '' : 'center',
    },
  });
};
