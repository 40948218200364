import { useAtonNavigation } from 'aton_management/aton/AtonRouter';

import { useCertificateNavigation } from 'certificate_management/certificate/CertificateRouter';
import { useApprovalNavigation } from 'client_management/approval/ApprovalRouter';
import { useClientNavigation } from 'client_management/client/ClientRouter';
import { useUserNavigation } from 'client_management/user/UserRouter';
import { useAuth } from 'common/auth/AuthHook';
import { useWelcomeTextNavigation } from 'dashboard/welcome_text/WelcomeTextRouter';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import { useLicenceApi } from 'licence_management/LicenceApiHooks';
import {
  useApplyLicenceNavigation,
  useCancelLicenceNavigation,
  useModifyLicenceNavigation,
  useLicenceTransferRequestNavigation,
} from 'licence_management/LicenceRouter';
import { useManagementRightNavigator } from 'licence_management/management_right/ManagementRightRouter';
import { useLicenceReferenceDataNavigator } from 'licence_management/reference_data/ReferenceDataRouter';
import { useApplyLicenceToSupplyNavigation } from 'licence_to_supply_management/LicenceToSupplyRouter';
import { useSearchNavigation } from 'search/SearchRouter';

import { useScheduledJobNavigation } from '../../scheduled_job_management/ScheduledJobRouter';

export const useShortcuts = () => {
  const { hasPermission, hasAnyPermission, isInternalUser, currentClientId, currentClientName, isCasualUser, hasRole } = useAuth();
  const { navigateToCreateClient, navigateToSelfRegisterClient } = useClientNavigation();
  const { navigateToApplyLicence } = useApplyLicenceNavigation();
  const { navigateToModifyLicence } = useModifyLicenceNavigation();
  const { navigateToCancelLicence } = useCancelLicenceNavigation();
  const { navigateToApplyManagementRight, navigateToFormFour } = useManagementRightNavigator();
  const { navigateToApplyLicenceToSupply } = useApplyLicenceToSupplyNavigation();
  const { navigateToSearchEquipment, navigateToSearchSpectrumMask, navigateToSearchSpectrum, navigateToGeoReferenceConverter } =
    useLicenceReferenceDataNavigator();
  const {
    navigateToSearchClient,
    navigateToSearchLicence,
    navigateToSearchLicenceToSupply,
    navigateToSearchAntenna,
    navigateToSearchLocation,
    navigateToSearchManagementRights,
    navigateToSearchCertificate,
    navigateToSearchCallsign,
    navigateToSearchAton,
  } = useSearchNavigation();
  const { navigateToSearchApproval } = useApprovalNavigation();
  const { navigateToScheduledJobs } = useScheduledJobNavigation();
  const licenceApi = useLicenceApi();
  const { navigateToCreateInernalUser, navigateToSearchUserToMaintain } = useUserNavigation();
  const { navigateToLicenceTransferRequest } = useLicenceTransferRequestNavigation();
  const { navigateToCreateCertificate, navigateToCreateCallsign } = useCertificateNavigation();
  const investigationRouter = useInvestigationRouter();
  const { navigateToCreateAton } = useAtonNavigation();
  const { navigateToWelcomeTextMaintenance } = useWelcomeTextNavigation();
  return [
    {
      key: 1,
      text: 'Licences',
      shortcutName: 'Search - Licences',
      show: true,
      onClick: () => navigateToSearchLicence(),
    },
    {
      key: 2,
      text: 'Management Rights',
      shortcutName: 'Search - Management Rights',
      show: true,
      onClick: () => navigateToSearchManagementRights(),
    },
    {
      key: 3,
      text: 'Clients',
      shortcutName: 'Search - Clients',
      show: (hasPermission('SEARCH_ALL_CLIENT') || hasPermission('SEARCH_ACTIVE_CLIENT')) && !hasRole('ROLE_RIGHT_HOLDER'),
      onClick: () => navigateToSearchClient(),
    },
    {
      key: 4,
      text: 'Licences to Supply Radio Transmitters',
      shortcutName: 'Search - Licences to Supply Radio Transmitters',
      show: true,
      onClick: () => navigateToSearchLicenceToSupply(),
    },
    {
      key: 5,
      text: 'Certificates',
      shortcutName: 'Search - Certificates',
      show: true,
      onClick: () => navigateToSearchCertificate(),
    },
    {
      key: 6,
      text: 'Licence',
      shortcutName: 'Apply/Create - Licence',
      show: hasPermission('CREATE_APPLICATION'),
      onClick: () => navigateToApplyLicence(),
    },
    {
      key: 7,
      text: 'Client',
      shortcutName: 'Apply/Create - Client',
      show: hasPermission('CREATE_CLIENT') || hasPermission('CREATE_RESTRICTED_ORG_CLIENT'),
      onClick: () => navigateToCreateClient(),
    },
    {
      key: 8,
      text: 'AtoN',
      shortcutName: 'Apply/Create - AtoN',
      show: hasPermission('CREATE_ATON'),
      onClick: () => navigateToCreateAton(),
    },
    {
      key: 9,
      text: 'Callsign',
      shortcutName: 'Apply/Create - Callsign',
      show: hasPermission('CREATE_CALLSIGN'),
      onClick: () => navigateToCreateCallsign(),
    },
    {
      key: 10,
      text: 'Certificate',
      shortcutName: 'Apply/Create - Certificate',
      show: hasPermission('CREATE_CERTIFICATE'),
      onClick: () => navigateToCreateCertificate(),
    },
    {
      key: 11,
      text: 'Licence to Supply Radio Transmitters',
      shortcutName: 'Apply/Create - Licence to Supply Radio Transmitters',
      show: hasPermission('CREATE_LICENCE_TO_SUPPLY'),
      onClick: () => navigateToApplyLicenceToSupply(),
    },
    {
      key: 12,
      text: 'Internal User',
      shortcutName: 'Apply/Create - Internal User',
      show: hasPermission('CREATE_INT_USER'),
      onClick: () => navigateToCreateInernalUser(),
    },
    {
      key: 13,
      text: 'Register new client',
      shortcutName: 'Apply/Create - Register new client',
      show: hasPermission('SELF_REG_CLIENT') || isCasualUser(),
      onClick: () => navigateToSelfRegisterClient(),
    },
    {
      key: 14,
      text: 'Management right',
      shortcutName: 'Apply/Create - Management right',
      show: hasRole('ROLE_RSM_RADIO_ENGINEER'),
      onClick: () => navigateToApplyManagementRight(),
    },
    {
      key: 15,
      text: 'Cancel',
      shortcutName: 'Maintain - Licence - Cancel',
      show: hasPermission('CANCEL_LICENCE'),
      onClick: () => navigateToCancelLicence(),
    },
    {
      key: 16,
      text: 'Modify',
      shortcutName: 'Maintain - Licence - Modify',
      show: hasPermission('CREATE_APPLICATION'),
      onClick: () => navigateToModifyLicence(),
    },
    {
      key: 17,
      text: 'View All Transactions',
      shortcutName: 'Pay Fees - View All Transactions',
      show: isInternalUser!,
      onClick: () =>
        licenceApi
          .searchPaymentTransactions({ returnUrl: `${window.location.origin}/ui/app/dashboard` })
          .then((result) => window.open(result.redirectUrl, '_self')),
    },
    {
      key: 18,
      text: `View  ${currentClientName} Transactions`,
      shortcutName: `Pay Fees - View  ${currentClientName} Transactions`,
      show: currentClientId !== undefined,
      onClick: () =>
        licenceApi
          .searchPaymentTransactions({ clientId: currentClientId, returnUrl: `${window.location.origin}/ui/app/dashboard` })
          .then((result) => window.open(result.redirectUrl, '_self')),
    },
    {
      key: 19,
      text: 'Antenna',
      shortcutName: 'Reference Data - Antenna',
      show: hasPermission('VIEW_ANTENNA'),
      onClick: () => navigateToSearchAntenna(),
    },
    {
      key: 20,
      text: 'Equipment',
      shortcutName: 'Reference Data - Equipment',
      show: hasPermission('VIEW_EQUIPMENT'),
      onClick: () => navigateToSearchEquipment(),
    },
    {
      key: 21,
      text: 'Location',
      shortcutName: 'Reference Data - Location',
      show: hasPermission('VIEW_LOCATION'),
      onClick: () => navigateToSearchLocation(),
    },
    {
      key: 22,
      text: 'Spectrum',
      shortcutName: 'Reference Data - Spectrum',
      show: hasPermission('VIEW_SPEC_RECORD'),
      onClick: () => navigateToSearchSpectrum(),
    },
    {
      key: 23,
      text: 'Spectrum Mask',
      shortcutName: 'Reference Data - Spectrum Mask',
      show: hasPermission('VIEW_SPECTRUM_MASK'),
      onClick: () => navigateToSearchSpectrumMask(),
    },
    {
      key: 24,
      text: 'Complaint',
      shortcutName: 'Investigations - Complaint',
      show: true,
      onClick: () => investigationRouter.navigateToCreateComplaint(),
    },
    {
      key: 25,
      text: 'Search',
      shortcutName: 'Investigations - Search',
      show: !!hasAnyPermission(['VIEW_AUDIT', 'VIEW_COMPLAINT', 'SEARCH_COMPLAINT']),
      onClick: () => investigationRouter.navigateToSeachCases(),
    },

    {
      key: 26,
      text: 'Maintain Approval List',
      shortcutName: 'RSM - Admin - Maintain Approval List',
      show: hasPermission('VIEW_APPROVAL'),
      onClick: () => navigateToSearchApproval(),
    },
    {
      key: 27,
      text: 'Camunda Cockpit',
      shortcutName: 'RSM - Admin - Camunda Cockpit',
      show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
      onClick: () => window.open('/camunda/app/cockpit/', '_blank')?.focus(),
    },
    {
      key: 28,
      text: 'Manage Scheduled Jobs',
      shortcutName: 'RSM - Admin - Manage Scheduled Jobs',
      show: hasPermission('VIEW_DAY_END_JOB'),
      onClick: () => navigateToScheduledJobs(),
    },
    {
      key: 29,
      text: 'Callsigns',
      shortcutName: 'Search - Callsigns',
      show: true,
      onClick: () => navigateToSearchCallsign(),
    },
    {
      key: 30,
      text: 'Form 4',
      shortcutName: 'Management right - Form 4',
      show: hasRole('ROLE_PRIVATE_BAND_MANAGER') || hasRole('ROLE_CROWN_BAND_MANAGER'),
      onClick: () => navigateToFormFour(),
    },
    {
      key: 31,
      text: 'Transfer',
      shortcutName: 'Maintain - Licence - Transfer',
      show: hasPermission('CREATE_LICENCE_TRANSFER_REQUEST') && !(hasRole('ROLE_PRIVATE_BAND_MANAGER') || hasRole('ROLE_CROWN_BAND_MANAGER')),
      onClick: () => navigateToLicenceTransferRequest(),
    },
    {
      key: 32,
      text: 'NZ Grid Reference Converter',
      shortcutName: 'Search - NZ Grid Reference Converter',
      show: true,
      onClick: () => navigateToGeoReferenceConverter(),
    },
    {
      key: 33,
      text: 'AtoNs',
      shortcutName: 'Search - AtoNs',
      show: true,
      onClick: () => navigateToSearchAton(),
    },
    {
      key: 34,
      text: 'Maintain User Account',
      shortcutName: 'Maintain - User Account',
      show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
      onClick: () => navigateToSearchUserToMaintain(),
    },
    {
      key: 35,
      text: 'Welcome Text Maintenance',
      shortcutName: 'RSM - Admin - Welcome Text Maintenance',
      show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
      onClick: () => navigateToWelcomeTextMaintenance(),
    },
  ];
};
