import { mergeStyles } from '@fluentui/react';
import * as React from 'react';

import { geoRefDescFromType } from 'licence_management/reference_data/location/locationSupportData';

import '../licence/search_map.css';

interface Props {
  count: number;
  index: number;
  border: boolean;
  showLink: boolean;
  showCount: boolean;
  data: any | undefined;
}

const borderOn = mergeStyles({
  border: '1px solid black',
  borderRadius: '4px',
  padding: '5px',
  marginTop: '5px',
});

const borderOff = mergeStyles({
  border: 'none',
});

const MapLocationDetailCard: React.FC<Props> = (props) => {
  const cardClass = props.border ? borderOn : borderOff;
  return (
    <div className={cardClass}>
      <div className="rmap-lic-popup-grid">
        <div>Id</div>
        <div>
          {props.showLink ? <a href={`/ui/app/licence/reference/location/maintain/${props.data.LocId}`}>{props.data.LocId}</a> : props.data.LocId}
        </div>
        <div>Name</div>
        <div>{props.data.locName}</div>
        <div>{geoRefDescFromType(props.data.locGeorefType)}</div>
        <div>
          {props.data.locMap} &nbsp;{props.data.locNorthing} &nbsp;{props.data.locEasting}
        </div>
        <div>Altitude</div>
        <div>{props.data.locAltitude}</div>
        <div>Description</div>
        <div>{props.data.locDesc}</div>
      </div>
      {props.showCount && (
        <div style={{ color: 'red' }}>
          <b>
            {props.index + 1} of {props.count}
          </b>
          &nbsp; click pin to select
        </div>
      )}
      {!props.showLink && !props.showCount && <div style={{ color: 'red' }}>click pin for details</div>}
    </div>
  );
};

export default MapLocationDetailCard;
