import React, { useEffect, useState } from 'react';

import { CaseStatusEnum, CaseTypeCodeEnum, CaseTypeGroupEnum, FileNoteDtoEntityTypeEnum } from 'api-client';

import { Form } from 'ui-library';
import { AdditionalFormButton } from 'ui-library/form/FormButtons';
import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { hasAction } from 'common/api/hateosUtils';
import { usePageNavigator } from 'common/auth/AppRoute';
import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import { CASE_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { AuditFormProps } from 'investigation/interfaces';

import AuditFormContent from './components/AuditFormContent';
import AuditRecommendationDialog from './components/AuditRecommendationDialog';
import { PRODUCT_OR_SUPPLIER_AUDIT, useAuditFormState } from './hooks';

const AuditRecommendationForm = () => {
  const { hasPermission } = useAuth();
  const { caseDto, formState } = useAuditFormState();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { navigateTo } = usePageNavigator();

  useEffect(() => {
    if (caseDto?.id && caseDto.type?.group !== CaseTypeGroupEnum.AUDIT) {
      navigateTo('/not-found');
    }
  }, [caseDto]);

  const caseType = formState.caseTypeOptions?.find(({ key }) => key === formState.caseTypeId);
  const caseTypeCode = caseType?.data?.code;
  const isRecommendationRequired = caseTypeCode && ![CaseTypeCodeEnum.ENGINEER, CaseTypeCodeEnum.LICENCE_ENGINEERING].includes(caseTypeCode);
  const isOffencesRequired = !!(caseTypeCode && [...PRODUCT_OR_SUPPLIER_AUDIT, CaseTypeCodeEnum.LICENCE].includes(caseTypeCode));
  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  const additionalButtons: AdditionalFormButton[] =
    caseDto.status === CaseStatusEnum.AUDIT_CPL && isRecommendationRequired && hasAction('make-recommendation', caseDto.links)
      ? [
          {
            id: 'auditRecommendation',
            isPrimary: true,
            onClick: () => {
              setIsDialogOpen(true);
            },
            showModes: ['VIEW'],
            text: 'Recommendation',
          },
        ]
      : [];

  return (
    <>
      {isRecommendationRequired && (
        <AuditRecommendationDialog
          caseDetails={{
            caseNumber: formState?.caseNumber,
            caseType: caseType?.text,
            caseTypeCode: caseType?.data.code,
            clientType: formState.client?.type,
            emcAuditType: formState.emcAuditType,
            isOffencesRequired,
            radioAuditType: formState.radioAuditType,
            startDate: formState.startDate,
          }}
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
        />
      )}
      <Form<AuditFormProps>
        formButtonsOptions={{
          additionalButtons,
        }}
        id="audit-recommendation-form"
        initialValues={formState}
        showFormButtonsBottom
      >
        <AuditFormContent />

        {formState?.caseNumber && canViewFileNote && (
          <FileNoteSection entityId={formState.caseNumber} entityType={FileNoteDtoEntityTypeEnum.CASE} noteTypes={CASE_FILE_NOTE_TYPE} />
        )}
        {formState?.caseNumber && <EventLogSection entityName={'CASE'} entityId={+formState.caseNumber} />}
      </Form>
    </>
  );
};

const AuditRecommendationPage = () => {
  return (
    <Page title="Audit Recommendation">
      <AuditRecommendationForm />
    </Page>
  );
};

export { AuditRecommendationPage };
