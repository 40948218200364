import { Formik } from 'formik';
import React from 'react';

import { HeightUnitEnum } from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import { getPreferenceGeoReferenceType } from 'common/user/components/UserPreference';
import { isNotEmpty } from 'common/validation/yupUtils';
import { parseQueryString } from 'search/utils';

import Form from '../../../../common/form/Form';
import Page from '../../../../common/layout/Page';
import { validateWithFormValues } from '../../../../common/validation/validationWithFormValues';
import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../../ReferenceDataRouter';
import PointFields from '../components/PointFields';
import { createPointSchema } from '../validationSchema';

const CreatePointPage = () => {
  const { createPoint } = useReferenceDataHookApi();

  const { navigateToMaintainLocation } = useLicenceReferenceDataNavigator();

  const qInitialValue = parseQueryString({});

  const { userPreference } = useAuth();
  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  const initValue = {
    locationName: '',
    heightUom: HeightUnitEnum.M,
    height: undefined,
    source: undefined,
    georeferenceType: isNotEmpty(qInitialValue.type) ? qInitialValue.type : geoRefTypeUserPreference,
    georeference: {
      origin: {
        type: isNotEmpty(qInitialValue.type) ? qInitialValue.type : geoRefTypeUserPreference,
        original: true,
        northing: qInitialValue.northing || '',
        easting: qInitialValue.easting || '',
        mapNumber: qInitialValue.mapNumber || '',
        order: 0,
      },
    },
  };

  return (
    <Page title="Create Location">
      <Formik
        initialValues={initValue}
        onSubmit={(value: any) => {
          return createPoint(
            {
              ...value,
              georeference: {
                origin: {
                  ...value.georeference.origin,
                  type: value.georeferenceType,
                },
              },
            },
            {
              successMessage: 'Location has been created.',
            },
          ).then((v) => {
            navigateToMaintainLocation(v.id!);
          });
        }}
        validate={validateWithFormValues(createPointSchema, {})}
      >
        <Form id={'create-point-form'} showFormButtonsBottom mode={'CREATE'}>
          <PointFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreatePointPage;
