import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { PagingCriteria } from 'api-client';

import ProtectedRoute from 'common/auth/ProtectedRoute';
import { stringifyQueryObject } from 'search/utils';

const JobExecutionPage = React.lazy(() => import('./JobExecutionPage'));
const ScheduledJobPage = React.lazy(() => import('./ScheduledJobPage'));
const ScheduledJobsPage = React.lazy(() => import('./ScheduledJobsPage'));

const ScheduledJobRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/job-execution/:jobId`} component={JobExecutionPage} permission={['VIEW_DAY_END_JOB']} />
        <ProtectedRoute path={`${path}/scheduled-jobs/:jobName`} component={ScheduledJobPage} permission={['VIEW_DAY_END_JOB']} />
        <ProtectedRoute path={`${path}/scheduled-jobs`} component={ScheduledJobsPage} permission={['VIEW_DAY_END_JOB']} />
      </Switch>
    </>
  );
};

export const useScheduledJobNavigation = () => {
  const history = useHistory();

  return {
    navigateToJob: (jobId: number) => history.push(`/ui/app/scheduled-job/job-execution/${jobId}`),
    navigateToScheduledJob: (jobName: string, criteria?: PagingCriteria, replace?: boolean) =>
      replace
        ? history.replace(`/ui/app/scheduled-job/scheduled-jobs/${jobName}?${stringifyQueryObject(criteria)}`)
        : history.push(`/ui/app/scheduled-job/scheduled-jobs/${jobName}?${stringifyQueryObject(criteria)}`),
    navigateToScheduledJobs: () => history.push('/ui/app/scheduled-job/scheduled-jobs'),
  };
};

export default ScheduledJobRouter;
