import { IDropdownOption } from '@fluentui/react';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StringSchema } from 'yup';

import {
  AuditFurtherActionEnum,
  AuditRecommendationEnum,
  BaseCaseDto,
  CamundaGroupDto,
  CamundaUserDto,
  CaseClassificationDto,
  CaseDto,
  CaseFinalResultEnum,
  CaseOwnerTypeEnum,
  CaseQuestionAnswerDto,
  CaseQuestionDto,
  CaseSearchResult,
  CaseStatusEnum,
  CaseTypeCodeEnum,
  CaseTypeDto,
  CaseTypeGroupEnum,
  ClientSearchResult,
  CloseCaseDto,
  CreateCaseDto,
  EmcStandardDto,
  EventCriteria,
  EventResults,
  LicenceSearchResult,
  MaintainCaseDto,
  OffenceDto,
  OffenceDtoCategoryEnum,
  QuestionInputTypeEnum,
  ServiceOfInterestDto,
  SourceCodeDto,
} from 'api-client';

import { DropdownOption } from 'ui-library/dropdown';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { camundaGroupApi, camundaUserApi, caseManagementApi, investigationApi } from 'common/api/api';
import { useAuth } from 'common/auth/AuthHook';
import {
  AUDIT_FURTHER_ACTION_OPTIONS,
  AUDIT_RECOMMENDATION_OPTIONS,
  AUDIT_RESULT_OPTIONS,
  CASE_STATUS,
  COMPLAINT_RESULT_OPTIONS,
} from 'common/reference/referenceData';
import { AssociatedToByEnum } from 'investigation/components/CaseAssociatedTo';
import { AuditFormProps, ComplaintFormProps } from 'investigation/interfaces';
import { useSearchApi } from 'search/SearchApiHooks';

import { useLicenceApi } from '../../licence_management/LicenceApiHooks';

export const REQUIRED_FIELDS = ['caseTypeId', 'caseClassificationId', 'associatedToBy'];
export const REQUIRED_MESSAGE = 'Required';

export const isRequiredString = (yupString: StringSchema, message = REQUIRED_MESSAGE) => yupString.required(message);
export const isOptionalString = (yupString: StringSchema) => yupString.optional();

export const convertToAssociatedToByEnum = (value: string | undefined): AssociatedToByEnum => {
  if (value === 'CL') {
    return AssociatedToByEnum.CLIENT;
  } else if (value === 'LI') {
    return AssociatedToByEnum.LICENCE;
  } else if (value === 'LA') {
    return AssociatedToByEnum.LA;
  } else if (value === 'MR') {
    return AssociatedToByEnum.MR;
  }
  return value as AssociatedToByEnum;
};

export const convertFurtherAction = (status?: AuditFurtherActionEnum): string => {
  return AUDIT_FURTHER_ACTION_OPTIONS.find(({ key }) => key === status)?.text || '';
};

export const convertRecommendation = (recommendation?: AuditRecommendationEnum): string => {
  return AUDIT_RECOMMENDATION_OPTIONS.find(({ key }) => key === recommendation)?.text || '';
};

export const convertResult = (status?: CaseFinalResultEnum): string => {
  return [...AUDIT_RESULT_OPTIONS, ...COMPLAINT_RESULT_OPTIONS].find(({ key }) => key === status)?.text || '';
};

export const convertStatus = (status?: CaseStatusEnum): string => {
  return CASE_STATUS.find(({ key }) => key === status)?.text || '';
};

export const convertAssignee = (assigneeId: number, assigneeOptions: IDropdownOption<CamundaUserDto>[]): string => {
  return assigneeOptions.find((s) => s.key === assigneeId)?.text || '';
};

export const useCamundaGroupOptions = ({ isForComplaint }: { isForComplaint?: boolean } = { isForComplaint: false }) => {
  const { getAllocatedToGroups } = useInvestigationApi();
  const [camundaGroupOptions, setCamundaGroupOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    getAllocatedToGroups().then((groups) =>
      setCamundaGroupOptions(
        groups
          .filter(({ id }) => !isForComplaint || !['internal-engineers', 'licensing'].includes(id))
          .map(({ id = '', name = '', ...otherProps }) => ({
            key: id,
            text: name,
            ...otherProps,
          })),
      ),
    );
  }, []);

  return { camundaGroupOptions };
};

export const useCaseAssociatedTo = (associatedToBy: AssociatedToByEnum | undefined, params: { entityId?: string }) => {
  const { searchClient, searchLicence } = useSearchApi();
  const { getFrequency } = useLicenceApi();

  const [client, setClient] = useState<ClientSearchResult>();
  const [licence, setLicence] = useState<LicenceSearchResult>();
  const [emission, setEmission] = useState<string>();

  useEffect(() => {
    if (associatedToBy && params) {
      (async () => {
        if (associatedToBy === AssociatedToByEnum.CLIENT) {
          const { results } = await searchClient({ searchText: params.entityId });
          setClient(results && results[0]);
        } else if (associatedToBy === AssociatedToByEnum.LICENCE) {
          if (params.entityId) {
            const { results } = await searchLicence({ licenceIds: [+params.entityId] });
            setLicence(results && results[0]);

            if (results && results[0]?.frequencyId) {
              const frequency = await getFrequency(results[0].frequencyId);
              setEmission(frequency.frequencyEmissions?.map((em) => em.emission).join(' '));
            }
          }
        }
      })();
    }
  }, [associatedToBy]);

  return { client, licence, emission };
};

export const useCaseClassificationOptions = (caseTypeId?: number) => {
  const { getCaseClassificationsByCaseType } = useInvestigationApi();
  const [caseClassificationOptions, setCaseClassificationOptions] = useState<DropdownOption<CaseClassificationDto>[]>([]);

  useEffect(() => {
    if (caseTypeId) {
      (async () => {
        const classifications = await getCaseClassificationsByCaseType(caseTypeId);
        setCaseClassificationOptions(
          classifications.map((classification) => ({
            key: classification.id || 0,
            text: classification.name || '',
            data: classification,
          })),
        );
      })();
    }
  }, [caseTypeId]);

  return { caseClassificationOptions };
};

export const useCaseSearchResultParser = () => {
  return (result: CaseSearchResult) => {
    const status = convertStatus(result.status as CaseStatusEnum);
    return {
      key: `${result.id} | ${result.type} | ${result.clientName} | ${status} | ${result.result}`,
      name: `${result.id} | ${result.type} | ${result.clientName} | ${status} | ${result.result}`,
      data: result,
    };
  };
};

export const useCaseTypeOptions = (group?: CaseTypeGroupEnum, includeInactive?: boolean) => {
  const { getCaseTypes } = useInvestigationApi();
  const [caseTypeOptions, setCaseTypeOptions] = useState<DropdownOption<CaseTypeDto>[]>([]);

  useEffect(() => {
    (async () => {
      const caseTypes = await getCaseTypes(group || CaseTypeGroupEnum.COMPLAINT, includeInactive);
      setCaseTypeOptions(
        caseTypes.map((caseType) => ({
          key: caseType.id || 0,
          text: caseType.name || '',
          data: caseType,
        })),
      );
    })();
  }, []);

  return { caseTypeOptions };
};

export const useCreateCase = () => {
  const { createCase: createCaseApi } = useInvestigationApi();
  const convertToDto = useDtoConverter<CreateCaseDto>();

  const createCase = (values: AuditFormProps | ComplaintFormProps) => {
    const caseDto = convertToDto(values);

    return createCaseApi(caseDto, {
      callerHandleErrors: true,
      showLoadingSpinner: false,
    });
  };

  return { createCase };
};

export const useDtoConverter = <T extends unknown>() => {
  return (values: AuditFormProps | ComplaintFormProps): T => {
    let answers: CaseQuestionAnswerDto[] = [];

    const isDomesticCase = values.caseTypeId === values.caseTypeOptions.find((option) => CaseTypeCodeEnum.DOMESTIC === option.data.code)?.data.id;

    if (isDomesticCase && Object.keys(values.answers).length) {
      answers = values.caseQuestions
        ?.filter(({ id }) => values.answers[`question_${id}`]?.answer)
        .map(({ id, inputType }) => {
          let answer = values.answers[`question_${id}`].answer;

          if (QuestionInputTypeEnum.ADDRESS_PICKER === inputType) {
            answer = JSON.stringify(answer.manual ? answer : answer.data);
          }

          return {
            questionId: id,
            answer,
            id: values.answers[`question_${id}`].answerId,
          };
        });
    }

    const clientOrBusinessAssociatedNo = values.associatedToBy === AssociatedToByEnum.CLIENT ? `${values.client?.id}` : `${values.licence?.id}`;
    const isAllocatedToGroup = !values.allocatedToUser;

    const baseCaseDto: BaseCaseDto = {
      associatedTo: values.associatedToBy,
      camundaGroupId: values.allocatedTo || '',
      camundaUserId: !isAllocatedToGroup && values.allocatedToUser ? (values.allocatedToUser as number) : undefined,
      clientName: values.client?.clientName || values.licence?.licensee || '',
      clientOrBusinessAssociatedNo,
      notes: values.notes,
      ownerType: isAllocatedToGroup ? CaseOwnerTypeEnum.CAMUNDA_GROUP : CaseOwnerTypeEnum.CAMUNDA_USER,
      originCaseId: values.originCase?.id,
      tags: (values as AuditFormProps).tags,
    };

    if (answers.length) {
      baseCaseDto.answers = answers;
    }

    if (!values.caseNumber) {
      const caseDto: CreateCaseDto = {
        ...baseCaseDto,
        caseDiscriminator: 'CreateCaseDto',
        classificationId: values.caseClassificationId || undefined,
        typeId: values.caseTypeId || 0,
      };

      return caseDto as T;
    } else {
      const caseDto: MaintainCaseDto = {
        ...baseCaseDto,
        id: values.caseNumber,
        caseDiscriminator: 'MaintainCaseDto',
        classificationId: values.caseClassificationId || undefined,
        emcStandardId: (values as AuditFormProps).emcStandardId,
        endDate: values.endDate,
        serviceOfInterestId: values.serviceOfInterestId,
        sourceCodeId: values.sourceCodeId,
        startDate: values.startDate,
        typeId: values.caseTypeId || 0,
      };

      return caseDto as T;
    }
  };
};

export const useCurrentUserGroups = () => {
  const { hasAnyPermission, currentRole } = useAuth();
  const { getMyGroups } = useInvestigationApi();

  const [currentUserGroups, setCurrentUserGroups] = useState<CamundaGroupDto[]>([]);

  useEffect(() => {
    if (hasAnyPermission(['CREATE_AUDIT', 'CREATE_COMPLAINT'])) {
      getMyGroups().then((groups) => setCurrentUserGroups(groups));
    }
  }, []);

  let currentRadioInvestigatorGroup;
  switch (currentRole) {
    case 'ROLE_SENIOR_RADIO_INVESTIGATOR':
      currentRadioInvestigatorGroup = currentUserGroups?.find((group) => group.id === 'investigators-senior-radio');
      break;
    case 'ROLE_INVESTIGATIONS_MANAGER':
      currentRadioInvestigatorGroup = currentUserGroups?.find((group) => group.id === 'investigators-manager');
      break;
    case 'ROLE_RADIO_INVESTIGATOR':
      currentRadioInvestigatorGroup = currentUserGroups?.find((group) => group.id.match(/^investigators/s) !== null);
      break;
    case 'ROLE_LICENSING_MANAGER':
    case 'ROLE_LICENSING_OFFICER':
      currentRadioInvestigatorGroup = currentUserGroups?.find((group) => 'licensing' === group.id);
      break;
    default:
      currentRadioInvestigatorGroup = currentUserGroups?.find((group) => group.id.match(/^investigators/s) !== null || 'licensing' === group.id);
      break;
  }

  return { currentUserGroups, currentRadioInvestigatorGroup };
};

export const useEmcStandardOptions = () => {
  const { getEmcStandards } = useInvestigationApi();
  const [emcStandardOptions, setEmcStandardOptions] = useState<DropdownOption<EmcStandardDto>[]>([]);

  useEffect(() => {
    (async () => {
      const emcStandards = await getEmcStandards();
      setEmcStandardOptions(
        emcStandards.map((emcStandard) => ({
          key: emcStandard.id,
          text: emcStandard.description,
          data: emcStandard,
        })),
      );
    })();
  }, []);

  return emcStandardOptions;
};

export const useInvestigationApi = () => {
  const { aroundApi } = useApi();

  const defaultApiConfig: ApiConfig = {
    callerHandleErrors: true,
    disableErrorMessage: false,
    showLoadingSpinner: false,
  };

  return {
    closeCase: (closeCaseDto: CloseCaseDto, config = defaultApiConfig): Promise<CaseDto> =>
      aroundApi(caseManagementApi.closeCase(closeCaseDto), config),
    createCase: (caseDto: CreateCaseDto, config: ApiConfig): Promise<CaseDto> =>
      aroundApi(caseManagementApi.createCase({ ...caseDto, caseDiscriminator: 'CreateCaseDto' }), config),
    getAllocatedToGroups: (): Promise<CamundaGroupDto[]> => aroundApi(camundaGroupApi.getInvestigationGroups()),
    getCaseById: (id: number): Promise<CaseDto> => aroundApi(caseManagementApi.getCaseById(id)),
    getCaseClassificationsByCaseType: (caseTypeId: number): Promise<CaseClassificationDto[]> =>
      aroundApi(caseManagementApi.getCaseClassificationsByCaseType(caseTypeId)),
    getCaseQuestions: (): Promise<CaseQuestionDto[]> => aroundApi(caseManagementApi.getCaseQuestions()),
    getCaseTypes: (caseTypeGroup: CaseTypeGroupEnum, includeInactive?: boolean): Promise<CaseTypeDto[]> =>
      aroundApi(caseManagementApi.getCaseTypes(caseTypeGroup, includeInactive)),
    getEmcStandards: (): Promise<EmcStandardDto[]> => aroundApi(investigationApi.getEmcStandards()),
    getCaseEvents: (id: number, eventCriteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(caseManagementApi.getCaseEvents(id, eventCriteria), config),
    getMyGroups: (): Promise<CamundaGroupDto[]> => aroundApi(camundaGroupApi.getCurrentUserGroups()),
    getOffences: (): Promise<OffenceDto[]> => aroundApi(investigationApi.getOffences()),
    getRadioInvestigators: (groupId: string): Promise<CamundaUserDto[]> => aroundApi(camundaUserApi.getUsersByCamundaGroup(groupId)),
    getCamundaUsers: (groupIds: string[]): Promise<CamundaUserDto[]> => aroundApi(camundaUserApi.getUsersByCamundaGroups(groupIds)),
    getServiceOfInterests: (includeInactive?: boolean): Promise<ServiceOfInterestDto[]> =>
      aroundApi(investigationApi.getServiceOfInterests(includeInactive)),
    getSourceCodes: (): Promise<SourceCodeDto[]> => aroundApi(investigationApi.getSourceCodes()),
    maintainCase: (caseId: number, caseDto: MaintainCaseDto, config: ApiConfig): Promise<CaseDto> =>
      aroundApi(caseManagementApi.maintainCase(caseId, { ...caseDto, caseDiscriminator: 'MaintainCaseDto' }), config),
  };
};

export const useMaintainCase = () => {
  const { maintainCase: maintainCaseApi } = useInvestigationApi();
  const convertToDto = useDtoConverter<MaintainCaseDto>();

  const maintainCase = (values: AuditFormProps | ComplaintFormProps) => {
    const caseDto = convertToDto(values);
    return maintainCaseApi(values.caseNumber, caseDto, {
      callerHandleErrors: true,
      showLoadingSpinner: false,
    });
  };

  return { maintainCase };
};

export const useOffencesByCategory = () => {
  const { getOffences } = useInvestigationApi();

  const [offences, setOffences] = useState<{
    [OffenceDtoCategoryEnum.EMC_AUDIT]?: OffenceDto[];
    [OffenceDtoCategoryEnum.LICENCE_CONDITION]?: OffenceDto[];
    [OffenceDtoCategoryEnum.RADIO_AUDIT]?: OffenceDto[];
  }>({});

  useEffect(() => {
    (async () => {
      const offences = await getOffences();
      const offencesByCategory = offences.reduce((output, current) => {
        if (!current.category) {
          return output;
        }

        const key = current.category;
        const items = output[key] || [];

        return {
          ...output,
          [key]: [...items, current],
        };
      }, {} as any);

      setOffences(offencesByCategory);
    })();
  }, []);

  return offences;
};

export const useOriginCase = (originCaseId: number | undefined) => {
  const { searchCase } = useSearchApi();
  const [originCase, setOriginCase] = useState<CaseSearchResult>();

  useEffect(() => {
    if (originCaseId) {
      searchCase({
        searchText: `${originCaseId}`,
      }).then(({ results }) => {
        if (results?.length) {
          setOriginCase(results[0]);
        }
      });
    }
  }, [originCaseId]);

  return originCase;
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useRadioInvestigatorOptions = (allocatedTo?: string) => {
  const { getRadioInvestigators } = useInvestigationApi();
  const [radioInvestigatorOptions, setRadioInvestigatorOptions] = useState<IDropdownOption<CamundaUserDto>[]>([]);

  useEffect(() => {
    if (allocatedTo) {
      (async () => {
        const radioInvestigators = await getRadioInvestigators(allocatedTo);

        const radioInvestigatorOptions = radioInvestigators.map(({ id = 0, firstName = '', lastName }) => ({
          key: id,
          text: `${firstName} ${lastName}`,
        }));

        setRadioInvestigatorOptions([{ key: 0, text: '' }, ...radioInvestigatorOptions]);
      })();
    }
  }, [allocatedTo]);

  return { radioInvestigatorOptions };
};

export const useAssigneeOptions = () => {
  const { getCamundaUsers } = useInvestigationApi();
  const [assigneeOptions, setAssigneeOptions] = useState<IDropdownOption<CamundaUserDto>[]>([]);

  useEffect(() => {
    (async () => {
      const users = await getCamundaUsers([
        'investigators-auckland',
        'investigators-christchurch',
        'investigators-dunedin',
        'investigators-hamilton',
        'investigators-wellington',
        'investigators-senior-radio',
        'investigators-manager',
        'licensing',
      ]);

      const options = users.map(({ id = 0, firstName = '', lastName }) => ({
        key: id,
        text: `${firstName} ${lastName}`,
      }));

      setAssigneeOptions([{ key: 0, text: '' }, ...options]);
    })();
  }, []);

  return { assigneeOptions };
};

export const useServiceOfInterestOptions = () => {
  const { getServiceOfInterests } = useInvestigationApi();
  const [serviceOfInterestOptions, setServiceOfInterestOptions] = useState<DropdownOption<ServiceOfInterestDto>[]>([]);

  useEffect(() => {
    (async () => {
      const serviceOfInterests = await getServiceOfInterests();
      setServiceOfInterestOptions(
        serviceOfInterests.map((serviceOfInterest) => ({
          key: serviceOfInterest.id || '',
          text: serviceOfInterest.description || '',
          data: serviceOfInterest,
        })),
      );
    })();
  }, []);

  return serviceOfInterestOptions;
};

export const useSourceCodeOptions = () => {
  const { getSourceCodes } = useInvestigationApi();
  const [sourceCodeOptions, setSourceCodeOptions] = useState<DropdownOption<SourceCodeDto>[]>([]);

  useEffect(() => {
    (async () => {
      const sourceCodes = await getSourceCodes();
      setSourceCodeOptions(
        sourceCodes.map((sourceCode) => ({
          key: sourceCode.id || '',
          text: sourceCode.value || '',
          data: sourceCode,
        })),
      );
    })();
  }, []);

  return sourceCodeOptions;
};

export const useValidateForm = (caseTypeGroup: CaseTypeGroupEnum, caseQuestions: CaseQuestionDto[] = []) => {
  const { currentRole, hasPermission } = useAuth();
  const hasFullUpdatePermission = hasPermission('UPDATE_COMPLAINT');

  const validateForm = (values: AuditFormProps | ComplaintFormProps) => {
    const isClassificationOptional = (values as AuditFormProps).isClassificationOptional;

    const errors: { [key: string]: any } = REQUIRED_FIELDS.filter((fieldName) => !isClassificationOptional || fieldName !== 'caseClassificationId')
      .filter((fieldName) => !(values as any)[fieldName])
      .reduce(
        (output, fieldName) => ({
          ...output,
          [fieldName]: REQUIRED_MESSAGE,
        }),
        {},
      );

    if (hasFullUpdatePermission) {
      if (values.caseNumber) {
        if (values.startDate && values.endDate && values.startDate > values.endDate) {
          errors['endDate'] = `End date cannot be earlier than start date`;
        }
      }
    }

    if (!values.allocatedTo) {
      errors['allocatedTo'] = REQUIRED_MESSAGE;
    }

    const { associatedToBy, client, licence } = values;
    if ((associatedToBy === AssociatedToByEnum.CLIENT && !client?.id) || (associatedToBy === AssociatedToByEnum.LICENCE && !licence?.id)) {
      errors['associatedTo'] = REQUIRED_MESSAGE;
    }

    if (!values.allocatedToUser && currentRole !== 'ROLE_MSG_SERVICE_CENTRE' && values.caseNumber) {
      errors['allocatedToUser'] = REQUIRED_MESSAGE;
    }

    if (values.caseTypeId === values.caseTypeOptions.find((option) => option.data.code === CaseTypeCodeEnum.DOMESTIC)?.data.id) {
      const answers = values.answers || {};

      const unanswered = caseQuestions?.filter(({ id, inputType }) => {
        const answer = answers[`question_${id}`]?.answer;

        if (inputType === QuestionInputTypeEnum.ADDRESS_PICKER && answer?.manual) {
          // check if any of the manual address fields is still empty
          return (
            Object.keys(answer)
              .filter((key) => key !== 'manual')
              .reduce((output, key) => {
                return !answer[key] ? output + 1 : output;
              }, 0) > 0
          );
        }

        return !hasFullUpdatePermission && !answer;
      });

      if (unanswered?.length) {
        errors.answers = unanswered.reduce((output: any, { id, inputType }) => {
          if (QuestionInputTypeEnum.ADDRESS_PICKER !== inputType) {
            return {
              ...output,
              [`question_${id}`]: {
                answer: REQUIRED_MESSAGE,
              },
            };
          }

          const addressAnswer = answers[`question_${id}`]?.answer;

          if (addressAnswer?.manual) {
            const emptyManualAddressFields = ['cityTown', 'countryCode', 'postCode', 'streetName', 'suburb'].filter((key) => !addressAnswer[key]);

            if (emptyManualAddressFields?.length === 0) {
              return output;
            }

            return {
              ...output,
              [`question_${id}`]: {
                answer: emptyManualAddressFields.reduce((output, key) => {
                  return {
                    ...output,
                    [key]: REQUIRED_MESSAGE,
                  };
                }, {}),
              },
            };
          } else if (!hasFullUpdatePermission && isEmpty(addressAnswer)) {
            return {
              ...output,
              [`question_${id}`]: {
                answer: REQUIRED_MESSAGE,
              },
            };
          }

          return output;
        }, {});
      }
    }

    return errors;
  };

  return { validateForm };
};
