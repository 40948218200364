import { Link } from '@fluentui/react';
import React from 'react';

import { MAINTAIN_CLIENT_URL } from 'client_management/client/ClientRouter';
import { useAuth } from 'common/auth/AuthHook';
import { VIEW_CLIENT_URL } from 'public/PublicRouter';

import { usePublicApi } from '../../../public/PublicApiHook';
import { hasAction } from '../../api/hateosUtils';
import ReadonlyData from './ReadonlyData';

interface ClientLinkProps {
  clientId?: number;
  inline?: boolean;
  label?: string;
}

const ClientLink: React.FC<ClientLinkProps> = ({ clientId, inline, label }) => {
  const { getClientDetails } = usePublicApi();
  const { isInternalUser, loggedIn, hasAnyRole } = useAuth();
  const [hasExecutiveORDelegatedAuth, setHasExecutiveORDelegatedAuthe] = React.useState<boolean>(false);
  const isAreArcArx = hasAnyRole(['ROLE_APPROVED_RADIO_ENGINEER', 'ROLE_APPROVED_RADIO_CERTIFIER', 'ROLE_APPROVED_RADIO_EXAMINER']);

  React.useEffect(() => {
    if (!isInternalUser && loggedIn) {
      if (clientId != null) {
        getClientDetails(clientId).then((result: any) => {
          setHasExecutiveORDelegatedAuthe(hasAction('Maintain', result.links));
        });
      }
    }
  }, [clientId]);

  const href = `${isInternalUser || isAreArcArx || (loggedIn && hasExecutiveORDelegatedAuth) ? MAINTAIN_CLIENT_URL : VIEW_CLIENT_URL}${clientId}`;

  return inline ? <Link href={href}>{clientId}</Link> : <ReadonlyData label={label ?? 'Client number'} value={clientId} href={href} />;
};

export default ClientLink;
