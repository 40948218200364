import { MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { useState } from 'react';

import { BaseLocationDto, LocationSearchResult } from 'api-client';

import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import Card from 'common/controls/surfaces/Card';
import MoreOrLess from 'common/controls/surfaces/MoreOrLess';
import { FormMode } from 'common/form/Form';
import { useReferenceDataHookApi } from 'licence_management/reference_data/ReferenceDataApiHook';
import EmbeddedSearchLocation from 'search/location/EmbeddedSearchLocation';

import { LocationTypeOptionsEnum } from '../../../search/location/SearchLocationForm';
import AtonLocationList from './AtonLocationList';

interface AtonLocationSectionProps {
  onSelected: (item: LocationSearchResult) => void;
  initialSelected?: undefined | LocationSearchResult;
  mode: FormMode;
  onRemove: (item: LocationSearchResult) => void;
}

const AtonLocationSection = ({ onSelected, initialSelected, mode, onRemove }: AtonLocationSectionProps) => {
  const { getLocation } = useReferenceDataHookApi();

  const [location, setLocation] = useState<LocationSearchResult>(initialSelected!);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hideSearchPanel, setHideSearchPanel] = useState<boolean>(false);

  React.useEffect(() => setLocation(initialSelected!), [initialSelected]);

  const setSelected = (item: LocationSearchResult) => {
    getLocation(item?.id).then((locationDto: BaseLocationDto) => {
      setErrorMessage('');
      setLocation(item);
      onSelected(item);
      setHideSearchPanel(true);
    });
  };

  const handleRemove = (location: LocationSearchResult) => {
    if (location && location.id) {
      onRemove(location);
      setLocation(undefined!);
      setHideSearchPanel(false);
    }
  };

  const isEditable = mode !== 'VIEW';
  const canShowEmbededSearchPanel = location !== undefined;

  return (
    <>
      {errorMessage && (
        <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setErrorMessage('')}>
          {errorMessage}
        </MessageBar>
      )}
      <div style={{ marginTop: 5 }}>
        <Card>
          {!canShowEmbededSearchPanel && (
            <>
              <ErrorLabel name="locationId" showIcon />
              <MoreOrLess
                id="modify-location-search"
                key={(location as any as LocationSearchResult)?.id}
                initiallyMore={!location}
                moreText="Search"
                lessText="Hide Search"
                more={
                  <EmbeddedSearchLocation
                    onSelect={setSelected}
                    searchLabel="Search location"
                    searchOptions={{ locationTypeOptions: [LocationTypeOptionsEnum.POINT], hideVisibiltiy: true }}
                    defaultLocationType={'P'}
                  />
                }
              />
            </>
          )}
          {location && <AtonLocationList initialLocations={[location!]} onRemove={handleRemove} editable={isEditable} />}
        </Card>
      </div>
    </>
  );
};
export default AtonLocationSection;
