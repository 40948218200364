import React, { useEffect } from 'react';

import { LicenceRadiationPatternDto } from 'api-client';

import {
  CENTRE_Y,
  DEGREE_LINE_COLOUR,
  DEGREE_LINE_WIDTH,
  drawTooltip,
  getCanvasHeight,
  getPatternDescription,
  OUTER_CIRCLE_LINE_COLOUR,
  OUTER_CIRCLE_LINE_WIDTH,
  RADIUS,
  scaleCanvas,
  SECTOR_CIRCLE_LINE_COLOUR,
  SECTOR_CIRCLE_LINE_WIDTH,
  SECTOR_VALUE_FONT,
  SECTOR_VALUE_FONT_COLOUR,
  SECTOR_VALUE_LINE_COLOUR,
  SECTOR_VALUE_LINE_WIDTH,
  setupRadiationPatternChartVars,
  TEN_DEGREE_FONT,
  TEN_DEGREE_FONT_COLOUR,
  TEN_DEGREE_LINE_WIDTH,
} from './radiationPatternUtils';

interface VerticalRadiationPatternChartProps {
  radiationPatterns: LicenceRadiationPatternDto[];
}

const WIDTH = 300;
const CENTRE_X = 30;

const VerticalRadiationPatternChart: React.FC<VerticalRadiationPatternChartProps> = ({ radiationPatterns }) => {
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const tooltipCanvas = React.useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (radiationPatterns.length === 0) {
      return;
    }
    drawGraph();
  }, []);

  function drawGraph() {
    if (!canvas.current) {
      return;
    }

    const { numSectors, sectorValues, sectorRatios, ratios } = setupRadiationPatternChartVars(radiationPatterns);
    const currentCanvas = canvas.current as HTMLCanvasElement;
    const ctx = scaleCanvas(currentCanvas);

    //Outer Circle
    ctx.beginPath();
    ctx.strokeStyle = OUTER_CIRCLE_LINE_COLOUR;
    ctx.lineWidth = OUTER_CIRCLE_LINE_WIDTH;
    ctx.arc(CENTRE_X, CENTRE_Y, RADIUS, (3 * Math.PI) / 2, Math.PI / 2, false);
    ctx.closePath();
    ctx.stroke();

    //Degree Lines
    ctx.save();
    ctx.translate(CENTRE_X, CENTRE_Y);
    ctx.strokeStyle = DEGREE_LINE_COLOUR;
    for (let i = 0; i < 180; i++) {
      ctx.beginPath();
      ctx.moveTo(0, 0);
      if (i % 10 === 0) {
        ctx.lineWidth = TEN_DEGREE_LINE_WIDTH;
      } else {
        ctx.lineWidth = DEGREE_LINE_WIDTH;
      }
      ctx.lineTo(0, -RADIUS);
      ctx.stroke();
      ctx.rotate(Math.PI / 180);
    }
    ctx.restore();

    //Degree Text
    ctx.save();
    ctx.translate(CENTRE_X, CENTRE_Y);
    ctx.font = TEN_DEGREE_FONT;
    ctx.fillStyle = TEN_DEGREE_FONT_COLOUR;
    for (let i = 0; i <= 18; i++) {
      const label = +(i - 9) * -10;
      ctx.fillText(label.toString(), 0 - ctx.measureText(label.toString()).width / 2, (RADIUS + 10) * -1);
      ctx.rotate(Math.PI / 18);
    }
    ctx.restore();

    //Sector Circles
    ctx.save();
    for (let i = 0; i < numSectors; i++) {
      ctx.beginPath();
      ctx.strokeStyle = SECTOR_CIRCLE_LINE_COLOUR;
      ctx.lineWidth = SECTOR_CIRCLE_LINE_WIDTH;
      ctx.arc(CENTRE_X, CENTRE_Y, RADIUS * sectorRatios[i], (3 * Math.PI) / 2, Math.PI / 2, false);
      ctx.stroke();
    }
    ctx.restore();

    //Value Text
    ctx.font = SECTOR_VALUE_FONT;
    ctx.fillStyle = SECTOR_VALUE_FONT_COLOUR;
    for (let i = 0; i < numSectors; i++) {
      ctx.fillText(sectorValues[i].toString(), CENTRE_X - ctx.measureText(sectorValues[i].toString()).width - 5, CENTRE_Y - RADIUS * sectorRatios[i]);
    }

    //Value Arcs
    ctx.strokeStyle = SECTOR_VALUE_LINE_COLOUR;
    ctx.lineWidth = SECTOR_VALUE_LINE_WIDTH;
    ctx.translate(CENTRE_X, CENTRE_Y);
    ctx.rotate(Math.PI / 2);
    ctx.beginPath();
    radiationPatterns.forEach((radiationPattern, i) => {
      ctx.arc(
        0,
        0,
        RADIUS * ratios[i],
        (-radiationPattern.bearingFrom / 360) * Math.PI * 2 - Math.PI / 2,
        (-radiationPattern.bearingTo / 360) * Math.PI * 2 - Math.PI / 2,
        false,
      );
    });
    ctx.stroke();

    if (!tooltipCanvas?.current) {
      return;
    }
    const tooltipCtx = scaleCanvas(tooltipCanvas.current);
    tooltipCtx.translate(CENTRE_X, CENTRE_Y);
    tooltipCtx.rotate(Math.PI / 2);
    tooltipCanvas.current.addEventListener('mousemove', (event) => {
      // Check whether point is inside circle
      // Store the current transformation matrix
      tooltipCtx.save();
      // Use the identity matrix while clearing the canvas
      tooltipCtx.setTransform(1, 0, 0, 1, 0, 0);
      tooltipCtx.clearRect(0, 0, tooltipCanvas.current!.width, tooltipCanvas.current!.height);
      // Restore the transform
      tooltipCtx.restore();

      let i = 0;
      for (const radiationPattern of radiationPatterns) {
        tooltipCtx.beginPath();
        tooltipCtx.moveTo(0, 0);
        tooltipCtx.arc(
          0,
          0,
          RADIUS * ratios[i],
          (-radiationPattern.bearingFrom / 360) * Math.PI * 2 - Math.PI / 2,
          (-radiationPattern.bearingTo / 360) * Math.PI * 2 - Math.PI / 2,
          false,
        );
        const position = {
          x: event.offsetX * window.devicePixelRatio,
          y: event.offsetY * window.devicePixelRatio,
        };
        const isPointInPath = tooltipCtx.isPointInPath(position.x, position.y);
        if (isPointInPath) {
          tooltipCtx.fillStyle = SECTOR_VALUE_LINE_COLOUR;
          tooltipCtx.fill();
          drawTooltip(tooltipCtx, event.offsetX, event.offsetY, radiationPattern, WIDTH);
        }
        i++;
      }
    });
  }

  return (
    <div data-automation-id="verticalRadiationPatternChart" style={{ position: 'relative' }}>
      <canvas
        ref={canvas}
        height={getCanvasHeight()}
        width={WIDTH * window.devicePixelRatio}
        style={{ height: '500px', width: `${WIDTH}px` }}
        role="img"
        aria-label={`A circular chart displaying the radiation patterns bearing from and to, and their values. The values are: ${getPatternDescription(
          radiationPatterns,
        )}`}
      />
      <canvas
        ref={tooltipCanvas}
        height={getCanvasHeight()}
        width={WIDTH * window.devicePixelRatio}
        style={{ height: '500px', width: `${WIDTH}px`, position: 'absolute', left: 0 }}
        role="img"
        aria-label={`A canvas for displaying tooltips over the above chart.`}
      />
    </div>
  );
};

export default VerticalRadiationPatternChart;
