import { DefaultButton, IChoiceGroupOption, Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import * as React from 'react';
import { FileError } from 'react-dropzone';

import { DocumentDto, MmsiNumber, ShipCallsignRecordDtoEquipmentTypeEnum, ShipCallsignRecordDtoStatusEnum } from 'api-client';

import { Grid } from 'ui-library';
import FileViews from 'ui-library/shared/file_note/FileViews';

import { useCertificateApi } from 'certificate_management/certificate/CertificateApiHook';
import { ValidateCallsignContext } from 'certificate_management/certificate/components/CertificateCallSign';
import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import ChoiceGroup from 'common/controls/inputs/ChoiceGroup';
import { NumberField } from 'common/controls/inputs/NumberField';
import { TextField } from 'common/controls/inputs/TextField';
import ClientLink from 'common/controls/items/ClientLink';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import TooltipHint from 'common/controls/items/TooltipHint';
import DetailsList from 'common/controls/lists/DetailsList';
import { useForm } from 'common/form/FormHook';
import { SectionHeading, SubSectionHeading } from 'common/layout/SectionHeadings';
import { CBES_CLIENT, SHIP_CALLSIGN_TYPE } from 'common/reference/referenceData';
import FileUpload, { DrmDocument } from 'document_management/components/FileUploadRrf';

interface ShipCallsignProps {
  serviceType?: string;
}

const ShipCallsign: React.FC<ShipCallsignProps> = (props) => {
  const { values, setValues, setFieldValue, setErrors, setTouched } = useFormikContext<any>();
  const { callsignErrors, setCallsignErrors } = React.useContext(ValidateCallsignContext);
  const { mode } = useForm();
  const [existingFile, setExistingFile] = React.useState<DrmDocument[]>();

  React.useEffect(() => {
    if (values?.maritimeVesselSafetyDrmIds) {
      setExistingFile(values.maritimeVesselSafetyDrmIds.map((x: DocumentDto) => ({ drmId: x.drmId, name: x.fileName })));
    } else {
      setExistingFile(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.maritimeVesselSafetyDrmIds]);

  const { isInternalUser, currentRole, currentClientId } = useAuth();
  const { validateClientVesselName, validateShipCallsign, validateMMSI, validateSCN, getNextMaritimeCraftMMSI } = useCertificateApi();
  const formikContext = useFormikContext<any>();

  const handleFileRemoved = (file: DrmDocument): Promise<void> => {
    formikContext.setFieldValue(
      'maritimeVesselSafetyDrmIds',
      formikContext.values.maritimeVesselSafetyDrmIds.filter((x: DocumentDto) => x.drmId !== file.drmId),
    );
    return Promise.resolve();
  };

  const handleFileUploaded = (file: DrmDocument): Promise<void> => {
    if (formikContext.values.maritimeVesselSafetyDrmIds && formikContext.values.maritimeVesselSafetyDrmIds.length < 1) {
      formikContext.setFieldValue('maritimeVesselSafetyDrmIds', [{ drmId: file.drmId, name: file.name }]);
    } else {
      formikContext.setFieldValue('maritimeVesselSafetyDrmIds', [
        ...formikContext.values.maritimeVesselSafetyDrmIds,
        { drmId: file.drmId, name: file.name },
      ]);
    }
    return Promise.resolve();
  };

  const fileValidator = (file: File): FileError | null => {
    if (file.size > 100000 * 1024) {
      return {
        code: 'rrf-file-is-too-large',
        message: 'The file selected is too large. Please select a file that is no greater than 3MB.',
      };
    }
    return null;
  };

  const handleVesselNameOnBlur = (vesselName: string) => {
    if (!isNaN(values.clientId) && values.clientId !== '' && !_.isEmpty(vesselName)) {
      validateClientVesselName(
        { clientId: values.clientId, vesselName, shipCallsignRecordId: values.id },
        { callerHandleErrors: true, disableErrorMessage: true },
      ).then((res) => (res ? setFieldValue('isValidVesselName', true) : setFieldValue('isValidVesselName', false)));
    } else {
      setFieldValue('isValidVesselName', true);
    }
  };

  const cleanApiError = (apiErrors: any) => {
    const result = {};
    const pathVal = _.get(apiErrors, 'callsign');
    if (!_.isEmpty(pathVal)) {
      _.set(result, 'callsign', pathVal);
    }
    return result;
  };

  const updateError = (path?: string, message?: string) => {
    const copiedError = JSON.parse(JSON.stringify(callsignErrors));
    if (path) {
      _.set(copiedError, path, message);
    }
    const cleanedApiErrors = cleanApiError(copiedError);
    setCallsignErrors(cleanedApiErrors);
    const errors = _.merge(cleanedApiErrors);

    setErrors(errors);
    setTouched(errors);
  };

  const handleCallsignOnBlur = (callsign: string, equipment?: any, classA?: boolean) => {
    const serviceType = props.serviceType ? props.serviceType : equipment;
    if (callsign && !_.isEmpty(callsign) && serviceType && !_.isEmpty(serviceType)) {
      validateShipCallsign(
        { callsign: callsign, serviceType: serviceType, shipCallsignRecordId: values.id, isClassAShip: classA ? true : false },
        { callerHandleErrors: true, disableErrorMessage: true },
      ).then(({ message, suggestions }) => {
        let callsignError = null;
        if (message !== null) {
          callsignError = message;
          callsignError +=
            suggestions && suggestions.length > 0 ? ' Available callsigns are: ' + suggestions.join(', ') : ' No callsign suggestions are found.';
        }
        updateError('callsign', callsignError);
      });
    } else {
      updateError('callsign', undefined);
    }
  };

  const handleMMSIOnBlur = (mmsi: number, isHandVHF: boolean, inmarsat?: boolean) => {
    if (!isNaN(mmsi) && !_.isEmpty(mmsi)) {
      validateMMSI(
        {
          mmsiNumber: mmsi,
          isHandHeldVHF: isHandVHF ? true : false,
          isInmarsatSatellite: inmarsat ? true : false,
          shipCallsignRecordId: values.id,
        },
        { callerHandleErrors: true, disableErrorMessage: true },
      ).then((res) => (res ? setFieldValue('isValidMMSI', true) : setFieldValue('isValidMMSI', false)));
    } else {
      setFieldValue('isValidMMSI', true);
    }
  };

  const handleSCNOnBlur = (scn: number) => {
    if (!_.isEmpty(scn)) {
      validateSCN({ scn, shipCallsignRecordId: values.id }, { callerHandleErrors: true, disableErrorMessage: true }).then((res) =>
        res ? setFieldValue('isValidSCN', true) : setFieldValue('isValidSCN', false),
      );
    } else {
      setFieldValue('isValidSCN', true);
    }
  };

  const cleanOnChangeEquipmentType = (o?: IChoiceGroupOption) => {
    if (o?.key === 'SHIP_HAND_VHF' || o?.key === 'SHIP_VHF') {
      setFieldValue('isSelectiveCallNumber', false);
      setFieldValue('scn', '');
      setFieldValue('isClassAShip', false);
      if (o?.key === 'SHIP_HAND_VHF') {
        setFieldValue('vesselName', '');
        setFieldValue('isInmarsatSatellite', false);
        setFieldValue('mmsiCraftAssociated', []);
        setFieldValue('maritimeVesselSafetyDrmIds', []);
        handleMMSIOnBlur(values.mmsi, true, false);
      } else {
        handleMMSIOnBlur(values.mmsi, false, values.isInmarsatSatellite);
      }
      handleCallsignOnBlur(values.callsign, o?.key, false);
    } else {
      handleCallsignOnBlur(values.callsign, o?.key, values.isClassAShip);
      handleMMSIOnBlur(values.mmsi, false, values.isInmarsatSatellite);
    }
  };

  const addCraftMMSI = () => {
    getNextMaritimeCraftMMSI({ callerHandleErrors: true }).then((res: MmsiNumber) =>
      setValues({
        ...values,
        mmsiCraftAssociated: [
          ...(values.mmsiCraftAssociated ?? []),
          {
            parentShipCraftMMSI: res.mmsi,
          },
        ],
      }),
    );
  };

  const mmsiCraftAssociatedColumns = [
    {
      key: 'reference',
      fieldName: 'reference',
      name: 'Ref',
      minWidth: 100,
      maxWidth: 100,
      isMultiline: true,
      isResizable: true,
      onRender: (item: any, index?: number) => <ReadonlyData label="" value={index! + 1} />,
    },
    {
      key: 'parentShipCraftMMSI',
      fieldName: 'parentShipCraftMMSI',
      name: 'Parent ship craft MMSI',
      minWidth: 100,
      maxWidth: 300,
      isMultiline: true,
      isResizable: true,
      onRender: (item: any, index?: number) => <ReadonlyData label="" value={item.parentShipCraftMMSI} />,
    },
    {
      key: 'actions',
      fieldName: 'actions',
      name: '',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      onRender: (item: any, index?: number) => {
        return mode === 'CREATE' || (mode === 'EDIT' && isInternalUser) ? (
          <>
            <DefaultButton
              text="Remove"
              onClick={() => {
                setValues({
                  ...values,
                  mmsiCraftAssociated: values.mmsiCraftAssociated
                    .map((v: any, idx: number) => (index === idx ? undefined : v))
                    .filter((v: any) => !!v),
                });
              }}
              id={'Remove-mmsiCraftAssociated-' + item.id}
              data-automation-id={'Remove-mmsiCraftAssociated-' + item.id}
            />
          </>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <>
      {(mode === 'VIEW' || mode === 'EDIT') && (
        <>
          {formikContext?.values?.approvalRadio && !formikContext.values.approvalRadio.startsWith('null0') && (
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <ReadonlyData label={'Approved radio examiner'} value={formikContext.values?.approvalRadio} />
              </Grid.Col>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Col sm={12} lg={6}>
              <ClientLink clientId={formikContext.values?.clientId} />
            </Grid.Col>
            <Grid.Col sm={12} lg={6}>
              <ReadonlyData label="Client name" value={formikContext.values?.clientName} />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      <SectionHeading title="Ship callsign details" />
      {(mode === 'VIEW' || mode === 'EDIT') && (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <ReadonlyData label="Status" value={formikContext.values?.status} />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <ChoiceGroup
            name="equipmentType"
            label="Equipment type"
            options={SHIP_CALLSIGN_TYPE.map((x) => ({
              ...x,
              disabled:
                (x.key === 'SHIP_VHF' || x.key === 'SHIP_HAND_VHF') &&
                !isInternalUser &&
                !(currentRole === 'ROLE_APPROVED_RADIO_EXAMINER' && currentClientId === CBES_CLIENT),
            }))}
            onChoiceChange={(o?: IChoiceGroupOption) => cleanOnChangeEquipmentType(o)}
            required
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={6}>
          <Label>&nbsp;</Label>
          {(values.equipmentType === 'SHIP_MF/HF&VHF' || values.equipmentType === 'SHIP_MF/HF' || values.equipmentType === 'SHIP_VHF') && (
            <Checkbox
              name="isInmarsatSatellite"
              label="INMARSAT Satellite C communications equipment fitted?"
              styles={{ root: { margin: '8px' } }}
              onChange={(_: any, inmarsat?: boolean) =>
                handleMMSIOnBlur(values.mmsi, values.equipmentType === ShipCallsignRecordDtoEquipmentTypeEnum.HAND_VHF, inmarsat)
              }
            />
          )}

          {(values.equipmentType === 'SHIP_MF/HF&VHF' || values.equipmentType === 'SHIP_MF/HF') && (
            <Checkbox
              name="isSelectiveCallNumber"
              label="Selective Call Number (SCN) required?"
              styles={{ root: { margin: '8px' } }}
              onChange={(_: any, checked?: boolean) => {
                if (!checked) {
                  setFieldValue('scn', '');
                }
              }}
            />
          )}

          {isInternalUser && (values.equipmentType === 'SHIP_MF/HF&VHF' || values.equipmentType === 'SHIP_MF/HF') && (
            <Checkbox
              name="isClassAShip"
              label="Class 'A' ship? (Ship Registration Act 1992)"
              styles={{ root: { margin: '8px' } }}
              onChange={(_: any, classA?: boolean) => handleCallsignOnBlur(values.callsign, values.equipmentType, classA)}
            />
          )}
        </Grid.Col>
      </Grid.Row>

      {values.equipmentType !== 'SHIP_HAND_VHF' && (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <TextField
              name="vesselName"
              label="Vessel Name"
              maxLength={50}
              required
              onBlurCapture={(e: any) => handleVesselNameOnBlur(e.target.value)}
            />
          </Grid.Col>
        </Grid.Row>
      )}

      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          <TextField
            name="callsign"
            label="Callsign"
            required={values.equipmentType === 'SHIP_VHF' || values.equipmentType === 'SHIP_HAND_VHF' || values.isClassAShip}
            helpText={
              (values.equipmentType === 'SHIP_MF/HF&VHF' || values.equipmentType === 'SHIP_MF/HF') && !values.isClassAShip
                ? 'The system will allocate a callsign automatically if you do not complete this field.'
                : undefined
            }
            onBlurCapture={(e: any) => handleCallsignOnBlur(e.target.value, values.equipmentType, values.isClassAShip)}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          {isInternalUser ? (
            <NumberField
              name="mmsi"
              label="MMSI"
              decimalDigit={0}
              onBlurCapture={(e: any) =>
                handleMMSIOnBlur(e.target.value, values.equipmentType === ShipCallsignRecordDtoEquipmentTypeEnum.HAND_VHF, values.isInmarsatSatellite)
              }
            />
          ) : (
            <ReadonlyData value={values.mmsi} label="MMSI" />
          )}
        </Grid.Col>
      </Grid.Row>

      {values.isSelectiveCallNumber && (values.equipmentType === 'SHIP_MF/HF&VHF' || values.equipmentType === 'SHIP_MF/HF') && (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            {isInternalUser ? (
              <NumberField name="scn" label="SCN" decimalDigit={0} onBlurCapture={(e: any) => handleSCNOnBlur(e.target.value)} />
            ) : (
              <ReadonlyData value={values.scn} label="SCN" />
            )}
          </Grid.Col>
        </Grid.Row>
      )}

      {values.equipmentType && values.equipmentType !== 'SHIP_HAND_VHF' && (
        <>
          <Grid.Row>
            <Grid.Col sm={12} lg={6}>
              <Label>
                Maritime Vessel Safety Form
                <TooltipHint
                  id="maritime-vessel-safety-form-tooltip"
                  content={'https://www.rsm.govt.nz/assets/Uploads/documents/pibs/radio-operator-certificate-and-callsign-rules-pib-46.pdf'}
                  styles={{ root: { marginLeft: '5px' } }}
                />
              </Label>
              {(mode === 'EDIT' || mode === 'CREATE') && (
                <FileUpload
                  id="maritimeVesselSafetyForm"
                  onFileRemoved={handleFileRemoved}
                  onFileUploaded={handleFileUploaded}
                  documentType="VESSEL_SAFETY"
                  validator={fileValidator}
                  formFieldName="maritimeVesselSafetyDrmIds.drmId"
                  existingFiles={existingFile ? existingFile : []}
                />
              )}
              {mode === 'VIEW' && <FileViews fileList={values.maritimeVesselSafetyDrmIds} />}
            </Grid.Col>
          </Grid.Row>

          <SubSectionHeading
            title={'MMSI for craft associated to parent ship'}
            actionButtonText={'Add'}
            actionButtonId={'add-mmsi-craft-parent-ship-callsign'}
            hint={
              'Up to 10 MMSIs for craft associated to parent ship can be added. To add the MMSI(s), please contact RSM (rsmlicensing@mbie.govt.nz)'
            }
            actionButtonOnClick={!isInternalUser || mode === 'VIEW' || values.mmsiCraftAssociated?.length >= 10 ? undefined : addCraftMMSI}
          />

          {isInternalUser && (
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <DetailsList columns={mmsiCraftAssociatedColumns} items={values.mmsiCraftAssociated ? values.mmsiCraftAssociated : []} />
              </Grid.Col>
            </Grid.Row>
          )}
        </>
      )}
      {(mode === 'VIEW' || mode === 'EDIT') && (
        <>
          <Grid.Row>
            <Grid.Col sm={12} lg={4}>
              <ReadonlyData label={'Created date'} value={formikContext.values?.createdDate} formatDateFromISO />
            </Grid.Col>
            <Grid.Col sm={12} lg={4}>
              <ReadonlyData label={'Last updated by'} value={formikContext.values?.lastUpdatedName} />
            </Grid.Col>
            <Grid.Col sm={12} lg={4}>
              <ReadonlyData label={'Last updated date'} value={formikContext.values?.lastUpdatedDate} formatDateFromISO />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      {formikContext.values?.status === ShipCallsignRecordDtoStatusEnum.Cancelled && (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <ReadonlyData value={formikContext.values?.reason} label={'Release reason'} />
          </Grid.Col>
        </Grid.Row>
      )}
      {formikContext.values?.status === ShipCallsignRecordDtoStatusEnum.Revoked && (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <ReadonlyData value={formikContext.values?.reason} label={'Revoke reason'} />
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

export default ShipCallsign;
