import { DefaultButton, IDropdownOption, mergeStyles } from '@fluentui/react';
import React from 'react';

import {
  AuditAppealProcessTypeEnum,
  AuditFurtherActionEnum,
  AuditMethodEnum,
  AuditRecommendationEnum,
  AuditTypeEnum,
  CamundaUserDto,
  CaseFinalResultEnum,
  CaseSearchResult,
  CaseSearchResultTypeGroupEnum,
  CaseStatusEnum,
  CaseTypeGroupEnum,
} from 'api-client';

import SearchForm from 'ui-library/form/SearchForm';

import { useAuth } from 'common/auth/AuthHook';
import { Column } from 'common/controls/lists/DetailsList';
import Page from 'common/layout/Page';
import {
  AUDIT_FURTHER_ACTION_OPTIONS,
  AUDIT_METHOD_OPTIONS,
  AUDIT_RECOMMENDATION_OPTIONS,
  CASE_RESULT,
  CASE_STATUS,
} from 'common/reference/referenceData';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import {
  convertAssignee,
  convertFurtherAction,
  convertResult,
  convertStatus,
  useCamundaGroupOptions,
  useCaseTypeOptions,
  useAssigneeOptions,
} from 'investigation/hooks';
import { useSearchApi } from 'search/SearchApiHooks';
import { AppliedFilters } from 'search/utils';

import SearchCaseAdvancedOptions from './SearchCaseAdvancedOptions';
import SearchCaseCriteria, { SearchCaseFormProps } from './SearchCaseCriteria';

const Tags = ({ tags = [] }: { tags: any[] | undefined }) => {
  const tagContainerStyle = mergeStyles({ display: 'flex', flexWrap: 'wrap' });
  const tagItemStyle = mergeStyles({ backgroundColor: '#f3f2f1', margin: 5, padding: '5px 10px' });

  return (
    <span className={tagContainerStyle}>
      {tags?.map((tag) => (
        <span key={tag} className={tagItemStyle}>
          {tag}
        </span>
      ))}
    </span>
  );
};

export const SearchCasePage = () => {
  const { hasAnyPermission, hasPermission } = useAuth();
  const { caseTypeOptions } = useCaseTypeOptions(CaseTypeGroupEnum.ALL);
  const router = useInvestigationRouter();

  const searchCase = useSearchCase();
  const { assigneeOptions } = useAssigneeOptions();
  const searchCaseFilter = useSearchCaseFilter(assigneeOptions);
  const searchCaseQueryParamsParser = useSearchCaseQueryParamsParser();

  const columns: Column[] = [
    { key: 'id', fieldName: 'id', name: 'Case number', minWidth: 80, maxWidth: 90, isResizable: true },
    { key: 'type', fieldName: 'type', name: 'Case type', minWidth: 80, maxWidth: 200, isResizable: true },
    { key: 'clientName', fieldName: 'clientName', name: 'Client', minWidth: 250, maxWidth: 220, isResizable: true },
    {
      key: 'auditTypes',
      name: 'Audit types',
      minWidth: 100,
      maxWidth: 160,
      onRender: ({ auditTypes }: CaseSearchResult) => <Tags tags={auditTypes} />,
    },
    {
      key: 'tags',
      name: 'Tags',
      minWidth: 120,
      onRender: ({ tags }: CaseSearchResult) => <Tags tags={tags} />,
    },
    { key: 'allocatedTo', fieldName: 'allocatedTo', name: 'Initiator', minWidth: 100, isResizable: true },
    {
      key: 'assigneeId',
      fieldName: 'assigneeId',
      name: 'Currently allocated to',
      minWidth: 100,
      isResizable: true,
      onRender: ({ assigneeId }: CaseSearchResult) => (assigneeId ? convertAssignee(+assigneeId, assigneeOptions) : ''),
    },
    { key: 'startDate', fieldName: 'startDate', name: 'Start date', minWidth: 100, isResizable: true },
    {
      key: 'status',
      name: 'Status',
      minWidth: 100,
      isResizable: true,
      onRender: ({ status }: CaseSearchResult) => convertStatus(status as CaseStatusEnum),
    },
    {
      key: 'result',
      name: 'Result',
      minWidth: 120,
      isResizable: true,
      onRender: ({ result: caseResult }: CaseSearchResult) => (!caseResult ? '' : convertResult(caseResult as CaseFinalResultEnum) || caseResult),
    },
    {
      key: 'furtherAction',
      name: 'Further action',
      minWidth: 130,
      isResizable: true,
      onRender: ({ furtherAction }: CaseSearchResult) =>
        !furtherAction ? '' : convertFurtherAction(furtherAction as AuditFurtherActionEnum) || furtherAction, // TODO
    },
    {
      key: 'view',
      name: '',
      minWidth: 70,
      onRender: (item: CaseSearchResult) => {
        const isViewBtnVisible =
          (item.typeGroup === CaseSearchResultTypeGroupEnum.AUDIT && hasPermission('VIEW_AUDIT')) ||
          (item.typeGroup === CaseSearchResultTypeGroupEnum.COMPLAINT && hasAnyPermission(['BASIC_VIEW_COMPLAINT', 'VIEW_COMPLAINT']));

        return isViewBtnVisible ? (
          <DefaultButton
            id={`view-${item.id}`}
            text="View"
            onClick={() => {
              const id = item.id as number;

              if (caseTypeOptions.find((option) => option.text === item.type)?.data.group === CaseTypeGroupEnum.AUDIT) {
                switch (item.status) {
                  case CaseStatusEnum.AUDIT_CPL:
                    ['6', '10'].includes(item?.typeId ?? '') ? router.navigateToAuditView(id) : router.navigateToAuditRecommendation(id);
                    return;
                  case CaseStatusEnum.RECOMMD:
                    router.navigateToAuditReview(id);
                    return;
                  case CaseStatusEnum.IPRG:
                    router.navigateToMaintainAudit(id);
                    return;
                  default:
                    router.navigateToAuditView(id);
                }
              } else {
                switch (item.status) {
                  case CaseStatusEnum.PEND:
                  case CaseStatusEnum.IPRG:
                    router.navigateToMaintainComplaint(id);
                    return;
                  default:
                    router.navigateToViewComplaint(id);
                }
              }
            }}
          />
        ) : (
          <></>
        );
      },
    },
  ];

  const { camundaGroupOptions: allocatedToGroupOptions } = useCamundaGroupOptions();

  return (
    <Page title="Search Cases">
      <SearchForm<SearchCaseFormProps>
        collapsible
        columns={columns}
        excludeFromSorting={['tags', 'auditTypes']}
        filtersSummary={searchCaseFilter}
        initialValues={{
          allocatedToGroupOptions,
          caseTypeOptions,
        }}
        moreOptions={<SearchCaseAdvancedOptions />}
        onParseQueryString={searchCaseQueryParamsParser}
        onSearch={searchCase}
        queryParams={[
          'allocatedToGroupId',
          'allocatedToUserId',
          {
            name: 'allocatedToUserIds',
            resolver: (values) => {
              if (values.allocatedToUserId) {
                return undefined;
              }

              return values.allocatedToUserIds || values.allocatedToUserOptions?.filter(({ key }) => key).map(({ key }) => key.toString());
            },
          },
          'auditMethods',
          'auditTypes',
          'caseTypeIds',
          'clientAppealed',
          'recommendations',
          'result',
          'furtherAction',
          'searchText',
          'startDateFrom',
          'startDateTo',
          'status',
          'tags',
          'assigneeId',
        ]}
        searchBoxProps={{
          placeholder: "Case number or client's name",
          hint: "You can search by case number or client's name",
        }}
        title="Search case"
        defaultSort={{
          sortKey: 'startDate',
          descending: true,
        }}
      >
        <SearchCaseCriteria assigneeOptions={assigneeOptions} />
      </SearchForm>
    </Page>
  );
};

export const useSearchCase = () => {
  const { searchCase } = useSearchApi();

  return (values: SearchCaseFormProps) => {
    const {
      allocatedToGroupId,
      allocatedToUserId,
      allocatedToUserIds,
      allocatedToGroupOptions,
      allocatedToUserOptions,
      caseTypeIds,
      caseTypeOptions,
      clientAppealed,
      ...criteria
    } = values;

    let allocatedToList: string[] | undefined;

    if (allocatedToUserId) {
      allocatedToList = [allocatedToUserId.toString()];
    } else if (allocatedToGroupId) {
      allocatedToList = [allocatedToGroupId];

      if (allocatedToUserIds?.length) {
        allocatedToList = allocatedToList.concat(allocatedToUserIds);
      } else {
        allocatedToList = allocatedToList.concat(allocatedToUserOptions?.filter(({ key }) => key).map(({ key }) => key.toString()));
      }
    }

    return searchCase({
      ...criteria,
      allocatedTo: allocatedToList,
      appealProcessTypes: clientAppealed ? [AuditAppealProcessTypeEnum.HEARING, AuditAppealProcessTypeEnum.REVIEW] : [],
      typeIds: caseTypeIds,
    });
  };
};

export const useSearchCaseFilter = (assigneeOptions: IDropdownOption<CamundaUserDto>[]) => {
  return (values: SearchCaseFormProps) => {
    const { auditMethods, auditTypes, clientAppealed, result = [], furtherAction = [], startDateFrom, startDateTo, status = [], tags = [] } = values;

    let startRange = '';
    if (startDateFrom && startDateTo) {
      startRange = `${startDateFrom} to ${startDateTo}`;
    } else {
      startRange = startDateFrom || startDateTo;
    }

    const { allocatedToGroupOptions = [], allocatedToGroupId, allocatedToUserId, allocatedToUserOptions = [], assigneeId } = values;

    let allocatedTo;
    if (allocatedToUserId) {
      allocatedTo = allocatedToUserOptions.find((s) => s.key === allocatedToUserId)?.text || '';
    } else if (allocatedToGroupId) {
      allocatedTo = allocatedToGroupOptions.find((s) => s.key === allocatedToGroupId)?.text || '';
    }

    const assigneeIdName = assigneeId ? convertAssignee(assigneeId, assigneeOptions) : '';

    const { caseTypeIds, caseTypeOptions = [], recommendations = [] } = values;
    const caseTypes = caseTypeIds?.length ? caseTypeOptions.filter((s: any) => caseTypeIds.includes(s.key)).map((s) => s.text) : undefined;

    return new AppliedFilters()
      .push(auditMethods?.map(convertAuditMethod), 'Audit method')
      .push(auditTypes, 'Audit type')
      .push(caseTypes, 'Case type')
      .push(assigneeIdName, 'Case currently allocated to')
      .push(allocatedTo, 'Initiator')
      .push(result?.map((_) => CASE_RESULT.find(({ key }) => key === _)?.text).sort(), 'Audit/Interference Complaint result')
      .push(furtherAction?.map((_) => AUDIT_FURTHER_ACTION_OPTIONS.find(({ key }) => key === _)?.text).sort(), 'Further action')
      .push(status?.map((_) => CASE_STATUS.find(({ key }) => key === _)?.text).sort(), 'Case status')
      .push(recommendations?.map((_) => AUDIT_RECOMMENDATION_OPTIONS.find(({ key }) => key === _)?.text).sort(), 'Recommendations')
      .push(startRange, 'Start date')
      .push(tags, 'Tags')
      .push(clientAppealed, 'Client appeal')
      .get();
  };
};

export const useSearchCaseQueryParamsParser = () => {
  return (queryParams: { [key: string]: string }): Partial<SearchCaseFormProps> => {
    const {
      allocatedToGroupId,
      allocatedToUserId,
      allocatedToUserIds,
      auditMethods,
      auditTypes,
      caseTypeIds,
      clientAppealed,
      recommendations,
      result,
      furtherAction,
      searchText,
      startDateFrom,
      startDateTo,
      status,
      tags,
      assigneeId,
    } = queryParams;

    return {
      allocatedToGroupId,
      allocatedToUserId: Number(allocatedToUserId) || undefined,
      allocatedToUserIds: allocatedToUserIds?.split(','),
      auditMethods: auditMethods?.split(',').map((s) => s as AuditMethodEnum),
      auditTypes: auditTypes?.split(',').map((s) => s as AuditTypeEnum),
      caseTypeIds: caseTypeIds?.split(',').map((id) => Number(id)),
      clientAppealed: !!clientAppealed,
      recommendations: recommendations?.split(',').map((s) => s as AuditRecommendationEnum),
      result: result?.split(',').map((s) => s as CaseFinalResultEnum),
      furtherAction: furtherAction?.split(',').map((s) => s as AuditFurtherActionEnum),
      searchText,
      startDateFrom,
      startDateTo,
      status: status?.split(','),
      tags: tags?.split(','),
      assigneeId: assigneeId ? +assigneeId : undefined,
    };
  };
};

const convertAuditMethod = (auditMethod: AuditMethodEnum) => {
  return AUDIT_METHOD_OPTIONS.find(({ key }) => key === auditMethod)?.text;
};
