import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';

const MaintainAmateurCallsignPage = React.lazy(() => import('certificate_management/callsign/maintain/amateur/MaintainAmateurCallsignPage'));
const MaintainShipCallsignPage = React.lazy(() => import('certificate_management/callsign/maintain/ship/MaintainShipCallsignPage'));
const CreateCallsignPage = React.lazy(() => import('../callsign/create/CreateCallsignPage'));
const CreateCertificatePage = React.lazy(() => import('./create/CreateCertificatePage'));
const MaintainCertificatePage = React.lazy(() => import('./maintain/MaintainCertificatePage'));

const CertificateRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/create`} component={CreateCertificatePage} permission={['CREATE_CERTIFICATE']} />
        <ProtectedRoute path={`${path}/callsign/create`} component={CreateCallsignPage} permission={['CREATE_CALLSIGN']} />{' '}
        <ProtectedRoute
          path={`${path}/callsign/amateur/maintain/:callsignId`}
          component={MaintainAmateurCallsignPage}
          permission={['VIEW_CALLSIGN', 'UPDATE_CALLSIGN']}
        />
        <ProtectedRoute
          path={`${path}/callsign/ship/maintain/:callsignId`}
          component={MaintainShipCallsignPage}
          permission={['VIEW_CALLSIGN', 'UPDATE_CALLSIGN']}
        />
        <ProtectedRoute path={`${path}/maintain/:certificateId`} component={MaintainCertificatePage} />
      </Switch>
    </>
  );
};

export const useCertificateNavigation = () => {
  const history = useHistory();

  return {
    navigateToCreateCertificate: () => history.push('/ui/app/certificate/create'),
    navigateToCreateCallsign: () => history.push('/ui/app/certificate/callsign/create'),
    navigateToMaintainAmateurCallsign: (callsignId: number) => history.push('/ui/app/certificate/callsign/amateur/maintain/' + callsignId),
    navigateToMaintainShipCallsign: (callsignId: number) => history.push('/ui/app/certificate/callsign/ship/maintain/' + callsignId),
    navigateToMaintainCertificate: (certificateId: number) => history.push('/ui/app/certificate/maintain/' + certificateId),
  };
};

export default CertificateRouter;
