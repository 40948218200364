import moment from 'moment';
import * as yup from 'yup';

import { RRF_DATE_FORMAT } from '../../../../common/utils/dateUtils';
import { dateSchema, requiredDate, requiredString, wholeNumber } from '../../../../common/validation/yupUtils';
import { tenMonthsFromLastDayOfCurrentMonth } from '../../../apply/utils';
import { baseCallsignsSchema, mobileCallsignsSchema } from '../../../common/validationSchema';

export const setsSchema = wholeNumber
  .min(1, 'Must be greater than or equal to 1')
  .max(999, 'Must be less than or equal to 999')
  .when('$context.setTotalRequired', {
    is: (setTotalRequired) => setTotalRequired,
    // set typeError to handle the empty string case by displaying a nice error message instead of: sets must be a `number` type, but the final value was: `NaN` (cast from the value `""`).
    then: yup.number().typeError('Required').required('Required'),
  })
  .nullable(true);

const commencementDateSchema = dateSchema
  .when('$context.modifyRadioLicence', {
    is: (modifyRadioLicence: boolean) => modifyRadioLicence,
    then: requiredDate,
    otherwise: requiredDate,
  })
  .when(['fixedTerm'], {
    is: (fixedTerm: boolean) => fixedTerm === true,
    then: requiredDate.test(
      'commencementDate',
      `Commencement date of a fixed term radio licence cannot be later than ${tenMonthsFromLastDayOfCurrentMonth.format(RRF_DATE_FORMAT)}`,
      function (this: yup.TestContext, value) {
        return moment(value).isSameOrBefore(tenMonthsFromLastDayOfCurrentMonth);
      },
    ),
  });

const managementRightCommencementDateSchema = yup
  .date()
  .required('Required')
  .test('management right commencement', 'Must be after or same as Management Right Commencement', function (this: yup.TestContext, date) {
    const mr = this.parent.managementRight;
    return !date || !mr?.commencementDate || moment(date).isSameOrAfter(mr.commencementDate);
  });

const commencementDate = dateSchema.when('managementRightId', {
  is: (managementRightId) => !!managementRightId,
  then: managementRightCommencementDateSchema,
  otherwise: commencementDateSchema,
});

const expiryDateSchema = dateSchema.when(['fixedTerm'], {
  is: (fixedTerm: boolean) => fixedTerm === true,
  then: requiredDate.test('periodEnding', 'Must be after or same as Commencement date', function (this: yup.TestContext, value) {
    return !value || !this.parent['commencementDate'] || moment(value).isSameOrAfter(this.parent['commencementDate']);
  }),
});

const managementRightExpiryDateSchema = expiryDateSchema.test(
  'management right expiry',
  'Must be before or same as Management Right Expiry',
  function (this: yup.TestContext, date) {
    const mr = this.parent.managementRight;
    return !date || !mr?.expiryDate || moment(date).isSameOrBefore(mr.expiryDate);
  },
);

const expiryDate = dateSchema.when('managementRightId', {
  is: (managementRightId) => !!managementRightId,
  then: managementRightExpiryDateSchema,
  otherwise: expiryDateSchema,
});

const basicDetailsShapeFull = {
  licenceTypeId: requiredString,
  classification: yup
    .string()
    .when('$context.requiresClassification', {
      is: (required) => required,
      then: requiredString,
    })
    .nullable(true),
  commencementDate,
  expiryDate,
  anniversaryMonth: requiredString,
  baseCallsigns: baseCallsignsSchema,
  mobileCallsign: mobileCallsignsSchema,
  sets: setsSchema,
};

export const basicDetailsSchemaFull = yup.object().shape(basicDetailsShapeFull);
