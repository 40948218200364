import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { AuditTypeEnum } from 'api-client';

import { Dropdown, Grid, TagInput } from 'ui-library';

import Checkbox from 'common/controls/inputs/Checkbox';
import MultiSelectDropdown from 'common/controls/inputs/MultiSelectDropdown';
import { AUDIT_METHOD_OPTIONS, AUDIT_RECOMMENDATION_OPTIONS } from 'common/reference/referenceData';

import { SectionHeading } from '../../common/layout/SectionHeadings';
import { useRadioInvestigatorOptions } from '../hooks';
import { SearchCaseFormProps } from './SearchCaseCriteria';

const ALL_OPTION = { key: '', text: '' };

const SearchCaseAdvancedOptions = () => {
  const { values, setFieldValue } = useFormikContext<SearchCaseFormProps>();
  const { radioInvestigatorOptions } = useRadioInvestigatorOptions(values.allocatedToGroupId);

  useEffect(() => {
    setFieldValue('allocatedToUserOptions', radioInvestigatorOptions);
  }, [radioInvestigatorOptions]);
  const { allocatedToGroupOptions = [] } = values;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={2}>
          <MultiSelectDropdown
            label="Audit type"
            name="auditTypes"
            options={[
              { key: AuditTypeEnum.EMC, text: 'EMC' },
              { key: AuditTypeEnum.RADIO, text: 'Radio' },
            ]}
          />
        </Grid.Col>
        <Grid.Col lg={3}>
          <MultiSelectDropdown label="Audit method" name="auditMethods" options={AUDIT_METHOD_OPTIONS} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <MultiSelectDropdown label="Recommendation" name="recommendations" options={AUDIT_RECOMMENDATION_OPTIONS} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <TagInput label="Tags" name="tags" />
        </Grid.Col>
        {/* <Grid.Col lg={3}>
          <MultiSelectDropdown label="Offences" name="offences" options={[]} />
        </Grid.Col> */}
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Checkbox label="Client appealed" name="clientAppealed" />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={7}>
          <SectionHeading title="Case initiator" />
          <Grid>
            <Grid.Col lg={8}>
              <Dropdown label="Group" name="allocatedToGroupId" options={[ALL_OPTION, ...allocatedToGroupOptions]} />
            </Grid.Col>

            <Grid.Col lg={8}>
              <Dropdown label="Investigators" name="allocatedToUserId" options={radioInvestigatorOptions} disabled={!values.allocatedToGroupId} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default SearchCaseAdvancedOptions;
