import React from 'react';

import { Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { toSentenceCase } from 'common/utils/stringUtils';

import { formatDate } from '../../../common/utils/dateUtils';

const AuditCourtDecisionSection = () => (
  <>
    <SectionHeading title="Court ruling" />
    <Grid2>
      <Grid2.Col lg={3}>
        <ReadOnlyField label="Ruling" name="courtDecision" renderFormat={toSentenceCase} />
      </Grid2.Col>
      <Grid2.Col lg={9}>
        <ReadOnlyField label="Court date" name="courtDecisionDate" renderFormat={formatDate} />
      </Grid2.Col>
      <Grid2.Col>
        <ReadOnlyField label="Details" name="courtDecisionDetails" />
      </Grid2.Col>
    </Grid2>
  </>
);

export default AuditCourtDecisionSection;
