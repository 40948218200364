import * as React from 'react';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { EquipmentSearchCriteria, SpectrumSearchCriteria } from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import GeoReferenceTypeConverterPage from 'licence_management/reference_data/geoReferenceType/GeoReferenceTypeConverterPage';

import ProtectedRoute from '../../common/auth/ProtectedRoute';
import { SearchCriteria, SearchType } from '../../search/SearchRouter';
import { stringifyQueryObject } from '../../search/utils';
import SearchEquipmentPage from '../search/equipment/SearchEquipmentPage';
import SearchSpectrumMaskPage from '../search/spectrumMask/SearchSpectrumMaskPage';
import SearchSpectrumRecordPage from '../search/spectrum_record/SearchSpectrumRecordPage';
import CreateAntennaPage from './antenna/CreateAntennaPage';
import UpdateAntennaPage from './antenna/UpdateAntennaPage';
import CreateEquipmentPage from './equipment/CreateEquipmentPage';
import UpdateEquipmentPage from './equipment/UpdateEquipmentPage';
import CreateDefinedAreaPage from './location/create/CreateDefinedAreaPage';
import CreateLocationNamePage from './location/create/CreateLocationNamePage';
import CreateMultiPointPage from './location/create/CreateMultiPointPage';
import CreatePointPage from './location/create/CreatePointPage';
import MaintainLocationPage from './location/maintain/MaintainLocationPage';
import CreateSpectrumMaskPage from './spectrum_mask/create/CreateSpectrumMaskPage';
import UpdateSpectrumMaskPage from './spectrum_mask/maintain/UpdateSpectrumMaskPage';
import CreateSpectrumRecordPage from './spectrum_record/CreateSpectrumRecordPage';
import UpdateSpectrumRecordPage from './spectrum_record/UpdateSpectrumRecordPage';

interface Props {}

export const MAINTAIN_ANTENNA_URL = '/ui/app/licence/reference/antenna/maintain/';
export const MAINTAIN_EQUIPMENT_URL = '/ui/app/licence/reference/equipment/maintain/';
export const MAINTAIN_SPECTRUM_URL = '/ui/app/licence/reference/spectrum-record/maintain/';

export const useLicenceReferenceDataNavigator = () => {
  const history = useHistory();
  const location = useLocation();
  const { loggedIn } = useAuth();

  const navigateToSearch = (searchType: SearchType, searchCriteria?: SearchCriteria, replaceHistory?: boolean) => {
    // Logged in urls start with /ui/app. Public ones do not. Limited searching is available for both logged in and public users.
    const searchUrl = (searchType: SearchType, searchCriteria?: SearchCriteria) =>
      location.pathname.replace(/^(\/ui(\/app)?)\/.*/, `$1/licence/reference/${searchType}?${stringifyQueryObject(searchCriteria)}`);
    if (replaceHistory) {
      history.replace(searchUrl(searchType, searchCriteria));
    } else {
      history.push(searchUrl(searchType, searchCriteria));
    }
  };

  return {
    navigateToCreateEquipment: () => history.push('/ui/app/licence/reference/equipment/create'),
    navigateToMaintainEquipment: (id: number, replace?: boolean) =>
      replace ? history.replace(MAINTAIN_EQUIPMENT_URL + id) : history.push(MAINTAIN_EQUIPMENT_URL + id),
    navigateToCreateAntenna: () => history.push('/ui/app/licence/reference/antenna/create'),
    navigateToMaintainAntenna: (id: number, replace?: boolean) =>
      replace ? history.replace(MAINTAIN_ANTENNA_URL + id) : history.push(MAINTAIN_ANTENNA_URL + id),
    navigateToSearchEquipment: (searchCriteria?: EquipmentSearchCriteria, replaceHistory?: boolean) =>
      navigateToSearch('equipment', searchCriteria, replaceHistory),
    navigateToSearchSpectrumMask: (searchCriteria?: EquipmentSearchCriteria, replaceHistory?: boolean) =>
      navigateToSearch('spectrumMask', searchCriteria, replaceHistory),
    navigateToCreateSpectrumMask: () => history.push('/ui/app/licence/reference/spectrum-mask/create'),
    navigateToMaintainLocation: (id: number) => history.push('/ui/app/licence/reference/location/maintain/' + id),
    navigateToCreateLocation: (type: String, georef: any) =>
      history.push('/ui/app/licence/reference/location/create/' + type + '?' + stringifyQueryObject(georef)),
    navigateToUpdateSpectrumMask: (id: number) => history.push('/ui/app/licence/reference/spectrum-mask/maintain/' + id),
    navigateToCreateSpectrumRecord: () => history.push('/ui/app/licence/reference/spectrum-record/create'),
    navigateToMaintainSpectrumRecord: (id: number, replace?: boolean) =>
      replace ? history.replace(MAINTAIN_SPECTRUM_URL + id) : history.push(MAINTAIN_SPECTRUM_URL + id),
    navigateToSearchSpectrum: (searchCriteria?: SpectrumSearchCriteria, replaceHistory?: boolean) =>
      navigateToSearch('spectrum', searchCriteria, replaceHistory),
    navigateToGeoReferenceConverter: () =>
      loggedIn ? history.push('/ui/app/licence/reference/geo-reference-converter/') : history.push('/ui/licence/reference/geo-reference-converter/'),
  };
};

const LicenceReferenceDataRouter: React.FC<Props> = (props) => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/equipment/create`} exact permission={['CREATE_EQUIPMENT']} component={CreateEquipmentPage} />
        <ProtectedRoute
          path={`${path}/equipment/maintain/:equipmentId`}
          exact
          permission={['VIEW_EQUIPMENT', 'UPDATE_EQUIPMENT']}
          component={UpdateEquipmentPage}
        />
        <ProtectedRoute path={`${path}/equipment`} exact permission={['VIEW_EQUIPMENT']} component={SearchEquipmentPage} />

        <ProtectedRoute path={`${path}/antenna/create`} exact permission={['CREATE_ANTENNA']} component={CreateAntennaPage} />
        <ProtectedRoute
          path={`${path}/antenna/maintain/:antennaId`}
          exact
          permission={['VIEW_ANTENNA', 'UPDATE_ANTENNA']}
          component={UpdateAntennaPage}
        />
        <ProtectedRoute path={`${path}/location/create/point`} exact permission={['CREATE_LOCATION']} component={CreatePointPage} />
        <ProtectedRoute path={`${path}/location/create/multiplepoints`} exact permission={['CREATE_LOCATION']} component={CreateMultiPointPage} />
        <ProtectedRoute path={`${path}/location/create/definedarea`} exact permission={['CREATE_LOCATION']} component={CreateDefinedAreaPage} />
        <ProtectedRoute path={`${path}/location/create/locationname`} exact permission={['CREATE_LOCATION']} component={CreateLocationNamePage} />
        <ProtectedRoute
          path={`${path}/location/maintain/:locationId`}
          exact
          permission={['VIEW_LOCATION', 'UPDATE_LOCATION', 'UPDATE_SPEC_LOCATION']}
          component={MaintainLocationPage}
        />
        <ProtectedRoute path={`${path}/spectrumMask`} exact permission={['VIEW_SPECTRUM_MASK']} component={SearchSpectrumMaskPage} />
        <ProtectedRoute path={`${path}/spectrum-mask/create`} exact permission={['CREATE_SPECTRUM_MASK']} component={CreateSpectrumMaskPage} />
        <ProtectedRoute
          path={`${path}/spectrum-mask/maintain/:spectrumMaskId`}
          exact
          permission={['VIEW_SPECTRUM_MASK', 'UPDATE_SPECTRUM_MASK']}
          component={UpdateSpectrumMaskPage}
        />
        <ProtectedRoute path={`${path}/spectrum-record/create`} exact permission={['CREATE_SPEC_RECORD']} component={CreateSpectrumRecordPage} />
        <ProtectedRoute
          path={`${path}/spectrum-record/maintain/:spectrumId`}
          exact
          permission={['VIEW_SPEC_RECORD', 'UPDATE_SPEC_RECORD']}
          component={UpdateSpectrumRecordPage}
        />
        <ProtectedRoute path={`${path}/spectrum`} exact permission={['VIEW_SPEC_RECORD']} component={SearchSpectrumRecordPage} />
        <ProtectedRoute path={`${path}/geo-reference-converter`} component={GeoReferenceTypeConverterPage} />
      </Switch>
    </>
  );
};

export default LicenceReferenceDataRouter;
