import { Formik } from 'formik';
import React from 'react';

import { AntennaDto } from 'api-client';

import Form from '../../../common/form/Form';
import Page from '../../../common/layout/Page';
import { useReferenceDataHookApi } from '../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../ReferenceDataRouter';
import AntennaFields from './components/AntennaFields';
import { antennaSchema } from './validationSchema';

const CreateAntennaPage = () => {
  const { createAntenna } = useReferenceDataHookApi();
  const { navigateToMaintainAntenna } = useLicenceReferenceDataNavigator();

  const formInitValue: AntennaDto = {
    type: '',
    make: '',
    model: '',
    beamWidth: '',
    frontBackRatio: '',
    highFrequency: '',
    lowFrequency: '',
    bizVersion: undefined,
    diameter: undefined,
    gainHigh: undefined,
    gainLow: undefined,
    gainMid: undefined,
    xPol: '',
    remarks: '',
  };

  const submitForm = (values: any) => {
    return createAntenna(
      { ...values, type: values.type?.toUpperCase(), make: values.make?.toUpperCase(), model: values.model?.toUpperCase() } as AntennaDto,
      {
        successMessage: 'Antenna has been created.',
      },
    ).then((response) => navigateToMaintainAntenna(response.id!));
  };

  return (
    <Page title="Create Antenna">
      <Formik initialValues={formInitValue} onSubmit={submitForm} validationSchema={antennaSchema}>
        <Form id={'create-antenna-form'} showFormButtonsBottom mode="CREATE">
          <AntennaFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreateAntennaPage;
