import * as yup from 'yup';

import { isEmpty, validRangeWithDecimal, numberSchema } from '../../../../../common/validation/yupUtils';

export const azimuth = validRangeWithDecimal(0, 360, 1, 'Must be one decimal place and in the range 0 to 360');
export const elevation = validRangeWithDecimal(-90, 90, 1, 'Must be one decimal place and in the range -90.0 to 90.0');
export const antennaHeight = numberSchema
  .integer('Must be an integer')
  .min(-999, 'Must be in the range -999 to 999')
  .max(999, 'Must be in the range -999 to 999');
export const configurationLoss = validRangeWithDecimal(0, 999.9, 1, 'Must be one decimal place and in the range 0 to 999.9');

const txLocSchema = yup.object().shape({
  azimuth: azimuth,
  elevation: elevation,
  antennaHeight: antennaHeight,
  configurationLoss: configurationLoss,
});

export const transmitLocationsSchema = yup.object().shape({
  locations: yup.array().nullable(true).of(txLocSchema),
});

export const protectionRatio = validRangeWithDecimal(0, 100, 1, 'Must be one decimal place and in the range 0.0 to 100');
export const bearing = validRangeWithDecimal(0, 360, 1, 'Must be one decimal place and in the range 0.0 to 360');
export const pathLoss = validRangeWithDecimal(0, 200, 1, 'Must be one decimal place and in the range 0.0 to 200');
export const wantedSignalUomId = yup.number().notRequired().nullable(true);
export const wantedSignal = validRangeWithDecimal(-200, 200, 1, 'Must be one decimal place and in the range -200 to 200').test(
  'wantedSignal',
  `Please specify unit of measurement`,
  function (this: yup.TestContext, value: any) {
    return !(!isEmpty(value) && (this.parent.wantedSignalUomId === undefined || this.parent.wantedSignalUomId === null));
  },
);
export const measuredSignalUomId = yup.number().notRequired().nullable(true);
export const measuredSignal = validRangeWithDecimal(-200, 200, 1, 'Must be one decimal place and in the range -200 to 200')
  .test('measuredSignal', `Please specify unit of measurement`, function (this: yup.TestContext, value: any) {
    return !(!isEmpty(value) && (this.parent.measuredSignalUomId === undefined || this.parent.measuredSignalUomId === null));
  })
  .nullable(true);
export const mpisUomId = yup.number().notRequired().nullable(true);
export const mpis = validRangeWithDecimal(-200, 200, 1, 'Must be one decimal place and in the range -200 to 200')
  .test('mpis', `Please specify unit of measurement`, function (this: yup.TestContext, value: any) {
    return !(!isEmpty(value) && (this.parent.mpisUomId === undefined || this.parent.mpisUomId === null));
  })
  .nullable(true);

const rcvLocSchema = yup.object().shape({
  azimuth: azimuth,
  elevation: elevation,
  antennaHeight: antennaHeight,
  configurationLoss: configurationLoss,
  protectionRatio: protectionRatio,
  bearing: bearing,
  pathLoss: pathLoss,
  wantedSignalUomId: wantedSignalUomId,
  wantedSignal: wantedSignal,
  measuredSignalUomId: measuredSignalUomId,
  measuredSignal: measuredSignal,
  mpisUomId: mpisUomId,
  mpis: mpis,
});

export const receiveLocationsSchema = yup.object().shape({
  locations: yup.array().nullable(true).of(rcvLocSchema),
});
