import * as yup from 'yup';
import { TestContext, ValidationError } from 'yup';

import { ValidationCallsignResponse } from 'api-client';

import { requiredList, requiredString } from '../../common/validation/yupUtils';

const validateCallsign = (testContext: TestContext): undefined | ((_: string) => Promise<ValidationCallsignResponse>) =>
  (testContext.options.context as any)?.context?.validateCallsign;

export const callsignSchema = yup.string().test('is-valid-callsign', 'Invalid', function (this: TestContext, callsign) {
  if (!validateCallsign(this) || !callsign) {
    return true;
  }
  return validateCallsign(this)!(callsign).then(({ success, message, suggestions }) =>
    success
      ? true
      : this.createError({
          message:
            message +
            (suggestions && suggestions.length > 0 ? ' Available callsigns are: ' + suggestions.join(', ') : ' No callsign suggestions are found.'),
        }),
  );
});

export const baseCallsignsSchema = yup
  .array()
  .when(['baseCallSignCapture'], {
    is: (baseCallSignCapture: boolean) => baseCallSignCapture === true,
    then: requiredList,
  })
  .test('is-valid-callsigns', 'Invalid', function (this: TestContext, callsigns) {
    if (!validateCallsign(this)) {
      return true;
    }

    const lastErrorMessage = (results: ValidationCallsignResponse[]) =>
      results
        .filter(({ success }) => !success)
        .map(
          ({ message, suggestions }) =>
            message +
            (suggestions && suggestions.length > 0 ? ' Available callsigns are: ' + suggestions.join(', ') : ' No callsign suggestions are found.'),
        )
        .pop();

    const successOrValidationError = (message?: string): ValidationError | true => (message ? this.createError({ message }) : true);

    return (
      !callsigns?.length ||
      Promise.all((callsigns as string[])?.map(validateCallsign(this)!))
        .then(lastErrorMessage)
        .then(successOrValidationError)
    );
  })
  .nullable(true);

export const mobileCallsignsSchema = yup
  .array()
  .when(['mobileCallSignCapture'], {
    is: (mobileCallSignCapture: boolean) => mobileCallSignCapture === true,
    then: requiredList,
  })
  .test('is-valid-callsigns', 'Invalid', function (this: TestContext, callsigns) {
    if (!validateCallsign(this)) {
      return true;
    }

    const lastErrorMessage = (results: ValidationCallsignResponse[]) =>
      results
        .filter(({ success }) => !success)
        .map(
          ({ message, suggestions }) =>
            message +
            (suggestions && suggestions.length > 0 ? ' Available callsigns are: ' + suggestions.join(', ') : ' No callsign suggestions are found.'),
        )
        .pop();

    const successOrValidationError = (message?: string): ValidationError | true => (message ? this.createError({ message }) : true);

    return (
      !callsigns?.length ||
      Promise.all((callsigns as string[])?.map(validateCallsign(this)!))
        .then(lastErrorMessage)
        .then(successOrValidationError)
    );
  })
  .test('base-mobile-non-duplicate', 'This Callsign has been used, please enter different one.', function (this: TestContext, mobileCallsigns) {
    const baseCallsigns: string[] | undefined = this.parent.baseCallsigns;
    const inBaseCallsigns = (mobile: any) => !!baseCallsigns?.find((base) => base === mobile);
    if (
      (this.parent.baseCallsignNotes == null && this.parent.mobileCallsignNotes == null) ||
      (!this.parent.baseCallSignCapture && !!this.parent.mobileCallSignCapture)
    ) {
      return !mobileCallsigns?.find(inBaseCallsigns);
    }
    return true;
  })
  .nullable(true);

export const returnToEngineerValidationSchema = yup.object().shape({
  reason: requiredString,
});
