import * as React from 'react';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import Dropdown from 'common/controls/inputs/Dropdown';
import SearchForm from 'search/SearchForm';
import { hiddenOptions } from 'search/utils';

import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';

interface Props {
  onClear?: () => void;
  showAddEquipmentButton?: boolean;
}

const SearchEquipmentForm: React.FC<Props> = (props) => {
  const { hasPermission, hasRole, isInternalUser } = useAuth();

  const { navigateToCreateEquipment } = useLicenceReferenceDataNavigator();

  const addEquipmentButtonProps =
    props.showAddEquipmentButton && hasPermission('CREATE_EQUIPMENT')
      ? [{ id: 'add-equipment', text: 'Add a New Equipment', onClick: () => navigateToCreateEquipment() }]
      : undefined;

  const isSystemAdmin = hasRole('ROLE_SYSTEM_ADMINISTRATOR');
  return (
    <>
      <SearchForm
        id="search-equipment-form"
        mode="STAND_ALONE"
        title="Search equipment"
        searchBoxProps={{
          placeholder: 'Make, Model, Band or Identifier.',
          hint: 'You can search by Make, Model, Band or Identifier.',
          required: true,
        }}
        onClear={props.onClear}
        additionalButtons={addEquipmentButtonProps}
        disableErrorMessage
      >
        {isInternalUser && (
          <>
            <Grid.Row>
              <Grid.Col sm={12} lg={4}>
                <Dropdown label="Equipment visibility" name="hidden" options={hiddenOptions} />
              </Grid.Col>
            </Grid.Row>
            {isSystemAdmin && (
              <Grid.Row>
                <Grid.Col sm={12} lg={4} style={{ paddingTop: '10px' }}>
                  <Checkbox name="includeOlderVersions" label="Show old versions" />
                </Grid.Col>
              </Grid.Row>
            )}
          </>
        )}
      </SearchForm>
    </>
  );
};

export default SearchEquipmentForm;
