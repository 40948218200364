import { DefaultButton } from '@fluentui/react';
import React from 'react';

import { SpectrumMaskDto, SpectrumMaskSearchCriteria, SpectrumMaskSearchResults } from 'api-client';

import { Grid } from 'ui-library';

import ExportSearchCommonResults from 'search/common/ExportSearchCommonResults';

import { useAuth } from '../../../common/auth/AuthHook';
import Pagination, { withSorting } from '../../../common/controls/items/Pagination';
import DetailsList from '../../../common/controls/lists/DetailsList';
import { SectionHeading } from '../../../common/layout/SectionHeadings';
import { formatDateFromISO, formatDateTimeFromISO, RRF_DATETIME_FORMAT_SLASH } from '../../../common/utils/dateUtils';
import { parseBoolean } from '../../../common/utils/objectUtils';
import { AppliedFilters } from '../../../search/utils';
import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';

interface Props {
  searchCriteria?: SpectrumMaskSearchCriteria;
  searchResults?: SpectrumMaskSearchResults;
  onPaging: (page: number, pageSize: number) => void;
  onOrderBy: (orderBy: string) => void;
  submitted: boolean;
}

const SearchSpectrumMaskResults: React.FC<Props> = (props) => {
  const { navigateToUpdateSpectrumMask } = useLicenceReferenceDataNavigator();
  const { isInternalUser } = useAuth();

  const columns = [
    {
      key: 'id',
      fieldName: 'id',
      name: 'Id',
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      isNumber: true,
      onRender: (item: SpectrumMaskDto) => item.id,
    },
    ...(isInternalUser
      ? [
          {
            key: 'hidden',
            fieldName: 'hidden',
            name: 'Hidden',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item: SpectrumMaskDto) => (item.hidden ? 'Yes' : ''),
          },
        ]
      : []),
    {
      key: 'identifier',
      fieldName: 'identifier',
      name: 'Identifier',
      minWidth: 250,
      maxWidth: 250,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: 'description',
      fieldName: 'description',
      name: 'Description',
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: 'lastUpdated',
      fieldName: 'lastUpdated',
      name: 'Last updated date',
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
      onRender: (item: SpectrumMaskDto) => formatDateTimeFromISO(item.lastUpdated),
    },
    {
      key: 'view',
      name: '',
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: SpectrumMaskDto) => (
        <DefaultButton
          id={`view-${item.id}`}
          text="View"
          ariaLabel={`View spectrum mask ${item.identifier} button`}
          onClick={() => navigateToUpdateSpectrumMask(item.id!)}
        />
      ),
    },
  ];

  const filtersSummary = (filter: SpectrumMaskSearchCriteria = {}) => {
    return new AppliedFilters()
      .push(parseBoolean(filter.hidden) ? 'Hidden' : parseBoolean(filter.hidden) === false ? 'Visible' : '', 'Spectrum mask visibility')
      .get();
  };

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Hidden', key: 'hidden' },
    { label: 'Identifier', key: 'identifier' },
    { label: 'Description', key: 'description' },
    { label: 'Last updated date', key: 'lastUpdated' },
  ];

  const transformForCsv = (data: SpectrumMaskDto[]) => {
    const copiedData: SpectrumMaskDto[] = JSON.parse(JSON.stringify(data));
    return copiedData.map((o) => Object.assign(o, { lastUpdated: formatDateFromISO(o.lastUpdated, RRF_DATETIME_FORMAT_SLASH) }));
  };

  return (
    <>
      <SectionHeading title="Search results" />
      {props.searchResults?.results && props.searchResults.results.length > 0 ? (
        <>
          <Grid.Row>
            <ExportSearchCommonResults
              headers={isInternalUser ? csvHeaders : csvHeaders.filter((h) => h.label !== 'Hidden')}
              data={transformForCsv(props.searchResults?.results) ?? []}
              currentPage={props.searchCriteria?.page}
              fileNamePrefix="Search Spectrum Mask"
            />
          </Grid.Row>
          <Pagination
            filtersSummary={isInternalUser ? filtersSummary(props.searchCriteria) : undefined}
            renderResults={() => (
              <DetailsList columns={withSorting(columns, props.searchCriteria, props.onOrderBy)} items={props.searchResults?.results ?? []} />
            )}
            onPaging={props.onPaging}
            showIncreasedPageSizes
            {...props.searchResults}
          />
        </>
      ) : props.submitted ? (
        <span>Your search returned no results.</span>
      ) : (
        ''
      )}
    </>
  );
};
export default SearchSpectrumMaskResults;
