import { EngineerDto, LicenceClassificationType, LicenceDto, ServiceTypeDto } from 'api-client';

import { findLicenceType } from 'licence_management/common/utils';

const { A, B, C } = LicenceClassificationType;

export const radioLicenceClassificationOptions = [
  { key: A, text: 'Transmit with Receive Protection' },
  { key: B, text: 'Transmit' },
  { key: C, text: 'Receive' },
];

export const spectrumLicenceClassificationOptions = [
  { key: A, text: 'Licence to Transmit and Have No Interference' },
  { key: B, text: 'Licence to Transmit' },
  { key: C, text: 'Licence to Have No Interference' },
];

export const availableLicenceClassificationsByLicenceType = (licence: Partial<LicenceDto>, serviceTypes: ServiceTypeDto[]) =>
  findLicenceType(licence, serviceTypes)?.availableClassifications ?? [];

export const defaultLicenceClassification = (licence: Partial<LicenceDto>, serviceTypes: ServiceTypeDto[]) =>
  [...availableLicenceClassificationsByLicenceType(licence, serviceTypes), undefined][0];

export const engineerName = (engineer?: EngineerDto, defaultName: string = '') =>
  engineer ? `${engineer?.firstName} ${engineer?.lastName} (${engineer?.approvalType}${engineer?.id})` : defaultName;
