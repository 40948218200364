import { IDropdownOption, Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { AtonDtoAccessModeEnum, AtonDtoStatusEnum, AtonDtoTypeEnum, LocationSearchResult } from 'api-client';

import { Grid } from 'ui-library';

import ChoiceGroup from 'common/controls/inputs/ChoiceGroup';
import Dropdown from 'common/controls/inputs/Dropdown';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { TextField } from 'common/controls/inputs/TextField';
import { FormMode } from 'common/form/Form';
import { SectionHeading } from 'common/layout/SectionHeadings';

import AtonLocationSection from './AtonLocationSection';

interface AtonDetailsSectionProps {
  initialLocation?: LocationSearchResult;
  mode: FormMode;
}

const types = Object.values(AtonDtoTypeEnum);
const accessModes = Object.values(AtonDtoAccessModeEnum);
const status = Object.values(AtonDtoStatusEnum);

const AtonDetailsSection: React.FC<AtonDetailsSectionProps> = (props) => {
  const formikContext = useFormikContext<any>();

  const atonTypeOptions = [{ key: '', text: '' }, ...types.map((s) => ({ key: s, text: s }))] as IDropdownOption[];
  const accessModeOptions = accessModes.map((s) => ({ key: s, text: s }));
  const atonStatusOptions = status.map((s) => ({ key: s, text: s })) as IDropdownOption[];

  return (
    <>
      <SectionHeading title="AtoN details" />
      <Grid>
        <Grid.Row>
          <Grid.Col lg={3}>
            <Dropdown label="AtoN Type" name="type" options={atonTypeOptions} required />
          </Grid.Col>
          <Grid.Col lg={3}>
            <Dropdown label="AtoN status" name="status" options={atonStatusOptions} readOnly />
          </Grid.Col>
        </Grid.Row>
        {atonTypeOptions && formikContext.values.type !== 'MAtoN' && (
          <Grid.Row>
            <Grid.Col>
              <Label required>AtoN name</Label>
            </Grid.Col>

            <Grid.Col>
              <AtonLocationSection
                onSelected={(item) => {
                  formikContext.setFieldValue('locationId', item.id);
                  formikContext.setFieldValue('location', item);
                  formikContext.setFieldValue('atonName', item.name);
                  formikContext.setFieldValue('showLocation', true);
                }}
                initialSelected={props.initialLocation}
                mode={props.mode}
                onRemove={(item) => {
                  formikContext.setFieldValue('locationId', undefined);
                  formikContext.setFieldValue('location', undefined);
                  formikContext.setFieldValue('atonName', undefined);
                  formikContext.setFieldValue('showLocation', false);
                }}
              />
            </Grid.Col>
            <Grid.Col>
              <ErrorLabel name={'locationId'} />
            </Grid.Col>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Col lg={5}>
            {atonTypeOptions && formikContext.values.type === 'MAtoN' && (
              <TextField name="matonName" label="MAtoN name" required maxLength={20} showMaxHint />
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={5}>
            <ChoiceGroup name="accessMode" label="Access mode" direction={'row'} options={accessModeOptions} required />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={3}>
            {accessModeOptions && formikContext.values.accessMode === 'FATDMA' && <TextField name="ais1StartSlot" label="AIS1 Start Slot" required />}
          </Grid.Col>
          <Grid.Col lg={3}>
            {accessModeOptions && formikContext.values.accessMode === 'FATDMA' && <TextField name="ais2StartSlot" label="AIS2 Start Slot" required />}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={3}>
            <TextField name="mmsi" label="MMSI" helpText="The system will allocate a mmsi automatically if you do not complete this field." />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={6}>
            {formikContext.values.status === AtonDtoStatusEnum.Cancelled && (
              <TextField required name="reason" label="Cancel reason" multiline readOnly />
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default AtonDetailsSection;
