import React from 'react';

import { Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { convertFurtherAction } from 'investigation/hooks';

const AuditFurtherActionSection = () => {
  return (
    <>
      <SectionHeading title="Further action" />
      <Grid2>
        <Grid2.Col colStart={1} lg={5}>
          <ReadOnlyField label="Further action" name="furtherAction" renderFormat={convertFurtherAction} />
        </Grid2.Col>
        <Grid2.Col lg={7}>
          <ReadOnlyField label="Notes" name="furtherActionNotes" />
        </Grid2.Col>
      </Grid2>
    </>
  );
};

export default AuditFurtherActionSection;
