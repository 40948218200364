import { UserDto, UserPreferencesDto, FeatureFlagDto } from 'api-client';

import { decodeJWT, Jwt, saveJWT } from '../auth/jwtUtils';

export enum TypeKeys {
  SET_ROLE_CLIENT_PROFILE = 'SET_ROLE_CLIENT_PROFILE',
  SET_AGREED_VIEW_SUPPRESSED = 'SET_AGREED_VIEW_SUPPRESSED',
  SET_USER_PREFERENCES = 'SET_USER_PREFERENCES',
  SET_USER_NAME = 'SET_USER_NAME',
  SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
  OTHER_ACTION = 'OTHER_ACTION',
}

// --------------------------
// set new Role and client
// --------------------------
export interface SetRoleClientProfile {
  type: TypeKeys.SET_ROLE_CLIENT_PROFILE;
  payload: Jwt;
}

// Action creator
export const setRoleClientProfile = (newJwt: string) => {
  saveJWT(newJwt);
  return {
    type: TypeKeys.SET_ROLE_CLIENT_PROFILE,
    payload: decodeJWT(newJwt),
  };
};

export interface SetAgreedViewSuppressedAction {
  type: TypeKeys.SET_AGREED_VIEW_SUPPRESSED;
  payload: boolean;
}

export const setAgreedViewSuppressed = (yes: boolean): SetAgreedViewSuppressedAction => ({
  type: TypeKeys.SET_AGREED_VIEW_SUPPRESSED,
  payload: yes,
});

export interface SetUserPreferences {
  type: TypeKeys.SET_USER_PREFERENCES;
  payload: UserPreferencesDto;
}

// Action creator
export const setUserPreferences = (preference: UserPreferencesDto) => {
  return {
    type: TypeKeys.SET_USER_PREFERENCES,
    payload: preference,
  };
};

export interface SetUserName {
  type: TypeKeys.SET_USER_NAME;
  payload: UserDto;
}

export const setUserName = (userDto: UserDto) => {
  return {
    type: TypeKeys.SET_USER_NAME,
    payload: userDto,
  };
};

export interface SetFeatureFlags {
  type: TypeKeys.SET_FEATURE_FLAGS;
  payload: FeatureFlagDto[];
}

// Action creator
export const setFeatureFlags = (featureFlags: FeatureFlagDto[]) => {
  return {
    type: TypeKeys.SET_FEATURE_FLAGS,
    payload: featureFlags,
  };
};

// --------------------------
// OtherAction
// --------------------------

export interface OtherAction {
  type: TypeKeys.OTHER_ACTION;
}

export type LoginStateActionType =
  | SetRoleClientProfile
  | SetAgreedViewSuppressedAction
  | SetUserPreferences
  | SetUserName
  | SetFeatureFlags
  | OtherAction;
