import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import Overlay from '../surfaces/Overlay';
import { getClassNames } from './OverlaySpinner.classNames';

interface Props {
  label: string;
}

const OverlaySpinner: React.FC<Props> = (props) => {
  const { root } = getClassNames();
  const spinnerStyle = {
    label: {
      color: '#d14904',
    },
    circle: {
      borderColor: '#d14904', // circle color
      borderRightColor: 'rgba(0, 0, 0, 0)', // transparent
      borderWidth: '3px', // circle width
    },
  };
  return (
    <>
      <Overlay />
      <div className={root}>
        <Spinner size={SpinnerSize.large} label={props.label} styles={spinnerStyle} />
      </div>
    </>
  );
};

export default OverlaySpinner;
