import { validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';

import { atonValidationSchema } from './create/validationSchema';

const validationAtonForm = (values: any) => {
  try {
    validateYupSchema(values, atonValidationSchema, true, { ...values });
  } catch (err) {
    return yupToFormErrors(err);
  }
  return {};
};

const atonFormikValidator = (schema: any, apiErrors: any) => (values: any) => {
  try {
    validateYupSchema(values, schema, true, { ...values });
  } catch (err) {
    return _.merge(yupToFormErrors(err), apiErrors);
  }
  return { ...apiErrors };
};

const atonValidateForm = (helper: any, validationSchema: any, apiErrors: any) => {
  let validationErrors = {};
  try {
    validateYupSchema(helper.values, validationSchema, true, { ...helper.values });
  } catch (err) {
    validationErrors = _.merge(yupToFormErrors(err), apiErrors);
  }

  if (!_.isEmpty(validationErrors)) {
    helper.setErrors(validationErrors);
    helper.setTouched(validationErrors);
  } else {
    return true;
  }

  return false;
};

export { validationAtonForm, atonValidateForm, atonFormikValidator };
