import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';
import BpmManagement from 'development/BpmManagement';
import FeatureFlagManagement from 'development/FeatureFlagManagement';
import TestDocumentPage from 'document_management/TestDocumentPage';

import AzureSearch from './AzureSearch';

export const DevelopmentRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute role={['ROLE_SYSTEM_ADMINISTRATOR']} path={`${path}/azure-search`} component={AzureSearch} />
      <ProtectedRoute role={['ROLE_SYSTEM_ADMINISTRATOR']} path={`${path}/bpm`} component={BpmManagement} />
      <ProtectedRoute role={['ROLE_SYSTEM_ADMINISTRATOR']} path={`${path}/feature-flags`} component={FeatureFlagManagement} />
      <ProtectedRoute role={['ROLE_SYSTEM_ADMINISTRATOR']} path={`${path}/test-drm`} component={TestDocumentPage} />
    </Switch>
  );
};

export const useDevelopmentNavigation = () => {
  const history = useHistory();

  return {
    navigateToAzureSearch: () => history.push(`/ui/app/development/azure-search`),
    navigateToBpmManagement: () => history.push(`/ui/app/development/bpm`),
    navigateToFeatureFlagManagement: () => history.push(`/ui/app/development/feature-flags`),
  };
};

export default DevelopmentRouter;
