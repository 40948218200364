import { Formik } from 'formik';
import React from 'react';

import { HeightUnitEnum } from 'api-client';

import InlineForm from '../../../../common/form/InlineForm';
import { SubSectionHeading } from '../../../../common/layout/SectionHeadings';
import { validateWithFormValues } from '../../../../common/validation/validationWithFormValues';
import PointFields from '../components/PointFields';
import { createPointSchema } from '../validationSchema';

interface Props {
  onCancel: () => void;
  onAdd: (pointDto: any) => void;
}

const EmbeddedCreatePoint = (props: Props) => {
  const initValue = {
    locationName: '',
    heightUom: HeightUnitEnum.M,
    height: 0,
    source: undefined,
    georeferenceType: 'D2000',
    georeference: {
      origin: {
        type: 'D2000',
        original: true,
        northing: '',
        easting: '',
        mapNumber: '',
        order: 0,
      },
    },
  };

  const additionalButtons = () => {
    return [
      {
        id: 'cancel-create-point-location',
        onClick: props.onCancel,
        text: 'Cancel',
      },
    ];
  };

  return (
    <Formik initialValues={initValue} onSubmit={props.onAdd} validate={validateWithFormValues(createPointSchema, {})}>
      <InlineForm
        id={'create-embedded-point-location-form'}
        showFormButtonsBottom
        hideFormButtonsTop
        hideBack
        mode="CREATE"
        additionalButtons={additionalButtons}
      >
        <SubSectionHeading title={'Add a new location'} />
        <PointFields isSatellite />
      </InlineForm>
    </Formik>
  );
};

export default EmbeddedCreatePoint;
