import { mergeStyles, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';

import usePage from './PageHook';

export const PageInstruction = () => {
  const className = mergeStyles({
    gridArea: 'instructions',
  });

  const expiryTime = 10;
  const [count, setCount] = React.useState(expiryTime);

  const { pageInstruction, setPageInstruction } = usePage();

  // Reset the auto-dismiss count for a fresh success message
  React.useEffect(() => setCount(expiryTime), [pageInstruction]);

  React.useEffect(() => {
    let timeout: any = null;
    if (pageInstruction && pageInstruction.type === MessageBarType.success && !pageInstruction.noExpiry) {
      timeout = setTimeout(() => {
        if (count === 1) {
          setPageInstruction();
          setCount(expiryTime);
        } else {
          setCount(count - 1);
        }
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [count, setCount, pageInstruction, setPageInstruction]);

  return pageInstruction?.message ? (
    <MessageBar
      delayedRender={false}
      data-automation-id="page-instruction"
      className={className}
      messageBarType={pageInstruction.type}
      onDismiss={() => setPageInstruction()}
    >
      {pageInstruction.message}
    </MessageBar>
  ) : (
    <div className={className} style={{ minHeight: '35px' }} />
  );
};
