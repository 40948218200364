import {
  VerticalDivider,
  Toolbar,
  HistoryButtonGroup,
  BasicFormattingButtonGroup,
  ListButtonGroup,
  HeadingLevelButtonGroup,
  useCommands,
  DropdownButton,
  CommandMenuItem,
  DecreaseFontSizeButton,
  CommandButtonGroup,
  IncreaseFontSizeButton,
  useActive,
  InsertHorizontalRuleButton,
  ToggleSubscriptButton,
  ToggleSuperscriptButton,
  ToggleStrikeButton,
  DataTransferButtonGroup,
} from '@remirror/react';
import React from 'react';
import {
  FontSizeExtension,
  HorizontalRuleExtension,
  LinkExtension,
  StrikeExtension,
  SubExtension,
  SupExtension,
  wysiwygPreset,
} from 'remirror/extensions';

import { thymeleafVariableRegex } from './EditorUtils';
import VariableMenu from './VariableMenu';

export interface ToolbarProps {
  value: string;
  defaultVariables?: string[];
}

const FONT_SIZES = ['8', '10', '12', '14', '16', '18', '24', '30'];

const FontSizeButtons = () => {
  const { setFontSize } = useCommands();
  const { fontSize } = useActive();
  return (
    <DropdownButton aria-label="Set font size" icon="fontSize">
      {FONT_SIZES.map((size) => (
        <CommandMenuItem
          key={size}
          commandName="setFontSize"
          onSelect={() => setFontSize(size)}
          enabled={setFontSize.enabled(size)}
          active={fontSize({ size })}
          label={size}
          icon={null}
          displayDescription={false}
        />
      ))}
    </DropdownButton>
  );
};

export const getToolbarExtensions = () => [
  ...wysiwygPreset(),
  new FontSizeExtension({ defaultSize: '14', unit: 'px' }),
  new HorizontalRuleExtension(),
  new StrikeExtension(),
  new SubExtension(),
  new SupExtension(),
  new LinkExtension({ autoLink: true }),
];

const EditorToolbar: React.FC<ToolbarProps> = ({ value, defaultVariables = [] }) => {
  const variables = new Set(value ? [...(value.match(thymeleafVariableRegex)?.map((match) => match) ?? []), ...defaultVariables] : defaultVariables);
  return (
    <Toolbar>
      <HistoryButtonGroup />
      <VerticalDivider />
      <DataTransferButtonGroup />
      <VerticalDivider />
      <HeadingLevelButtonGroup />
      <VerticalDivider />
      <CommandButtonGroup>
        <DecreaseFontSizeButton />
        <FontSizeButtons />
        <IncreaseFontSizeButton />
      </CommandButtonGroup>
      <VerticalDivider />
      <BasicFormattingButtonGroup />
      <CommandButtonGroup>
        <ToggleStrikeButton />
        <ToggleSuperscriptButton />
        <ToggleSubscriptButton />
      </CommandButtonGroup>
      <VerticalDivider />
      <ListButtonGroup />
      <VerticalDivider />
      <CommandButtonGroup>
        <VariableMenu variables={variables} />
        <InsertHorizontalRuleButton />
      </CommandButtonGroup>
    </Toolbar>
  );
};

export default EditorToolbar;
