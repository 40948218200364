import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';

import { ModelError } from 'api-client';

export async function pollUntilCondition<Type>(
  pollFn: () => Promise<Type>,
  conditionFn: (result: Type) => boolean,
  depth = 0,
  maxDepth = 9,
): Promise<Type> {
  try {
    const response = await pollFn();
    if (!conditionFn(response)) throw new Error();
    return response;
  } catch (e) {
    if (depth === maxDepth) {
      throw e;
    }
    // waits 10ms initially and doubles wait time each run, default up to 10s
    await wait(2 ** depth * 10);

    return pollUntilCondition(pollFn, conditionFn, depth + 1);
  }
}

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function apiErrorToFormErrors(error: AxiosError<ModelError>, formikHelpers: FormikHelpers<any>): void {
  error?.response?.data?.fields?.forEach((field) => {
    field.name && formikHelpers.setFieldError(field.name, field.error);
  });
}
