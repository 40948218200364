import { ApiConfig, useApi } from 'common/api/ApiHook';
import { camundaMaintainApi } from 'common/api/api';

const useBpmMaintainApi = () => {
  const { aroundApi } = useApi();

  return {
    serializeObejct: (processDefinitionId: string, variable: string, config?: ApiConfig) =>
      aroundApi(camundaMaintainApi.serializeObject(processDefinitionId, variable), config),
  };
};

export default useBpmMaintainApi;
