import { DefaultButton } from '@fluentui/react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Grid } from 'ui-library';

import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { DnDCard } from 'common/controls/surfaces/DnDCard';
import { useForm } from 'common/form/FormHook';
import { useMedia } from 'common/layout/MediaHook';
import { SectionHeading } from 'common/layout/SectionHeadings';

import { SpectrumMaskPoint } from './SpectrumMaskPoint';
import { SpectrumMaskPointTableHeader } from './SpectrumMaskPointTableHeader';

interface SpectrumMaskPointsProps {
  readOnly?: boolean;
  addBtnText?: string;
}

export const SpectrumMaskPoints: React.FC<SpectrumMaskPointsProps> = (props) => {
  const { isMobile } = useMedia();
  return (
    <>
      <SectionHeading title={'Mask points'} hint="Note - Graph points can be re-ordered using drag and drop" />
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          {!isMobile && <SpectrumMaskPointTableHeader />}
          <FieldArray name={'spectrumMaskPoints'} render={(arrayHelpers) => <SpectrumMaskPointsComponent {...arrayHelpers} {...props} />} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const SpectrumMaskPointsComponent = (props: FieldArrayRenderProps & SpectrumMaskPointsProps) => {
  const { values } = useFormikContext<any>();
  const { mode } = useForm();

  return (
    <>
      {mode === 'VIEW' || props.readOnly ? (
        values.spectrumMaskPoints &&
        values.spectrumMaskPoints.length > 0 &&
        values.spectrumMaskPoints.map((spectrumMaskPoint: 'SpectrumMaskPointDto', index: number) => (
          <div key={`spectrum-mask-point-${index}`}>
            <SpectrumMaskPointRow {...props} itemNumber={index} />
          </div>
        ))
      ) : (
        <DndProvider backend={HTML5Backend}>
          {values.spectrumMaskPoints &&
            values.spectrumMaskPoints.length > 0 &&
            values.spectrumMaskPoints.map((spectrumMaskPoint: 'SpectrumMaskPointDto', index: number) => (
              <div key={`spectrum-mask-point-${index}`}>
                <Container {...props} itemNumber={index} />
              </div>
            ))}
        </DndProvider>
      )}
      <>
        {!(props.readOnly || mode === 'VIEW') && (
          <Grid.Row>
            <Grid.Col sm={12} lg={15}>
              <DefaultButton
                text={props.addBtnText ?? 'Add Mask Point'}
                onClick={() => {
                  props.push({
                    displacement: undefined,
                    frequency: undefined,
                    dbwSetLevel: undefined,
                    calcLevel: undefined,
                    displacementOrFrequenyError: undefined,
                    calcLevelOrSetLevelError: undefined,
                    graphPointIndex: values.spectrumMaskPoints.length,
                  });
                }}
                id={'add-sm-point'}
                data-automation-id={'add-spectrum-mask-point'}
              />
            </Grid.Col>
          </Grid.Row>
        )}
      </>
    </>
  );
};

export const Container: React.FC<any> = (props: FieldArrayRenderProps & { itemNumber: number }) => {
  const { mode } = useForm();

  const moveCard = (arrayHelper: FieldArrayRenderProps) => (dragIndex: number, hoverIndex: number) => {
    arrayHelper.move(dragIndex, hoverIndex);
  };

  const renderCard = (index: number, props: FieldArrayRenderProps) => {
    return (
      <DnDCard
        key={index}
        index={index}
        id={index}
        item={<SpectrumMaskPointRow {...props} itemNumber={index} readOnly={mode === 'VIEW'} />}
        moveCard={moveCard(props)}
      />
    );
  };

  return <>{renderCard(props.itemNumber, props)}</>;
};

const SpectrumMaskPointRow = (props: FieldArrayRenderProps & { itemNumber: number; readOnly?: boolean }) => {
  const { mode } = useForm();
  const { isMobile } = useMedia();
  const isAlwaysValidate = mode === 'EDIT';
  const { values, setValues } = useFormikContext<any>();

  return (
    <>
      <Grid.Row>
        <SpectrumMaskPoint name={`spectrumMaskPoints.${props.itemNumber}`} graphPointIndex={props.itemNumber} required />

        {!(props.readOnly || mode === 'VIEW') && (
          <Grid.Col sm={12} lg={2}>
            <DefaultButton
              style={{ marginTop: isMobile ? '5px' : 0 }}
              text={'Remove'}
              onClick={() => {
                props.remove(props.itemNumber);
                setValues({
                  ...values,
                  spectrumMaskPoints: values.spectrumMaskPoints
                    .filter((s: any, idx: number) => idx !== props.itemNumber)
                    .map((s: any, idx: number) => ({
                      ...s,
                      graphPointIndex: s.graphPointIndex - 1,
                    })),
                });
              }}
              id={'remove-spectrum-mask-point-' + props.itemNumber}
              data-automation-id={'remove-spectrum-mask-point-' + props.itemNumber}
            />
          </Grid.Col>
        )}
      </Grid.Row>

      <ErrorLabel name={`spectrumMaskPoints.${props.itemNumber}.displacementOrFrequenyError`} showIcon={false} alwaysValidate={isAlwaysValidate} />
      <ErrorLabel name={`spectrumMaskPoints.${props.itemNumber}.calcLevelOrSetLevelError`} showIcon={false} alwaysValidate={isAlwaysValidate} />
    </>
  );
};
