import { useFormikContext } from 'formik';
import * as React from 'react';

import { ClientSearchCriteriaTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import ChoiceGroup from 'common/controls/inputs/ChoiceGroup';
import Form from 'common/form/Form';
import { CALLSIGN_TYPE } from 'common/reference/referenceData';

import AmateurClubCallsign from '../components/AmateurClubCallsign';
import ClientSectionCallsign from '../components/ClientSectionCallsign';
import ShipCallsign from '../components/ShipCallsign';

interface CreateCallsignProps {
  handleSubmit: () => void;
}

const CreateCallsignForm: React.FC<CreateCallsignProps> = (props) => {
  const formikContext = useFormikContext<any>();

  const cleanClientPicker = (option: any) => {
    const cleanForm =
      option.key === 'SHIP_CALLSIGN'
        ? {
            trusteePicker: '',
            trusteeCertificate: null,
            trusteeClientId: '',
            trusteeName: '',
            primaryCallsign: {
              callsign: null,
              callsignDiscriminator: 'PrimaryCallsignDto',
            },
            secondaryCallsign: undefined,
            temporaryCallsigns: [],
            note: '',
          }
        : {
            equipmentType: null,
            scn: '',
            mmsi: '',
            callsign: '',
            vesselName: '',
            mmsiCraftAssociated: [],
            isInmarsatSatellite: false,
            isSelectiveCallNumber: false,
            isClassAShip: false,
          };

    formikContext.setValues({
      ...formikContext.values,
      ...cleanForm,
      callsignType: option.key,
      clientPicker: '',
      email: '',
      clientId: '',
      clientName: '',
      address: '',
      phoneNumbers: undefined,
    });
  };

  return (
    <Form id="create-callsign-form" showFormButtonsBottom mode="CREATE" onSubmit={formikContext.isValid ? props.handleSubmit : undefined}>
      <Grid.Row>
        <Grid.Col sm={12} lg={6}>
          <ChoiceGroup
            name="callsignType"
            label="Callsign type"
            direction={'row'}
            options={CALLSIGN_TYPE}
            required
            onChoiceChange={cleanClientPicker}
          />
        </Grid.Col>
      </Grid.Row>

      <ClientSectionCallsign
        disabled={!formikContext.values.callsignType}
        typeSearch={formikContext.values.callsignType === 'AMATEUR_CLUB_CALLSIGN' ? ClientSearchCriteriaTypeEnum.Amateur_Radio_Club : undefined}
        isRequired={true}
        hint={formikContext.values.callsignType === 'AMATEUR_CLUB_CALLSIGN' ? 'The client must be an amateur club.' : undefined}
      />

      {formikContext.values.callsignType === 'AMATEUR_CLUB_CALLSIGN' && <AmateurClubCallsign />}
      {formikContext.values.callsignType === 'SHIP_CALLSIGN' && <ShipCallsign />}
    </Form>
  );
};

export default CreateCallsignForm;
