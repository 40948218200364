import moment from 'moment';
import * as yup from 'yup';

import { isMonetary } from '../utils/monetaryUtils';

export const numberSchema = yup.number().typeError('Must be a number').nullable(true);

export const dateSchema = yup.date().nullable(true).typeError('Must be a date');

export const requiredString = yup.string().trim().required('Required').nullable(true);

export const notRequiredString = yup.string().notRequired().nullable(true);

export const requiredNumber = numberSchema.required('Required');

export const requiredBoolean = yup.boolean().required('Required').nullable(true);

export const notRequiredBoolean = yup.boolean().notRequired().nullable(true);

export const requiredDecimal = (numberDecimal: number, message: string) =>
  yup
    .number()
    .typeError(message)
    .nullable(true)
    .test('numberDecimal', message, (value) => (String(value).split('.')[1] ? String(value).split('.')[1].length : 0) <= numberDecimal);

export const validFrequency = requiredDecimal(6, 'Must be a positive number')
  .test('', 'Must be a positive number', (value) => (value ? value.toString().match(/^(\d*\.)?\d+$/) !== null : true))
  .test('', 'Must not exceed decimal(6,6)', (value) => (value ? value.toString().match(/^(?!-0(\.0+)?$)-?(0|\d{1,6})(\.\d{0,6})?$/) !== null : true));

export const wholeNumber = numberSchema.test('', 'Must be a whole number', (value) =>
  value ? value.toString().match(/^-?\d+\.\d+$/) === null : true,
);

export const wholePositiveNumber = yup
  .string()
  .nullable(true)
  .test('', 'Must be a whole positive number', (value) => (value ? value.toString().match(/^\d+$/) !== null : true));

export const requiredWholeNumber = wholeNumber.required('Required');

export const requiredWholePositiveNumber = wholePositiveNumber.required('Required');

export const notRequiredNumber = numberSchema.notRequired();

export const emailSchema = yup.string().email(`Please check your email address, it doesn't seem to be in a known format`).nullable(true);

export const requiredEmail = emailSchema.required('Required');

export const requiredDate = dateSchema.required('Required');

export const requiredList = yup.array().required('Required');

export const notRequiredDate = dateSchema.notRequired();

export const moneyValueSchema = yup
  .string()
  .test('money', 'Must be a monetary value', function (this: yup.TestContext, value) {
    return value ? isMonetary(value) : true;
  })
  .nullable(true);

export const requiredMoneyValue = moneyValueSchema.required('Required');

export const validDateRange = (fromName: string, toName: string, message?: string) =>
  dateSchema.test(fromName, message ? message : "The 'from' date must not be after the 'to' date", function (this: yup.TestContext, value) {
    return !value || !this.parent[toName] || moment(value).isSameOrBefore(this.parent[toName]);
  });

export const requiredValidDateRange = (fromName: string, toName: string, message?: string) =>
  validDateRange(fromName, toName, message).required('Required');

export const mustBePastOrPresentDate = (message: string = 'Must not be a future date') =>
  yup.date().test('not-future-date', message, (date) => !date || !isAfterToday(date));

export const mustBeFutureOrPresentDate = (message: string = 'Must not be a past date') =>
  yup.date().test('not-past-date', message, (date) => !date || !isBeforeToday(date));

export const validRange = (min: number, max: number, message: string) => numberSchema.min(min, message).max(max, message);

export const validRangeWithDecimal = (min: number, max: number, numberDecimal: number, message: string) =>
  numberSchema
    .min(min, message)
    .max(max, message)
    .test('numberDecimal', message, (value) => (String(value).split('.')[1] ? String(value).split('.')[1].length : 0) <= numberDecimal);

export const requiredValidRange = (min: number, max: number, message: string) => validRange(min, max, message).required('Required');

export const requiredValidRangeWithDecimal = (min: number, max: number, numberDecimal: number, message: string) =>
  validRangeWithDecimal(min, max, numberDecimal, message).required('Required');

export const monetaryMinMax = (min: number, max: number, value?: string | null): boolean => {
  if (isEmpty(value)) {
    return true;
  }
  // @ts-ignore
  let numericalValue = monetaryNumVal(value);

  if (Object.is(numericalValue, -0)) {
    return false;
  }

  if (!Number.isNaN(numericalValue)) {
    return numericalValue >= min && numericalValue <= max;
  }
  return true;
};

export const monetaryMax = (max: number, value?: string): boolean => {
  if (isEmpty(value)) {
    return true;
  }
  // @ts-ignore
  let numericalValue = monetaryNumVal(value);
  if (!Number.isNaN(numericalValue)) {
    return numericalValue <= max;
  }
  return true;
};

export const monetaryMin = (min: number, value?: string): boolean => {
  if (isEmpty(value)) {
    return true;
  }
  // @ts-ignore
  let numericalValue = monetaryNumVal(value);
  if (!Number.isNaN(numericalValue)) {
    return numericalValue >= min;
  }
  return true;
};

const monetaryNumVal = (value: string) => Number.parseFloat(value.replace(/,/g, ''));

export const isBlank = (value?: string | null): boolean => isEmpty(value) || isEmpty(value?.toString()!.trim());

export const isEmpty = (value?: string | number | null): boolean => value === undefined || value === null || value === '';

export const isNotEmpty = (value?: string | null): boolean => !isEmpty(value);

export const isAfterToday = (date: Date | string) => moment(date).isAfter(moment(), 'day');

export const isBeforeToday = (date: Date | string) => moment(date).isBefore(moment(), 'day');

export function isDigits(val?: string | null) {
  if (isEmpty(val)) {
    // Skip since .required() will catch this
    return true;
  }
  const match = val ? val.match(/[0-9]+/g) : null;
  return match !== null && match.length > 0 && match[0] === val;
}

export function isDigitsOfLength(len: number, val?: string | null) {
  return isDigits(val) && String(val).length === len;
}

export const requiredDigitsSchema = yup
  .string()
  .nullable(true)
  .required('Required')
  .test('digits', 'Please enter only numbers', (value) => isDigits(value));

export const digitsSchema = yup
  .string()
  .nullable(true)
  .test('digits', 'Please enter only numbers', (value) => isDigits(value));

export const validMoneyRange = (fromName: string, toName: string, message?: string) =>
  moneyValueSchema.test(fromName, message ? message : 'Must be less than or equal to To amount', function (this: yup.TestContext, value) {
    if (value !== null && value !== undefined && value !== '' && !isEmpty(this.parent[toName])) {
      return Number.parseFloat(value.replace(/,/g, '')) <= Number.parseFloat(this.parent[toName].replace(/,/g, ''));
    }
    return true;
  });

export const phoneNumber = yup
  .object({
    countryCode: digitsSchema,
    areaCode: digitsSchema,
    number: digitsSchema,
  })
  .nullable(true);

const requiredPhoneNumberObject = {
  countryCode: requiredString,
  areaCode: requiredDigitsSchema,
  number: requiredDigitsSchema.test('ph-num-min-6', 'Please enter at least 6 digits', (val) => (val ? val.length >= 6 : true)),
};

export const requiredPhoneNumber = yup.object(requiredPhoneNumberObject).nullable(true);

export const requiredPhoneNumbers = yup
  .array()
  .of(
    yup.object({
      ...requiredPhoneNumberObject,
      type: requiredString,
    }),
  )
  .nullable(true);

export const requiredWhenManualAddress = yup
  .string()
  .when(['isManual', 'pafId', 'addressId'], {
    is: (isManual: boolean, pafId: string, addressId: string) => isManual || (!!addressId && pafId === null),
    then: requiredString,
    otherwise: notRequiredString,
  })
  .nullable(true);

export const requiredWhenNZCountryCode = notRequiredString.when(['countryCode'], {
  is: (countryCode: string) => countryCode === 'NZ',
  then: requiredString,
});

export const addressSchema = yup
  .object({
    isManual: notRequiredBoolean,
    addressId: wholePositiveNumber,
    pafId: notRequiredString,
    addressTypeCode: notRequiredString,
    streetName: notRequiredString,
    suburb: notRequiredString,
    cityTown: notRequiredString,
    postCode: requiredWhenNZCountryCode,
    countryCode: notRequiredString,
  })
  .nullable(true);

export const requiredAddressSchema = yup
  .object()
  .shape({
    isManual: notRequiredBoolean,
    addressId: wholePositiveNumber,
    pafId: notRequiredString,
    addressTypeCode: notRequiredString,
    streetName: requiredWhenManualAddress,
    suburb: notRequiredString,
    cityTown: notRequiredString,
    postCode: requiredWhenNZCountryCode,
    countryCode: requiredWhenManualAddress,
  })
  .nullable(true);

export const addressPafIdRequiredWhenSearching = (value: any) => {
  if (!!value && value.isManual) {
    return true;
  }

  if (!!value && value.addressTypeCode === undefined) {
    return false;
  }

  if (value && value.addressTypeCode !== 'PHY') {
    return true;
  }

  if (!!value && value.addressId && (value.pafId === undefined || value.pafId === null)) {
    return true;
  }

  return !!value && !!value.pafId;
};

const isValidNZBN = (nzbn?: string) => {
  if (nzbn === undefined || nzbn === null || nzbn === '') {
    return false;
  }
  const gtin = require('gtin.js');
  const Gtin = gtin.Gtin;
  return Gtin.isValid(nzbn);
};

const errMessage = `Please check your NZBN number, it doesn't seem to be in a known format`;

export const nzbnSchema = yup
  .string()
  .test('nzbn', errMessage, (nzbn: any) => isEmpty(nzbn) || nzbn.length === 13)
  // .test('nzbn', errMessage, (nzbn: string) =>  isEmpty(nzbn) || nzbn.match(/94.+/) !== null)
  .test('nzbn', errMessage, (nzbn: any) => isEmpty(nzbn) || isValidNZBN(nzbn))
  .nullable(true);

export const requiredSpectrum = yup.array().of(
  yup
    .object()
    .shape({
      spectrumId: requiredString.nullable(false),
    })
    .required('Required object'),
);

export const requiredMultipleEmailSchema = (message?: string) =>
  yup
    .string()
    .required('Required')
    .nullable(true)
    .test({
      name: 'email',
      test: function (value) {
        if (isEmpty(value)) {
          return true;
        }
        const firstInvalidEmail = value
          .split(',')
          .filter((v: any) => !isEmpty(v))
          .find((email: string) => !yup.string().email().required('Required').nullable(true).isValidSync(email.trim()));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: message ? message : `The email address '${firstInvalidEmail}' is invalid.`,
            });
      },
    });

export const multipleEmailSchema = yup
  .string()
  .nullable(true)
  .test({
    name: 'email',
    test: function (value) {
      if (isEmpty(value)) {
        return true;
      }
      const firstInvalidEmail = value
        .split(',')
        .filter((v: any) => !isEmpty(v))
        .find((email: string) => !yup.string().email().nullable(true).isValidSync(email.trim()));

      return !firstInvalidEmail
        ? true
        : this.createError({
            message: `The email address '${firstInvalidEmail}' is invalid.`,
          });
    },
  });

export const timeSchema = yup
  .string()
  .test('valid-time', 'Must be a time', (time) => isEmpty(time) || !!time!.match(/^([01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/))
  .nullable(true);
