import { Grid } from 'ui-library';

import Checkbox from 'common/controls/inputs/Checkbox';
import { TextField } from 'common/controls/inputs/TextField';

import HeaderInformation from './HeaderInformation';
import LocationAuditInformation from './LocationAuditInformation';
import { LocationFormProps } from './PointFields';

const NameFields = (props: LocationFormProps) => {
  return (
    <>
      <HeaderInformation type={'Name'} districtsRequired />
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label={'Location description'} name={'description'} multiline />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <Checkbox name={'verified'} label={'Verified'} />
        </Grid.Col>
        {props.showHidden && (
          <Grid.Col sm={12} lg={5}>
            <Checkbox name={'hidden'} label={'Hidden'} />
          </Grid.Col>
        )}
      </Grid.Row>
      {props.showAudit && <LocationAuditInformation />}
    </>
  );
};

export default NameFields;
