import { IColumn } from '@fluentui/react';
import * as React from 'react';

import { GeoReference, GeoreferenceTypeEnum, LocationSearchResult } from 'api-client';

import InlineButton from 'common/controls/buttons/InlineButton';
import DetailsList, { ColumnFactoryFunctionProps } from 'common/controls/lists/DetailsList';
import { useMedia } from 'common/layout/MediaHook';

interface Props {
  initialLocations?: LocationSearchResult[];
  onRemove: (config: LocationSearchResult) => void;
  editable: boolean;
}

const D2000 = GeoreferenceTypeEnum.D2000;

const renderEasting = (item: LocationSearchResult) => {
  return item.geoReferences!.filter((x: GeoReference) => x.type === D2000)[0].easting;
};

const renderNorthing = (item: LocationSearchResult) => {
  return item.geoReferences!.filter((x: GeoReference) => x.type === D2000)[0].northing;
};

const userPrefGeoRefType = undefined;

const columns = ({ isMobile, onView, onRemove, hideRemove }: ColumnFactoryFunctionProps): IColumn[] => [
  {
    key: 'name',
    name: 'Location name',
    fieldName: 'name',
    minWidth: isMobile ? 0 : 100,
    maxWidth: 250,
    isResizable: true,
    isMultiline: true,
    headerClassName: 'pointer-cursor-header',
    onRender: (item: LocationSearchResult) => item.name,
  },
  {
    key: 'gridReference',
    name: 'Grid reference',
    fieldName: 'gridReference',
    minWidth: isMobile ? 0 : 150,
    maxWidth: 220,
    isResizable: true,
    headerClassName: 'pointer-cursor-header',
    onRender: (item: LocationSearchResult) => {
      const byTypeThenOrder = (g1: GeoReference, g2: GeoReference) => g1.typePriority * 10 - g2.typePriority * 10 + g1.order - g2.order;
      const geoRefs = item?.geoReferences?.sort(byTypeThenOrder) ?? [];
      return (geoRefs.find((g) => g.type === userPrefGeoRefType) ?? [...geoRefs, undefined][0])?.gridReference;
    },
  },
  {
    key: 'latitude',
    name: 'Latitude',
    fieldName: 'northing',
    minWidth: isMobile ? 0 : 150,
    maxWidth: 200,
    isResizable: true,
    headerClassName: 'pointer-cursor-header',
    onRender: renderNorthing,
  },
  {
    key: 'longitude',
    name: 'Longitude',
    fieldName: 'easting',
    minWidth: isMobile ? 0 : 150,
    maxWidth: 200,
    isMultiline: true,
    isResizable: true,
    headerClassName: 'pointer-cursor-header',
    onRender: renderEasting,
  },
  {
    key: 'action',
    name: '',
    minWidth: isMobile ? 0 : 120,
    maxWidth: 120,
    onRender: (item, index) => {
      if (hideRemove) {
        return '';
      } else {
        return <InlineButton text="Remove" id={'remove-btn-' + index} data-automation-id={'remove-btn-' + index} onClick={() => onRemove(item)} />;
      }
    },
  },
];

const AtonLocationList: React.FC<Props> = (props) => {
  const { isMobile } = useMedia();
  const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>();

  return (
    <DetailsList
      items={props.initialLocations ? props.initialLocations : []}
      columns={columns({ isMobile, onView: (index) => setExpandedIndex(index), onRemove: props.onRemove, hideRemove: !props.editable })}
    />
  );
};

export default AtonLocationList;
