import * as React from 'react';

import { Grid } from 'ui-library';

import Dropdown from '../../../common/controls/inputs/Dropdown';
import { TextField } from '../../../common/controls/inputs/TextField';
import { SectionHeading, SubSectionHeading } from '../../../common/layout/SectionHeadings';
import { landSimplexAutograntedLocations } from '../utils';

const LandSimplexDetailsSection = () => {
  return (
    <>
      <SectionHeading title="Land simplex details" />
      <Grid.Row>
        <Grid.Col sm={12} lg={2}>
          <TextField name="noOfSetsRequired" label="Number of sets" required errorImmediate />
        </Grid.Col>
      </Grid.Row>
      <SubSectionHeading title="Site details - transmit location" />
      <Grid.Row>
        <Grid.Col sm={12} lg={2}>
          <Dropdown name="location" label="Location" options={landSimplexAutograntedLocations} required />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default LandSimplexDetailsSection;
