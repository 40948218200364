import { datadogLogs } from '@datadog/browser-logs';
import { ENVIRONMENT } from 'env-config';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from './App';
import loginReducer from './common/reducer/loginReducer';
import pageStateReducer from './common/reducer/pageReducer';
import { initialiseStoreState, StoreState } from './store';

const reducers = combineReducers<StoreState>({
  loginState: loginReducer,
  pageState: pageStateReducer,
});

initialiseStoreState().then((state) => {
  const store = createStore<StoreState, any, {}, {}>(reducers, state, composeWithDevTools(applyMiddleware(thunk)));

  if (state.loginState.featureFlags?.find((flag) => flag.name === 'ENABLE_GOOGLE_TAG_MANAGER' && flag.enabled) !== undefined) {
    TagManager.initialize({ gtmId: 'GTM-W4D655W' });
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

if (ENVIRONMENT !== 'LOCAL' && ENVIRONMENT !== 'CI') {
  datadogLogs.init({
    clientToken: 'pub70637436ffc668eeab6268be82966e77',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: ENVIRONMENT.toLowerCase(),
    service: `rrf-js-${ENVIRONMENT.toLowerCase()}`,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
