import { DefaultButton, IButtonProps, IButtonStyles } from '@fluentui/react';
import * as React from 'react';
import { useTheme } from '../../theme/RRFTheme';

interface Props extends IButtonProps {}

const InlineButton: React.FC<Props> = (props) => {
  const { semanticColors } = useTheme();
  const styles: IButtonStyles = {
    root: { backgroundColor: semanticColors.inlineButtonBackground, borderColor: '#0e7ac3', color: '#0e7ac3' },
    rootHovered: {
      backgroundColor: semanticColors.inlineButtonBackgroundHovered,
      borderColor: semanticColors.inlineButtonBorderHovered,
      color: semanticColors.inlineButtonTextHovered,
    },
  };

  return <DefaultButton {...props} styles={styles} />;
};

export default InlineButton;
