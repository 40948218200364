import { isEmpty, isNaN, isNumber } from 'lodash';

export const formatDigit = (value: string, integerDigits: number, fractionDigits: number, maximumFraction: number): string => {
  if (isNaN(+value)) {
    return value;
  }

  const optionFormatNumber: Intl.NumberFormatOptions = {
    minimumIntegerDigits: integerDigits,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: maximumFraction,
    useGrouping: false,
  };

  return new Intl.NumberFormat(undefined, optionFormatNumber).format(+value);
};

export const asNumber = (value?: number | string | null): number | string | undefined | null => (!isNaN(+(value as any)) ? +value! : value);

export const isNumeric = (value?: number | string | null) => {
  return isNumber(value) || (!isEmpty(value) && !isNaN(parseFloat(value!)));
};
