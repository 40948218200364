import { DefaultButton, FocusZoneTabbableElements, IColumn, IDetailsRowProps, Text } from '@fluentui/react';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { LocationConfigDto, ReceiveLocationDto, GeoreferenceTypeEnum, LicenceClassificationType } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import { getPreferenceGeoReferenceType } from 'common/user/components/UserPreference';
import { formatEastingNorthing, byPriorityThenOrder } from 'common/utils/locationUtils';

import { LicenceLocationGeoreferenceDto, TransmitLocationDto } from '../../../../../../gen/api/api';
import InlineButton from '../../../../../common/controls/buttons/InlineButton';
import DetailsList, { ColumnFactoryFunctionProps, SortProps } from '../../../../../common/controls/lists/DetailsList';
import { useMedia } from '../../../../../common/layout/MediaHook';
import LocationInlineForm from './LocationInlineForm';

const MAX_NUMBER_OF_GEO_REFERENCES_TO_DISPLAY = 4;

interface Props {
  licenceId: number;
  locationType: 'TRANSMIT' | 'RECEIVE';
  locationConfiguration?: LocationConfigDto;
  onRemove: (config: TransmitLocationDto) => void;
  isDone?: boolean;
  onSave: () => void;
  renderRowAsError: (props: IDetailsRowProps) => boolean;
  isSatellite?: boolean;
  licenceClassification?: LicenceClassificationType;
  onShowMap?: (config: TransmitLocationDto) => void;
  onViewLocation: (locId?: number) => void;
  lastSaved?: Date;
  onFocus: () => void;
}

const LicenceLocationList: React.FC<Props> = (props) => {
  const { initialValues } = useFormikContext<{ locations?: TransmitLocationDto[] | ReceiveLocationDto[] }>();
  return (
    <div className="ms-Grid-row" onFocus={props.onFocus}>
      <Grid.Col sm={12} lg={12}>
        <LocationsList {...props} locations={initialValues.locations ?? []} />
      </Grid.Col>
    </div>
  );
};

const LocationsList: React.FC<Props & { locations: TransmitLocationDto[] | ReceiveLocationDto[] }> = (props) => {
  const columns = ({ isMobile, onRemove, onView, hideRemove, onShowMap }: ColumnFactoryFunctionProps): IColumn[] => [
    {
      key: 'locationName',
      name: 'Location name',
      fieldName: 'locationName',
      minWidth: isMobile ? 0 : 100,
      maxWidth: 250,
      isResizable: true,
      isMultiline: true,
      headerClassName: 'pointer-cursor-header',
    },
    {
      key: 'gridReference',
      name: 'Grid reference',
      fieldName: 'gridReference',
      minWidth: isMobile ? 0 : 150,
      maxWidth: 220,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: renderGeoReferenceColumn,
    },
    {
      key: 'antenna',
      name: 'Antenna',
      fieldName: 'antenna.type',
      minWidth: isMobile ? 0 : 150,
      maxWidth: 200,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: (transmitConfig?: TransmitLocationDto) => (
        <Text>
          {transmitConfig?.antenna?.make} {transmitConfig?.antenna?.model}
        </Text>
      ),
    },
    {
      key: 'equipment',
      name: 'Equipment',
      fieldName: 'equipment.make',
      minWidth: isMobile ? 0 : 150,
      maxWidth: 150,
      isMultiline: true,
      isResizable: true,
      headerClassName: 'pointer-cursor-header',
      onRender: (transmitConfig?: TransmitLocationDto) => (
        <Text>
          {transmitConfig?.equipment?.make} {transmitConfig?.equipment?.model}
        </Text>
      ),
    },
    {
      key: 'action',
      name: '',
      minWidth: isMobile ? 0 : 120,
      maxWidth: 120,
      onRender: (item, index) => (
        <>
          <InlineButton text="View" id={'view-btn-' + index} data-automation-id={'view-btn-' + index} onClick={() => onView(item.id)} />
          {!hideRemove && (
            <InlineButton text="Remove" id={'remove-btn-' + index} data-automation-id={'remove-btn-' + index} onClick={() => onRemove(item)} />
          )}
          {onShowMap && (item.locationType === 'MULTIPLE POINTS' || item.locationType === 'POINT') && (
            <DefaultButton
              text="Show on Map"
              id={'show-map-btn-' + index}
              data-automation-id={'show-map-btn-' + index}
              onClick={() => onShowMap(item)}
            />
          )}
        </>
      ),
    },
  ];

  const getUserPreferenceValue = (geoRefs?: LicenceLocationGeoreferenceDto[]) => {
    let userPreferenceGeoRef = geoRefs?.filter((ref: LicenceLocationGeoreferenceDto) => GeoreferenceTypeEnum[ref.type!] === geoRefTypeUserPreference);

    const sortedGeoRef = geoRefs?.sort(byPriorityThenOrder) ?? [];
    //If the location does not have the user's preferred geo reference type, then display next available in priority order geo reference type
    if (userPreferenceGeoRef?.length === 0) {
      return geoRefs?.filter((ref) => GeoreferenceTypeEnum[ref?.type!] === GeoreferenceTypeEnum[[...sortedGeoRef, undefined][0]?.type!]);
    }
    return userPreferenceGeoRef;
  };

  const renderGeoReference = (geoRefs?: LicenceLocationGeoreferenceDto[]) => {
    const geoRefFiltered = getUserPreferenceValue(geoRefs);
    const sliced = geoRefFiltered?.slice(0, MAX_NUMBER_OF_GEO_REFERENCES_TO_DISPLAY);

    const listItems = sliced?.map((ref) =>
      GeoreferenceTypeEnum[ref.type!] === GeoreferenceTypeEnum.T || GeoreferenceTypeEnum[ref.type!] === GeoreferenceTypeEnum.M ? (
        <li>{`${ref?.mapNumber} ${formatEastingNorthing(ref?.easting, GeoreferenceTypeEnum[ref.type!])} ${formatEastingNorthing(
          ref?.northing,
          GeoreferenceTypeEnum[ref.type!],
        )}`}</li>
      ) : GeoreferenceTypeEnum[ref.type!] === GeoreferenceTypeEnum.D2000 ? (
        <li>{`${formatEastingNorthing(ref?.northing, GeoreferenceTypeEnum[ref.type!])} ${formatEastingNorthing(
          ref?.easting,
          GeoreferenceTypeEnum[ref.type!],
        )}`}</li>
      ) : (
        <li>{`${formatEastingNorthing(ref?.easting, GeoreferenceTypeEnum[ref.type!])} ${formatEastingNorthing(
          ref?.northing,
          GeoreferenceTypeEnum[ref.type!],
        )}`}</li>
      ),
    );

    if (geoRefFiltered && geoRefFiltered.length > MAX_NUMBER_OF_GEO_REFERENCES_TO_DISPLAY) {
      listItems?.push(<li>...</li>);
    }

    return listItems;
  };

  const renderGeoReferenceColumn = (transmitConfig?: TransmitLocationDto) => {
    const geoRefGroups = transmitConfig?.geoReferenceGroups ? transmitConfig.geoReferenceGroups : [];
    const value = geoRefGroups.map((ref) => (ref?.geoReferences ? renderGeoReference(ref?.geoReferences) : ''));
    return <ul style={{ listStyle: 'none', padding: 0, display: 'inline' }}>{value}</ul>;
  };

  const { isMobile } = useMedia();
  const [recentlySavedLocationId, setRecentlySavedLocationId] = React.useState<number | undefined>(undefined);

  const [expandedId, setExpandedIdState] = React.useState<number | undefined>();

  const setExpandedId = (id: number = -1) => {
    setExpandedIdState(id);
    props.onViewLocation(id !== -1 ? id : undefined);
  };
  const expandedIndex = props.locations.findIndex((loc) => loc.id === expandedId);

  const [sortProps, setSortProps] = useState<SortProps>();
  const sort = (items: (TransmitLocationDto | ReceiveLocationDto)[]) =>
    sortProps
      ? [...items].sort((a: any, b: any) => {
          const key = sortProps.sortKey;
          const val = (item: any) => (key === 'antenna' || key === 'equipment' ? `${item[key]?.make} ${item[key]?.model}` : item[key]);
          const x = val(a);
          const y = val(b);
          const n = x === y ? 0 : x < y ? -1 : 1;
          return sortProps.descending ? -n : n;
        })
      : items;

  const { userPreference } = useAuth();

  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  useEffect(() => {
    setRecentlySavedLocationId(expandedId);
    // Collapse the opened view after saving.
    // If Confirm Save Changes was prompted from another View pressed, setExpandedId will be called with the new id following this call to clear the expandedId.
    setExpandedId();
    // eslint-disable-next-line
  }, [props.lastSaved]);

  return (
    <DetailsList
      items={sort(props.locations)}
      columns={columns({
        isMobile,
        onView: (index) => setExpandedId(index),
        onRemove: props.onRemove,
        hideRemove: props.isDone,
        onShowMap: props.onShowMap,
      })}
      renderExpandPanel={(item) => {
        return (
          <LocationInlineForm
            {...props}
            onCancel={() => setExpandedId()}
            locationConfig={props.locationConfiguration}
            licenceClassification={props.licenceClassification}
            locIdx={expandedIndex}
            onSave={props.onSave}
          />
        );
      }}
      expandedIndex={expandedIndex}
      renderRowAsSuccess={(row) => {
        return { shouldRender: recentlySavedLocationId === row.item.id, callback: () => setRecentlySavedLocationId(undefined) };
      }}
      renderRowAsError={props.renderRowAsError}
      handleTabKey={FocusZoneTabbableElements.all}
      onSort={(sortProps) => {
        if (['action', 'gridReference'].indexOf(sortProps.sortKey) < 0) {
          setSortProps(sortProps);
        }
      }}
      sortProps={sortProps}
    />
  );
};

export default LicenceLocationList;
