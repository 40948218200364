import { IDropdownOption } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { Dropdown, Grid, Grid2 } from 'ui-library';
import { ClientPicker, LicencePicker } from 'ui-library/shared';

import { useAuth } from 'common/auth/AuthHook';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import TooltipWarning from 'common/controls/items/TooltipWarning';
import { useForm } from 'common/form/FormHook';
import { useDisplayAddress, useGetClientWarning } from 'common/utils/clientUtils';
import { ComplaintFormProps } from 'investigation/interfaces';

import { useLicenceApi } from '../../licence_management/LicenceApiHooks';
import { polarisationMap } from '../../licence_management/craft/components/SpectrumDetails/LicenceSpectrumList';

export enum AssociatedToByEnum {
  // eslint-disable-next-line no-unused-vars
  CLIENT = 'Client',
  // eslint-disable-next-line no-unused-vars
  LICENCE = 'Licence',
  MR = 'Management Right',
  LA = 'Licence Application',
}

const associatedByOptions: IDropdownOption[] = [
  { key: AssociatedToByEnum.CLIENT, text: 'Client' },
  { key: AssociatedToByEnum.LICENCE, text: 'Licence' },
];

const CaseAssociatedTo = () => {
  const { hasRole } = useAuth();
  const { values, setValues } = useFormikContext<ComplaintFormProps>();
  const { mode } = useForm();

  const isSearchPickerVisible = mode === 'CREATE' || mode === 'EDIT';

  if (!isSearchPickerVisible) {
    return (
      <Grid.Row>
        <Grid.Col lg={3}>
          <ReadOnlyField label="Associated to" name="associatedToBy" />
        </Grid.Col>
        <Grid.Col lg={9}>
          <AssociatedToDetails />
        </Grid.Col>
      </Grid.Row>
    );
  }

  return (
    <Grid2>
      <Grid2.Col lg={3}>
        <Dropdown
          disabled={hasRole('ROLE_MSG_SERVICE_CENTRE')}
          label="Associated to"
          name="associatedToBy"
          options={associatedByOptions}
          onChange={(option) => {
            setValues({
              ...values,
              associatedTo: undefined,
              associatedToBy: option.key as AssociatedToByEnum,
              client: undefined,
            });
          }}
          required
        />
      </Grid2.Col>
      {values.associatedToBy && (
        <Grid2.Col lg={5}>
          <SearchPicker />
        </Grid2.Col>
      )}
      <Grid2.Col>
        <AssociatedToDetails />
      </Grid2.Col>
    </Grid2>
  );
};

const SearchPicker = () => {
  const { getFrequency } = useLicenceApi();
  const { values, setFieldValue } = useFormikContext<ComplaintFormProps>();
  const { associatedToBy } = values;

  const creditStatusWarning = useGetClientWarning(values.client?.id, values.client?.creditStatus);

  if (associatedToBy === AssociatedToByEnum.CLIENT) {
    return (
      <div>
        <Grid.Col lg={creditStatusWarning ? 6 : 12}>
          <ClientPicker name="associatedTo" label="Client" required onChange={(selectedTag) => setFieldValue('client', selectedTag?.data)} />
        </Grid.Col>
        {creditStatusWarning && (
          <Grid.Col lg={6} style={{ paddingTop: '25px' }}>
            {' '}
            <TooltipWarning
              id="client-details-warning"
              content={creditStatusWarning}
              styles={{ root: { marginLeft: '5px', paddingTop: '25px' } }}
            ></TooltipWarning>
          </Grid.Col>
        )}
      </div>
    );
  }

  return (
    <LicencePicker
      name="associatedTo"
      label="Licence"
      required
      onChange={(selectedTag) => {
        selectedTag?.data?.frequencyId
          ? getFrequency(selectedTag?.data?.frequencyId).then((frequency) => {
              setFieldValue('licence', selectedTag?.data);
              setFieldValue('emission', frequency.frequencyEmissions?.map((em) => em.emission).join(' '));
            })
          : setFieldValue('licence', selectedTag?.data);
      }}
    />
  );
};

export const AssociatedToDetails = () => {
  const { values } = useFormikContext<ComplaintFormProps>();
  const { associatedToBy } = values;

  if (!associatedToBy) {
    return null;
  }

  return associatedToBy === AssociatedToByEnum.CLIENT ? <ClientDetails /> : associatedToBy === AssociatedToByEnum.LICENCE ? <LicenceDetails /> : null;
};

const ClientDetails = () => {
  const { values } = useFormikContext<ComplaintFormProps>();

  return (
    <Grid2 layout={{ lg: 2 }}>
      <Grid2.Col>
        <ReadOnlyField label="Client number" name="client.id" href={`/ui/app/client/maintain/${values.client?.id}`} />
      </Grid2.Col>
      <Grid2.Col>
        <ReadOnlyField label="Client name" name="client.clientName" />
      </Grid2.Col>

      <Grid2.Col colStart={1}>
        <ReadOnlyField label="Email" name="client.email" />
      </Grid2.Col>
      <Grid2.Col>
        <ReadonlyData label="Residential/Physical address" value={useDisplayAddress(values.client)} />
      </Grid2.Col>
    </Grid2>
  );
};

const LicenceDetails = () => {
  const { values } = useFormikContext<ComplaintFormProps>();
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col lg={4}>
          <ReadOnlyField
            label="Licence ID"
            name="licence.id"
            href={`/ui/app/licence/${values.licence?.licenceTypeCode === 'SPL' ? 'spectrum' : 'radio'}/view/${values.licence?.id}`}
          />
        </Grid.Col>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Channel" name="licence.channel" />
        </Grid.Col>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Polarisation" name="licence.polarisation" renderFormat={(val) => polarisationMap.get(val)} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Licensee" name="licence.licensee" href={`/ui/app/client/maintain/${values.licence?.clientId}`} />
        </Grid.Col>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Ref. Freq (MHz)" name="licence.refFrequency" />
        </Grid.Col>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Emission" name="emission" />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Licence status" name="licence.licenceStatus" />
        </Grid.Col>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Power dBW(eirp)" name="licence.power" renderFormat={(val) => val?.toFixed(1)} />
        </Grid.Col>
        <Grid.Col lg={4}>
          <ReadOnlyField label="Location" name="licence.location" />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default CaseAssociatedTo;
