import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router';

import usePage from '../common/layout/PageHook';
import { SearchCriteria } from './SearchRouter';

interface Props {
  onClear?: () => void;
  onSearch?: (values: any) => void;
}

const SearchButtons: React.FC<Props> = (props) => {
  const formikContext = useFormikContext<SearchCriteria>();
  const history = useHistory();
  const { setPageInstruction } = usePage();

  return (
    <div style={{ paddingTop: '10px', float: 'right' }}>
      <DefaultButton
        text="Clear"
        id="clear-button"
        data-automation-id="clear-button"
        onClick={() => {
          history.replace(window.location.pathname);
          formikContext.resetForm();
          if (props.onClear) {
            props.onClear();
          }
        }}
      />
      <PrimaryButton
        text="Search"
        disabled={formikContext.isSubmitting || !formikContext.isValid}
        type="submit"
        style={{ marginLeft: '5px' }}
        id="search-button"
        data-automation-id="search-button"
        onClick={(event) => {
          formikContext.setFieldValue('orderBy', null);
          formikContext.setFieldValue('page', 1);
          setPageInstruction();
          if (props.onSearch) {
            props.onSearch({ ...formikContext.values, orderBy: null, page: 1 });
            // Prevent the form from submitting, as we are overriding the submit handler with this custom callback.
            event.preventDefault();
          }
        }}
      />
    </div>
  );
};

export default SearchButtons;
