import { mergeStyleSets } from '@fluentui/merge-styles';

export interface IComponentClassNames {
  root: string;
}

export const getClassNames = (): IComponentClassNames =>
  mergeStyleSets({
    root: {
      position: 'fixed',
      zIndex: '2000000',
      height: '2em',
      margin: 'auto',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  });
