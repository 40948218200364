import { useFormikContext } from 'formik';
import React from 'react';

import { Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { convertRecommendation, convertResult } from 'investigation/hooks';
import { AuditFormProps } from 'investigation/interfaces';

const AuditRecommendationSection = () => {
  const { values } = useFormikContext<AuditFormProps>();
  return (
    <>
      <SectionHeading title="Recommendation" />
      <Grid2>
        {values.recommendation && (
          <Grid2.Col lg={5}>
            <ReadOnlyField label="Recommendation" name="recommendation" renderFormat={convertRecommendation} />
          </Grid2.Col>
        )}

        <Grid2.Col lg={7}>
          <ReadOnlyField label="Audit result" name="finalResult" renderFormat={convertResult} />
        </Grid2.Col>

        <Grid2.Col>
          <ReadOnlyField label="Notes" name="finalResultNotes" />
        </Grid2.Col>
      </Grid2>
    </>
  );
};

export default AuditRecommendationSection;
