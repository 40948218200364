import { useFormikContext } from 'formik';
import { upperFirst } from 'lodash';
import React from 'react';

import { Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { AuditFormProps } from 'investigation/interfaces';

const _upperFirst = (str: string) => upperFirst(str.toLowerCase());

const AuditClientAppealSection = () => {
  const { values } = useFormikContext<AuditFormProps>();
  return (
    <>
      <SectionHeading title="Client appeal" />
      <Grid2>
        <Grid2.Col lg={3}>
          <ReadOnlyField name="appealProcessType" label="Appeal process" renderFormat={_upperFirst} />
        </Grid2.Col>
        {values.appealResult && (
          <>
            <Grid2.Col lg={9}>
              <ReadOnlyField name="appealResult" label="Result" renderFormat={_upperFirst} />
            </Grid2.Col>
            <Grid2.Col colStart={4}>
              <ReadOnlyField name="appealResultDetails" label="Details" />
            </Grid2.Col>
          </>
        )}
      </Grid2>
    </>
  );
};

export default AuditClientAppealSection;
