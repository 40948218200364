import { DirectionalHint, Icon, ITooltipHostProps, mergeStyles, TooltipHost } from '@fluentui/react';
import * as React from 'react';

export interface TooltipIconProps extends ITooltipHostProps {
  style?: React.CSSProperties;
  iconName?: string;
}

const TooltipIcon: React.FC<TooltipIconProps> = (props) => {
  return (
    <TooltipHost
      directionalHint={DirectionalHint.rightCenter}
      closeDelay={1000}
      tooltipProps={{ maxWidth: '1200' }}
      {...props}
      className={mergeStyles({ border: '1px solid #C84504' })}
    >
      <Icon
        iconName={props.iconName ? props.iconName : 'InfoSolid'}
        style={{ lineHeight: '32px', color: '#C84504', fontSize: 20, verticalAlign: 'middle', ...props.style }}
      />
    </TooltipHost>
  );
};

export default TooltipIcon;
