import { AddressDto, AddressDtoAddressTypeCodeEnum, NzbnEntity, NzbnEntityAddressAddressTypeEnum } from 'api-client';

import { PAF_SEARCH_URL } from '../../../../env-config';
import { isEmpty } from '../../../utils/objectUtils';

interface PafSearchResults {
  results?: PafSearchResult[];
}

export interface PafSearchResult {
  Line1?: string;
  Line2?: string;
  Line3?: string;
  Line4?: string;
  StreetNumber?: string;
  StreetName?: string;
  StreetType?: string;
  UnitType?: string;
  UnitIdentifier?: string;
  TownCity?: string;
  PostCode?: string;
  DPID: string;
  AddressType: string;
  StreetAlpha?: string;
  RDNumber?: string;
  SuburbName?: string;
  Address: string;
  AddressTypeaheadFormat?: string;
  BoxBagLobbyName?: string;
  BoxBagNumber?: string;
  Floor?: string;
  BuildingName?: string;
}

export interface PafAddress
  extends Pick<
    AddressDto,
    | 'pafId'
    | 'pafAddress'
    | 'flatOfficeNumber'
    | 'streetNumber'
    | 'streetName'
    | 'poBox'
    | 'cityTown'
    | 'countryCode'
    | 'suburb'
    | 'postCode'
    | 'addressTypeCode'
  > {}

export const addressesUtils = (partialAddress: string, includePhysical?: boolean, includePostal?: boolean): Promise<AddressDto[]> => {
  return callPaws(partialAddress).then((pafSearchResults: PafSearchResults) => {
    if (!pafSearchResults || !pafSearchResults.results) {
      return [];
    }
    return pafSearchResults.results
      .filter((a) => (isPoBox(a) && includePostal) || (!isPoBox(a) && includePhysical))
      .map((p) => mapPafSearchResultToAddress(p));
  });
};

const callPaws = (partialAddress: string): Promise<PafSearchResults> => {
  return fetch(PAF_SEARCH_URL + partialAddress).then((resp) => {
    if (resp.ok) {
      return resp.json();
    }
    return resp;
  });
};

export const mapPafSearchResultToAddress = (p: PafSearchResult): PafAddress => {
  const flatOfficeNumber = (f: PafSearchResult) => {
    const no =
      titleCase(f.UnitType ? f.UnitType + ' ' : '') +
      titleCase(f.UnitIdentifier ? f.UnitIdentifier + ' ' : '') +
      titleCase(f.Floor ? 'Level ' + f.Floor + ' ' : '') +
      titleCase(f.BuildingName ? f.BuildingName : '');
    return no.trim().length > 0 ? no.trim() : undefined;
  };

  const streetNumber = (n: PafSearchResult) => {
    const no = titleCase(n.StreetNumber ? n.StreetNumber : '') + (p.StreetAlpha ? n.StreetAlpha : '');
    return no.trim().length > 0 ? no : undefined;
  };

  const streetName = (s: PafSearchResult) => {
    const name = titleCase(s.StreetName ? s.StreetName : '') + ' ' + titleCase(s.StreetType ? s.StreetType : '');
    return name.trim().length > 0 ? name : undefined;
  };

  const titleCase = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

  return {
    pafId: +p.DPID,
    pafAddress: p.Address,
    flatOfficeNumber: flatOfficeNumber(p),
    streetNumber: streetNumber(p),
    streetName: streetName(p),
    poBox: isPoBox(p) ? p.Line1 : undefined,
    cityTown: p.TownCity,
    countryCode: 'NZ',
    suburb: isPoBox(p) || p.RDNumber ? p.Line2 : p.SuburbName,
    postCode: p.PostCode,
    addressTypeCode: isPoBox(p) ? AddressDtoAddressTypeCodeEnum.PST : AddressDtoAddressTypeCodeEnum.PHY,
  };
};

const isPoBox = (a: PafSearchResult) => ['BOX', 'BAG'].find((p) => p === a.AddressType);

export const isNew = (address: AddressDto) => !address.addressId;

export const isPaf = (address: AddressDto) => address.pafId !== null && address.pafId !== undefined;

export const isPhysicalAddress = (address: AddressDto) => address.addressTypeCode === AddressDtoAddressTypeCodeEnum.PHY;

export const isPostalAddress = (address: AddressDto) => address.addressTypeCode === AddressDtoAddressTypeCodeEnum.PST;

export const addressInOneLine = (address?: AddressDto): string | undefined => {
  if (!address) {
    return undefined;
  }
  const addressLines = new Array<string | undefined>();
  if (address.addressTypeCode === AddressDtoAddressTypeCodeEnum.PHY) {
    addressLines.push(address.flatOfficeNumber);
    addressLines.push(
      ((isEmpty(address.streetNumber) ? '' : address.streetNumber) + ' ' + (isEmpty(address.streetName) ? '' : address.streetName)).trim(),
    );
    addressLines.push(address.suburb);
    addressLines.push(address.cityTown);
    addressLines.push(address.postCode);
    addressLines.push(address.countryCode ?? undefined);
  } else if (address.addressTypeCode === AddressDtoAddressTypeCodeEnum.PST) {
    addressLines.push(address.poBox);
    addressLines.push(address.suburb);
    addressLines.push(address.cityTown);
    addressLines.push(address.postCode);
    addressLines.push(address.countryCode ?? undefined);
  }

  const _1LineAddress = addressLines
    .filter((l) => l && true && l.trim().length > 0)
    .map((l) => l && l.trim())
    .join(', ');

  const serviceAddress = address.pafAddress === undefined || address.pafAddress === null ? '' : '' + address.pafAddress;
  return _1LineAddress.length > serviceAddress.length ? _1LineAddress : address.pafAddress;
};

export const mapToRRFAddress = (addressType: NzbnEntityAddressAddressTypeEnum, nzbnEntity?: NzbnEntity) => {
  const address = nzbnEntity?.addresses?.addressList?.find((addr) => addr.addressType === addressType);

  return address
    ? ({
        streetName: address.address1,
        suburb: address.address2,
        cityTown: address.address3,
        postCode: address.postCode,
        countryCode: address.countryCode,
        pafId: address.pafId ? +address.pafId : undefined,
        isManual: !address?.pafId,
      } as AddressDto)
    : undefined;
};
