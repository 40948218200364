import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';

import { EmissionLimitDto, MaintainUnwantedEmissionLimitDto } from 'api-client';

import UnwantedEmissionLimitsView from 'licence_management/common/components/UnwantedEmissionLimitsView';
import UelGraph from 'licence_management/management_right/common/components/UelGraph';

import { FrequencyDto } from '../../../../../gen/api/api';
import ErrorLabel from '../../../../common/controls/inputs/ErrorLabel';
import { SubSectionHeading } from '../../../../common/layout/SectionHeadings';
import UnwantedEmissionLimitsDndView from './UnwantedEmissionLimitsListView';
import { emptyUnwantedEmissionLimit, SpectrumMask, UelType, withBounds } from './UnwantedEmissionLimitsSection';

const SpectrumCreationUELSection = () => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [typeUel, setTypeUel] = useState<UelType>(UelType.EditUelPoints);
  const name = `unwantedEmissionLimits`;
  const uels: EmissionLimitDto[] = get(values, name) ?? [];
  const frequencies: FrequencyDto[] = values.frequencies ?? [];

  const replaceUnwantedEmissionLimits = (uels: MaintainUnwantedEmissionLimitDto[]) =>
    setFieldValue(
      name,
      uels.map((uel) => withBounds(uel, values)),
    );

  return (
    <FieldArray
      name={name}
      render={(arrayProps) => (
        <div style={{ marginTop: 30 }}>
          <SubSectionHeading
            title="Unwanted emission limits (UEL)"
            hint="Spectrum low and spectrum high must be entered first before UELs can be entered"
            actionButtonOnClick={typeUel === UelType.EditUelPoints ? () => arrayProps.push(emptyUnwantedEmissionLimit(values)) : undefined}
            actionButtonId="add-uel-button"
            actionButtonProps={{ disabled: !values.lowerBound || !values.upperBound }}
          />
          <>
            {typeUel === UelType.EditUelPoints && (
              <>
                <SpectrumMask referenceFrequencies={frequencies} onUnwantedEmissionLimits={replaceUnwantedEmissionLimits} />
                <ErrorLabel name={name} showIcon />
                <UnwantedEmissionLimitsDndView
                  {...arrayProps}
                  lowerBound={values.lowerBound}
                  upperBound={values.upperBound}
                  onViewUel={() => setTypeUel(UelType.ViewUelPoints)}
                  onViewGraph={() => setTypeUel(UelType.GraphUel)}
                />
              </>
            )}
            {typeUel === UelType.ViewUelPoints && (
              <UnwantedEmissionLimitsView
                unwantedEmissionLimits={uels}
                {...values}
                onViewUelPts={() => setTypeUel(UelType.EditUelPoints)}
                onViewGraph={() => setTypeUel(UelType.GraphUel)}
              />
            )}

            {typeUel === UelType.GraphUel && (
              <UelGraph
                onViewUelPts={() => setTypeUel(UelType.ViewUelPoints)}
                onEditUelPts={() => setTypeUel(UelType.EditUelPoints)}
                lowerBound={values.lowerBound}
                adjacentFrequencyEmissionLimitLow={uels
                  .filter((x: EmissionLimitDto) => x.limitFrequency <= values.lowerBound)
                  .sort((a: EmissionLimitDto, b: EmissionLimitDto) => a.limitFrequency - b.limitFrequency)}
                upperBound={values.upperBound}
                adjacentFrequencyEmissionLimitHigh={uels
                  .filter((x: EmissionLimitDto) => x.limitFrequency >= values.upperBound)
                  .sort((a: EmissionLimitDto, b: EmissionLimitDto) => a.limitFrequency - b.limitFrequency)}
              />
            )}
          </>
        </div>
      )}
    />
  );
};

export default SpectrumCreationUELSection;
