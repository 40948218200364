import { ITextFieldStyles, Label, mergeStyles, TextField as FabricTextField } from '@fluentui/react';
import { useField } from 'formik';
import * as React from 'react';

import { Grid } from 'ui-library';

import { useForm } from '../../form/FormHook';
import { useMedia } from '../../layout/MediaHook';
import { countryCodes, countryPhoneCodeOptions } from '../../reference/countryCodes';
import ReadonlyData from '../items/ReadonlyData';
import Dropdown from './Dropdown';
import { TextField } from './TextField';

interface PhoneNumberProps {
  name: string;
  label?: string;
  initialIsdCode?: string;
  showPlaceHolders?: boolean;
  hideLabels?: boolean;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

const commonStyle = {
  float: 'left',
  boxSizing: 'border-box',
};
const padding = '4px !important';

export const PhoneNumber: React.FC<PhoneNumberProps> = (props) => {
  const { isMobile } = useMedia();
  const { mode } = useForm();
  const countryStyle = !isMobile
    ? mergeStyles({
        ...commonStyle,
        paddingRight: padding,
      })
    : '';
  const codeStyle = !isMobile
    ? mergeStyles({
        ...commonStyle,
        paddingLeft: padding,
        paddingRight: padding,
      })
    : '';
  const numberStyle = !isMobile
    ? mergeStyles({
        ...commonStyle,
        paddingLeft: padding,
      })
    : '';
  const [countryCodeField] = useField(props.name + '.countryCode');
  const countryCodeStd = countryCodes.find((c) => c.code === countryCodeField.value)?.std ?? '';
  const getStyles = (): Partial<ITextFieldStyles> => ({
    subComponentStyles: {
      label: {
        root: {
          fontWeight: 'normal',
        },
      },
    },
  });

  return (
    <>
      {props.label && (
        <Grid.Row>
          <Grid.Col sm={12}>
            <Label>{props.label}</Label>
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col sm={12} lg={5} classNames={countryStyle}>
          <Dropdown
            name={props.name + '.countryCode'}
            label={props.hideLabels ? '' : 'Country'}
            options={countryPhoneCodeOptions}
            required={props.required}
            placeholder={props.showPlaceHolders ? 'Country' : ''}
            styles={props.label ? { label: { fontWeight: 'lighter' } } : {}}
            readOnly={props.readOnly}
            disabled={props.disabled}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={2} classNames={codeStyle}>
          {mode === 'VIEW' || props.readOnly ? (
            <ReadonlyData label={props.hideLabels ? '' : 'Code'} value={`+${countryCodeStd}`} />
          ) : (
            <FabricTextField
              readOnly
              name="code"
              label={props.hideLabels ? '' : 'Code'}
              value={`${countryCodeStd}`}
              placeholder={props.showPlaceHolders ? 'Code' : ''}
              styles={props.label ? getStyles : {}}
              disabled={mode === 'DISABLED' || props.disabled}
            />
          )}
        </Grid.Col>
        <Grid.Col sm={12} lg={2} classNames={codeStyle}>
          <TextField
            name={props.name + '.areaCode'}
            label={props.hideLabels ? '' : 'Area'}
            maxLength={6}
            placeholder={props.showPlaceHolders ? 'Area' : ''}
            styles={props.label ? getStyles : {}}
            readOnly={props.readOnly}
            required={props.required}
            disabled={props.disabled}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3} classNames={numberStyle}>
          <TextField
            name={props.name + '.number'}
            label={props.hideLabels ? '' : 'Number'}
            maxLength={12}
            placeholder={props.showPlaceHolders ? 'Number' : ''}
            required={props.required}
            styles={props.label ? getStyles : {}}
            readOnly={props.readOnly}
            disabled={props.disabled}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};
