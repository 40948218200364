import { useFormikContext } from 'formik';
import React from 'react';

import { Grid } from 'ui-library';

import Dropdown from 'common/controls/inputs/Dropdown';
import { SectionHeading } from 'common/layout/SectionHeadings';
import GeoreferenceDetail from 'licence_management/reference_data/location/components/GeoreferenceDetail';
import GeoreferenceGroupPanel from 'licence_management/reference_data/location/components/GeoreferenceGroupPanel';
import { georeferenceTypes } from 'licence_management/reference_data/location/locationSupportData';

import { GeoReferenceProps } from '../GeoReferenceTypeConverterPage';

const GeoReferenceTypeContent = () => {
  const { values, setFieldValue } = useFormikContext<GeoReferenceProps>();
  const resetConversionValues = () => {
    setFieldValue('geoReferenceGroup', undefined, false);
  };
  return (
    <>
      <SectionHeading title="New Zealand Grid Reference Converter" />
      <Grid.Row>
        <Grid.Col lg={7}>
          <Dropdown
            name="georeference.type"
            label={'Georeference type'}
            onChange={resetConversionValues}
            options={[{ key: null as any, text: '' }, ...georeferenceTypes]}
            required
          />
        </Grid.Col>
      </Grid.Row>
      <GeoreferenceDetail namePrefix={'georeference.'} textFieldSize={3} showRequired={true} />
      {values.geoReferenceGroup && <GeoreferenceGroupPanel georeferenceGroup={values.geoReferenceGroup} hideOriginal={true} />}
    </>
  );
};

export default GeoReferenceTypeContent;
