import { css } from '@emotion/css';
import { Text } from '@fluentui/react';
import { EditorComponent, OnChangeHTML, Remirror, useRemirror, ThemeProvider, useRemirrorContext } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import escape from 'lodash/escape';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, Ref } from 'react';
import { CodeBlockExtension } from 'remirror/extensions';

import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { useForm } from 'common/form/FormHook';

import EditorToolbar, { getToolbarExtensions } from './EditorToolbar';
import FloatingLinkToolbar from './FloatingLinkToolbar';

export interface EditorProps {
  name: string;
  label: string;
  height?: string;
  showCode?: boolean;
  defaultVariables?: string[];
}

// https://remirror.io/docs/faq
export interface EditorRef {
  setContent: (content: any) => void;
}

const ImperativeHandle = forwardRef((_: unknown, ref: Ref<EditorRef>) => {
  const { setContent } = useRemirrorContext({
    autoUpdate: true,
  });

  // Expose content handling to outside
  useImperativeHandle(ref, () => ({ setContent }));

  return <></>;
});

interface HtmlTextEditorProps {
  text: string;
  label: string;
}

const HtmlTextEditor: React.FC<HtmlTextEditorProps> = ({ text, label }) => {
  const editorRef = useRef<EditorRef | null>(null);
  const convertText = (text: string) => `<pre><code data-code-block-language="html">` + escape(text) + `</code></pre>`;
  const { manager, state } = useRemirror({
    extensions: () => [new CodeBlockExtension()],
    content: convertText(text),
    stringHandler: 'html',
  });

  useEffect(() => {
    editorRef.current!.setContent(convertText(text));
  }, [text]);

  return (
    <Remirror
      classNames={[
        css`
          &.ProseMirror {
            min-height: auto !important;
            padding: 0 !important;
            background: #2b2b2b;
            font-family: Fira Sans;
          }
          pre {
            white-space: pre-wrap !important;
          }
        `,
      ]}
      manager={manager}
      initialContent={state}
      editable={false}
      label={label}
    >
      <ImperativeHandle ref={editorRef} /> <EditorComponent />
    </Remirror>
  );
};

const HTMLEditor: React.FC<EditorProps> = (props) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const { mode } = useForm();
  const editorRef = useRef<EditorRef | null>(null);

  const { manager, state } = useRemirror({
    extensions: () => getToolbarExtensions(),
    content: values[props.name] as string,
    selection: 'start',
    stringHandler: 'html',
  });

  useEffect(() => {
    if (mode === 'VIEW') {
      // Reset editor content when cancelling editing or retrieving updated message
      editorRef.current?.setContent(values[props.name]);
    }
  }, [values[props.name], mode]);

  const debounceSetFieldValue = debounce(setFieldValue, 100);

  return (
    <div>
      <Text style={{ margin: '0 5', fontWeight: 'bold' }}>{props.label}</Text>
      <div style={{ marginTop: 10 }}>
        <AllStyledComponent>
          <ThemeProvider>
            <Remirror
              classNames={[
                css`
                  &.ProseMirror {
                    min-height: auto !important;
                    font-family: Fira Sans;
                    background-color: white;
                  }
                `,
              ]}
              manager={manager}
              initialContent={state}
              editable={mode === 'EDIT'}
              label={props.label + ' textbox'}
            >
              {mode === 'EDIT' && <EditorToolbar value={values[props.name] as string} defaultVariables={props.defaultVariables} />}
              <OnChangeHTML onChange={(html) => debounceSetFieldValue(props.name, html)} />
              <ImperativeHandle ref={editorRef} />
              <EditorComponent />
              {mode === 'EDIT' && <FloatingLinkToolbar />}
            </Remirror>
            {props.showCode && <HtmlTextEditor text={values[props.name] as string} label={props.label + ' code'} />}
          </ThemeProvider>
        </AllStyledComponent>
        <ErrorLabel name={props.name} />
      </div>
    </div>
  );
};

export default HTMLEditor;
