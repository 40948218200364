import { useFormikContext } from 'formik';
import React from 'react';

import { Grid } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import MultiSelectDropdown from 'common/controls/inputs/MultiSelectDropdown';
import { TextField } from 'common/controls/inputs/TextField';
import HorizontalDivider from 'common/layout/HorizontalDivider';

import TooltipHint from '../../../common/controls/items/TooltipHint';
import { useInfringementFeeOptions } from '../hooks';
import { AuditRecommendationFormProps } from './AuditRecommendationDialog';

const AuditRecommendationInfringement = () => {
  const { values } = useFormikContext<AuditRecommendationFormProps>();
  const infringementFeeOptions = useInfringementFeeOptions();

  return (
    <>
      <h3 style={{ margin: '5px 0' }}>Infringement</h3>
      <HorizontalDivider />
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <MultiSelectDropdown label="Infringement" name="infringementFees" options={infringementFeeOptions} required />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={6} style={{ display: 'inline-block', verticalAlign: 'top' }}>
            <TextField label="Location" name="infringementLocation" multiline maxLength={200} showMaxHint required />
          </Grid.Col>
          <Grid.Col lg={3} style={{ display: 'inline-block' }}>
            <Grid>
              <Grid.Row>
                <Grid.Col>
                  <DatePicker label="Date" name="infringementDate" maxDate={new Date()} required dateOnlyAsString />
                </Grid.Col>
                <Grid.Col>
                  <TextField label="Time" name="infringementTime" placeholder="HH:MM" readOnlyEmptyFormat=" " required maxLength={5} />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Grid.Col>
        </Grid.Row>
        {values.caseDetails.clientType?.toUpperCase() === 'PERSON' && (
          <Grid.Row>
            <Grid.Col sm={10} md={6} lg={3} style={{ display: 'inline-block' }}>
              <DatePicker label="Birth date" name="clientBirthDate" maxDate={new Date()} dateOnlyAsString />
            </Grid.Col>
            <Grid.Col sm={2} md={6} lg={3} style={{ display: 'inline-block', verticalAlign: 'top' }}>
              <TooltipHint id="birth-date-tooltip-hint" content={'Leave blank where date is not known or client is an organisation.'} />
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
};

export default AuditRecommendationInfringement;
