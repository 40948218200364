import { Icon } from '@fluentui/react';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  BaseLocationDto,
  GeoreferenceDto,
  GeoreferenceTypeEnum,
  LocationSearchResult,
  LocationTypeEnum,
  MultipointDto,
  PointDto,
  SatelliteDto,
} from 'api-client';

import { Grid } from 'ui-library';

import Dropdown from 'common/controls/inputs/Dropdown';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { NumberField } from 'common/controls/inputs/NumberField';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import LocationTagPicker from 'common/controls/inputs/location/LocationTagPicker';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatEastingNorthing } from 'common/utils/locationUtils';
import { useReferenceDataHookApi } from 'licence_management/reference_data/ReferenceDataApiHook';

import { useAuth } from '../../../../common/auth/AuthHook';
import ConfirmDialog from '../../../../common/controls/surfaces/ConfirmDialog';
import EmbeddedCreateDefinedArea from '../../../reference_data/location/create/EmbeddedCreateDefinedArea';
import EmbeddedCreatePoint from '../../../reference_data/location/create/EmbeddedCreatePoint';
import { ApplyLicencePageContext } from '../../ApplyLicencePage';
import { receiveLocationSectionTitle, transmitLocationSectionTitle } from './LocationSection';

const D2000 = GeoreferenceTypeEnum.D2000;
const { P, M } = LocationTypeEnum;

export const initialSatelliteValues = (defaults: { satellite?: SatelliteDto }) => ({
  name: undefined,
  licenceClassification: undefined,
  satelliteType: undefined,
  orbitalPosition: undefined,
  beamName: undefined,
  minimumElevation: undefined,
  ituNoticeId: undefined,
  commercialName: undefined,
  owner: undefined,
  ...defaults.satellite,
});

export const satelliteLicenceClassificationOptions = [
  { key: 'A', text: 'Earth-to-Space and Space-to-Earth' },
  { key: 'B', text: 'Earth-to-Space only' },
  { key: 'C', text: 'Space-to-Earth only' },
];

const satelliteTypeOptions = [
  // TODO {key: 'ANY', text: 'ANY'}
  { key: 'GSO', text: 'GSO' },
  { key: 'NGSO', text: 'NGSO' },
];

const warningMessageMap = new Map<string, string>([
  ['A', ` This will delete the data under the sections "${transmitLocationSectionTitle}" and "${receiveLocationSectionTitle}"`],
  ['B', ` This will delete the data under the section "${transmitLocationSectionTitle}"`],
  ['C', ` This will delete the data under the section "${receiveLocationSectionTitle}"`],
]);

export const formatLatLongs = (latLongs: GeoreferenceDto[]) =>
  latLongs.map((latLong) => `${formatEastingNorthing(latLong.northing + '', D2000)} ${formatEastingNorthing(latLong.easting + '', D2000)}`);

export const asLatLongs = (loc: BaseLocationDto): GeoreferenceDto[] | undefined => {
  const byLatLongType = (georef?: GeoreferenceDto) => georef?.type === D2000;
  if (loc.type === P) {
    const { georeference } = loc as PointDto;
    const georefs = [georeference.origin, ...georeference.convertions!];
    const latLong = georefs.find(byLatLongType);
    return latLong ? [latLong] : [];
  } else if (loc.type === M) {
    const { georeferences } = loc as MultipointDto;
    const latLongs = georeferences?.flatMap((georef) => [georef.origin, ...georef.convertions!]).filter(byLatLongType) as
      | undefined
      | GeoreferenceDto[];
    return latLongs ?? [];
  }
};

const SatelliteDetailsSection = () => {
  const { setFieldValue, setFieldTouched, values, initialValues } = useFormikContext<{ satellite: SatelliteDto & { tagPicker?: string } }>();

  const { createDefinedArea, createPoint, getLocation } = useReferenceDataHookApi();
  const { hasRole } = useAuth();

  const { onLicenceClassificationChange } = React.useContext(ApplyLicencePageContext);

  const [latLongGeoref, setLatLongGeorefsState] = useState<GeoreferenceDto[]>([]);

  const setLatLongGeoRefs = (locationId?: number) =>
    !locationId
      ? setLatLongGeorefsState([])
      : getLocation(locationId, { disableErrorMessage: true })
          .then((loc) => setLatLongGeorefsState(asLatLongs(loc) ?? []))
          .catch(() => {
            setLatLongGeorefsState([]);
          });

  const resetValues = () => {
    setFieldValue('satellite.orbitalPosition', null, true);
    setFieldValue('satellite.minimumElevation', null, false);
  };

  const [showCreatePointLocation, setShowCreatePointLocation] = useState<boolean>(false);
  const [showCreateDefinedAreaLocation, setShowCreateDefinedAreaLocation] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string | undefined>(undefined);

  const canCreatePointLocation =
    hasRole('ROLE_APPROVED_RADIO_ENGINEER') ||
    hasRole('ROLE_APPROVED_RADIO_CERTIFIER') ||
    hasRole('ROLE_RSM_RADIO_ENGINEER') ||
    hasRole('ROLE_LICENSING_OFFICER') ||
    hasRole('ROLE_LICENSING_MANAGER') ||
    hasRole('ROLE_SYSTEM_ADMINISTRATOR');

  const canCreateDefinedAreaLocation =
    hasRole('ROLE_RSM_RADIO_ENGINEER') ||
    hasRole('ROLE_LICENSING_OFFICER') ||
    hasRole('ROLE_LICENSING_MANAGER') ||
    hasRole('ROLE_SYSTEM_ADMINISTRATOR');

  useEffect(() => {
    if (values.satellite.name && !values.satellite?.tagPicker) {
      // Initialise the tag picker
      setFieldValue('satellite.tagPicker', `${values.satellite?.locationId}=>${values.satellite?.name}`);
    }
    if (values.satellite.locationId) {
      setLatLongGeoRefs(values.satellite.locationId);
    }
  }, [initialValues]);

  const setFormFields = (locDto: any, stripId?: boolean) => {
    setFieldValue('satellite.locationId', locDto?.id);
    setFieldTouched('satellite.name', true);
    // TagPicker name made up of ID & location name, so strip off ID prefix.
    setFieldValue('satellite.name', stripId ? locDto?.name.replace(/^\d+ /, '') : locDto?.locationName);
    // TODO satellite.longitude
    setLatLongGeoRefs(locDto?.id);
  };

  const handleCreatePointLocation = (value: any) => {
    return createPoint({
      ...value,
      georeference: {
        origin: {
          ...value.georeference.origin,
          type: value.georeferenceType,
        },
      },
    }).then((pointDto) => {
      setFormFields(pointDto);
      setFieldValue('satellite.tagPicker', `${pointDto.id}=>${pointDto.locationName}`);
      setShowCreatePointLocation(false);
    });
  };

  const handleCreateDefinedAreaLocation = (value: any) => {
    return createDefinedArea(value).then((definedAreaDto) => {
      setFormFields(definedAreaDto);
      setFieldValue('satellite.tagPicker', `${definedAreaDto.id}=>${definedAreaDto.locationName}`);
      setShowCreateDefinedAreaLocation(false);
    });
  };

  return (
    <>
      <SectionHeading title="Satellite details" />
      <ConfirmDialog
        title={`Please confirm that you want to change the licence classification.`}
        onOk={() => {
          onLicenceClassificationChange();
          setWarningMessage(undefined);
        }}
        onCancel={() => {
          setFieldValue('satellite.licenceClassification', initialValues.satellite.licenceClassification);
          setWarningMessage(undefined);
        }}
        body={
          <p>
            <Icon iconName="Warning" style={{ lineHeight: '32px', color: '#C84504', fontSize: 20, verticalAlign: 'sub' }} />
            {warningMessage}
          </p>
        }
        visible={!!warningMessage}
        yesText="OK"
        noText="Cancel"
        //notDangerous
      />
      <Grid.Row>
        <Grid.Col lg={6}>
          <LocationTagPicker
            name="satellite.tagPicker"
            label="Search satellite"
            itemLimit={1}
            onChange={(locs: LocationSearchResult[]) => {
              const loc = locs.pop();
              if (loc?.id === 0) {
                setShowCreateDefinedAreaLocation(false);
                setShowCreatePointLocation(true);
              } else if (loc?.id === -1) {
                setShowCreatePointLocation(false);
                setShowCreateDefinedAreaLocation(true);
              } else {
                setFormFields(loc, true);
              }
            }}
            canCreateDefinedAreaLocation={canCreateDefinedAreaLocation}
            canCreatePointLocation={canCreatePointLocation}
          />
        </Grid.Col>
      </Grid.Row>
      {showCreatePointLocation && <EmbeddedCreatePoint onCancel={() => setShowCreatePointLocation(false)} onAdd={handleCreatePointLocation} />}
      {showCreateDefinedAreaLocation && (
        <EmbeddedCreateDefinedArea onCancel={() => setShowCreateDefinedAreaLocation(false)} onAdd={handleCreateDefinedAreaLocation} />
      )}
      <Grid.Row>
        <Grid.Col lg={5}>
          <ReadOnlyField name="satellite.name" label="ITU Name of the satellite" indicateRequired />
          <ErrorLabel name="satellite.name" />
        </Grid.Col>
        <Grid.Col lg={7}>
          <ReadonlyData label="Latitude and Longitude" value={formatLatLongs(latLongGeoref)} renderFormat={(values) => values?.join(', ')} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <Dropdown
            label="Licence classification"
            required
            name="satellite.licenceClassification"
            options={satelliteLicenceClassificationOptions}
            onChange={() => {
              if (!!initialValues?.satellite?.licenceClassification) {
                setWarningMessage(warningMessageMap.get(values?.satellite?.licenceClassification || 'A'));
              }
            }}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={2}>
          <Dropdown label="GSO/NGSO" required name="satellite.satelliteType" onChange={resetValues} options={satelliteTypeOptions} />
        </Grid.Col>
      </Grid.Row>
      {values.satellite.satelliteType === 'GSO' && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <NumberField name="satellite.orbitalPosition" label="Orbital position" required maxLength={7} errorImmediate />
          </Grid.Col>
        </Grid.Row>
      )}
      {values.satellite.satelliteType === 'NGSO' && (
        <Grid.Row>
          <Grid.Col lg={5}>
            <TextField name="satellite.minimumElevation" label="Minimum elevation" />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col lg={5}>
          <TextField name="satellite.ituNoticeId" label="ITU SNS Notice ID" required maxLength={9} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <TextField name="satellite.beamName" label="Beam name" maxLength={150} required />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <TextField name="satellite.commercialName" label="Commercial name of satellite" required maxLength={150} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <TextField name="satellite.owner" label="Satellite owner or operator" required maxLength={150} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={5}>
          <TextField name="satellite.additionalInfo" label="Additional information" multiline maxLength={2000} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default SatelliteDetailsSection;
