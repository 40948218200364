import { Text } from '@fluentui/react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import {
  LicenceClassificationType,
  LicenceDto,
  LicenceDtoLicenceTypeCodeEnum,
  LicenceGeneralConditionsDto,
  LicenceSpecificConditionsDto,
  LicenceSpectrumConfigurationDto,
  LocationConfigDto,
} from 'api-client';

import { Grid } from 'ui-library';

import Accordion from 'common/layout/Accordion';
import { useLicenceCraftingApi } from 'licence_management/craft/LicenceCraftingApiHook';

import { TextField } from '../../../../common/controls/inputs/TextField';
import MoreOrLess from '../../../../common/controls/surfaces/MoreOrLess';
import Form from '../../../../common/form/Form';
import { isEmpty } from '../../../../common/utils/objectUtils';
import { licenceConditionsSchema, nullSchema } from './licenceConditionsValidationSchema';

interface LicenceConditionsProps {
  licence: LicenceDto;
  licenceSpectrumConfig: LicenceSpectrumConfigurationDto;
  locationConfiguration?: LocationConfigDto;
  onDone: (isDone: boolean, conditions?: LicenceSpecificConditionsDto) => Promise<void>;
  onSaved: (conditions: LicenceSpecificConditionsDto) => void;
  readonly?: boolean;
  isPrevSectionsDone?: boolean;
}

const LicenceConditions: React.FC<LicenceConditionsProps> = (props) => {
  const licenceId = props.licence.id!;
  const requireSpectrums = props.licenceSpectrumConfig.maxSpectrums !== 0;
  const requiresTransmitLocations =
    props.locationConfiguration?.txLocationNumberConstraint !== null && props.licence.classification !== LicenceClassificationType.C;
  const requiresReceiveLocations =
    props.locationConfiguration?.rxLocationNumberConstraint !== null && props.licence.classification !== LicenceClassificationType.B;
  const requiresAuthorities = props.licence.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.SPL;
  const { updateLicenceConditions } = useLicenceCraftingApi();
  const handleUpdateLicenceConditions = (id: number, conditions: LicenceSpecificConditionsDto) =>
    updateLicenceConditions(id, conditions, { successMessage: 'The Conditions have been successfully updated' }).then(() => conditions);
  const {
    isBasicDetailsDone,
    isSpectrumDetailsDone,
    isTransmitLocationDetailsDone,
    isReceiveLocationDetailsDone,
    isAuthoritiesDone,
    isConditionsDone,
  } = props.licence.craftingProgress ?? {};
  const isDone = !!isConditionsDone;
  const isPreviousSectionsDone =
    props.isPrevSectionsDone ??
    !!(
      isBasicDetailsDone &&
      (!requireSpectrums || isSpectrumDetailsDone) &&
      (!requiresTransmitLocations || isTransmitLocationDetailsDone) &&
      (!requiresReceiveLocations || isReceiveLocationDetailsDone) &&
      (!requiresAuthorities || isAuthoritiesDone)
    );
  const [expanded, setExpanded] = useState<boolean | undefined>();
  const onContinueCrafting = () => props.onDone(false);
  const onDone = (conditions: LicenceSpecificConditionsDto) => props.onDone(true, conditions);
  const onSaved = props.onSaved;
  let submitDone: boolean = false;

  useEffect(() => {
    if (expanded === undefined) {
      setExpanded(isPreviousSectionsDone && !isDone); // Initially
    } else {
      setExpanded(!isDone);
    }
    // eslint-disable-next-line
  }, [isPreviousSectionsDone, isDone]);

  return (
    <Formik
      initialValues={{ specificConditions: props.licence.specificConditions }}
      onSubmit={(conditions) => handleUpdateLicenceConditions(licenceId, conditions).then(submitDone ? onDone : onSaved)}
      validationSchema={() => (submitDone ? licenceConditionsSchema : nullSchema)}
      enableReinitialize
    >
      {({ submitForm }: FormikProps<any>) => (
        <LicenceConditionsAccordion
          onDone={
            !props.readonly
              ? isDone
                ? onContinueCrafting
                : () => {
                    submitDone = true;
                    submitForm().then(() => (submitDone = false));
                  }
              : undefined
          }
          onExpandCollapsed={() => setExpanded(!expanded)}
          done={isDone}
          expanded={expanded}
        >
          <LicenceConditionsForm
            mode={isDone ? 'VIEW' : 'EDIT'}
            onCancelEdit={() => setExpanded(false)}
            licenceCondition={props.licence?.licenceConditions}
          />
        </LicenceConditionsAccordion>
      )}
    </Formik>
  );
};

export const LicenceConditionsForm = ({
  mode,
  onCancelEdit,
  name,
  licenceCondition,
}: {
  mode: 'VIEW' | 'EDIT';
  onCancelEdit?: () => void;
  name?: string;
  licenceCondition?: LicenceGeneralConditionsDto;
}) => {
  const namePrefix = name ? `${name}.` : '';
  const generalConditionsHtmlData = licenceCondition?.generalConditionsHtml
    ? licenceCondition?.generalConditionsHtml
    : '<p>No General Conditions Available</p>';
  const scheduleConditionsHtmlData = licenceCondition?.scheduleConditionsHtml
    ? licenceCondition?.scheduleConditionsHtml
    : '<p>No Schedule Conditions Available</p>';
  return (
    <Form id="conditions-form" mode={mode} onCancelEdit={onCancelEdit} hideBack hideFormButtonsTop showFormButtonsBottom disableErrorMessage>
      <Grid.Row>
        <Grid.Col lg={12} sm={12}>
          <TextField label="Specific conditions" name={`${namePrefix}specificConditions`} multiline={true} style={{ height: '13em' }} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row data-automation-id={'General Conditions'}>
        <Grid.Col lg={12} sm={12}>
          <MoreOrLess
            moreText="Show general conditions"
            lessText="Hide general conditions"
            more={
              <Text>
                <span dangerouslySetInnerHTML={{ __html: generalConditionsHtmlData }} />
              </Text>
            }
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row data-automation-id={'Schedule Conditions'}>
        <Grid.Col lg={12} sm={12}>
          <MoreOrLess
            moreText="Show schedule conditions"
            lessText="Hide schedule conditions"
            more={
              <Text>
                <span dangerouslySetInnerHTML={{ __html: scheduleConditionsHtmlData }} />
              </Text>
            }
          />
        </Grid.Col>
      </Grid.Row>
    </Form>
  );
};

const LicenceConditionsAccordion: React.FC<{
  expanded?: boolean;
  done?: boolean;
  onDone: (() => void) | undefined;
  onExpandCollapsed?: () => void;
  children?: React.ReactNode;
}> = (props) => {
  const { initialValues, errors } = useFormikContext<{}>();
  const [hasInitialError, setHasInitialError] = useState<boolean | undefined>();
  const hasError = !isEmpty(errors);

  // initialErrors holds errors on load, where as "errors" holds errors when Done is pressed
  useEffect(() => {
    setHasInitialError(!licenceConditionsSchema.isValidSync(initialValues));
    // eslint-disable-next-line
  }, [initialValues]);

  return (
    <Accordion {...props} id="licence-conditions" title="Conditions" error={hasInitialError || hasError}>
      {props.children}
    </Accordion>
  );
};

export default LicenceConditions;
