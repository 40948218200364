import { DefaultButton } from '@fluentui/react';
import React from 'react';

import { SpectrumSearchCriteria, SpectrumSearchResults } from 'api-client';
import { SpectrumSearchResult } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Pagination, { withSorting } from 'common/controls/items/Pagination';
import DetailsList from 'common/controls/lists/DetailsList';
import { useMedia } from 'common/layout/MediaHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatDateFromISO } from 'common/utils/dateUtils';
import { parseBoolean } from 'common/utils/objectUtils';
import { ITUServiceTypesITags } from 'licence_management/reference_data/spectrum_record/references';
import ExportSearchCommonResults from 'search/common/ExportSearchCommonResults';
import { AppliedFilters } from 'search/utils';

import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';

interface Props {
  searchCriteria?: SpectrumSearchCriteria;
  searchResults?: SpectrumSearchResults;
  onPaging: (page: number, pageSize: number) => void;
  onOrderBy: (orderBy: string) => void;
  submitted: boolean;
  labelChannel: string;
}

const SearchSpectrumRecordResults: React.FC<Props> = (props) => {
  const { navigateToMaintainSpectrumRecord } = useLicenceReferenceDataNavigator();
  const { isInternalUser } = useAuth();
  const { isMobile } = useMedia();

  const columns = [
    {
      key: 'channel',
      fieldName: 'channel',
      name: props.labelChannel,
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isNumber: true,
      onRender: (item: SpectrumSearchResult) => item.channel,
    },

    {
      key: 'lowerBound',
      fieldName: 'lowerBound',
      name: 'Spectrum Low (MHz)',
      minWidth: isInternalUser ? 150 : 200,
      maxWidth: isInternalUser ? 150 : 200,
      isMultiline: true,
      isResizable: true,
      isNumber: true,
      onRender: (item: SpectrumSearchResult) => item.lowerBound.toFixed(6),
    },
    {
      key: 'upperBound',
      fieldName: 'upperBound',
      name: 'Spectrum High (MHz)',
      minWidth: isInternalUser ? 150 : 200,
      maxWidth: isInternalUser ? 150 : 200,
      isResizable: true,
      isNumber: true,
      onRender: (item: SpectrumSearchResult) => item.upperBound.toFixed(6),
    },
    {
      key: 'refFrequency',
      fieldName: 'refFrequency',
      name: 'Ref. Frequency (MHz)',
      minWidth: isMobile ? 0 : 150,
      maxWidth: 200,
      isMultiline: true,
      isResizable: true,
      isNumber: true,
      onRender: (item: SpectrumSearchResult) => item.refFrequency?.toFixed(6),
    },
    {
      key: 'bandwidth',
      fieldName: 'bandwidth',
      name: 'Bandwidth (MHz)',
      minWidth: isInternalUser ? 100 : 180,
      maxWidth: isInternalUser ? 100 : 180,
      isResizable: true,
      isMultiline: true,
      isNumber: true,
      onRender: (item: SpectrumSearchResult) => item.bandwidth?.toFixed(6),
    },
    {
      key: 'service',
      fieldName: 'service',
      name: 'Service',
      minWidth: isInternalUser ? 150 : 200,
      maxWidth: isInternalUser ? 150 : 200,
      isResizable: true,
      isMultiline: true,
      onRender: (item: SpectrumSearchResult) => serviceDescription(item),
    },
    ...(isInternalUser
      ? [
          {
            key: 'visibility',
            fieldName: 'visibility',
            name: 'Spectrum visibility',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isMultiline: true,
            isSortable: false,
            onRender: (item: SpectrumSearchResult) => visibility(item.startDate, item.endDate),
          },
          {
            key: 'startDate',
            fieldName: 'startDate',
            name: 'Start date',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isMultiline: true,
            onRender: (item: SpectrumSearchResult) => formatDateFromISO(item.startDate),
          },
          {
            key: 'endDate',
            fieldName: 'endDate',
            name: 'End date',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isMultiline: true,
            onRender: (item: SpectrumSearchResult) => formatDateFromISO(item.endDate),
          },
        ]
      : []),
    {
      key: 'view',
      name: '',
      minWidth: 75,
      maxWidth: 75,
      onRender: (item: SpectrumSearchResult) => (
        <DefaultButton
          id={`view-${item.id}`}
          text="View"
          ariaLabel={`View spectrum ${item.channel ?? ''} ${item.lowerBound.toFixed(6) ?? ''} MHz - ${item.upperBound.toFixed(6) ?? ''} MHz ${
            item.refFrequency?.toFixed(6) ?? ''
          } - ${item.service} button`}
          onClick={() => navigateToMaintainSpectrumRecord(item.id!)}
        />
      ),
    },
  ];

  const visibility = (startDate?: string, endDate?: string): string => {
    const today = new Date();
    const strtDate = startDate != null ? new Date(startDate!) : startDate;
    const enddate = endDate != null ? new Date(endDate!) : endDate;
    if ((strtDate === undefined || strtDate === null || strtDate <= today) && (enddate === undefined || enddate === null || enddate >= today)) {
      return 'Current';
    } else if (strtDate! > today) {
      return 'Future';
    } else if (enddate! < today) {
      return 'Previous';
    }
    return '';
  };

  const serviceDescription = (item: SpectrumSearchResult) => (item.ituService !== null ? item.ituService : item.service);

  const csvHeaders = [
    { label: 'Id', key: 'id' },
    { label: props.labelChannel, key: 'channel' },
    { label: 'Spectrum Low (MHz)', key: 'lowerBound' },
    { label: 'Spectrum High (MHz)', key: 'upperBound' },
    { label: 'Ref. Frequency (MHz)', key: 'refFrequency' },
    { label: 'Bandwidth (MHz)', key: 'bandwidth' },
    { label: 'Service', key: 'service' },
    { label: 'Spectrum visibility', key: 'visibility' },
    { label: 'Start date', key: 'startDate' },
    { label: 'End date', key: 'endDate' },
  ];

  const transformForCsv = (data: SpectrumSearchResult[]) => {
    const copiedData: SpectrumSearchResult[] = JSON.parse(JSON.stringify(data));
    return copiedData.map((o) => Object.assign(o, { id: o.id, service: serviceDescription(o), visibility: visibility(o.startDate, o.endDate) }));
  };

  return (
    <>
      <SectionHeading title="Search results" />
      {props.searchResults?.results && props.searchResults.results.length > 0 ? (
        <>
          <Grid.Row>
            <ExportSearchCommonResults
              headers={isInternalUser ? csvHeaders : csvHeaders.filter((h) => h.key !== 'visibility' && h.key !== 'startDate' && h.key !== 'endDate')}
              data={transformForCsv(props.searchResults?.results) ?? []}
              currentPage={props.searchCriteria?.page}
              fileNamePrefix="Search Spectrum Record"
            />
          </Grid.Row>
          <Pagination
            filtersSummary={filtersSummary(props.searchCriteria)}
            renderResults={() => (
              <DetailsList columns={withSorting(columns!, props.searchCriteria, props.onOrderBy)} items={props.searchResults?.results ?? []} />
            )}
            onPaging={props.onPaging}
            showIncreasedPageSizes
            {...props.searchResults}
          />
        </>
      ) : props.submitted ? (
        <span>Your search returned no results.</span>
      ) : (
        ''
      )}
    </>
  );
};

const filtersSummary = (filter: SpectrumSearchCriteria = {}) =>
  new AppliedFilters()
    .push(filter.spectrumType, 'Spectrum Type')
    .push(parseBoolean(filter.generalPurpose) ? 'Yes' : parseBoolean(filter.generalPurpose) === false ? 'No' : undefined, 'General use')
    .push(filter.ituServiceId ? ITUServiceTypesITags.find((e) => filter.ituServiceId === e.key)?.name : undefined, 'ITU Service type')
    .push(filter.channel, 'Channel')
    .push(filter.visibility, 'Spectrum Visibility')
    .push(
      filter.refFrequencyExactMatch && (filter.refFrequency || (filter.refFrequency as any) === 0) ? `= ${filter.refFrequency}` : filter.refFrequency,
      'Frequency (MHz)',
    )
    .pushRange(filter.refFrequencyFrom, filter.refFrequencyTo, 'Frequency (MHz)')

    .get();

export default SearchSpectrumRecordResults;
