import { Formik } from 'formik';
import React from 'react';

import { SpectrumMaskDto } from 'api-client';

import Form from '../../../../common/form/Form';
import Page from '../../../../common/layout/Page';
import { validateWithFormValues } from '../../../../common/validation/validationWithFormValues';
import { useReferenceDataHookApi } from '../../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../../ReferenceDataRouter';
import SpectrumMaskFields from '../SpectrumMaskFields';
import { replaceGraphPointIndex } from '../utils';
import { spectrumMaskSchema } from '../validationSchema';

const CreateSpectrumMaskPage = () => {
  const { createSpectrumMask } = useReferenceDataHookApi();
  const { navigateToUpdateSpectrumMask } = useLicenceReferenceDataNavigator();

  const formInitValue = {
    identifier: '',
    description: '',
    spectrumMaskPoints: [
      {
        displacement: undefined,
        frequency: undefined,
        dbwSetLevel: undefined,
        calcLevel: undefined,
        displacementOrFrequenyError: undefined,
        calcLevelOrSetLevelError: undefined,
        graphPointIndex: 0,
      },
    ],
  };

  const submitForm = (values: any) =>
    createSpectrumMask(replaceGraphPointIndex(values) as SpectrumMaskDto, {
      successMessage: 'Spectrum Mask has been created.',
    }).then((response) => navigateToUpdateSpectrumMask(response.id!));

  return (
    <Page title="Create Spectrum Mask">
      <Formik initialValues={formInitValue} onSubmit={submitForm} validate={validateWithFormValues(spectrumMaskSchema, {})}>
        <Form id={'create-spectrum-mask-form'} showFormButtonsBottom mode="CREATE">
          <SpectrumMaskFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreateSpectrumMaskPage;
