import {
  AnnualRenewalInvoiceRedirectRequestDto,
  AnnualRenewalPaymentRequestDto,
  AnnualRenewalPaymentResultDto,
  ApplicantDto,
  ApplicationDecisionDto,
  ApplicationInvoiceRedirectRequestDto,
  ApplicationPaymentRequestDto,
  CancelRadioLicenceDto,
  CancelSpectrumLicenceDto,
  ChannelDto,
  ConvertShipCallSignDto,
  CreateFrequencySpectrumDto,
  EngineerDto,
  EventCriteria,
  FrequencyDto,
  IncompleteLicenceTransferRequestDto,
  ITUServiceTypeDto,
  LicenceCallsignTypeEnum,
  LicenceId,
  LicenceTransferRequestSearchCriteria,
  MaintainFrequencySpectrumDto,
  MaintainLicenceDto,
  ModifyRadioDetailsDto,
  ModifyReasonDto,
  ModifySpectrumDetailsDto,
  MortgageCaveatDto,
  NotesDto,
  PaymentRedirectDto,
  PaymentRequiredDetailsDto,
  PaymentResultDto,
  PaymentServiceRequestDto,
  PayRadioTransferRequestDto,
  PreferredBandDto,
  RadioApplicationDto,
  RejectReasonDto,
  ServiceTypeDto,
  SpectrumApplicationDto,
  SpectrumDto,
  SpectrumLicenceDto,
  SpectrumLicenceTransferRequestDto,
  ValidationCallsignRequestTypeEnum,
} from 'api-client';

import { AssignEngineerDto, AssociatedLicenceDto, EventResults, SearchTransactionResult, UpdateAssociatedLicenceDto } from '../../gen/api/api';
import { ApiConfig, useApi } from '../common/api/ApiHook';
import { applicationsApi, certificateApi, licenceApi, mapApi, paymentApi } from '../common/api/api';
import { useAuth } from '../common/auth/AuthHook';
import { toISODateString } from '../common/utils/dateUtils';

export const useLicenceApi = () => {
  const { aroundApi } = useApi();
  const { isInternalUser } = useAuth();

  return {
    getServiceTypes: (config?: ApiConfig): Promise<ServiceTypeDto[]> => aroundApi(licenceApi.getServiceTypes(), config),
    getServiceTypesForApplication: (config?: ApiConfig): Promise<ServiceTypeDto[]> => aroundApi(licenceApi.getServiceTypesForApplication(), config),
    getITUServiceTypes: (config?: ApiConfig): Promise<ITUServiceTypeDto[]> => aroundApi(licenceApi.getITUServiceTypes(), config),
    getPreferredBands: (serviceLevelOneId: number, config?: ApiConfig): Promise<PreferredBandDto[]> =>
      aroundApi(licenceApi.getPreferredBands(serviceLevelOneId), config),
    getChannels: (bandId: number, config?: ApiConfig): Promise<[ChannelDto]> => aroundApi(licenceApi.getChannels(bandId), config),
    getBaseMobileCallSignCaptureDetails: (licenceTypeId: number, bandIds: Array<number>, config?: ApiConfig) =>
      aroundApi(licenceApi.getBaseMobileCallSignCaptureDetails(licenceTypeId, bandIds), config),
    getRadioLicenceExternal: (id: number, config?: ApiConfig) => aroundApi(licenceApi.getRadioLicenceExternal(id), config),
    getRadioLicenceInternal: (id: number, config?: ApiConfig) => aroundApi(licenceApi.getRadioLicenceInternal(id), config),
    getSpectrumLicence: (id: number, config?: ApiConfig): Promise<SpectrumLicenceDto> => aroundApi(licenceApi.getSpectrumLicence(id), config),
    getLicence: (id: number, type: 'radio' | 'spectrum' = 'radio', config?: ApiConfig) => {
      if (type === 'spectrum') {
        return aroundApi(licenceApi.getSpectrumLicence(id), config);
      }
      return aroundApi(isInternalUser ? licenceApi.getRadioLicenceInternal(id) : licenceApi.getRadioLicenceExternal(id), config);
    },
    getApplicantDetails: (applicantId: number, config?: ApiConfig): Promise<ApplicantDto> =>
      aroundApi(licenceApi.getApplicantDetails(applicantId), config),
    getRadioApplication: (applicantId: number, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(licenceApi.getRadioApplication(applicantId), config),
    getRadioApplicationCopy: (applicantId: number, copyFlag: boolean, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(licenceApi.getRadioApplicationCopy(applicantId, copyFlag), config),
    getRadioApplicationLicence: (applicationId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.getRadioApplicationLicence(applicationId), config),
    getSatelliteAssociatedLicence: (applicationId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.getSatelliteAssociatedLicence(applicationId), config),
    getSpectrumApplication: (applicantId: number, config?: ApiConfig): Promise<SpectrumApplicationDto> =>
      aroundApi(licenceApi.getSpectrumApplication(applicantId), config),
    submitRadioApplication: (application: RadioApplicationDto, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(applicationsApi.submitRadioApplication(application), config),
    updateRadioApplication: (application: RadioApplicationDto, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(applicationsApi.updateRadioApplication(application.id!, application), config),
    updateLicenceClassification: (application: RadioApplicationDto, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(applicationsApi.updateLicenceClassification(application.id!, application), config),
    submitSpectrumApplication: (application: SpectrumApplicationDto, config?: ApiConfig): Promise<SpectrumApplicationDto> =>
      aroundApi(applicationsApi.submitSpectrumApplication(application), config),
    submitSatelliteDoneApplication: (application: RadioApplicationDto, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(applicationsApi.submitSatelliteDoneApplication(application), config),
    getEngineers: (config?: ApiConfig): Promise<EngineerDto[]> => aroundApi(licenceApi.getEngineers(), { ...config }),
    getAllEngineers: (config?: ApiConfig): Promise<EngineerDto[]> => aroundApi(licenceApi.getAllEngineers(), { ...config }),
    getEngineerByApprovalId: (id: number, config?: ApiConfig): Promise<EngineerDto> =>
      aroundApi(licenceApi.getEngineerByApprovalId(id), { ...config }),
    getInternalEngineerByUserlId: (id: number, config?: ApiConfig): Promise<EngineerDto> =>
      aroundApi(licenceApi.getInternalEngineerByUserId(id), { ...config }),
    getPaymentResult: (resultId: string, config?: ApiConfig): Promise<PaymentResultDto> =>
      aroundApi(paymentApi.getTransactionResult(resultId), config),
    searchPaymentTransactions: (paymentServiceRequestDto: PaymentServiceRequestDto, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(paymentApi.searchTransactions(paymentServiceRequestDto), config),
    maintainDirectDebitDetails: (paymentServiceRequestDto: PaymentServiceRequestDto, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(paymentApi.maintainDirectDebitDetails(paymentServiceRequestDto), config),
    getTransferRequestInvoiceDetail: (transferRequestId: number, invoiceId: string, config?: ApiConfig): Promise<SearchTransactionResult> =>
      aroundApi(paymentApi.getTransferRequestInvoiceDetail(transferRequestId, invoiceId), config),
    getCraftingConfigExternal: (id: number, config?: ApiConfig) => aroundApi(licenceApi.getLicenceCraftingConfigurationExternal(id), config),
    payApplication: (applicationId: number, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(licenceApi.payApplication(applicationId), config),
    payInvoice: (applicationId: number, invoiceNumber: string, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(licenceApi.payInvoice(applicationId, invoiceNumber), config),
    payTransfer: (payRadioTransferRequestDto: PayRadioTransferRequestDto, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(licenceApi.payTransfer(payRadioTransferRequestDto), config),
    generateOpenInvoiceTransfer: (transferRequestIds: Array<number>, clientInvoiceReference?: string, config?: ApiConfig) =>
      aroundApi(licenceApi.generateOpenInvoiceTransfer(transferRequestIds, clientInvoiceReference), config),
    payTransferInvoice: (transferRequestId: number, invoiceNumber: string, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(licenceApi.payTransferInvoice(transferRequestId, invoiceNumber), config),
    processTransferInvoicePayment: (transferRequestId: number, invoiceNumber: string, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.processTransferInvoicePayment(transferRequestId, invoiceNumber), config),
    processTransferPaymentResult: (bulkPaymentId: number, resultId: string, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.processTransferPaymentResult(bulkPaymentId, resultId), config),
    generateOpenInvoice: (applicationId: number, clientInvoiceReference?: string, config?: ApiConfig) =>
      aroundApi(licenceApi.generateOpenInvoice(applicationId, clientInvoiceReference), config),
    processApplicationPaymentResult: (licenceId: number, resultId: string, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.processApplicationPaymentResult(licenceId, resultId), config),
    hasUnpaidInvoicedApplications: (clientId: number, config?: ApiConfig): Promise<boolean> =>
      aroundApi(paymentApi.hasUnpaidInvoicedApplications(clientId), config),
    processApplicationInvoicePayment: (licenceId: number, invoiceNumber: string, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.processApplicationInvoicePayment(licenceId, invoiceNumber), config),
    getSpectrums: (licenceId: number, config?: ApiConfig): Promise<SpectrumDto[]> =>
      aroundApi(licenceApi.getSpectrums(licenceId), config).then(sortByReferenceFrequencies),
    maintainFrequencySpectrum: (licenceId: number, spectrum: MaintainFrequencySpectrumDto, config?: ApiConfig) =>
      aroundApi(licenceApi.maintainFrequencySpectrum(licenceId, spectrum), config).then((spectrum) =>
        spectrum ? sortByReferenceFrequencies([spectrum])[0] : spectrum,
      ),
    createFrequencySpectrum: (licenceId: number, spectrum: CreateFrequencySpectrumDto, config?: ApiConfig): Promise<SpectrumDto> =>
      aroundApi(licenceApi.createFrequencySpectrum(licenceId, spectrum), config),
    getSpectrumConfigs: (config?: ApiConfig) => aroundApi(licenceApi.getSpectrumConfigurations(), config),
    getTransmitLocationsExternal: (id: number, config?: ApiConfig) => aroundApi(licenceApi.getTransmitLocationsExternal(id), config),
    getReceiveLocationsExternal: (id: number, config?: ApiConfig) => aroundApi(licenceApi.getReceiveLocationsExternal(id), config),
    getAssociatedLicences: (licenceId: number, config?: ApiConfig) => aroundApi(licenceApi.getAssociatedLicences(licenceId), config),
    createAssociatedLicences: (licenceId: number, config?: ApiConfig) => aroundApi(licenceApi.createAssociatedLicences(licenceId), config),
    updateAssociatedLicenceLinks: (licenceId: number, associatedLicences: UpdateAssociatedLicenceDto[], config?: ApiConfig) =>
      aroundApi(licenceApi.updateAssociatedLicenceLinks(licenceId, associatedLicences), config),
    previewEngineersCertificate: (licenceId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.previewEngineersCertificate(licenceId, { responseType: 'blob' }), config),
    previewEngineersCertificateHtml: (licenceId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.previewEngineersCertificateHtml(licenceId), config),
    getEngineersCertificate: (licenceId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.getEngineersCertificate(licenceId, { responseType: 'blob' }), config),
    certifyLicence: (licenceId: number, notes: ApplicationDecisionDto, config?: ApiConfig) =>
      aroundApi(licenceApi.certifyLicence(licenceId, notes), config),
    modifyLicence: (licenceId: number, notes: ApplicationDecisionDto, config?: ApiConfig) =>
      aroundApi(licenceApi.modifyLicence(licenceId, notes), config),
    validateAssociatedForCertify: (licenceId: number, config?: ApiConfig) => aroundApi(licenceApi.validateAssociatedForCertify(licenceId), config),
    assignEngineer: (licenceId: number, engineer: AssignEngineerDto, config?: ApiConfig) =>
      aroundApi(licenceApi.assignEngineer(licenceId, engineer), config),
    approveRadioApplicationExternal: (applicationId: number, notes: ApplicationDecisionDto, config?: ApiConfig) =>
      aroundApi(applicationsApi.approveRadioApplicationExternal(applicationId, notes), config),
    rejectRadioApplicationExternal: (applicationId: number, notes: ApplicationDecisionDto, config?: ApiConfig) =>
      aroundApi(applicationsApi.rejectRadioApplicationExternal(applicationId, notes), config),
    approveRadioApplicationInternal: (applicationId: number, notes: ApplicationDecisionDto, config?: ApiConfig) =>
      aroundApi(applicationsApi.approveRadioApplicationInternal(applicationId, notes), config),
    rejectRadioApplicationInternal: (applicationId: number, notes: ApplicationDecisionDto, config?: ApiConfig) =>
      aroundApi(applicationsApi.rejectRadioApplicationInternal(applicationId, notes), config),
    grantLicence: (licenceId: number, config?: ApiConfig): Promise<PaymentRequiredDetailsDto> =>
      aroundApi(licenceApi.grantRadioLicence(licenceId), config),
    modifyRadioLicence: (licenceId: number, modifyDetails: ModifyRadioDetailsDto, config?: ApiConfig): Promise<RadioApplicationDto> =>
      aroundApi(licenceApi.modifyRadioLicence(licenceId, modifyDetails), config),
    modifySpectrumLicence: (licenceId: number, modifyDetails: ModifySpectrumDetailsDto, config?: ApiConfig): Promise<SpectrumApplicationDto> =>
      aroundApi(licenceApi.modifySpectrumLicence(licenceId, modifyDetails), config),
    modifySpectrumLicenceForm22: (licenceId: number, config?: ApiConfig): Promise<SpectrumApplicationDto> =>
      aroundApi(licenceApi.modifySpectrumLicenceForm22(licenceId), config),
    cancelRadioLicence: (cancelRequest: CancelRadioLicenceDto, config?: ApiConfig) => aroundApi(licenceApi.cancelRadioLicence(cancelRequest), config),
    isRefundable: (licenceIds: Array<number>, config?: ApiConfig) => aroundApi(licenceApi.isRefundable(licenceIds), config),
    validateLicenceForModification: (licenceId: number, config?: ApiConfig): Promise<ModifyReasonDto> =>
      aroundApi(licenceApi.validateLicenceForModification(licenceId), config),
    fetchLicenceTypesForLicensee: (licenseeId: number, excludeRecordLicenceTypes: boolean, config?: ApiConfig): Promise<Array<number>> =>
      aroundApi(licenceApi.getLicenceTypesForApplicant(licenseeId, excludeRecordLicenceTypes), config),
    fetchLicenceTypesForManager: (managerId: number, config?: ApiConfig): Promise<Array<number>> =>
      aroundApi(licenceApi.getLicenceTypesForManager(managerId), config),
    getRelatedLicences: (licId: number, agreedViewSuppressed: boolean) => aroundApi(licenceApi.getRelatedLicences(licId, agreedViewSuppressed)),
    removeRelatedLicence: (licId: number, licIdOther: number, config?: ApiConfig) =>
      aroundApi(licenceApi.removeRelatedLicence(licId, licIdOther), config),
    createRelatedLicence: (licId: number, data: AssociatedLicenceDto, config?: ApiConfig) =>
      aroundApi(licenceApi.createRelatedLicence(licId, data), config),
    getMapLicenceDetails: (licId: number, grt?: string) => aroundApi(mapApi.getLicenceDetails(licId, grt)),
    getLicenceEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(licenceApi.getLicenceEvents(entityId, criteria), config),
    radioLicenceTransferRequest: (transferRequest: IncompleteLicenceTransferRequestDto, config?: ApiConfig) =>
      aroundApi(licenceApi.requestTransferRadioLicence(transferRequest), config),
    sendForLaReview: (licenceId: number, notes: NotesDto, config?: ApiConfig) => aroundApi(licenceApi.sendForLaReview(licenceId, notes), config),
    doneLaReview: (licenceId: number, notes: NotesDto, config?: ApiConfig) => aroundApi(licenceApi.doneLaReview(licenceId, notes), config),
    searchTransferRequest: (criteria: LicenceTransferRequestSearchCriteria, config?: ApiConfig) =>
      aroundApi(licenceApi.searchLicenceTransferRequest(criteria), config),
    declineTransferRadioLicenceRequest: (transferRequestIds: Array<number>, config?: ApiConfig) =>
      aroundApi(licenceApi.declineTransferRadioLicenceRequest(transferRequestIds), config),
    acceptTransferRadioLicenceRequest: (transferRequestIds: Array<number>, config?: ApiConfig) =>
      aroundApi(licenceApi.acceptTransferRadioLicenceRequest(transferRequestIds), config),
    withdrawTransferRadioLicenceRequest: (transferRequestIds: Array<number>, config?: ApiConfig) =>
      aroundApi(licenceApi.withdrawTransferRadioLicenceRequest(transferRequestIds), config),
    spectrumLicenceTransferRequest: (transferRequest: SpectrumLicenceTransferRequestDto, config?: ApiConfig) =>
      aroundApi(licenceApi.requestTransferSpectrumLicence(transferRequest), config),
    spectrumLicenceCancelRequest: (cancelRequest: CancelSpectrumLicenceDto, config?: ApiConfig) =>
      aroundApi(licenceApi.cancelSpectrumLicence(cancelRequest), config),
    convertShipCallsignToShipLicence: (convertShipCallsign: ConvertShipCallSignDto, config?: ApiConfig) =>
      aroundApi(licenceApi.convertShipCallsignToShipLicence(convertShipCallsign), config),
    withdrawApplication: (applicationId: number, config?: ApiConfig) => aroundApi(licenceApi.withdrawApplication(applicationId), config),
    copyLicence: (licenceId: number, copyLicenceId: LicenceId, config?: ApiConfig) =>
      aroundApi(licenceApi.copyLicence(licenceId, copyLicenceId), config),
    declineLicence: (licenceId: number, rejectionReason: RejectReasonDto, config?: ApiConfig) =>
      aroundApi(licenceApi.declineLicence(licenceId, rejectionReason), config),
    maintainLicence: (licenceId: number, maintainLicenceDto: MaintainLicenceDto, config?: ApiConfig) =>
      aroundApi(licenceApi.maintainLicence(licenceId, maintainLicenceDto), config),
    validateLicenceCallsign: ({ callsign, callsignType }: { callsign?: string; callsignType: LicenceCallsignTypeEnum }, config?: ApiConfig) => {
      const typeMap = new Map<LicenceCallsignTypeEnum, ValidationCallsignRequestTypeEnum>([
        [LicenceCallsignTypeEnum.AMATEUR_PRIM, ValidationCallsignRequestTypeEnum.PRIM],
        [LicenceCallsignTypeEnum.LICENCE_AIR, ValidationCallsignRequestTypeEnum.LICENCE_AIR],
        [LicenceCallsignTypeEnum.LICENCE_AERO_BASE_STATION, ValidationCallsignRequestTypeEnum.LICENCE_AERO_BASE_STATION],
        [LicenceCallsignTypeEnum.LICENCE_LAND_MOBILE_FIXED, ValidationCallsignRequestTypeEnum.LICENCE_LAND_MOBILE_FIXED],
        [LicenceCallsignTypeEnum.LICENCE_MARITIME_COAST_STATION, ValidationCallsignRequestTypeEnum.LICENCE_MARITIME_COAST_STATION],
      ]);
      return aroundApi(
        certificateApi.validateCallsign({
          callsign,
          type: typeMap.get(callsignType),
          commenceDate: toISODateString(new Date()),
          expiryDate: '9999-12-31',
        }),
        config,
      );
    },
    deleteCertificate: (licenceId: number, config?: ApiConfig) => aroundApi(licenceApi.deleteCertificate(licenceId), config),
    payAnnualRenewal: (annualRenewalPaymentRequestDto: AnnualRenewalPaymentRequestDto, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(licenceApi.payAnnualRenewal(annualRenewalPaymentRequestDto), config),
    generateOpenInvoiceAnnualRenewal: (annualRenewalPaymentRequestDto: AnnualRenewalPaymentRequestDto, config?: ApiConfig) =>
      aroundApi(licenceApi.generateOpenInvoiceAnnualRenewal(annualRenewalPaymentRequestDto), config),
    processAnnualRenewalPaymentResult: (bulkPaymentId: number, resultId: string, config?: ApiConfig): Promise<AnnualRenewalPaymentResultDto> =>
      aroundApi(licenceApi.processAnnualRenewalPaymentResult(bulkPaymentId, resultId), config),
    payAnnualRenewalInvoice: (annualRenewalInvoiceRedirectRequestDto: AnnualRenewalInvoiceRedirectRequestDto, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.payAnnualRenewalInvoice(annualRenewalInvoiceRedirectRequestDto), config),
    processAnnualRenewalInvoicePayment: (annualFeeId: number, invoiceNumber: string, config?: ApiConfig): Promise<AnnualRenewalPaymentResultDto> =>
      aroundApi(licenceApi.processAnnualRenewalInvoicePayment(annualFeeId, invoiceNumber), config),
    payMultipleApplications: (applicationPaymentRequestDto: ApplicationPaymentRequestDto, config?: ApiConfig): Promise<PaymentRedirectDto> =>
      aroundApi(licenceApi.payMultipleApplications(applicationPaymentRequestDto), config),
    generateOpenInvoiceMultipleApplications: (applicationPaymentRequestDto: ApplicationPaymentRequestDto, config?: ApiConfig) =>
      aroundApi(licenceApi.generateOpenInvoiceMultipleApplications(applicationPaymentRequestDto), config),
    processMultipleApplicationPaymentResult: (bulkPaymentId: number, resultId: string, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.processMultipleApplicationPaymentResult(bulkPaymentId, resultId), config),
    payMultipleApplicationInvoice: (
      applicationInvoiceRedirectRequestDto: ApplicationInvoiceRedirectRequestDto,
      config?: ApiConfig,
    ): Promise<boolean> => aroundApi(licenceApi.payMultipleApplicationInvoice(applicationInvoiceRedirectRequestDto), config),
    processMultipleApplicationInvoicePayment: (licenceId: number, invoiceNumber: string, config?: ApiConfig): Promise<boolean> =>
      aroundApi(licenceApi.processMultipleApplicationInvoicePayment(licenceId, invoiceNumber), config),
    getMortgagesForSpectrumLicence: (licenceId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(licenceApi.getMortgagesForSpectrumLicence(licenceId), config),
    getCaveatsForSpectrumLicence: (licenceId: number, config?: ApiConfig): Promise<MortgageCaveatDto[]> =>
      aroundApi(licenceApi.getCaveatsForSpectrumLicence(licenceId), config),
    getLicenceExtract: (licenceId: number, licenceType: string, config?: ApiConfig): Promise<Blob> =>
      aroundApi(licenceApi.getLicenceExtract(licenceId, licenceType, { responseType: 'blob' }), config),
    getAssociatedLicenceSummary: (licenceId: number, config?: ApiConfig) => aroundApi(licenceApi.getAssociatedLicenceSummary(licenceId), config),
    getRadioApplicationForCopy: (applicationId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.getRadioApplicationForCopy(applicationId), config),
    getSpectrumApplicationForCopy: (applicationId: number, config?: ApiConfig) =>
      aroundApi(licenceApi.getSpectrumApplicationForCopy(applicationId), config),
    getFrequency: (frequencyId: number, config?: ApiConfig): Promise<FrequencyDto> => aroundApi(licenceApi.getFrequency(frequencyId), config),
  };
};

export const sortByReferenceFrequencies = (spectrums: SpectrumDto[]) => {
  const n = (n?: number) => (n || n === 0 ? n : -1);
  // undefined is considered lowest
  const byLowestReferenceFrequency = (f1: FrequencyDto, f2: FrequencyDto) => n(f1.referenceFrequency) - n(f2.referenceFrequency);

  spectrums = spectrums.map((spectrum) => ({ ...spectrum, frequencies: spectrum.frequencies?.sort(byLowestReferenceFrequency) }));
  return spectrums.sort((s1, s2) => {
    const frequency = (spectrum: SpectrumDto) => n([...(spectrum.frequencies ?? []), {}][0].referenceFrequency);
    return frequency(s1) - frequency(s2);
  });
};
