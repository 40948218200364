import _ from 'lodash';

export const asHtmlId = (name: string) =>
  name
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase();

export const fieldValuesChanged = (paths: string[], originalValue: any, modifiedValue: any) => {
  return paths.filter((path) => _.get(originalValue, path) !== _.get(modifiedValue, path)).length > 0;
};

export interface DataChangeUnit {
  changeType: string;
  originalValue?: any;
  modifiedValue?: any;
}

export const arrayHasChanges: (pathsToCheck: string[], originalValues: any[], modifiedValues: any[]) => DataChangeUnit[] = (
  pathsToCheck: string[],
  originalValues: any[],
  modifiedValues: any[],
) => {
  const trimmedOriginalValues = originalValues.map((val) => _.pick(val, pathsToCheck));
  const trimmedModifiedValues = modifiedValues.map((val) => _.pick(val, pathsToCheck));

  const isEqual = (val1: any, val2: any) => {
    const v1 = _.pick(val1, pathsToCheck);
    const v2 = _.pick(val2, pathsToCheck);
    return _.isEqualWith(v1, v2);
  };

  const deleted = originalValues
    .filter((beforeChange) => !modifiedValues.find((val) => isEqual(val, beforeChange)))
    .map((beforeChange: any) => ({
      changeType: 'deleted',
      originalValue: beforeChange,
    }));

  const created = modifiedValues
    .filter((afterChange) => {
      return !originalValues.find((val) => isEqual(afterChange, val));
    })
    .map((afterChange) => ({
      changeType: 'created',
      modifiedValue: afterChange,
    }));

  const unchanged = modifiedValues
    .filter((afterChange) => !!originalValues.find((val) => isEqual(afterChange, val)))
    .map((afterChange) => ({
      changeType: 'unchanged',
      originalValue: afterChange,
      modifiedValue: afterChange,
    }));

  return [...deleted, ...created, ...unchanged];
};
