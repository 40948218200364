import { MessageBarType } from '@fluentui/react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { OutageNotificationDto } from 'api-client';

import { ModalContextType } from '../controls/surfaces/ModalContextType';

// eslint-disable-next-line no-unused-vars
export enum TypeKeys {
  // eslint-disable-next-line no-unused-vars
  SET_IS_LOADING = 'SET_IS_LOADING',
  // eslint-disable-next-line no-unused-vars
  SET_PAGE_INSTRUCTION = 'SET_NOTIFICATION',
  // eslint-disable-next-line no-unused-vars
  CLEAR_PAGE_INSTRUCTION = 'CLEAR_NOTIFICATION',
  // eslint-disable-next-line no-unused-vars
  SHOW_MOBILE_NAV = 'SHOW_MOBILE_NAV',
  // eslint-disable-next-line no-unused-vars
  SET_SHOW_WARNING_MODAL = 'SET_SHOW_WARNING_MODAL',
  // eslint-disable-next-line no-unused-vars
  OTHER_ACTION = 'OTHER_ACTION',
  // eslint-disable-next-line no-unused-vars
  SET_OUTAGE_NOTIFICATION = 'SET_OUTAGE_NOTIFICATION',
  // eslint-disable-next-line no-unused-vars
  CLEAR_OUTAGE_NOTIFICATION = 'CLEAR_OUTAGE_NOTIFICATION',
}

// --------------------------
// isLoading
// --------------------------
export interface SetIsLoadingAction {
  type: TypeKeys.SET_IS_LOADING;
  payload: boolean | string;
}

// Action creator
export const setIsLoading = (loading: boolean | string): PageStateActionType => {
  return {
    type: TypeKeys.SET_IS_LOADING,
    payload: loading,
  };
};

// --------------------------
// setPageInstruction
// --------------------------
export interface SetPageInstructionAction {
  type: TypeKeys.SET_PAGE_INSTRUCTION;
  payload: { message?: string; type?: MessageBarType };
  noExpiry: boolean;
}

// Action creator
export const setPageInstruction = (payload?: { message?: string | JSX.Element; type?: MessageBarType; noExpiry?: boolean }) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
): Promise<void> => {
  return new Promise<void>((_) => {
    dispatch({
      type: TypeKeys.SET_PAGE_INSTRUCTION,
      payload,
    });

    if (payload?.message && payload.message !== '') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  });
};

// --------------------------
// clearPageInstruction
// --------------------------
export interface ClearPageInstructionAction {
  type: TypeKeys.CLEAR_PAGE_INSTRUCTION;
  payload: { message?: string; type?: MessageBarType; noExpiry?: boolean };
}

// Action creator
export const clearPageInstruction = (payload?: {}) => (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  return new Promise<void>((_) => {
    dispatch({
      type: TypeKeys.CLEAR_PAGE_INSTRUCTION,
      payload,
    });
  });
};

// --------------------------
// Outage Notifications
// --------------------------
export interface SetOutageNotificationAction {
  type: TypeKeys.SET_OUTAGE_NOTIFICATION;
  payload: OutageNotificationDto;
}

// Action creator
export const setOutageNotificationAction = (payload?: OutageNotificationDto) => (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  return new Promise<void>((_) => {
    dispatch({
      type: TypeKeys.SET_OUTAGE_NOTIFICATION,
      payload,
    });

    if (payload?.message && payload?.message !== '') {
      //  && payload.messages.length > 0
      window.scrollTo(0, 0);
    }
  });
};

export interface ClearOutageNotificationAction {
  type: TypeKeys.CLEAR_OUTAGE_NOTIFICATION;
  payload: OutageNotificationDto;
}

// Action creator
export const clearOutageNotification = (payload?: {}) => (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  return new Promise<void>((_) => {
    dispatch({
      type: TypeKeys.CLEAR_OUTAGE_NOTIFICATION,
      payload,
    });
  });
};

// --------------------------
// setShowMobileNav
// --------------------------
export interface ShowMobileNavAction {
  type: TypeKeys.SHOW_MOBILE_NAV;
  payload: boolean;
}

// Action creator
export const setShowMobileNav = (show: boolean) => {
  return {
    type: TypeKeys.SHOW_MOBILE_NAV,
    payload: show,
  };
};

// --------------------------
// showWarningModal
// --------------------------
export interface WarningModalAction {
  type: TypeKeys.SET_SHOW_WARNING_MODAL;
  payload: { show: boolean; warnings?: string[]; hasConfirmed?: boolean; modalContext?: ModalContextType };
}

export const setShowWarningModal = (show: boolean, warnings?: string[], hasConfirmed?: boolean, modalContext?: ModalContextType) => {
  return {
    type: TypeKeys.SET_SHOW_WARNING_MODAL,
    payload: { showWarningModal: show, modalContext, warnings, hasConfirmed },
  };
};

// --------------------------
// OtherAction
// --------------------------

export interface OtherAction {
  type: TypeKeys.OTHER_ACTION;
}

export type PageStateActionType =
  | SetIsLoadingAction
  | SetPageInstructionAction
  | ClearPageInstructionAction
  | SetOutageNotificationAction
  | ClearOutageNotificationAction
  | ShowMobileNavAction
  | WarningModalAction
  | OtherAction;
