import { RolesUserDto } from 'api-client';

import { ApiConfig, useApi } from '../api/ApiHook';
import { securityApi } from '../api/api';

export const useSecurityApi = () => {
  const { aroundApi } = useApi();

  return {
    rolesByUser: (config?: ApiConfig) => aroundApi(securityApi.rolesByUser(), config),
    issueJwt: (rolesUserDto: RolesUserDto, config?: ApiConfig) => aroundApi(securityApi.issueJwt(rolesUserDto), config),
  };
};
