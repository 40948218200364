import { PopupOptions } from 'azure-maps-control';

import { PopupType } from 'search/licence/MapLicenceDetailCard';

export interface PopupDetails {
  visible: boolean;
  modal: boolean;
  dataType: PopupType;
  data?: Array<any>;
  count: number;
  dataCount: number;
  options?: PopupOptions;
}

export const DEFAULT_POPUP_DETAILS: PopupDetails = {
  visible: false,
  modal: false,
  dataType: PopupType.POPUP_TYPE_SEARCH,
  data: undefined,
  count: 0,
  dataCount: 0,
};
