import {
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  IButtonStyles,
  IContextualMenuItem,
  IPersonaCoinProps,
  IStyle,
  Persona as FluentUIPersona,
  PersonaPresence,
  PersonaSize,
  TooltipHost,
  mergeStyles,
  Link,
} from '@fluentui/react';
import _ from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';

import { RolesUserDto } from 'api-client';

import { useUserNavigation } from 'client_management/user/UserRouter';
import TooltipHint from 'common/controls/items/TooltipHint';
import ConfirmDialog from 'common/controls/surfaces/ConfirmDialog';
import { useTheme } from 'common/theme/RRFTheme';
import { emptyStringIfUndefined } from 'common/utils/stringUtils';

import { useClientNavigation } from '../../../client_management/client/ClientRouter';
import { useAuth } from '../../auth/AuthHook';
import { handleLogout } from '../../auth/login';
import { useSecurityApi } from '../../auth/securityApiHook';
import NotLoggedInMenuITems from '../../layout/LoginItems';
import { NavigationContext } from '../../layout/Navigation';
import { REFERENCE_DATA_ROLES_LOGIN } from '../../reference/referenceData';
import { ConfirmNavigateModal, dirtyFormConfirmStrategy, neverConfirmStrategy } from '../inputs/ConfirmOnClick';

interface PopUpValidation {
  display: boolean;
  userRoleSelected?: RolesUserDto;
}

const getRoleName = (role: string | undefined, authorityType: string | undefined) =>
  emptyStringIfUndefined(REFERENCE_DATA_ROLES_LOGIN.find((x) => x.key === role || x.key === role + '_' + authorityType)?.text);

const Persona: React.FC = () => {
  const theme = useTheme();
  const { navigateToMyUserAccount } = useUserNavigation();
  const { firstName, lastName, isLocked } = useAuth();
  const coinProps: IPersonaCoinProps = {
    styles: { coin: { cursor: 'Pointer' }, initials: { backgroundColor: theme.semanticColors.primaryButtonBackground } },
    onClick: () => (!isLocked ? navigateToMyUserAccount() : undefined),
  };

  const { loggedIn } = useAuth();

  let userLogin = 'Login';
  if (firstName || lastName) {
    userLogin = emptyStringIfUndefined(firstName) + ' ' + emptyStringIfUndefined(lastName);
  }

  return (
    <>
      {!loggedIn && <NotLoggedInMenuITems />}
      {loggedIn && (
        <FluentUIPersona
          id="persona"
          text={userLogin}
          size={PersonaSize.size40}
          onRenderPrimaryText={(props) => <PrimaryText text={props?.text} />}
          onRenderSecondaryText={(props) => <SecondaryText />}
          coinProps={coinProps}
          initialsColor={theme.semanticColors.primaryButtonBackground}
          presence={PersonaPresence.online}
        />
      )}
    </>
  );
};

const PrimaryText = (props: { text?: string }) => {
  const { loggedIn, currentRole, authorityType, currentClientId, myRolesAvailables: myRoles, isInternalUser, isLocked } = useAuth();
  const { formikContext, mode } = React.useContext(NavigationContext);
  const { issueJwt } = useSecurityApi();
  const { refreshJWT } = useAuth();
  const history = useHistory();

  const initialPopupValidation: PopUpValidation = { display: false };
  const [displayPopUp, setDisplayPopUp] = React.useState<PopUpValidation>(initialPopupValidation);
  const [displayUserPopUp, setDisplayUserPopUp] = React.useState<boolean>(false);
  const { navigateToMyUserAccount, navigateToOnboarding } = useUserNavigation();
  const { navigateToSelfRegisterClient } = useClientNavigation();

  const theme = useTheme();
  const commonStyles: IStyle = {
    color: theme.palette.white,
    backgroundColor: theme.semanticColors.menuHeader,
  };

  const parseListRolesAvailablesText = (roleUserDto: RolesUserDto) => {
    let text = '';
    text += getRoleName(roleUserDto.roleName, roleUserDto.authorityType);
    text += roleUserDto.clientName ? '/' + roleUserDto.clientName + ' ' : '';
    text += roleUserDto.clientId ? roleUserDto.clientId : '';

    return text;
  };

  const sortAvailableRoles = (a: any, b: any) => {
    if (a.text === b.text) {
      if (a.clientId) {
        if (b.clientId) {
          return a.clientId < b.clientId ? -1 : 1;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    }
    return a.text!.localeCompare(b.text!);
  };

  const parseRoles = (list: RolesUserDto[]) => {
    return list
      .map((x: RolesUserDto) => ({
        ...x,
        key: `${x.roleName}_${x.authorityType}_${x.clientId}`,
        text: parseListRolesAvailablesText(x),
        onClick: () => setDisplayPopUp({ display: true, userRoleSelected: x }),
      }))
      .filter(
        (value) =>
          currentRole !== value.roleName ||
          // eslint-disable-next-line eqeqeq
          value.clientId != currentClientId ||
          // eslint-disable-next-line eqeqeq
          (value.clientId === currentClientId && value.authorityType != authorityType),
      )
      .sort(sortAvailableRoles);
  };

  const parsedRoles = myRoles ? parseRoles(myRoles) : [];

  const manageRealMe = !isInternalUser
    ? [
        {
          key: 'manageRealMe',
          text: 'Manage My RealMe',
          hidden: true,
          onClick: () => {
            window.open('https://www.realme.govt.nz', '_blank');
          },
          onRenderContent: () => (
            <div className="ms-ContextualMenu-link">
              <span className="ms-ContextualMenu-itemText" style={{ margin: '0px 4px' }}>
                Manage My RealMe
              </span>
              <TooltipHint id="manage-my-realme-tool-tip" content={<span>Login to RealMe to manage your account.</span>} />
            </div>
          ),
        },
      ]
    : [];

  const manageMyAccount = !isLocked
    ? [
        {
          key: 'myAccount',
          text: 'My account',
          onClick: () => navigateToMyUserAccount(),
        },
      ]
    : [];

  const styles: IButtonStyles = {
    root: {
      ...commonStyles,
      borderColor: 'transparent',
      padding: 0,
    },
    rootHovered: { ...commonStyles },
    rootExpanded: { ...commonStyles },
    rootPressed: { ...commonStyles },
  };

  const loggedInMenuItems = [
    ...parsedRoles,
    { key: 'divider_Logout', itemType: ContextualMenuItemType.Divider },
    ...manageMyAccount,
    {
      key: 'linkAccount',
      text: 'Link with existing accounts',
      onClick: navigateToOnboarding,
    },
    ...manageRealMe,
    {
      key: 'logout',
      text: 'Logout',
      onClick: () => {
        setDisplayPopUp({ display: true });
      },
    },
  ];

  const menuItems: IContextualMenuItem[] = loggedInMenuItems;

  const handleChangeRole = (roleUserDto: RolesUserDto) => {
    issueJwt(roleUserDto, { showLoadingSpinner: true }).then((response) => {
      refreshJWT(response);
      history.push('/ui/app/dashboard');
    });
  };

  const handleConfirmClick = () => {
    if (displayPopUp.userRoleSelected) {
      handleChangeRole(displayPopUp.userRoleSelected);
      setDisplayPopUp({ display: false });
    } else {
      handleLogout();
    }
  };

  const handleOnClickOk = () => {
    navigateToSelfRegisterClient();
    setDisplayUserPopUp(false);
  };

  const handleOnCancel = () => {
    setDisplayUserPopUp(false);
  };

  React.useEffect(() => {
    if (
      window.location.href === `${window.location.origin}/ui/` &&
      loggedIn &&
      (_.isEmpty(myRoles) ||
        (myRoles && myRoles.filter((role) => role.roleName === 'ROLE_CLIENT' && role.clientId === null).length > 1 && myRoles.length === 1))
    ) {
      setDisplayUserPopUp(true);
    }
  }, []);

  return (
    <>
      {loggedIn && (
        <DefaultButton
          id="persona-button"
          data-automation-id="persona-button"
          styles={styles}
          text={props?.text}
          menuProps={{ items: menuItems, id: 'persona-menu' }}
        />
      )}
      <ConfirmNavigateModal
        confirmStrategy={formikContext && mode !== 'SEARCH' && mode !== 'VIEW' ? dirtyFormConfirmStrategy(formikContext) : neverConfirmStrategy}
        onClick={handleConfirmClick}
        onCancel={() => setDisplayPopUp({ display: false })}
        visible={displayPopUp?.display}
        formikContext={formikContext!}
      />
      <ConfirmDialog
        title=""
        onOk={handleOnClickOk}
        onCancel={handleOnCancel}
        body={
          <div>
            <p>
              Your account does not appear to be linked with any client. Clicking on 'Ok' will navigate you to the screen where you can register a
              client to work with.
            </p>
            <p>
              If you are already registered with a client and wish to link it with your user account, please click &nbsp;
              <Link
                href={`/ui/app/onboarding`}
                onClick={(e) => {
                  navigateToOnboarding();
                  setDisplayUserPopUp(false);
                }}
              >
                here
              </Link>
            </p>
          </div>
        }
        visible={displayUserPopUp}
        yesText="Ok"
        noText="Cancel"
        notDangerous
      />
    </>
  );
};

const SecondaryText = () => {
  const theme = useTheme();
  const { currentRole, currentClientName, authorityType, currentClientId } = useAuth();
  const clientProfile = currentClientName ? '/' + currentClientName + ' ' + emptyStringIfUndefined(currentClientId) : '';
  const secondaryText = getRoleName(currentRole, authorityType) + clientProfile;
  const secondaryTextMaxLenght = 40;
  const truncate = (text: string) => (text.length > secondaryTextMaxLenght ? text.substring(0, secondaryTextMaxLenght) + '...' : text);

  return (
    <TooltipHost
      directionalHint={DirectionalHint.leftCenter}
      closeDelay={1000}
      tooltipProps={{ maxWidth: '1200' }}
      content={<span>{secondaryText}</span>}
      className={mergeStyles({ border: '1px solid #C84504' })}
    >
      <span style={{ color: theme.palette.white }}>{truncate(secondaryText)}</span>
    </TooltipHost>
  );
};

export default Persona;
