import { AtonDto, EventCriteria, EventResults } from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { atonApi } from 'common/api/api';

export const useAtonApi = () => {
  const { aroundApi } = useApi();

  return {
    createAton: (atonDto: AtonDto, config?: ApiConfig) => aroundApi(atonApi.createAton(atonDto), config),
    getAton: (atonId: number, config?: ApiConfig) => aroundApi(atonApi.getAton(atonId), config),
    maintainAton: (atonId: number, atonDto: AtonDto, config?: ApiConfig) => aroundApi(atonApi.maintainAton(atonId, atonDto), config),
    cancelAton: (atonId: number, atonDto: AtonDto, config?: ApiConfig) => aroundApi(atonApi.cancelAton(atonId, atonDto), config),
    getAtonEvents: (entityId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(atonApi.getAtonEvents(entityId, criteria), config),
  };
};
