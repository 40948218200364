import * as yup from 'yup';

import { GeoreferenceDto } from 'api-client';

import { isEmpty } from 'common/validation/yupUtils';
import { georeferenceSchema } from 'licence_management/reference_data/location/validationSchema';

export const isNotEmptyGeoreference = (georeference?: GeoreferenceDto) =>
  !isEmpty(georeference?.mapNumber) || !isEmpty(georeference?.easting) || !isEmpty(georeference?.northing);

const validationSchema = yup.object().shape({
  searchTextError: yup
    .string()
    .nullable(true)
    .test('search required', 'Please specify your query', function () {
      return !isEmpty(this.parent.searchText) || isNotEmptyGeoreference(this.parent.georeference);
    }),
  georeference: yup.object().when(['$georeference'], {
    is: isNotEmptyGeoreference,
    then: georeferenceSchema,
  }),
});

export default validationSchema;
