import { IDropdownOption } from '@fluentui/react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Schema } from 'yup';

import {
  CreateFrequencySpectrumDto,
  LocationConfigDto,
  LocationSearchResult,
  MaintainFrequencySpectrumDto,
  RadioLicenceDto,
  ReceiveLocationDto,
  SpectrumDto,
  SpectrumDtoSpectrumTypeEnum,
  TransmitLocationDto,
} from 'api-client';

import Card from 'common/controls/surfaces/Card';
import Accordion from 'common/layout/Accordion';
import { useLicenceCraftingApi } from 'licence_management/craft/LicenceCraftingApiHook';

import Error from '../../../../common/controls/items/Error';
import { isEmpty } from '../../../../common/utils/objectUtils';
import { validateWithFormValues } from '../../../../common/validation/validationWithFormValues';
import EmbeddedSearchLocation from '../../../../search/location/EmbeddedSearchLocation';
import { LocationTypeOptionsEnum } from '../../../../search/location/SearchLocationForm';
import { Grid } from '../../../../ui-library';
import { useLicenceApi } from '../../../LicenceApiHooks';
import { useDoneValidation } from '../../../craft/DoneValidationHooks';
import { spectrumsAsFormValues } from '../../../craft/components/SpectrumDetails/LicenceSpectrumDetails';
import LicenceLocationList from '../../../craft/components/licence_locations/common/LocationsList';
import SpectrumDetailsForm from './SpectrumDetailsForm';
import {
  receiveLocationDoneSchema,
  satelliteReceiveLocationAllDoneSchema,
  satelliteReceiveLocationsSchema,
  satelliteSpectrumDetailsSchema,
  satelliteTransmitLocationAllDoneSchema,
  satelliteTransmitLocationsSchema,
  transmitLocationDoneSchema,
} from './validationSchema';

interface Props {
  locationType: 'TRANSMIT' | 'RECEIVE';
  licence: RadioLicenceDto;
  onDone: (isDone: boolean) => Promise<void>;
  isPreviousSectionsDone: boolean;
  locationConfiguration: LocationConfigDto;
}

const LocationSection: React.FC<Props> = (props) => {
  const { createFrequencySpectrum, getSpectrums, maintainFrequencySpectrum } = useLicenceApi();

  const {
    maintainReceiveLocation,
    maintainTransmitLocation,
    removeLicenceConfiguration,
    createReceiveLocation,
    createTransmitLocation,
    getReceiveLocations,
    getTransmitLocations,
    removeSpectrum,
  } = useLicenceCraftingApi();

  const [locations, setLocations] = React.useState<TransmitLocationDto[] | ReceiveLocationDto[]>([]);
  const location = locations?.length ? locations[0] : undefined;

  const [spectrums, setSpectrumsState] = useState<SpectrumDto[]>([]);
  const setSpectrums = (spectrums: SpectrumDto[]) => setSpectrumsState(spectrumsAsFormValues(spectrums));
  const spectrum = spectrums?.length ? spectrums[0] : undefined;

  const [expanded, setExpanded] = useState<boolean | undefined>();

  const isDone =
    props.locationType === 'TRANSMIT'
      ? !!props.licence?.craftingProgress?.isTransmitLocationDetailsDone
      : !!props.licence?.craftingProgress?.isReceiveLocationDetailsDone;

  const allDoneSchema = props.locationType === 'TRANSMIT' ? satelliteTransmitLocationAllDoneSchema : satelliteReceiveLocationAllDoneSchema;

  const spectrumSectionTitle = props.locationType === 'TRANSMIT' ? 'Create a spectrum record (Transmit)' : 'Create a spectrum record (Receive)';

  const [viewLocationId, setViewLocationId] = useState<number | undefined>();
  const [lastSaved, setLastSaved] = useState<Date | undefined>();

  let [submit, setSubmitState] = useState<{ onSubmit: (values: any) => Promise<any>; schema: Schema<any>; isDone?: boolean }>({
    onSubmit: () => Promise.resolve(),
    schema: yup.object(),
  });
  const setSubmit = (submitState: { onSubmit: (values: any) => Promise<any>; schema: Schema<any> }) => {
    submit = submitState;
    setSubmitState(submitState);
  };

  // Preserve any unsaved spectrum details changes following save location
  const continueEditingSpectrumDetails = ({ spectrum }: { spectrum?: SpectrumDto }) => {
    if (spectrum) {
      setSpectrums([spectrum]);
    }
  };

  let validateDone = false;

  const submitDone = {
    isDone: true,
    schema: allDoneSchema,
    onSubmit: (values: {
      spectrum?: MaintainFrequencySpectrumDto & CreateFrequencySpectrumDto;
      locations?: TransmitLocationDto[] | ReceiveLocationDto[];
    }) => onDone(values.spectrum!, values.locations ? values.locations[0] : undefined),
  };

  const submitSpectrum = {
    schema: satelliteSpectrumDetailsSchema,
    onSubmit: ({ spectrum }: { spectrum: MaintainFrequencySpectrumDto & CreateFrequencySpectrumDto }) => handleSaveSpectrum(spectrum),
  };

  const submitLocation = {
    schema: props.locationType === 'TRANSMIT' ? satelliteTransmitLocationsSchema : satelliteReceiveLocationsSchema,
    onSubmit: ({ locations }: { locations: TransmitLocationDto[] | ReceiveLocationDto[] }) => handleSaveLocation(locations[0]),
  };

  const onDone = async (
    updatingSpectrum: MaintainFrequencySpectrumDto & CreateFrequencySpectrumDto,
    updatingLocation?: TransmitLocationDto | ReceiveLocationDto,
  ) => {
    const canDone1 = !!(await handleSaveSpectrum(updatingSpectrum));
    const canDone2 = updatingLocation ? !!(await handleSaveLocation(updatingLocation)) : true;
    return canDone1 && canDone2 ? props.onDone(true) : undefined;
  };

  useEffect(() => {
    if (props.locationType === 'TRANSMIT') {
      getTransmitLocations(props.licence.id!, { showLoadingSpinner: true }).then(setLocations);
    } else {
      getReceiveLocations(props.licence.id!, { showLoadingSpinner: true }).then(setLocations);
    }
    getSpectrums(props.licence.id!, { showLoadingSpinner: true }).then(setSpectrums);
  }, [props.licence]);

  const onContinueCrafting = () => props.onDone(false);

  useEffect(() => {
    if (expanded === undefined) {
      setExpanded(props.isPreviousSectionsDone && !isDone);
    } else {
      setExpanded(!isDone);
    }
  }, [props.isPreviousSectionsDone, !!isDone]);

  const handleSelectLocation = async (location: LocationSearchResult) => {
    if (props.locationType === 'TRANSMIT') {
      await createTransmitLocation(props.licence.id!, location.id);
      getTransmitLocations(props.licence.id!).then(setLocations);
    } else {
      await createReceiveLocation(props.licence.id!, location.id);
      getReceiveLocations(props.licence.id!).then(setLocations);
    }
  };

  const handleRemoveLocation = (loc: TransmitLocationDto | ReceiveLocationDto) =>
    removeLicenceConfiguration(props.licence.id!, loc.id!).then(() => setLocations(locations.filter((r) => r.id !== loc.id)));

  const handleSaveLocation = (loc: TransmitLocationDto | ReceiveLocationDto) => {
    const updateLocations = (updatedLocs: TransmitLocationDto[] | ReceiveLocationDto[]) => {
      if (updatedLocs) {
        setLocations(updatedLocs);
        setLastSaved(new Date());
      }
      return updatedLocs[0];
    };

    return props.locationType === 'TRANSMIT'
      ? maintainTransmitLocation(props.licence.id!, loc.id!, {
          ...loc,
          licenceId: props.licence.id,
          antennaId: loc.antenna?.id,
        } as any).then(() => {
          return getTransmitLocations(props.licence.id || 0).then(updateLocations);
        })
      : maintainReceiveLocation(props.licence.id!, loc.id!, {
          ...loc,
          licenceId: props.licence.id,
          antennaId: loc.antenna?.id,
        } as any).then(() => {
          return getReceiveLocations(props.licence.id || 0).then(updateLocations);
        });
  };

  const handleSaveSpectrum = (spm: MaintainFrequencySpectrumDto & CreateFrequencySpectrumDto) => {
    spm = transformSpectrumDto(spm);
    const updateSpectrum = (updatedSpectrum: SpectrumDto) => {
      if (updatedSpectrum) {
        setSpectrums([updatedSpectrum]);
        setLastSaved(new Date());
      }
      return updatedSpectrum;
    };

    return (spm.id ? maintainFrequencySpectrum(props.licence.id || 0, spm) : createFrequencySpectrum(props.licence.id!, spm)).then(updateSpectrum);
  };

  const handleRemoveSpectrum = (spectrumId: number) => {
    const removeFromSpectrums = () => {
      setSpectrums([]);
      return spectrums;
    };
    return removeSpectrum(props.licence.id || 0, spectrumId, { showLoadingSpinner: 'Removing...' }).then(removeFromSpectrums);
  };

  return (
    <Formik<{ spectrum: SpectrumDto; locations: TransmitLocationDto[] | ReceiveLocationDto[] }>
      initialValues={
        {
          spectrum: spectrum
            ? {
                ...spectrum,
                frequencies: spectrum.frequencies && spectrum.frequencies.length > 0 ? spectrum.frequencies : [emptyFrequencyObj],
              }
            : ({
                spectrumType: SpectrumDtoSpectrumTypeEnum.FRQ,
                lowerBound: undefined,
                upperBound: undefined,
                frequencies: [emptyFrequencyObj],
              } as any),
          locations: location
            ? [{ ...location }]
            : [
                {
                  id: undefined,
                  stationType: undefined,
                  antenna: undefined,
                  antennaPattern: undefined,
                  antennaPatternOther: undefined,
                },
              ],
        } as any
      }
      onSubmit={submit.onSubmit}
      validate={(values) => validateWithFormValues(validateDone ? submitDone.schema : submit.schema, { ...props.licence })(values)}
      enableReinitialize
    >
      {(formProps: FormikProps<{ spectrum: SpectrumDto; locations: TransmitLocationDto[] | ReceiveLocationDto[] }>) => (
        <LocationSectionAccordion
          locationType={props.locationType}
          onDone={
            isDone
              ? onContinueCrafting
              : () => {
                  setSubmit(submitDone);
                  formProps.submitForm();
                }
          }
          done={isDone}
          onValidateDone={() => {
            validateDone = true;
            formProps.validateForm().then(() => (validateDone = false));
          }}
          licence={props.licence}
          onExpandCollapsed={() => setExpanded(!expanded)}
          expanded={expanded}
        >
          {!isDone && !(locations?.length === 1) ? (
            <Grid.Row>
              <Grid.Col>
                <Card>
                  <EmbeddedSearchLocation
                    id={props.locationType + 'search-location-form'}
                    onSelect={(loc) => handleSelectLocation(loc).then(() => continueEditingSpectrumDetails(formProps.values))}
                    searchLabel="Search and Link an existing location record"
                    searchOptions={{
                      locationTypeOptions: [LocationTypeOptionsEnum.POINT, LocationTypeOptionsEnum.DEFINED_AREA],
                      hideVisibiltiy: true,
                    }}
                    defaultLocationType={'P'}
                  />
                </Card>
              </Grid.Col>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Col>
                <LicenceLocationList
                  onFocus={() => submit.isDone || setSubmit(submitLocation)}
                  licenceId={props.licence.id ?? -1}
                  onRemove={(loc) => handleRemoveLocation(loc).then(() => continueEditingSpectrumDetails(formProps.values))}
                  isDone={isDone}
                  locationConfiguration={props.locationConfiguration}
                  onSave={() => {
                    setSubmit(submitLocation);
                    formProps.submitForm();
                  }}
                  renderRowAsError={(row) =>
                    props.locationType === 'TRANSMIT'
                      ? !transmitLocationDoneSchema.isValidSync(row.item)
                      : !receiveLocationDoneSchema.isValidSync(row.item)
                  }
                  locationType={props.locationType}
                  isSatellite={true}
                  licenceClassification={props.licence.classification}
                  onViewLocation={setViewLocationId}
                  lastSaved={lastSaved}
                />
              </Grid.Col>
            </Grid.Row>
          )}
          <SpectrumDetailsForm
            onFocus={() => submit.isDone || setSubmit(submitSpectrum)}
            spectrum={spectrum as any}
            onSaveSpectrum={() => {
              setSubmit(submitSpectrum);
              formProps.submitForm();
            }}
            onRemoveSpectrum={handleRemoveSpectrum}
            isDone={isDone}
            lastSaved={lastSaved}
            title={spectrumSectionTitle}
          />
        </LocationSectionAccordion>
      )}
    </Formik>
  );
};

export const transmitLocationSectionTitle = 'Satellite Earth station details - Transmit location';
export const receiveLocationSectionTitle = 'Site details - Receive location';

const LocationSectionAccordion: React.FC<{
  locationType: 'TRANSMIT' | 'RECEIVE';
  expanded?: boolean;
  done?: boolean;
  onDone: () => void;
  onValidateDone: () => void;
  onExpandCollapsed?: () => void;
  licence?: RadioLicenceDto;
  children?: React.ReactNode;
}> = (props) => {
  const { initialValues, errors } = useFormikContext<{ spectrum?: SpectrumDto; locations?: TransmitLocationDto[] | ReceiveLocationDto[] }>();
  const { validateSatelliteTransmitLocation, validateSatelliteReceiveLocation } = useDoneValidation();
  const [hasInitialError, setHasInitialError] = useState<boolean | undefined>();
  const hasError = !isEmpty(errors);
  const hasLocationDoneError = typeof errors?.locations === 'object';
  const hasSpectrumDetailsDoneError = typeof errors?.spectrum === 'object';
  const isInitialised = !!props.licence && !!initialValues.spectrum && !!initialValues.locations;
  const { craftingProgress } = props.licence ?? {};

  const isDone =
    props.locationType === 'TRANSMIT' ? !craftingProgress?.isTransmitLocationDetailsDone : !craftingProgress?.isReceiveLocationDetailsDone;
  const title = props.locationType === 'TRANSMIT' ? transmitLocationSectionTitle : receiveLocationSectionTitle;
  const errorMessage = props.locationType === 'TRANSMIT' ? 'Invalid Transmit location' : 'Invalid Receive location';

  // initialErrors holds errors on load, whereas "errors" holds errors when Done is pressed
  useEffect(() => {
    if (isInitialised && !isDone && props.locationType === 'TRANSMIT') {
      validateSatelliteTransmitLocation(props.licence!).then((valid) => setHasInitialError(!valid));
    } else if (isInitialised && !isDone && props.locationType === 'RECEIVE') {
      validateSatelliteReceiveLocation(props.licence!).then((valid) => setHasInitialError(!valid));
    }
    // eslint-disable-next-line
  }, [isInitialised]);

  return (
    <Accordion
      {...props}
      id={props.locationType + '-location-section'}
      title={title}
      error={!props.done && isInitialised && (hasInitialError || hasError)}
    >
      {hasLocationDoneError && <Error errorMessage={errorMessage} showIcon />}
      {hasSpectrumDetailsDoneError && <Error errorMessage="Invalid Spectrum details" showIcon />}
      {props.children}
    </Accordion>
  );
};

export const stationTypeOptions: IDropdownOption[] = [
  { key: 'Gateway/Feeder link', text: 'Gateway/Feeder link' },
  { key: 'Terminal/User terminal', text: 'Terminal/User terminal' },
  { key: 'Other', text: 'Other' },
];

export const antennaPatternOptions: IDropdownOption[] = [
  { key: 'REC ITU-R S.465', text: 'REC ITU-R S.465' },
  { key: 'REC ITU-R S.580', text: 'REC ITU-R S.580' },
  { key: 'REC ITU-R S.672', text: 'REC ITU-R S.672' },
  { key: 'REC ITU-R S.1855', text: 'REC ITU-R S.1855' },
  { key: 'REC ITU-R S.1528', text: 'REC ITU-R S.1528' },
  { key: 'Other', text: 'Other (please state) [e.g. Yagi, or omni]' },
];

export const emptyFrequencyObj = {
  referenceFrequency: undefined,
  referenceFrequencyType: undefined,
  power: undefined,
  powerType: undefined,
  frequencyEmissions: [],
  isoStartTime: undefined,
  isoStopTime: undefined,
  hourCode: undefined,
};

export const transformSpectrumDto = (
  dto: MaintainFrequencySpectrumDto & CreateFrequencySpectrumDto,
): MaintainFrequencySpectrumDto & CreateFrequencySpectrumDto => {
  if (dto.frequencies && isUndefinedObject(dto.frequencies[0])) {
    dto = {
      ...dto,
      frequencies: undefined,
    };
  }
  return dto;
};

const isUndefinedObject = (object?: any): boolean => {
  if (!object) return true;
  for (const key in object) {
    // eslint-disable-next-line
    if (object[key] != undefined && (!Array.isArray(object[key]) || object[key].length > 0)) {
      return false;
    }
  }
  return true;
};

export default LocationSection;
