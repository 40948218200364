import { Checkbox, IColumn } from '@fluentui/react';
import React from 'react';

import { GeoreferenceGroupDto, GeoreferenceTypeEnum } from 'api-client';

import DetailsList from '../../../../common/controls/lists/DetailsList';
import { useMedia } from '../../../../common/layout/MediaHook';
import { formatEastingNorthing, displayType } from '../../../../common/utils/locationUtils';
import { georeferenceTypes } from '../locationSupportData';

interface Props {
  georeferenceGroup: GeoreferenceGroupDto;

  hideOriginal: boolean;
  originalValue?: boolean;
}

const GeoreferenceGroupPanel = (props: Props) => {
  const georefs = [props.georeferenceGroup.origin, ...(props.georeferenceGroup.convertions ?? [])];
  const data = georeferenceTypes.map((g) => {
    const ref = georefs.find((ref) => ref?.type.toString() === g.key);
    return {
      original: props.originalValue ? ref?.original : !!ref && ref?.id === props.georeferenceGroup.origin?.id,
      type: g.text,
      typeKey: g.key,
      map: ref?.mapNumber,
      easting: ref?.easting,
      northing: ref?.northing,
    };
  });
  const { isMobile } = useMedia();

  const columns = (): IColumn[] => [
    {
      key: 'original',
      fieldName: 'original',
      name: 'Original',
      minWidth: 15,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => (item.original ? <Checkbox checked={item.original} disabled={item.original} /> : ''),
    },
    {
      key: 'type',
      fieldName: 'type',
      name: 'Georeference',
      minWidth: 15,
      maxWidth: 140,
      isMultiline: true,
      isResizable: true,
    },
    {
      key: 'map',
      fieldName: 'map',
      name: 'Map',
      minWidth: 15,
      maxWidth: 100,
      isMultiline: true,
      isResizable: true,
      onRender: (item: any) => (item.map ? item.map : '-'),
    },
    {
      key: 'easting',
      fieldName: 'easting',
      name: '',
      minWidth: 15,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) =>
        item.easting
          ? formatEastingNorthing(
              GeoreferenceTypeEnum[item.typeKey as keyof typeof GeoreferenceTypeEnum] === GeoreferenceTypeEnum.D2000 ? item.northing : item.easting,
              GeoreferenceTypeEnum[item.typeKey as keyof typeof GeoreferenceTypeEnum],
            )
          : '-',
    },
    {
      key: 'northing',
      fieldName: 'northing',
      name: '',
      minWidth: 15,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) =>
        item.northing
          ? formatEastingNorthing(
              GeoreferenceTypeEnum[item.typeKey as keyof typeof GeoreferenceTypeEnum] === GeoreferenceTypeEnum.D2000 ? item.easting : item.northing,
              GeoreferenceTypeEnum[item.typeKey as keyof typeof GeoreferenceTypeEnum],
            )
          : '-',
    },
    {
      key: 'type',
      fieldName: 'type',
      name: '',
      minWidth: 15,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => displayType(GeoreferenceTypeEnum[item.typeKey as keyof typeof GeoreferenceTypeEnum]),
    },
  ];

  return (
    <div
      style={{
        width: isMobile ? '600px' : '100%',
      }}
    >
      <DetailsList columns={props.hideOriginal ? columns().filter((ref) => ref.key !== 'original') : columns()} items={data} />
    </div>
  );
};

export default GeoreferenceGroupPanel;
