import * as React from 'react';

import { AppProtectedRoute, AppRouteSwitch, usePageNavigator } from 'common/auth/AppRoute';
import { AuditRecommendationPage } from 'investigation/audit/AuditRecommendationPage';
import { AuditReviewPage } from 'investigation/audit/AuditReviewPage';
import { CreateAuditPage } from 'investigation/audit/CreateAuditPage';
import { MaintainAuditPage } from 'investigation/audit/MaintainAuditPage';
import { ViewAuditPage } from 'investigation/audit/ViewAuditPage';
import { CreateComplaintPage } from 'investigation/complaint/CreateComplaintPage';
import { MaintainComplaintPage } from 'investigation/complaint/MaintainComplaintPage';
import { ViewComplaintPage } from 'investigation/complaint/ViewComplaintPage';
import { SearchCasePage } from 'investigation/search/SearchCasePage';

const InvestigationRouter = () => {
  return (
    <AppRouteSwitch>
      <AppProtectedRoute path="/audit/create" component={CreateAuditPage} permission={['CREATE_AUDIT']} featureFlag={'ENABLE_INVESTIGATION'} />
      <AppProtectedRoute
        path="/audit/maintain/:caseId"
        component={MaintainAuditPage}
        permission={['UPDATE_AUDIT', 'VIEW_AUDIT']}
        featureFlag={'ENABLE_INVESTIGATION'}
      />
      <AppProtectedRoute
        path="/audit/recommendation/:caseId"
        component={AuditRecommendationPage}
        permission={['UPDATE_AUDIT']}
        featureFlag={'ENABLE_INVESTIGATION'}
      />
      <AppProtectedRoute
        path="/audit/review/:caseId"
        component={AuditReviewPage}
        permission={['UPDATE_AUDIT']}
        featureFlag={'ENABLE_INVESTIGATION'}
      />
      <AppProtectedRoute path="/audit/view/:caseId" component={ViewAuditPage} permission={['VIEW_AUDIT']} featureFlag={'ENABLE_INVESTIGATION'} />

      <AppProtectedRoute
        path="/complaint/create"
        component={CreateComplaintPage}
        permission={['BASIC_CREATE_COMPLAINT', 'CREATE_COMPLAINT']}
        featureFlag={'ENABLE_INVESTIGATION'}
      />
      <AppProtectedRoute
        path="/complaint/maintain/:caseId"
        component={MaintainComplaintPage}
        permission={['BASIC_UPDATE_COMPLAINT', 'UPDATE_COMPLAINT']}
        featureFlag={'ENABLE_INVESTIGATION'}
      />
      <AppProtectedRoute
        path="/complaint/view/:caseId"
        component={ViewComplaintPage}
        permission={['BASIC_VIEW_COMPLAINT', 'VIEW_COMPLAINT']}
        featureFlag={'ENABLE_INVESTIGATION'}
      />

      <AppProtectedRoute path="/search" component={SearchCasePage} permission={['SEARCH_COMPLAINT']} featureFlag={'ENABLE_INVESTIGATION'} />
    </AppRouteSwitch>
  );
};

export const useInvestigationRouter = () => {
  const { navigateTo } = usePageNavigator('investigation');

  return {
    navigateToAuditRecommendation: (id: number) => navigateTo(`/audit/recommendation/${id}`),
    navigateToAuditReview: (id: number) => navigateTo(`/audit/review/${id}`),
    navigateToAuditView: (id: number) => navigateTo(`/audit/view/${id}`),
    navigateToCreateAudit: (options?: { clientId?: number; licenceId?: number; openInNewTab?: boolean }) =>
      navigateTo('/audit/create', {
        queryParams: {
          clientId: options?.clientId,
          licenceId: options?.licenceId,
        },
        openInNewTab: options?.openInNewTab,
      }),
    navigateToMaintainAudit: (id: number) => navigateTo(`/audit/maintain/${id}`),

    navigateToCreateComplaint: () => navigateTo('/complaint/create'),
    navigateToMaintainComplaint: (id: number) => navigateTo(`/complaint/maintain/${id}`),
    navigateToViewComplaint: (id: number) => navigateTo(`/complaint/view/${id}`),

    navigateToSeachCases: () => navigateTo('/search'),
  };
};

export default InvestigationRouter;
