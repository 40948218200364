import jwt_decode from 'jwt-decode';
import log from 'loglevel';

import { Permission } from './Permissions';

const RAW_JWT_STORAGE_KEY = 'rrf-jwt';

export type Jwt = {
  /** Contact */
  ctt: string;
  iat: number;
  exp: number;
  fn: string;
  ln: string;
  ul: boolean;

  /** Roles */
  rnp: string;
  cpn: string;
  at: string;
  aa: boolean;
  cik: boolean;

  /** Permissions */
  perm: string;
  utp: UserType;
  uid: number;
  cid: number;
};

export type UserType = 'INT' | 'EXT';

// In-memory JWT in case local storage doesn't work
var inMemoryJWT: string | null = null;

export function getJWTFromLocalStorage(): string {
  const rawJwt = getStoredJwt();
  if (rawJwt === null) {
    return ''; // or maybe error
  }
  const oasisJwt = decodeJWT(rawJwt);
  if (hasJWTExpired(oasisJwt)) {
    clearJWTFromLocalStorage();
    return '';
  }
  return rawJwt;
}

export function decodeJWT(jwt: string) {
  return jwt_decode(jwt) as Jwt;
}

export function getDecodedJWTFromLocalStorage(): Jwt | null {
  const rawJWT = getJWTFromLocalStorage();
  if (rawJWT === '') {
    return null;
  }
  return decodeJWT(rawJWT);
}

function safeGetJWTFromLocalStorage(): string | null {
  try {
    return localStorage.getItem(RAW_JWT_STORAGE_KEY);
  } catch (error) {
    log.error('Caught exception trying to get JWT from browser storage - falling back to in-memory', error);
    return null;
  }
}

function getStoredJwt() {
  const jwt = safeGetJWTFromLocalStorage();
  if (jwt == null || jwt.length === 0) {
    return inMemoryJWT;
  }
  return jwt;
}

// JWT is due for refresh if at least ten minutes old
const SECONDS_UNTIL_JWT_DUE_FOR_REFRESH: number = 10 * 60;

export function isJWTDueForRefresh(): boolean {
  const jwt = getStoredJwt();
  if (jwt == null || jwt.length === 0) {
    // No JWT, so it can't expire
    return false;
  }
  const decodedJwt = decodeJWT(jwt);
  const expiryInSecondsSinceEpoch = decodedJwt.exp;
  const currentTimeInSecondsSinceEpoch = Math.floor(Date.now() / 1000);
  return currentTimeInSecondsSinceEpoch + SECONDS_UNTIL_JWT_DUE_FOR_REFRESH >= expiryInSecondsSinceEpoch;
}

function hasJWTExpired(jwt: Jwt): boolean {
  const expiryInSecondsSinceEpoch = jwt.exp;
  const currentTimeInSecondsSinceEpoch = Math.floor(Date.now() / 1000);
  return currentTimeInSecondsSinceEpoch >= expiryInSecondsSinceEpoch;
}

export function clearJWTFromLocalStorage() {
  try {
    localStorage.removeItem(RAW_JWT_STORAGE_KEY);
  } catch (error) {
    log.error(`Couldn't remove JWT from local storage`, error);
  }
  inMemoryJWT = null;
}

export function saveJWT(newJwt: string) {
  try {
    localStorage.setItem(RAW_JWT_STORAGE_KEY, newJwt);
  } catch (error) {
    log.error('Caught exception trying to store JWT in browser storage', error);
  }
  const jwt = safeGetJWTFromLocalStorage();

  if (jwt === undefined || jwt === null) {
    log.info(`JWT didn't get stored in local storage, falling back to in-memory storage`);
    inMemoryJWT = newJwt;
  } else {
    inMemoryJWT = null;
  }
}

export function isJWT(maybeJwt?: string | null): boolean {
  if (!(maybeJwt === undefined || maybeJwt === null) && maybeJwt.length > 0) {
    try {
      decodeJWT(maybeJwt);
      return true;
    } catch {
      // Not a JWT
    }
  }
  return false;
}

export const permissionsFromJwt = (jwt?: Jwt | null): Permission[] => {
  if (jwt === undefined || jwt === null) {
    return [];
  }
  return JSON.parse(jwt.perm) as Permission[];
};
