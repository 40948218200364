import { MessageBarType } from '@fluentui/react';
import { useAppRouter } from 'Routes';
import React from 'react';

import { AuditDto } from 'api-client';

import usePage from 'common/layout/PageHook';

import { TextField } from '../../../common/controls/inputs/TextField';
import { DialogForm, Grid2 } from '../../../ui-library';
import { AuditFormProps } from '../../interfaces';
import { useAuditApi } from '../hooks';

interface DialogProps {
  audit: Partial<AuditFormProps>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSaveCallback: (auditDto: AuditDto) => void;
}

interface FormProps {
  audit: Partial<AuditFormProps>;
  withdrawalReason: string;
}

const InfringementWithdrawalDialog = (props: DialogProps) => {
  const { manualWithdrawal } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  return (
    <DialogForm<FormProps>
      title="Withdraw infringement"
      isOpen={props.isOpen}
      id="InfringementWithdrawalDialog"
      buttonProps={{
        cancelClicked: () => props.setIsOpen(false),
        okClicked: (values, formikHelpers) => {
          manualWithdrawal(
            {
              id: values.audit.caseNumber || 0,
              infringement: {
                ...values.audit.infringement,
                withdrawalReason: values.withdrawalReason,
              },
            },
            {
              successMessage: 'The infringement has been withdrawn. Please check your email for the generated credit note.',
            },
          ).then((auditDto) => {
            props.onSaveCallback(auditDto);
            navigateToDashboard();
            setPageInstruction('Case successfully closed.', MessageBarType.success);
          });
        },
      }}
      validationSchema={[{ name: 'withdrawalReason' }]}
      initialValues={{ audit: props.audit }}
    >
      <FormContent />
    </DialogForm>
  );
};

const FormContent = () => {
  return (
    <Grid2>
      <Grid2.Col>
        <TextField label="Reason for withdrawal" name="withdrawalReason" multiline maxLength={400} showMaxHint required />
      </Grid2.Col>
    </Grid2>
  );
};

export default InfringementWithdrawalDialog;
