import Axios from 'axios';

import {
  AtonManagementApi,
  BpmTasksApi,
  BpmIncidentApi,
  CamundaGroupApi,
  CaseManagementApi,
  CertificateManagementApi,
  ClientManagementApi,
  DrmApi,
  EmailApi,
  EventApi,
  FeatureFlagApi,
  InvestigationApi,
  LicenceManagementApi,
  LicenceCraftingApi,
  LicenceToSupplyManagementApi,
  PublicMapApi,
  MapApi,
  MbiePaymentsApi,
  PublicClientApi,
  PublicLicenceApi,
  PublicSearchApi,
  SearchApi,
  ExportFileApi,
  SecurityApi,
  ScheduledJobManagementApi,
  OutageNotificationApi,
  CamundaUserApi,
  PowerbiApi,
  TemplateApi,
  AuditManagementApi,
  RegistrarQueueApi,
  FormSevenApi,
  FormTenApi,
  FormEightApi,
  FormNineApi,
  Form11Api,
  Form18Api,
  PublicAtonApi,
  Form20Api,
  ApplyForLicenceApi,
  ModifyLicenceApi,
  Form21Api,
  PublicFormApi,
  Form22SpectrumLicenceApi,
  CamundaMaintainApi,
  DashboardApi,
  Form22ManagementRightApi,
  AdminManagementApi,
} from 'api-client';

import { refreshJWTIfNeeded } from '../auth/jwtRefresh';
import { getJWTFromLocalStorage } from '../auth/jwtUtils';
import { showNotLoggedInPage } from '../auth/login';

// Create axios instance
export const axiosInstance = Axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + getJWTFromLocalStorage(),
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    refreshJWTIfNeeded();
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      showNotLoggedInPage();
    } else {
      return Promise.reject(error);
    }
  },
);

export const applicationsApi = new LicenceManagementApi(undefined, '/api', axiosInstance as any);
export const approvalApi = new ClientManagementApi(undefined, '/api', axiosInstance as any);
export const atonApi = new AtonManagementApi(undefined, '/api', axiosInstance as any);
export const auditApi = new AuditManagementApi(undefined, '/api', axiosInstance as any);
export const bpmTasksApi = new BpmTasksApi(undefined, '/api', axiosInstance as any);
export const bpmIncidentApi = new BpmIncidentApi(undefined, '/api', axiosInstance as any);
export const registrarQueueApi = new RegistrarQueueApi(undefined, '/api', axiosInstance as any);
export const camundaGroupApi = new CamundaGroupApi(undefined, '/api', axiosInstance as any);
export const camundaUserApi = new CamundaUserApi(undefined, '/api', axiosInstance as any);
export const caseManagementApi = new CaseManagementApi(undefined, '/api', axiosInstance as any);
export const certificateApi = new CertificateManagementApi(undefined, '/api', axiosInstance as any);
export const clientApi = new ClientManagementApi(undefined, '/api', axiosInstance as any);
export const dashboardApi = new DashboardApi(undefined, '/api', axiosInstance as any);
export const delegationApi = new ClientManagementApi(undefined, '/api', axiosInstance as any);
export const documentsApi = new DrmApi(undefined, '/api', axiosInstance as any);
export const emailApi = new EmailApi(undefined, '/api', axiosInstance as any);
export const templateApi = new TemplateApi(undefined, '/api', axiosInstance as any);
export const eventApi = new EventApi(undefined, '/api', axiosInstance as any);
export const featureFlagApi = new FeatureFlagApi(undefined, '/api', axiosInstance as any);
export const investigationApi = new InvestigationApi(undefined, '/api', axiosInstance as any);
export const licenceApi = new LicenceManagementApi(undefined, '/api', axiosInstance as any);
export const licenceCraftingApi = new LicenceCraftingApi(undefined, '/api', axiosInstance as any);
export const adminApi = new AdminManagementApi(undefined, '/api', axiosInstance as any);
export const licenceToSupplyApi = new LicenceToSupplyManagementApi(undefined, '/api', axiosInstance as any);
export const publicMapApi = new PublicMapApi(undefined, '/api', axiosInstance as any);
export const mapApi = new MapApi(undefined, '/api', axiosInstance as any);
export const nzbnApi = new ClientManagementApi(undefined, '/api', axiosInstance as any);
export const orgUsersApi = new ClientManagementApi(undefined, '/api', axiosInstance as any);
export const paymentApi = new MbiePaymentsApi(undefined, '/api', axiosInstance as any);
export const publicApi = new PublicClientApi(undefined, '/api', axiosInstance as any);
export const publicLicenceApi = new PublicLicenceApi(undefined, '/api', axiosInstance as any);
export const publicSearchApi = new PublicSearchApi(undefined, '/api', axiosInstance as any);
export const referenceDataApi = new LicenceManagementApi(undefined, '/api', axiosInstance as any);
export const scheduledJobApi = new ScheduledJobManagementApi(undefined, '/api', axiosInstance as any);
export const outageNotificationApi = new OutageNotificationApi(undefined, '/api', axiosInstance as any);
export const searchApi = new SearchApi(undefined, '/api', axiosInstance as any);
export const exportFileApi = new ExportFileApi(undefined, '/api', axiosInstance as any);
export const securityApi = new SecurityApi(undefined, '/api', axiosInstance as any);
export const usersApi = new ClientManagementApi(undefined, '/api', axiosInstance as any);
export const powerbiApi = new PowerbiApi(undefined, '/api', axiosInstance as any);
export const formSevenApi = new FormSevenApi(undefined, '/api', axiosInstance as any);
export const formEightApi = new FormEightApi(undefined, '/api', axiosInstance as any);
export const formTenApi = new FormTenApi(undefined, '/api', axiosInstance as any);
export const formNineApi = new FormNineApi(undefined, '/api', axiosInstance as any);
export const publicAtonApi = new PublicAtonApi(undefined, '/api', axiosInstance as any);
export const form11Api = new Form11Api(undefined, '/api', axiosInstance as any);
export const form18Api = new Form18Api(undefined, '/api', axiosInstance as any);
export const form20Api = new Form20Api(undefined, '/api', axiosInstance as any);
export const form21Api = new Form21Api(undefined, '/api', axiosInstance as any);
export const form22ManagementRightApi = new Form22ManagementRightApi(undefined, '/api', axiosInstance as any);
export const form22SpectrumLicenceApi = new Form22SpectrumLicenceApi(undefined, '/api', axiosInstance as any);
export const applyForLicenceApi = new ApplyForLicenceApi(undefined, '/api', axiosInstance as any);
export const modifyLicenceApi = new ModifyLicenceApi(undefined, '/api', axiosInstance as any);
export const publicFormApi = new PublicFormApi(undefined, '/api', axiosInstance as any);
export const camundaMaintainApi = new CamundaMaintainApi(undefined, '/api', axiosInstance as any);
