import { Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { AntennaDto, FileNoteDtoEntityTypeEnum } from 'api-client';

import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { useAuth } from 'common/auth/AuthHook';
import Form from 'common/form/Form';
import Page from 'common/layout/Page';
import { COMMON_FILE_NOTE_TYPE } from 'common/reference/referenceData';

import { useReferenceDataHookApi } from '../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../ReferenceDataRouter';
import AntennaFields from './components/AntennaFields';
import { antennaSchema } from './validationSchema';

const UpdateAntennaPage = () => {
  const { updateAntenna, getAntenna } = useReferenceDataHookApi();
  const { navigateToMaintainAntenna } = useLicenceReferenceDataNavigator();
  const { antennaId } = useParams<{ antennaId: string }>();
  const [antenna, setAntenna] = useState<AntennaDto>();
  const { isInternalUser, hasPermission } = useAuth();

  const submitForm = (values: AntennaDto, formikHelper: FormikHelpers<AntennaDto>) =>
    updateAntenna(
      { ...values, type: values.type?.toUpperCase(), make: values.make?.toUpperCase(), model: values.model?.toUpperCase() },
      {
        successMessage: 'A new version of this Antenna has been created.',
      },
    ).then((response) => {
      navigateToMaintainAntenna(response.id!, true);
      setAntenna(response);
    });

  useEffect(() => {
    getAntenna(+antennaId).then(setAntenna);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [antennaId]);

  const canEdit = antenna?.current && hasPermission('UPDATE_ANTENNA') && (isInternalUser || !antenna?.hidden);
  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  return (
    <Page title="Update Antenna">
      {antenna ? (
        <Formik initialValues={antenna} onSubmit={submitForm} enableReinitialize validationSchema={antennaSchema}>
          <Form id={'update-antenna-form'} showFormButtonsBottom mode="VIEW" canEdit={canEdit} disabledSubmitWhenNoDirty>
            <AntennaFields />
            {canViewFileNote && (
              <FileNoteSection entityId={+antennaId} entityType={FileNoteDtoEntityTypeEnum.ANTENNA} noteTypes={COMMON_FILE_NOTE_TYPE} />
            )}
          </Form>
        </Formik>
      ) : (
        <> </>
      )}
    </Page>
  );
};

export default UpdateAntennaPage;
