import { useFormikContext } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';

import { RadioApplicationDto } from 'api-client';

import ShipCallsign from '../../../certificate_management/callsign/components/ShipCallsign';

const ShipDetailsSection = () => {
  const { values, setFieldValue } = useFormikContext<RadioApplicationDto>();

  useEffect(() => {
    setFieldValue('clientId', values.applicant);
  }, [values.applicant]);

  return <ShipCallsign serviceType="LICENCE_SHIP" />;
};

export default ShipDetailsSection;
