import { DefaultButton, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { DialogButtonsContainer } from '../surfaces/Dialog';
import { useEffect, useRef } from 'react';

export type YesNoButtonsProps = {
  onYes: () => void;
  onNo: () => void;
  yesText?: string;
  noText?: string;
  disableYes?: boolean;
  notDangerous?: boolean;
};

const YesNoButtons = (props: YesNoButtonsProps) => {
  const notDangerousYesRef = useRef<HTMLDivElement | null>(null);

  // Set initial focus on the not dangerous Yes button, so that the user can easily Enter to confirm.
  useEffect(() => {
    const yesButton = notDangerousYesRef?.current?.getElementsByTagName('button')?.item(0);
    yesButton?.focus();
  }, [notDangerousYesRef]);

  return (
    <DialogButtonsContainer>
      {!props.notDangerous && (
        <>
          <PrimaryButton onClick={props.onNo}>{props.noText ? props.noText : 'No'}</PrimaryButton>
          <DefaultButton onClick={props.onYes} className="danger-button" disabled={props.disableYes}>
            {props.yesText ? props.yesText : 'Yes'}
          </DefaultButton>
        </>
      )}
      {props.notDangerous && (
        <>
          <DefaultButton onClick={props.onNo}>{props.noText ? props.noText : 'No'}</DefaultButton>
          <div ref={notDangerousYesRef}>
            <PrimaryButton onClick={props.onYes} disabled={props.disableYes}>
              {props.yesText ? props.yesText : 'Yes'}
            </PrimaryButton>
          </div>
        </>
      )}
    </DialogButtonsContainer>
  );
};
export default YesNoButtons;
