export const isEmpty = (object: any): boolean => {
  if (typeof object === 'string') {
    return object === undefined || object === null || object.length === 0;
  }
  return object === undefined || object === null || Object.keys(object).length === 0;
};

/**
 * If the path is a string, convert it to an array
 * @param  {String|Array} path The path
 * @return {Array}             The path array
 */
const stringToPath = (path: string) => {
  // Create new array
  const output: string[] = [];

  // Split to an array with dot notation
  path.split('.').forEach((item: string, index: number) => {
    // Split to an array with bracket notation
    item.split(/\[([^}]+)\]/g).forEach(function (key) {
      // Push to the new array
      if (key.length > 0) {
        output.push(key);
      }
    });
  });
  return output;
};

export const getValueByPath = function (obj: any, path: string) {
  // Get the path as an array
  const paths: string[] = stringToPath(path);
  // Cache the current object
  let current = obj;
  // For each item in the path, dig into the object
  for (var i = 0; i < paths.length; i++) {
    // If the item isn't found, return the default (or null)
    if (!current[paths[i]]) return undefined;
    // Otherwise, update the current  value
    current = current[paths[i]];
  }
  return current;
};

export const parseBoolean = (target?: any): boolean | undefined => (isEmpty(target) ? undefined : '' + target === 'true');
