import { isEmpty } from '../validation/yupUtils';

export const validFormatSpectrumMaskPoint = (isSetOrCalcLevel?: boolean) => (val: string) => {
  if (/^(-?\d*)(\.\d*)?$/.test(val) && val !== '.') {
    // it is a number
    const numberVal = Number.parseFloat(val);
    // check range.
    const isInRange = isSetOrCalcLevel ? numberVal >= -1000 && numberVal <= 1000 : numberVal >= -100000 && numberVal <= 100000;
    if (!isSetOrCalcLevel && isInRange && /^(-?\d*)(\.\d{0,6})?$/.test(val)) {
      // no more than 6 decimal places.
      return formatSpectrumMaskPoint(val, isSetOrCalcLevel);
    }
    if (isSetOrCalcLevel && isInRange && /^(-?\d*)(\.\d{0,1})?$/.test(val)) {
      // no more than 1 decimal places.
      return formatSpectrumMaskPoint(val, isSetOrCalcLevel);
    }
  }
  return val;
};

export const formatSpectrumMaskPoint = (val: string, isSetOrCalcLevel?: boolean) => {
  if (isEmpty(val)) return '';
  if (isSetOrCalcLevel) {
    return Number.parseFloat(val).toFixed(1);
  } else {
    return Number.parseFloat(val).toFixed(6);
  }
};
