import { DefaultButton } from '@fluentui/react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { GeoreferenceGroupDto } from 'api-client';

import { DnDCard } from '../../../../common/controls/surfaces/DnDCard';
import { FormContext } from '../../../../common/form/Form';
import { useMedia } from '../../../../common/layout/MediaHook';
import GeoreferenceDetail from './GeoreferenceDetail';
import MultipointGeoferenceHeader from './MulpointGeoreferenceHeader';

const GeoreferencesComponent = (props: FieldArrayRenderProps) => {
  const { values } = useFormikContext<any>();
  const { mode } = useContext(FormContext);

  return (
    <>
      {mode === 'VIEW' ? (
        values.georeferences.map((geoRefGroup: GeoreferenceGroupDto, idx: number) => (
          <div key={`georeference-${idx}`}>
            <GeoreferenceRow {...props} idx={idx} />{' '}
          </div>
        ))
      ) : (
        <DndProvider backend={HTML5Backend}>
          {values.georeferences.map((geoRefGroup: GeoreferenceGroupDto, idx: number) => {
            return (
              <div key={`georeference-${idx}`}>
                <Container {...props} idx={idx} />
              </div>
            );
          })}
        </DndProvider>
      )}
      <>
        {(mode === 'EDIT' || mode === 'CREATE') && (
          <DefaultButton
            id="add-georeference-button"
            onClick={() => {
              props.push({
                origin: {
                  original: true,
                  northing: '',
                  easting: '',
                  mapNumber: '',
                  type: values.georeferenceType,
                  order: values.georeferences!.length! + 1,
                },
              });
            }}
          >
            Add a point
          </DefaultButton>
        )}
      </>
    </>
  );
};

const GeoreferenceRow = (props: FieldArrayRenderProps & { idx: number }) => {
  const { mode } = useContext(FormContext);
  const { isMobile } = useMedia();
  const { values, setValues } = useFormikContext<any>();

  const removeHandler = (arrayProps: FieldArrayRenderProps, idx: number) => () => {
    props.remove(idx);
    setValues({
      ...values,
      georeferences: values.georeferences
        .filter((g: any, orderIdx: number) => orderIdx !== idx)
        .map((g: any, orderIdx: number) => ({
          origin: {
            ...g.origin,
            order: orderIdx + 1,
          },
        })),
    });
  };

  return (
    <GeoreferenceDetail
      key={props.idx}
      geoReferenceIndex={props.idx + 1}
      namePrefix={`georeferences.${props.idx}.origin.`}
      hideLabel={!isMobile}
      showOrder
      showDetails={true}
      onRemove={values.georeferences.length > 3 && (mode === 'EDIT' || mode === 'CREATE') ? removeHandler(props, props.idx) : undefined}
    />
  );
};

export const Container: React.FC<any> = (props: FieldArrayRenderProps & { idx: number }) => {
  const moveCard = (arrayHelper: FieldArrayRenderProps) => (dragIndex: number, hoverIndex: number) => {
    arrayHelper.move(dragIndex, hoverIndex);
  };

  const renderCard = (index: number, props: FieldArrayRenderProps) => {
    return <DnDCard key={index} index={index} id={index} item={<GeoreferenceRow {...props} idx={index} />} moveCard={moveCard(props)} />;
  };

  return <>{renderCard(props.idx, props)}</>;
};

const Georeferences = () => {
  const { isMobile } = useMedia();

  return (
    <>
      {!isMobile && <MultipointGeoferenceHeader />}
      <FieldArray name={'georeferences'} render={(arrayHelpers) => <GeoreferencesComponent {...arrayHelpers} />} />
    </>
  );
};

export default Georeferences;
