import { IBasePickerStyles, ITag, Label, TagPicker } from '@fluentui/react';
import { useField } from 'formik';
import * as React from 'react';

import { AntennaSearchResult } from 'api-client';

import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { useTheme } from 'common/theme/RRFTheme';
import { useSearchApi } from 'search/SearchApiHooks';

interface Props {
  onAntennaSelected: (id: number) => void;
  required?: boolean;
  name: string;
}

const AntennaSearch: React.FC<Props> = (props) => {
  const { searchAntenna } = useSearchApi();
  const { semanticColors } = useTheme();
  // eslint-disable-next-line
  const [_, meta] = useField(props as any);
  const asAntennaTag = ({ id, identifier, make, model, type, remarks }: AntennaSearchResult) => ({
    key: `${id}`,
    name: `${identifier}, ${make}, ${model}, ${type}, ${remarks}`,
  });
  const inputProps = {
    id: 'search-antenna-field',
    'aria-required': props.required,
    'aria-labelledby': 'search-antenna-label',
    placeholder: 'Antenna identifier, make, model, or type',
  };
  const error = meta.touched && typeof meta.error === 'string';
  const styles: Partial<IBasePickerStyles> = error
    ? {
        text: { borderColor: semanticColors.errorText, backgroundColor: semanticColors.inputBackground },
      }
    : { text: { backgroundColor: semanticColors.inputBackground } };

  return (
    <div data-input-wrapper>
      <Label id="search-antenna-label" required={props.required}>
        Search antenna
      </Label>
      <TagPicker
        onResolveSuggestions={async (searchText: string, _selectedItems?: ITag[]) => {
          return searchText !== ''
            ? searchAntenna({ searchText, pageSize: 20, showOldVersions: false, hidden: false }, { callerHandleErrors: true }).then((searchResults) =>
                searchResults.results ? searchResults.results.map(asAntennaTag) : [],
              )
            : [];
        }}
        inputProps={props.required ? { required: true, ...inputProps } : inputProps}
        onItemSelected={(item?: ITag) => {
          props.onAntennaSelected(item ? +item.key : -1);
          return null;
        }}
        styles={styles}
        pickerSuggestionsProps={{
          loadingText: 'Searching for antenna',
          suggestionsHeaderText: 'Suggested antenna',
          noResultsFoundText: 'No antenna found',
        }}
      />
      <ErrorLabel {...props} />
    </div>
  );
};

export default AntennaSearch;
