import * as React from 'react';
import { Redirect, Route, RouteProps, useParams } from 'react-router-dom';

import useBpmTasksApi from 'dashboard/tasklist/BpmApiHook';

interface TaskRouteProps extends RouteProps {
  to: (taskVars: any) => string;
}
const TaskRoute: React.FC<TaskRouteProps> = ({ to, ...rest }) => {
  return (
    <Route {...rest}>
      <TaskRouteRedirect to={to} />
    </Route>
  );
};

const TaskRouteRedirect: React.FC<{ to: (taskVars: any) => string }> = (props) => {
  const { taskId } = useParams<{ taskId: string }>();
  const [taskVars, setTaskVars] = React.useState<any>();
  const { getTaskVariables } = useBpmTasksApi();

  React.useEffect(() => {
    if (taskId) {
      getTaskVariables(taskId).then((resp) => setTaskVars(resp));
    }
    // eslint-disable-next-line
  }, [taskId]);
  return taskVars ? <Redirect to={props.to({ ...taskVars, taskId })} /> : null;
};

export default TaskRoute;
