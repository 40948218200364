import { IDropdownOption, ITag } from '@fluentui/react';

export const ITUServiceTypesITags = [
  { key: 1, name: '(deep space) (Earth-to-space)' },
  { key: 2, name: '(Earth-to-space)' },
  { key: 3, name: '(Not allocated)' },
  { key: 4, name: '(space-to-Earth)' },
  { key: 5, name: 'AERONAUTICAL RADIONAVIGATION' },
  { key: 6, name: 'Aeronautical mobile' },
  { key: 7, name: 'AERONAUTICAL MOBILE' },
  { key: 8, name: 'AERONAUTICAL MOBILE (OR)' },
  { key: 9, name: 'Aeronautical mobile (OR)' },
  { key: 10, name: 'AERONAUTICAL MOBILE (R)' },
  { key: 11, name: 'AERONAUTICAL MOBILE-SATELLITE (R)' },
  { key: 12, name: 'Aeronautical radionavigation' },
  { key: 13, name: 'AMATEUR' },
  { key: 14, name: 'Amateur' },
  { key: 15, name: 'AMATEUR-SATELLITE' },
  { key: 16, name: 'Amateur-satellite (space-to-Earth)' },
  { key: 17, name: 'BROADCASTING' },
  { key: 18, name: 'BROADCASTING-SATELLITE' },
  { key: 19, name: 'Earth exploration-satellite' },
  { key: 20, name: 'EARTH EXPLORATION-SATELLITE (active)' },
  { key: 21, name: 'Earth exploration-satellite (active)' },
  { key: 22, name: 'EARTH EXPLORATION-SATELLITE (Earth-to-space)' },
  { key: 23, name: 'EARTH EXPLORATION-SATELLITE (Earth-to-space) (space-to-space)' },
  { key: 24, name: 'EARTH EXPLORATION-SATELLITE (passive)' },
  { key: 25, name: 'Earth exploration-satellite (passive)' },
  { key: 26, name: 'Earth exploration-satellite (space-to-Earth)' },
  { key: 27, name: 'EARTH EXPLORATION-SATELLITE (space-to-Earth)' },
  { key: 28, name: 'EARTH EXPLORATION-SATELLITE (space-to-Earth) (space-to-space)' },
  { key: 29, name: 'FIXED' },
  { key: 30, name: 'FIXED-SATELLITE' },
  { key: 31, name: 'FIXED-SATELLITE (Earth-to-space)' },
  { key: 32, name: 'FIXED-SATELLITE (space-to-Earth)' },
  { key: 33, name: 'FIXED-SATELLITE (Earth-to-space) (space-to-Earth)' },
  { key: 34, name: 'FIXED-SATELLITE (space-to-Earth) (Earth-to-space)' },
  { key: 35, name: 'INTER-SATELLITE' },
  { key: 36, name: 'Land mobile' },
  { key: 37, name: 'MARITIME MOBILE' },
  { key: 38, name: 'MARITIME MOBILE (distress and calling via DSC)' },
  { key: 39, name: 'MARITIME MOBILE (distress and calling)' },
  { key: 40, name: 'Maritime mobile-satellite (Earth-to-space)' },
  { key: 41, name: 'MARITIME MOBILE-SATELLITE (space-to-Earth)' },
  { key: 42, name: 'MARITIME RADIONAVIGATION' },
  { key: 43, name: 'MARITIME RADIONAVIGATION (radiobeacons)' },
  { key: 44, name: 'METEOROLOGICAL AIDS' },
  { key: 45, name: 'Meteorological-Satellite' },
  { key: 46, name: 'METEOROLOGICAL-SATELLITE (Earth-to-space)' },
  { key: 47, name: 'METEOROLOGICAL-SATELLITE (space-to-Earth)' },
  { key: 48, name: 'Maritime mobile-satellite' },
  { key: 49, name: 'MOBILE' },
  { key: 50, name: 'MOBILE (distress and calling)' },
  { key: 51, name: 'MOBILE except aeronautical' },
  { key: 52, name: 'MOBILE except aeronautical mobile' },
  { key: 53, name: 'MOBILE except aeronautical mobile (R)' },
  { key: 54, name: 'MOBILE-SATELLITE' },
  { key: 55, name: 'Mobile-satellite (Earth-to-space)' },
  { key: 56, name: 'MOBILE-SATELLITE (Earth-to-space)' },
  { key: 57, name: 'Mobile-satellite (space-to-Earth)' },
  { key: 58, name: 'MOBILE-SATELLITE (space–to-Earth)' },
  { key: 59, name: 'Not allocated' },
  { key: 60, name: 'RADIO ASTRONOMY' },
  { key: 61, name: 'Radio astronomy' },
  { key: 62, name: 'Radiodetermination-satellite (Earth-to-Space)' },
  { key: 63, name: 'RADIODETERMINATION-SATELLITE (space-to-Earth)' },
  { key: 64, name: 'RADIOLOCATION' },
  { key: 65, name: 'Radiolocation' },
  { key: 66, name: 'RADIONAVIGATION' },
  { key: 67, name: 'Radionavigation-satellite' },
  { key: 68, name: 'RADIONAVIGATION-SATELLITE (Earth-to-space)' },
  { key: 69, name: 'RADIONAVIGATION-SATELLITE (space-to-Earth) (space-to-space)' },
  { key: 70, name: 'SPACE OPERATION' },
  { key: 71, name: 'SPACE OPERATION (Earth-to-space)' },
  { key: 72, name: 'SPACE OPERATION (Earth-to-space) (space-to-space)' },
  { key: 73, name: 'SPACE OPERATION (satellite identification)' },
  { key: 74, name: 'SPACE OPERATION (space-to-Earth)' },
  { key: 75, name: 'SPACE OPERATION (space-to-Earth) (space-to-space)' },
  { key: 76, name: 'Space Research' },
  { key: 77, name: 'SPACE RESEARCH' },
  { key: 78, name: 'SPACE RESEARCH (deep space) (space-to-Earth)' },
  { key: 79, name: 'SPACE RESEARCH (space-to-space)' },
  { key: 80, name: 'SPACE RESEARCH (active)' },
  { key: 81, name: 'SPACE RESEARCH (active) Amateur' },
  { key: 82, name: 'SPACE RESEARCH (active) RADIOLOCATION' },
  { key: 83, name: 'Space research (deep space)' },
  { key: 84, name: 'SPACE RESEARCH (deep space) (Earth-to-space)' },
  { key: 85, name: 'Space research (deep space) (space-to-Earth)' },
  { key: 86, name: 'SPACE RESEARCH (Earth-to-space)' },
  { key: 87, name: 'SPACE RESEARCH (Earth-to-space) (space-to-space)' },
  { key: 88, name: 'SPACE RESEARCH (Passive)' },
  { key: 89, name: 'SPACE RESEARCH (space-to-Earth)' },
  { key: 90, name: 'SPACE RESEARCH (space-to-Earth) (space-to-space)' },
  { key: 91, name: 'STANDARD FREQUENCY AND TIME SIGNAL' },
  { key: 92, name: 'STANDARD FREQUENCY AND TIME SIGNAL (10 000 kHz)' },
  { key: 93, name: 'STANDARD FREQUENCY AND TIME SIGNAL (15 000 kHz)' },
  { key: 94, name: 'STANDARD FREQUENCY AND TIME SIGNAL (2 500 kHz)' },
  { key: 95, name: 'STANDARD FREQUENCY AND TIME SIGNAL (5 000 kHz)' },
  { key: 96, name: 'STANDARD FREQUENCY AND TIME SIGNAL (20 kHz)' },
  { key: 97, name: 'STANDARD FREQUENCY AND TIME SIGNAL (20.000 MHz)' },
  { key: 98, name: 'STANDARD FREQUENCY AND TIME SIGNAL (25.000 MHz)' },
  { key: 99, name: 'STANDARD FREQUENCY AND TIME SIGNAL-SATELLITE (400.1 MHz)' },
  { key: 100, name: 'Standard frequency and time signal-satellite (Earth-to-space)' },
  { key: 101, name: 'Standard frequency and time signal-satellite (space-to-Earth)' },
] as ITag[];

export const spectrumTypeOptions = [
  { key: '', text: '' },
  { key: 'BND', text: 'Band' },
  { key: 'CHL', text: 'Channel' },
  { key: 'FRQ', text: 'Frequency' },
  { key: 'ITU', text: 'ITU Allocation' },
] as IDropdownOption[];

export const polarisationOptions = [
  { key: 'N', text: 'Non-specific' },
  { key: 'X', text: '+/- 45 degrees' },
  { key: 'R', text: 'Circular' },
  { key: 'A', text: 'Circular (anticlock)' },
  { key: 'C', text: 'Circular (clockwise)' },
  { key: 'P', text: 'Cross Polar' },
  { key: 'H', text: 'Horizontal' },
  { key: 'M', text: 'Mixed' },
  { key: 'L', text: 'Mixed or Linear' },
  { key: 'G', text: 'Orthogonal' },
  { key: 'O', text: 'Other' },
  { key: 'S', text: 'Slant' },
  { key: 'V', text: 'Vertical' },
] as IDropdownOption[];

export const frequencyTypeOptions = [
  { key: 'Assigned Frequency', text: 'Assigned Frequency' },
  { key: 'Carrier Frequency', text: 'Carrier Frequency' },
  { key: 'Nominal Frequency', text: 'Nominal Frequency' },
  { key: 'Registered Frequency', text: 'Registered Frequency' },
  { key: 'TV NICAM Carrier', text: 'TV NICAM Carrier' },
  { key: 'TV Sound Carrier', text: 'TV Sound Carrier' },
  { key: 'TV Vision Carrier', text: 'TV Vision Carrier' },
] as IDropdownOption[];

export const powerTypeOptions = [
  { key: 'Carrier Power', text: 'Carrier Power' },
  { key: 'Mean Power', text: 'Mean Power' },
  { key: 'Peak Envelope Power', text: 'Peak Envelope Power' },
] as IDropdownOption[];

export const hoursOfOperation = [
  { key: '', text: '' },
  { key: 'Day', text: 'Day' },
  { key: 'Night', text: 'Night' },
  { key: 'Transition', text: 'Transition' },
] as IDropdownOption[];
