import * as yup from 'yup';

import { requiredString, dateSchema } from '../../../../common/validation/yupUtils';
import { baseCallsignsSchema, mobileCallsignsSchema } from '../../../common/validationSchema';
import { setsSchema } from './basicDetailsValidationSchemaFull';

const basicDetailsShape = {
  licenceTypeId: requiredString.test('1-is-placeholder-value', 'Required', (value) => value !== '1'),
  commencementDate: dateSchema,
  expiryDate: dateSchema,
  anniversaryMonth: requiredString,
  baseCallsigns: baseCallsignsSchema,
  mobileCallsigns: mobileCallsignsSchema,
  sets: setsSchema,
};

export const basicDetailsSchema = yup.object().shape(basicDetailsShape);
