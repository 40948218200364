import Axios from 'axios';

import { getJWTFromLocalStorage, isJWTDueForRefresh, saveJWT } from './jwtUtils';
import { showNotLoggedInPage } from './login';

async function refreshJWT(): Promise<void> {
  const authHeader = { Authorization: 'Bearer ' + getJWTFromLocalStorage(), 'Content-Type': 'application/json', Accept: 'application/json' };
  const refreshResponse = await Axios.post<string>(
    '/auth/refresh',
    {},
    {
      method: 'POST',
      headers: authHeader,
    },
  );

  if (refreshResponse.status) {
    const newJwt = refreshResponse.data;
    console.log(`Refreshed JWT new value ${newJwt}`);

    saveJWT(newJwt);
  } else {
    showNotLoggedInPage();
  }
}

export function refreshJWTIfNeeded() {
  if (isJWTDueForRefresh()) {
    console.log('Refreshing JWT');
    refreshJWT();
  }
}
