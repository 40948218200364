import { FormikHelpers, FormikValues } from 'formik';
import React, { ReactNode } from 'react';

import { Form } from 'ui-library';

import Dialog from 'common/controls/surfaces/Dialog';
import HorizontalDivider from 'common/layout/HorizontalDivider';

import { FormProps } from '.';

interface DialogFormProps<T extends FormikValues> extends Pick<FormProps<T>, 'initialValues' | 'validationSchema'> {
  buttonProps: {
    cancelText?: string;
    cancelClicked: () => void;
    okText?: string;
    okClicked: (values: T, formikHelpers: FormikHelpers<T>) => void;
  };
  children: ReactNode;
  id: string;
  isOpen: boolean;
  title: string;
  submittingSpinnerLabel?: string;
}

const DialogForm = <T extends FormikValues>(props: DialogFormProps<T>) => {
  const { buttonProps, children, id, isOpen, title, validationSchema } = props;
  return (
    <Dialog title={title} isOpen={isOpen}>
      <HorizontalDivider />
      <Form<T>
        formButtonsOptions={{
          additionalButtons: [
            {
              id: `${props.id}_CancelBtn`,
              onClick: buttonProps.cancelClicked,
              text: buttonProps.cancelText || 'Cancel',
            },
          ],
          hideBackButton: true,
          hideCancelButton: false,
          submitButtonText: buttonProps.okText || 'OK',
        }}
        handleSubmit={buttonProps.okClicked}
        hideFormButtonsTop
        id={`${id}_Form`}
        initialValues={props.initialValues}
        isInDialog
        mode="CREATE"
        showFormButtonsBottom
        validationSchema={validationSchema}
        submittingSpinnerLabel={props.submittingSpinnerLabel}
      >
        <div style={{ paddingTop: 8 }}>{children}</div>
      </Form>
    </Dialog>
  );
};

export default DialogForm;
