import { Field, useFormikContext } from 'formik';
import * as React from 'react';

import { ClientSearchCriteria, ClientSearchCriteriaStatusEnum, ClientSearchCriteriaTypeEnum, ClientSearchResult } from 'api-client';

import { Grid } from 'ui-library';

import { PhoneNumbers } from 'common/controls/inputs/PhoneNumbers';
import ClientTagPicker from 'common/controls/inputs/client/ClientTagPicker';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { useGetClientWarning, useDisplayAddress } from 'common/utils/clientUtils';

interface AtonClientSectionProps {
  disabled?: boolean;
  typeSearch?: ClientSearchCriteriaTypeEnum;
  isRequired?: boolean;
  hint?: string;
}

const AtonClientSection: React.FC<AtonClientSectionProps> = (props) => {
  const formikContext = useFormikContext<any>();
  const clientSearchCriteria: ClientSearchCriteria = {
    status: ClientSearchCriteriaStatusEnum.Active,
    type: props.typeSearch,
    hidden: false,
  };

  const asClientTag = ({
    id,
    clientName,
    address,
    email,
    mobilePhone,
    fax,
    privatePhone,
    businessPhone,
    creditStatus,
    privacyAct,
    type,
  }: ClientSearchResult) => ({
    key: `${clientName} (${id})`,
    name: `${clientName} (${id})`,
    address,
    email,
    mobilePhone,
    fax,
    privatePhone,
    businessPhone,
    clientName,
    id,
    creditStatus,
    privacyAct,
    type,
  });

  const onChangeClientPicker = (item: any) => {
    let phoneNumbers = [];
    if (item[0]?.mobilePhone) {
      phoneNumbers.push({ ...item[0]?.mobilePhone, type: 'Mobile' });
    }
    if (item[0]?.businessPhone) {
      phoneNumbers.push({ ...item[0]?.businessPhone, type: 'Business' });
    }
    if (item[0]?.privatePhone) {
      phoneNumbers.push({ ...item[0]?.privatePhone, type: 'Private' });
    }
    if (item[0]?.fax) {
      phoneNumbers.push({ ...item[0]?.fax, type: 'Fax' });
    }
    formikContext.setFieldValue('email', item[0]?.email);
    formikContext.setFieldValue('clientId', item[0]?.id);
    formikContext.setFieldValue('clientName', item[0]?.clientName);
    formikContext.setFieldValue('address', item[0]?.address);
    formikContext.setFieldValue('phoneNumbers', phoneNumbers);
    formikContext.setFieldValue('creditStatus', item[0]?.creditStatus);
    formikContext.setFieldValue('privacyAct', item[0]?.privacyAct);
    formikContext.setFieldValue('client', item[0]);
  };

  return (
    <>
      <SectionHeading
        title="Client details"
        hint={props.hint}
        warning={useGetClientWarning(formikContext.values.id, formikContext.values.creditStatus)}
      />
      <Grid>
        <Grid.Row>
          <Grid.Col lg={6}>
            <ClientTagPicker
              name="clientPicker"
              label="Client"
              asClientTag={asClientTag}
              criteria={clientSearchCriteria}
              onChange={onChangeClientPicker}
              disabled={props.disabled}
              isRequired={props.isRequired}
            />
          </Grid.Col>
          <Grid.Col lg={6}>
            <Field type="hidden" name="clientId" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={6}>
            <ReadonlyData label={'Residential address'} value={useDisplayAddress(formikContext.values.client)} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={6}>
            <ReadonlyData label={'Email'} value={formikContext.values?.email} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <PhoneNumbers readOnly />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default AtonClientSection;
