import { interpolateObject, range } from 'd3';

export const interpolatePoints = (points: any[]) => {
  if (!points || points.length < 1) return [];
  let interpolatedPoints: any[] = [];

  for (let i = 0; i < points.length - 1; i++) {
    const inBetween = range(0, 1, 1 / 20).map((stepSize) => interpolateObject(points[i], points[i + 1])(stepSize));
    interpolatedPoints = interpolatedPoints.concat(inBetween);
  }

  return interpolatedPoints;
};

export const calculateRange = (
  series: {
    id: string;
    data: {
      x: number;
      y: number;
    }[];
  }[],
  hiddenIds: (string | number)[],
) => {
  const xRange = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER, padding: 0 };
  const yRange = { min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER, padding: 0 };

  series.forEach((part) => {
    if (!hiddenIds.includes(part.id)) {
      part.data.forEach((point) => {
        xRange.min = Math.min(point.x, xRange.min);
        xRange.max = Math.max(point.x, xRange.max);
        yRange.min = Math.min(point.y, yRange.min);
        yRange.max = Math.max(point.y, yRange.max);
      });
    }
  });
  if (xRange.min === Number.MAX_SAFE_INTEGER) {
    xRange.min = 0;
    xRange.max = 0;
    yRange.min = 0;
    yRange.max = 0;
  }

  xRange.padding = Math.abs(xRange.max - xRange.min) / 10;
  yRange.padding = Math.abs(yRange.max - yRange.min) / 10;

  return {
    xRange,
    yRange,
  };
};
