import { DetailsRow, IDetailsRowProps, IRenderFunction } from '@fluentui/react';

import { useTheme } from '../../theme/RRFTheme';

const renderWarningRow: IRenderFunction<IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }> = (props) =>
  props ? <WarningRow {...props} /> : null;

const WarningRow = (props: IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }) => {
  const { semanticColors } = useTheme();
  const styledProps = {
    ...props,
    styles: { root: { background: semanticColors.warningBackground } },
  };
  return (
    <div data-row-warning data-row-warning-index={props.itemIndex}>
      {' '}
      {props.onRenderRow ? props.onRenderRow(styledProps) : <DetailsRow {...styledProps} />}{' '}
    </div>
  );
};

export default renderWarningRow;
