import { MessageBarType } from '@fluentui/react';
import { useSelector } from 'react-redux';

import { OutageNotificationDto } from 'api-client';

import { useAppDispatch } from 'common/hooks';

import { StoreState } from '../../store';
import {
  clearOutageNotification,
  clearPageInstruction,
  setIsLoading,
  setOutageNotificationAction,
  setPageInstruction,
  setShowMobileNav,
  setShowWarningModal,
} from '../actions/pageActions';
import { ModalContextType } from '../controls/surfaces/ModalContextType';

const usePage = () => {
  const { isLoading, pageInstruction, showMobileNav, showWarningModal, modalContext, warnings, outageNotificationsState } = useSelector(
    (state: StoreState) => state.pageState,
  );
  const dispatch = useAppDispatch();

  return {
    isLoading,
    setIsLoading: (loading: boolean | string) => dispatch(setIsLoading(loading)),
    pageInstruction,
    setPageInstruction: (message?: string | JSX.Element, type?: MessageBarType, noExpiry?: boolean) =>
      dispatch(setPageInstruction({ message, type, noExpiry })),
    outageNotificationsState,
    addOutageNotification: (item?: OutageNotificationDto) => dispatch(setOutageNotificationAction(item)),
    removeOutageNotification: (item?: OutageNotificationDto) => dispatch(clearOutageNotification(item)),
    clearPageInstruction: () => dispatch(clearPageInstruction()),
    showMobileNav,
    setShowMobileNav: (show: boolean) => dispatch(setShowMobileNav(show)),
    showWarningModal,
    modalContext,
    warnings,
    setShowWarningModal: (show: boolean, warnings?: string[], hasConfirmed?: boolean, modalContext?: ModalContextType) =>
      dispatch(setShowWarningModal(show, warnings, hasConfirmed, modalContext)),
  };
};

export default usePage;
