import { MessageBarType } from '@fluentui/react';
import { Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AtonDto, AtonDtoStatusEnum, FileNoteDtoEntityTypeEnum, LocationSearchResult, LocationTypeEnum } from 'api-client';

import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { useAuth } from 'common/auth/AuthHook';
import Form, { FormMode } from 'common/form/Form';
import { FormButton } from 'common/form/FormButtons';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { COMMON_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { usePublicApi } from 'public/PublicApiHook';
import { useSearchApi } from 'search/SearchApiHooks';

import { useAtonApi } from '../AtonApiHook';
import { atonFormikValidator, atonValidateForm } from '../Utils';
import AtonCancelConfirmPopup from '../components/AtonCancelConfirmPopup';
import AtonDetailsSection from '../components/AtonDetailsSection';
import { atonValidationSchema } from '../create/validationSchema';
import AtonAuditSection from './AtonAuditSection';
import MaintainAtonForm from './MaintainAtonForm';

const initialAton: any = {
  clientId: undefined,
  clientName: undefined,
  clientPicker: undefined,
  type: undefined,
  locationId: undefined,
  atonName: undefined,
  accessMode: undefined,
  ais1StartSlot: undefined,
  ais2StartSlot: undefined,
  mmsi: undefined,
  location: undefined,
};

const MaintainAtonPage: React.FC = () => {
  const { maintainAton, getAton } = useAtonApi();
  const searchApi = useSearchApi();
  const publicApi = usePublicApi();
  const { atonId } = useParams<{ atonId: string }>();
  const [aton, setAton] = useState<any>(initialAton);
  const [location, setLocation] = useState<LocationSearchResult>();
  const { isCasualUser, hasPermission, loggedIn } = useAuth();
  const { searchLocation } = !loggedIn || isCasualUser() ? publicApi : searchApi;
  const { setShowWarningModal, setPageInstruction } = usePage();
  const [popupMode, setPopupMode] = useState<string>();
  const [formMode, setFormMode] = useState<FormMode>('VIEW');
  const [maintainAtonErrors, setMaintainAtonErrors] = useState<any>({});

  const canViewClient = hasPermission('SEARCH_ALL_CLIENT');
  const canViewLocation = hasPermission('VIEW_LOCATION');

  const getLocation = async (dto: AtonDto) => {
    let locationSearchResult;
    const locationSearchResults =
      (await searchLocation({ searchText: dto?.locationId?.toString(), locationType: LocationTypeEnum.P, hidden: false, pageSize: 20 })) || [];
    locationSearchResult = locationSearchResults.results[0];
    setLocation(locationSearchResult);
  };

  const updateAton = async (dto: AtonDto) => {
    if (canViewClient && canViewLocation) {
      const clientSearchResults = (await searchApi.searchClient({ searchText: dto.clientId.toString() })) || [];
      if (!clientSearchResults.results || clientSearchResults.results.length === 0) return;

      const clientSearchResult = clientSearchResults.results[0];
      let phoneNumbers = [];
      if (clientSearchResult?.mobilePhone) {
        phoneNumbers.push({ ...clientSearchResult?.mobilePhone, type: 'Mobile' });
      }
      if (clientSearchResult?.businessPhone) {
        phoneNumbers.push({ ...clientSearchResult?.businessPhone, type: 'Business' });
      }
      if (clientSearchResult?.privatePhone) {
        phoneNumbers.push({ ...clientSearchResult?.privatePhone, type: 'Private' });
      }
      if (clientSearchResult?.fax) {
        phoneNumbers.push({ ...clientSearchResult?.fax, type: 'Fax' });
      }
      if (dto?.locationId) {
        getLocation(dto);
      }
      setAton({
        ...dto,
        clientPicker: clientSearchResult?.clientName + ' (' + clientSearchResult?.id + ')',
        email: clientSearchResult?.email,
        clientId: clientSearchResult?.id,
        clientName: clientSearchResult?.clientName,
        address: clientSearchResult?.address,
        phoneNumbers,
        location: location,
      });
    } else {
      if (dto?.locationId) {
        getLocation(dto);
      }
      setAton({
        ...dto,
        clientId: dto.clientId,
        clientName: dto.clientName,
        location: location,
      });
    }
  };

  useEffect(() => {
    loggedIn && !isCasualUser() ? getAton(+atonId).then(updateAton) : publicApi.getAtonPublic(+atonId).then(updateAton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atonId]);

  const render = (helper: any) => {
    const { values } = helper;

    const save = () => {
      const schema = atonValidationSchema;

      const formIsValid = atonValidateForm(helper, schema, maintainAtonErrors);
      if (formIsValid) {
        maintainAton(+atonId, values, {
          successMessage: 'This AtoN record has been updated.',
        }).then(updateAton);
      } else {
        setPageInstruction('There are errors on the form.', MessageBarType.error);
      }
    };

    const canEdit = hasPermission('UPDATE_ATON') && aton?.status === AtonDtoStatusEnum.Current;
    const canCancel = hasPermission('CANCEL_ATON') && aton?.status === AtonDtoStatusEnum.Current;

    const canceleAton = () => {
      setPopupMode('CANCEL');
    };

    const buildActionButtons = (): FormButton[] => {
      const actionButtons: FormButton[] = [];

      if (canCancel) {
        actionButtons.push(
          { id: 'cancele-aton', text: 'Cancel AtoN', onClick: canceleAton },
          { id: 'save-aton', text: 'Save', onClick: save, disabledWhenNoDirty: true, isPrimary: true },
        );
      }
      return actionButtons;
    };

    return (
      <>
        {popupMode === 'CANCEL' && (
          <AtonCancelConfirmPopup
            atonId={+atonId}
            onCancel={() => setPopupMode(undefined)}
            aton={aton}
            operation={popupMode}
            onUpdateAton={updateAton}
          />
        )}
        <Form
          id="maintain-aton-form"
          showFormButtonsBottom
          mode={formMode}
          canEdit={canEdit}
          onEditClick={() => {
            setFormMode('EDIT');
          }}
          onCancelEdit={() => {
            setFormMode('VIEW');
          }}
          hideSubmit
          additionalButtons={(mode) => (mode === 'EDIT' ? buildActionButtons() : [])}
        >
          <MaintainAtonForm />
          <AtonDetailsSection initialLocation={location} mode={formMode} />
          <AtonAuditSection />
        </Form>
      </>
    );
  };

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');

  return (
    <Page title="Update Aton">
      {aton ? (
        <>
          <Formik<AtonDto>
            initialValues={aton}
            onSubmit={() => {}}
            validate={atonFormikValidator(atonValidationSchema, maintainAtonErrors)}
            enableReinitialize
            validateOnBlur
          >
            {render}
          </Formik>
          {canViewFileNote && <FileNoteSection entityId={+atonId} entityType={FileNoteDtoEntityTypeEnum.ATON} noteTypes={COMMON_FILE_NOTE_TYPE} />}
          <EventLogSection entityName={'ATON'} entityId={+atonId} entityToReloadOn={aton} />
        </>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default MaintainAtonPage;
