export const asOptionsSimple = (values: string[] = [], emptyOption?: boolean, sort?: boolean) => {
  const options = [];
  if (emptyOption === true) {
    options.push({ key: '', text: '' }, ...values.map((value: string) => ({ key: value, text: value })));
  } else {
    options.push(...values.map((value: string) => ({ key: value, text: value })));
  }
  return sort ? options.sort((a, b) => a.text.localeCompare(b.text)) : options;
};

export const asOptions = (keyValuePairs: KeyValue[] = [], noEmptyOption?: boolean, noSort?: boolean) => {
  const options = [];
  if (noEmptyOption === true) {
    options.push(...keyValuePairs.map((pair: KeyValue) => ({ key: pair.key, text: pair.value })));
  } else {
    options.push({ key: '', text: '' }, ...keyValuePairs.map((pair: KeyValue) => ({ key: pair.key, text: pair.value })));
  }
  return noSort ? options : options.sort((a, b) => a.text.localeCompare(b.text));
};

export interface KeyValue {
  key: string;
  value: string;
}
