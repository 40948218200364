import React from 'react';

import { Grid } from 'ui-library';

import { NumberField } from 'common/controls/inputs/NumberField';

import MultiSelectDropdown from '../../common/controls/inputs/MultiSelectDropdown';
import SearchForm, { SEARCH_FORM_MODE } from '../../search/SearchForm';

interface Props {
  onSearch?: (values: any) => void;
  onClear?: () => void;
  mode: SEARCH_FORM_MODE;
  hideManagementRightsStatuses?: boolean;
}

const SearchManagementRightsForm: React.FC<Props> = (props) => {
  const managementRightsStatusOptions = [
    { key: 'Current', text: 'Current' },
    { key: 'Registered', text: 'Registered' },
    { key: 'Cancelled', text: 'Cancelled' },
    { key: 'Expired', text: 'Expired' },
  ];

  return (
    <SearchForm
      id="search-management-rights-form"
      mode={props.mode}
      title="Search Management Rights"
      searchBoxProps={{
        placeholder: "MR number, licence number, manager's client number, or manager's client name",
        hint: (
          <>
            You can search by any of the following: <br /> Management right number, licence number, <br /> manager's client number, manager's client
            name.
          </>
        ),
      }}
      onSearch={props.onSearch}
      onClear={props.onClear}
      collapsible={props.mode === 'EMBEDDED'}
    >
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <NumberField
            label="Frequency (MHz)"
            name="refFrequency"
            placeholder="Enter the exact frequency value"
            maxLength={13}
            decimalDigit={6}
            maximunDecimalDigit={6}
            errorImmediate
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <NumberField
            label="Frequency from (MHz)"
            name="refFrequencyFrom"
            placeholder="Enter the Lower frequency value"
            maxLength={13}
            decimalDigit={6}
            maximunDecimalDigit={6}
            errorImmediate
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <NumberField
            label="Frequency to (MHz)"
            name="refFrequencyTo"
            placeholder="Enter the Upper frequency value"
            maxLength={13}
            decimalDigit={6}
            maximunDecimalDigit={6}
            errorImmediate
          />
        </Grid.Col>
      </Grid.Row>
      {!props.hideManagementRightsStatuses && (
        <Grid.Row>
          <Grid.Col sm={12} lg={3}>
            <MultiSelectDropdown
              label="Management right status"
              name="managementRightsStatuses"
              options={managementRightsStatusOptions}
              includeSelectAll
            />
          </Grid.Col>
        </Grid.Row>
      )}
    </SearchForm>
  );
};

export default SearchManagementRightsForm;
