import { ITextFieldProps, Spinner, TextField as FabricTextField } from '@fluentui/react';
import { FieldAttributes, FieldInputProps, FieldMetaProps, useField } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { FormMode } from '../../form/Form';
import { useForm } from '../../form/FormHook';
import { formatDigit } from '../../utils/numberUtils';
import { renderHelpText } from './HelpTextRenderer';
import ReadOnlyField from './ReadOnlyField';

export interface FormikTextFieldProps extends ITextFieldProps {
  loading?: boolean;
  errorImmediate?: boolean;
  helpText?: string;
  integerDigit?: number;
  decimalDigit?: number;
  maximunDecimalDigit?: number;
  readOnlyRenderFormat?: (value: any) => void;
  hideLabel?: boolean;
}

export const DEFAULT_DECIMAL = 2;
export const DEFAULT_INTEGER = 1;

const error = (meta: FieldMetaProps<string>, errorMessage?: string | JSX.Element) => {
  if (errorMessage) {
    return errorMessage;
  }

  return meta.touched && meta.error ? meta.error : undefined;
};

const commonProps = (
  props: FieldAttributes<FormikTextFieldProps>,
  field: FieldInputProps<string>,
  meta: FieldMetaProps<string>,
  mode: FormMode,
): FieldAttributes<FormikTextFieldProps> => {
  return {
    ...props,
    ...field,
    onChange: props.onChange
      ? (_: any) => {
          props.onChange!(_);
        }
      : undefined,
    errorMessage: error(meta, props.errorMessage),
    disabled: mode === 'DISABLED' || props.disabled,
    onRenderLabel: renderHelpText,
  };
};
const isEmpty = (value?: number | string) => !value && value !== 0;

export const NumberField: React.FC<FieldAttributes<FormikTextFieldProps>> = (props) => {
  const [value, setValue] = useState<string>();
  const [doTouch, setDoTouch] = useState<boolean>();
  const [field, meta, fieldHelper] = useField<string>(props as any);
  const { mode } = useForm();
  const integerDigit = props.integerDigit ? props.integerDigit : DEFAULT_INTEGER;
  const decimalDigit = props.decimalDigit !== undefined ? props.decimalDigit : DEFAULT_DECIMAL;
  const maximunDecimalDigit = props.maximunDecimalDigit ? props.maximunDecimalDigit : decimalDigit;
  const formatValue = (value: string) => formatDigit(value, integerDigit, decimalDigit, maximunDecimalDigit);
  const onBlur = (_: any) => {
    if (props.onBlur) {
      props.onBlur(_);
    }
    let changedValue = value;
    if (value) {
      const formattedText = formatValue(value);
      if (formattedText !== value) {
        changedValue = formattedText;
        setValue(formattedText);
      }
    }

    fieldHelper.setValue(changedValue ? changedValue : '');
    // Touch the field after the formik value has actually been set, so the validation runs on the updated formik field value.
    setDoTouch(true);
  };

  useEffect(() => {
    if (doTouch) {
      fieldHelper.setTouched(true, true);
      setDoTouch(false);
    }
    // eslint-disable-next-line
  }, [doTouch]);

  useEffect(() => {
    const formattedInitialValue = !isEmpty(meta.initialValue) ? formatValue(meta.initialValue!) : '';
    const value = !isEmpty(meta.initialValue) && meta.initialValue === field.value ? formattedInitialValue : field.value ?? '';
    setValue(value);
    // eslint-disable-next-line
  }, [meta.initialValue]);

  // If the field value itself has changed then display this (formatted) value on the TextField component.
  useEffect(() => {
    if ((value ?? '') !== (field.value ?? '')) {
      setValue(!isEmpty(field.value) ? formatValue(field.value) ?? '' : '');
    }
    // eslint-disable-next-line
  }, [field.value]);

  if (mode === 'VIEW' || props.readOnly) {
    return <ReadOnlyField {...props} renderFormat={props.readOnlyRenderFormat} />;
  }

  return props.loading ? (
    <FabricTextField {...commonProps(props, field, meta, mode)} onBlur={onBlur} onRenderSuffix={() => <Spinner />} />
  ) : (
    <FabricTextField {...commonProps(props, field, meta, mode)} onBlur={onBlur} onChange={(_, text) => setValue(text)} value={value} />
  );
};
