import { useFormikContext } from 'formik';
import React from 'react';

import { CaseTypeCodeEnum, OffenceDto } from 'api-client';

import { Grid2 } from 'ui-library';

import Checkbox from 'common/controls/inputs/Checkbox';
import HorizontalDivider from 'common/layout/HorizontalDivider';
import { PRODUCT_OR_SUPPLIER_AUDIT } from 'investigation/audit/hooks';
import { useOffencesByCategory } from 'investigation/hooks';

import { TextField } from '../../../common/controls/inputs/TextField';
import { AuditRecommendationFormProps } from './AuditRecommendationDialog';

const AuditOffenceSection = ({ offences = [] }: { offences: OffenceDto[] | undefined }) => {
  return (
    <Grid2.Col lg={1}>
      {offences.map((offence) => (
        <div key={offence.id} style={{ paddingBottom: 5 }}>
          <Checkbox label={offence.description} name={`offences.${offence.id}`} />
        </div>
      ))}
    </Grid2.Col>
  );
};

type AuditRecommendationOffencesProps = {
  caseTypeCode?: CaseTypeCodeEnum;
  emcAuditType?: boolean;
  radioAuditType?: boolean;
};

const AuditRecommendationOffences = (props: AuditRecommendationOffencesProps) => {
  const offences = useOffencesByCategory();
  const { values } = useFormikContext<AuditRecommendationFormProps>();

  const caseTypeCode = props.caseTypeCode;
  const isProductOrSupplierAudit = caseTypeCode && PRODUCT_OR_SUPPLIER_AUDIT.includes(caseTypeCode);
  const isLicenceAudit = caseTypeCode === CaseTypeCodeEnum.LICENCE;

  const otherOffenceId = offences.LICENCE_CONDITION
    ? offences.LICENCE_CONDITION.filter((offenceDto) => offenceDto.description === 'Other').map((offenceDto) => offenceDto.id) + ''
    : '';

  let otherSelected = !!values?.offences?.[otherOffenceId];

  return (
    <>
      <h3 style={{ margin: '5px 0' }}>Offences</h3>
      <HorizontalDivider />
      <Grid2>
        <Grid2.Col>
          <Grid2 layout={{ lg: 3 }}>
            {props.emcAuditType && isProductOrSupplierAudit && <AuditOffenceSection offences={offences.EMC_AUDIT} />}
            {props.radioAuditType && isProductOrSupplierAudit && <AuditOffenceSection offences={offences.RADIO_AUDIT} />}

            {isLicenceAudit && <AuditOffenceSection offences={offences.LICENCE_CONDITION} />}
          </Grid2>
        </Grid2.Col>
        <Grid2.Col lg={6} md={6}>
          {isLicenceAudit && otherSelected && <TextField name={`otherOffenceDescs.${otherOffenceId}`} label="Please specify" maxLength={50} />}
        </Grid2.Col>
      </Grid2>
    </>
  );
};

export default AuditRecommendationOffences;
