import { ActionButton, IButtonProps, IButtonStyles, useTheme } from '@fluentui/react';
import * as React from 'react';

interface Props extends IButtonProps {}

const MasterButton: React.FC<Props> = (props) => {
  const theme = useTheme();

  const styles: IButtonStyles = {
    root: {
      color: theme.semanticColors.primaryButtonBackground,
    },
    rootHovered: {
      color: theme.semanticColors.primaryButtonBackground,
    },
    icon: {
      color: theme.semanticColors.primaryButtonBackground,
    },
    iconHovered: {
      color: theme.semanticColors.primaryButtonBackground,
    },
  };

  return (
    <ActionButton {...props} styles={styles} allowDisabledFocus>
      {props.children}
    </ActionButton>
  );
};

export default MasterButton;
