import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import { NumberField } from 'common/controls/inputs/NumberField';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';

interface AntennaFieldsProps {}

const AntennaFields = (props: AntennaFieldsProps) => {
  const { isInternalUser } = useAuth();
  const { mode } = useForm();
  const maintainForm = mode === 'EDIT' || mode === 'VIEW';

  return (
    <>
      {maintainForm && (
        <Grid.Row>
          <Grid.Col sm={12} lg={3}>
            <ReadOnlyField label="Antenna ID" name={'id'} />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <ReadOnlyField label="Identifier" name={'identifier'} />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <ReadOnlyField label="Version number" name={'bizVersion'} />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            {maintainForm && isInternalUser && <Checkbox label="Hidden" name="hidden" topMargin />}
          </Grid.Col>
        </Grid.Row>
      )}

      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Make" name="make" maxLength={50} required />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Model" name="model" maxLength={50} required />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Type" name="type" maxLength={50} required />
        </Grid.Col>
      </Grid.Row>

      <SectionHeading title="Frequency" />
      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="Low (MHz)" name="lowFrequency" required maxLength={13} integerDigit={1} decimalDigit={6} maximunDecimalDigit={11} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="High (MHz)" name="highFrequency" required maxLength={13} integerDigit={1} decimalDigit={6} maximunDecimalDigit={11} />
        </Grid.Col>
      </Grid.Row>

      <SectionHeading
        title="Gain"
        required={mode === 'CREATE' || mode === 'EDIT'}
        hint="Valid combinations of Gain data are: a) Low, Mid and High; b) Low and High; c) Mid Only"
      />

      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="Low (dBi)" name="gainLow" maxLength={6} integerDigit={1} decimalDigit={1} maximunDecimalDigit={5} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="Mid (dBi)" name="gainMid" maxLength={6} integerDigit={1} decimalDigit={1} maximunDecimalDigit={5} />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="High (dBi)" name="gainHigh" maxLength={6} integerDigit={1} decimalDigit={1} maximunDecimalDigit={5} />
        </Grid.Col>
      </Grid.Row>

      <SectionHeading title="Other details" />

      <Grid.Row>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="Beamwidth (°)" name="beamWidth" maxLength={6} required integerDigit={1} decimalDigit={2} maximunDecimalDigit={5} />
        </Grid.Col>

        <Grid.Col sm={12} lg={3}>
          <NumberField label="Diameter (m)" name="diameter" maxLength={5} integerDigit={1} decimalDigit={2} maximunDecimalDigit={4} />
        </Grid.Col>

        <Grid.Col sm={12} lg={3}>
          <NumberField
            label="Front-Back ratio (dB)"
            name="frontBackRatio"
            maxLength={4}
            required
            integerDigit={1}
            decimalDigit={1}
            maximunDecimalDigit={3}
          />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <NumberField label="Xpol (dB)" name="xPol" maxLength={5} integerDigit={1} decimalDigit={1} maximunDecimalDigit={4} />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <TextField label="Remarks" name="remarks" maxLength={400} multiline />
        </Grid.Col>
      </Grid.Row>

      {maintainForm && isInternalUser && (
        <Grid.Row>
          <Grid.Col sm={12} lg={3}>
            <ReadOnlyField label="Created by" name={'createdBy'} />
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <ReadOnlyField label="Created date" name={'createdDate'} renderFormat={formatDateTimeFromISO} />
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

export default AntennaFields;
