import { OutageNotificationDto } from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { outageNotificationApi } from 'common/api/api';

export const useOutageNotificationApi = () => {
  const { aroundApi } = useApi();

  return {
    createOutageNotification: (outageNotificationDto: OutageNotificationDto, config?: ApiConfig) =>
      aroundApi(outageNotificationApi.createOutageNotification(outageNotificationDto), config),
    getOutageNotification: (outageNotificationId: number, config?: ApiConfig) =>
      aroundApi(outageNotificationApi.getOutageNotification(outageNotificationId), config),
    getOutageNotifications: (config?: ApiConfig): Promise<OutageNotificationDto[]> =>
      aroundApi(outageNotificationApi.getOutageNotifications(), config),
    updateOutageNotification: (
      outageNotificationId: number,
      outageNotificationDto: OutageNotificationDto,
      config?: ApiConfig,
    ): Promise<OutageNotificationDto> =>
      aroundApi(outageNotificationApi.updateOutageNotification(outageNotificationId, outageNotificationDto), config),
    removeOutageNotification: (outageNotificationId: number, outageNotificationDto: OutageNotificationDto, config?: ApiConfig) =>
      aroundApi(outageNotificationApi.removeOutageNotification(outageNotificationId, outageNotificationDto), config),
  };
};
