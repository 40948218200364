import { mergeStyles } from '@fluentui/merge-styles';
import * as React from 'react';
import Form from './Form';
import { FormProps } from './Form';

interface Props extends FormProps {
  showCloseButton?: boolean;
}

const InlineForm: React.FC<Props> = (props) => {
  const className = mergeStyles({
    background: '#f0f2f8',
    padding: '29.1px 27.5px 6.4px 22px',
  });

  return (
    <div className={className}>
      <Form {...props} />
    </div>
  );
};

export default InlineForm;
