import {
  StoredDocument,
  StoreDocumentRequest,
  DocumentUploadDto,
  AvUploadLocation,
  GetDocumentDetailsRequest,
  GetDocumentDetailsResponse,
} from 'api-client';

import { ApiConfig, useApi } from '../common/api/ApiHook';
import { documentsApi } from '../common/api/api';

export const useDocumentManagementApi = () => {
  const { aroundApi } = useApi();

  return {
    createDocumentUpload: (documentUploadRequest: DocumentUploadDto, config?: ApiConfig): Promise<AvUploadLocation> =>
      aroundApi(documentsApi.createUpload(documentUploadRequest), config),

    storeDocument: (storeDocumentRequest: StoreDocumentRequest, config?: ApiConfig): Promise<StoredDocument> =>
      aroundApi(documentsApi.storeDocument(storeDocumentRequest), config),

    getDocument: (drmId: string, config?: ApiConfig): Promise<StoreDocumentRequest> => aroundApi(documentsApi.getDocument(drmId), config),

    getDocumentDetails: (request: GetDocumentDetailsRequest, config?: ApiConfig): Promise<GetDocumentDetailsResponse> =>
      aroundApi(documentsApi.getDocumentDetails(request), config),
  };
};
