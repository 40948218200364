import { DetailsRow, IDetailsRowProps, IRenderFunction } from '@fluentui/react';

import { useTheme } from '../../theme/RRFTheme';

const renderSevereWarningRow: IRenderFunction<IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }> = (props) =>
  props ? <SevereWarningRow {...props} /> : null;

const SevereWarningRow = (props: IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }) => {
  const { semanticColors } = useTheme();
  const styledProps = {
    ...props,
    styles: {
      root: {
        background: props.selection.isIndexSelected(props.itemIndex)
          ? semanticColors.detailsListSevereWarningRowSelected
          : semanticColors.detailsListSevereWarningRow,
      },
    },
  };

  return (
    <div data-row-severe-warning data-row-severe-warning-index={props.itemIndex}>
      {' '}
      {props.onRenderRow ? props.onRenderRow(styledProps) : <DetailsRow {...styledProps} />}{' '}
    </div>
  );
};

export default renderSevereWarningRow;
