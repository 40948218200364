import * as yup from 'yup';
import { isBlank, requiredString, notRequiredString, requiredNumber, numberSchema } from '../../../common/validation/yupUtils';

export const searchSpectrumValidationSchema = yup.object().shape({
  searchTextError: yup.string().when(['$channel', '$ituServiceId', '$refFrequency', '$refFrequencyFrom', '$refFrequencyTo'], {
    is: (channel, ituServiceId, refFrequency, refFrequencyFrom, refFrequencyTo) =>
      isBlank(channel) && isBlank(ituServiceId) && isBlank(refFrequency) && isBlank(refFrequencyFrom) && isBlank(refFrequencyTo),
    then: requiredString.required('Please specify your query'),
    otherwise: notRequiredString,
  }),
  refFrequency: numberSchema.when(['refFrequencyFrom', 'refFrequencyTo'], {
    is: (refFrequencyFrom, refFrequencyTo) => refFrequencyFrom || refFrequencyTo,
    then: yup
      .number()
      .test(
        'Frequency',
        "Only one of 'Frequency' or 'Frequency range' parameter may be supplied, not both",
        (refFrequencyFrom) => !refFrequencyFrom && refFrequencyFrom !== 0,
      ),
  }),
  refFrequencyFrom: numberSchema
    .test('frequency-range', "The 'to' frequency must be greater than the 'from' frequency", function (this: yup.TestContext, refFrequencyFrom) {
      const refFrequencyTo = Number.parseFloat(this.parent.refFrequencyTo);
      if (refFrequencyFrom !== null && refFrequencyFrom !== undefined && !Number.isNaN(refFrequencyTo)) {
        return refFrequencyFrom < refFrequencyTo;
      }
      return true;
    })
    .test('required', 'Required', function (this: yup.TestContext, refFrequencyFrom) {
      const refFrequencyTo = this.parent.refFrequencyTo;
      return !!refFrequencyFrom || (!refFrequencyTo && refFrequencyFrom !== 0);
    }),
  refFrequencyTo: numberSchema.when('refFrequencyFrom', {
    is: (refFrequencyFrom) => refFrequencyFrom,
    then: requiredNumber,
  }),
});
