import { Reducer } from 'redux';

import { AGREED_VIEW_SUPPRESSED_STORAGE_KEY, LoginState, parseLoginStateFromJwt } from '../../store';
import {
  LoginStateActionType,
  SetAgreedViewSuppressedAction,
  SetFeatureFlags,
  SetRoleClientProfile,
  SetUserName,
  SetUserPreferences,
  TypeKeys,
} from '../actions/loginActions';

const loginReducer: Reducer = (state: LoginState, action: LoginStateActionType): LoginState => {
  switch (action.type) {
    case TypeKeys.SET_ROLE_CLIENT_PROFILE:
      return handleSetRoleClient(state, action);
    case TypeKeys.SET_AGREED_VIEW_SUPPRESSED:
      return handleSetAgreedViewSuppressed(state, action);
    case TypeKeys.SET_USER_PREFERENCES:
      return handleSetUserPreferences(state, action);
    case TypeKeys.SET_USER_NAME:
      return handleSetUserName(state, action);
    case TypeKeys.SET_FEATURE_FLAGS:
      return handleSetFeatureFlags(state, action);
    default:
      return { ...state } as LoginState;
  }
};

const handleSetRoleClient = (state: LoginState, action: SetRoleClientProfile): LoginState => {
  return {
    ...state,
    ...parseLoginStateFromJwt(action.payload),
  };
};

const handleSetAgreedViewSuppressed = (state: LoginState, action: SetAgreedViewSuppressedAction): LoginState => {
  localStorage.setItem(AGREED_VIEW_SUPPRESSED_STORAGE_KEY, action.payload + '');
  return {
    ...state,
    agreedViewSuppressed: action.payload,
  };
};

const handleSetUserPreferences = (state: LoginState, action: SetUserPreferences): LoginState => {
  return {
    ...state,
    userPreference: action.payload,
  };
};

const handleSetUserName = (state: LoginState, action: SetUserName): LoginState => {
  return {
    ...state,
    firstName: action.payload.firstName,
    lastName: action.payload.lastName,
  };
};

const handleSetFeatureFlags = (state: LoginState, action: SetFeatureFlags): LoginState => {
  return {
    ...state,
    featureFlags: action.payload,
  };
};

export default loginReducer;
