import { mergeStyles } from '@fluentui/react';
import * as React from 'react';

interface Props {}

const overlayClassName = mergeStyles({
  display: 'block',
  position: 'fixed',
  zIndex: '2000000',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
});

const Overlay: React.FC<Props> = (props) => {
  return <div className={overlayClassName} />;
};

export default Overlay;
