import { Label } from '@fluentui/react';
import * as React from 'react';

import { Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import { useMedia } from 'common/layout/MediaHook';
import { validFormatSpectrumMaskPoint } from 'common/utils/spectrumMaskPointUtils';
import { numberToLetter } from 'common/utils/stringUtils';

interface SpectrumMaskPointProps {
  id?: number;
  name?: string;
  label?: string;
  required?: boolean;
  graphPointIndex?: number;
}

export const SpectrumMaskPoint: React.FC<SpectrumMaskPointProps> = (props) => {
  const { isMobile } = useMedia();

  return (
    <>
      <Grid.Col sm={12} lg={1}>
        {isMobile && <Label>Graph Point</Label>}
        <TextField name={props.name + '.graphPointIndex'} readOnly readOnlyRenderFormat={() => numberToLetter(props.graphPointIndex ?? 0)} />
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <TextField
          name={props.name + '.displacement'}
          label={isMobile ? 'Displacement (MHz)' : ''}
          formatText={validFormatSpectrumMaskPoint(false)}
          readOnlyRenderFormat={validFormatSpectrumMaskPoint(false)}
          ariaLabel="Displacement (MHz)"
        />
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <TextField
          name={props.name + '.frequency'}
          label={isMobile ? 'Frequency (MHz)' : ''}
          formatText={validFormatSpectrumMaskPoint(false)}
          readOnlyRenderFormat={validFormatSpectrumMaskPoint(false)}
          ariaLabel="Frequency (MHz)"
        />
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <TextField
          name={props.name + '.dbwSetLevel'}
          label={isMobile ? 'Set Level dBW (eirp)' : ''}
          formatText={validFormatSpectrumMaskPoint(true)}
          readOnlyRenderFormat={validFormatSpectrumMaskPoint(true)}
          ariaLabel="Set Level dBW (eirp)"
        />
      </Grid.Col>
      <Grid.Col sm={12} lg={2}>
        <TextField
          name={props.name + '.calcLevel'}
          label={isMobile ? 'Calc. Level dB' : ''}
          formatText={validFormatSpectrumMaskPoint(true)}
          readOnlyRenderFormat={validFormatSpectrumMaskPoint(true)}
          ariaLabel="Calc. Level dB"
        />
      </Grid.Col>
    </>
  );
};
