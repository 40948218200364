import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider } from '@fluentui/react';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import Routes from './Routes';
import { RRFSemanticColors } from './common/theme/RRFSematicColors';
import { RRFTheme } from './common/theme/RRFTheme';

initializeIcons();

export const themePalette = {
  themePrimary: '#00b5e2', //#0078D4 - pass #00b5e2 - fail
  themeLighterAlt: '#eff8f9',
  themeLighter: '#c3e4e9',
  themeLight: '#95ccd5',
  themeTertiary: '#449dab',
  themeSecondary: '#107484',
  themeDarkAlt: '#005a67',
  themeDark: '#004c57',
  themeDarker: '#003840',
  neutralLighterAlt: '#faf9f8',
  neutralLighter: '#f3f2f1',
  neutralLight: '#edebe9',
  neutralQuaternaryAlt: '#e1dfdd',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c6c4',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#414042',
  white: '#ffffff',
};
// Seconday color #6dbe97
const semanticColors: Partial<RRFSemanticColors> = {
  bodyBackground: '#faf9f8', // also see App.css
  bodyDivider: '#e2e2e2',
  menuHeader: '#414042',

  //Dropdown
  menuItemBackgroundHovered: '#daeaf5',
  menuItemBackgroundPressed: '#f1f1f1',

  // Primary button
  primaryButtonBorder: '#C84504',
  primaryButtonBackground: '#C84504',
  primaryButtonBackgroundHovered: '#D14904',
  primaryButtonBackgroundDisabled: '#e2e2e2',

  // Default button
  buttonBackground: '#0e7ac3',
  buttonBackgroundHovered: '#3a8fbf',
  buttonBorder: '#0e7ac3',
  buttonTextHovered: '#FFFFFF',
  buttonText: '#FFFFFF',
  buttonBackgroundDisabled: '#e2e2e2',

  // Secondary button
  secondaryButtonBackground: '#006272',
  secondaryButtonBorder: '#006272',
  secondaryButtonBackgroundHovered: '#287f7f',
  secondaryButtonBorderHovered: '#287f7f',

  // Inline button
  inlineButtonBackground: '#ffffff',
  inlineButtonBackgroundHovered: '#ffffff',
  inlineButtonBorder: '#0e7ac3',
  inlineButtonBorderHovered: '#3a8fbf',
  inlineButtonText: '#0e7ac3',
  inlineButtonTextHovered: '#3a8fbf',

  // Error styling
  errorBackground: '#fed9cc',
  errorText: '#ce0404',

  // Input styling
  inputBorder: '#707070',
  inputText: '#414042',

  // Details list
  detailsListBackground: '#FFFFFF',
  detailsListText: '#000000',
  detailsListGroupHeader: '#e3eef6', //#3a8fbf',
  detailsListGroupHeaderText: '#000000',
  detailsListGroupHeaderChevron: '#000000',
  detailsListGroupHeaderCountText: '#000000',
  detailsListErrorRow: '#FDE7E9',
  detailsListErrorRowSelected: '#fbd0d4',
  detailsListSevereWarningRow: '#FED9CC',
  detailsListSevereWarningRowSelected: '#fdb79f',
  detailsListWarningRow: '#FFF4CE',
  detailsListWarningRowSelected: '#faeab4',

  // Links
  link: '#0e7ac3',
  linkHovered: '#0e7ac3',

  // Tagpicker
  tagPickerTextColor: '#0e7ac3',
  tagPickerTextBackgroundColor: '#daeaf5',
  tagPickerCloseButtonBackgroundColor: '#daeaf5',

  //Accordion
  accordionHeaderHover: '#daeaf5',
  accordionContent: '#FFFFFF',
  accordionHeaderExpanded: '#e3eef6',
  accordionHeaderCollapsed: '#FFFFFF',

  //Inline panel
  inlinePanelBackgroundColor: '#f0f2f8',

  //Back to top link
  backToTopIconBackgroudColor: '#C84504',
  backToTopIconBackgroudColorHovered: '#D14904',

  // Dashboard
  dashboardWidgetBackground: '#FFFFFF',
};

const defaultTheme: Partial<RRFTheme> = {
  defaultFontStyle: { fontFamily: 'Fira Sans', fontStyle: 'normal', fontSize: 14 },
  semanticColors: semanticColors,
  palette: themePalette,
  isInverted: true,
};

export interface AppContextProps {
  loggedIn?: boolean;
  username?: string;
  page: {
    showMobileNav: boolean;
    setShowMobileNav: (show: boolean) => void;
    pageInstruction?: string;
    setPageInstruction: (instruction?: string) => void;
  };
}

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeProvider theme={defaultTheme}>
        <div className="App">
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
