import atlas from 'azure-maps-control';

export const SAT_LAYER_ID: string = 'LinzSatelliteTileLayer';
export const TLA_LAYER_ID: string = 'StatsTLATileLayer';

/** Options for the LinzSatelliteToggle */
export interface LinzSatelliteToggleOptions extends atlas.ControlOptions {
  allowTLA?: boolean | false;
}

/**
 * Map control to switch LINZ satellite tile display on/off.
 * Optionally quad-state to also allow display of TLA boundaries or both.
 *
 * Also corrects the LINZ tile layer order.
 *
 * Requires that the parent map control has added the layer, defined the id with the above
 * constant LAYER_ID and set it to initially non-visible.
 */
export class LinzSatelliteToggle implements atlas.Control {
  // attributes
  private _shifted = false;
  private _container: HTMLElement | undefined | null;
  private _select: HTMLSelectElement | undefined;
  public satelliteVisible = false;
  public tlaVisible = false;
  private _map: atlas.Map | undefined | null;
  private _options: LinzSatelliteToggleOptions = { allowTLA: false };

  /** Constructor  */
  constructor(options?: LinzSatelliteToggleOptions | any) {
    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  // private methods
  private createSelectOption(val: string, text: string, selected: boolean): HTMLOptionElement {
    let opt = document.createElement('option');
    opt.setAttribute('value', val);
    opt.innerText = text;
    if (selected) {
      opt.setAttribute('selected', 'selected');
    }

    return opt;
  }

  /**
   * Action to perform when the control is added to the map.
   * @param map The map the control was added to.
   * @param options The control options used when adding the control to the map.
   * @returns The HTML Element that represents the control.
   */
  public onAdd(map: atlas.Map, options?: atlas.ControlOptions): HTMLElement {
    this._map = map;

    const labels = ['Streets', 'Satellite', 'TLA', 'TLA & Satellite'];
    const _ariaSatLabel = labels[3];

    //Create a button container.
    this._container = document.createElement('div');
    this._container.setAttribute('aria-label', _ariaSatLabel);
    this._container.classList.add('azure-maps-control-container');

    //Create the slider input.
    this._select = document.createElement('select');
    this._select.classList.add('atlas-map-slider');
    this._select.setAttribute('title', _ariaSatLabel);
    this._select.setAttribute('alt', _ariaSatLabel);

    // add select options
    this._select.appendChild(this.createSelectOption('0', labels[0], true));
    this._select.appendChild(this.createSelectOption('1', labels[1], false));
    if (this._options.allowTLA) {
      this._select.appendChild(this.createSelectOption('2', labels[2], false));
      this._select.appendChild(this.createSelectOption('3', labels[3], false));
    }

    // store in global if needed for testing
    // (window as any).g_map = this._map;

    // add select change event listener to change the layer visibility
    this._select.addEventListener('change', () => {
      if (this._map && this._select) {
        // set TLA display based on selection
        if (this._options.allowTLA) {
          this.tlaVisible = this._select.value === '2' || this._select.value === '3';
          let tlaLayer = this._map.layers.getLayerById(TLA_LAYER_ID) as atlas.layer.TileLayer;
          tlaLayer.setOptions({ visible: this.tlaVisible });
        }

        let satLayer = this._map.layers.getLayerById(SAT_LAYER_ID) as atlas.layer.TileLayer;
        if (satLayer != null) {
          if (!this._shifted) {
            // done only once as a hack to correct the Z-order
            this._shifted = true;
            this._map.layers.remove(satLayer); // remove and
            this._map.layers.add(satLayer, 'transit'); // re-add below roads
          }
          // set satellite tile display based on slider
          this.satelliteVisible = this._select.value === '1' || this._select.value === '3';
          let opacity = this.tlaVisible ? 0.5 : 1.0; // reduce opacity when also showing TLA
          satLayer.setOptions({ visible: this.satelliteVisible, opacity: opacity });
        }
      }
    });

    this._container.appendChild(this._select);
    return this._container;
  }

  /** cleanup when control is removed from the map. */
  public onRemove(): void {
    if (this._container) {
      this._container.remove();
      this._container = null;
    }
    this._map = null;
  }
}
