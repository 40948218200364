import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AppealAuditDto,
  AuditAppealResultDto,
  AuditComplianceDto,
  AuditCourtDecisionDto,
  AuditDto,
  CaseSearchResult,
  CaseTypeCodeEnum,
  CaseTypeGroupEnum,
  CloseAuditDto,
  CompleteAuditDto,
  CreateAuditDto,
  CreateInvoiceAuditDto,
  InfringementFeeDto,
  InfringementFeeDtoTypeEnum,
  MaintainAuditDto,
  OffenceDto,
  RecommendAuditDto,
  ReviewAuditDto,
} from 'api-client';
import { WithdrawInvoiceAuditDto } from 'api-client/api';

import { DropdownOption } from 'ui-library/dropdown';
import { SelectedTag } from 'ui-library/tagpicker';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { auditApi } from 'common/api/api';
import usePage from 'common/layout/PageHook';
import { formatDate, formatDateTimeFromISO } from 'common/utils/dateUtils';
import { AssociatedToByEnum } from 'investigation/components/CaseAssociatedTo';
import {
  convertStatus,
  convertToAssociatedToByEnum,
  REQUIRED_MESSAGE,
  useCamundaGroupOptions,
  useCaseAssociatedTo,
  useCaseTypeOptions,
  useCaseSearchResultParser,
  useDtoConverter,
  useEmcStandardOptions,
  useOffencesByCategory,
  useOriginCase,
  useServiceOfInterestOptions,
  useSourceCodeOptions,
} from 'investigation/hooks';
import { AuditFormProps, BaseCaseFormProps } from 'investigation/interfaces';
import { useSearchApi } from 'search/SearchApiHooks';

import { AuditLinkedCaseTypeEnum } from '../components/AuditLinkedCases';

export const useAuditApi = () => {
  const { aroundApi } = useApi();

  const defaultApiConfig: ApiConfig = {
    callerHandleErrors: true,
    disableErrorMessage: false,
    showLoadingSpinner: false,
  };

  return {
    appealAuditApi: (dto: AppealAuditDto): Promise<AuditDto> => aroundApi(auditApi.appealAudit(dto, defaultApiConfig)),
    auditAppealResultApi: (dto: AuditAppealResultDto): Promise<AuditDto> => aroundApi(auditApi.auditAppealResult(dto, defaultApiConfig)),
    auditComplianceApi: (dto: AuditComplianceDto): Promise<AuditDto> => aroundApi(auditApi.auditCompliance(dto, defaultApiConfig)),
    auditCourtDecisionApi: (dto: AuditCourtDecisionDto): Promise<AuditDto> => aroundApi(auditApi.auditCourtDecision(dto, defaultApiConfig)),
    closeAuditApi: (dto: CloseAuditDto): Promise<AuditDto> => aroundApi(auditApi.closeAudit(dto, defaultApiConfig)),
    completeAuditApi: (dto: CompleteAuditDto): Promise<AuditDto> => aroundApi(auditApi.completeAudit(dto, defaultApiConfig)),
    createAuditApi: (dto: CreateAuditDto): Promise<AuditDto> =>
      aroundApi(
        auditApi.createAudit(
          {
            ...dto,
            caseDiscriminator: 'CreateAuditDto',
          },
          { ...defaultApiConfig, showLoadingSpinner: true } as ApiConfig,
        ),
      ),
    getAuditByIdApi: (id: number): Promise<AuditDto> => aroundApi(auditApi.getAuditById(id), defaultApiConfig),
    getInfringementFees: (): Promise<InfringementFeeDto[]> => aroundApi(auditApi.getInfringementFees(), defaultApiConfig),
    maintainAuditApi: (dto: MaintainAuditDto): Promise<AuditDto> =>
      aroundApi(
        auditApi.maintainAudit(
          {
            ...dto,
            caseDiscriminator: 'MaintainAuditDto',
          },
          { ...defaultApiConfig, showLoadingSpinner: true } as ApiConfig,
        ),
      ),
    recommendAuditApi: (dto: RecommendAuditDto): Promise<AuditDto> => aroundApi(auditApi.recommendAudit(dto, defaultApiConfig)),
    reviewAuditApi: (dto: ReviewAuditDto): Promise<AuditDto> => aroundApi(auditApi.reviewAudit(dto, defaultApiConfig)),
    confirmEmailSentApi: (caseId: number): Promise<AuditDto> => aroundApi(auditApi.confirmEmailSent(caseId, defaultApiConfig)),
    createInfringementInvoice: (auditDto: CreateInvoiceAuditDto, config?: ApiConfig): Promise<AuditDto> =>
      aroundApi(auditApi.createInfringementInvoice(auditDto), config),
    getInfringementNotice: (infringementId: number, config?: ApiConfig) =>
      aroundApi(auditApi.getInfringementNotice(infringementId, { responseType: 'blob' }), config),
    manualWithdrawal: (auditDto?: WithdrawInvoiceAuditDto, config?: ApiConfig): Promise<AuditDto> =>
      aroundApi(auditApi.manualWithdrawal(auditDto), config),
  };
};

export const useAuditDataFetcher = (group?: CaseTypeGroupEnum, includeInactive?: boolean) => {
  const { getAuditByIdApi } = useAuditApi();
  const { camundaGroupOptions } = useCamundaGroupOptions();
  const { setIsLoading } = usePage();
  const { caseId } = useParams<{ caseId: string }>();
  const [caseDto, setCaseDto] = useState<Partial<AuditDto>>({});

  const { caseTypeOptions } = useCaseTypeOptions(group, includeInactive);

  const { client, licence, emission } = useCaseAssociatedTo(convertToAssociatedToByEnum(caseDto?.associatedTo), {
    entityId: caseDto?.clientOrBusinessAssociatedNo,
  });

  const offencesByCategory = useOffencesByCategory();
  const originCase = useOriginCase(caseDto?.originCaseId);

  const serviceOfInterestOptions = useServiceOfInterestOptions();
  const sourceCodeOptions = useSourceCodeOptions();

  const { cases: linkedCases, callSearchApi } = useCaseSearcherById();

  useEffect(() => {
    setIsLoading(true);
    getAuditByIdApi(+caseId)
      .then((caseDto) => {
        setCaseDto(caseDto);

        const { originatorCases = [], subsidiaryCases = [] } = caseDto;
        const caseNumbers = [...originatorCases.map(({ id }) => id), ...subsidiaryCases.map(({ id }) => id)];

        callSearchApi(caseNumbers);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const originatorCases = caseDto.originatorCases || [];
  const subsidiaryCases = caseDto.subsidiaryCases || [];

  const linkTypes: any = {};

  originatorCases?.forEach(({ id }) => {
    linkTypes[`caseId_${id}`] = AuditLinkedCaseTypeEnum.ORIGINATOR;
  });
  subsidiaryCases?.forEach(({ id }) => {
    linkTypes[`caseId_${id}`] = AuditLinkedCaseTypeEnum.SUBSIDIARY;
  });

  const linkedManagementRights = caseDto.linkedManagementRights?.length ? caseDto.linkedManagementRights[0] : undefined;

  return {
    caseDetails: {
      allocatedTo: caseDto.camundaGroupId,
      allocatedToGroups: camundaGroupOptions,
      allocatedToUser: caseDto.camundaUserId,
      appealProcessType: caseDto.appealProcessType,
      appealResult: caseDto.appealResult,
      appealResultDetails: caseDto.appealResultDetails,
      associatedToBy: convertToAssociatedToByEnum(caseDto.associatedTo),
      associatedTo: undefined,
      caseClassificationId: caseDto.classification?.id,
      caseNumber: caseDto.id,
      caseTypeId: caseDto.type?.id,
      clientOrBusinessAssociatedNo: caseDto.clientOrBusinessAssociatedNo,
      closedDate: caseDto.closedDate,
      complianceDate: formatDateTimeFromISO(caseDto.complianceDate),
      complianceNotes: caseDto.complianceNotes,
      complianceCheckResult: caseDto.complianceCheckResult,
      courtDecision: caseDto.courtDecision,
      courtDecisionDate: caseDto.courtDecisionDate,
      courtDecisionDetails: caseDto.courtDecisionDetails,
      createdBy: caseDto.createdName,
      createdDate: formatDateTimeFromISO(caseDto.createdDate, { showSeconds: true }),
      emcStandardId: caseDto.emcStandardId,
      endDate: caseDto.endDate,
      finalResult: caseDto.finalResult,
      finalResultNotes: caseDto.finalResultNotes,
      lastUpdatedBy: caseDto.lastUpdatedName,
      lastUpdatedDate: formatDateTimeFromISO(caseDto.lastUpdatedDate, { showSeconds: true }),
      notes: caseDto.notes,
      offencesByCategory,
      originCaseId: caseDto.originCaseId,
      serviceOfInterestId: caseDto.serviceOfInterest?.id,
      sourceCodeId: caseDto.sourceCode?.id,
      startDate: caseDto.startDate || formatDate(new Date()),
      startUser: caseDto.startUser,
      status: convertStatus(caseDto.status),
      statusEnum: caseDto.status,
      tags: caseDto.tags,

      caseTypeOptions,
      client,
      licence,
      emission,
      originCase,
      serviceOfInterestOptions,
      sourceCodeOptions,

      linkedCases,
      linkTypes,
      linkedManagementRights: linkedManagementRights && {
        key: linkedManagementRights.id,
        name: `${linkedManagementRights.id} | ${linkedManagementRights.managerName} | ${linkedManagementRights.status} | ${linkedManagementRights.clientId}`,
        data: linkedManagementRights,
      },
      links: caseDto.links,
    },
    caseDto,
    caseId,
  };
};

export const useAuditFormState = () => {
  const [formState, setFormState] = useState<Partial<AuditFormProps>>();

  const { caseDto, caseDetails } = useAuditDataFetcher(CaseTypeGroupEnum.AUDIT);
  const emcStandardOptions = useEmcStandardOptions();

  const auditDto: Partial<AuditDto> = caseDto;

  let offences: any = {};
  let offenceOther: any = {};

  if (auditDto.offences?.length) {
    const { EMC_AUDIT = [], LICENCE_CONDITION = [], RADIO_AUDIT = [] } = caseDetails.offencesByCategory;
    const caseTypeCode = caseDto.type?.code;

    const isProductOrSupplierAudit = caseTypeCode && PRODUCT_OR_SUPPLIER_AUDIT.includes(caseTypeCode);
    const isLicenceAudit = caseTypeCode === CaseTypeCodeEnum.LICENCE;

    let availableOffences: OffenceDto[] = [];

    if (isLicenceAudit) {
      availableOffences = [...LICENCE_CONDITION];
    } else if (isProductOrSupplierAudit) {
      availableOffences = [...EMC_AUDIT, ...RADIO_AUDIT];
    }

    const offenceIds = auditDto.offences?.map(({ offenceId = '' }) => offenceId) || [];

    offences = availableOffences.reduce(
      (output, { id = '' }) => ({
        ...output,
        [id]: offenceIds.includes(id),
      }),
      offences,
    );

    offenceOther = auditDto.offences
      ?.filter((offence) => offence.otherDesc)
      .reduce(
        (output, { offenceId = '', otherDesc }) => ({
          ...output,
          [offenceId]: otherDesc,
        }),
        offenceOther,
      );
  }

  return {
    caseDto,
    formState: {
      emcStandardOptions,
      ...caseDetails,
      ...formState,
      auditMethod: formState?.auditMethod || auditDto.auditMethod,
      emcAuditType: formState === undefined ? !!caseDetails.emcStandardId : !!formState.emcStandardId,
      radioAuditType: formState === undefined ? !!caseDetails.serviceOfInterestId : !!formState.serviceOfInterestId,
      furtherAction: auditDto.furtherAction,
      furtherActionNotes: auditDto.furtherActionNotes,
      infringement: formState?.infringement || auditDto.infringement,
      offences,
      offenceOther,
      recommendation: auditDto.recommendation,
      recommendationNotes: auditDto.recommendationNotes,
    } as Partial<AuditFormProps>,
    setFormState,
  };
};

export const useCreateAuditApi = () => {
  const { createAuditApi } = useAuditApi();
  const convertToDto = useDtoConverter<CreateAuditDto>();

  return (values: AuditFormProps) => {
    const auditDto = convertToDto(values);
    auditDto.auditMethod = values.auditMethod;

    const { linkedCases, linkTypes = {}, linkedManagementRights } = values;

    if (linkedCases?.length) {
      const linkedCases = values.linkedCases.reduce(
        (output, current) => {
          const linkedCaseId = current.data.id;
          const linkType = linkTypes[`caseId_${linkedCaseId}`] || AuditLinkedCaseTypeEnum.SUBSIDIARY;
          return {
            ...output,
            [linkType]: [...output[linkType], linkedCaseId],
          };
        },
        {
          [AuditLinkedCaseTypeEnum.ORIGINATOR]: [],
          [AuditLinkedCaseTypeEnum.SUBSIDIARY]: [],
        },
      );

      auditDto.originatorCaseIds = linkedCases[AuditLinkedCaseTypeEnum.ORIGINATOR];
      auditDto.subsidiaryCaseIds = linkedCases[AuditLinkedCaseTypeEnum.SUBSIDIARY];
    }

    if (linkedManagementRights?.data?.id) {
      auditDto.linkedManagementRightIds = [linkedManagementRights?.data.id];
    }

    auditDto.startDate = values.startDate;
    return createAuditApi(auditDto);
  };
};

export const useInfringementFeeOptions = () => {
  const { getInfringementFees } = useAuditApi();
  const [infringementFeeOptions, setInfringementFeeOptions] = useState<DropdownOption<InfringementFeeDto>[]>([]);

  useEffect(() => {
    (async () => {
      const infringementFees = await getInfringementFees();

      setInfringementFeeOptions(
        infringementFees.map((infringementFee) => ({
          key: infringementFee.id || 0,
          text: `${infringementFee.type === InfringementFeeDtoTypeEnum.BODY_CORPORATE ? 'Body Corporate' : 'Individual'} - ${
            infringementFee.offenceShortDesc
          }`,
          data: infringementFee,
        })),
      );
    })();
  }, []);

  return infringementFeeOptions;
};

export const useMaintainAuditApi = () => {
  const { maintainAuditApi } = useAuditApi();
  const convertToDto = useDtoConverter<MaintainAuditDto>();

  return (values: AuditFormProps) => {
    const auditDto = convertToDto(values);
    auditDto.auditMethod = values.auditMethod;

    const { linkedCases, linkTypes, linkedManagementRights } = values;
    if (linkedCases?.length) {
      const linkedCases = values.linkedCases.reduce(
        (output, current) => {
          const linkedCaseId = current.data.id;
          const linkType = linkTypes[`caseId_${linkedCaseId}`] || AuditLinkedCaseTypeEnum.SUBSIDIARY;
          return {
            ...output,
            [linkType]: [...output[linkType], linkedCaseId],
          };
        },
        {
          [AuditLinkedCaseTypeEnum.ORIGINATOR]: [],
          [AuditLinkedCaseTypeEnum.SUBSIDIARY]: [],
        },
      );

      auditDto.originatorCaseIds = linkedCases[AuditLinkedCaseTypeEnum.ORIGINATOR];
      auditDto.subsidiaryCaseIds = linkedCases[AuditLinkedCaseTypeEnum.SUBSIDIARY];
    }

    if (linkedManagementRights?.data?.id) {
      auditDto.linkedManagementRightIds = [linkedManagementRights?.data.id];
    }

    return maintainAuditApi(auditDto);
  };
};

export const useCaseSearcherById = () => {
  const { searchCase } = useSearchApi();
  const resultParser = useCaseSearchResultParser();

  const [cases, setCases] = useState<SelectedTag<CaseSearchResult>[]>([]);

  const callSearchApi = async (caseNumbers: number[]) => {
    if (caseNumbers.length) {
      const { results = [] } = await searchCase({
        orderBy: 'clientName',
        caseNumbers,
      });

      setCases(results?.map(resultParser));
    }
  };

  return { cases, callSearchApi };
};

export const PRODUCT_OR_SUPPLIER_AUDIT = [CaseTypeCodeEnum.PRODUCT, CaseTypeCodeEnum.SUPPLIER];

const useValidateForm = (requiredFields: string[]) => {
  return (values: BaseCaseFormProps) => {
    const errors: { [key: string]: any } = requiredFields
      .filter((fieldName) => !(values as any)[fieldName])
      .reduce(
        (output, fieldName) => ({
          ...output,
          [fieldName]: REQUIRED_MESSAGE,
        }),
        {},
      );

    const { associatedToBy, client, licence } = values;
    if ((associatedToBy === AssociatedToByEnum.CLIENT && !client?.id) || (associatedToBy === AssociatedToByEnum.LICENCE && !licence?.id)) {
      errors['associatedTo'] = REQUIRED_MESSAGE;
    }

    return errors;
  };
};

export const CASE_TYPES_WITH_OPTIONAL_CLASSIFICATIONS = [CaseTypeCodeEnum.LICENCE_ENGINEERING, CaseTypeCodeEnum.OPERATOR];

export const useValidateAuditForm = () => {
  const validateCaseForm = useValidateForm(['caseTypeId', 'associatedToBy', 'allocatedTo']);

  const validateCanComplete = (values: AuditFormProps) => {
    const { caseTypeOptions, caseTypeId } = values;
    const selectedCaseTypeCode = caseTypeOptions?.find((option) => caseTypeId && option.key === caseTypeId)?.data.code;

    const errors: { [p: string]: any } = {};

    if (values.caseNumber) {
      if (selectedCaseTypeCode === CaseTypeCodeEnum.LICENCE && !values.auditMethod) {
        errors['auditMethod'] = REQUIRED_MESSAGE;
      } else if (selectedCaseTypeCode && PRODUCT_OR_SUPPLIER_AUDIT.includes(selectedCaseTypeCode) && !values.emcAuditType && !values.radioAuditType) {
        errors['auditType'] = 'One or both of the EMC/Radio checkboxes must be checked';
      }
    }
    return errors;
  };

  const validateForm = (values: AuditFormProps) => {
    const errors = validateCaseForm(values);

    const { caseTypeOptions, caseTypeId } = values;
    const selectedCaseTypeCode = caseTypeOptions?.find((option) => caseTypeId && option.key === caseTypeId)?.data.code;
    const isClassificationOptional = selectedCaseTypeCode && CASE_TYPES_WITH_OPTIONAL_CLASSIFICATIONS.includes(selectedCaseTypeCode);

    if (!isClassificationOptional && !values.caseClassificationId) {
      errors['caseClassificationId'] = REQUIRED_MESSAGE;
    }

    if (values.caseNumber) {
      if (!values.allocatedToUser) {
        errors['allocatedToUser'] = REQUIRED_MESSAGE;
      }

      if (selectedCaseTypeCode && PRODUCT_OR_SUPPLIER_AUDIT.includes(selectedCaseTypeCode)) {
        if (values.emcAuditType && !values.emcStandardId) {
          errors['emcStandardId'] = REQUIRED_MESSAGE;
        }

        if (values.radioAuditType && !values.serviceOfInterestId) {
          errors['serviceOfInterestId'] = REQUIRED_MESSAGE;
        }
      }
    }

    return errors;
  };

  return { validateForm, validateCanComplete };
};
