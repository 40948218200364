import { IBasePickerStyles, ITag, Label, TagPicker } from '@fluentui/react';
import { useField } from 'formik';
import * as React from 'react';

import { EquipmentDto } from 'api-client';

import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { useTheme } from 'common/theme/RRFTheme';
import { useReferenceDataHookApi } from 'licence_management/reference_data/ReferenceDataApiHook';

interface Props {
  onEquipmentSelected: (id: number) => void;
  required?: boolean;
  name: string;
}

const EquipmentSearch: React.FC<Props> = (props) => {
  const { searchEquipment } = useReferenceDataHookApi();
  const { semanticColors } = useTheme();
  // eslint-disable-next-line
  const [_, meta] = useField(props as any);
  const asEquipmentTag = ({ id, identifier, make, model }: EquipmentDto) => ({
    key: `${id}`,
    name: `${identifier}, ${make}, ${model}`,
  });
  const inputProps = {
    id: 'search-equipment-field',
    'aria-required': props.required,
    'aria-labelledby': 'search-equipment-label',
    placeholder: 'Equipment identifier, make or model',
  };
  const error = meta.touched && typeof meta.error === 'string';
  const styles: Partial<IBasePickerStyles> = error
    ? {
        text: { borderColor: semanticColors.errorText, backgroundColor: semanticColors.inputBackground },
      }
    : { text: { backgroundColor: semanticColors.inputBackground } };

  return (
    <div data-input-wrapper>
      <Label id="search-equipment-label" required={props.required}>
        Search equipment
      </Label>
      <TagPicker
        onResolveSuggestions={(searchText: string, _selectedItems?: ITag[]) => {
          return searchText !== ''
            ? searchEquipment({ searchText, pageSize: 20, includeOlderVersions: false, hidden: false }, { callerHandleErrors: true }).then(
                (searchResults) => (searchResults.results ? searchResults.results.map(asEquipmentTag) : []),
              )
            : [];
        }}
        inputProps={props.required ? { required: true, ...inputProps } : inputProps}
        onItemSelected={(item?: ITag) => {
          props.onEquipmentSelected(item ? +item.key : -1);
          return null;
        }}
        styles={styles}
        pickerSuggestionsProps={{
          loadingText: 'Searching for equipment',
          suggestionsHeaderText: 'Suggested equipment',
          noResultsFoundText: 'No equipment found',
        }}
      />
      <ErrorLabel {...props} />
    </div>
  );
};

export default EquipmentSearch;
