import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
import { FormikValues, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';
import Pagination from 'common/controls/items/Pagination';
import TooltipHint from 'common/controls/items/TooltipHint';
import DetailsList, { Column, SortProps } from 'common/controls/lists/DetailsList';
import MoreOrLess from 'common/controls/surfaces/MoreOrLess';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { searchResultsFocus } from 'search/utils';

import Form, { FormProps } from './index';

interface SearchResultsProps {
  page: number;
  pageSize: number;
  orderBy?: string;
  results?: any[];
  totalItems: number;
  totalPages: number;
}

interface QueryParam<T> {
  name: string;
  resolver: (values: T) => undefined | string | number | string[] | number[];
}

interface SearchFormProps<T> extends Pick<FormProps<T>, 'initialValues'> {
  children?: ReactNode;
  collapsible?: boolean;
  columns: Column[];
  excludeFromSorting?: string[];
  filtersSummary: (values: T) => string[];
  moreOptions?: ReactNode;
  onClear?: () => void;
  onParseQueryString: (queryParams: { [key: string]: string }) => Partial<T>;
  onSearch: (values: T) => Promise<any>;
  queryParams: (string | QueryParam<T>)[];
  searchBoxProps?: {
    hint?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
  };
  title: string;
  defaultSort?: SortProps;
}

const useSearchQueryParams = <T extends FormikValues>() => {
  const history = useHistory();
  const location = useLocation();

  return {
    buildQueryParams: (formValues: T, queryParams: (string | QueryParam<T>)[]) => {
      if (isEmpty(formValues)) {
        return;
      }

      let queryString = queryParams
        .map((queryParam) => {
          if (typeof queryParam === 'string') {
            return { key: queryParam, value: formValues[queryParam] };
          }

          return { key: queryParam.name, value: queryParam.resolver(formValues) };
        })
        .filter(({ value }) => (Array.isArray(value) ? value.length : value))
        .map(({ key, value }) => {
          return `${key}=${Array.isArray(value) ? value.join(',') : value}`;
        })
        .join('&');

      const { page = 1, pageSize = 20 } = formValues;
      history.push({
        search: `?${queryString}${queryString ? '&' : ''}page=${page}&pageSize=${pageSize}`,
      });
    },
    parseQueryParams: () => {
      const searchParams = new URLSearchParams(location.search);
      const queryParams: { [key: string]: string } = {};

      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
      }

      return queryParams;
    },
  };
};

interface IPagination extends FormikValues {
  page?: number;
  pageSize?: number;
}

const SearchResults = <T extends IPagination>(props: SearchFormProps<T>) => {
  const {
    defaultSort = {
      sortKey: 'id',
      descending: true,
    },
  } = props;

  const formikHelpers = useFormikContext<T>();
  const { initialValues, isSubmitting, setSubmitting, values } = formikHelpers;

  const history = useHistory();
  const [sortProps, setSortProps] = useState<SortProps>(defaultSort);
  const [searchResults, setSearchResults] = useState<SearchResultsProps>();
  const [selectedFilters, setSelectedFilters] = useState<T>();

  const { buildQueryParams, parseQueryParams } = useSearchQueryParams<T>();

  useEffect(() => {
    if (isSubmitting) {
      callSearchApi(values);
    }
  }, [isSubmitting]);

  const { page: queryPage = 1, pageSize: queryPageSize = 20, ...queryParams } = parseQueryParams();
  const initialFormValues = {
    ...props.onParseQueryString(queryParams),
    page: queryPage,
    pageSize: queryPageSize,
  } as T;

  useEffect(() => {
    if (history.location.search) {
      formikHelpers.setValues({ ...initialValues, ...initialFormValues });
    }
  }, [initialValues]);

  useEffect(() => {
    if (history.location.search) {
      callSearchApi(initialFormValues);
    }
  }, []);

  useEffect(() => {
    if (history.location.search && history.action === 'POP') {
      callSearchApi(initialFormValues, true);
    }
  }, [history.location]);

  const callSearchApi = (values: T, isGoBackPrevPage?: boolean) => {
    setSelectedFilters(values);

    !isGoBackPrevPage && buildQueryParams(values, props.queryParams);
    const orderBy = values.orderBy?.split(' ');
    orderBy &&
      setSortProps({
        sortKey: orderBy[0],
        descending: orderBy[1] === 'desc',
      });

    props
      .onSearch(values)
      .then((searchResults) => {
        setSearchResults(searchResults);
        searchResultsFocus();
      })
      .finally(() => setSubmitting(false));
  };

  const { page = 1, pageSize = 20, results = [], totalItems = 0, totalPages = 1, orderBy } = searchResults || {};
  return (
    <>
      <SectionHeading title="Search results" />
      <Pagination
        filtersSummary={props.filtersSummary(selectedFilters || ({} as T))}
        onPaging={(page: number, pageSize: number) => callSearchApi({ ...values, page, pageSize, orderBy })}
        page={page}
        pageSize={pageSize}
        renderResults={() => (
          <DetailsList
            columns={props.columns}
            items={results}
            onSort={(sortBy) => {
              if (!props.excludeFromSorting || props.excludeFromSorting.indexOf(sortBy.sortKey) < 0) {
                callSearchApi({
                  ...values,
                  orderBy: `${sortBy.sortKey} ${sortBy.descending ? 'desc' : 'asc'} `,
                });
              }
            }}
            sortProps={sortProps}
          />
        )}
        totalItems={totalItems}
        totalPages={totalPages}
      />
    </>
  );
};

const SearchFormContent = <T extends FormikValues>(props: SearchFormProps<T>) => {
  const { collapsible, moreOptions, searchBoxProps, title } = props;

  const { initialValues, resetForm, submitForm } = useFormikContext<T>();
  const history = useHistory();

  return (
    <div>
      <SectionHeading title={title} />

      <Grid>
        <Grid.Row>
          <Grid.Col lg={5}>
            <TextField
              label={searchBoxProps?.label ?? 'Search'}
              name="searchText"
              placeholder={searchBoxProps?.placeholder ?? 'Search'}
              maxLength={200}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  submitForm();
                }
              }}
              required={searchBoxProps?.required}
            />
          </Grid.Col>
          <Grid.Col lg={1}>
            {searchBoxProps?.hint && (
              <>
                <Label>&nbsp;</Label>
                <TooltipHint id="search-box-tooltip-hint" content={searchBoxProps!.hint} />
              </>
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>{props.children}</Grid.Col>
        </Grid.Row>
        {moreOptions && (
          <Grid.Row>
            <Grid.Col>{collapsible ? <MoreOrLess more={moreOptions} moreText="More Options" lessText="Less Options" /> : moreOptions}</Grid.Col>
          </Grid.Row>
        )}
        <Grid.Row style={{ paddingTop: '10px', float: 'right' }}>
          <Grid.Col>
            <DefaultButton
              text="Clear"
              onClick={() => {
                if (isEmpty(initialValues)) {
                  history.replace(window.location.pathname);
                }

                resetForm({ values: initialValues });
                submitForm();

                props.onClear && props.onClear();
              }}
            />

            <PrimaryButton
              style={{ marginLeft: 10 }}
              text="Search"
              id="search-button"
              data-automation-id="search-button"
              onClick={() => {
                submitForm();
              }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <SearchResults<T> {...props} />
    </div>
  );
};

const SearchForm = <T extends FormikValues>(props: SearchFormProps<T>) => {
  return (
    <Form<T> formButtonsOptions={{ hideSubmitButton: true }} mode="SEARCH" id="searchForm" {...props}>
      <SearchFormContent {...props} />
    </Form>
  );
};

export default SearchForm;
