import { useField } from 'formik';
import * as React from 'react';

import ReadonlyData from '../items/ReadonlyData';

interface ReadOnlyFieldProps {
  label?: string;
  name: string;
  value?: any;
  renderFormat?: (value: any) => any;
  emptyFormat?: any;
  /**
   * `_blank` Opens the linked document in a new window or tab
   * `_self` Opens the linked document in the same frame as it was clicked (this is default)
   * `_parent` Opens the linked document in the parent frame
   * `_top` Opens the linked document in the full body of the window
   **/
  target?: string;
  /**  URL the link points to **/
  href?: string;
  indicateRequired?: boolean;
  hideLabel?: boolean;
}

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [field, _, fieldHelper] = useField(props.name);

  React.useEffect(() => {
    if (props.value) {
      fieldHelper.setValue(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <ReadonlyData
      {...props}
      label={props.label ?? ''}
      value={field.value}
      id={props.name}
      renderFormat={props.renderFormat}
      emptyFormat={props.emptyFormat}
    />
  );
};

export const renderAsOptions = (options?: { key: any; text: any }[]) => (value: any | any[]) => {
  if (!options) {
    return value;
  }

  return options
    .filter((opt) => (value instanceof Array ? isInValues(opt, value) : opt.key === value))
    .map((opt) => opt.text)
    .join(', ');
};

const isInValues = (option: { key: any; text: any }, value: any[]) => value.find((v) => v === option.key);

export default ReadOnlyField;
