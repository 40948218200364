import { DetailsRow, IDetailsRowProps, IRenderFunction } from '@fluentui/react';
import * as React from 'react';

import { useTheme } from '../../theme/RRFTheme';

const renderErrorRow: IRenderFunction<IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }> = (props) =>
  props ? <ErrorRow {...props} /> : null;

const ErrorRow = (props: IDetailsRowProps & { onRenderRow?: IRenderFunction<IDetailsRowProps> }) => {
  const { semanticColors } = useTheme();
  const styledProps: IDetailsRowProps = {
    ...props,
    styles: {
      root: {
        background: props.selection.isIndexSelected(props.itemIndex)
          ? semanticColors.detailsListErrorRowSelected
          : semanticColors.detailsListErrorRow,
      },
    },
  };

  return (
    <div data-row-error data-row-error-index={props.itemIndex}>
      {' '}
      {props.onRenderRow ? props.onRenderRow(styledProps) : <DetailsRow {...styledProps} />}{' '}
    </div>
  );
};

export default renderErrorRow;
