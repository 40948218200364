import React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';

import WelcomeTextMaintenancePage from './WelcomeTextMaintenancePage';

const WelcomeTextRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/maintenance`} component={WelcomeTextMaintenancePage} role={['ROLE_SYSTEM_ADMINISTRATOR']} />
      </Switch>
    </>
  );
};

export const useWelcomeTextNavigation = () => {
  const history = useHistory();

  return {
    navigateToWelcomeTextMaintenance: () => history.push('/ui/app/welcome-text/maintenance'),
  };
};

export default WelcomeTextRouter;
