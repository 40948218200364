import * as React from 'react';

import { LocationSearchResult } from 'api-client';

import SearchLocation from './SearchLocation';
import { LocationSearchOptions } from './SearchLocationForm';

interface Props {
  id?: string;
  onSelect?: (item: LocationSearchResult) => void;
  searchLabel: string;
  searchOptions?: LocationSearchOptions;
  excludedIds?: any[];
  defaultLocationType: string;
}

const EmbeddedSearchLocation: React.FC<Props> = (props) => {
  const defaultValues: any = {
    locationType: props.defaultLocationType,
    hidden: 'false',
    excludedIds: props.excludedIds,
  };

  return <SearchLocation {...props} defaultSearchCriteria={defaultValues} initialSearchCriteria={defaultValues} mode={'EMBEDDED'} />;
};

export default EmbeddedSearchLocation;
