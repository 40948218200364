import { useFormikContext } from 'formik';

import { Grid } from 'ui-library';

import MultiSelectDropdown from 'common/controls/inputs/MultiSelectDropdown';
import { TextField } from 'common/controls/inputs/TextField';
import ReadonlyData from 'common/controls/items/ReadonlyData';

import { offices } from '../locationSupportData';

interface HeaderInformationProps {
  type: string;
  districtsRequired?: boolean;
}

const HeaderInformation = (props: HeaderInformationProps) => {
  const { values } = useFormikContext<any>();
  return (
    <>
      <Grid.Row>
        {values.id && (
          <Grid.Col sm={12} lg={5}>
            <TextField label={'Location ID'} name={'id'} readOnly />
          </Grid.Col>
        )}
        <Grid.Col sm={12} lg={5}>
          <ReadonlyData label={'Location type'} value={props.type} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <TextField label="Location name" name={'locationName'} required maxLength={200} />
        </Grid.Col>
        <Grid.Col sm={12} lg={5}>
          <MultiSelectDropdown
            name="districts"
            label="District(s)"
            options={offices}
            required={props.districtsRequired}
            readOnly={!props.districtsRequired}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default HeaderInformation;
