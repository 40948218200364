import * as yup from 'yup';

import { LicenceClassificationType, LicenceDtoLicenceTypeCodeEnum, LicenceRadiationPatternDto } from 'api-client';

import { getValueByPath } from 'common/utils/objectUtils';
import { isEmpty, numberSchema, requiredNumber } from 'common/validation/yupUtils';

import { asNumber } from '../../../../common/utils/numberUtils';

const C = LicenceClassificationType.C;
const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;

export const requiresHtlRadiationPatterns = (props?: { licenceTypeCode?: any; classification?: any }) =>
  props && props.licenceTypeCode === SPL && props.classification !== C;

const radiationPatterns = (testCtx: yup.TestContext): LicenceRadiationPatternDto[] => {
  // e.g. radiationPatterns.horizontal[0].bearingFrom -> radiationPatterns.horizontal
  const path = testCtx.path.split(/\[[\d]+]/)[0];
  return getValueByPath(testCtx.options.context, path) ?? [];
};

const valueSchema = (maxPower: number = 100) =>
  numberSchema
    .min(-99, `Must be between -99.0 and ${maxPower.toFixed(1)}`)
    .max(maxPower, `Must be between -99.0 and ${maxPower.toFixed(1)}`)
    .test('adjacent-not-same', 'No two adjacent sectors can contain the same power value (dBW eirp)', function (this: yup.TestContext, value) {
      const sectors = radiationPatterns(this);
      const idx = sectors.findIndex((sector) => sector.bearingFrom === this.parent.bearingFrom);
      return !idx || isEmpty(value) || isEmpty(sectors[idx - 1].value) || +value! !== +sectors[idx - 1].value;
    });
const htlBearingToSchema = numberSchema
  .min(0, 'Must be between 0.0 and 360.0')
  .max(360, 'Must be between 0.0 and 360.0')
  .test('min-bearing-to', 'Must be greater than the previous Bearing (To)', function (this: yup.TestContext, bearingTo) {
    return isEmpty(bearingTo) || +bearingTo! > +this.parent.bearingFrom;
  });

const htlRadiationPatternSchema = (maxPower?: number) =>
  yup.object().shape({
    bearingTo: htlBearingToSchema.when(['$context.licenceTypeCode', '$context.classification'], {
      is: (licenceTypeCode, classification) => requiresHtlRadiationPatterns({ licenceTypeCode, classification }),
      then: numberSchema.test('maybe-required-first-row', 'Required', function (this: yup.TestContext, bearingTo) {
        const [first] = radiationPatterns(this);
        const isFirst = first.bearingFrom === this.parent.bearingFrom;
        return isFirst ? isEmpty(this.parent.value) || !isEmpty(bearingTo) : !isEmpty(bearingTo);
      }),
      otherwise: requiredNumber,
    }),
    value: valueSchema(maxPower).when(['$context.licenceTypeCode', '$context.classification'], {
      is: (licenceTypeCode, classification) => requiresHtlRadiationPatterns({ licenceTypeCode, classification }),
      then: numberSchema.test('maybe-required-first-row', 'Required', function (this: yup.TestContext, value) {
        const [first] = radiationPatterns(this);
        const isFirst = first.bearingFrom === this.parent.bearingFrom;
        return isFirst ? isEmpty(this.parent.bearingTo) || !isEmpty(value) : !isEmpty(value);
      }),
      otherwise: requiredNumber,
    }),
  });

const htlRadiationPatternDoneSchema = (maxPower?: number) =>
  yup.object().shape({
    bearingTo: htlBearingToSchema
      .required('Required')
      .test('last-bearing-to-eq-360', 'The last Bearing (To) must be 360.0 degrees', function (this: yup.TestContext, bearingTo) {
        const last = [...radiationPatterns(this)].pop()!;
        return last.bearingFrom !== this.parent.bearingFrom || asNumber(bearingTo) === 360;
      }),
    value: valueSchema(maxPower).required('Required'),
  });

const vtlBearingToSchema = numberSchema
  .min(-90, 'Must be between 90.0 and -90.0')
  .max(90, 'Must be between 90.0 and -90.0')
  .test('max-bearing-to', 'Must be less than the previous Bearing (To)', function (this: yup.TestContext, bearingTo) {
    return isEmpty(bearingTo) || +bearingTo! < +this.parent.bearingFrom;
  });

const vtlRadiationPatternSchema = (maxPower?: number) =>
  yup.object().shape({
    bearingTo: vtlBearingToSchema.required('Required'),
    value: valueSchema(maxPower).required('Required'),
  });

const vtlRadiationPatternDoneSchema = (maxPower?: number) =>
  yup.object().shape({
    bearingTo: vtlBearingToSchema.test(
      'last-bearing-to-eq-360',
      'The last Bearing (To) must be 0.0 or -90.0 degrees',
      function (this: yup.TestContext, bearingTo) {
        bearingTo = asNumber(bearingTo) as any;
        /*if (!this.options.context || !this.path.match(/RadiationPatterns/)) {
            return true;
        }*/
        const last = [...radiationPatterns(this)].pop()!;
        return last.bearingFrom !== this.parent.bearingFrom || bearingTo === 0 || bearingTo === -90;
      },
    ),
    value: valueSchema(maxPower),
  });

export const radiationPatternsSchema = (maxPower?: number) =>
  yup.object().shape({
    radiationPatterns: radiationPatternsSaveSchema(maxPower),
  });

export const radiationPatternsSaveSchema = (maxPower?: number) =>
  yup.object().shape({
    horizontal: yup.array().of(htlRadiationPatternSchema(maxPower)),
    vertical: yup.array().of(vtlRadiationPatternSchema(maxPower)),
  });

export const htlRadiationPatternsDoneSchema = (maxPower?: number) =>
  yup
    .array()
    .of(htlRadiationPatternDoneSchema(maxPower))
    .nullable(true)
    .when(['$context.licenceTypeCode', '$context.classification'], {
      is: (licenceTypeCode, classification) => requiresHtlRadiationPatterns({ licenceTypeCode, classification }),
      then: yup.array().min(1, 'A horizontal radiation pattern is mandatory for this type of licence'),
    })
    .test(
      'min-1-is-max-power',
      'At least one of the sectors must contain the maximum proposed power value (dBW eirp)',
      (sectors) => maxPower === undefined || !sectors?.length || !!sectors.find((sector) => asNumber(sector?.value) === maxPower),
    );

export const vtlRadiationPatternsDoneSchema = (maxPower?: number) =>
  yup
    .array()
    .of(vtlRadiationPatternDoneSchema(maxPower))
    .nullable(true)
    .test(
      'min-1-is-max-power',
      'At least one of the sectors must contain the maximum proposed power value (dBW eirp)',
      (sectors) => maxPower === undefined || !sectors?.length || !!sectors.find((sector) => asNumber(sector?.value) === maxPower),
    );

export const radiationPatternsDoneSchema = (maxPower?: number) =>
  yup.object().shape({
    horizontal: htlRadiationPatternsDoneSchema(maxPower),
    vertical: vtlRadiationPatternsDoneSchema(maxPower),
  });
