import { Formik, FormikHelpers, FormikState } from 'formik';
import * as React from 'react';
import { useState } from 'react';

import { SpectrumSearchCriteria, SpectrumSearchResults } from 'api-client';

import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import { validateWithFormValues } from 'common/validation/validationWithFormValues';
import { useSearchApi } from 'search/SearchApiHooks';
import { parseQueryString, searchResultsFocus } from 'search/utils';

import { useLicenceReferenceDataNavigator } from '../../reference_data/ReferenceDataRouter';
import SearchSpectrumRecordForm from './SearchSpectrumRecordForm';
import SearchSpectrumRecordResults from './SearchSpectrumRecordResults';
import { searchSpectrumValidationSchema } from './validationSchema';

const SearchSpectrumRecordPage: React.FC = () => {
  const { isInternalUser } = useAuth();
  const [searchCriteria, setSearchCriteria] = useState<SpectrumSearchCriteria>();
  const [searchResults, setSearchResults] = useState<SpectrumSearchResults>();
  const { searchSpectrum } = useSearchApi();
  const { navigateToSearchSpectrum } = useLicenceReferenceDataNavigator();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [labelChannel, setLabelChannel] = useState<string>('Band/Channel');
  const [showITUServiceType, setITUShowServiceType] = useState<boolean>(false);

  const defaultValues = {
    visibility: 'Current',
  };
  const initialValues = parseQueryString(defaultValues);

  React.useEffect(() => {
    if (initialValues.spectrumType === 'ITU Allocation') {
      setITUShowServiceType(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (formValues: any) => {
    formValues = { ...formValues };
    if (!isInternalUser) {
      formValues = { ...formValues, hidden: false };
    }
    navigateToSearchSpectrum(formValues, true);

    return searchSpectrum(formValues, { showLoadingSpinner: 'Searching...' }).then((searchResults) => {
      setSearchCriteria({
        ...formValues,
        orderBy: searchResults.orderBy ?? formValues.orderBy,
      });
      setSearchResults(searchResults);

      if (formValues.spectrumType === 'Band' || formValues.spectrumType === 'Channel') {
        setLabelChannel(formValues.spectrumType === 'Band' ? 'Band' : 'Channel');
      } else {
        setLabelChannel('Band/Channel');
      }

      setSubmitted(true);
      searchResultsFocus();
      return searchResults;
    });
  };

  const handlePaging = (page: number, pageSize: number) => {
    let paging = searchCriteria ?? {};
    paging.page = page;
    paging.pageSize = pageSize;
    navigateToSearchSpectrum(paging, true);
  };

  const handleOrderBy = (orderBy: string) => {
    let sort = searchCriteria ?? {};
    sort.orderBy = orderBy;
    navigateToSearchSpectrum(sort, true);
  };

  const renderForm = (helper: FormikHelpers<any> & FormikState<any>) => {
    const handleSpectrumTypeChange = (key: any) => {
      if (key === 'ITU Allocation') {
        setITUShowServiceType(true);
      } else {
        helper.setFieldValue('ituServiceId', undefined);
        setITUShowServiceType(false);
      }
    };

    const handleClear = () => {
      setSearchCriteria(defaultValues as any);
      setSearchResults(undefined);
      setSubmitted(false);
      setITUShowServiceType(false);
    };

    return (
      <>
        <SearchSpectrumRecordForm
          onClear={handleClear}
          onSpectrumTypeChange={handleSpectrumTypeChange}
          showITUServiceType={showITUServiceType}
          showAddSpectrumButton={submitted}
        />
      </>
    );
  };
  return (
    <Page title="Search Spectrum">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateWithFormValues(searchSpectrumValidationSchema)}
        enableReinitialize
        validateOnBlur
      >
        {renderForm}
      </Formik>
      {submitted && (
        <SearchSpectrumRecordResults
          searchCriteria={searchCriteria}
          searchResults={searchResults}
          onPaging={handlePaging}
          onOrderBy={handleOrderBy}
          submitted={submitted}
          labelChannel={labelChannel}
        />
      )}
    </Page>
  );
};

export default SearchSpectrumRecordPage;
