import React from 'react';

import { Dropdown, Grid2 } from 'ui-library';

import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { COMPLIANCE_CHECK_RESULT_OPTIONS } from 'common/reference/referenceData';
import { formatDate } from 'common/utils/dateUtils';

const AuditComplianceSection = () => {
  return (
    <>
      <SectionHeading title="Compliance details" />
      <Grid2>
        <Grid2.Col lg={3}>
          <ReadOnlyField name="complianceDate" label="Date" renderFormat={formatDate} />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <Dropdown name="complianceCheckResult" label="Compliance check result" options={COMPLIANCE_CHECK_RESULT_OPTIONS} readOnly />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <ReadOnlyField name="complianceNotes" label="Notes" />
        </Grid2.Col>
      </Grid2>
    </>
  );
};

export default AuditComplianceSection;
