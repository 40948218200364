import { MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { GeoreferenceDto, GeoreferenceGroupDto } from 'api-client';

import { Form } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { getPreferenceGeoReferenceType } from 'common/user/components/UserPreference';
import { validateWithFormValues } from 'common/validation/validationWithFormValues';

import GeoReferenceTypeContent from './components/GeoReferenceTypeContent';
import { useGeoRefTypeConverter } from './hooks';
import { GeoReferenceSchema } from './validationSchema';

export interface GeoReferenceProps {
  georeference: GeoreferenceDto;
  geoReferenceGroup?: GeoreferenceGroupDto;
}

const GeoRefTypeConverterForm = () => {
  const { convertGeoRefType } = useGeoRefTypeConverter();
  const { setPageInstruction } = usePage();
  const [geoReference, setGeoReference] = useState<GeoReferenceProps>();
  const { userPreference } = useAuth();
  const geoRefTypeUserPreference = getPreferenceGeoReferenceType(userPreference?.otherPreference!);

  useEffect(() => {
    setGeoReference({
      georeference: { type: geoRefTypeUserPreference, easting: '', northing: '', mapNumber: undefined },
      geoReferenceGroup: undefined,
    });
  }, []);

  return (
    <Form<GeoReferenceProps>
      id="ref-type-form"
      formButtonsOptions={{
        submitButtonText: 'Convert',

        additionalButtons: [
          {
            id: 'clear-results',
            text: 'Clear',
            onClick: (formikContext) => {
              formikContext.resetForm();
            },
          },
        ],
      }}
      submittingSpinnerLabel="Converting..."
      showFormButtonsBottom={false}
      handleSubmit={(values, formikHelpers) => {
        convertGeoRefType(values.georeference)
          .then((response) => {
            formikHelpers.setValues({ ...values, geoReferenceGroup: response });
            setPageInstruction(<span>Converted successfully.</span>, MessageBarType.success);
          })
          .finally(() => formikHelpers.setSubmitting(false));
      }}
      initialValues={geoReference}
      mode={'CREATE'}
      validate={validateWithFormValues(GeoReferenceSchema)}
    >
      <GeoReferenceTypeContent />
    </Form>
  );
};

const GeoReferenceTypeConverterPage = () => {
  return (
    <Page title="New Zealand Grid Reference Converter">
      <GeoRefTypeConverterForm />
    </Page>
  );
};

export default GeoReferenceTypeConverterPage;
