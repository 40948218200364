import { ContextualMenu, IconButton, IDragOptions, IIconProps, mergeStyles, Modal } from '@fluentui/react';

import { FeatureCollection } from 'api-client';

import LocationSearchMap from 'search/location/LocationSearchMap';

const dragOptions: IDragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

interface ModalMapProps {
  isModeless?: boolean;
  isOpen: boolean;
  closeModalMap: () => void;
  pointsToDisplay?: FeatureCollection;
}

const styleModalMap = mergeStyles({
  padding: '10px',
});

const ModalMap = ({ isModeless, isOpen, pointsToDisplay, closeModalMap }: ModalMapProps) => {
  return (
    <Modal titleAriaId={'Text Show Title'} isOpen={isOpen} isModeless={false} dragOptions={dragOptions} containerClassName={styleModalMap}>
      <div>
        <span id={'modalMapTitle'}>&nbsp;</span>
        <IconButton iconProps={cancelIcon} ariaLabel="Close Map" onClick={closeModalMap} style={{ float: 'right' }} />
      </div>
      <div style={{ marginTop: '10px' }}>
        <LocationSearchMap searchData={pointsToDisplay} />
      </div>
    </Modal>
  );
};

export default ModalMap;
