import { Formik } from 'formik';
import React from 'react';

import { EquipmentDto } from 'api-client';

import Form from '../../../common/form/Form';
import Page from '../../../common/layout/Page';
import { useReferenceDataHookApi } from '../ReferenceDataApiHook';
import { useLicenceReferenceDataNavigator } from '../ReferenceDataRouter';
import EquipmentFields from './EquipmentFields';
import { equipmentSchema } from './validationSchema';

const CreateEquipmentPage = () => {
  const { createEquipment } = useReferenceDataHookApi();
  const { navigateToMaintainEquipment } = useLicenceReferenceDataNavigator();

  const formInitValue: EquipmentDto = {
    make: '',
    model: '',
    band: '',
  };
  const submitForm = (values: any) =>
    createEquipment(
      { ...values, make: values.make?.toUpperCase(), model: values.model?.toUpperCase(), band: values.band?.toUpperCase() } as EquipmentDto,
      {
        successMessage: 'Equipment has been created.',
      },
    ).then((eqp) => navigateToMaintainEquipment(eqp.id!));

  return (
    <Page title="Create Equipment">
      <Formik initialValues={formInitValue} onSubmit={submitForm} validationSchema={equipmentSchema}>
        <Form id={'create-equipment-form'} showFormButtonsBottom mode="CREATE">
          <EquipmentFields />
        </Form>
      </Formik>
    </Page>
  );
};

export default CreateEquipmentPage;
