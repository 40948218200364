import { MessageBarType } from '@fluentui/react';

import { FeatureFlagDto, OutageNotificationDto, RolesUserDto, UserPreferencesDto } from 'api-client';

import { defaultUserPreference } from 'common/user/components/UserPreference';

import { ApiConfig } from './common/api/ApiHook';
import { featureFlagApi, outageNotificationApi, securityApi } from './common/api/api';
import { Permission } from './common/auth/Permissions';
import { Role } from './common/auth/Roles';
import { getDecodedJWTFromLocalStorage, Jwt, permissionsFromJwt } from './common/auth/jwtUtils';
import { ModalContextType } from './common/controls/surfaces/ModalContextType';
import { parseBoolean } from './common/utils/objectUtils';

export interface StoreState {
  loginState: LoginState;
  pageState: PageState;
}

export interface LoginState {
  loggedIn?: boolean;
  permissions?: Permission[];
  username?: string;
  isInternalUser?: boolean;
  myUserId?: number;
  currentClientId?: number;
  isKeyClient?: boolean;
  firstName?: string;
  lastName?: string;
  currentRole?: Role;
  clientNameProfile?: string;
  authorityType?: string;
  adminAccess?: boolean;
  myRolesAvailables?: RolesUserDto[];
  refreshJWT?: (newJwt: Jwt) => void;
  agreedViewSuppressed?: boolean;
  userPreference?: UserPreferencesDto;
  isLocked?: boolean;
  featureFlags?: FeatureFlagDto[];
}

export interface PageState {
  isLoading?: boolean | string;
  showMobileNav?: boolean;
  pageInstruction?: {
    message?: string | JSX.Element;
    type?: MessageBarType;
    noExpiry?: boolean; // by default success message expires. Set to true for the message to not disappear.
  };
  warnings?: string[];
  showWarningModal?: boolean;
  modalContext?: ModalContextType;
  outageNotificationsState: OutageNotificationDto[];
}

export function createStoreState(
  myRolesAvailables: RolesUserDto[],
  outageNotificationsState: OutageNotificationDto[],
  agreedViewSuppressed?: boolean,
  userPreference?: UserPreferencesDto,
  featureFlags?: FeatureFlagDto[],
): StoreState {
  const decodedJwt = getDecodedJWTFromLocalStorage();
  return {
    loginState: {
      ...parseLoginStateFromJwt(decodedJwt),
      myRolesAvailables: myRolesAvailables,
      agreedViewSuppressed,
      userPreference,
      featureFlags,
    },
    pageState: { outageNotificationsState },
  };
}

export const parseLoginStateFromJwt = (decodedJwt: Jwt | null) => {
  const isInternal = decodedJwt?.utp === 'INT';
  const userId = decodedJwt?.uid;
  const clientId = decodedJwt?.cid;
  const isKeyClient = decodedJwt?.cik;
  const currentRole = decodedJwt?.rnp;
  const clientNameProfile = decodedJwt?.cpn;
  const authorityTye = decodedJwt?.at;
  const adminAccess = decodedJwt?.aa;
  const firstname = decodedJwt?.fn;
  const lastName = decodedJwt?.ln;
  const isLocked = decodedJwt?.ul;

  return {
    loggedIn: decodedJwt !== null,
    permissions: permissionsFromJwt(decodedJwt),
    isInternalUser: isInternal,
    myUserId: userId,
    currentClientId: clientId,
    isKeyClient: isKeyClient,
    firstName: firstname,
    lastName: lastName,
    currentRole: currentRole as Role,
    clientNameProfile: clientNameProfile,
    authorityType: authorityTye,
    adminAccess: adminAccess,
    isLocked: isLocked,
  };
};

export async function initialiseStoreState(): Promise<StoreState> {
  let myRolesAvailables: RolesUserDto[] = [];
  let up: UserPreferencesDto | undefined = undefined;
  let featureFlags: FeatureFlagDto[] | undefined = undefined;
  const decodedJwt = getDecodedJWTFromLocalStorage();
  if (decodedJwt?.uid) {
    const config: ApiConfig = { disableErrorMessage: true, showSuccessMessage: false, callerHandleErrors: true };
    try {
      await securityApi.rolesByUser(config).then((response) => response.data.forEach((x) => myRolesAvailables.push(x)));
      const userPreference = await securityApi.getMyPreferences();
      up = userPreference.data?.otherPreference ? userPreference.data : defaultUserPreference;
    } catch {
      //ignore
    }
  } else {
    localStorage.removeItem(AGREED_VIEW_SUPPRESSED_STORAGE_KEY);
  }

  try {
    featureFlags = (await featureFlagApi.getFeatureFlags()).data;
  } catch {
    // ignore
  }

  const agreeViewSuppressed = parseBoolean(localStorage.getItem(AGREED_VIEW_SUPPRESSED_STORAGE_KEY));

  // PUBLIC ACCESS for Outage Notifications
  let outageNotif: OutageNotificationDto[] = [];
  try {
    outageNotif = await outageNotificationApi.getPublishedOutageNotifications().then((response) => response.data);
  } catch (err) {
    console.error(err);
  }

  return Promise.resolve(createStoreState(myRolesAvailables, outageNotif, agreeViewSuppressed, up, featureFlags));
}

export const AGREED_VIEW_SUPPRESSED_STORAGE_KEY = 'rrf-agreedViewSuppressed';
