import {
  AntennaSearchCriteria,
  AntennaSearchResults,
  ApplicationFeeSearchCriteria,
  CaseSearchCriteria,
  CaseSearchResults,
  ClientSearchCriteria,
  ClientSearchResults,
  DocumentDeliveryLogSearchCriteria,
  DocumentDeliveryLogSearchResults,
  LicenceSearchCriteria,
  LicenceSearchResults,
  LicenceToSupplySearchCriteria,
  LicenceToSupplySearchResults,
  LightweightRenewalFeeSearchResults,
  LocationSearchCriteria,
  ManagementRightsSearchCriteria,
  NotificationSearchCriteria,
  NotificationSearchResults,
  RenewalFeeSearchCriteria,
  RenewalFeeSearchResults,
  SearchMortgageCaveatByLicenceCriteria,
  SearchMortgageCaveatByManagementRightCriteria,
  SpectrumSearchCriteria,
  TaskSearchCriteria,
} from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { licenceApi, licenceToSupplyApi, searchApi } from 'common/api/api';

export const useSearchApi = () => {
  const { aroundApi } = useApi();

  return {
    searchCase: (criteria: CaseSearchCriteria, config?: ApiConfig): Promise<CaseSearchResults> =>
      aroundApi(searchApi.searchCase(criteria), { ...config }),
    searchClient: (criteria: ClientSearchCriteria, config?: ApiConfig): Promise<ClientSearchResults> =>
      aroundApi(searchApi.searchClient(criteria), config),
    searchActiveClient: (criteria: ClientSearchCriteria, config?: ApiConfig): Promise<ClientSearchResults> =>
      aroundApi(searchApi.searchActiveClient(criteria), config),
    searchAntenna: (criteria: AntennaSearchCriteria, config?: ApiConfig): Promise<AntennaSearchResults> =>
      aroundApi(licenceApi.searchAntenna(criteria), config),
    searchLicence: (criteria: LicenceSearchCriteria, config?: ApiConfig): Promise<LicenceSearchResults> =>
      aroundApi(searchApi.searchLicence(criteria), config),
    searchLicenceToSupply: (criteria: LicenceToSupplySearchCriteria, config?: ApiConfig): Promise<LicenceToSupplySearchResults> =>
      aroundApi(licenceToSupplyApi.searchLicenceToSupply(criteria), config),
    searchLicenceToSupplyPublic: (criteria: LicenceToSupplySearchCriteria, config?: ApiConfig): Promise<LicenceToSupplySearchResults> =>
      aroundApi(licenceToSupplyApi.searchLicenceToSupplyPublic(criteria), config),
    searchSpectrum: (criteria: SpectrumSearchCriteria, config?: ApiConfig) => aroundApi(searchApi.searchSpectrum(criteria), config),
    incrementalSearchSpectrum: (criteria: SpectrumSearchCriteria, config?: ApiConfig) =>
      aroundApi(searchApi.incrementalSearchSpectrum(criteria), config),
    searchLocation: (criteria: LocationSearchCriteria, config?: ApiConfig) => aroundApi(searchApi.searchLocation(criteria), config),
    searchManagementRightsForMortgage: (criteria: ManagementRightsSearchCriteria, config?: ApiConfig) =>
      aroundApi(searchApi.searchManagementRightsForMortgage(criteria), config),
    searchLicenceForMortgage: (criteria: LicenceSearchCriteria, config?: ApiConfig) =>
      aroundApi(searchApi.searchLicenceForMortgage(criteria), config),
    searchMortgageCaveatWithLicence: (criteria: SearchMortgageCaveatByLicenceCriteria, config?: ApiConfig) =>
      aroundApi(searchApi.searchMortgageCaveatWithLicence(criteria), config),
    searchMortgageCaveatWithManagementRight: (criteria: SearchMortgageCaveatByManagementRightCriteria, config?: ApiConfig) =>
      aroundApi(searchApi.searchMortgageCaveatWithManagementRight(criteria), config),
    searchDocumentDeliveryLog: (criteria: DocumentDeliveryLogSearchCriteria, config?: ApiConfig): Promise<DocumentDeliveryLogSearchResults> =>
      aroundApi(searchApi.searchDocumentDeliveryLog(criteria), config),
    searchNotifications: (criteria: NotificationSearchCriteria, config?: ApiConfig): Promise<NotificationSearchResults> =>
      aroundApi(searchApi.searchNotifications(criteria), config),
    searchRenewalFee: (criteria: RenewalFeeSearchCriteria, config?: ApiConfig): Promise<RenewalFeeSearchResults> =>
      aroundApi(searchApi.searchRenewalFee(criteria), config),
    searchApplicationFee: (criteria: ApplicationFeeSearchCriteria, config?: ApiConfig) => aroundApi(searchApi.searchApplicationFee(criteria), config),
    searchLightweightApplicationFee: (criteria: ApplicationFeeSearchCriteria, config?: ApiConfig) =>
      aroundApi(searchApi.searchLightweightApplicationFee(criteria), config),
    searchLightweightRenewalFee: (criteria: RenewalFeeSearchCriteria, config?: ApiConfig): Promise<LightweightRenewalFeeSearchResults> =>
      aroundApi(searchApi.searchLightweightRenewalFee(criteria), config),
    searchTask: (criteria: TaskSearchCriteria, config?: ApiConfig) => aroundApi(searchApi.searchTask(criteria), config),
    preloadTaskSearch: (config?: ApiConfig) => aroundApi(searchApi.preloadTaskSearch({}), config),
    getPlannedAndCurrentLicenceTypesForLicensee: (licenseeId: number, config?: ApiConfig): Promise<Array<number>> =>
      aroundApi(searchApi.getPlannedAndCurrentLicenceTypesForLicensee(licenseeId), config),
  };
};
