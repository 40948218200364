import { MessageBar, MessageBarType } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { ClientSearchCriteriaStatusEnum, ClientSearchResultStatusEnum } from 'api-client';

import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import ClientTagPicker from 'common/controls/inputs/client/ClientTagPicker';
import ReadonlyData from 'common/controls/items/ReadonlyData';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { useGetClientWarning, useDisplayAddress } from 'common/utils/clientUtils';
import { useLicenceApi } from 'licence_management/LicenceApiHooks';
import { useSearchApi } from 'search/SearchApiHooks';

const Active = ClientSearchCriteriaStatusEnum.Active;

const SEARCH_CLIENT_ERRORS = {
  CLIENT_INACTIVE: 'Client is inactive',
  CLIENT_NOT_FOUND: 'Client does not exist',
};

interface ClientDetailsSectionProps {
  clientDetailTitle?: string;
  clientSearchVisible: boolean;
  initialApplicant?: number;
}

const ClientDetailsSection = ({ clientDetailTitle, clientSearchVisible, initialApplicant }: ClientDetailsSectionProps) => {
  const { searchClient } = useSearchApi();
  const sectionTitle = clientDetailTitle ? clientDetailTitle : 'Client details';
  const { currentClientId } = useAuth();
  const [creditStatus, setCreditStatus] = useState<string | undefined>();

  const { getApplicantDetails } = useLicenceApi();
  const { values, setFieldError, setFieldValue } = useFormikContext<any>();

  const errorHandler = () => setFieldError('applicant', 'Default client does not exist or inactive');

  const fetchApplicantDetails = (clientId: number) =>
    getApplicantDetails(clientId, { callerHandleErrors: true, disableErrorMessage: true, showLoadingSpinner: true });

  const populateApplicantDetails = (applicant: any) => {
    setFieldValue('applicant', applicant?.id, false);
    setFieldValue('suppressLicenceDetails', applicant?.suppressLicenceDetails, false);
    setFieldValue('applicantSuppressDetails', applicant?.suppressLicenceDetails, false);
    setFieldValue('defenceClient', applicant?.defenceClient, false);
    setCreditStatus(applicant?.creditStatus);
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (initialApplicant || currentClientId) {
      const fetchClientDetails = searchClient(
        initialApplicant
          ? {
              searchText: initialApplicant.toString(),
              hidden: false,
              status: Active,
            }
          : {
              searchText: currentClientId!.toString(),
              hidden: false,
            },
      );

      Promise.all([fetchClientDetails, fetchApplicantDetails(initialApplicant ?? currentClientId!)])
        .then(([{ results = [], totalItems }, applicant]) => {
          if (totalItems) {
            const client = results[0];

            setFieldValue('client', {
              ...client,
              displayName: `${client.clientName} (${client.id})`,
            });
            if (!initialApplicant) {
              setFieldValue('anniversaryMonth', client?.defaultBillingMonth);
            }
            populateApplicantDetails(applicant);

            if (client?.status !== ClientSearchResultStatusEnum.Active) {
              setErrorMessage(SEARCH_CLIENT_ERRORS.CLIENT_INACTIVE);
            }
          } else {
            setErrorMessage(SEARCH_CLIENT_ERRORS.CLIENT_NOT_FOUND);
          }
        })
        .catch(errorHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SectionHeading title={sectionTitle} warning={useGetClientWarning(values.client?.id, creditStatus ?? values.client?.creditStatus)} />
      {errorMessage && <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>}
      {clientSearchVisible ? (
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <ClientTagPicker
              alwaysValidate={true}
              criteria={{
                status: ClientSearchCriteriaStatusEnum.Active,
                hidden: false,
              }}
              isRequired
              label="Client"
              name="client.displayName"
              onChange={(items) => {
                if (items?.length) {
                  const client: any = items[0];
                  setFieldValue('client', client);
                  setFieldValue('anniversaryMonth', client?.defaultBillingMonth);
                  fetchApplicantDetails(client?.id).then(populateApplicantDetails).catch(errorHandler);
                } else {
                  setErrorMessage('');
                  setFieldValue('anniversaryMonth', undefined);
                  setFieldValue('client', undefined);
                  populateApplicantDetails(undefined);
                }
              }}
            />
          </Grid.Col>
        </Grid.Row>
      ) : (
        <>
          <Grid.Row>
            <Grid.Col sm={12} lg={5}>
              <ReadOnlyField name="client.id" label="Client number" />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col sm={12} lg={5}>
              <ReadOnlyField name="client.clientName" label="Client name" />
            </Grid.Col>
          </Grid.Row>
        </>
      )}

      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <ReadonlyData label="Residential/Physical address" value={useDisplayAddress(values.client)} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={5}>
          <ReadOnlyField name="client.email" label="Email" />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default ClientDetailsSection;
