import { Label, Link, Text } from '@fluentui/react';
import { uniqueId } from 'lodash';
import * as React from 'react';
import { CSSProperties, useState } from 'react';

import { formatDateFromISO, months } from '../../utils/dateUtils';
import { getClassNames } from './ReadonlyData classNames';

export type ReadonlyDataProps = {
  label: string;
  value?: any;
  id?: string;
  renderFormat?: (value: any) => any;
  /**
   * `_blank` Opens the linked document in a new window or tab (this is default)
   * `_self` Opens the linked document in the same frame as it was clicked
   * `_parent` Opens the linked document in the parent frame
   * `_top` Opens the linked document in the full body of the window
   **/
  target?: string;
  /**  URL the link points to **/
  href?: string;
  emptyFormat?: any;
  onClickValue?: () => void;
  multiline?: boolean;
  formatDateFromISO?: boolean;
  indicateRequired?: boolean;
  direction?: 'horizontal' | 'vertical';
  hideLabel?: boolean;
};

const ReadonlyData: React.FC<ReadonlyDataProps> = (props) => {
  const id = props.id ? props.id : (props.label as string).toLowerCase().replace(new RegExp(' ', 'g'), '-');
  const [generatedId] = useState(uniqueId(id));
  const classNames = getClassNames(props.multiline);
  const value = props.value !== undefined ? (props.renderFormat ? props.renderFormat(props.value) : props.value) : '';
  const displayValue = !props.value && props.value !== 0 && props.emptyFormat !== undefined ? props.emptyFormat : value;
  const valueToDisplay = props.formatDateFromISO ? formatDateFromISO(displayValue.toString()) : displayValue?.toString();
  const redAsterisk = <span style={{ color: 'rgb(206,4,4)', fontWeight: 'bold' }}> *</span>;

  const inlineStyle: CSSProperties =
    props.direction === 'horizontal'
      ? {
          display: 'inline-block',
          margin: '5px',
        }
      : {};
  return (
    <div data-read-only-field={props.label} className={classNames.root}>
      {!props.hideLabel && (
        <Label id={`${generatedId}-label`} htmlFor={generatedId} style={{ fontWeight: 'normal' }}>
          {props.label}
          {props.indicateRequired ? redAsterisk : undefined}
        </Label>
      )}
      <Text
        className={classNames.value}
        style={inlineStyle}
        data-automation-id={id}
        id={generatedId}
        onClick={props.onClickValue}
        aria-labelledby={`${generatedId}-label`}
        role="textbox"
      >
        {displayValue === undefined || displayValue === null || displayValue === '' ? (
          '-'
        ) : props.href ? (
          <Link target={props.target ?? '_blank'} href={props.href}>
            {valueToDisplay?.toString()}
          </Link>
        ) : (
          valueToDisplay?.toString()
        )}
      </Text>
    </div>
  );
};

export const renderAsYesNo = (value?: any) => (value ? (value ? 'Yes' : 'No') : 'No');

export const renderStringAsYesNo = (value?: any) => (value ? (value === 'true' ? 'Yes' : 'No') : 'No');

export const prettyMonth = (value?: any) => (value ? months.get(value) : '');

export default ReadonlyData;
