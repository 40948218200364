import {
  ActivateUserParameters,
  MedsecUserDto,
  UserDto,
  UserSearchCriteria,
  UserSearchResults,
  OtherPreference,
  EventCriteria,
  EventResults,
  UserToMaintainSearchResults,
} from 'api-client';

import { ApiConfig, useApi } from 'common/api/ApiHook';
import { usersApi } from 'common/api/api';

export const useUserApi = () => {
  const { aroundApi } = useApi();

  return {
    getUsers: (status: string, role: string, config?: ApiConfig) => aroundApi(usersApi.getUsers(status, role), config),
    createInternalUser: (user: UserDto, config?: ApiConfig) => aroundApi(usersApi.createInternalUser(user), config),
    getActivateDetail: (activationCode: string, config?: ApiConfig) => aroundApi(usersApi.getActivateDetail(activationCode), config),
    activateUser: (param: ActivateUserParameters, activationCode: string, config?: ApiConfig) =>
      aroundApi(usersApi.activateUser(param, activationCode), config),
    maintainUser: (user: UserDto, config?: ApiConfig) => aroundApi(usersApi.maintainUser(user), config),
    getUser: (userId: number, config?: ApiConfig) => aroundApi(usersApi.getUserById(userId), config),
    getMyUser: (config?: ApiConfig) => aroundApi(usersApi.getMyUserAccount(), config),
    getMainMedsecUser: (config?: ApiConfig) => aroundApi(usersApi.getMainMedsecUser(), config),
    getMedsecUsers: (userName: string, password: string, config?: ApiConfig) => aroundApi(usersApi.getMedsecUsers(userName, password), config),
    mergeMedsecUsers: (medsecAccounts: MedsecUserDto[], config?: ApiConfig) => aroundApi(usersApi.mergeMedsecUsers(medsecAccounts), config),
    updateUserOtherPreference: (otherPreference: OtherPreference, config?: ApiConfig) =>
      aroundApi(usersApi.updateUserOtherPreference(otherPreference), config),
    searchUser: (criteria: UserSearchCriteria, config?: ApiConfig): Promise<UserSearchResults> => aroundApi(usersApi.searchUser(criteria), config),
    searchUserToMaintain: (criteria: UserSearchCriteria, config?: ApiConfig): Promise<UserToMaintainSearchResults> =>
      aroundApi(usersApi.searchUserToMaintain(criteria), config),
    getUserEvents: (userId: number, criteria: EventCriteria, config?: ApiConfig): Promise<EventResults> =>
      aroundApi(usersApi.getUserEvents(userId, criteria), config),
  };
};
