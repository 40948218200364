import * as React from 'react';

import Page from './layout/Page';

const NotFoundPage = () => (
  <Page title="Page not found">
    <section>
      <p>Sorry, we couldn't find anything at that location.</p>
    </section>
  </Page>
);

export default NotFoundPage;
