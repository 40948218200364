import { DefaultButton, Label } from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';

import {
  LicenceClassificationType,
  LicenceDto,
  LicenceDtoLicenceTypeCodeEnum,
  LicenceRadiationPatternDto,
  LicenceRadiationPatternDtoRadiationPatternCodeEnum,
  ServiceTypeDto,
  SpectrumDto,
} from 'api-client';

import HorizontalRadiationPatternChart from 'common/charts/HorizontalRadiationPatternChart';
import VerticalRadiationPatternChart from 'common/charts/VerticalRadiationPatternChart';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import Card from 'common/controls/surfaces/Card';
import MoreOrLess from 'common/controls/surfaces/MoreOrLess';
import { useForm } from 'common/form/FormHook';
import { findLicenceType } from 'licence_management/common/utils';

import { NumberField } from '../../../../common/controls/inputs/NumberField';
import ReadOnlyField from '../../../../common/controls/inputs/ReadOnlyField';
import Form from '../../../../common/form/Form';
import { SubSectionHeading } from '../../../../common/layout/SectionHeadings';
import { Grid } from '../../../../ui-library';
import { Licence } from '../../CraftLicencePage';
import { htlRadiationPatternsDoneSchema, requiresHtlRadiationPatterns, vtlRadiationPatternsDoneSchema } from './radiationPatternValidationSchema';

const { HTL, VTL } = LicenceRadiationPatternDtoRadiationPatternCodeEnum;
const C = LicenceClassificationType.C;
const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;

const RadiationPatternsSection = ({
  licence,
  maxPower,
  serviceTypes,
  onSave,
  readOnly,
  onFocus,
  onCancel,
}: {
  licence?: Licence;
  maxPower?: number;
  serviceTypes: ServiceTypeDto[];
  onSave: () => void;
  readOnly?: boolean;
  onFocus: () => void;
  onCancel: () => void;
}) => {
  const mode = readOnly ? 'VIEW' : 'EDIT';
  const { radPattHtlCapture, radPattVtlCapture } = (licence && findLicenceType(licence, serviceTypes)) ?? {};
  const requiredHtl = requiresHtlRadiationPatterns(licence);

  return (!radPattHtlCapture && !radPattVtlCapture) ||
    (licence?.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.SPL && licence?.classification === C) ? (
    <></>
  ) : (
    <div onFocus={onFocus}>
      <MoreOrLess
        moreText="Show radiation patterns"
        lessText="Hide radiation patterns"
        initiallyMore={requiredHtl}
        more={
          <Card>
            <Form
              id="radiation-patterns-form"
              canEdit={false}
              hideBack
              hideFormButtonsTop
              showFormButtonsBottom
              disableErrorMessage
              mode={mode}
              onSubmit={onSave}
              onCancelEdit={onCancel}
              ignoreFieldsValidation={['spectrums']}
            >
              <Grid>
                {!!radPattHtlCapture && (
                  <RadiationPatterns
                    name="radiationPatterns.horizontal"
                    title="Horizontal radiation pattern (HRP)"
                    hint="0 degrees refers to True North"
                    radiationPatternCode={HTL}
                    required={requiredHtl}
                    maxPower={maxPower}
                    licence={licence}
                  />
                )}
                {!!radPattVtlCapture && (
                  <RadiationPatterns
                    name="radiationPatterns.vertical"
                    title="Vertical radiation pattern (VRP)"
                    hint="0 degrees refers to the horizontal plane"
                    radiationPatternCode={VTL}
                    maxPower={maxPower}
                    licence={licence}
                  />
                )}
              </Grid>
            </Form>
          </Card>
        }
      />
    </div>
  );
};

const RadiationPatterns = ({
  name,
  required,
  title,
  hint,
  radiationPatternCode,
  maxPower,
  licence,
}: {
  name: string;
  title: string;
  hint: string;
  required?: boolean;
  radiationPatternCode: LicenceRadiationPatternDtoRadiationPatternCodeEnum;
  maxPower?: number;
  licence?: Licence;
}) => {
  const editMode = useForm().mode === 'EDIT';
  const { values, setFieldValue } = useFormikContext();
  const [isGraphMode, setIsGraphMode] = useState(false);
  const radiationPatterns: LicenceRadiationPatternDto[] = get(values, name) ?? [];
  const bearingTos = radiationPatterns.map((rp) => rp.bearingTo);
  const lastBearingTo = [...bearingTos].pop();
  const isHtl = radiationPatternCode === HTL;
  const radiationPatternsDoneSchema = (isHtl ? htlRadiationPatternsDoneSchema : vtlRadiationPatternsDoneSchema)(maxPower);
  const [isValid, setValid] = useState<boolean>(false);
  const defaultSector = {
    radiationPatternCode,
    bearingFrom: lastBearingTo ?? (isHtl ? 0 : 90),
    bearingTo: isHtl ? 360 : undefined,
    value: maxPower,
  };

  useEffect(() => {
    // Set bearingFrom as the previous bearingTo
    bearingTos.forEach((bearingTo, i) => i < bearingTos.length - 1 && setFieldValue(`${name}[${i + 1}].bearingFrom`, bearingTo));
  }, ['' + bearingTos]);

  useEffect(() => {
    setValid(radiationPatternsDoneSchema.isValidSync(radiationPatterns, { context: radiationPatterns }));
  }, [radiationPatterns]);

  return (
    <FieldArray
      name={name}
      render={({ name, remove, push }) => (
        <>
          <SubSectionHeading
            title={title}
            hint={hint}
            required={required}
            actionButtonId={`add-${isHtl ? 'htl' : 'vtl'}-radiation-pattern-sector-button`}
            actionButtonOnClick={editMode ? () => push(defaultSector) : undefined}
          />
          <ErrorLabel name={`${name}`} showIcon alwaysValidate />
          {!!radiationPatterns.length && (
            <RadiationPatternsHeader
              graphButtonId={`${isHtl ? 'htl' : 'vtl'}-radiation-pattern-graph-button`}
              graphButtonDisabled={!isValid}
              graphButtonText={`${isHtl ? 'Horizontal' : 'Vertical'} radiation pattern`}
              isGraphMode={isGraphMode}
              handleChangeMode={() => setIsGraphMode(!isGraphMode)}
            />
          )}
          {isGraphMode
            ? (radiationPatterns.length ?? 0) > 0 &&
              (isHtl ? (
                <HorizontalRadiationPatternChart radiationPatterns={radiationPatterns} />
              ) : (
                <VerticalRadiationPatternChart radiationPatterns={radiationPatterns} />
              ))
            : radiationPatterns.map((_, i) => <RadiationPattern name={`${name}[${i}]`} required={required && !i} remove={() => remove(i)} />)}
        </>
      )}
    />
  );
};

const RadiationPatternsHeader = ({
  graphButtonId,
  graphButtonDisabled,
  graphButtonText,
  isGraphMode,
  handleChangeMode,
}: {
  graphButtonId: string;
  graphButtonDisabled: boolean;
  graphButtonText: string;
  isGraphMode: boolean;
  handleChangeMode: () => void;
}) => {
  const notEditMode = useForm().mode !== 'EDIT' ? true : undefined;
  const rightAlign: CSSProperties = { textAlign: 'right' };

  return (
    <Grid.Row>
      {!isGraphMode ? (
        <>
          <Grid.Col lg={2} style={rightAlign}>
            <Label>Bearing (From)</Label>
          </Grid.Col>
          <Grid.Col lg={2} style={notEditMode && rightAlign}>
            <Label required={!notEditMode}>Bearing (To)</Label>
          </Grid.Col>
          <Grid.Col lg={2} style={notEditMode && rightAlign}>
            <Label required={!notEditMode} style={{ whiteSpace: 'nowrap' }}>
              Bearing value (dBW eirp)
            </Label>
          </Grid.Col>
        </>
      ) : (
        <Grid.Col lg={6} />
      )}
      <Grid.Col lg={6} style={{ textAlign: 'right', display: 'inline-block' }}>
        <DefaultButton
          id={graphButtonId}
          text={isGraphMode ? graphButtonText : 'Graph'}
          disabled={graphButtonDisabled}
          style={{ marginLeft: 10 }}
          onClick={handleChangeMode}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

const RadiationPattern = ({ name, required, remove }: { name: string; required?: boolean; remove: () => void }) => {
  const notEditMode = useForm().mode !== 'EDIT' ? true : undefined;
  const rightAlign: CSSProperties = { textAlign: 'right' };
  const compact: CSSProperties = { margin: 0, padding: 0 };
  const oneDp = (n: any) => (isNaN(+n) ? '' : (+n).toFixed(1));

  return (
    <Grid.Row style={notEditMode && compact}>
      <Grid.Col lg={2} style={rightAlign}>
        <ReadOnlyField name={`${name}.bearingFrom`} renderFormat={oneDp} hideLabel />
      </Grid.Col>
      <Grid.Col lg={2} style={notEditMode && rightAlign}>
        <NumberField name={`${name}.bearingTo`} decimalDigit={1} maxLength={6} readOnlyRenderFormat={oneDp} hideLabel />
      </Grid.Col>
      <Grid.Col lg={2} style={notEditMode && rightAlign}>
        <NumberField name={`${name}.value`} decimalDigit={1} maxLength={5} readOnlyRenderFormat={oneDp} hideLabel />
      </Grid.Col>
      {!notEditMode && !required && (
        <Grid.Col lg={2}>
          <DefaultButton id={`${name}-remove-button`} text="Remove" onClick={remove} />
        </Grid.Col>
      )}
    </Grid.Row>
  );
};

export const maximumPower = (spectrums: SpectrumDto[] = []): number | undefined =>
  spectrums
    .flatMap((s) => s.frequencies)
    .map((f) => f!.power)
    .filter((power) => power !== null && power !== undefined && typeof power === 'number')
    .sort((a, b) => a! - b!)
    .pop();

export interface RadiationPatternsFormProps {
  horizontal: LicenceRadiationPatternDto[];
  vertical: LicenceRadiationPatternDto[];
}

export const radiationPatternsAsFormValues = (licence?: LicenceDto, maxPower?: number): RadiationPatternsFormProps => {
  let horizontal = licence?.radiationPatterns?.filter((rp) => rp.radiationPatternCode === HTL).sort((a, b) => +a.bearingFrom - +b.bearingFrom) ?? [];
  if (requiresHtlRadiationPatterns(licence) && !horizontal.length) {
    horizontal.push({
      radiationPatternCode: HTL,
      bearingFrom: 0,
      bearingTo: 360,
      value: maxPower as any,
    });
  }
  const vertical = licence?.radiationPatterns?.filter((rp) => rp.radiationPatternCode === VTL).sort((a, b) => +b.bearingFrom - +a.bearingFrom) ?? [];
  return {
    horizontal,
    vertical,
  };
};

export default RadiationPatternsSection;
