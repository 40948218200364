import * as yup from 'yup';

import { isBlank, requiredString, notRequiredString } from '../../../common/validation/yupUtils';

const isValidSetOrCalcLevel = (isSetOrCalcLevel: boolean) =>
  function (val: any) {
    // @ts-ignore
    const me: any = this;
    if (isBlank(val)) {
      return true;
    }

    if (/^(-?\d*)(\.\d*)?$/.test(val) && val !== '.') {
      const numberVal = Number.parseFloat(val);
      if (isSetOrCalcLevel ? numberVal >= -1000 && numberVal <= 1000 : numberVal >= -100000 && numberVal <= 100000) {
        if (!isSetOrCalcLevel && !/^(-?\d*)(\.\d{0,6})?$/.test(val)) {
          // more than 6 decimal places.
          return me.createError({
            message: 'Maximum of 6 decimal places allowed.',
          });
        } else if (isSetOrCalcLevel && !/^(-?\d*)(\.\d{0,1})?$/.test(val)) {
          // more than 1 decimal places.
          return me.createError({
            message: 'Maximum of 1 decimal places allowed.',
          });
        } else {
          return true;
        }
      }
    }
    return me.createError({
      message: isSetOrCalcLevel ? 'Must be a number between -1000 and 1000 exclusive.' : 'Must be a number between -100000 and 100000 exclusive.',
    });
  };

const isValidFrequency = () =>
  function (val: any) {
    // @ts-ignore
    const me: any = this;
    if (isBlank(val)) {
      return true;
    }

    if (/^(-?\d*)(\.\d*)?$/.test(val) && val !== '.') {
      const numberVal = Number.parseFloat(val);
      if (numberVal >= 0 && numberVal < 100000) {
        if (!/^(-?\d*)(\.\d{0,6})?$/.test(val)) {
          // more than 6 decimal places.
          return me.createError({
            message: 'Maximum of 6 decimal places allowed.',
          });
        } else {
          return true;
        }
      }
    }
    return me.createError({
      message: 'Must be a number between 0 and 100000 exclusive.',
    });
  };

const validFrequency = notRequiredString
  .test('', 'Frequency must be a positive number', (value) => (value ? value.toString().match(/^(\d*\.)?\d+$/) !== null : true))
  .test('valid value', '', isValidFrequency());

export const requiredSpectrumMaskPoints = yup
  .array()
  .of(
    yup.object({
      displacement: notRequiredString.test('valid value', '', isValidSetOrCalcLevel(false)),
      frequency: validFrequency,
      dbwSetLevel: notRequiredString.test('valid value', '', isValidSetOrCalcLevel(true)),
      calcLevel: notRequiredString.test('valid value', '', isValidSetOrCalcLevel(true)),

      displacementOrFrequenyError: notRequiredString.when(['displacement', 'frequency'], {
        is: (displacement: any, frequency: any) => (isBlank(displacement) && isBlank(frequency)) || (!isBlank(displacement) && !isBlank(frequency)),
        then: requiredString.required('A point must have either a Displacement or Frequency value entered.'),
        otherwise: notRequiredString,
      }),

      calcLevelOrSetLevelError: notRequiredString.when(['calcLevel', 'dbwSetLevel'], {
        is: (calcLevel: any, setLevel: any) => isBlank(calcLevel) && isBlank(setLevel),
        then: requiredString.required('A point must have at least a Set Level or Calc. Level value entered.'),
        otherwise: notRequiredString,
      }),
    }),
  )
  .nullable(true);

export const spectrumMaskSchema = yup.object().shape({
  identifier: requiredString,

  description: requiredString,

  spectrumMaskPoints: requiredSpectrumMaskPoints,
});
