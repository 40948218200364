import { mergeStyles, useTheme } from '@fluentui/react';
import React from 'react';

import { Grid } from 'ui-library';

export interface FileViewProps {
  nameFile: string;
}

const FileView: React.FC<FileViewProps> = (props: FileViewProps) => {
  const theme = useTheme();

  const fileViewClass = mergeStyles({
    color: theme.semanticColors.bodyText,
  });

  return (
    <Grid.Row>
      <Grid.Col sm={12} lg={6}>
        <span className={fileViewClass}>{props.nameFile}</span>
      </Grid.Col>
    </Grid.Row>
  );
};

export default FileView;
