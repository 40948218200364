import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AddressDto, CaseDto, CaseQuestionDto, CaseTypeGroupEnum, QuestionInputTypeEnum } from 'api-client';

import { toAddressTag } from 'ui-library/shared/AddressPicker';

import { useAuth } from 'common/auth/AuthHook';
import usePage from 'common/layout/PageHook';
import { formatDate, formatDateTimeFromISO } from 'common/utils/dateUtils';
import {
  convertStatus,
  convertToAssociatedToByEnum,
  useCamundaGroupOptions,
  useCaseAssociatedTo,
  useCaseTypeOptions,
  useInvestigationApi,
  useOriginCase,
  useServiceOfInterestOptions,
  useSourceCodeOptions,
} from 'investigation/hooks';

export const useCaseQuestions = () => {
  const { getCaseQuestions } = useInvestigationApi();
  const [caseQuestions, setCaseQuestions] = useState<CaseQuestionDto[]>([]);

  useEffect(() => {
    (async () => {
      setCaseQuestions(await getCaseQuestions());
    })();
  }, []);

  return { caseQuestions };
};

export const useComplaintDataFetcher = (group?: CaseTypeGroupEnum, includeInactive?: boolean) => {
  const { hasAnyPermission } = useAuth();
  const { getCaseById } = useInvestigationApi();
  const { setIsLoading } = usePage();
  const { caseId } = useParams<{ caseId: string }>();
  const [caseDto, setCaseDto] = useState<Partial<CaseDto>>({});

  const { caseTypeOptions } = useCaseTypeOptions(group, includeInactive);
  const { camundaGroupOptions } = useCamundaGroupOptions();
  const { caseQuestions } = useCaseQuestions();

  const { client, licence, emission } = useCaseAssociatedTo(convertToAssociatedToByEnum(caseDto?.associatedTo), {
    entityId: caseDto?.clientOrBusinessAssociatedNo,
  });
  const originCase = useOriginCase(caseDto?.originCaseId);

  const serviceOfInterestOptions = useServiceOfInterestOptions();
  const sourceCodeOptions = useSourceCodeOptions();

  useEffect(() => {
    setIsLoading(true);
    getCaseById(+caseId)
      .then((caseDto) => setCaseDto(caseDto))
      .finally(() => setIsLoading(false));
  }, []);

  const addressQuestion = caseQuestions?.find(({ inputType }) => QuestionInputTypeEnum.ADDRESS_PICKER === inputType) || {};

  const answers = caseDto.answers?.reduce((output, { answer, questionId, id }) => {
    const questionKey = `question_${questionId}`;

    if (questionId === addressQuestion?.id) {
      if (!answer) {
        return {
          ...output,
          [questionKey]: { answer, answerId: id },
        };
      }

      try {
        const address = JSON.parse(answer);
        return {
          ...output,
          [questionKey]: {
            answer: address.manual ? address : toAddressTag(address as AddressDto),
            answerId: id,
          },
        };
      } catch (e) {
        return {
          ...output,
          [questionKey]: { answer, answerId: id },
        };
      }
    }

    return {
      ...output,
      [questionKey]: { answer, answerId: id },
    };
  }, {});

  return {
    caseDetails: {
      allocatedTo: caseDto.camundaGroupId,
      allocatedToGroups: camundaGroupOptions,
      allocatedToUser: caseDto.camundaUserId,
      answers,
      associatedToBy: convertToAssociatedToByEnum(caseDto.associatedTo),
      associatedTo: undefined,
      caseClassificationId: caseDto.classification?.id,
      caseNumber: caseDto.id,
      caseTypeId: caseDto.type?.id,
      clientOrBusinessAssociatedNo: caseDto.clientOrBusinessAssociatedNo,
      closedDate: caseDto.closedDate,
      createdBy: caseDto.createdName,
      createdDate: formatDateTimeFromISO(caseDto.createdDate, { showSeconds: true }),
      emcStandardId: caseDto.emcStandardId,
      endDate: caseDto.endDate,
      finalResult: caseDto.finalResult,
      finalResultNotes: caseDto.finalResultNotes,
      lastUpdatedBy: caseDto.lastUpdatedName,
      lastUpdatedDate: formatDateTimeFromISO(caseDto.lastUpdatedDate, { showSeconds: true }),
      notes: caseDto.notes,
      originCaseId: caseDto.originCaseId,
      serviceOfInterestId: caseDto.serviceOfInterest?.id,
      sourceCodeId: caseDto.sourceCode?.id,
      startDate: hasAnyPermission(['UPDATE_COMPLAINT']) ? caseDto.startDate || formatDate(new Date()) : undefined,
      startUser: caseDto.startUser,
      status: convertStatus(caseDto.status),
      statusEnum: caseDto.status,
      tags: caseDto.tags,
      links: caseDto?.links,

      caseTypeOptions,
      caseQuestions,
      client,
      licence,
      emission,
      originCase,
      serviceOfInterestOptions,
      sourceCodeOptions,
    },
    caseDto,
    caseId,
  };
};
