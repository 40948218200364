import { mergeStyles } from '@fluentui/merge-styles';
import { DefaultButton, Label } from '@fluentui/react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React from 'react';

import { LicenceClassificationType, LicenceDtoLicenceTypeCodeEnum, FrequencyDto } from 'api-client';

import { Grid } from 'ui-library';

import Dropdown from 'common/controls/inputs/Dropdown';
import ErrorLabel from 'common/controls/inputs/ErrorLabel';
import { NumberField } from 'common/controls/inputs/NumberField';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import { useForm } from 'common/form/FormHook';
import HorizontalDivider from 'common/layout/HorizontalDivider';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatTime } from 'common/utils/dateUtils';

import Emissions from './Emissions';
import { frequencyTypeOptions, hoursOfOperation, powerTypeOptions } from './references';

const ReferenceFrequencies: React.FC<{
  requiredEmissions: boolean;
  licenceClassification?: LicenceClassificationType;
  licenceTypeCode?: LicenceDtoLicenceTypeCodeEnum;
}> = (props) => {
  return (
    <>
      <Grid.Row>
        <FieldArray name={'frequencies'} render={(arrayHelpers) => <ReferenceFrequencyComponent {...arrayHelpers} {...props} />} />
      </Grid.Row>
    </>
  );
};

const ReferenceFrequencyComponent = (
  props: FieldArrayRenderProps & {
    requiredEmissions: boolean;
    licenceClassification?: LicenceClassificationType;
    licenceTypeCode?: LicenceDtoLicenceTypeCodeEnum;
  },
) => {
  const { values } = useFormikContext<any>();

  const { mode } = useForm();

  const className = mergeStyles({
    background: '#f0f2f8',
    padding: '29.1px 27.5px 6.4px 22px',
  });

  return (
    <>
      <SectionHeading
        title="Reference frequencies"
        actionButtonId={'reference-frequencies'}
        actionButtonText="Add Reference Frequency"
        actionButtonOnClick={() => {
          props.push({
            referenceFrequency: '',
            referenceFrequencyType: undefined,
            power: '',
            powerType: undefined,
            tolerance: '',
            hourCode: undefined,
            isoStartTime: '',
            isoStopTime: '',
            frequencyEmissions: [],
          });
        }}
        hideActionButton={mode === 'VIEW'}
        expanded
        hideIconChevron
      ></SectionHeading>
      <ErrorLabel name={`frequencies`} showIcon />
      {values.frequencies &&
        values.frequencies.length > 0 &&
        values.frequencies.map((frequencyDto: FrequencyDto, index: number) => (
          <div key={`frequencies-${index}`} className={className}>
            <ReferenceFrequency {...props} itemNumber={index} namePrefix={`frequencies[${index}].`} />
          </div>
        ))}
    </>
  );
};

export const ReferenceFrequency = (
  props: FieldArrayRenderProps & {
    itemNumber: number;
    namePrefix: string;
    requiredEmissions: boolean;
    licenceClassification?: LicenceClassificationType;
    licenceTypeCode?: LicenceDtoLicenceTypeCodeEnum;
  },
) => {
  const { values, setFieldValue } = useFormikContext<any>();

  const lt30Mhz =
    values.upperBound < 30 ||
    (values.lowerBound < 30 &&
      (!values.frequencies[props.itemNumber].referenceFrequency || values.frequencies[props.itemNumber].referenceFrequency < 30));

  const hourCode = values.frequencies[props.itemNumber].hourCode;
  React.useEffect(() => {
    if (hourCode || !lt30Mhz) {
      setFieldValue(`${props.namePrefix}isoStartTime`, null);
      setFieldValue(`${props.namePrefix}isoStopTime`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourCode, lt30Mhz]);

  return (
    <>
      {useForm().mode !== 'CREATE' && (
        <>
          <Grid.Row>
            <Grid.Col sm={6} lg={5}>
              <ReadOnlyField label="Id" name="id" />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      <Grid.Row>
        <Grid.Col sm={12} lg={2}>
          <TextField label="Ref. Freq. (MHz)" name={`${props.namePrefix}referenceFrequency`} required />
        </Grid.Col>
        <Grid.Col sm={12} lg={3}>
          <Dropdown label="Frequency type" name={`${props.namePrefix}referenceFrequencyType`} options={frequencyTypeOptions} required />
        </Grid.Col>
        {(props.licenceClassification === undefined ||
          props.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL ||
          props.licenceClassification !== LicenceClassificationType.C) && (
          <>
            <Grid.Col sm={12} lg={2}>
              <NumberField label="Power dBW (eirp)" name={`${props.namePrefix}power`} required maxLength={3} decimalDigit={0} />
            </Grid.Col>
            <Grid.Col sm={12} lg={3}>
              <Dropdown label="Power type" name={`${props.namePrefix}powerType`} options={powerTypeOptions} required />
            </Grid.Col>
          </>
        )}
      </Grid.Row>
      <Grid.Row>
        {(props.licenceClassification === undefined ||
          props.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL ||
          props.licenceClassification !== LicenceClassificationType.C) && (
          <Grid.Col sm={12} lg={2}>
            <TextField label="Tolerance" name={`${props.namePrefix}tolerance`} maxLength={10} />
          </Grid.Col>
        )}
        {lt30Mhz && (
          <>
            <Grid.Col sm={12} lg={3}>
              <Dropdown label="Hours of operation" name={`${props.namePrefix}hourCode`} options={hoursOfOperation} />
            </Grid.Col>
            <Grid.Col sm={12} lg={2}>
              <TextField
                label="Start time"
                name={`${props.namePrefix}isoStartTime`}
                placeholder={hourCode ? undefined : 'HH:MM:SS'}
                disabled={!!hourCode}
                formatText={formatTime}
                readOnlyEmptyFormat=" "
              />
            </Grid.Col>
            <Grid.Col sm={12} lg={2}>
              <TextField
                label="Stop time"
                name={`${props.namePrefix}isoStopTime`}
                placeholder={hourCode ? undefined : 'HH:MM:SS'}
                disabled={!!hourCode}
                formatText={formatTime}
                readOnlyEmptyFormat=" "
              />
            </Grid.Col>
          </>
        )}
      </Grid.Row>
      {(props.licenceTypeCode === LicenceDtoLicenceTypeCodeEnum.RDL || props.licenceClassification !== LicenceClassificationType.C) && (
        <Grid.Row>
          <Grid.Col lg={5} sm={12}>
            <Emissions name={`${props.namePrefix}frequencyEmissions`} requiredEmissions={props.requiredEmissions} />
          </Grid.Col>
        </Grid.Row>
      )}
      {useForm().mode !== 'VIEW' && (
        <Grid.Row>
          <Grid.Col sm={12} lg={3} style={{ float: 'right', textAlign: 'right' }}>
            <Label>&nbsp;</Label>
            <DefaultButton
              text={'Remove Frequency'}
              onClick={() => props.remove(props.itemNumber)}
              id={'remove-ref-frequency'}
              data-automation-id={'remove-ref-frequency'}
            />
          </Grid.Col>
        </Grid.Row>
      )}
      <div>
        <Grid.Row>
          <HorizontalDivider />
        </Grid.Row>
      </div>
    </>
  );
};

export default ReferenceFrequencies;
