import { MessageBarType } from '@fluentui/react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CaseStatusEnum, CaseTypeGroupEnum, FileNoteDtoEntityTypeEnum } from 'api-client';

import { Form } from 'ui-library';
import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { hasAction } from 'common/api/hateosUtils';
import { usePageNavigator } from 'common/auth/AppRoute';
import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import usePage from 'common/layout/PageHook';
import { CASE_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { formatDateTimeFromISO, toISODateString } from 'common/utils/dateUtils';
import { AuditFormProps } from 'investigation/interfaces';

import ActionCardAuditComplete from './components/ActionCardAuditComplete';
import AuditFormContent from './components/AuditFormContent';
import { AuditLinkedCaseTypeEnum } from './components/AuditLinkedCases';
import { useAuditFormState, useMaintainAuditApi, useValidateAuditForm } from './hooks';

const MaintainAuditForm = () => {
  const maintainAuditApi = useMaintainAuditApi();
  const { hasPermission } = useAuth();
  const [actionCardVisible, setActionCardVisible] = useState<boolean>(true);

  const { validateForm, validateCanComplete } = useValidateAuditForm();

  const { setPageInstruction } = usePage();
  const { navigateTo } = usePageNavigator();

  const { caseDto, formState, setFormState } = useAuditFormState();

  useEffect(() => {
    if (caseDto?.id && caseDto.type?.group !== CaseTypeGroupEnum.AUDIT) {
      navigateTo('/not-found');
    }
  }, [caseDto]);

  const canBeAuditCompleted =
    formState.statusEnum === CaseStatusEnum.IPRG &&
    isEmpty(validateForm(formState as AuditFormProps)) &&
    isEmpty(validateCanComplete(formState as AuditFormProps));
  hasAction('complete-audit', formState?.links);

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');
  const { caseId } = useParams<{ caseId: string }>();

  return (
    <>
      {canBeAuditCompleted && (
        <ActionCardAuditComplete
          id={formState.caseNumber || 0}
          startDate={formState.startDate}
          endDate={toISODateString(new Date())!!}
          visible={actionCardVisible}
        />
      )}
      <Form<AuditFormProps>
        canEdit={hasPermission('UPDATE_AUDIT') && caseDto.status === CaseStatusEnum.IPRG}
        formButtonsOptions={{
          submitButtonText: 'Save',
        }}
        id="MaintainAuditForm"
        handleSubmit={(values, formikHelpers) => {
          setActionCardVisible(false);
          maintainAuditApi(values)
            .then(({ id, lastUpdatedName, lastUpdatedDate, startUser, status, subsidiaryCases, originatorCases, links }) => {
              //parse subsidiaryCases, originatorCases to linkTypes to display on the list
              const listTypeFromMaintain = (originatorCases?.map((x) => ({ [`caseId_${x.id}`]: AuditLinkedCaseTypeEnum.ORIGINATOR })) ?? []).concat(
                subsidiaryCases?.map((x) => ({ [`caseId_${x.id}`]: AuditLinkedCaseTypeEnum.SUBSIDIARY })) ?? [],
              );

              setFormState({
                ...values,
                lastUpdatedBy: lastUpdatedName,
                lastUpdatedDate: formatDateTimeFromISO(lastUpdatedDate, { showSeconds: true }),
                startUser: startUser,
                statusEnum: status,
                links: links,
                originatorCases,
                subsidiaryCases,

                linkTypes: listTypeFromMaintain.reduce((acc, current) => ({ ...acc, ...current }), {}),
              });
              setPageInstruction(<span>Case #{id} has been successfully updated.</span>, MessageBarType.success);
            })
            .finally(() => {
              setActionCardVisible(true);
              formikHelpers.setSubmitting(false);
            });
        }}
        initialValues={formState}
        showFormButtonsBottom
        validate={validateForm}
      >
        <AuditFormContent setActionCardVisible={setActionCardVisible} />
        {canViewFileNote && <FileNoteSection entityId={+caseId} entityType={FileNoteDtoEntityTypeEnum.CASE} noteTypes={CASE_FILE_NOTE_TYPE} />}
        <EventLogSection entityName={'CASE'} entityId={+caseId} />
      </Form>
    </>
  );
};

const MaintainAuditPage = () => {
  return (
    <Page title="Maintain Audit">
      <MaintainAuditForm />
    </Page>
  );
};

export { MaintainAuditPage };
