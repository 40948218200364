import { DefaultButton, Label } from '@fluentui/react';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as yup from 'yup';

import { SpectrumDto } from 'api-client';

import ErrorLabel from '../../../../common/controls/inputs/ErrorLabel';
import { NumberField } from '../../../../common/controls/inputs/NumberField';
import ReadonlyData from '../../../../common/controls/items/ReadonlyData';
import { DnDCard } from '../../../../common/controls/surfaces/DnDCard';
import { useForm } from '../../../../common/form/FormHook';
import { numberToLetter } from '../../../../common/utils/stringUtils';
import Grid from '../../../../ui-library/grid';
import { unwantedEmissionLimitsDoneSchema } from './spectrumDetailsValidationSchema';

const UnwantedEmissionLimitsListView = ({
  name,
  remove,
  move,
  onViewUel,
  onViewGraph,
  lowerBound,
  upperBound,
}: FieldArrayRenderProps & { onViewUel: () => void; onViewGraph: () => void; lowerBound: number; upperBound: number }) => {
  const mode = useForm().mode;
  const editMode = mode === 'EDIT' || mode === 'CREATE';
  const { values } = useFormikContext<{ spectrums: SpectrumDto[] }>();
  const [isValid, setValid] = useState<boolean>(false);
  const unwantedEmissionLimits: [] = get(values, name) ?? [];

  useEffect(() => {
    setValid(
      yup.object().shape({ unwantedEmissionLimits: unwantedEmissionLimitsDoneSchema }).isValidSync(
        {
          lowerBound,
          upperBound,
          unwantedEmissionLimits,
        },
        { context: values },
      ),
    );
    // eslint-disable-next-line
  }, [unwantedEmissionLimits]);

  return (
    <>
      {!!unwantedEmissionLimits.length && <UnwantedEmissionLimitHeader onViewUel={onViewUel} onViewGraph={onViewGraph} buttonsDisabled={!isValid} />}
      {!editMode && unwantedEmissionLimits.map((_, i) => <UnwantedEmissionLimitRow name={`${name}[${i}]`} index={i} />)}
      {editMode && (
        <DndProvider backend={HTML5Backend}>
          {unwantedEmissionLimits.map((_, i) => (
            <DnDCard
              key={`unwantedEmissionLimit-${i}`}
              id={`unwantedEmissionLimit-${i}`}
              index={i}
              style={{ backgroundColor: 'transparent' }}
              moveCard={move}
              item={<UnwantedEmissionLimitRow name={`${name}[${i}]`} index={i} onRemove={() => remove(i)} />}
            />
          ))}
        </DndProvider>
      )}
    </>
  );
};

const UnwantedEmissionLimitHeader = ({
  onViewUel,
  onViewGraph,
  buttonsDisabled,
}: {
  onViewUel: () => void;
  onViewGraph: () => void;
  buttonsDisabled: boolean;
}) => {
  const mode = useForm().mode;
  const dndEditMode = mode === 'EDIT' || mode === 'CREATE';
  const numberColStyle: CSSProperties | undefined = !dndEditMode ? { textAlign: 'right' } : undefined;
  return (
    <div style={dndEditMode ? { marginLeft: 3, marginBottom: 5 } : {}}>
      <Grid.Row>
        <Grid.Col lg={1}>
          <Label>Graph point</Label>
        </Grid.Col>
        <Grid.Col lg={2} style={numberColStyle}>
          <Label required={dndEditMode}>eirp (dBW)</Label>
        </Grid.Col>
        <Grid.Col lg={2} style={numberColStyle}>
          <Label required={dndEditMode}>Frequency (MHz)</Label>
        </Grid.Col>
        <Grid.Col lg={7} style={{ textAlign: 'right', display: 'inline-block' }}>
          <DefaultButton id="uel-button" text="UEL" disabled={buttonsDisabled} onClick={onViewUel} />
          <DefaultButton id="uel-graph-button" text="Graph" disabled={buttonsDisabled} style={{ marginLeft: 10 }} onClick={onViewGraph} />
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

const UnwantedEmissionLimitRow = ({ name, index, onRemove }: { name: string; index: number; onRemove?: () => void }) => {
  const mode = useForm().mode;
  const dndEditMode = mode === 'EDIT' || mode === 'CREATE';
  const numberColStyle: CSSProperties | undefined = !dndEditMode ? { textAlign: 'right' } : undefined;
  return (
    <Grid.Row>
      <Grid.Col lg={1}>
        <div style={dndEditMode ? { marginLeft: 5 } : {}}>
          <ReadonlyData label="" value={numberToLetter(index)} />
        </div>
      </Grid.Col>
      <Grid.Col lg={2} style={numberColStyle}>
        <NumberField
          name={`${name}.limitValue`}
          maxLength={6}
          decimalDigit={1}
          readOnlyRenderFormat={(f) => f?.toFixed(1)}
          ariaLabel={numberToLetter(index) + ' eirp (dBW)'}
        />
        <div style={{ whiteSpace: 'nowrap' }}>
          <ErrorLabel name={`${name}.isUnique`} alwaysValidate />
        </div>
      </Grid.Col>
      <Grid.Col lg={2} style={numberColStyle}>
        <NumberField
          name={`${name}.limitFrequency`}
          maxLength={13}
          decimalDigit={6}
          readOnlyRenderFormat={(f) => f?.toFixed(6)}
          ariaLabel={numberToLetter(index) + ' Frequency (MHz)'}
        />
      </Grid.Col>
      {dndEditMode && (
        <Grid.Col lg={2}>
          <DefaultButton id={`remove-uel-${index}-button`} text="Remove" onClick={onRemove} />
        </Grid.Col>
      )}
    </Grid.Row>
  );
};

export default UnwantedEmissionLimitsListView;
