import { clearJWTFromLocalStorage } from './jwtUtils';

export const getReturnPath = (returnPath?: string) => {
  if (returnPath) {
    return returnPath;
  } else {
    if (window.location.pathname === '/ui/') {
      return '/ui/app/dashboard';
    } else {
      return `${window.location.pathname}${window.location.search}`;
    }
  }
};

export const loginExternalURL = () => loginURL('external');

export const loginInternalURL = () => loginURL('internal');

export const handleLogout = () => {
  clearJWTFromLocalStorage();
  window.location.href = '/ui/';
};

const loginURL = (idp: 'external' | 'internal') => `/auth/login/${idp}?returnPath=${encodeURIComponent('/ui/app/dashboard')}`;

export const stubLoginUrl = (userName?: string) => `/auth/login/stub?returnPath=${encodeURIComponent('/ui/app/dashboard')}&userName=${userName}`;

/**
 * Change browser URL to go to "Not logged in" page with return set to current url.
 */
export const showNotLoggedInPage = (returnPath?: string) =>
  (window.location.href = `/ui/not-logged-in/${encodeURIComponent(getReturnPath(returnPath))}`);
