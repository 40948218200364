import { TaskDto, CompleteTasksRequest, CompleteTasksResponse } from 'api-client';

import { ApiConfig, useApi } from '../../common/api/ApiHook';
import { bpmTasksApi } from '../../common/api/api';

const useBpmTasksApi = () => {
  const { aroundApi } = useApi();

  return {
    getMyTasks: (config?: ApiConfig): Promise<TaskDto[]> => aroundApi(bpmTasksApi.getMyTasks(), config),
    getFormKeyForTask: (task: TaskDto, config?: ApiConfig): Promise<string> =>
      aroundApi(bpmTasksApi.getFormKeyForTasks(task.processDefinitionId, task.taskDefinitionKey), config),
    getTaskVariables: (taskId: string, config?: ApiConfig): Promise<any> => aroundApi(bpmTasksApi.getFormVariablesForTask(taskId), config),
    claimTask: (taskId: string, config?: ApiConfig): Promise<void> => aroundApi(bpmTasksApi.claimTask(taskId), config),
    completeTask: (taskId: string, variables?: { [key: string]: object }, config?: ApiConfig): Promise<void> =>
      aroundApi(bpmTasksApi.completeTask(taskId, variables), config),
    completeTasks: (completeBatchTaskRequest: CompleteTasksRequest, config?: ApiConfig): Promise<CompleteTasksResponse> =>
      aroundApi(bpmTasksApi.completeTasks(completeBatchTaskRequest), config),
  };
};

export default useBpmTasksApi;
