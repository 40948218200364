import { DirectionalHint, Icon, IContextualMenuItemProps, mergeStyles, TooltipHost } from '@fluentui/react';
import { useAtonNavigation } from 'aton_management/aton/AtonRouter';
import { ENVIRONMENT } from 'env-config';
import { FormikContextType } from 'formik';
import { useOutageNotificationNavigation } from 'outage_notification/OutageNotificationRouter';
import * as React from 'react';
import { useCorrespondenceNavigation } from 'template_management/CorrespondenceRouter';

import { useCertificateNavigation } from 'certificate_management/certificate/CertificateRouter';
import { useApprovalNavigation } from 'client_management/approval/ApprovalRouter';
import { useClientNavigation } from 'client_management/client/ClientRouter';
import { useUserNavigation } from 'client_management/user/UserRouter';
import { useAuth } from 'common/auth/AuthHook';
import TooltipHint from 'common/controls/items/TooltipHint';
import { FormMode } from 'common/form/Form';
import { useWelcomeTextNavigation } from 'dashboard/welcome_text/WelcomeTextRouter';
import { useDevelopmentNavigation } from 'development/DevelopmentRouter';
import { useInvestigationRouter } from 'investigation/InvestigationRouter';
import { useLicenceApi } from 'licence_management/LicenceApiHooks';
import {
  useApplyLicenceNavigation,
  useCancelLicenceNavigation,
  useDeclineLicenceTransferRequestNavigation,
  useLicenceTransferRequestNavigation,
  useModifyLicenceForm22Navigation,
  useModifyLicenceNavigation,
  useWithdrawLicenceTransferRequestNavigation,
} from 'licence_management/LicenceRouter';
import { useManagementRightNavigator } from 'licence_management/management_right/ManagementRightRouter';
import { useLicenceReferenceDataNavigator } from 'licence_management/reference_data/ReferenceDataRouter';
import { useApplyLicenceToSupplyNavigation } from 'licence_to_supply_management/LicenceToSupplyRouter';
import { useSearchNavigation } from 'search/SearchRouter';

import { useDisplayIcon } from '../../dashboard/widgets/hooks/displayShortcutIcon';
import { useMaintainShortcut } from '../../dashboard/widgets/hooks/maintainShortcut';
import { useScheduledJobNavigation } from '../../scheduled_job_management/ScheduledJobRouter';
import DesktopNavBar, { MenuProps } from './DesktopNavBar';
import { useMedia } from './MediaHook';
import MobileNavBar from './MobileNavBar';

interface OnRenderMenuItemsProps {
  item: IContextualMenuItemProps;
}
const iconStyle = { verticalAlign: 'middle', marginRight: 5 };

const RenderMenuItem: React.FC<OnRenderMenuItemsProps> = (props) => {
  const { maintainShortcut } = useMaintainShortcut();
  const { displayShortcutIcon } = useDisplayIcon();

  const isDashboard = window.location.href.indexOf('dashboard') !== -1;
  return (
    <>
      <div className="ms-ContextualMenu-link" style={{ display: 'flex' }}>
        <span className="ms-ContextualMenu-itemText" style={{ margin: '0px 4px' }}>
          {props.item.item.text}
        </span>
      </div>
      {isDashboard && (
        <div style={{ marginLeft: 'auto' }}>
          <TooltipHost
            directionalHint={DirectionalHint.rightCenter}
            closeDelay={1000}
            tooltipProps={{ maxWidth: '1200' }}
            content={<span>Add to the shortcuts.</span>}
            className={mergeStyles({ border: '1px solid #C84504' })}
          >
            {displayShortcutIcon(props.item.item.text) && (
              <Icon
                iconName="AddBookmark"
                style={iconStyle}
                onClick={(e) => {
                  maintainShortcut(props.item.item.text, 'CREATE');
                  e.stopPropagation();
                }}
              />
            )}
          </TooltipHost>
        </div>
      )}
    </>
  );
};

export const Navigation = (props: { title: string }) => {
  const { isMobile } = useMedia();
  const {
    loggedIn,
    hasPermission,
    hasAnyPermission,
    isInternalUser,
    currentClientId,
    currentClientName,
    isCasualUser,
    hasRole,
    isFloatingARE,
    isFeatureFlagEnabled,
  } = useAuth();
  const { navigateToCreateClient, navigateToSelfRegisterClient } = useClientNavigation();
  const { navigateToApplyLicence } = useApplyLicenceNavigation();
  const { navigateToModifyLicence } = useModifyLicenceNavigation();
  const { navigateToModifyLicenceForm22 } = useModifyLicenceForm22Navigation();
  const { navigateToCancelLicence } = useCancelLicenceNavigation();
  const { navigateToApplyManagementRight, navigateToFormTwo, navigateToFormFour, navigateToFormFive, navigateToFormSix, navigateToFormTwentyTwo } =
    useManagementRightNavigator();
  const { navigateToLicenceTransferRequest } = useLicenceTransferRequestNavigation();
  const { navigateToDeclineLicenceTransferRequest } = useDeclineLicenceTransferRequestNavigation();
  const { navigateToWithdrawLicenceTransferRequest } = useWithdrawLicenceTransferRequestNavigation();
  const { navigateToApplyLicenceToSupply } = useApplyLicenceToSupplyNavigation();
  const { navigateToSearchEquipment, navigateToSearchSpectrumMask, navigateToSearchSpectrum, navigateToGeoReferenceConverter } =
    useLicenceReferenceDataNavigator();
  const {
    navigateToSearchClient,
    navigateToSearchLicence,
    navigateToSearchLicenceToSupply,
    navigateToSearchAntenna,
    navigateToSearchLocation,
    navigateToSearchManagementRights,
    navigateToSearchCertificate,
    navigateToSearchCallsign,
    navigateToSearchMRForForm11,
    navigateToSearchSLForForm11,
    navigateToSearchMRForForm18,
    navigateToSearchSLForForm18,
    navigateToSearchAton,
    navigateToSearchMRForForm20,
    navigateToSearchSLForForm20,
    navigateToSearchDocumentDeliveryLog,
    navigateToSearchMRForForm21,
    navigateToSearchSLForForm21,
    navigateToSearchRenewalFee,
    navigateToSearchApplicationFee,
    navigateToSearchTask,
  } = useSearchNavigation();
  const { navigateToSearchApproval } = useApprovalNavigation();
  const { navigateToScheduledJobs } = useScheduledJobNavigation();
  const { navigateToOutageNotifications } = useOutageNotificationNavigation();
  const { navigateToAzureSearch, navigateToBpmManagement, navigateToFeatureFlagManagement } = useDevelopmentNavigation();
  const licenceApi = useLicenceApi();
  const { navigateToCreateInernalUser, navigateToSearchUserToMaintain } = useUserNavigation();

  const { navigateToCreateCertificate, navigateToCreateCallsign } = useCertificateNavigation();
  const investigationRouter = useInvestigationRouter();
  const { maintainShortcut } = useMaintainShortcut();
  const { displayShortcutIcon } = useDisplayIcon();
  const { navigateToCreateAton } = useAtonNavigation();
  const { navigateToSearchTemplates } = useCorrespondenceNavigation();
  const { navigateToWelcomeTextMaintenance } = useWelcomeTextNavigation();

  const menus: MenuProps[] = [
    {
      label: 'Search',
      show: true,
      items: [
        {
          key: 'search-licence',
          text: 'Licences',
          show: true,
          onClick: () => navigateToSearchLicence(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-management-rights',
          text: 'Management Rights',
          show: true,
          onClick: () => navigateToSearchManagementRights(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-client',
          text: 'Clients',
          show:
            (hasPermission('SEARCH_ALL_CLIENT') || hasPermission('SEARCH_ACTIVE_CLIENT')) &&
            !(hasRole('ROLE_RIGHT_HOLDER') || hasRole('ROLE_PRIVATE_BAND_MANAGER')),
          onClick: () => navigateToSearchClient(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-licence-to-supply',
          text: 'Licences to Supply Radio Transmitters',
          show: true,
          onClick: () => navigateToSearchLicenceToSupply(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-certificate',
          text: 'Certificates',
          show: true,
          onClick: () => navigateToSearchCertificate(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-callsign',
          text: 'Callsigns',
          show: true,
          onClick: () => navigateToSearchCallsign(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'grid-reference-converter',
          text: 'NZ Grid Reference Converter',
          show: true,
          onClick: () => navigateToGeoReferenceConverter(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-aton',
          text: 'AtoNs',
          show: true,
          onClick: () => navigateToSearchAton(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-task',
          text: 'Applications',
          show: !(!loggedIn || isCasualUser() || hasRole('ROLE_GENERAL_USER')),
          onClick: () => navigateToSearchTask(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
      ],
    },
    {
      label: 'Apply/Create',
      show: !!loggedIn,
      items: [
        {
          key: 'create-new-licence',
          text: 'Licence',
          show: hasPermission('CREATE_APPLICATION'),
          onClick: () => navigateToApplyLicence(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'create-client',
          text: 'Client',
          show: hasPermission('CREATE_CLIENT') || hasPermission('CREATE_RESTRICTED_ORG_CLIENT'),
          onClick: () => navigateToCreateClient(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'createAton',
          text: 'AtoN',
          show: hasPermission('CREATE_ATON'),
          onClick: () => navigateToCreateAton(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'createCallsign',
          text: 'Callsign',
          show: hasPermission('CREATE_CALLSIGN'),
          onClick: () => navigateToCreateCallsign(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'createCertificate',
          text: 'Certificate',
          show: hasPermission('CREATE_CERTIFICATE'),
          onClick: () => navigateToCreateCertificate(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'create-new-licence-to-supply',
          text: 'Licence to Supply Radio Transmitters',
          show: hasPermission('CREATE_LICENCE_TO_SUPPLY'),
          onClick: () => navigateToApplyLicenceToSupply(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'create-internal-user',
          text: 'Internal User',
          show: hasPermission('CREATE_INT_USER'),
          onClick: () => navigateToCreateInernalUser(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'register-new-client',
          text: 'Register new client',
          show: hasPermission('SELF_REG_CLIENT') || isCasualUser(),
          onClick: () => navigateToSelfRegisterClient(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'new-management-right',
          text: 'Management Right',
          show: hasRole('ROLE_RSM_RADIO_ENGINEER'),
          onClick: () => navigateToApplyManagementRight(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
      ],
    },
    {
      label: 'Maintain',
      show: !isCasualUser() && !!loggedIn,
      items: [
        {
          key: 'licence',
          text: 'Licence',
          show: true,
          subMenuItems: [
            {
              key: 'cancel-current-licence',
              text: 'Cancel',
              show: hasPermission('CANCEL_LICENCE') && !isFloatingARE(),
              onClick: () => navigateToCancelLicence(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'modify-current-licence',
              text: 'Modify',
              show: hasPermission('CREATE_APPLICATION'),
              onClick: () => navigateToModifyLicence(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'transfer-licence-request',
              text: 'Transfer',
              show: hasPermission('CREATE_LICENCE_TRANSFER_REQUEST') && !isFloatingARE(),
              onClick: () => navigateToLicenceTransferRequest(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'accept-decline-transfer-request',
              text: 'Accept/Decline Transfer',
              show: hasPermission('DECLINE_LICENCE_TRANSFER_REQUEST') && !isFloatingARE(),
              onClick: () => navigateToDeclineLicenceTransferRequest(),
            },
            {
              key: 'withdraw-transfer-request',
              text: 'Withdraw Transfer',
              show: hasPermission('WITHDRAW_LICENCE_TRANSFER_REQUEST') && !isFloatingARE(),
              onClick: () => navigateToWithdrawLicenceTransferRequest(),
            },
            {
              key: 'modify-current-licence-form22',
              text: 'Form 22',
              show: hasRole('ROLE_REGISTRAR'),
              onClick: () => navigateToModifyLicenceForm22(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
          ],
        },
        {
          key: 'management-right',
          text: 'Management right',
          show: !isCasualUser() && (hasRole('ROLE_PRIVATE_BAND_MANAGER') || hasRole('ROLE_CROWN_BAND_MANAGER')),
          subMenuItems: [
            {
              key: 'form-2',
              text: 'Form 2 (Transfer Management Right)',
              show: true,
              onClick: () => navigateToFormTwo(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'form-4',
              text: 'Form 4 (Modify Power Floor)',
              show: true,
              onClick: () => navigateToFormFour(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'form-5',
              text: 'Form 5 (Modify Protection Limit)',
              show: true,
              onClick: () => navigateToFormFive(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'form-6',
              text: 'Form 6 (Modify AFELs)',
              show: true,
              onClick: () => navigateToFormSix(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
          ],
        },
        {
          key: 'management-right',
          text: 'Management right',
          show: !isCasualUser() && hasRole('ROLE_REGISTRAR'),
          subMenuItems: [
            {
              key: 'form-22',
              text: 'Form 22 (Correction of register)',
              show: hasRole('ROLE_REGISTRAR'),
              onClick: () => navigateToFormTwentyTwo(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
          ],
        },
        {
          key: 'mortgage',
          text: 'Mortgage',
          show: hasAnyPermission(['SEARCH_MR_FOR_MORTGAGE', 'SEARCH_SPL_FOR_MORTGAGE', 'SEARCH_MORTGAGE_TO_DISCHARGE'])!,
          subMenuItems: [
            {
              key: 'memorandum-of-mortgage',
              text: 'Memorandum of mortgage',
              show: hasAnyPermission(['SEARCH_MR_FOR_MORTGAGE', 'SEARCH_SPL_FOR_MORTGAGE'])!,
              items: [
                {
                  key: 'search-mr-for-mortgage',
                  text: 'Search Management Right(s) to Mortgage',
                  show: hasPermission('SEARCH_MR_FOR_MORTGAGE'),
                  onClick: () => navigateToSearchMRForForm11(),
                },
                {
                  key: 'search-sl-for-mortgage',
                  text: 'Search Spectrum Licence(s) to Mortgage',
                  show: hasPermission('SEARCH_SPL_FOR_MORTGAGE'),
                  onClick: () => navigateToSearchSLForForm11(),
                },
              ],
            },
            {
              key: 'discharge-of-mortgage',
              text: 'Discharge of mortgage',
              show: hasPermission('SEARCH_MORTGAGE_TO_DISCHARGE'),
              items: [
                {
                  key: 'search-mr-for-mortgage',
                  text: 'Search Management Right Mortgage to Discharge',
                  show: hasPermission('SEARCH_MORTGAGE_TO_DISCHARGE'),
                  onClick: () => navigateToSearchMRForForm18(),
                },

                {
                  key: 'search-sl-for-mortgage',
                  text: 'Search Spectrum Licence Mortgage to Discharge',
                  show: hasPermission('SEARCH_MORTGAGE_TO_DISCHARGE'),
                  onClick: () => navigateToSearchSLForForm18(),
                },
              ],
            },
          ],
        },
        {
          key: 'caveat',
          text: 'Caveat',
          show: hasAnyPermission(['SEARCH_MR_FOR_CAVEAT', 'SEARCH_SPL_FOR_CAVEAT'])!,
          subMenuItems: [
            {
              key: 'lodgement-of-caveat',
              text: 'Lodgement of Caveat',
              show: !isCasualUser(),
              items: [
                {
                  key: 'search-mr-for-mortgage',
                  text: 'Search Management Right(s) to Caveat',
                  onClick: () => navigateToSearchMRForForm20(),
                },
                {
                  key: 'search-sl-for-mortgage',
                  text: 'Search Spectrum Licence(s) to Caveat',
                  onClick: () => navigateToSearchSLForForm20(),
                },
              ],
            },
            {
              key: 'withdraw-of-caveat',
              text: 'Withdrawal my caveats',
              show: !isCasualUser(),
              items: [
                {
                  key: 'search-mr-for-mortgage',
                  text: 'From management right(s)',
                  onClick: () => navigateToSearchMRForForm21(),
                },
                {
                  key: 'search-sl-for-mortgage',
                  text: 'From spectrum licence(s)',
                  onClick: () => navigateToSearchSLForForm21(),
                },
              ],
            },
          ],
        },
        {
          key: 'callsign',
          text: 'Callsign',
          show: !isCasualUser() && !hasRole('ROLE_REGISTRAR'),
          onClick: () => navigateToSearchCallsign(),
        },
      ],
    },
    {
      label: 'Pay Fees',
      show: isInternalUser! || currentClientId !== undefined,
      items: [
        {
          key: 'quick-pay-admin',
          show: isInternalUser!,
          onClick: () =>
            licenceApi
              .searchPaymentTransactions({ returnUrl: `${window.location.origin}/ui/app/dashboard` })
              .then((result) => window.open(result.redirectUrl, '_self')),
          onRenderContent: () => (
            <div className="ms-ContextualMenu-link">
              <span className="ms-ContextualMenu-itemText" style={{ margin: '0px 4px' }}>
                View All Transactions
              </span>
              <TooltipHint
                id="quick-pay-admin-tool-tip"
                content={
                  <span>
                    Viewing all transactions requires you to be connected to the MBIE network otherwise an error will occur. <br />
                    You will be prompted for your active directory credentials prior to connecting to Quick Pay.
                  </span>
                }
              />
              {window.location.href.indexOf('dashboard') !== -1 && displayShortcutIcon('View All Transactions') && (
                <Icon
                  iconName="AddBookmark"
                  style={iconStyle}
                  onClick={(e) => {
                    maintainShortcut('View All Transactions', 'CREATE');
                    e.stopPropagation();
                  }}
                />
              )}
            </div>
          ),
        },
        {
          key: 'search-payment-transactions',
          text: `View  ${currentClientName} Transactions`,
          show: currentClientId !== undefined,
          onClick: () =>
            licenceApi
              .searchPaymentTransactions({ clientId: currentClientId, returnUrl: `${window.location.origin}/ui/app/dashboard` })
              .then((result) => window.open(result.redirectUrl, '_self')),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'pay-annual-licence-renewal-fee',
          text: 'Pay annual licence fees (Renewal)',
          show: hasPermission('SEARCH_RENEWAL_FEE') || !!currentClientId,
          onClick: () => navigateToSearchRenewalFee(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'pay-annual-licence-application-fee',
          text: 'Pay annual licence fees (Applications)',
          show: hasPermission('SEARCH_APPLICATION_FEE') || !!currentClientId,
          onClick: () => navigateToSearchApplicationFee(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
      ],
    },
    {
      label: 'Reference Data',
      show: !!hasAnyPermission(['VIEW_LOCATION', 'VIEW_SPEC_RECORD', 'VIEW_ANTENNA', 'VIEW_EQUIPMENT', 'VIEW_SPECTRUM_MASK']),
      items: [
        {
          key: 'antennas',
          text: 'Antenna',
          show: hasPermission('VIEW_ANTENNA'),
          onClick: () => navigateToSearchAntenna(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'equipment',
          text: 'Equipment',
          show: hasPermission('VIEW_EQUIPMENT'),
          onClick: () => navigateToSearchEquipment(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'locations',
          text: 'Location',
          show: hasPermission('VIEW_LOCATION'),
          onClick: () => navigateToSearchLocation(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'spectrum',
          text: 'Spectrum',
          show: hasPermission('VIEW_SPEC_RECORD'),
          onClick: () => navigateToSearchSpectrum(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'spectrumMask',
          text: 'Spectrum Mask',
          show: hasPermission('VIEW_SPECTRUM_MASK'),
          onClick: () => navigateToSearchSpectrumMask(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
      ],
    },
    {
      label: 'Investigations',
      show:
        isFeatureFlagEnabled('ENABLE_INVESTIGATION') &&
        !!hasAnyPermission([
          'BASIC_CREATE_COMPLAINT',
          'BASIC_UPDATE_COMPLAINT',
          'BASIC_VIEW_COMPLAINT',
          'CREATE_AUDIT',
          'CREATE_COMPLAINT',
          'SEARCH_COMPLAINT',
          'VIEW_AUDIT',
          'VIEW_COMPLAINT',
        ]),
      items: [
        {
          key: 'createAudit',
          text: 'Audit',
          show: !!hasPermission('CREATE_AUDIT'),
          onClick: () => investigationRouter.navigateToCreateAudit(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'createComplaint',
          text: 'Complaint',
          show: !!hasAnyPermission(['BASIC_CREATE_COMPLAINT', 'CREATE_COMPLAINT']),
          onClick: () => investigationRouter.navigateToCreateComplaint(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'searchComplaint',
          text: 'Search',
          show: !!hasPermission('SEARCH_COMPLAINT'),
          onClick: () => investigationRouter.navigateToSeachCases(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
      ],
    },
    {
      label: 'Correspondence',
      show: !!isInternalUser,
      items: [
        {
          key: 'maintainTemplates',
          text: 'Maintain Templates ',
          show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
          onClick: () => navigateToSearchTemplates(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
        {
          key: 'search-document-delivery-log',
          text: 'Document Delivery Log',
          show: !!isInternalUser,
          onClick: () => navigateToSearchDocumentDeliveryLog(),
          onRenderContent: (item) => <RenderMenuItem item={item} />,
        },
      ],
    },
    {
      label: 'RSM',
      show: !!isInternalUser,
      items: [
        {
          key: 'admin',
          text: 'Admin',
          show: !!hasAnyPermission(['VIEW_APPROVAL', 'VIEW_DAY_END_JOB']),
          subMenuItems: [
            {
              key: 'maintainApproval',
              text: 'Maintain Approval List',
              show: hasPermission('VIEW_APPROVAL'),
              onClick: () => navigateToSearchApproval(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'camundaCockpit',
              text: 'Camunda Cockpit',
              show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
              onClick: () => window.open('/camunda/app/cockpit/', '_blank')?.focus(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'scheduledJobs',
              text: 'Manage Scheduled Jobs',
              show: hasPermission('VIEW_DAY_END_JOB'),
              onClick: () => navigateToScheduledJobs(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'outageNotifications',
              text: 'Outage Notification',
              show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
              onClick: () => navigateToOutageNotifications(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'maintainUserAccount',
              text: 'Maintain User Account',
              show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
              onClick: () => navigateToSearchUserToMaintain(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
            {
              key: 'welcomeTextMaintenance',
              text: 'Welcome Text Maintenance',
              show: hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
              onClick: () => navigateToWelcomeTextMaintenance(),
              onRenderContent: (item) => <RenderMenuItem item={item} />,
            },
          ],
        },
      ],
    },
    {
      label: 'Tools',
      show: (ENVIRONMENT === 'LOCAL' || ENVIRONMENT === 'CI' || ENVIRONMENT === 'TST') && hasRole('ROLE_SYSTEM_ADMINISTRATOR'),
      items: [
        {
          key: 'azure-search',
          text: 'Azure search',
          show: true,
          onClick: () => navigateToAzureSearch(),
        },
        {
          key: 'bpm-management',
          text: 'Bpm management',
          show: true,
          onClick: () => navigateToBpmManagement(),
        },

        {
          key: 'feature-flag-management',
          text: 'Feature flag management',
          show: true,
          onClick: () => navigateToFeatureFlagManagement(),
        },
      ],
    },
  ];

  return (
    <>
      {!isMobile && <DesktopNavBar menus={menus} title={props.title} />}
      {isMobile && <MobileNavBar menus={menus} title={props.title} />}
    </>
  );
};

export interface NavigationContextProps {
  setFormikContext: (formikContext: FormikContextType<any>) => void;
  formikContext?: FormikContextType<any>;
  setMode: (mode: FormMode) => void;
  mode?: FormMode;
}

// The purpose of this context is to pass the form FormikContext to the nav so that we can bring up the confirm modal
// when unsaved form changes and a menu item clicked.
export const NavigationContext = React.createContext<NavigationContextProps>({
  setFormikContext: () => {},
  setMode: () => {},
});
