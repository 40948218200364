import { IModalProps, mergeStyles, Modal as FabricModal } from '@fluentui/react';
import React from 'react';

import { useMedia } from '../../layout/MediaHook';

export interface ModalProps extends IModalProps {
  title: string;
  width?: string;
  id?: string;
}

const Dialog: React.FC<ModalProps> = (props) => {
  const headingStyle = mergeStyles({
    padding: '2px 0',
    fontSize: '16px',
    marginBottom: '0.5em',
  });

  const { isMobile } = useMedia();

  const modalBoxStyle = mergeStyles({
    padding: '30px',
    fontSize: '16px',
    boxSizing: 'border-box',
    width: isMobile ? '100%' : '640px',
  });

  return (
    <FabricModal {...props} className={props.className}>
      <div className={modalBoxStyle} data-automation-id={props.id} style={{ width: props.width }}>
        <h2 className={headingStyle}>{props.title}</h2>
        {props.children}
      </div>
    </FabricModal>
  );
};

export const DialogButtonsContainer: React.FC<{ children?: React.ReactNode }> = (props) => {
  const className = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '36px',
    gap: '10px',
  });

  return <div className={className}>{props.children}</div>;
};

export default Dialog;
