import { LicenceRadiationPatternDto } from 'api-client';

export const OUTER_CIRCLE_LINE_COLOUR = '#dddddd';
export const OUTER_CIRCLE_LINE_WIDTH = 1;
export const SECTOR_CIRCLE_LINE_COLOUR = '#dddddd';
export const SECTOR_CIRCLE_LINE_WIDTH = 1;
export const DEGREE_LINE_COLOUR = '#dddddd';
export const DEGREE_LINE_WIDTH = 0.2;
export const TEN_DEGREE_FONT = "12px 'arial'";
export const TEN_DEGREE_FONT_COLOUR = '#414042';
export const TEN_DEGREE_LINE_WIDTH = 0.5;
export const SECTOR_VALUE_FONT = "11px 'arial'";
export const SECTOR_VALUE_FONT_COLOUR = '#414042';
export const SECTOR_VALUE_LINE_COLOUR = '#006ECC';
export const SECTOR_VALUE_LINE_WIDTH = 1.5;
export const CENTRE_X = 250;
export const CENTRE_Y = 250;
export const RADIUS = 220;
const WIDTH = 500;
const HEIGHT = 500;

export function setupRadiationPatternChartVars(radiationPatterns: LicenceRadiationPatternDto[]) {
  //  Calculate min and max
  let min = Number.MAX_SAFE_INTEGER;
  let max = Number.MIN_SAFE_INTEGER;
  for (let i = 0; i < radiationPatterns.length; i++) {
    if (radiationPatterns[i].value < min) {
      min = radiationPatterns[i].value;
    }
    if (radiationPatterns[i].value > max) {
      max = radiationPatterns[i].value;
    }
  }
  // Calculate range and incremement as per business rules
  const range = Math.abs(max - min);
  const increment = range > 50 ? roundUpTo(range / 10, 3) : 5;
  // Round our min, max and range
  const calculatedMin = roundDownTo(min - 1, increment);
  const calculatedMax = roundUpTo(max, increment);
  const calculatedRange = Math.abs(calculatedMax - calculatedMin);

  // Build sectors
  const numSectors = calculatedRange / increment;
  const sectorRatios = [];
  const sectorValues = [];
  for (let i = 0; i < numSectors; i++) {
    sectorValues.push(calculatedMax - i * increment);
    sectorRatios.push((sectorValues[i] - calculatedMin) / (calculatedMax - calculatedMin));
  }

  // Build ratios. ratios are how much the value is as 0..1 between its value
  // and our range
  const ratios = radiationPatterns.map((pattern) => (pattern.value - calculatedMin) / (calculatedMax - calculatedMin));

  return { numSectors, sectorValues, sectorRatios, ratios };
}

export function scaleCanvas(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d')!;
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  return ctx;
}

export function getCanvasWidth(width = WIDTH) {
  return width * window.devicePixelRatio;
}

export function getCanvasHeight(height = HEIGHT) {
  return height * window.devicePixelRatio;
}

export function drawTooltip(
  tooltipCtx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radiationPattern: LicenceRadiationPatternDto,
  maxWidth = WIDTH,
) {
  const bearingFromText = `Bearing (From): ${radiationPattern.bearingFrom?.toFixed(1)}`;
  const bearingToText = `Bearing (To): ${radiationPattern.bearingTo?.toFixed(1)}`;
  const bearingValueText = `Bearing value: ${radiationPattern.value?.toFixed(1)}`;

  tooltipCtx.beginPath();
  tooltipCtx.save();
  // Use the identity matrix while clearing the canvas
  tooltipCtx.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

  tooltipCtx.font = '14px Fira Sans';
  const textWidth =
    Math.max(
      tooltipCtx.measureText(bearingFromText).width,
      tooltipCtx.measureText(bearingToText).width,
      tooltipCtx.measureText(bearingValueText).width,
    ) + 20;
  tooltipCtx.translate(Math.min(x, maxWidth - textWidth), Math.max(y - 65, 0));
  tooltipCtx.clearRect(0, 0, textWidth, 60);
  tooltipCtx.rect(0, 0, textWidth, 60);
  tooltipCtx.fillStyle = 'white';
  tooltipCtx.fill();
  tooltipCtx.strokeStyle = DEGREE_LINE_COLOUR;
  tooltipCtx.strokeRect(0, 0, textWidth, 60);

  tooltipCtx.beginPath();
  tooltipCtx.fillStyle = 'rgb(50, 49, 48)';
  tooltipCtx.fillText(bearingFromText, 10, 20);
  tooltipCtx.fillText(bearingToText, 10, 35);
  tooltipCtx.fillText(bearingValueText, 10, 50);
  tooltipCtx.restore();
}

export function getPatternDescription(radiationPatterns: LicenceRadiationPatternDto[]) {
  return radiationPatterns
    .map((pattern) => `bearing ${pattern.value} dBW from ${pattern.bearingFrom} degrees to ${pattern.bearingTo} degrees`)
    .join(', ');
}

function roundDownTo(val: number, scale: number) {
  return Math.floor(val / scale) * scale;
}

function roundUpTo(val: number, scale: number) {
  return Math.ceil(val / scale) * scale;
}
