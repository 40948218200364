import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';

import { CaseStatusEnum, ManagementRightsSearchResult } from 'api-client';
import { AuditManagementRightsDto } from 'api-client/api';

import { Grid2 } from 'ui-library';
import { SingleTagPicker } from 'ui-library/tagpicker';

import ReadonlyData from 'common/controls/items/ReadonlyData';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { AuditFormProps } from 'investigation/interfaces';
import { usePublicApi } from 'public/PublicApiHook';

const AuditLinkedManagementRightsSection = () => {
  const { mode } = useForm();
  const { values, dirty } = useFormikContext<AuditFormProps>();
  const { linkedManagementRights } = values;

  const { searchManagementRightsPublic } = usePublicApi();

  if (isEmpty(linkedManagementRights) && values.statusEnum && CaseStatusEnum.IPRG !== values.statusEnum) {
    return <></>;
  }

  const canBeModified = mode === 'CREATE' || mode === 'EDIT';

  return (
    <SectionHeading title="Linked management rights">
      <Grid2>
        {canBeModified && (
          <Grid2.Col lg={8}>
            <SingleTagPicker
              label="Search management rights"
              name="linkedManagementRights"
              onResolveSuggestions={async (searchText: string) => {
                const { results = [] } = await searchManagementRightsPublic({
                  searchText,
                });

                return results.map(({ id, clientId, manager, managementRightsStatus }: ManagementRightsSearchResult) => {
                  return {
                    key: id,
                    name: `${id} | ${manager} | ${managementRightsStatus} | ${clientId}`,
                    data: { id, managerId: clientId, managerName: manager, status: managementRightsStatus } as AuditManagementRightsDto,
                  };
                });
              }}
              pickerSuggestionsProps={{
                loadingText: 'Searching for management rights',
                noResultsFoundText: 'No management rights found',
                suggestionsHeaderText: 'Suggested management rights',
              }}
              placeholder="Type MR number, licence number , manager's client number or manager's client name"
            />
          </Grid2.Col>
        )}
        <Grid2.Col lg={3} colStart={1}>
          <ReadonlyData
            label="MR number"
            href={`/ui/app/licence/management-rights/view/${linkedManagementRights?.data?.id}`}
            value={linkedManagementRights?.data?.id}
          />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <ReadonlyData label="Status" value={linkedManagementRights?.data?.status} />
        </Grid2.Col>

        <Grid2.Col lg={3} colStart={1}>
          <ReadonlyData label="Client number" value={linkedManagementRights?.data?.clientId} />
        </Grid2.Col>
        <Grid2.Col lg={3}>
          <ReadonlyData label="Manager" value={linkedManagementRights?.data?.managerName} />
        </Grid2.Col>
      </Grid2>
    </SectionHeading>
  );
};

export default AuditLinkedManagementRightsSection;
