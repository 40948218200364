import { useFormikContext } from 'formik';
import React from 'react';

import { CaseStatusEnum, CaseTypeCodeEnum } from 'api-client';

import { Dropdown, Grid2 } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import DatePicker from 'common/controls/inputs/DatePicker';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import { FormMode } from 'common/form/Form';
import { useForm } from 'common/form/FormHook';
import HorizontalDivider from 'common/layout/HorizontalDivider';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { COMPLAINT_RESULT_OPTIONS } from 'common/reference/referenceData';
import { formatDate } from 'common/utils/dateUtils';
import CaseAllocatedTo from 'investigation/components/CaseAllocatedTo';
import CaseAssociatedTo from 'investigation/components/CaseAssociatedTo';
import CaseOrigin from 'investigation/components/CaseOrigin';
import CaseReadOnlyFields from 'investigation/components/CaseReadOnlyFields';
import { useCaseClassificationOptions } from 'investigation/hooks';
import { ComplaintFormProps } from 'investigation/interfaces';

import ComplaintQuestionnaire from './ComplaintQuestionnaire';

const CaseOutcome = () => {
  return (
    <Grid2>
      <Grid2.Col colStart={1}>
        <ReadOnlyField
          label="Final result"
          name="finalResult"
          renderFormat={(finalResult) => COMPLAINT_RESULT_OPTIONS.find(({ key }) => key === finalResult)?.text}
        />
      </Grid2.Col>
      <Grid2.Col colStart={1}>
        <ReadOnlyField label="Final result notes" name="finalResultNotes" />
      </Grid2.Col>
    </Grid2>
  );
};

const MaintainComplaintOnlyFields = () => {
  const { mode } = useForm();
  const { values } = useFormikContext<ComplaintFormProps>();

  return (
    <>
      <HorizontalDivider />
      <Grid2>
        <Grid2.Col lg={7}>
          <Grid2>
            <Grid2.Col lg={3}>
              {mode === 'EDIT' || values.startUser ? (
                <DatePicker dateOnlyAsString label="Start date" name="startDate" maxDate={new Date()} />
              ) : (
                <ReadOnlyField name="just_a_placeholder" label="Start date" />
              )}
            </Grid2.Col>
            <Grid2.Col colStart={1}>
              <Dropdown
                label="Radio communication service of interest"
                name="serviceOfInterestId"
                options={values.serviceOfInterestOptions}
                required
              />
            </Grid2.Col>
            <Grid2.Col colStart={1}>
              <Dropdown label="Source code" name="sourceCodeId" options={values.sourceCodeOptions} required />
            </Grid2.Col>
          </Grid2>
        </Grid2.Col>
      </Grid2>
    </>
  );
};

interface ComplaintFormContentProps {
  setModeForm?: (mode: FormMode) => void;
}

const ComplaintFormContent = ({ setModeForm }: ComplaintFormContentProps) => {
  const { hasRole } = useAuth();
  const { values, setFieldValue } = useFormikContext<ComplaintFormProps>();
  const { caseClassificationOptions } = useCaseClassificationOptions(values.caseTypeId);
  const { mode } = useForm();
  const isCaseClosed = values.statusEnum === CaseStatusEnum.CLSD;
  const isServiceCentreUser = hasRole('ROLE_MSG_SERVICE_CENTRE');

  React.useEffect(() => {
    if (setModeForm) {
      setModeForm(mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <>
      <SectionHeading title="Interference complaint details" />
      <Grid2>
        {values.caseNumber && (
          <Grid2.Col>
            <Grid2>
              <Grid2.Col lg={6}>
                <ReadOnlyField name="caseNumber" label="Case number" />
              </Grid2.Col>
              {isCaseClosed && (
                <>
                  <Grid2.Col lg={4}>
                    <CaseOutcome />
                  </Grid2.Col>
                  <Grid2.Col>
                    <HorizontalDivider />
                  </Grid2.Col>
                </>
              )}
            </Grid2>
          </Grid2.Col>
        )}

        <Grid2.Col lg={3} colStart={1}>
          <Dropdown
            label="Case type"
            name="caseTypeId"
            options={values.caseTypeOptions}
            required
            disabled={isServiceCentreUser}
            onChange={() => {
              setFieldValue('caseClassificationId', null);
            }}
          />
        </Grid2.Col>
        <Grid2.Col lg={3} colStart={1}>
          <Dropdown disabled={!values.caseTypeId} label="Classification" name="caseClassificationId" options={caseClassificationOptions} required />
        </Grid2.Col>

        <Grid2.Col colStart={1}>
          <CaseAssociatedTo />
        </Grid2.Col>

        <Grid2.Col colStart={1} lg={8}>
          <TextField name="notes" label="Notes" multiline maxLength={400} showMaxHint />
        </Grid2.Col>

        {values.caseTypeId && values.caseTypeId === values.caseTypeOptions?.find(({ data }) => CaseTypeCodeEnum.DOMESTIC === data?.code)?.key && (
          <Grid2.Col colStart={1}>
            <ComplaintQuestionnaire />
          </Grid2.Col>
        )}

        {(!isCaseClosed || values.originCaseId) && (
          <Grid2.Col colStart={1}>
            <CaseOrigin />
          </Grid2.Col>
        )}

        <Grid2.Col colStart={1}>
          <CaseAllocatedTo />
        </Grid2.Col>

        {!isServiceCentreUser && values.caseNumber && (
          <Grid2.Col colStart={1}>
            <MaintainComplaintOnlyFields />
          </Grid2.Col>
        )}

        {isCaseClosed && (
          <Grid2.Col colStart={1}>
            <ReadOnlyField label="End date" name="endDate" renderFormat={(endDate) => formatDate(endDate)} />
          </Grid2.Col>
        )}

        {values.caseNumber && (
          <Grid2.Col colStart={1}>
            <CaseReadOnlyFields />
          </Grid2.Col>
        )}
      </Grid2>
    </>
  );
};

export default ComplaintFormContent;
