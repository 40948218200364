import * as React from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'common/auth/ProtectedRoute';

const CreateAtonPage = React.lazy(() => import('./create/CreateAtonPage'));
const MaintainAtonPage = React.lazy(() => import('./maintain/MaintainAtonPage'));

const AtonRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <ProtectedRoute path={`${path}/create`} component={CreateAtonPage} permission={['CREATE_ATON']} />
        <ProtectedRoute path={`${path}/aton/create`} component={CreateAtonPage} permission={['CREATE_ATON']} />
        <ProtectedRoute path={`${path}/aton/maintain/:atonId`} component={MaintainAtonPage} permission={['UPDATE_ATON']} />
        <ProtectedRoute path={`${path}/maintain/:atonId`} component={MaintainAtonPage} />
      </Switch>
    </>
  );
};

export const useAtonNavigation = () => {
  const history = useHistory();

  return {
    navigateToCreateAton: () => history.push('/ui/app/aton/create'),
    navigateToMaintainAton: (atonId: number) => history.push('/ui/app/aton/maintain/' + atonId),
  };
};

export default AtonRouter;
