import { DefaultButton, Label } from '@fluentui/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { Grid } from 'ui-library';

import ReadonlyData from 'common/controls/items/ReadonlyData';

interface Props {
  allowChangeType?: boolean;
  onLicenceTypeChange: () => void;
  showLicenceTypeApply?: boolean;
}

const ServiceLevelReadOnly: React.FC<Props> = (props) => {
  const formikContext = useFormikContext<any>();
  const renderServiceLevelsReadOnly = (): string => {
    if (!formikContext.values.licenceTypeId) return '';
    return (
      [formikContext.values.level1, formikContext.values.level2, formikContext.values.level3, formikContext.values.licenceTypeName]
        .filter(Boolean)
        .join(' / ') + ` (${formikContext.values.licenceTypeDisplayCode})`
    );
  };

  return (
    <Grid.Row>
      <Grid.Col sm={12} lg={5}>
        <ReadonlyData label="Licence type" value={renderServiceLevelsReadOnly()} />
      </Grid.Col>
      {props.allowChangeType && (
        <Grid.Col sm={12} lg={2}>
          <Label>&nbsp;</Label>
          <div>
            <DefaultButton text="Change licence type" id="change-licence-type-button" onClick={props.onLicenceTypeChange} />
          </div>
        </Grid.Col>
      )}
    </Grid.Row>
  );
};

export default ServiceLevelReadOnly;
