import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useAppRouter } from 'Routes';
import { useFormikContext } from 'formik';
import React from 'react';

import { CaseFinalResultEnum } from 'api-client';

import { Dropdown, Grid2 } from 'ui-library';
import { Form } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import { TextField } from 'common/controls/inputs/TextField';
import usePage from 'common/layout/PageHook';
import { COMPLAINT_RESULT_OPTIONS } from 'common/reference/referenceData';
import { useInvestigationApi } from 'investigation/hooks';

type CloseComplaintFormProps = {
  id?: number;
  startDate?: string;
  endDate?: string;
  finalResult?: CaseFinalResultEnum;
  finalResultNotes?: string;
};

const ActionCardCloseComplaint = (closeCompliant: CloseComplaintFormProps) => {
  const { closeCase } = useInvestigationApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  const handleSubmitClose = (values: CloseComplaintFormProps) => {
    closeCase(values).then(() => {
      navigateToDashboard();
      setPageInstruction('Complaint successfully closed', MessageBarType.success);
    });
  };

  return (
    <Form<CloseComplaintFormProps>
      formButtonsOptions={{
        hideBackButton: true,
        hideCancelButton: true,
        hideSubmitButton: true,
      }}
      id="closeComplaint"
      hideFormButtonsTop
      initialValues={closeCompliant}
      isInDialog
      mode="CREATE"
      showFormButtonsBottom
      handleSubmit={handleSubmitClose}
      validationSchema={[{ name: 'finalResult' }, { name: 'endDate' }]}
    >
      <CloseComplaintContent />
    </Form>
  );
};

const CloseComplaintContent = () => {
  const { values, submitForm, resetForm } = useFormikContext<CloseComplaintFormProps>();

  return (
    <MessageBar
      styles={{ root: { marginTop: 10 } }}
      data-automation-id={`close-action`}
      messageBarType={MessageBarType.warning}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="close-case-action" onClick={() => submitForm()}>
              Close case
            </PrimaryButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <DefaultButton data-automation-id="close-case-clear" onClick={() => resetForm()}>
              Clear
            </DefaultButton>
          </div>
        </>
      }
    >
      <Text>Check all details have been completed before closing the case.</Text>
      <Grid2>
        <Grid2.Col colStart={1} lg={6}>
          <DatePicker dateOnlyAsString disabled label="Start date" name="startDate" />
        </Grid2.Col>
        <Grid2.Col lg={6}>
          <DatePicker dateOnlyAsString label="End date" maxDate={new Date()} minDate={values?.startDate} name="endDate" required />
        </Grid2.Col>
        <Grid2.Col colStart={1} lg={12}>
          <Dropdown label="Result" name="finalResult" options={COMPLAINT_RESULT_OPTIONS} required />
        </Grid2.Col>
        <Grid2.Col colStart={1} lg={12}>
          <TextField
            label={values.finalResult === CaseFinalResultEnum.DISCARD ? 'Reason for discarding case' : 'Notes'}
            name="finalResultNotes"
            multiline
            maxLength={400}
            showMaxHint
          />
        </Grid2.Col>
      </Grid2>
    </MessageBar>
  );
};

export default ActionCardCloseComplaint;
