import { Grid } from 'ui-library';

import { useAuth } from 'common/auth/AuthHook';
import Checkbox from 'common/controls/inputs/Checkbox';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';

import EquipmentFields from './EquipmentFields';

const UpdateEquipmentFields = () => {
  const { isInternalUser } = useAuth();

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <ReadOnlyField label="Equipment ID" name={'id'} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <ReadOnlyField label="Identifier" name={'identifier'} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <ReadOnlyField label="Version number" name={'versionNumber'} />
        </Grid.Col>
      </Grid.Row>
      <EquipmentFields />
      {isInternalUser && (
        <>
          <Grid.Row>
            <Grid.Col lg={5} sm={12}>
              <Checkbox name="hidden" label="Hidden" />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <ReadOnlyField label="Created by" name={'createdBy'} />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <ReadOnlyField label="Created date" name={'createdDate'} renderFormat={formatDateTimeFromISO} />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
    </>
  );
};

export default UpdateEquipmentFields;
