import { DetailsRow, FocusZoneTabbableElements, IDetailsRowProps, mergeStyles, useTheme } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { FrequencyDto, LicenceClassificationType, LicenceDtoLicenceTypeCodeEnum, SpectrumDto, SpectrumDtoPolarisationEnum } from 'api-client';

import { Grid } from 'ui-library';

import InlineButton from 'common/controls/buttons/InlineButton';
import { dirtyFormConfirmStrategy, neverConfirmStrategy, withConfirmOnClick } from 'common/controls/inputs/ConfirmOnClick';
import DetailsList, { SortProps } from 'common/controls/lists/DetailsList';
import InlineForm from 'common/form/InlineForm';

import { Licence } from '../../CraftLicencePage';
import ReferenceFrequenciesSection from './ReferenceFrequenciesSection';
import SpectrumDetailsSection from './SpectrumDetailsSection';
import UnwantedEmissionLimitsSection from './UnwantedEmissionLimitsSection';
import { requiresUnwantedEmissionLimits, spectrumDoneSchema } from './spectrumDetailsValidationSchema';

const SPL = LicenceDtoLicenceTypeCodeEnum.SPL;

const A = SpectrumDtoPolarisationEnum.A;
const C = SpectrumDtoPolarisationEnum.C;
const H = SpectrumDtoPolarisationEnum.H;
const O = SpectrumDtoPolarisationEnum.O;
const S = SpectrumDtoPolarisationEnum.S;
const V = SpectrumDtoPolarisationEnum.V;
const N = SpectrumDtoPolarisationEnum.N;
const R = SpectrumDtoPolarisationEnum.R;
const L = SpectrumDtoPolarisationEnum.L;
const M = SpectrumDtoPolarisationEnum.M;
const P = SpectrumDtoPolarisationEnum.P;
const X = SpectrumDtoPolarisationEnum.X;
const G = SpectrumDtoPolarisationEnum.G;

export const polarisationMap = new Map<SpectrumDtoPolarisationEnum, string>([
  [N, 'Non-specific'],
  [X, '+/- 45 degrees'],
  [R, 'Circular'],
  [A, 'Circular (anticlock)'],
  [C, 'Circular (clockwise)'],
  [P, 'Cross Polar'],
  [H, 'Horizontal'],
  [M, 'Mixed'],
  [L, 'Mixed or Linear'],
  [G, 'Orthogonal'],
  [O, 'Other'],
  [S, 'Slant'],
  [V, 'Vertical'],
]);

export interface Spectrum extends SpectrumDto {
  created?: Date;
}

interface Props {
  readOnly?: boolean;
  onRemoveSpectrum: (spectrumId: number) => Promise<any>;
  onViewSpectrum: (spectrumId?: number) => void;
  onSaveSpectrum: () => void;
  onCancelSpectrum: () => void;
  licence?: Licence;
  lastSaved?: Date;
  onFocus: () => void;
}

const LicenceSpectrumList: React.FC<Props> = (props) => {
  const { initialValues } = useFormikContext<{ spectrums?: Spectrum[] }>();
  return (
    <div className="ms-Grid-row" onFocus={props.onFocus}>
      <Grid.Col sm={12} lg={12}>
        <SpectrumList {...props} spectrums={initialValues.spectrums ?? []} readOnly={props.readOnly} />
      </Grid.Col>
    </div>
  );
};

const SpectrumList: React.FC<Props & { spectrums: Spectrum[] }> = ({
  readOnly,
  onRemoveSpectrum,
  onViewSpectrum,
  onSaveSpectrum,
  onCancelSpectrum,
  spectrums,
  licence,
  lastSaved,
}) => {
  const mode = readOnly ? 'VIEW' : 'EDIT';
  const [expandedId, setExpandedIdState] = useState<number>(-1);
  const [recentlySavedId, setRecentlySavedId] = useState<number | undefined>();

  const showUelSection = licence?.licenceTypeCode === SPL && requiresUnwantedEmissionLimits(licence);

  const setExpandedId = (id: number = -1) => {
    setExpandedIdState(id);
    onViewSpectrum(id !== -1 ? id : undefined);
  };
  const expandedIndex = spectrums.findIndex((spectrum) => spectrum.id === expandedId);

  const [sortProps, setSortProps] = useState<SortProps>();
  const sort = (items: Spectrum[]) =>
    sortProps
      ? [...items].sort((a: any, b: any) => {
          const x = a[sortProps.sortKey];
          const y = b[sortProps.sortKey];
          const sortVal = x === y ? 0 : x < y ? -1 : 1;
          return sortProps.descending ? -sortVal : sortVal;
        })
      : items;

  useEffect(() => {
    setRecentlySavedId(expandedId);
    // Collapse the opened view after saving.
    // If Confirm Save Changes was prompted from another View pressed, setExpandedId will be called with the new id following this call to clear the expandedId.
    setExpandedId();
    // eslint-disable-next-line
  }, [lastSaved]);

  const maxWidth = readOnly ? 135 : 120;
  const columns = [
    {
      key: 'label',
      name: 'Channel',
    },
    {
      key: 'lowerBound',
      name: 'Low (MHz)',
      isNumber: true,
      onRender: (item: SpectrumDto) => item.lowerBound?.toFixed(6),
    },
    {
      key: 'upperBound',
      name: 'High (MHz)',
      isNumber: true,
      onRender: (item: SpectrumDto) => item.upperBound?.toFixed(6),
    },
    {
      key: 'referenceFrequency',
      name: 'Ref. freq. (MHz)',
      isNumber: true,
      onRender: (item: FrequencyDto) => item.referenceFrequency?.toFixed(6),
    },
    ...(licence?.licenceTypeCode !== LicenceDtoLicenceTypeCodeEnum.SPL ||
    licence?.classification === undefined ||
    licence?.classification !== LicenceClassificationType.C
      ? [
          {
            key: 'power',
            name: 'Power dBW(eirp)',
            isNumber: true,
            onRender: (item: FrequencyDto) => item.power?.toFixed(1),
          },
          {
            key: 'frequencyEmissions',
            name: 'Emission',
            isMultiline: true,
          },
          {
            key: 'polarisation',
            name: 'Polarisation',
            onRender: (item: SpectrumDto) => (item.polarisation ? polarisationMap.get(item.polarisation) : undefined),
          },
        ]
      : []),
    {
      key: 'action',
      fieldName: '',
      name: '',
      minWidth: readOnly ? 85 : 185,
      maxWidth: readOnly ? 85 : 185,
      isResizable: false,
      onRender: (item: SpectrumDto, idx: number = -1) =>
        !item.id ? undefined : (
          <>
            <ViewButton id={item.id} onClick={() => setExpandedId(item.id)} confirmDirty={item.id !== expandedId} />
            {!readOnly && (
              <RemoveButton id={item.id} onClick={() => onRemoveSpectrum(item.id!).then(() => (item.id === expandedId ? setExpandedId() : {}))} />
            )}
          </>
        ),
    },
  ].map((col) => ({
    ...col,
    minWidth: col.minWidth ?? 15,
    maxWidth: col.maxWidth ?? maxWidth,
    isResizable: col.isResizable === undefined ? true : false,
    fieldName: col.key,
  }));

  return (
    <DetailsList
      items={sort(spectrums)}
      columns={columns}
      onRenderRow={(rowProps) => (rowProps ? <SpectrumRow {...rowProps} /> : null)}
      renderExpandPanel={(item) => {
        const close = () => {
          setExpandedId();
          onCancelSpectrum();
        };
        return (
          <InlineForm
            id="inline-form"
            canEdit={false}
            hideBack
            hideFormButtonsTop
            showFormButtonsBottom
            disableErrorMessage
            mode={mode}
            onCancelEdit={close}
            additionalButtons={(mode) => (mode === 'VIEW' ? [{ id: `close-spectrum-${expandedIndex}`, text: 'Close', onClick: close }] : [])}
            onSubmit={onSaveSpectrum}
            ignoreFieldsValidation={['radiationPatterns']}
          >
            <SpectrumDetailsSection spectrumIdx={expandedIndex} licence={licence} />
            <ReferenceFrequenciesSection
              spectrumIdx={expandedIndex}
              licenceClassification={licence?.classification}
              licenceTypeCode={licence?.licenceTypeCode}
            />
            {showUelSection && <UnwantedEmissionLimitsSection spectrumIdx={expandedIndex} licence={licence} />}
          </InlineForm>
        );
      }}
      renderRowAsSuccess={(row) => ({ shouldRender: recentlySavedId === row.item.id, callback: () => setRecentlySavedId(undefined) })}
      renderRowAsError={
        mode === 'VIEW'
          ? undefined
          : (row) => !spectrumDoneSchema(licence?.licenceTypeCode).isValidSync(row.item, { context: { context: { ...licence } } })
      }
      expandedIndex={expandedIndex}
      handleTabKey={FocusZoneTabbableElements.all}
      onSort={(sortProps) => {
        if (['action', 'referenceFrequency', 'frequencyEmissions'].indexOf(sortProps.sortKey) < 0) {
          setSortProps(sortProps);
        }
      }}
      sortProps={sortProps}
    />
  );
};

const ViewButton = (props: { id: number; onClick: () => void; confirmDirty: boolean }) => {
  const formikContext = useFormikContext();
  return (
    <>
      {withConfirmOnClick(
        <InlineButton id={`view-${props.id}`} text="View" onClick={props.onClick} />,
        props.confirmDirty ? dirtyFormConfirmStrategy(formikContext, ['radiationPatterns']) : neverConfirmStrategy,
        formikContext,
      )}
    </>
  );
};

const RemoveButton = (props: { id: number; onClick: () => void }) => <InlineButton id={`remove-${props.id}`} text="Remove" onClick={props.onClick} />;

// Display a spectrum record that has multiple frequencies on a separate row.
// Display recently cloned spectrum item {created} with a light-green background that fades away after 5s.
const SpectrumRow = (rowProps: IDetailsRowProps) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [recentlyCreatedNoMore, setRecentlyCreatedNoMore] = useState<Date | undefined>();
  const now = () => new Date();
  const isSecondsOld = (seconds: number, date?: Date) => date && now().getTime() - date.getTime() < seconds * 1000;
  const spectrum = rowProps.item as Spectrum;
  const rootStyles = (rowProps.styles as any)?.root;

  useEffect(() => {
    if (spectrum.created) {
      // Use the Date to force re-render, as the newly cloned Spectrum is inserted to the first position, the others are
      // shuffled down while the SpectrumRow component itself remains in the same position.
      setTimeout(() => setRecentlyCreatedNoMore(now()), 5000);
    }
  }, [spectrum.created]);

  // A recently cloned spectrum item shows with a light-green background and fades away after 5s.
  const recentlyCreatedStyle = isSecondsOld(5, spectrum.created)
    ? {
        background: theme.semanticColors.successBackground,
      }
    : { transition: isSecondsOld(1, recentlyCreatedNoMore) ? '1s' : undefined };

  if (!spectrum.frequencies?.length) {
    return <DetailsRow {...rowProps} styles={{ root: { ...rootStyles, ...recentlyCreatedStyle } }} />;
  }

  // If multiple spectrum frequencies, show each frequency on a separate row and show the spectrum details on the first row only.
  const spectrumRows = spectrum.frequencies.map((f: FrequencyDto, i: number) => ({
    ...(i === 0 ? spectrum : {}),
    referenceFrequency: f.referenceFrequency,
    power: f.power,
    frequencyEmissions: f.frequencyEmissions?.map((e) => e.emission).join(' '),
  }));

  const noBottomLineStyle = mergeStyles({
    '& .ms-DetailsRow': {
      borderBottomColor: 'transparent',
    },
  });

  // Workaround to progammatically use a isHovering flag to set the hover over background to encompass all frequency rows
  // belonging to the same spectrum. I had to do it this way rather than using e.g. mergeStyles selector because the latter
  // kept resetting to the OOB hover over single row.
  const hoverStyle = isHovering
    ? {
        background: theme.palette.neutralLighter,
        color: theme.palette.neutralPrimary,
      }
    : undefined;

  return (
    <div onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
      {spectrumRows.map((spectrumRow: any, idx: number) => (
        <div key={`${spectrum.id}.${idx}`} className={idx < spectrumRows.length - 1 ? noBottomLineStyle : undefined}>
          <DetailsRow {...rowProps} item={spectrumRow} styles={{ root: { ...rootStyles, ...(hoverStyle ?? recentlyCreatedStyle) } }} />
        </div>
      ))}
    </div>
  );
};

export default LicenceSpectrumList;
