import { DefaultButton, Label } from '@fluentui/react';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import * as React from 'react';

import { PhoneDto, PhoneDtoTypeEnum } from 'api-client';

import { Grid } from 'ui-library';

import { useForm } from '../../form/FormHook';
import { SectionHeading } from '../../layout/SectionHeadings';
import { asOptionsSimple } from '../../utils/dropdownUtils';
import Dropdown from './Dropdown';
import { PhoneNumber } from './PhoneNumber';

interface PhoneNumbersProps {
  readOnly?: boolean;
  addBtnText?: string;
}

export const PhoneNumbers: React.FC<PhoneNumbersProps> = (props) => {
  return (
    <>
      <SectionHeading title={'Phone number(s)'} />
      <Grid.Row>
        <Grid.Col sm={12} lg={8}>
          <FieldArray name={'phoneNumbers'} render={(arrayHelpers) => <PhoneNumbersComponent {...arrayHelpers} {...props} />} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const PhoneNumbersComponent = (props: FieldArrayRenderProps & PhoneNumbersProps) => {
  const { values } = useFormikContext<any>();
  const { mode } = useForm();

  return (
    <>
      {values.phoneNumbers &&
        values.phoneNumbers.length > 0 &&
        values.phoneNumbers.map((phoneNumber: PhoneDto, index: number) => (
          <div key={`phone-number-${index}`}>
            <PhoneNumberRow {...props} itemNumber={index} readOnly={mode === 'VIEW' || props.readOnly} />
          </div>
        ))}
      <>
        {!(props.readOnly || mode === 'VIEW') && (
          <Grid.Row>
            <Grid.Col sm={12} lg={12}>
              <DefaultButton
                text={props.addBtnText ?? 'Add'}
                onClick={() => props.push({ countryCode: 'NZ', type: undefined, areaCode: undefined, number: undefined })}
                id={'add-phone-number'}
                data-automation-id={'add-phone-number'}
                disabled={values.phoneNumbers && values.phoneNumbers.length >= 4}
              />
            </Grid.Col>
          </Grid.Row>
        )}
      </>
    </>
  );
};

const PhoneNumberRow = (props: FieldArrayRenderProps & { itemNumber: number; readOnly?: boolean }) => (
  <Grid.Row>
    <Grid.Col sm={12} lg={2}>
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <PhoneTypeDropdown {...props} itemNumber={props.itemNumber} />
        </Grid.Col>
      </Grid.Row>
    </Grid.Col>
    <Grid.Col sm={12} lg={8}>
      <PhoneNumber {...props} name={`phoneNumbers.${props.itemNumber}`} required />
    </Grid.Col>
    {props.itemNumber !== 0 && !props.readOnly && (
      <Grid.Col sm={12} lg={2}>
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Label>&nbsp;</Label>
            <DefaultButton
              text={'Remove'}
              onClick={() => props.remove(props.itemNumber)}
              id={'remove-phone-number-' + props.itemNumber}
              data-automation-id={'remove-phone-number-' + props.itemNumber}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    )}
  </Grid.Row>
);

const PhoneTypeDropdown = (props: { itemNumber: number }) => {
  const { values } = useFormikContext<any>();

  // should come from phoneDto types
  const typesOptions = asOptionsSimple(Object.keys(PhoneDtoTypeEnum));

  // get selected types from form
  const selectedTypes = values.phoneNumbers.filter((phone: PhoneDto) => phone.type !== undefined).map((phone: any) => phone.type);

  // from all options, remove the ones that have already been selected
  const options = typesOptions.filter(
    (typeOption) =>
      selectedTypes.find((selectedType: PhoneDtoTypeEnum) => selectedType === typeOption.key) === undefined ||
      typeOption.key === values.phoneNumbers[props.itemNumber].type,
  );

  return <Dropdown {...props} name={`phoneNumbers.${props.itemNumber}.type`} label="Phone type" options={options} required />;
};
