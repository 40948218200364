import * as React from 'react';

import Page from '../layout/Page';

interface Props {}

const ForbiddenPage: React.FC<Props> = (props) => {
  return (
    <Page title="Forbidden">
      <p>Either the page you have navigated to does not exist or your permissions do not allow access to this page.</p>
      <p>Click your browser 'Back' button to go back to the previous page.</p>
      <p>Please contact RSM if you think you should have access.</p>
    </Page>
  );
};

export default ForbiddenPage;
