import { useFormikContext } from 'formik';
import React from 'react';

import { CaseFinalResultEnum, CaseStatusEnum, CaseTypeCodeEnum } from 'api-client';

import { Dropdown, Grid2, TagInput } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import ReadOnlyField from 'common/controls/inputs/ReadOnlyField';
import { TextField } from 'common/controls/inputs/TextField';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { formatDate } from 'common/utils/dateUtils';
import { CASE_TYPES_WITH_OPTIONAL_CLASSIFICATIONS, PRODUCT_OR_SUPPLIER_AUDIT } from 'investigation/audit/hooks';
import CaseAllocatedTo from 'investigation/components/CaseAllocatedTo';
import CaseAssociatedTo, { AssociatedToDetails } from 'investigation/components/CaseAssociatedTo';
import CaseOrigin from 'investigation/components/CaseOrigin';
import CaseReadOnlyFields from 'investigation/components/CaseReadOnlyFields';
import { useCaseClassificationOptions } from 'investigation/hooks';
import { AuditFormProps } from 'investigation/interfaces';

import { useForm } from '../../../common/form/FormHook';
import AuditClientAppealSection from './AuditClientAppealSection';
import AuditComplianceSection from './AuditComplianceSection';
import AuditCourtDecisionSection from './AuditCourtDecisionSection';
import AuditFinalResultSection from './AuditFinalResultSection';
import AuditFurtherActionSection from './AuditFurtherActionSection';
import AuditInfringementSection from './AuditInfringementSection';
import AuditLicenceSection from './AuditLicenceSection';
import AuditLinkedCases from './AuditLinkedCases';
import AuditLinkedManagementRightsSection from './AuditLinkedManagementRightsSection';
import AuditProductOrSupplierAuditSection from './AuditProductOrSupplierAuditSection';
import AuditRecommendationSection from './AuditRecommendationSection';

interface Props {
  setIsWithdrawalDialogOpen?: (isOpen: boolean) => void;
  setActionCardVisible?: (visible: boolean) => void;
}

const AuditFormContent = (props: Props) => {
  const { setFieldValue, values } = useFormikContext<AuditFormProps>();
  const { mode } = useForm();
  const { caseTypeId, caseTypeOptions } = values;

  const { caseClassificationOptions } = useCaseClassificationOptions(caseTypeId);
  const selectedCaseTypeCode = caseTypeOptions?.find((option) => caseTypeId && option.key === caseTypeId)?.data.code;
  const isClassificationOptional = selectedCaseTypeCode && CASE_TYPES_WITH_OPTIONAL_CLASSIFICATIONS.includes(selectedCaseTypeCode);

  React.useEffect(() => {
    if (props.setActionCardVisible) {
      props.setActionCardVisible(mode === 'VIEW');
    }
  }, [mode]);

  return (
    <div>
      <SectionHeading title="Audit details" />

      <Grid2>
        {values.caseNumber && (
          <Grid2.Col lg={8}>
            <ReadOnlyField name="caseNumber" label="Case number" />
          </Grid2.Col>
        )}

        <Grid2.Col lg={3} colStart={1}>
          <Dropdown
            label="Case type"
            name="caseTypeId"
            onChange={() => {
              setFieldValue('caseClassificationId', null);
            }}
            options={values.caseTypeOptions}
            required
          />
        </Grid2.Col>

        {!isClassificationOptional && (
          <Grid2.Col colStart={1} lg={3}>
            <Dropdown disabled={!values.caseTypeId} label="Classification" name="caseClassificationId" options={caseClassificationOptions} required />
          </Grid2.Col>
        )}

        <Grid2.Col colStart={1} lg={values.isAssociatedToOptional ? 8 : 12}>
          {values.isAssociatedToOptional ? <AssociatedToDetails /> : <CaseAssociatedTo />}
        </Grid2.Col>

        <Grid2.Col colStart={1} lg={8}>
          <TextField name="notes" label="Notes" multiline maxLength={400} showMaxHint />
        </Grid2.Col>

        <Grid2.Col colStart={1} lg={8}>
          <TagInput label="Tags" maxTagsAllowed={10} name="tags" />
        </Grid2.Col>

        <Grid2.Col colStart={1} lg={3}>
          <DatePicker label="Start date" name="startDate" maxDate={new Date()} dateOnlyAsString />
        </Grid2.Col>

        {values.endDate && (
          <Grid2.Col lg={3}>
            <ReadOnlyField label="End date" name="endDate" renderFormat={formatDate} />
          </Grid2.Col>
        )}

        {values.complianceDate && values.complianceCheckResult && values.complianceNotes && (
          <Grid2.Col>
            <AuditComplianceSection />
          </Grid2.Col>
        )}

        {values.statusEnum === CaseStatusEnum.CLSD && values.courtDecision && (
          <Grid2.Col>
            <AuditCourtDecisionSection />
          </Grid2.Col>
        )}

        {values.appealProcessType && (
          <Grid2.Col>
            <AuditClientAppealSection />
          </Grid2.Col>
        )}

        {values.finalResult &&
          [CaseFinalResultEnum.COMPLIANT, CaseFinalResultEnum.DISCARD, CaseFinalResultEnum.NON_COMPLIANT].includes(values.finalResult) && (
            <Grid2.Col>
              <AuditFinalResultSection />
            </Grid2.Col>
          )}

        <Grid2.Col colStart={1}>
          <AuditLinkedCases />
        </Grid2.Col>

        <Grid2.Col colStart={1}>
          <AuditLinkedManagementRightsSection />
        </Grid2.Col>

        {(values.originCaseId || !values.statusEnum || values.statusEnum === CaseStatusEnum.IPRG) && (
          <Grid2.Col colStart={1}>
            <CaseOrigin />
          </Grid2.Col>
        )}

        <Grid2.Col colStart={1}>
          <CaseAllocatedTo />
        </Grid2.Col>

        {values.caseNumber && selectedCaseTypeCode && PRODUCT_OR_SUPPLIER_AUDIT.includes(selectedCaseTypeCode) && (
          <Grid2.Col>
            <AuditProductOrSupplierAuditSection />
          </Grid2.Col>
        )}

        {values.caseNumber && selectedCaseTypeCode === CaseTypeCodeEnum.LICENCE && (
          <Grid2.Col>
            <AuditLicenceSection />
          </Grid2.Col>
        )}

        {values.finalResult && [CaseFinalResultEnum.SATISFACTORY, CaseFinalResultEnum.UNSATISFACTORY].includes(values.finalResult) && (
          <Grid2.Col>
            <AuditRecommendationSection />
          </Grid2.Col>
        )}

        {values.finalResult === CaseFinalResultEnum.UNSATISFACTORY && (
          <Grid2.Col>
            <AuditInfringementSection setIsWithdrawalDialogOpen={props.setIsWithdrawalDialogOpen} />
          </Grid2.Col>
        )}

        {values.furtherAction && (
          <Grid2.Col>
            <AuditFurtherActionSection />
          </Grid2.Col>
        )}

        {values.caseNumber && (
          <Grid2.Col>
            <CaseReadOnlyFields />
          </Grid2.Col>
        )}
      </Grid2>
    </div>
  );
};

export default AuditFormContent;
