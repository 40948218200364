import React, { useEffect, useState } from 'react';

import { CaseStatusEnum, CaseTypeGroupEnum, FileNoteDtoEntityTypeEnum } from 'api-client';

import { Form } from 'ui-library';
import { AdditionalFormButton } from 'ui-library/form/FormButtons';
import EventLogSection from 'ui-library/shared/EventLogSection';
import FileNoteSection from 'ui-library/shared/file_note/FileNoteSection';

import { hasAction } from 'common/api/hateosUtils';
import { usePageNavigator } from 'common/auth/AppRoute';
import { useAuth } from 'common/auth/AuthHook';
import Page from 'common/layout/Page';
import { CASE_FILE_NOTE_TYPE } from 'common/reference/referenceData';
import { formatDateTimeFromISO } from 'common/utils/dateUtils';
import { AuditFormProps } from 'investigation/interfaces';

import ActionCardAppealResult from './components/ActionCardAppealResult';
import ActionCardAuditCompliance from './components/ActionCardAuditCompliance';
import ActionCardAuditCourtDecision from './components/ActionCardAuditCourtDecision';
import ActionCardCloseAudit from './components/ActionCardCloseAudit';
import ActionCardEmailClientSent from './components/ActionCardEmailClientSent';
import AuditClientAppealedDialog from './components/AuditClientAppealedDialog';
import AuditFormContent from './components/AuditFormContent';
import InfringementWithdrawalDialog from './components/InfringementWithdrawalDialog';
import { useAuditFormState } from './hooks';

const ViewAuditForm = () => {
  const { hasPermission, hasAnyRole } = useAuth();
  const { navigateTo } = usePageNavigator();
  const { caseDto, formState, setFormState } = useAuditFormState();

  useEffect(() => {
    if (caseDto?.id && caseDto.type?.group !== CaseTypeGroupEnum.AUDIT) {
      navigateTo('/not-found');
    }
  }, [caseDto]);

  const canViewFileNote = hasPermission('VIEW_FILE_NOTE_AND_ATTACHMENT');
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isWithdrawalDialogOpen, setIsWithdrawalDialogOpen] = useState<boolean>(false);

  let additionalButtons: AdditionalFormButton[] =
    formState.statusEnum === CaseStatusEnum.INFRINGE
      ? caseDto.appealProcessType || !hasAnyRole(['ROLE_SENIOR_RADIO_INVESTIGATOR', 'ROLE_INVESTIGATIONS_MANAGER'])
        ? []
        : [
            {
              id: 'clientAppealedBtn',
              isPrimary: true,
              onClick: () => setIsDialogOpen(true),
              text: 'Client appealed',
            },
          ]
      : [];

  return (
    <>
      {formState.statusEnum === CaseStatusEnum.INFRINGE && (
        <>
          <AuditClientAppealedDialog
            caseDetails={{
              caseNumber: caseDto.id,
            }}
            isOpen={isDialogOpen}
            setIsOpen={setIsDialogOpen}
          />
          <InfringementWithdrawalDialog
            isOpen={isWithdrawalDialogOpen}
            setIsOpen={setIsWithdrawalDialogOpen}
            audit={{
              caseNumber: caseDto.id,
              infringement: caseDto.infringement,
            }}
            onSaveCallback={(auditDto) => {
              setFormState({
                ...formState,
                statusEnum: auditDto.status,
                infringement: {
                  ...auditDto.infringement,
                  withdrawalReason: auditDto.infringement ? auditDto.infringement.withdrawalReason : '',
                  withdrawalDate: auditDto.infringement ? auditDto.infringement.withdrawalDate : '',
                },
                lastUpdatedBy: auditDto.lastUpdatedName,
                lastUpdatedDate: formatDateTimeFromISO(auditDto.lastUpdatedDate, { showSeconds: true }),
              });
            }}
          />
        </>
      )}
      {formState.statusEnum === CaseStatusEnum.APPEAL && hasAction('appeal-results', caseDto?.links) && (
        <ActionCardAppealResult id={caseDto.id || 0} />
      )}
      {formState.statusEnum === CaseStatusEnum.PROSECUTE && hasAction('court-ruling', caseDto?.links) && (
        <ActionCardAuditCourtDecision caseDetails={{ caseNumber: caseDto.id }} />
      )}
      {formState.statusEnum === CaseStatusEnum.CPL_CHECK && hasAction('compliance-followup', formState?.links) && (
        <ActionCardAuditCompliance id={caseDto.id || 0} />
      )}
      {formState.statusEnum === CaseStatusEnum.AUDIT_CPL && hasAction('close-engineer-audit', caseDto?.links) && (
        <ActionCardCloseAudit id={formState?.caseNumber || 0} />
      )}
      {formState.statusEnum === CaseStatusEnum.EMAIL && hasAction('confirm-email-sent', caseDto?.links) && (
        <ActionCardEmailClientSent
          id={formState?.caseNumber || 0}
          onActionCallback={(auditDto) => {
            setFormState({
              ...formState,
              statusEnum: auditDto.status,
              lastUpdatedBy: auditDto.lastUpdatedName,
              lastUpdatedDate: formatDateTimeFromISO(auditDto.lastUpdatedDate, { showSeconds: true }),
              links: auditDto.links,
            });
          }}
        />
      )}
      <Form<AuditFormProps> formButtonsOptions={{ additionalButtons }} id="ViewAuditForm" initialValues={formState} showFormButtonsBottom>
        <AuditFormContent setIsWithdrawalDialogOpen={setIsWithdrawalDialogOpen} />
        {formState?.caseNumber && canViewFileNote && (
          <FileNoteSection entityId={formState.caseNumber} entityType={FileNoteDtoEntityTypeEnum.CASE} noteTypes={CASE_FILE_NOTE_TYPE} />
        )}
        {formState?.caseNumber && <EventLogSection entityName={'CASE'} entityId={+formState.caseNumber} />}
      </Form>
    </>
  );
};

const ViewAuditPage = () => {
  return (
    <Page title="View Audit">
      <ViewAuditForm />
    </Page>
  );
};

export { ViewAuditPage };
