import { Grid } from 'ui-library';

import { TextField } from 'common/controls/inputs/TextField';

import { SpectrumMaskPoints } from './SpectrumMaskPoints';

const SpectrumMaskFields = () => (
  <>
    <Grid.Row>
      <Grid.Col sm={12} lg={5}>
        <TextField label="Identifier" name={'identifier'} required maxLength={10} />
      </Grid.Col>

      <Grid.Col sm={12} lg={5}>
        <TextField label="Description" name={'description'} required maxLength={60} />
      </Grid.Col>
    </Grid.Row>

    <SpectrumMaskPoints />
  </>
);

export default SpectrumMaskFields;
