import { Dropdown as FluentDropdown, IDropdownProps } from '@fluentui/react';
import { useField } from 'formik';
import * as React from 'react';

import { renderHelpText } from 'common/controls/inputs/HelpTextRenderer';
import ReadOnlyField, { renderAsOptions } from 'common/controls/inputs/ReadOnlyField';
import { useForm } from 'common/form/FormHook';

export interface DropdownOption<T = any> {
  data: T;
  key: string | number;
  text: string;
}
export interface DropdownProps extends Omit<IDropdownProps, 'onChange'> {
  helpText?: string;
  name: string;
  onChange?: (option: DropdownOption) => void;
  readOnly?: boolean;
}

const Dropdown = (props: DropdownProps) => {
  const [field, meta, helper] = useField(props.name);
  const { mode } = useForm();

  // TODO: handling of multiple options.
  const selectedKey = field.value ? field.value : null;

  if (mode === 'VIEW' || props.readOnly) {
    return <ReadOnlyField {...props} value={selectedKey} renderFormat={renderAsOptions(props.options)} />;
  }

  const errorMessage = meta.touched && meta.error ? meta.error : props.errorMessage;

  return (
    <FluentDropdown
      {...field}
      {...props}
      data-automation-id={`drop-down-${props.name}`}
      disabled={mode === 'DISABLED' || props.disabled}
      errorMessage={errorMessage}
      onChange={(event, option) => {
        helper.setValue(option?.key ? option?.key : null);
        field.onChange(event);

        if (props.onChange) {
          props.onChange((option || {}) as DropdownOption);
        }
      }}
      onRenderLabel={renderHelpText}
      options={props.options?.map(({ key, ...option }) => ({ key, ...option }))}
      selectedKey={selectedKey}
      styles={{
        ...props.styles,
        caretDown: { color: '#333333' },
      }}
    />
  );
};

export default Dropdown;
