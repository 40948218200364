import { IColumn, SelectionMode } from '@fluentui/react';
import { useFormikContext } from 'formik';
import React from 'react';

import { CaseStatusEnum, CaseTypeGroupEnum } from 'api-client';

import { Grid2, TagPicker } from 'ui-library';

import ChoiceGroup from 'common/controls/inputs/ChoiceGroup';
import DetailsList from 'common/controls/lists/DetailsList';
import { useForm } from 'common/form/FormHook';
import { SectionHeading } from 'common/layout/SectionHeadings';
import { convertStatus, useCaseSearchResultParser } from 'investigation/hooks';
import { AuditFormProps } from 'investigation/interfaces';
import { useSearchApi } from 'search/SearchApiHooks';

export enum AuditLinkedCaseTypeEnum {
  SUBSIDIARY = 'SUBSIDIARY',
  ORIGINATOR = 'ORIGINATOR',
}

const options = [
  { key: AuditLinkedCaseTypeEnum.SUBSIDIARY, text: 'Subsidiary case' },
  { key: AuditLinkedCaseTypeEnum.ORIGINATOR, text: 'Originator case' },
];

const CELL_STYLE = { display: 'block', padding: '10px 0' };

const columns: IColumn[] = [
  {
    key: 'linkType',
    name: 'Type',
    minWidth: 100,
    maxWidth: 150,
    onRender: ({ caseNumber }: any) => (
      <ChoiceGroup name={`linkTypes.caseId_${caseNumber}`} options={options} defaultSelectedKey={AuditLinkedCaseTypeEnum.SUBSIDIARY} />
    ),
  },
  {
    key: 'name',
    name: 'Case number',
    minWidth: 30,
    maxWidth: 100,
    onRender: ({ caseNumber }: any) => (
      <a style={CELL_STYLE} href={`/ui/app/investigation/audit/view/${caseNumber}`} target="_blank" rel="noreferrer">
        {caseNumber}
      </a>
    ),
  },

  {
    key: 'clientName',
    name: 'Client name',
    minWidth: 70,
    onRender: ({ clientName }: any) => <span style={CELL_STYLE}>{clientName}</span>,
    isResizable: true,
  },
  {
    key: 'caseStatus',
    name: 'Status',
    minWidth: 100,
    isResizable: true,
    onRender: ({ caseStatus }: any) => <span style={CELL_STYLE}>{caseStatus}</span>,
  },
  {
    key: 'caseResult',
    name: 'Result',
    minWidth: 100,
    isResizable: true,
    onRender: ({ caseResult }: any) => <span style={CELL_STYLE}>{caseResult}</span>,
  },
];

const AuditLinkedCases = () => {
  const resultParser = useCaseSearchResultParser();
  const { mode } = useForm();
  const { values } = useFormikContext<AuditFormProps>();

  const { linkedCases = [], linkTypes } = values;
  const { searchCase } = useSearchApi();
  const canBeModified = mode === 'CREATE' || mode === 'EDIT';

  if (!linkedCases?.length && values.statusEnum && CaseStatusEnum.IPRG !== values.statusEnum) {
    return <></>;
  }

  return (
    <SectionHeading expanded={!!linkedCases?.length} title="Linked cases">
      <Grid2>
        {canBeModified && (
          <Grid2.Col lg={6}>
            <TagPicker
              label="Search case"
              name="linkedCases"
              maxAllowedItems={10}
              onResolveSuggestions={async (searchText: string) => {
                const { results = [] } = await searchCase({
                  orderBy: 'clientName',
                  searchText,
                  typeGroup: CaseTypeGroupEnum.AUDIT,
                });

                return results.map(resultParser).filter(({ data }) => data.id !== values.caseNumber);
              }}
              pickerSuggestionsProps={{
                loadingText: 'Searching for cases',
                noResultsFoundText: 'No cases found',
                suggestionsHeaderText: 'Suggested cases',
              }}
              placeholder="Type case number or client name"
            />
          </Grid2.Col>
        )}
        <Grid2.Col lg={12}>
          <DetailsList
            compact
            columns={columns}
            items={(linkedCases || []).map(({ data }) => ({
              clientName: data.clientName,
              caseNumber: data.id,
              caseStatus: convertStatus(data.status as CaseStatusEnum),
              caseResult: data.result,
            }))}
            selectionMode={SelectionMode.none}
          />
        </Grid2.Col>
      </Grid2>
    </SectionHeading>
  );
};

export default AuditLinkedCases;
