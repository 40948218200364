import React from 'react';
import { Route, RouteProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { useAuth } from './AuthHook';
import { FeatureFlag } from './FeatureFlags';
import ForbiddenPage from './ForbiddenPage';
import NotLoggedInPage from './NotLoggedInPage';
import { Permission } from './Permissions';

interface AppRouteProps extends RouteProps {
  permission?: Permission[];
  skipPermissionsForExternal?: boolean; // render to the page even if doesn't have permission
  featureFlag?: FeatureFlag;
}

export const AppProtectedRoute = (props: AppRouteProps) => {
  const { loggedIn, hasPermission, isFeatureFlagEnabled } = useAuth();

  if (!loggedIn) {
    return <Route {...props} component={NotLoggedInPage} />;
  }

  const canAccess = !props.permission ? true : props.permission?.find((p) => hasPermission(p)) !== undefined;
  const isEnabled = !props.featureFlag ? true : isFeatureFlagEnabled(props.featureFlag);
  return isEnabled && (canAccess || props.skipPermissionsForExternal) ? <Route {...props} /> : <Route {...props} component={ForbiddenPage} />;
};

type NavigateToProps = {
  openInNewTab?: boolean;
  queryParams?: {
    [key: string]: any;
  };
};

export const usePageNavigator = (module?: AppModule) => {
  const history = useHistory();

  return {
    navigateTo: (url: string, options?: NavigateToProps) => {
      let path = `/ui${module ? `/app/${module}${url}` : url}`;

      const queryParams = options?.queryParams;
      if (queryParams) {
        const queryString = Object.keys(queryParams)

          .filter((key) => queryParams[key])
          .map((key) => `${key}=${queryParams[key]}`)
          .join('&');

        path = path.indexOf('?') >= 0 ? `${path}&${queryString}` : `${path}?${queryString}`;
      }

      options?.openInNewTab ? window.open(path, '_blank') : history.push(path);
    },
  };
};

type AppModule = 'investigation';

export const AppRouteSwitch = ({ children }: any) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {React.Children.toArray(children).map((child: any) => {
        const props: AppRouteProps = child.props;
        return React.cloneElement(child, {
          path: `${path}${props.path}`,
        });
      })}
    </Switch>
  );
};
