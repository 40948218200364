import { IBasePickerSuggestionsProps, ITag, Label, mergeStyles, TagPicker as FluentTagPicker } from '@fluentui/react';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import React, { CSSProperties, ReactNode, useRef } from 'react';

import Error from 'common/controls/items/Error';

function debounceAsync<T, Callback extends (...args: any[]) => Promise<T>>(
  callback: Callback,
  wait: number,
): (...args: Parameters<Callback>) => Promise<T> {
  let timeoutId: number | null = null;

  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<T>((resolve) => {
      const timeoutPromise = new Promise<void>((resolve) => {
        timeoutId = setTimeout(resolve, wait);
      });
      timeoutPromise.then(async () => {
        resolve(await callback(...args));
      });
    });
  };
}

interface BaseTagPickerProps {
  name: string;
  label?: string;
  onRenderLabel?: ReactNode;

  placeholder?: string;
  required?: boolean;
}

interface PickerSuggestionsProps extends Pick<IBasePickerSuggestionsProps, 'loadingText' | 'noResultsFoundText' | 'suggestionsHeaderText'> {
  manualTagText?: string | ReactNode;
  onClickManualTag?: () => void;
}

export interface SelectedTag<T> extends ITag {
  data: T;
}
export interface SingleTagPickerProps<T> extends Omit<TagPickerProps<T>, 'defaultSelectedTags' | 'maxAllowedItems' | 'onChange'> {
  onChange?: (selectedTag: SelectedTag<T> | undefined) => void;
  defaultSelectedTag?: SelectedTag<T>;
}

export interface TagPickerProps<T> extends BaseTagPickerProps {
  className?: string;
  maxAllowedItems?: number;
  onChange?: (selectedTags: SelectedTag<T> | SelectedTag<T>[] | undefined) => void;
  onResolveSuggestions: (searchText: string) => Promise<SelectedTag<T>[]>;
  pickerSuggestionsProps?: PickerSuggestionsProps;
  defaultSelectedTags?: SelectedTag<T>[];
}

export const SingleTagPicker = <T extends unknown>(props: SingleTagPickerProps<T>) => {
  const className = mergeStyles({
    '.ms-TagItem': {
      maxWidth: 'none',
    },
  });

  const [field, meta, helper] = useField(props as any);
  const defaultSelectedTag = props.defaultSelectedTag || field.value || undefined;

  return (
    <TagPicker<T>
      {...props}
      className={`${className} ${props.className}`}
      maxAllowedItems={1}
      onChange={(selectedTag) => props.onChange && props.onChange(selectedTag as SelectedTag<T>)}
      defaultSelectedTags={defaultSelectedTag && [defaultSelectedTag]}
    />
  );
};

const TagPicker = <T extends unknown>(props: TagPickerProps<T>) => {
  const { name, maxAllowedItems = 1, onRenderLabel, placeholder } = props;
  const [field, meta, helper] = useField(props as any);

  const onResolveSuggestions = useRef(debounceAsync((searchText) => props.onResolveSuggestions(searchText), 500)).current;

  const {
    loadingText = 'Searching...',
    manualTagText,
    noResultsFoundText,
    onClickManualTag,
    ...pickerSuggestionsProps
  } = props.pickerSuggestionsProps || {};

  const tagWrapperStyle: CSSProperties = {
    display: 'block',
    padding: '0 10px',
    textAlign: 'left',
    width: '100%',
  };

  const manualTagWrapperStyle: CSSProperties = {
    borderTop: 'solid 1px #f1f1f1',
    cursor: 'pointer',
    display: 'block',
    padding: '10px 0',
  };

  const className = mergeStyles({
    '.ms-TagItem': {
      maxWidth: 'none',
    },
  });

  const renderManualTag = () => (
    <span onClick={onClickManualTag} style={manualTagWrapperStyle}>
      {manualTagText}
    </span>
  );

  const onRenderNoResultFound = () => {
    return (
      <span style={tagWrapperStyle}>
        <span style={{ display: 'block', padding: '10px 0' }}>{props.pickerSuggestionsProps?.noResultsFoundText || 'No results found'}</span>
        {manualTagText && renderManualTag()}
      </span>
    );
  };

  const hasError = meta.touched && meta.error;

  return (
    <div>
      {onRenderLabel || (
        <Label htmlFor={name} required={props.required}>
          {props.label}
        </Label>
      )}
      <FluentTagPicker
        {...field}
        removeButtonAriaLabel="Remove"
        className={`${hasError && 'pickerErrorBorder'} ${className} ${props.className}`}
        defaultSelectedItems={props.defaultSelectedTags || field.value}
        getTextFromItem={({ name = '' }: ITag, currentValue = '') => {
          return name.toLocaleLowerCase().startsWith(currentValue.toLocaleLowerCase()) ? name : '';
        }}
        inputProps={{
          id: name,
          name,
          placeholder,
        }}
        itemLimit={maxAllowedItems}
        onChange={(selectedTags) => {
          let value;

          if (!selectedTags?.length) {
            value = undefined;
          } else if (maxAllowedItems === 1) {
            value = selectedTags[0] as SelectedTag<T>;
          } else {
            value = selectedTags as SelectedTag<T>[];
          }

          helper.setValue(value || null, false);
          helper.setTouched(!value, false);

          props.onChange && props.onChange(value);
        }}
        onItemSelected={(item) => (item?.key === 'MANUAL' ? null : (item as ITag))}
        onResolveSuggestions={async (searchText) => {
          if (isEmpty(searchText)) {
            return [];
          }

          const searchResults = (await onResolveSuggestions(searchText)) as SelectedTag<T>[];

          const currentValues = field.value as SelectedTag<T>[];
          const suggestions = searchResults.filter((result) => !currentValues?.find((currentValue) => currentValue.key === result.key)?.key);

          if (!suggestions?.length || !manualTagText) {
            return suggestions;
          }

          return suggestions.concat([{ key: 'MANUAL', name: '', data: {} as T }]);
        }}
        onRenderSuggestionsItem={({ key, name }) => {
          if (key === 'MANUAL') {
            return <span style={tagWrapperStyle}>{renderManualTag()}</span>;
          }

          return <div style={{ padding: 10 }}>{name}</div>;
        }}
        pickerSuggestionsProps={{
          ...pickerSuggestionsProps,
          loadingText,
          onRenderNoResultFound,
        }}
      />
      {hasError && typeof meta.error === 'string' && <Error errorMessage={meta.error} />}
    </div>
  );
};

export default TagPicker;
