import _ from 'lodash';

export const placeholderIfEmpty = (val: string | number | undefined, placeholder: string = '-') => {
  if (val === undefined || val === null || val === '') {
    return placeholder;
  }

  return val;
};

export const emptyStringIfUndefined = (val: string | number | undefined, emptyStringPlaceholder?: string): string => (val ? val.toString() : '');

export const numberToLetter = (i: number) => {
  const previousLetters: string = i >= 26 ? numberToLetter(Math.floor(i / 26) - 1) : '';
  const lastLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i % 26];
  return previousLetters + lastLetter;
};

export const toSentenceCase = (val?: string) => _.upperFirst(_.lowerCase(val || ''));

/**
 * Concatenates multiple values with a specified delimiter, handling null, undefined, empty string.
 *
 * @param delimiter - The string to be used as a delimiter.
 * @param parts - The values to be concatenated.
 * @returns The concatenated string with the delimiter between each part.
 */
export const concatenateWithDelimiter = (delimiter: string, ...parts: (string | number | boolean | null | undefined)[]): string => {
  // Filter out null, undefined, and empty string values, then convert others to strings
  const filteredParts = parts
      .filter(part => part !== null && part !== undefined && part !== '')
      .map(part => String(part)) // Use String constructor to safely convert to string

  return filteredParts.join(delimiter);
}
