import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Text } from '@fluentui/react';
import { useAppRouter } from 'Routes';
import { useFormikContext } from 'formik';
import React from 'react';

import { AuditCourtDecisionEnum } from 'api-client';

import { Dropdown, Form, Grid2 } from 'ui-library';

import DatePicker from 'common/controls/inputs/DatePicker';
import { TextField } from 'common/controls/inputs/TextField';
import usePage from 'common/layout/PageHook';
import { useAuditApi } from 'investigation/audit/hooks';
import { CaseDetails } from 'investigation/interfaces';

interface AuditCourtDecisionFormProps {
  courtDecision: AuditCourtDecisionEnum;
  courtDecisionDate: string;
  courtDecisionDetails: string;
}

interface AuditCourtDecisionDialogProps {
  caseDetails: Partial<CaseDetails>;
}

const ActionCardAuditCourtDecision = (props: AuditCourtDecisionDialogProps) => {
  const { auditCourtDecisionApi } = useAuditApi();
  const { navigateToDashboard } = useAppRouter();
  const { setPageInstruction } = usePage();

  return (
    <Form<AuditCourtDecisionFormProps>
      handleSubmit={(values, formikHelpers) => {
        auditCourtDecisionApi({
          id: props.caseDetails.caseNumber || 0,
          ...values,
        }).then(() => {
          navigateToDashboard();
          setPageInstruction('Court ruling successfully saved and case closed.', MessageBarType.success);
        });
      }}
      mode="CREATE"
      hideFormButtonsTop={true}
      id="AuditCourtDecision"
      validationSchema={[{ name: 'courtDecision' }, { name: 'courtDecisionDate' }, { name: 'courtDecisionDetails' }]}
    >
      <AuditCourtDecisionContent />
    </Form>
  );
};

const AuditCourtDecisionContent = () => {
  const { submitForm, resetForm } = useFormikContext();

  return (
    <MessageBar
      data-automation-id={`audit-court-decision-action-card`}
      messageBarType={MessageBarType.warning}
      actions={
        <>
          <div>
            <PrimaryButton data-automation-id="audit-complete-action" onClick={() => submitForm()}>
              Submit
            </PrimaryButton>
          </div>
          <div style={{ marginRight: 10 }}>
            <DefaultButton data-automation-id="audit-complete-clear" onClick={() => resetForm()}>
              Clear
            </DefaultButton>
          </div>
        </>
      }
    >
      <Text>Check all details have been completed before closing the case.</Text>
      <Grid2>
        <Grid2.Col lg={6}>
          <Dropdown
            label="Court ruling"
            name="courtDecision"
            options={[
              { key: AuditCourtDecisionEnum.GUILTY, text: 'Guilty' },
              { key: AuditCourtDecisionEnum.NOT_GUILTY, text: 'Not guilty' },
            ]}
            required
          />
        </Grid2.Col>
        <Grid2.Col lg={6}>
          <DatePicker label="Court date" name="courtDecisionDate" dateOnlyAsString required />
        </Grid2.Col>
        <Grid2.Col lg={12}>
          <TextField label="Details" maxLength={400} multiline name="courtDecisionDetails" required showMaxHint />
        </Grid2.Col>
      </Grid2>
    </MessageBar>
  );
};

export default ActionCardAuditCourtDecision;
