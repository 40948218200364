import { MessageBarType, Text } from '@fluentui/react';
import { Form as FormikForm, useFormikContext } from 'formik';
import * as React from 'react';
import { useContext } from 'react';

import { ENVIRONMENT } from '../../env-config';
import OverlaySpinner from '../controls/progress/OverlaySpinner';
import ConfirmDialog from '../controls/surfaces/ConfirmDialog';
import { NavigationContext } from '../layout/Navigation';
import usePage from '../layout/PageHook';
import FormButtons, { FormButtonsProps } from './FormButtons';

/**
 * CREATE   - display form in editable mode and hide cancel button
 * SEARCH   - display form in editable mode, hide submit button and hide cancel button
 * VIEW     - display form in readonly mode
 * EDIT     - display form in editable mode
 * DISABLED - display form inputs in disabled mode
 */
export type FormMode = 'CREATE' | 'SEARCH' | 'VIEW' | 'EDIT' | 'DISABLED';

export interface FormProps extends Partial<FormButtonsProps> {
  id: string;
  showFormButtonsBottom?: boolean;
  hideFormButtonsTop?: boolean;
  inModal?: boolean;
  onBack?: () => void;
  submittingSpinnerLabel?: string;
  mode: FormMode;
  disableErrorMessage?: boolean;
  disabledSubmitWhenNoDirty?: boolean;
  children?: React.ReactNode;
}

interface FormContextProps {
  mode: FormMode;
  setMode?: (mode: FormMode) => void;
}

export const FormContext = React.createContext<FormContextProps>({
  mode: 'VIEW',
});

const Form: React.FunctionComponent<FormProps> = (props) => {
  const [canEdit, setCanEdit] = React.useState(props.canEdit);
  const [mode, setMode] = React.useState<FormMode>(props.mode);

  const { isSubmitting, errors, values, touched, isValid, resetForm } = useFormikContext();
  const context = useFormikContext();
  const { setPageInstruction } = usePage();
  const handleCancelEdit = () => {
    setMode(props.mode);
    resetForm();
    props.onCancelEdit && props.onCancelEdit();
  };
  const handleEditClick = () => setMode('EDIT');
  const formButtonsProps: FormButtonsProps = {
    ...props,
    onEditClick: () => {
      props.onEditClick && props.onEditClick();
      handleEditClick();
    },
    onCancelEdit: () => {
      props.onCancelEdit && props.onCancelEdit();
      handleCancelEdit();
    },
    canEdit: canEdit,
  };

  const navigationContext = useContext(NavigationContext);
  navigationContext.setFormikContext(context);
  navigationContext.setMode(mode);

  React.useEffect(() => {
    setCanEdit(props.canEdit);
  }, [props.canEdit]);

  React.useEffect(() => {
    if (!isValid && isSubmitting && !props.disableErrorMessage) {
      setPageInstruction('There are errors on the form.', MessageBarType.error);
    }
    // eslint-disable-next-line
  }, [isValid, isSubmitting]);

  React.useEffect(() => setMode(props.mode), [props.mode]);

  if (ENVIRONMENT !== 'PRD') {
    console.groupCollapsed(`Form info for form ${props.id}`);
    console.log(`Mode: ${mode}`);
    console.log(`Values: \n${JSON.stringify(values, null, 2)}`);
    console.log(`%cErrors: \n${JSON.stringify(errors, null, 2)}`, 'color:red;');
    console.log(`Touched fields: \n${JSON.stringify(touched, null, 2)}`);
    console.groupEnd();
  }

  return (
    <>
      {isSubmitting && <OverlaySpinner label={props.submittingSpinnerLabel ?? 'Saving...'} />}
      <FormContext.Provider value={{ mode }}>
        <FormikForm id={props.id} data-automation-id={props.id}>
          {values ? (
            <>
              {!props.hideFormButtonsTop && <FormButtons {...formButtonsProps} top />}
              {props.children}
              {props.showFormButtonsBottom && <FormButtons {...formButtonsProps} top={false} />}
            </>
          ) : (
            <></>
          )}
        </FormikForm>
        <ConfirmWarningDialog />
      </FormContext.Provider>
    </>
  );
};

const ConfirmWarningDialog = () => {
  const { showWarningModal, setShowWarningModal, modalContext, warnings } = usePage();

  return (
    <ConfirmDialog
      visible={showWarningModal}
      title="Do you want to continue?"
      notDangerous={true}
      yesText="OK"
      noText="Cancel"
      onCancel={() => {
        setShowWarningModal(false);
        if (modalContext?.onCancel) {
          modalContext?.onCancel();
        }
      }}
      onOk={() => {
        setShowWarningModal(false);
        if (modalContext?.onConfirm) {
          modalContext?.onConfirm();
        }
      }}
      body={
        <div>
          {warnings?.map((warning, idx) => (
            <Text>{warning}</Text>
          ))}
        </div>
      }
    />
  );
};

export default Form;
